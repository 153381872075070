import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";

// import Home from './Home';
import Dashboard from './Dashboard';
import TaskDueDate from './Transactions/Taskduedate';
import ConsolidatedReport from "./Report/ComplianceReports/ConsolidatedReport"
import DomainReport from './Report/ComplianceReports/DomainReports';
import Notification from './Notifications/Notifications';
import Messagesshowall from './Notifications/Messages';
import Rejectedsummary from './Transactions/Rejectedsummary';
import TaskCategory from './Transactions/Taskcategory';
import StatutoryNotificationList from './Report/StatutoryNotificationList';
import StatutoryMappingBulkReport from './Report/StatutoryMapping_BulkUploadReport';
import StatutoryMappingReport from './Report/StatutoryMappingReport';
import UserMappingReport from './Report/UserMappingReport';
import ClientAggrementMasterReport from './Report/ClientAggrementMasterReport';
import Taskcategoryedit from './Transactions/Taskcategoryedit';
import StatutoryMapping from './Transactions/StatutoryMapping';
import MyProfile from './MyProfile'
import ChangePassword from './ChangePassword'
import AddStatutoryMapping from './Transactions/Addstatutorymapping'
import StatutoruMappingBulkupload from './Transactions/StatutoryMappingBulkupload'
import Taskcategoryadd from './Transactions/Taskcategoryadd';
import Approvetaskcategorymapping from './Transactions/Approvetaskcategorymapping';
import ApproveStatutoryBulkupload from './Transactions/Approve_statutory_bulkupload'
import ApproveStatutoryMapping from './Transactions/ApproveStatutoryMapping';
import AssignClientUnit from './Transactions/Assignclientunit'
import ApproveAssignBulkupload from './Transactions/Approve_assign_bulkupload'
import Approveassignedstatutory from './Transactions/Approveassignedstatutory'
import ClientUnitDetailsReport from './Report/ClientUnitdetailsreport'
import DomainWiseAgreementMasterReport from './Report/DomainWiseAgreementMasterReport'
import StatutorySettingsReport from './Report/StatutorySettingsReport';
import ApproveStatutoryBulkReport from './Report/Approve_Statutory_bulkreport';
import AssignStatutoryBulkupload from './Transactions/Assign_statutory_bulkupload'
import RejectedAssignStatutoryBulkUpload from './Transactions/RejectedAssignStatutory_bulkupload'
import DE_Assignstatutory from './Transactions/DE_Assignstatutory';
import LegalEntityClosure from './Transactions/LegalEntityClosure';
import UnitCodeLabel from './Transactions/UnitCodeLabel';
import GroupAdmin_regis_email from './Transactions/GroupAdmin_regis_email';
import AssignLegalEntity from './Transactions/AssignLegalEntity';
import Country from './Master/CA_Country';
import TE_RejectClientUnitBulkUpload from './Transactions/TE_RejectClientUnitBulkUpload';
import ClientGroup from './Transactions/ClientGroup';
import TE_ClientUnit from './Transactions/TE_ClientUnit';

import ClientunitBulkupload from './Transactions/TE_Clientunitbulkupload';
import ApproveClientUnit_Vendor_BulkUpload from './Transactions/ApproveClientUnit_Vendor_BulkUpload';
import ApproveComplianceBased_AS_BulkUpload from "./Transactions/ApproveComplianceBasedAssignStatutory_BU";

const RoutesLink = () => {
    return (
        <Switch>
            <Route exact path="/dashboard">
                <Dashboard />
            </Route>
            <Route exact path="/knowledge/home/:id">
                <Dashboard />
            </Route>
            <Route exact path="/status-report-consolidated/:id">
                <ConsolidatedReport />
            </Route>
            <Route exact path="/domain-wise-report/:id">
                <DomainReport />
            </Route>
            <Route exact path="/knowledge/messages/:id">
                <Messagesshowall />
            </Route>
            <Route exact path="/knowledge/statutory-notifications/:id">
                <Notification />
            </Route>
            <Route exact path="/knowledge/reject-statutory-mapping-bu/:id">
                <Rejectedsummary />
            </Route>
            <Route exact path="/knowledge/task-due-extension/:id">
                <TaskDueDate />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <TaskCategory />
            </Route>
            <Route exact path="/knowledge/statutory-notifications-list/:id">
                <StatutoryNotificationList />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-bulkupload-report/:id">
                <StatutoryMappingBulkReport />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <Taskcategoryedit />
            </Route>
            <Route exact path="/knowledge/assign-legal-entity/:id">
                <AssignLegalEntity />
            </Route>
            <Route exact path="/knowledge/client-master/:id">
                <ClientGroup />
            </Route>
            <Route exact path="/knowledge/statutory-mapping/:id">
                <StatutoryMapping />
            </Route>
            <Route exact path="/knowledge/statutory-mapping/:id">
                <AddStatutoryMapping />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-report/:id">
                <StatutoryMappingReport />
            </Route>
            <Route exact path="/knowledge/user-mapping-report/:id">
                <UserMappingReport />
            </Route>
            <Route exact path="/knowledge/client-agreement-report/:id">
                <ClientAggrementMasterReport />
            </Route>
            <Route exact path="/knowledge/profile/:id">
                <MyProfile />
            </Route>
            <Route exact path="/knowledge/change-password/:id">
                <ChangePassword />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-bu/:id">
                <StatutoruMappingBulkupload />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <Taskcategoryadd />
            </Route>
            <Route exact path="/knowledge/approve-taskcategory-mapping/:id">
                <Approvetaskcategorymapping />
            </Route>
            <Route exact path="/knowledge/approve-statutory-mapping-bu/:id">
                <ApproveStatutoryBulkupload />
            </Route>
            <Route exact path="/knowledge/approve-statutory-mapping/:id">
                <ApproveStatutoryMapping />
            </Route>
            <Route exact path="/knowledge/assign-client-unit/:id">
                <AssignClientUnit />
            </Route>
            <Route exact path="/knowledge/approve-assign-statutory-bu/:id">
                <ApproveAssignBulkupload />
            </Route>
            <Route exact path="/knowledge/approve-assigned-statutory/:id">
                <Approveassignedstatutory />
            </Route>
            <Route exact path="/knowledge/client-unit-details/:id">
                <ClientUnitDetailsReport />
            </Route>
            <Route exact path="/knowledge/domain-agreement-master-report/:id">
                <DomainWiseAgreementMasterReport />
            </Route>
            <Route exact path="/knowledge/statutory-setting-report/:id">
                <StatutorySettingsReport />
            </Route>
            <Route exact path="/knowledge/assigned-statutory-bulk-report/:id">
                <ApproveStatutoryBulkReport />
            </Route>
            <Route exact path="/knowledge/assign-statutory-bu/:id">
                <AssignStatutoryBulkupload />
            </Route>
            <Route exact path="/knowledge/rejected-assign-statutory-bu/:id">
                <RejectedAssignStatutoryBulkUpload />
            </Route>
            <Route exact path="/knowledge/assign-statutory/:id">
                <DE_Assignstatutory />
            </Route>
            <Route exact path="/knowledge/legal-entity-closure/:id">
                <LegalEntityClosure />
            </Route>
            <Route exact path="/knowledge/unitcode-label/:id">
                <UnitCodeLabel />
            </Route>
            <Route exact path="/knowledge/group-admin-registration-email/:id">
                <GroupAdmin_regis_email />
            </Route>
            <Route exact path="/knowledge/country-master/:id">
                <Country />
            </Route>
            <Route exact path="/knowledge/rejected-client-unit-bu/:id">
                <TE_RejectClientUnitBulkUpload />
            </Route>
            <Route exact path="/knowledge/client-unit/:id">
                <TE_ClientUnit />
            </Route>
            <Route exact path="/knowledge/client-unit-bu/:id">
                <ClientunitBulkupload />
            </Route>
            <Route exact path="/knowledge/approve-client-unit-bu/:id">
                <ApproveClientUnit_Vendor_BulkUpload />
            </Route>
            <Route exact path="/knowledge/approve-compliance-assign-statutory-bu/:id">
                <ApproveComplianceBased_AS_BulkUpload />
            </Route>
        </Switch>
    )
}


export default connect(null)(RoutesLink);
