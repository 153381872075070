import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Modal, Card, Tabs, Table, Pagination ,Tooltip, Divider} from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { EyeOutlined, DownloadOutlined, FilterOutlined,FullscreenOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Clientagreementmasterfilter, GetClientAgreementReportData, getAgreementReport } from './../../../src/Store/Action/Reports/ClientAggrementMasterReport';
import { TOGGLE_COMMON_LOADER } from './../../Store/types/index'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import axios from 'axios';
import url from '../../Libs/URL';
import { decode as base64_decode } from 'base-64';
import { Toaster } from '../../Libs/Toaster';
import { filter } from 'lodash';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";




const mapStateToProps = (state) => ({
    clientagreementresult: state.ClientAggrementMasterReport.clientagreementfilter.clientagreementmasterfilter,
    clientagreementresultdata: state.ClientAggrementMasterReport.clientagreementfilter.clientagreementdata,
    clientAgreementPopUpData: state.ClientAggrementMasterReport.clientagreementfilter.popUpData
})

const ClientAggrementMasterReport = ({
    Clientagreementmasterfilter,
    clientagreementresult,
    GetClientAgreementReportData,
    clientagreementresultdata,
    getAgreementReport,
    clientAgreementPopUpData
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");

    console.log(clientAgreementPopUpData, 'clientAgreementPopUpData');

    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        currentpage: 1,
        pageSizeOptions: ['10', '20', '30']
    })
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [clientaggrementdata, setClientaggrementdata] = useState({
        country_id: '',
        country_name: ''
    })
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    let [index, setIndex] = useState(0)
    const [parentvalue, setparentvalue] = useState();
    const [finalvalue, setfinalvalue] = useState();
    const { TabPane } = Tabs;
    const [filtervalue, setfiltervalue] = useState({
        country: '',
        countryName: '',
        group: '',
        groupdata: '',
        domain: '',
        domaindata: '',
        businessgroup: '',
        busineesgroupdata: '',
        legalentity: '',
        legalentitydata: '',
        contractfrom: '',
        contractto: ''


    })
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpDetails, setPopUpDetails] = useState({
        groupName: '',
        shortName: '',
        groupAdmin: '',
        legalEntityName: ''
    })
    const [tabKey, setTabKey] = useState('1');

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        validator.current.showMessages()
        if (isAuth) {
            Clientagreementmasterfilter({
                payload: {
                    "session_token": authtoken,
                    "request": [
                        "GetClientAgreementReportFilters",
                        {}
                    ]
                },
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        let tempArrayClient = []
        let groupArray = [];
        let tempArrayBusiness = [];
        let businessArray = [];
        let legalArray = [];
        if (filtervalue && filtervalue.country != '') {
            clientagreementresult.domains && clientagreementresult.domains.length > 0 && clientagreementresult.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(filtervalue.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name
                    }
                    domainArray.push(tempArray)
                }
            })

            clientagreementresult.client_group_master && clientagreementresult.client_group_master.length > 0 && clientagreementresult.client_group_master.map((grouplist) => {
                const checkClientGroupExists = grouplist.country_ids.includes(parseInt(filtervalue.country));
                if (checkClientGroupExists) {
                    tempArrayClient = {
                        'group_id': grouplist.group_id,
                        'group_name': grouplist.group_name
                    }
                    groupArray.push(tempArrayClient)
                }
            })

            clientagreementresult.business_groups && clientagreementresult.business_groups.length > 0 && clientagreementresult.business_groups.map((businesslist) => {
                const checkBusinessExists = businesslist.country_ids.includes(parseInt(filtervalue.country));
                if (checkBusinessExists) {
                    tempArrayBusiness = {
                        'business_group_id': businesslist.business_group_id,
                        'business_group_name': businesslist.business_group_name
                    }
                    businessArray.push(tempArrayBusiness)
                }
            })

            let getLegal = _.filter(clientagreementresult.unit_legal_entity && clientagreementresult.unit_legal_entity.length > 0 && clientagreementresult.unit_legal_entity, { country_id: parseInt(filtervalue.country) })

            setfiltervalue({
                ...filtervalue,
                domaindata: domainArray,
                groupdata: groupArray,
                busineesgroupdata: businessArray,
                legalentitydata: getLegal//[].concat.apply([], legalArray)

            })
        }
    }, [filtervalue.country])

    const getbusiness_legal = (data) => {
        let getBusinessNew = _.filter(clientagreementresult.business_groups && clientagreementresult.business_groups.length > 0 && clientagreementresult.business_groups, { client_id: parseInt(data) })
        let getLegalnew = _.filter(clientagreementresult.unit_legal_entity && clientagreementresult.unit_legal_entity.length > 0 && clientagreementresult.unit_legal_entity, { client_id: parseInt(data) })
        setfiltervalue({
            ...filtervalue,
            group: data,
            busineesgroupdata: getBusinessNew,
            legalentitydata: getLegalnew//[].concat.apply([], legalArray)

        })

    }

    const getlegal = (data) => {
        let getLegalUpdated = _.filter(clientagreementresult.unit_legal_entity && clientagreementresult.unit_legal_entity.length > 0 && clientagreementresult.unit_legal_entity, { business_group_id: parseInt(data), client_id: parseInt(filtervalue.group) })
        setfiltervalue({
            ...filtervalue,
            businessgroup: data,
            legalentitydata: getLegalUpdated//[].concat.apply([], legalArray)

        })
    }

    const exportdata = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {

            const passpay = {
                "session_token": authtoken,
                "request": [
                    "GetClientAgreementReportData",
                    {
                        "country_id": filtervalue.country != "" ? parseInt(filtervalue.country) : null,
                        "client_id": filtervalue.group != "" ? parseInt(filtervalue.group) : null,
                        "business_group_id": filtervalue != "" ? parseInt(filtervalue.businessgroup) : null,
                        "legal_entity_id": filtervalue != "" ? parseInt(filtervalue.legalentity) : null,
                        "domain_id_optional": filtervalue != "" ? parseInt(filtervalue.domain) : null,
                        "contract_from_optional": filtervalue.contractfrom ? filtervalue.contractfrom : null,
                        "contract_to_optional": filtervalue.contractto ? filtervalue.contractto : null,
                        "csv": true,
                        "from_count": 0,
                        "page_count": 25,
                        "country_name": filtervalue.countryName ? filtervalue.countryName : null
                    }
                ]
            }
            const encryptkey = localStorage.getItem('formkey')
            let encoded = base64_decode(encryptkey);
            const data1 = EncryptDecrypt.encryptdata(passpay, encoded);
            const head = {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            }
            axios.post(`${url.CommonURL}/api/techno_report`, data1, {
                headers: head
            }).then((response) => {
                const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response.data, encoded))
                if (decryptData[0] == 'ExportToCSVSuccess') {
                    // window.open(process.env.REACT_APP_API_URL + decryptData[1].link);
                    window.open(process.env.REACT_APP_API_DOWN_URL + decryptData[1].link);
                } else {
                    Toaster.error(decryptData[0])
                }
            }).catch((error) => {
                Toaster.error('Unable to Perform. Kindly Try again Later')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                })
            })


        }

    }

    const loaddata = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            setFilterTaskAcc(false);
            setExitCollapse(true);
            setFilterModalVisible(false);
            const passpay = {
                "session_token": authtoken,
                "request": [
                    "GetClientAgreementReportData",
                    {
                        "country_id": filtervalue.country != "" ? parseInt(filtervalue.country) : null,
                        "client_id": filtervalue.group != "" ? parseInt(filtervalue.group) : null,
                        "business_group_id": filtervalue != "" ? parseInt(filtervalue.businessgroup) : null,
                        "legal_entity_id": filtervalue != "" ? parseInt(filtervalue.legalentity) : null,
                        "domain_id_optional": filtervalue != "" ? parseInt(filtervalue.domain) : null,
                        "contract_from_optional": filtervalue.contractfrom ? filtervalue.contractfrom : null,
                        "contract_to_optional": filtervalue.contractto ? filtervalue.contractto : null,
                        "csv": false,
                        "from_count": 0,
                        "page_count": 25,
                        "country_name": filtervalue.countryName ? filtervalue.countryName : null
                    }
                ]
            }

            GetClientAgreementReportData({
                payload: passpay,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        let uniqueGroup = []
        if (clientagreementresultdata.client_agreement_list && clientagreementresultdata.client_agreement_list.length > 0) {
            for (let i = 0; i < clientagreementresultdata.client_agreement_list.length; i++) {
                uniqueGroup.push(clientagreementresultdata.client_agreement_list[i].group_name)
            }
            const temp = {};
            for (const array of uniqueGroup) {
                temp[array] = true;
            }
            const uniquevalue = [];
            for (const tempvalue in temp) {
                let value = _.find(clientagreementresultdata.client_agreement_list, { group_name: tempvalue })
                uniquevalue.push({
                    'group_name': value.group_name,
                    'business_group_name': value.business_group_name,
                    'group_admin_email': value.group_admin_email
                });
            }
            setparentvalue(uniquevalue);
            const finalArrayParent = [...new Set(uniqueGroup)];
            console.log(clientagreementresultdata.client_agreement_list, 'uniquevalue')

            let finalTempArr = [];
            for (let k = 0; k < finalArrayParent.length; k++) {
                let finalValue = _.filter(clientagreementresultdata.client_agreement_list, { group_name: finalArrayParent[k] })
                finalTempArr.push(finalValue)

            }
            setfinalvalue([].concat.apply([], finalTempArr))
        }
    }, [clientagreementresultdata.client_agreement_list])

    const filterHandle = () => {
        setFilterTaskAcc(false);
        setFilterModalVisible(true);
    };


    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const columns = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            align: "center",
            width: "30%"
        },
        {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            align: "center",
            width: "30%"
        },
        {
            title: "Total Units",
            dataIndex: "domain_total_unit",
            key: "domain_total_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Used Units",
            dataIndex: "domain_used_unit",
            key: "domain_used_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Date of Agreement Inception",
            dataIndex: "activation_date",
            key: "activation_date",
            align: "center",
            width: "20%"
        }
    ]

    const columnslitigation = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            align: "center",
        },
        {
            title: "Total Units",
            dataIndex: "ltg_domain_total_unit",
            key: "ltg_domain_total_unit",
            align: "center",
        },
        {
            title: "Used Units",
            dataIndex: "ltg_domain_used_unit",
            key: "ltg_domain_used_unit",
            align: "center",
        },
    ]

    const columnsvendor = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            align: "center",
            width: "30%"
        },
        {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            align: "center",
            width: "30%"
        },
        {
            title: "Total Units",
            dataIndex: "vndr_domain_total_unit",
            key: "vndr_domain_total_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Used Units",
            dataIndex: "vndr_domain_used_unit",
            key: "vndr_domain_used_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Date of Agreement Inception",
            dataIndex: "activation_date",
            key: "activation_date",
            align: "center",
            width: "20%",
        }
    ]

    useEffect(() => {
        if (pageState == true) {
            const passpay1 = {
                "session_token": authtoken,
                "request": [
                    "GetClientAgreementReportData",
                    {
                        "country_id": filtervalue.country != "" ? parseInt(filtervalue.country) : null,
                        "client_id": filtervalue.group != "" ? parseInt(filtervalue.group) : null,
                        "business_group_id": filtervalue != "" ? parseInt(filtervalue.businessgroup) : null,
                        "legal_entity_id": filtervalue != "" ? parseInt(filtervalue.legalentity) : null,
                        "domain_id_optional": filtervalue != "" ? parseInt(filtervalue.domain) : null,
                        "contract_from_optional": filtervalue.contractfrom ? filtervalue.contractfrom : null,
                        "contract_to_optional": filtervalue.contractto ? filtervalue.contractto : null,
                        "csv": false,
                        "from_count": Math.max(((current - 1) * pageSize), 0),
                        "page_count": pageSize,
                        "country_name": filtervalue.countryName ? filtervalue.countryName : null
                    }
                ]
            }

            GetClientAgreementReportData({
                payload: passpay1,
                paramid: paramid
            })
            setIndex(pageSize * (current - 1))
        }
    }, [current, pageSize, pageState])
    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <div className="page-wrapper" id="page-wrapper">
            <div className='back-to-top'>
                        <ScrollButton />
                    </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row antable-head1">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>Client Agreement Master Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {filterTaskAcc == false ?
                        <div className="col-lg-6 d-flex my-1 justify-content-end align-items-center">
                            {/* <div style={{ display: "initial" }}>
                                <div className="d-inline" style={{ background: '#1890ff', color: "white", borderRadius: '15px', padding: '5px' }}>
                                    <p className=" mx-1 d-inline" >
                                        Country Name :
                                    </p><h4 className="text-white d-inline mt-1 fw-bolder">{filtervalue.countryName}</h4> </div>
                            </div> */}
                            &nbsp;
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "15px",
                                    display: filterTaskAcc ? "none" : "block",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button onClick={(e) => { fullscreenMode(); }}
                                className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title="Full Screen Mode"
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                        : false}
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "0px 0px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Client Agreement Master Report" key={key}>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        autoFocus={true}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={filtervalue.country || undefined}
                                                        onChange={(data, value) => {
                                                            console.log(value, 'valuevalue')
                                                            if (data != undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    country: data,
                                                                    countryName: value.children
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    country: '',
                                                                    group: '',
                                                                    domain: '',
                                                                    businessgroup: '',
                                                                    legalentity: ''
                                                                })
                                                            }

                                                        }}
                                                    >
                                                        {clientagreementresult && clientagreementresult.countries && clientagreementresult.countries.length > 0 && clientagreementresult.countries.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        filtervalue.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <label><b>Group Name:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Group"
                                                        onChange={(data) => {
                                                            if (data != undefined) {
                                                                getbusiness_legal(data)

                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    group: '',
                                                                    businessgroup: '',
                                                                    legalentity: ''
                                                                })
                                                            }
                                                        }}

                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={filtervalue.group || undefined}
                                                    >
                                                        {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.group_id}>
                                                                    {item.group_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <label><b>Business Group:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Business Group"
                                                        onChange={(data) => {
                                                            if (data != undefined) {
                                                                getlegal(data)

                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    businessgroup: '',
                                                                    legalentity: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={filtervalue.businessgroup || undefined}
                                                    >
                                                        {filtervalue.busineesgroupdata && filtervalue.busineesgroupdata.length > 0 && filtervalue.busineesgroupdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.business_group_id}>
                                                                    {item.business_group_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mt-1'>
                                            <label><b>Legal Entity:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        onChange={(data) => {
                                                            if (data != undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    legalentity: data
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    legalentity: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={filtervalue.legalentity || undefined}
                                                    >
                                                        {filtervalue.legalentitydata && filtervalue.legalentitydata.length > 0 && filtervalue.legalentitydata.map((item, i) => {
                                                            return (
                                                                <Option key={item.legal_entity_id}>
                                                                    {item.legal_entity_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mt-1'>
                                            <label><b>Domain:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Domain Name"

                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        onChange={(data) => {
                                                            if (data != undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    domain: data
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    domain: ''
                                                                })
                                                            }
                                                        }}
                                                        value={filtervalue.domain || undefined}
                                                    >
                                                        {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                                            return (
                                                                <Option key={item.domain_id}>
                                                                    {item.domain_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mt-1'>
                                            <label><b>Contract From:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <DatePicker style={{ width: '100%' }} 
                                                    allowClear={true} 
                                                    placeholder='DD-MM-YYYY'
                                                    format={'DD-MMM-YYYY'} 
                                                    value={filtervalue.contractfrom ? moment(filtervalue.contractfrom) : ''}
                                                        onChange={(date, dateString) => {
                                                            setfiltervalue({
                                                                ...filtervalue,
                                                                contractfrom: dateString,
                                                                to_date: ''
                                                            })
                                                        }}
                                                        
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mt-1'>
                                            <label><b>Contract To:</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <DatePicker style={{ width: '100%' }} 
                                                    format={'DD-MMM-YYYY'}
                                                    placeholder='DD-MM-YYYY'
                                                    value={filtervalue.contractto ? moment(filtervalue.contractto) : ''}
                                                        disabledDate={(current) => {
                                                            return moment(filtervalue.contractfrom).add(0, 'days') >= current
                                                        }}
                                                        onChange={(date, dateString) => {
                                                            setfiltervalue({
                                                                ...filtervalue,
                                                                contractto: dateString,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='text-center'>
                                        <Button type="primary" shape="round" className="addbutton" icon={<EyeOutlined />} size="default" onClick={(e) => {
                                            loaddata()
                                        }}>Show</Button>&nbsp;
                                        <Button type="primary" shape="round" className="addbutton" icon={< ExportOutlined />} size="default" onClick={(e) => {
                                            exportdata()
                                        }}>Export</Button>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        {exitCollapse == true ?
                            <>
                                <div  style={{ padding: "5px 5px" }}>

                                    <div className='ant-table-wrapper'>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                        <div className="ant-table-title py-2 px-3">{filtervalue.countryName ? <><b>Country : {filtervalue.countryName}</b></> : null}</div>
                                            <div className='ant-table-container px-2 pb-2'>
                                                <div className='ant-table-content'>
                                                    <table style={{ tableLayout: 'auto' }} className={"table-fixed"}>
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: "10px" }}>#</th>
                                                                <th className='ant-table-cell' style={{ width: "200px" }}>Legal Entity</th>
                                                                <th className='ant-table-cell' style={{ width: "90px" }}>Business Groups</th>
                                                                <th className='ant-table-cell' style={{ width: "100px" }}>Group Admin / LE Details</th>
                                                                <th className='ant-table-cell' style={{ width: "200px" }}>Product</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ant-table-tbody'>
                                                            {
                                                                parentvalue && parentvalue.length > 0 ? parentvalue.map((parentList, li) => {
                                                                    return <Fragment key={li}>
                                                                        <tr>
                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'><b>{parentList.group_name}</b></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'><b>{parentList.business_group_name}</b></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'>{parentList.group_admin_email}</td>
                                                                            <td className='ant-table-row ant-table-row-level-0'></td>

                                                                        </tr>
                                                                        {
                                                                            finalvalue && finalvalue.length > 0 && finalvalue.map((finallist, keyl) => {
                                                                                console.log(finallist, 'finallist');
                                                                                if (finallist.group_name == parentList.group_name) {
                                                                                    return <Fragment key={keyl}>
                                                                                        <tr>
                                                                                            <td className='ant-table-row ant-table-row-level-0'><center>{index + 1}</center></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'><a href="javascript:;" onClick={() => {
                                                                                                const passpay = {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "GetAggrementReportDisplayPopup",
                                                                                                        {
                                                                                                            "le_id": Number(finallist.legal_entity_id),
                                                                                                            "domain_id_optional": filtervalue.domain ? Number(filtervalue.domain) : null
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                                getAgreementReport({
                                                                                                    payload: passpay,
                                                                                                    paramid: paramid
                                                                                                })
                                                                                                setPopUpDetails({
                                                                                                    ...popUpDetails,
                                                                                                    groupName: finallist.group_name,
                                                                                                    shortName: finallist.short_name,
                                                                                                    groupAdmin: finallist.group_admin_email,
                                                                                                    legalEntityName: finallist.legal_entity_name,

                                                                                                })
                                                                                                showModal()
                                                                                                setTabKey('1');
                                                                                            }}>{finallist.legal_entity_name}</a></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'>{finallist.product_name}</td>
                                                                                        </tr>
                                                                                        <span hidden>{index = index + 1}</span>
                                                                                    </Fragment>
                                                                                }
                                                                            })
                                                                        }
                                                                    </Fragment>
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal title={`Client Groups : ${popUpDetails.groupName}`} className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                        <div className='card card-body rounded-3' style={{padding: '13px', marginBottom: '15px'}}>
                                            <div className='row py-1'>
                                                <div className='col-4'>
                                                    <label htmlFor=""><b>Short Name :</b><span style={{ marginLeft: '5px' }}>{popUpDetails.shortName}</span></label>
                                                </div>
                                                <div className='col-6'>
                                                    <label htmlFor=""><b>Group Admin :</b><span style={{ marginLeft: '5px' }}>{popUpDetails.groupAdmin}</span></label>
                                                </div>
                                                <div className='row py-1'>
                                                    <div className="col-12">
                                                        <label htmlFor=""><b>View Only Licence(s) :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.no_of_view_licence}</span></label>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-4">
                                                        <label htmlFor=""><b>IP Restriction :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.is_apply_ip_settings === 0 ? 'Disabled' : 'Enabled'}</span></label>
                                                    </div>
                                                    <div className="col-4" style={{paddingLeft: '25px'}}>
                                                        <label htmlFor=""><b>Remarks :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.remarks ? clientAgreementPopUpData.remarks : 'NA'}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card card-body rounded-3' style={{padding: '13px', marginBottom: '15px'}}>
                                            <div className='row'>
                                                <div className="col-3">
                                                    <label htmlFor=""><b>Country :</b><span style={{ marginLeft: '5px' }}>{filtervalue.countryName}</span></label>
                                                </div>
                                                {clientAgreementPopUpData.bg_name ? 
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Business Group :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.bg_name}</span></label>
                                                </div> : null}
                                                <div className="col">
                                                    <label htmlFor=""><b>Legal Entity :</b><span style={{ marginLeft: '5px' }}>{popUpDetails.legalEntityName}</span></label>
                                                </div>
                                            </div>
                                            <div className='row my-1'>
                                                <div className="col-3 ">
                                                    <label htmlFor=""><b>File Space :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.file_space_report} GB</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Used File Space :</b><span style={{ marginLeft: '5px' }}>{clientAgreementPopUpData.used_file_space_report} GB</span></label>
                                                </div>
                                            </div>
                                            <hr className='my-1'/>
                                        {/* </div> */}
                                        <Tabs defaultActiveKey={'1'} activeKey={tabKey} onChange={(key) => setTabKey(key)}>
                                            {clientAgreementPopUpData.org_list_report && clientAgreementPopUpData.org_list_report.length > 0 ?
                                                    <TabPane tab="Compfie" key="1">
                                                        <h6>Compfie- Contract Details</h6>
                                                        <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.used_licence}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                        <br />
                                                        <h6>Compfie- Domain &Organization Details</h6>
                                                        <Table
                                                            columns={columns}
                                                            dataSource={clientAgreementPopUpData.org_list_report}
                                                            bordered
                                                            pagination={false}
                                                        // scroll={{y: "100"}}
                                                        >
                                                        </Table>
                                                    </TabPane>

                                                : false}
                                            {clientAgreementPopUpData.ltgs_info_report && clientAgreementPopUpData.ltgs_info_report.length > 0 ?
                                                <TabPane tab="Compfie Litigation" key="2">
                                                    <h6>Compfie Litigation- Contract Details</h6>
                                                    <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Approval Status</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.ltgs_info_report[0].contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.ltgs_info_report[0].contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.ltgs_info_report[0].no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.ltgs_info_report[0].used_licn}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.ltgs_info_report[0].ltgs_apprvl == 1 ? 'Yes' : 'No'}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                    <br />
                                                    <Table
                                                        columns={columnslitigation}
                                                        dataSource={clientAgreementPopUpData.ltgs_info_report[0].ltgs_domain_info_report}
                                                        bordered
                                                        pagination={false}
                                                    // scroll={{y: "100"}}
                                                    >
                                                    </Table>
                                                </TabPane>
                                                : false}
                                            {clientAgreementPopUpData.vndr_info_report && clientAgreementPopUpData.vndr_info_report.length > 0 ?
                                                    <TabPane tab="Compfie Vendor" key="3">
                                                        <h6>Compfie Vendor- Contract Details</h6>
                                                        <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.vndr_info_report[0].contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.vndr_info_report[0].contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.vndr_info_report[0].no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{clientAgreementPopUpData.vndr_info_report[0].used_licn}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                        <br />
                                                        <Table
                                                            columns={columnsvendor}
                                                            dataSource={clientAgreementPopUpData.vndr_info_report[0].vndr_domain_info_report}
                                                            bordered
                                                            pagination={false}
                                                        // scroll={{y: "100"}}
                                                        >
                                                        </Table>

                                                    </TabPane>
                                                : false}
                                        </Tabs></div>
                                    </Modal>
                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {(current - 1) * pageSize + 1} to {index} of {clientagreementresultdata.total_count} entries</label>
                                            </div> : ''}
                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                }}
                                                pageSizeOptions={[25, 50, 100]}
                                                hideOnSinglePage={clientagreementresultdata.total_count > 25 ? false : true}
                                                defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    console.log(pageSizes, pageSize, page, 'pageSizes')
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={clientagreementresultdata.total_count}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : false}
                    </div>
                </div>
            </div >
            <Modal title="Client Agreement Master Report Filter" className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} footer={false} open={filterModalVisible} onCancel={setcancelFilter}>
                <div className='row'>
                    <div className='col-4'>
                        <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="default"
                                    placeholder="Enter Country"

                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={filtervalue.country || undefined}
                                    onChange={(data, value) => {
                                        console.log(value, 'valuevalue')
                                        if (data != undefined) {
                                            setfiltervalue({
                                                ...filtervalue,
                                                country: data,
                                                countryName: value.children
                                            })
                                        } else {
                                            setfiltervalue({
                                                ...filtervalue,
                                                country: '',
                                                group: '',
                                                domain: '',
                                                businessgroup: '',
                                                legalentity: ''
                                            })
                                        }

                                    }}
                                >
                                    {clientagreementresult && clientagreementresult.countries && clientagreementresult.countries.length > 0 && clientagreementresult.countries.map((item, i) => {
                                        return (
                                            <Option key={item.country_id}>
                                                {item.country_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {validator.current.message(
                                    'country',
                                    filtervalue.country,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Country Name Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <label><b>Group Name:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="default"
                                    placeholder="Enter Group"
                                    onChange={(data) => {
                                        if (data != undefined) {
                                            getbusiness_legal(data)

                                        } else {
                                            setfiltervalue({
                                                ...filtervalue,
                                                group: '',
                                                businessgroup: '',
                                                legalentity: ''
                                            })
                                        }
                                    }}

                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={filtervalue.group || undefined}
                                >
                                    {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                        return (
                                            <Option key={item.group_id}>
                                                {item.group_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <label><b>Business Group:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    onChange={(data) => {
                                        if (data != undefined) {
                                            getlegal(data)

                                        } else {
                                            setfiltervalue({
                                                ...filtervalue,
                                                businessgroup: '',
                                                legalentity: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={filtervalue.businessgroup || undefined}
                                >
                                    {filtervalue.busineesgroupdata && filtervalue.busineesgroupdata.length > 0 && filtervalue.busineesgroupdata.map((item, i) => {
                                        return (
                                            <Option key={item.business_group_id}>
                                                {item.business_group_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Legal Entity:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="default"
                                    placeholder="Enter Legal Entity"
                                    onChange={(data) => {
                                        if (data != undefined) {
                                            setfiltervalue({
                                                ...filtervalue,
                                                legalentity: data
                                            })
                                        } else {
                                            setfiltervalue({
                                                ...filtervalue,
                                                legalentity: ''
                                            })
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={filtervalue.legalentity || undefined}
                                >
                                    {filtervalue.legalentitydata && filtervalue.legalentitydata.length > 0 && filtervalue.legalentitydata.map((item, i) => {
                                        return (
                                            <Option key={item.legal_entity_id}>
                                                {item.legal_entity_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Domain:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="default"
                                    placeholder="Enter Domain Name"

                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    onChange={(data) => {
                                        if (data != undefined) {
                                            setfiltervalue({
                                                ...filtervalue,
                                                domain: data
                                            })
                                        } else {
                                            setfiltervalue({
                                                ...filtervalue,
                                                domain: ''
                                            })
                                        }

                                    }}
                                    value={filtervalue.domain || undefined}
                                >
                                    {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                        return (
                                            <Option key={item.domain_id}>
                                                {item.domain_name}
                                            </Option>
                                        );
                                    })}
                                </Select>

                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Contract From:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <DatePicker style={{ width: '100%' }} 
                                allowClear={true}
                                placeholder='DD-MM-YYYY' 
                                getPopupContainer={trigger => trigger.parentNode}
                                format={'DD-MMM-YYYY'} 
                                value={filtervalue.contractfrom ? moment(filtervalue.contractfrom) : ''}
                                    onChange={(date, dateString) => {
                                        setfiltervalue({
                                            ...filtervalue,
                                            contractfrom: dateString,
                                            to_date: ''
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Contract To:</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <DatePicker style={{ width: '100%' }} 
                                placeholder='DD-MM-YYYY'
                                getPopupContainer={trigger => trigger.parentNode} 
                                format={'DD-MMM-YYYY'} value={filtervalue.contractto ? moment(filtervalue.contractto) : ''}
                                    disabledDate={(current) => {
                                        return moment(filtervalue.contractfrom).add(0, 'days') >= current
                                    }}
                                    onChange={(date, dateString) => {
                                        setfiltervalue({
                                            ...filtervalue,
                                            contractto: dateString,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <Button type="primary" shape="round" className="addbutton custom-color-modal-button" icon={<EyeOutlined />} size="default" onClick={(e) => {
                            loaddata()
                        }}>Show</Button>&nbsp;
                        <Button type="primary" shape="round" className="addbutton custom-color-modal-button" icon={<ExportOutlined />} size="default" onClick={(e) => {
                            exportdata()
                        }}>Export</Button>
                    </div>
                </div>
            </Modal>
        </div >


    )
}
export default connect(mapStateToProps, {
    Clientagreementmasterfilter,
    GetClientAgreementReportData,
    getAgreementReport
})(ClientAggrementMasterReport);