import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal, Space, Descriptions, Badge, Empty } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { ExclamationCircleTwoTone, PlayCircleOutlined, DownloadOutlined, HistoryOutlined, EyeOutlined } from '@ant-design/icons';
import { ApproveStatutoryMappingsFilters, ApproveStatutoryMappings, approvestatutorymappingfinal, ComplianceInfo, GetComplianceHistory } from '../../Store/Action/Transactions/ApproveStatutoryMapping';
import Taskcategoryedit from './Taskcategoryedit';
import Taskcategoryadd from './Taskcategoryadd';
import {
    LikeOutlined, DislikeOutlined,
    PlusOutlined, InfoCircleOutlined, StopOutlined, SearchOutlined, PlusCircleOutlined, CloseCircleOutlined, FilterOutlined, FullscreenOutlined, UpOutlined
} from '@ant-design/icons';
import { COMPLIANCE_INFO_RESET } from './../../../src/Store/types/index'
import { Toaster } from '../../Libs/Toaster';
import url from './../../Libs/URL'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";


const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Approvetaskcategorymapping.getapprovetaskcategorymappingfilterdata,
    showdatas: state.Approvetaskcategorymapping.showdata,
    showtaskcompliances: state.Approvetaskcategorymapping.showtaskcompliance,
    approvestatutorymappingdata: state.ApproveStatutoryMapping.approvestatutorymapping,
    // history: state.ApproveStatutoryMapping.history
})

const ApproveStatutoryMapping = ({
    ApproveStatutoryMappingsFilters,
    lists,
    showdatas,
    showtaskcompliances,
    ComplianceInfo,
    approvestatutorymappingdata: { approvestatutorymappingfilter, showdata, compliance, success, history },
    ApproveStatutoryMappings,
    approvestatutorymappingfinal,
    GetComplianceHistory,
    // history
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [complianceview, setComplianceView] = useState(false)
    const [subcategory, setSubcategory] = useState([]);
    const [tabledata, setTableData] = useState([]);
    const [statutorynature, setStatutoryNature] = useState([]);
    const [domain, setdomain] = useState([]);
    const [organization, setOrganizations] = useState([]);
    const [temppayload, settemppayload] = useState([]);
    let [count, setcount] = useState(0)
    const [legendshow, setlegendshow] = useState('none');
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const [approvestatutorymapping, setApproveStatutoryMapping] = useState({
        country: '',
        countryName: '',
        domain: '',
        domainname: '',
        organization: '',
        organizationname: '',
        statutoryid: '',
        statutoryname: '',
        userid: '',
        username: ''
    })
    const dispatch = useDispatch();

    const [activeCount,setActiveCount] = useState()
    const [inActiveCount,setInActiveCount] = useState()
    const [complianceModal, setComplianceModal] = useState({
        statutorynature: '',
        oldstatutoryprovision: '',
        statutoryprovision: '',
        compliance_task: '',
        description: '',
        olddescription: '',
        format: '',
        penalconsequences: '',
        compfielaw: '',
        frequency: '',
        occurrence: '',
        location: '',
        oldlocation: '',
        link: '',
        taskstatus: '',
        effectivedate: "",
        statu_due: "",
        extn_due: "",
        remarks: "",
        criticality: '',
        notification_url: '',
        comp_id: '',
        oldcompliancetask: '',
        oldpenalconsequences: '',
        url: '',
    })
    const [show, setShow] = useState(false)
    const [actionvalue, setActionvalue] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [triggerValidation, setTriggerValidation] = useState([])
    const [remarks, setRemarks] = useState([])
    const [reason, setReason] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [page, setPage] = useState(1);
    const { Search, TextArea } = Input;
    var type = '';
    const { Panel } = Collapse;
    const { Option } = Select;
    const [historyModalOpen, setHistoryModalOpen] = useState(false)
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    useEffect(() => {
        if (showdata.approv_mappings) {
            console.log(showdata.approv_mappings.length, 'showdata')

        }
    }, [showdata.approv_mappings])

    useEffect(() => {
        if (compliance && compliance.statutory_nature_name) {
            setComplianceModal({
                statutorynature: compliance.statutory_nature_name,
                statutoryprovision: compliance.s_pro,
                oldstatutoryprovision: compliance.old_s_pro,
                compliance_task: compliance.c_task,
                description: compliance.descrip,
                olddescription: compliance.old_descrip,
                penalconsequences: compliance.p_cons,
                compfielaw: compliance.c_law_ids,
                frequency: compliance.freq,
                occurrence: compliance.summary,
                location: compliance.locat,
                oldlocation: compliance.old_locations,
                link: compliance.refer,
                taskstatus: compliance.is_active == true ? "Active" : "Inactive",
                effectivedate: compliance.effective_date,
                criticality: compliance.new_criticality_name,
                statu_due: compliance.statu_due_in,
                extn_due: compliance.extn_due_date,
                remarks: compliance.remarks,
                notification_url: compliance.notification_url,
                comp_id: compliance.comp_id,
                oldcompliancetask: compliance.old_c_task,
                oldpenalconsequences: compliance.old_p_cons,
                url: compliance.url



            })
            // dispatch({
            //     type: COMPLIANCE_INFO_RESET
            // });
        }
    }, [compliance]);

    useEffect(() => {
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetApproveStatutoryMappingsFilters",
                    {}
                ]
            }
            ApproveStatutoryMappingsFilters({
                payload: payload,
                caller: paramid,
            })

        }
    }, [isAuth]);

    useEffect(() => {
        if (success == "ApproveStatutoryMappingSuccess") {
            showdatavalue()
        }
    }, [success]);

    const viewhistory = (record) => {
        let payloadhistory = {
            "session_token": authtoken,
            "request": [
                "GetComplianceHistory",
                {
                    "comp_id": parseInt(record.comp_id)
                }
            ]
        }
        GetComplianceHistory({
            payload: payloadhistory,
            paramid: paramid
        })
        setHistoryModalOpen(true)
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
    //     setSelectedKeys(e.target.value ? [e.target.value] : [])
    //     confirm({ closeDropdown: false });
    //     // setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                        handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '40px',
            render: (text, record, index) => {
                return <>{((page - 1) * 10 + index + 1)}
                    {record.trans_type == "new" ?
                        <Tooltip title={"New"}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip>
                        : record.trans_type == "extn" ?
                            <Tooltip title={"Extension"}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip>
                            : record.trans_type == "edit" ?
                                <Tooltip title={"Edit/Amendment"}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip>
                                : ''}
                </>
            }
        },
        {
            title: 'Organization',
            dataIndex: 'org_names',
            key: 'org_names',
            ...getColumnSearchProps('org_names', 'Organization'),
            // ellipsis: true,
            width: '100px',
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span title={text.toString()} style={{ color: "red" }}>{text.toString()}</span>
                } else {
                    return (text.toString())
                }
            }
        },
        {
            title: 'Statutory Nature',
            dataIndex: 's_n_name',
            key: 's_n_name',
            ...getColumnSearchProps('s_n_name', 'Statutory Nature'),
            width: '9%',
            // align:'center',
            // ellipsis: true,
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span title={text} style={{ color: "red" }}>{text}</span>
                } else {
                    return (text)
                }
            }

        },
        {
            title: 'Statutory',
            dataIndex: 'map_text',
            key: 'map_text',
            width: '150px',
            ...getColumnSearchProps('map_text', 'Statutory'),
            // ellipsis: true,
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span title={text} style={{ color: "red" }}>{text}</span>
                } else {
                    return (text)
                }
            }

        },
        {
            title: <label>Action <span style={{ color: 'red' }}>*</span></label>,
            dataIndex: false,
            key: false,
            // ellipsis: true,
            width: '17%',
            align: 'center',
            render: (text, record, index) => {
                return (<>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                {record.history_count > 1 ?
                                    <Tooltip title='Click here for History'>
                                        <span className="btn btn-sm btn-light-primary
                                text-primary btn-circle d-flex align-items-center justify-content-center" 
                                onClick={(e) => viewhistory(record)}>
                                            {/* <HistoryOutlined onClick={(e) => viewhistory(record)} /> */}
                                            <HistoryOutlined />
                                        </span>
                                    </Tooltip>
                                    : false}
                            </div>&nbsp;&nbsp;
                            <div className='col-lg-9'>
                                <Select name="action" className='form-control' id="action"
                                    onChange={(e) => {
                                        if (e != 0) {
                                            let temp = actionvalue
                                            temp.push(record)
                                            let unique = _.uniqBy(temp && temp.length > 0 && temp, function (e) {
                                                return e.comp_id;
                                            });
                                            setActionvalue([...unique])
                                            if (e == 2) {
                                                reason[index] = {
                                                    "text": "NO DATA",
                                                    "id": record.comp_id,
                                                    "compliance": record.c_task
                                                }
                                                setReason([...reason])
                                            } else {
                                                reason[index] = {
                                                    "text": '',
                                                    "id": record.comp_id,
                                                    "compliance": record.c_task
                                                }
                                                setReason([...reason])
                                            }
                                        } else {
                                            let temparr = actionvalue
                                            if (temparr && temparr.length > 0) {
                                                for (let i in temparr) {
                                                    if (temparr[i].comp_id == record.comp_id)
                                                        temparr.splice(i, 1)
                                                }
                                            }
                                            setActionvalue([...temparr])
                                        }
                                        remarks[index] = Number(e)
                                        // reason[index] = {
                                        //     "text": 0,
                                        //     "id": record.comp_id
                                        // }
                                        // setReason([...reason])
                                        setRemarks([...remarks])
                                    }}
                                    defaultValue={"Select"}
                                // placeholder = {"Select"}
                                >
                                    <Option value="0">Select</Option>
                                    <Option value="2">Approve</Option>
                                    <Option value="3">Approve & Notify</Option>
                                    <Option value="4">Reject</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </>
                );
            }
        },
        {
            title: <label>Reason <span style={{ color: 'red' }}>*</span></label>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '95px',
            render: (text, record, index) => {
                for (let i in remarks) {
                    if (remarks[i] == 3 || remarks[i] == 4) {
                        if (index == i) {
                            return (
                                <div class="d-flex align-items-end">
                                    <TextArea name="remarks" className='form-control' placeholder='Enter Reason'
                                        // value={recalldata.remarks || undefined} 
                                        maxLength={500}
                                        id="remarks" cols="5" rows="0"
                                        onKeyDown={(event) => {
                                            if (event.code === 'Space') event.preventDefault()
                                        }}
                                        onBlur={(e) => {
                                            reason[index] =
                                            {
                                                "text": e.target.value,
                                                "id": reason[index].id,
                                                "compliance": record.c_task
                                            }
                                            setReason([...reason])
                                        }}
                                    >
                                    </TextArea>
                                </div>
                            );
                        }
                    } else {
                        if (index == i) {
                            return null
                        }
                    }
                }
            }
        },
        {
            title: 'Compliance',
            dataIndex: 'false',
            key: 'false',
            // ellipsis: true,
            width: '105px',
            align: 'center',
            render: (text, record, index) => {
                return (<Link onClick={() => {
                    ComplianceInfo({
                        payload: {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceInfo",
                                {
                                    "comp_id": record.comp_id,
                                    "extn_id": record.extn_id,
                                }
                            ]
                        },
                        paramid: paramid
                    })
                    showValue()
                }} >{record.c_task}</Link>);
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            align: 'center',
            width: '70px',
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span style={{ color: "red" }}>{record.is_active == true ? 'Active' : 'Inactive'}</span>
                } else {
                    return (record.is_active == true ? 'Active' : 'Inactive')
                }
            }

        },
        {
            title: 'Created By',
            dataIndex: 'c_by',
            key: 'c_by',
            // ellipsis: true,
            width: '90px',
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span title={text} style={{ color: "red" }}>{text}</span>
                } else {
                    return (text)
                }
            }

        },
        {
            title: 'Last updated by',
            dataIndex: 'u_by',
            key: 'u_by',
            // ellipsis: true,
            width: '100px',
            render: (text, record) => {
                if (record.action_type == 3) {
                    return <span title={text} style={{ color: "red" }}>{text}</span>
                } else {
                    return (text)
                }
            }
        },
    ];

    const showValue = () => {
        setComplianceModal({
            ...complianceModal,
            statutorynature: '',
            oldstatutoryprovision: '',
            statutoryprovision: '',
            compliance_task: '',
            description: '',
            olddescription: '',
            format: '',
            penalconsequences: '',
            compfielaw: '',
            frequency: '',
            occurrence: '',
            location: '',
            oldlocation: '',
            link: '',
            taskstatus: '',
            effectivedate: "",
            statu_due: "",
            extn_due: "",
            remarks: "",
            criticality: '',
            notification_url: '',
            comp_id: '',
            oldcompliancetask: '',
            oldpenalconsequences: '',
            url: '',
        })
        setShowValueModal(true);
    };

    useEffect(() => {
        if (actionvalue && actionvalue.length > 0) {
            let temp = []
            let entityArray = [];
            for (let i in actionvalue) {
                entityArray = {
                    "c_name": approvestatutorymapping.countryName,
                    "d_name": approvestatutorymapping.domainname,
                    "s_n_name": actionvalue[i].s_n_name,
                    "map_text": actionvalue[i].map_text,
                    "c_task": actionvalue[i].c_task,
                    "a_s_id": '',
                    "remarks": "",
                    "m_id": actionvalue[i].m_id,
                    "comp_id": actionvalue[i].comp_id,
                    "is_common": false,
                    "u_by": actionvalue[i].u_by,
                    "action_type": actionvalue[i].action_type,
                    "map_ind_ids": actionvalue[i].map_ind_ids,
                    "map_sta_ids": actionvalue[i].map_sta_ids,
                    "map_geo_ids": actionvalue[i].map_geo_ids,
                    "is_active": actionvalue[i].is_active,
                    "trans_type": actionvalue[i].trans_type,
                    "extn_id": actionvalue[i].extn_id
                }
                temp.push(entityArray)
            }
            settemppayload(temp)
        } else {
            settemppayload([])
        }
    }, [actionvalue]);

    // useEffect(() => {
    //     if (temppayload && temppayload.length > 0) {
    //         let temp = []
    //         let entityArray = [];
    //         for (let i in temppayload) {
    //             entityArray = {
    //                 "c_name": temppayload[i].c_name,
    //                 "d_name": temppayload[i].d_name,
    //                 "s_n_name": temppayload[i].s_n_name,
    //                 "map_text": temppayload[i].map_text,
    //                 "c_task": temppayload[i].c_task,
    //                 "a_s_id": '',
    //                 "remarks": "",
    //                 "m_id": temppayload[i].m_id,
    //                 "comp_id": temppayload[i].comp_id,
    //                 "is_common": false,
    //                 "u_by": temppayload[i].u_by,
    //                 "action_type": temppayload[i].action_type,
    //                 "map_ind_ids": temppayload[i].map_ind_ids,
    //                 "map_sta_ids": temppayload[i].map_sta_ids,
    //                 "map_geo_ids": temppayload[i].map_geo_ids,
    //                 "is_active": temppayload[i].is_active,
    //                 "trans_type": temppayload[i].trans_type,
    //                 "extn_id": temppayload[i].extn_id
    //             }
    //             temp.push(entityArray)
    //         }
    //         settemppayload(temp)
    //     } else {
    //         settemppayload([])
    //     }
    // }, [actionvalue]);

    const submitrecord = () => {
        // for (let i in remarks) {
        //     if (remarks[i] != undefined) {
        //         if (remarks[i] != 0) {
        //             if (remarks[i] != 2) {
        //                 if (reason[i] == '') {
        //                     Toaster.error('faileddddd')
        //                 }
        //             }
        //         } else {

        //         }
        //     }
        // }
        if (actionvalue && actionvalue.length > 0) {
            for (let i in reason) {
                // if(reason[i].text != 0){
                if (reason[i] != undefined) {
                    if (reason[i].text == '' || reason[i].text == undefined) {
                        if (reason[i].text == "NO DATA") {
                            triggerValidation[i] = undefined
                            setTriggerValidation([...triggerValidation])
                        } else {
                            triggerValidation[i] = 'Remarks Required for ' + reason[i].compliance
                            setTriggerValidation([...triggerValidation])
                        }
                    } else {
                        // Toaster.error('haii2')
                        triggerValidation[i] = undefined
                        setTriggerValidation([...triggerValidation])
                    }
                }
            }
            if (triggerValidation.filter(Boolean).length == 0) {
                let check = []
                let tempcheck = []
                for (let j in remarks) {
                    if (remarks[j] != undefined) {
                        if (remarks[j] != 2) {
                            check.push(reason[j])
                            tempcheck.push(remarks[j])
                        } else {
                            check.push(
                                {
                                    "text": '',
                                    "id": remarks[j].id,
                                    "compliance": remarks[j].compliance
                                })
                            tempcheck.push(remarks[j])
                        }
                    }
                }

                let tempfinal = []
                let entityArray = [];
                for (let i in temppayload) {
                    entityArray = {
                        "c_name": temppayload[i].c_name,
                        "d_name": temppayload[i].d_name,
                        "s_n_name": temppayload[i].s_n_name,
                        "map_text": temppayload[i].map_text,
                        "c_task": temppayload[i].c_task,
                        "a_s_id": tempcheck[i] ? tempcheck[i] : '',
                        "remarks": check[i].text ? check[i].text : '',
                        "m_id": temppayload[i].m_id,
                        "comp_id": temppayload[i].comp_id,
                        "is_common": false,
                        "u_by": temppayload[i].u_by,
                        "action_type": temppayload[i].action_type,
                        "map_ind_ids": temppayload[i].map_ind_ids,
                        "map_sta_ids": temppayload[i].map_sta_ids,
                        "map_geo_ids": temppayload[i].map_geo_ids,
                        "is_active": temppayload[i].is_active,
                        "trans_type": temppayload[i].trans_type,
                        "extn_id": temppayload[i].extn_id
                    }
                    tempfinal.push(entityArray)
                    approvestatutorymappingfinal({
                        payload: {
                            "session_token": authtoken,
                            "request": [
                                "ApproveStatutoryMapping",
                                {
                                    "s_mappings": tempfinal
                                }
                            ]
                        },
                        paramid: paramid
                    })
                }
            } else {
                Toaster.error(triggerValidation.filter(Boolean)[triggerValidation.filter(Boolean).length - 1]);
            }
            // }
        } else {
            Toaster.error("Action Required");
        }


    }

    const showdatavalue = () => {
        const payload = {
            "session_token": authtoken,
            "request": [
                "GetApproveStatutoryMappings",
                {
                    "a_c_id": Number(approvestatutorymapping.country),
                    "a_d_id": Number(approvestatutorymapping.domain),
                    "a_i_id": approvestatutorymapping.organization ? Number(approvestatutorymapping.organization) : null,
                    "a_s_n_id": approvestatutorymapping.statutoryid ? Number(approvestatutorymapping.statutoryid) : null,
                    "a_u_id": approvestatutorymapping.userid ? Number(approvestatutorymapping.userid) : null,
                    "r_count": 0
                }
            ]
        }

        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            setlegendshow('block');
            setShow(true)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            ApproveStatutoryMappings({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (showdatas) {
            let unique = []
            if (showdatas && showdatas.apptaskcatmapping && showdatas.apptaskcatmapping.length > 0) {
                unique = _.uniqBy(showdatas && showdatas.apptaskcatmapping, function (e) {
                    return e.task_group_id;
                });
            }
            setTableData(unique)
        }
    }, [showdatas])

    useEffect(() => {
        if (showtaskcompliances && showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0) {
            setComplianceView(true)
        }
    }, [showtaskcompliances])

    useEffect(() => {
        if (approvestatutorymapping != '') {
            if (approvestatutorymappingfilter && approvestatutorymappingfilter.domains && approvestatutorymappingfilter.domains.length > 0) {
                let temp = []
                for (let i in approvestatutorymappingfilter.domains) {
                    let check = approvestatutorymappingfilter.domains[i].country_ids.includes(Number(approvestatutorymapping.country))
                    if (check) {
                        temp.push(approvestatutorymappingfilter.domains[i])
                    }
                }
                setdomain(temp)
            }
        }
    }, [approvestatutorymapping.country])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if(showdata && showdata.approv_mappings){
            let active = _.filter(showdata && showdata.approv_mappings,{is_active : true})
            setActiveCount(active.length)
            let deactive = _.filter(showdata && showdata.approv_mappings,{is_active : false})
            setInActiveCount(deactive.length)
        }

    },[showdata && showdata.approv_mappings])

    const Cancelmodal = () => {
        setShowValueModal(false);
    }

    const downloadnotificationfile = (list) => {
        window.open(`${url.CommonURL}/` + list);
    }

    const historycolumns = [
        {
            title: 'Action Type',
            dataIndex: 'h_action',
            key: 'h_action',
            align: 'center',
            width: '10%',
            render: (text, record) => {
                let name;
                if (record.h_change_mode == 1) {
                    name = "Edit";
                } else if (record.h_change_mode == 2) {
                    name = "Amendment";
                } else if (record.h_change_mode == 0) {
                    name = "Create";
                } else if (record.h_change_mode == 3) {
                    name = "Inactivate";
                }
                return <span>{name}</span>
            }
        },
        {
            title: 'Effective Date',
            dataIndex: 'h_effective_date',
            key: 'h_effective_date',
            width: '13%',

        },
        {
            title: 'Statutory Info',
            dataIndex: 'summary',
            key: 'summary',
            width: '20%',

        },
        {
            title: 'Created by',
            dataIndex: 'h_created_by',
            key: 'h_created_by',
            width: '12%',

        },
        {
            title: 'Last Updated by',
            dataIndex: 'h_last_updated_by',
            key: 'h_last_updated_by',
            width: '12%',

        },
        {
            title: 'Action',
            dataIndex: 'h_action',
            key: 'h_action',
            width: '10%',

        },
        {
            title: 'Remarks',
            dataIndex: 'h_reason',
            key: 'h_reason',
            ellipsis: true,
            width: '12%',

        },
    ]

    const Cancelmodalhistory = () => {
        setHistoryModalOpen(false)
    }



    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };


    // Full Screen Mode
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <>
            <Modal title="Compliance Details" visible={showValueModal} footer={null} cancelText={false}
                onCancel={Cancelmodal} width={50} className={"userprivilegemodel1 custom-color-modal "+ localStorage.getItem('currentTheme')}
            >
                <div  className='row comp-details-pop custom-scroll mx-0'>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Statutory Nature</b></div>
                    <div className='col-lg-6'>{complianceModal.statutorynature}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Statutory Provision</b></div>
                    <div className='col-lg-6'>{complianceModal.oldstatutoryprovision == null ? complianceModal.statutoryprovision : <> <Tooltip title={complianceModal && complianceModal.oldstatutoryprovision}><ExclamationCircleTwoTone /></Tooltip> {complianceModal.statutoryprovision}</>}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Compliance Task</b></div>
                    <div className='col-lg-6'>{complianceModal.oldcompliancetask == null ? complianceModal.compliance_task : <> <Tooltip title={complianceModal && complianceModal.oldcompliancetask}><ExclamationCircleTwoTone /></Tooltip> {complianceModal.compliance_task}</>}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Compliance Description</b></div>
                    <div className='col-lg-6'>{complianceModal.description}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Format</b></div>
                    {console.log(complianceModal, 'modal')}
                    <div className='col-lg-6'> {complianceModal && complianceModal.url != null && complianceModal.url.length > 0 ? complianceModal.url.map((list) => {
                        return (<>
                            <span>{list.split('compliance_format/')} <DownloadOutlined onClick={(e) => {
                                downloadnotificationfile(list)
                            }} style={{ color: "blue" }} /></span><br /></>
                        )
                    }) : '-'}</div>
                    {console.log(complianceModal, 'complianceModal')}
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Penal Consequences</b></div>
                    <div className='col-lg-6'>{complianceModal.penalconsequences}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>CompfieLaw ID</b></div>
                    <div className='col-lg-6'>{complianceModal.compfielaw}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Compliance Frequency</b></div>
                    <div className='col-lg-6'>{complianceModal.frequency}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Compliance Occurrence</b></div>
                    <div className='col-lg-6'>{complianceModal.occurrence}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Applicable Location</b></div>
                    <div className='col-lg-6'>{complianceModal.oldlocation == "" ? complianceModal.location : <> <Tooltip title={complianceModal && complianceModal.oldlocation}><ExclamationCircleTwoTone /></Tooltip> {complianceModal.location}</>}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Reference Link</b></div>
                    <div className='col-lg-6'>{complianceModal.link}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Task Status</b></div>
                    <div className='col-lg-6'><Link>{complianceModal.taskstatus}</Link></div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Implementation Effective Date</b></div>
                    <div className='col-lg-6'>{complianceModal.effectivedate}</div>
                    {console.log(complianceModal.statu_due, 'complianceModal.statu_due')}

                    {complianceModal.statu_due != null ?
                        <>
                            <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Statutory Due</b></div>
                            <div className='col-lg-6'>{complianceModal.statu_due}</div></> : ''}
                    {complianceModal.extn_due != null ?
                        <>
                            <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Extended Statutory Date</b></div>
                            <div className='col-lg-6'>{complianceModal.extn_due}</div></> : ''}
                    {complianceModal.remarks != null ?
                        <>
                            <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Remarks</b></div>
                            <div className='col-lg-6'>{complianceModal.remarks}</div></> : ''}
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Criticality</b></div>
                    <div className='col-lg-6'>{complianceModal.criticality}</div>
                    <div className='col-lg-6' style={{ marginTop: '5px' }}><b>Notification File</b></div>
                    <div className='col-lg-6'>{complianceModal.notification_url && complianceModal.notification_url.length > 0 ? complianceModal.notification_url.map((list) => {
                        return (<>
                            <span>{list.split('compliance_format/')} <DownloadOutlined onClick={(e) => {
                                downloadnotificationfile(list)
                            }} style={{ color: "blue" }} /></span><br /></>
                        )
                    }) : '-'}</div>
                </div>

            </Modal>
            <div id="page-wrapper" className="page-wrapper">
                 <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-titles pb-1 pt-1">
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">                            
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span >Approve Statutory Mapping </span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div
                            className="col-lg-6 col-md-6 d-none d-md-flex align-items-center justify-content-end"
                        >
                            <div style={{ display: "initial" }}>
                                <div className="d-inline" style={{ background: '#39cb7f', color: "white", borderRadius: '15px', padding: '5px' }}>
                                    <p className=" mx-1 d-inline" >Active Status : <span className="text-white fw-bolder">{activeCount ? activeCount : '0'}</span> </p>
                                </div>
                                <div className="d-inline mx-1" style={{ background: 'red', color: "white", borderRadius: '15px', padding: '5px' }}>
                                    <p className=" mx-1 d-inline" >InActive Status : <span className="text-white fw-bolder">{inActiveCount ? inActiveCount : '0'}</span>
                                    </p>
                                </div>

                            </div>
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginLeft: "5px",
                                    display: filterTaskAcc ? "none" : "inline",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button
                                onClick={(e) => {
                                    fullscreenMode();
                                }}
                                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                            >
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                                <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}  >
                                    <Panel header="Approve Statutory Mapping" key={1}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            autoFocus={true}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={approvestatutorymapping.country || undefined}
                                                            onChange={(value, data) => {
                                                                console.log('value', value, 'data', data);
                                                                let statutorynature = _.filter(approvestatutorymappingfilter && approvestatutorymappingfilter.statutory_natures, { country_id: Number(value) })
                                                                setStatutoryNature(statutorynature)
                                                                if (data) {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            country: value,
                                                                            countryName: data.children ? data.children : '',
                                                                            domain: '',
                                                                            domainname: '',
                                                                            organization: '',
                                                                            organizationname: '',
                                                                            statutoryid: '',
                                                                            statutoryname: '',
                                                                            userid: '',
                                                                            username: ''
                                                                        })
                                                                } else {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            country: '',
                                                                            countryName: '',
                                                                            domain: '',
                                                                            domainname: '',
                                                                            organization: '',
                                                                            organizationname: '',
                                                                            statutoryid: '',
                                                                            statutoryname: '',
                                                                            userid: '',
                                                                            username: ''
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            {approvestatutorymappingfilter && approvestatutorymappingfilter.countries && approvestatutorymappingfilter.countries.length > 0 && approvestatutorymappingfilter.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id} value={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            approvestatutorymapping.country,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain Name :</b> <span style={{ color: "red" }}> *</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain Name"
                                                            showSearch
                                                            disabled={approvestatutorymapping.country == '' ? true : false}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={approvestatutorymapping.domainname || undefined}
                                                            onChange={(value, data) => {
                                                                let organization = _.filter(approvestatutorymappingfilter && approvestatutorymappingfilter.industries, { domain_id: Number(value) })
                                                                let organization1 = _.filter(organization, { country_id: Number(approvestatutorymapping.country) })
                                                                setOrganizations(organization1)
                                                                if (data) {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            domain: value,
                                                                            domainname: data.children,
                                                                            organization: '',
                                                                            organizationname: '',
                                                                            statutoryid: '',
                                                                            statutoryname: '',
                                                                            userid: '',
                                                                            username: ''
                                                                        });
                                                                } else {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            domain: '',
                                                                            domainname: '',
                                                                            organization: '',
                                                                            organizationname: '',
                                                                            statutoryid: '',
                                                                            statutoryname: '',
                                                                            userid: '',
                                                                            username: ''
                                                                        });
                                                                }
                                                            }}
                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.domain_id}>
                                                                        {item.domain_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            approvestatutorymapping.domain,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Organization :</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Organization"
                                                            showSearch
                                                            disabled={approvestatutorymapping.domain == '' ? true : false}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={approvestatutorymapping.organizationname || undefined}
                                                            onChange={(value, data) => {
                                                                if (data) {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            organization: value,
                                                                            organizationname: data.children
                                                                        });
                                                                } else {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            organization: '',
                                                                            organizationname: ''
                                                                        });
                                                                }
                                                            }}
                                                        >
                                                            {organization && organization.length > 0 && organization.map((item, i) => {
                                                                return (
                                                                    <Option key={item.industry_id}>
                                                                        {item.industry_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 mt-2'>
                                            <div className='row '>
                                                <div className='col-md-4'>
                                                    <label><b>Statutory Nature :</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Statutory Nature"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            disabled={approvestatutorymapping.country == '' ? true : false}
                                                            value={approvestatutorymapping.statutoryname || undefined}
                                                            onChange={(value, data) => {
                                                                if (data) {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            statutoryid: value,
                                                                            statutoryname: data.children,
                                                                        })
                                                                } else {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            statutoryid: '',
                                                                            statutoryname: '',
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            {statutorynature && statutorynature.length > 0 && statutorynature.map((item, i) => {
                                                                return (
                                                                    <Option key={item.statutory_nature_id}>
                                                                        {item.statutory_nature_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>User :</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter User"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={approvestatutorymapping.username || undefined}
                                                            onChange={(value, data) => {
                                                                let subcat = _.filter(lists.task_sub_cat, { task_group_id: Number(value) })
                                                                setSubcategory(subcat);
                                                                if (data) {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            userid: value,
                                                                            username: data.children,
                                                                        });
                                                                } else {
                                                                    setApproveStatutoryMapping
                                                                        ({
                                                                            ...approvestatutorymapping,
                                                                            userid: '',
                                                                            username: '',
                                                                        });
                                                                }
                                                            }}
                                                        >
                                                            {approvestatutorymappingfilter && approvestatutorymappingfilter.knowledgeusers && approvestatutorymappingfilter.knowledgeusers.length > 0 && approvestatutorymappingfilter.knowledgeusers.map((item, i) => {
                                                                return (
                                                                    <Option key={item.user_id}>
                                                                        {item.employee_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <Button
                                                        onClick={() => { showdatavalue() }}
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton'
                                                        style={{
                                                            marginTop: "10px"
                                                        }}
                                                        icon={<EyeOutlined />}
                                                    >
                                                        Show
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                {show == true ?
                                    <>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <Table
                                                    locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                                                    size={'small'}
                                                    className='taskrecalltable'
                                                    columns={columns}
                                                    dataSource={showdata && showdata.approv_mappings && showdata.approv_mappings.length > 0 && showdata.approv_mappings}
                                                    bordered
                                                    // scroll={{ x: 1500 }}
                                                    pagination={false}
                                                />
                                            </div>
                                            {showdata.approv_mappings && showdata.approv_mappings.length > 0 ?
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            Showing 1 to {showdata.approv_mappings && showdata.approv_mappings.length > 0 && showdata.approv_mappings.length} of {showdata.approv_mappings && showdata.approv_mappings.length > 0 && showdata.approv_mappings.length} entries
                                                        </div>
                                                        <div className="col-md-6" style={{ textAlign: "center", marginTop: '15px' }}>
                                                            <Button type="primary" shape="round" className='addbutton'
                                                                icon={<PlayCircleOutlined />} size='default'
                                                                onClick={() => { submitrecord() }}
                                                            >Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    </>
                                    : ""}
                                <Modal
                                    title="Approve Statutory Mapping"
                                    className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}
                                    footer={false}
                                    visible={filterModalVisible}
                                    onCancel={setcancelFilter}                                    

                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={approvestatutorymapping.countryName || undefined}
                                                        onChange={(value, data) => {
                                                            let statutorynature = _.filter(approvestatutorymappingfilter && approvestatutorymappingfilter.statutory_natures, { country_id: Number(value) })
                                                            setStatutoryNature(statutorynature)
                                                            if (data) {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        domain: '',
                                                                        domainname: '',
                                                                        organization: '',
                                                                        organizationname: '',
                                                                        statutoryid: '',
                                                                        statutoryname: '',
                                                                        userid: '',
                                                                        username: ''
                                                                    });
                                                            } else {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        country: '',
                                                                        countryName: '',
                                                                        domain: '',
                                                                        domainname: '',
                                                                        organization: '',
                                                                        organizationname: '',
                                                                        statutoryid: '',
                                                                        statutoryname: '',
                                                                        userid: '',
                                                                        username: ''
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        {approvestatutorymappingfilter && approvestatutorymappingfilter.countries && approvestatutorymappingfilter.countries.length > 0 && approvestatutorymappingfilter.countries.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        approvestatutorymapping.country,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain Name :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Domain Name"
                                                        showSearch
                                                        disabled={approvestatutorymapping.country == '' ? true : false}
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={approvestatutorymapping.domainname || undefined}
                                                        onChange={(value, data) => {
                                                            let organization = _.filter(approvestatutorymappingfilter && approvestatutorymappingfilter.industries, { domain_id: Number(value) })
                                                            let organization1 = _.filter(organization, { country_id: Number(approvestatutorymapping.country) })
                                                            setOrganizations(organization1);
                                                            if (data) {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        domain: value,
                                                                        domainname: data.children,
                                                                        organization: '',
                                                                        organizationname: '',
                                                                        statutoryid: '',
                                                                        statutoryname: '',
                                                                        userid: '',
                                                                        username: ''
                                                                    });
                                                            } else {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        domain: '',
                                                                        domainname: '',
                                                                        organization: '',
                                                                        organizationname: '',
                                                                        statutoryid: '',
                                                                        statutoryname: '',
                                                                        userid: '',
                                                                        username: ''
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.domain_id}>
                                                                    {item.domain_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        approvestatutorymapping.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Organization :</b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Organization"
                                                        showSearch
                                                        disabled={approvestatutorymapping.domain == '' ? true : false}
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={approvestatutorymapping.organizationname || undefined}
                                                        onChange={(value, data) => {
                                                            if (data) {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        organization: value,
                                                                        organizationname: data.children
                                                                    });
                                                            } else {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        organization: '',
                                                                        organizationname: ''
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        {organization && organization.length > 0 && organization.map((item, i) => {
                                                            return (
                                                                <Option key={item.industry_id}>
                                                                    {item.industry_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12 mt-1'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Statutory Nature :</b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Statutory Nature"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        disabled={approvestatutorymapping.country == '' ? true : false}
                                                        value={approvestatutorymapping.statutoryname || undefined}
                                                        onChange={(value, data) => {
                                                            if (data) {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        statutoryid: value,
                                                                        statutoryname: data.children,
                                                                    });
                                                            } else {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        statutoryid: '',
                                                                        statutoryname: '',
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        {statutorynature && statutorynature.length > 0 && statutorynature.map((item, i) => {
                                                            return (
                                                                <Option key={item.statutory_nature_id}>
                                                                    {item.statutory_nature_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User :</b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter User"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={approvestatutorymapping.username || undefined}
                                                        onChange={(value, data) => {
                                                            let subcat = _.filter(lists.task_sub_cat, { task_group_id: Number(value) })
                                                            setSubcategory(subcat)
                                                            if (data) {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        userid: value,
                                                                        username: data.children,
                                                                    })                                                                
                                                            } else {
                                                                setApproveStatutoryMapping
                                                                    ({
                                                                        ...approvestatutorymapping,
                                                                        userid: '',
                                                                        username: '',
                                                                    })  
                                                            }
                                                        }}
                                                    >
                                                        {approvestatutorymappingfilter && approvestatutorymappingfilter.knowledgeusers && approvestatutorymappingfilter.knowledgeusers.length > 0 && approvestatutorymappingfilter.knowledgeusers.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.employee_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <Button
                                                    onClick={() => { showdatavalue() }}
                                                    type="primary"
                                                    shape="round"
                                                    className='addbutton custom-color-modal-button'
                                                    style={{
                                                        marginTop: "10px"
                                                    }}
                                                    icon={<EyeOutlined />}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className='card p-4 py-2 mb-0 rounded user-mapping1 bg-white' style={{ display: legendshow }}>
                            <div className="d-flex justify-content-between">
                                <div>
                                </div>
                                <div>
                                    <i style={{ color: 'green' }} className="bi bi-square-fill"></i> <label>New</label>
                                </div>
                                <div >
                                    <i style={{ color: "orange" }} className="bi bi-square-fill"></i> <label>Extension</label>
                                </div>
                                <div >
                                    <i style={{ color: "yellow" }} className="bi bi-square-fill"></i> <label>Edit / Amendment </label>
                                </div>
                                <div >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </div>

            <Modal title="Compliance Amendment History" open={historyModalOpen} footer={null} cancelText={false}
                onCancel={Cancelmodalhistory} className={"custom-color-modal "+ localStorage.getItem('currentTheme')}
                >
                <Table
                    locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                    size={'small'}
                    columns={historycolumns}
                    dataSource={history.h_compliance_history_list && history.h_compliance_history_list.length > 0 && history.h_compliance_history_list}
                    bordered
                    pagination={false}
                />


            </Modal>
        </>
    )
}
export default connect(mapStateToProps, {
    ApproveStatutoryMappingsFilters,
    ApproveStatutoryMappings,
    ComplianceInfo,
    approvestatutorymappingfinal,
    GetComplianceHistory
})(ApproveStatutoryMapping);