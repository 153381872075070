import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { GETAPPROVETASKCATEGORYMAPPINGFILTER, APPROVE_TASK_CATEGORY_COMPLIANCE, APPROVE_TASK_CATEGORY_MAPPING_SHOW, APPROVE_TASK_CATEGORY_COMPLIANCE_SHOWMORE } from '../../types/index'

const initialState = {
    getapprovetaskcategorymappingfilterdata: '',
    showdata: '',
    showtaskcompliance: '',
    showtaskcomplianceshowmore: ''
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GETAPPROVETASKCATEGORYMAPPINGFILTER:
            {
                return {
                    ...state,
                    getapprovetaskcategorymappingfilterdata: payload
                };
            }
        case APPROVE_TASK_CATEGORY_MAPPING_SHOW:
            {
                return {
                    ...state,
                    showdata: payload[1]
                };
            }
        case APPROVE_TASK_CATEGORY_COMPLIANCE:
            {
                return {
                    ...state,
                    showtaskcompliance: payload[1]
                };
            }

        case APPROVE_TASK_CATEGORY_COMPLIANCE_SHOWMORE:
            {
                return {
                    ...state,
                    // showtaskcompliance: payload[1]
                    showtaskcomplianceshowmore: [...state.showtaskcomplianceshowmore,payload[1].apptaskcatcompliance]
                };
            }

        default:
            return state;
    }
}