import {
    MAPPINGBULKLIST, BULKUPLOADDOMAIN, UPLOADFILENAME, ERRORDATA, AMENDMENT_DATA,
    AMENDMENT_UPLOAD_SUCCESS, AMENDMENT_UPLOAD_ERROR, AMENDMENT_UPLOAD_FILE, MAPPING_UPLOAD_LIST, ADD_UPLOAD_DATA
} from '../../types/index'

const initialState = {
    bulkupload: {
        list: '',
        domainlist: '',
        uploadfilename: '',
        errordata: '',
        Amendmentdata: '',
        uploadsuccess: '',
        uploaderrordata: '',
        mappinglist: '',
        addUploadData: '',
        amentmentfile: ''
    }

}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case MAPPINGBULKLIST: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    list: payload,


                },
            };
        }
        case BULKUPLOADDOMAIN: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    domainlist: payload,


                },
            };
        }
        case MAPPING_UPLOAD_LIST: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    mappinglist: payload,


                },
            };
        }
        case AMENDMENT_UPLOAD_FILE: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    amentmentfile: payload,


                },
            };
        }
        case UPLOADFILENAME: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    uploadfilename: payload,


                },
            };
        }
        case ERRORDATA: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    errordata: payload,


                },
            };
        }
        case ADD_UPLOAD_DATA: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    addUploadData: payload,


                },
            };
        }
        case AMENDMENT_DATA: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    Amendmentdata: payload,


                },
            };
        }
        case AMENDMENT_UPLOAD_SUCCESS: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    uploadsuccess: payload,


                },
            };
        }
        case AMENDMENT_UPLOAD_ERROR: {
            return {
                ...state,
                bulkupload: {
                    ...state.bulkupload,
                    uploaderrordata: payload,


                },
            };
        }

        default:
            return state;
    }
}