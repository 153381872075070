import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined, CloseCircleOutlined, SearchOutlined, CloseOutlined, PlayCircleOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Button, Collapse, Table, Input, Card, Select, Tooltip } from 'antd';
import { Assignlegalentity, viewAssignedEntity, getAssignedEntityData, submitLegalEntity } from '../../Store/Action/Transactions/AssignLegalEntity';
import { ASSIGN_LEGAL_ENTITY, ASSIGNED_LEGAL_ENTITY, GET_ASSIGNED_ENTITY_DATA } from '../../Store/types';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { Toaster } from '../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from '../../Libs/fullscreenmode';
import ScrollButton from '../Scrollbar';

const _ = require("lodash");

const mapStateToProps = (state) => ({
})
const AssignLegalEntity = ({
    Assignlegalentity,
    viewAssignedEntity,
    getAssignedEntityData,
    submitLegalEntity
}) => {
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const tableData = useSelector((state) => state.AssignLegalEntity.assign_legal_tabledata)
    const assignedTableData = useSelector((state) => state.AssignLegalEntity.assignedTableData)
    const assignData = useSelector((state) => state.AssignLegalEntity.getAssignedData)
    console.log(tableData, 'tableData')
    console.log(assignData, 'assignData');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [viewState, setViewState] = useState(false)
    const [assignState, setAssignState] = useState(false)
    const [groupName, setGroupName] = useState()
    console.log(groupName, 'groupName');
    const [viewAssignedArray, setViewAssignedArray] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([])
    console.log(checkboxValue, 'checkboxValue');
    const [assignedDataArray, setAssignedDataArray] = useState([])
    const [technousers, setTechnoUsers] = useState([])
    const { Option } = Select;
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [technoUserId, setTechnoUserId] = useState()
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);

    const { Panel } = Collapse;


    useEffect(() => {
        let payload = {
            "session_token": authtoken,
            "request": [
                "GetAssignLegalEntityList",
                {}
            ]
        }

        Assignlegalentity({
            type: ASSIGN_LEGAL_ENTITY,
            payload: payload,
            caller: paramid
        })
    }, [])

    useEffect(() => {
        if (assignedTableData) {
            setViewAssignedArray(assignedTableData && assignedTableData.view_assigned_legal_entities)
        }
    }, [assignedTableData])

    useEffect(() => {
        if (assignData) {
            setAssignedDataArray(assignData && assignData.unassign_legal_entities)
            setTechnoUsers(assignData && assignData.mapped_techno_users)
        }
    }, [assignData])

    const rowSelection = {
        renderCell: (checked, record, index, originNode) => {
            return (
                <Fragment style={{ width: '10px' }}>
                    <input type="checkbox"
                        // style={{ width: '10px' }}
                        name="checked"
                        // className={record.d_id}
                        // hidden={record.is_locked === false ? false : true}
                        onClick={(e) => {
                            let checked = e.target.checked;
                            checkBoxOnChange(
                                checked,
                                record.legal_entity_id,
                                // record.d_id,
                                // record.r_count
                                // record.childId
                            );
                        }}
                    />
                </Fragment>
            )
        }

    }

    const checkBoxOnChange = (e, legal_entity_id) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(legal_entity_id) == true) {
            for (let i in temp) {
                if (temp[i] == legal_entity_id) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(legal_entity_id)
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('checked');

        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {

                if (ele[i].type == 'checkbox') {

                    ele[i].checked = true;
                    tempAllIds.push(assignedDataArray[i].legal_entity_id)
                }
            }

            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };


    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo(filters);
        // setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            dataIndex: "",
            key: "",
            align: 'center',
            width: '35px',
            render: (text, record, index) => {
                return <span>{index + 1}</span>
            }
        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Country</span>,
            dataIndex: 'country_names',
            key: 'country_names',
            ...getColumnSearchProps('country_names', 'Country'),
            // ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.country_names || null,
            ellipsis: true,
            // sorter: (a, b) => a.country_names.localeCompare(b.country_names),
            // sortOrder: sortedInfo.columnKey === 'country_names' ? sortedInfo.order : null,

        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Group</span>,
            dataIndex: 'group_name',
            key: 'group_name',
            ...getColumnSearchProps('group_name', 'Group'),
            // ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.group_name || null,
            ellipsis: true,
            // sorter: (a, b) => a.country_names.localeCompare(b.country_names),
            // sortOrder: sortedInfo.columnKey === 'country_names' ? sortedInfo.order : null,

        },
        {
            title: 'Unassigned Legal Entity',
            dataIndex: '',
            key: '',
            width: '80px',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                let unassignedVal = Number(record.no_of_legal_entities) - Number(record.no_of_assigned_legal_entities)
                return <p style={{ marginBottom: '0px' }}>{unassignedVal} / {record.no_of_legal_entities}</p>
            }
        },
        {
            title: 'Assign',
            dataIndex: '',
            key: '',
            width: '100px',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                let unassignedVal = Number(record.no_of_legal_entities) - Number(record.no_of_assigned_legal_entities)
                if (unassignedVal > 0) {
                    return <Button style={{ borderRadius: '15px' }} type="success"
                        onClick={() => {
                            const payload = {
                                "session_token": authtoken,
                                "request": [
                                    "GetEditAssignLegalEntity",
                                    {
                                        "group_id": record.client_id
                                    }
                                ]
                            }
                            console.log(payload, 'payload');
                            getAssignedEntityData({
                                type: GET_ASSIGNED_ENTITY_DATA,
                                payload: payload,
                                caller: paramid
                            })
                            setAssignState(true)
                            setGroupName(record)
                        }}
                    >Assign</Button>
                }
                else {
                    return false
                }
            }
        },
        {
            title: 'Techno Executive',
            dataIndex: '',
            key: '',
            width: '80px',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                return <Button style={{ borderRadius: '15px' }} type="success"
                    onClick={() => {
                        const payload = {
                            "session_token": authtoken,
                            "request": [
                                "ViewAssignLegalEntity",
                                {
                                    "client_id": record.client_id
                                }
                            ]
                        }
                        console.log(payload, 'payload');
                        viewAssignedEntity({
                            type: ASSIGNED_LEGAL_ENTITY,
                            payload: payload,
                            caller: paramid
                        })
                        setViewState(true)
                        setGroupName(record.group_name)
                    }}
                >View</Button>
            }
        }
    ]

    const columns1 = [
        {
            title: 'Country',
            dataIndex: 'c_name',
            key: 'c_name',
            width: '80px',
            // align: 'center',
            // ellipsis: true,

        },
        {
            title: 'Business Group',
            dataIndex: '',
            key: '',
            width: '80px',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                if (record.business_group_name != null) {
                    return <span>{record.business_group_name}</span>
                }
                else {
                    return <span>-</span>
                }
            }
        },
        {
            title: 'Legal Entity',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            width: '80px',
            // align: 'center',
            // ellipsis: true,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: '120px',
            // align: 'center',
            // ellipsis: true,
        },

    ]

    const columns2 = [
        {
            title: 'Country',
            dataIndex: 'c_name',
            key: 'c_name',
            width: '80px',
            // align: 'center',
            // ellipsis: true,

        },
        {
            title: 'Business Group',
            dataIndex: '',
            key: '',
            width: '80px',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                if (record.business_group_name != null) {
                    return <span>{record.business_group_name}</span>
                }
                else {
                    return <span>-</span>
                }
            }
        },
        {
            title: 'Legal Entity',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            width: '80px',
            // align: 'center',
            // ellipsis: true,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: '120px',
            // align: 'center',
            // ellipsis: true,
        },

    ]

    console.log(technoUserId, 'technoUserId');

    const Submit = () => {
        console.log('hii');
        if (technoUserId === '' || technoUserId === undefined) {
            Toaster.error('Techno executive required')
        }
        else {
            console.log('elsepart');
            const payload = {
                "session_token": authtoken,
                "request": [
                    "SaveAssignLegalEntity",
                    {
                        "client_id": groupName.client_id,
                        "legal_entity_ids": checkboxValue,
                        "user_ids": [Number(technoUserId)]
                    }
                ]
            }

            const tablePayload = {
                "session_token": authtoken,
                "request": [
                    "GetAssignLegalEntityList",
                    {}
                ]
            }

            submitLegalEntity({
                payload: payload,
                caller: paramid,
                tablePayload: tablePayload,
                setAssignState: setAssignState

            })
        }
    }

    const dispatch = useDispatch();
    //  Full screen mode //
    const [fullscreen, setfullscreen] = useState(false);
    // Full Screen Mode 
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    };

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Assign Legal Entity </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 text-end">
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            {/* //  Full screen mode // */}
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className='back-to-top'>
                <ScrollButton />
            </div>

            {viewState === true ?
                <Card
                    headStyle={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ',borderRadius:'10px' }}
                    size="small"
                    title="Assigned Legal Entity"
                    extra={<CloseOutlined onClick={() => {
                        setViewState(false)
                    }} />}
                    style={{
                        marginLeft: '10%',
                        marginRight: '10%',
                        borderRadius: '15px'
                    }}
                >

                    <p style={{ marginLeft: '1%' }}>Group: {groupName}
                    </p>
                    {/* <Table
                        className='taskrecalltable'
                        columns={columns1}
                        dataSource={viewAssignedArray}
                        // title={()=>{
                        //     return <span>Hiii</span>
                        // }}
                        // onHeaderRow={()=>{
                        //     return <span>Hiii</span>
                        // }}
                        bordered
                        // scroll={{ x: 700 }}
                        pagination={false}
                        onChange={handleChange} /> */}
                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                        <div className='ant-table ant-table-bordered'>
                            <div className='ant-table-container'>
                                <table>
                                    <colgroup>
                                        <col style={{ width: '10px' }}></col>
                                    </colgroup>
                                    <thead className="ant-table-thead " >
                                        <tr>
                                            <th className={'ant-table-cell'} style={{ width: '80px' }}>Country</th>
                                            <th className={'ant-table-cell'} style={{ width: '50px' }}>Business Group</th>
                                            <th className={'ant-table-cell'} style={{ width: '50px' }}>Legal Entity</th>
                                            <th className={'ant-table-cell'} style={{ width: '100px' }}>Product</th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        <span style={{ marginLeft: '4%' }}>{viewAssignedArray && viewAssignedArray.length > 0 && viewAssignedArray[0].employee_name}</span>
                                        {viewAssignedArray && viewAssignedArray.length > 0 && viewAssignedArray.map((item, i) => {
                                            console.log(item, 'itemitemitem');
                                            return <tr className='ant-table-row ant-table-row-level-0'>
                                                <td className='ant-table-cell border-end'>{item.c_name}</td>
                                                <td className='ant-table-cell border-end'><center>{item.business_group_name ? item.business_group_name : '-'}</center></td>
                                                <td className='ant-table-cell border-end'>{item.legal_entity_name}</td>
                                                <td className='ant-table-cell border-end'>{item.product}</td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Card> :
                assignState === true ?
                    <Card
                        headStyle={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3',borderRadius:'10px' }}
                        size="small"
                        title="Assign Legal Entity"
                        extra={<CloseOutlined onClick={() => {
                            setAssignState(false)
                            setCheckBoxValue([])
                            setTechnoUserId()
                        }} />}
                        style={{
                            marginLeft: '10%',
                            marginRight: '10%'
                        }}
                    >
                        <div className="col-md-12">
                            <div className="row" style={{ marginLeft: '20%' }}>
                                <div className="col-md-6">
                                    <label htmlFor="">Group: {groupName.group_name}</label>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="">Country: {groupName.country_names}</label>
                                </div>
                            </div>
                        </div>

                        <Table
                            className='taskrecalltable'
                            columns={columns2}
                            dataSource={assignedDataArray}
                            // title={()=>{
                            //     return <span>Hiii</span>
                            // }}
                            // onHeaderRow={()=>{
                            //     return <span>Hiii</span>
                            // }}
                            bordered
                            // scroll={{ x: 700 }}
                            pagination={false}
                            onChange={handleChange}
                            rowSelection={{
                                columnWidth: '30px',
                                columnTitle: <input type="checkbox"
                                    // style={{ width: '10px' }}
                                    checked={((assignedDataArray && assignedDataArray.length > 0 && assignedDataArray.length) === (checkboxValue && checkboxValue.length > 0 && checkboxValue.length)) ? true : false}
                                    name="allchecked" id="all" className='checkclass'
                                    onClick={(e) => {
                                        let checked = e.target.checked;
                                        selectAllCheckBox(
                                            checked
                                        );
                                    }}
                                />,
                                ...rowSelection
                            }}
                        />
                        <span>Selected Legal Entity : {checkboxValue && checkboxValue.length}</span>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">

                                </div>
                                <div className='col-md-4'>
                                    <label><b>Assign To Techno Executive : </b> <span style={{ color: "red" }}>*</span></label>
                                    <div className="form-group">
                                        <Select

                                            // defaultValue={localStorage.getItem('SelectedEntity') === "All Legal Entity" ? "" : localStorage.getItem('SelectedEntity')}
                                            allowClear={true}
                                            size="default"
                                            // onDrop={(e) => {
                                            //     e.preventDefault();
                                            //     Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            //     return false;
                                            // }}
                                            // onPaste={(e) => {
                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                            // }}
                                            disabled={checkboxValue && checkboxValue.length > 0 ? false : true}
                                            onChange={(data, value) => {
                                                console.log(data, 'legaldatat')
                                                if (data !== undefined) {
                                                    setTechnoUserId(data)
                                                }
                                                else {
                                                    setTechnoUserId()
                                                }
                                                setAddFormSubmit(false)
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            value={technoUserId ? technoUserId : undefined}
                                            style={{ width: "100%", marginTop: "5px", title: '' }}
                                        >
                                            {technousers && technousers.length > 0 && technousers.map((item, i) => {
                                                return (
                                                    <Option key={item.user_id}>
                                                        {item.employee_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {/* {validator.current.message(
                                            'legalentity',
                                            technoUserId,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Techno executive Required',
                                                }
                                            })} */}
                                    </div>
                                    <div className="mt-3 text-center">
                                        <Button type="primary" shape="round" className='addbutton'
                                            icon={<PlayCircleOutlined />} size='default'
                                            onClick={() => { Submit() }}
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </div>
                                <div className="col-md-4">

                                </div>
                            </div>
                        </div>



                    </Card> :
                    <>
                        {/* <Collapse defaultActiveKey={["1"]}>
                     <Panel header={'Assign Legal Entity'} key={1}> */}
                        <Table
                            className='taskrecalltable'
                            columns={columns}
                            dataSource={tableData}
                            bordered
                            // scroll={{ x: 700 }}
                            pagination={false}
                            onChange={handleChange}
                        />
                        {/* </Panel>
                 </Collapse> */}
                    </>
            }

        </div >
    )
}
export default connect(mapStateToProps, {
    Assignlegalentity,
    viewAssignedEntity,
    getAssignedEntityData,
    submitLegalEntity
})(AssignLegalEntity);