import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tabs, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { getclientunitFilter, getClientunitList, getaddunitList, addclientUnit, autoGentrateUnitCode } from '../../Store/Action/Transactions/TE_ClientUnit';
import Taskcategoryedit from './Taskcategoryedit';
import Taskcategoryadd from './Taskcategoryadd';
import {
    LikeOutlined, DislikeOutlined, ExclamationCircleTwoTone, UpOutlined, FullscreenOutlined, EyeOutlined, FilterOutlined,
    PlusOutlined, InfoCircleOutlined, StopOutlined, DeleteOutlined, SearchOutlined, DeleteTwoTone, ArrowLeftOutlined, EditOutlined, PlusCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, MinusCircleOutlined, PlayCircleOutlined, CiCircleOutlined
} from '@ant-design/icons';
import { update } from 'lodash';
import Swal from "sweetalert2";
import TextArea from 'antd/lib/input/TextArea';
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import axios from 'axios';
import url from '../../Libs/URL';
import { decode as base64_decode } from 'base-64';
import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";


const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.TE_ClientUnit.clientUnit.list,
    filterData: state.TE_ClientUnit.clientUnit.filterValue,
    unitListData: state.TE_ClientUnit.clientUnit.unitList,
    unitCode: state.TE_ClientUnit.clientUnit.unitCodes,
})
const TE_ClientUnit = ({
    getclientunitFilter,
    getClientunitList,
    autoGentrateUnitCode,
    getaddunitList,
    lists,
    filterData,
    addclientUnit,
    unitListData,
    unitCode

}) => {

    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    console.log(filterData, 'filterData');
    console.log(unitCode, 'unitCode');
    const [clientData, setClientData] = useState([])
    const [entityData, setEntityData] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [unitData, setUnitData] = useState([])
    const [autoeUnitCode, setAutoUnitcode] = useState(0)
    const [autoeUnitCode1, setAutoUnitcode1] = useState(0)
    const [parentIndexValue, setParentIndexValue] = useState()
    const [childIndex, setChildIndex] = useState()

    console.log(autoeUnitCode1, 'autoeUnitCode1');
    console.log(autoeUnitCode, 'autoeUnitCode');
    console.log(childIndex, 'childIndex');
    const { TabPane } = Tabs;
    console.log(unitData, 'unitData');
    const [show, setShow] = useState(false)
    const [divisionValue, setDivisionValue] = useState([])
    console.log(divisionValue, 'divisionValue');
    let [count, setcount] = useState(0)
    const [productData, setProductData] = useState([])
    console.log(productData, 'productData');
    const [productData1, setProductData1] = useState("")
    console.log(productData1, 'productData1');
    const [addType, setAddType] = useState('list')
    const [unitscount, setUnitscount] = useState(0)
    console.log(unitscount, 'unitscount');
    const [domainDetailsModal, setDomainDetailsModal] = useState(false)
    const [divisionData, setDivisionData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [compfieDomainData, setCompfieDomainData] = useState([])
    const [compfieOrganizationData, setcompfieOrganizationData] = useState([])
    const [litiationDomainData, setLitigationDomainData] = useState([])
    const [vendorDomainData, setVendorDomainData] = useState([])
    const [vendorOrganizationData, setVendorOrganizationData] = useState([])
    const [geographyLeval, setGeographyLeval] = useState([])
    const [divisionEdit, setdivisionEdit] = useState(false)

    const [categoryEdit, setCategoryEdit] = useState(false)
    const [geographyUnit, setGeoGraphyUnit] = useState([])
    const [showData, setShowData] = useState({
        client: '',
        legalEntity: '',
        business: '',
        country: '',
        client_name: ''
    })

    console.log(showData
        , 'showData');
    const [tableValue, setTableValue] = useState({
        client: '',
        legalEntity: ''
    })
    const { Search } = Input;
    const [countryData, setCountryData] = useState([])
    const [businessGroupData, setBusinessGroupData] = useState([])
    const [unitModal, setUnitModal] = useState(false)
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    const { Panel } = Collapse;
    const { Option, OptGroup } = Select;
    const [showModal, setShowModal] = useState(false)
    const [finalSelectedArray, setFinalSelectedArray] = useState([])
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo1, setFilteredInfo1] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo1(filters);
        setSortedInfo(sorter);
    };

    useEffect(() => {
        if (unitData && unitData.length > 0) {
            if (parentIndexValue !== '' || parentIndexValue !== undefined) {
                let clientName = showData.client_name.substring(0, 2).toUpperCase()
                if ((autoeUnitCode) !== (unitCode && unitCode.next_unit_code)) {
                    let unitCodedata = unitCode && unitCode.next_unit_code

                    console.log(clientName, 'clientName');
                    for (let i in unitData[parentIndexValue]) {

                        console.log(unitData[parentIndexValue][i], 'unitData[parentIndexValue][i]');
                        if (unitData[parentIndexValue][i].unitcode == '') {
                            unitCodedata = unitCodedata + 1
                            unitData[parentIndexValue][i].unitcode = `${clientName}000${unitCodedata}`
                        }
                        else {
                            unitData[parentIndexValue][i].unitcode = unitData[parentIndexValue][i].unitcode
                        }
                    }
                    setAutoUnitcode(unitCode && unitCode.next_unit_code)
                    setAutoUnitcode1(unitCodedata)
                }
                else {
                    console.log('testtttt');
                    let unitCodedata = autoeUnitCode1
                    for (let i in unitData[parentIndexValue]) {


                        console.log(unitData[parentIndexValue][i], 'unitData[parentIndexValue][i]');
                        if (unitData[parentIndexValue][i].unitcode == '') {
                            unitCodedata = unitCodedata + 1
                            unitData[parentIndexValue][i].unitcode = `${clientName}000${unitCodedata}`
                        }
                        else {
                            unitData[parentIndexValue][i].unitcode = unitData[parentIndexValue][i].unitcode
                        }
                    }
                    setAutoUnitcode1(unitCodedata)
                }
                setUnitData([...unitData])


            }
        }
    }, [unitCode])
    useEffect(() => {
        let temp = []
        if (productData && productData.length > 0) {

            if (productData.includes('Compfie')) {
                temp.push(1)
            }
            if (productData.includes(' Compfie Litigation')) {
                temp.push(2)
            }
            if (productData.includes(' Compfie Vendor')) {
                temp.push(3)
            }
            let stringData = temp.toString()
            setProductData1(stringData)
        }
    }, [productData])

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        let temp
        if (unitListData) {
            console.log(unitListData && unitListData.unit_list && unitListData.unit_list.length
                , 'unitListData');
        }
        let sum = 0
        for (let i in unitData) {
            console.log(unitData[i].length, 'unitData[i].length');
            sum = sum + Number(unitData[i].length)
        }
        temp = (sum) + (unitListData && unitListData.unit_list && unitListData.unit_list.length)
        setUnitscount(unitListData && unitListData.unit_list && unitListData.unit_list.length > 0 ? temp : 0)
        console.log(sum, '98777777');

    }, [unitListData, unitData])
    // useEffect(() => {
    //     let temp = []
    //     for (let i in unitData) {
    //         for (let j in unitData) {
    //             temp.push({

    //                 "unit_id": null,
    //                 "unit_name": unitData[i][j].unitname,
    //                 "unit_code": unitData[i][j].unitcode1,
    //                 "unitcodedynamicdata": [
    //                     {
    //                         "dynamic_data-0": unitData[i][j].unitcode1,
    //                         "dynamic_data-1": unitData[i][j].unitcode2,
    //                         "dynamic_data-2": unitData[i][j].unitcode3,
    //                         "dynamic_data-3": unitData[i][j].unitcode4
    //                     }
    //                 ],
    //                 "address": unitData[i][j].unitAddress,
    //                 "postal_code": unitData[i][j].postalcode,
    //                 "geography_id": unitData[i][j].geographylevel,
    //                 "d_ids": unitData[i][j].compfieDomain,
    //                 "i_ids_list": unitData[i][j].compfieOrganization,
    //                 "is_approved": 0,
    //                 "tz_id": 250,
    //                 "limt_domain": unitData[i][j].litigationDomain,
    //                 "is_compfie_limt": [
    //                     {
    //                         "is_cpmpf": 1
    //                     },
    //                     {
    //                         "is_limt": 1
    //                     },
    //                     {
    //                         "is_vndr": 1
    //                     }
    //                 ],
    //                 "v_i_ids_list": unitData[i][j].vendorOrganization,
    //                 "v_d_ids": unitData[i][j].vendorDomain

    //             })
    //         }
    //         console.log(temp, 'temp9855222');
    //     }
    // }, [unitData])



    useEffect(() => {
        if (filterData) {
            let uniqueObjArray = [...new Map(filterData && filterData.group_company_list && filterData.group_company_list.length > 0 && filterData.group_company_list.map((item) => [item["client_id"], item])).values()];
            // let uniqueUser = [...new Map(uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => [item["user_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item
            })
            setClientData(uniqueIds)
            setEntityData(filterData && filterData.unit_legal_entity
            )
            setBusinessGroupData(filterData && filterData.business_group_list


            )
            let uniqueObjArray2 = [...new Map(filterData && filterData.countries_units
                && filterData.countries_units
                    .length > 0 && filterData.countries_units
                        .map((item) => [item["country_id"], item])).values()];
            // let uniqueUser = [...new Map(uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => [item["user_id"], item])).values()];
            const uniqueIds2 = uniqueObjArray2 && uniqueObjArray2.map((item) => {
                return item
            })
            setCountryData(uniqueIds2
            )
        }
    }, [filterData])

    useEffect(() => {
        if (lists) {
            setDivisionData(lists.divisions
            )
            setCategoryData(lists.categories)
            setGeoGraphyUnit(lists.unit_geographies_list
            )
            setGeographyLeval(lists.unit_geography_level_list
            )

            if (lists && lists.client_unit_list && lists.client_unit_list.length > 0) {
                let temp = lists.client_unit_list[0].product_name.split(',')

                setProductData(temp)
            }
            setCompfieDomainData(lists && lists.domains_organization_list)
            setcompfieOrganizationData(lists && lists.domains_organization_list)
            setLitigationDomainData(lists && lists.ltmg_domains_list)
            setVendorDomainData(lists && lists.vendor_domains_organization_list
            )
            setVendorOrganizationData(lists && lists.vendor_domains_organization_list
            )

        }
    }, [lists])
    // useEffect(() => {
    //     let arrunit = [];
    //     let totalListunit = []
    //     let countrylisttempdataunit = [];
    //     if (unitData && unitData.length > 0 && parentIndexValue != undefined && unitData[parentIndexValue][childIndex].compfieDomain.length > 0) {
    //         for (let i in unitData[parentIndexValue][childIndex].compfieDomain) {
    //             let entityData = _.filter(lists && lists.domains_organization_list, { le_id: unitData[parentIndexValue][childIndex].compfieDomain[i] })
    //             console.log(entityData, 'entityData9604488888888880');
    //             arrunit.push(entityData[0])
    //         }
    //         console.log(arrunit, 'arrunit');

    //         arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
    //             let entityArrayunit = []
    //             let tempArrayunit = []

    //             lists && lists.domains_organization_list && lists && lists.domains_organization_list.length > 0 && lists && lists.domains_organization_list.map((subunitlist) => {
    //                 console.log(subunitlist, 'subunitlist');
    //                 if (pmenu && pmenu.domain_id == subunitlist.domain_id) {
    //                     entityArrayunit = {
    //                         "domain_id": subunitlist.domain_id,

    //                         "domain_name": subunitlist.domain_name,

    //                         "domain_status": subunitlist.domain_status,

    //                         "industry_id": subunitlist.industry_id,

    //                         "industry_name": subunitlist.industry_name,

    //                         "industry_status": subunitlist.industry_status,

    //                         "legal_entity_id": subunitlist.industry_status,

    //                         "unit_count": subunitlist.industry_status,

    //                         'label': subunitlist.domain_name,
    //                         'values': subunitlist.industry_id
    //                     }
    //                     tempArrayunit.push(entityArrayunit)
    //                 }
    //             })


    //             totalListunit = {
    //                 'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
    //                 'options': tempArrayunit
    //             }
    //             console.log(totalListunit, 'aishwarya');
    //             console.log(totalListunit, 'unitListData')
    //             countrylisttempdataunit.push(totalListunit)
    //         })
    //         unitData[parentIndexValue][childIndex].compfieDomain = countrylisttempdataunit
    //         setUnitData([...unitData])
    //     }

    // }, [lists && lists.domains_organization_list, unitData && unitData.length > 0 && parentIndexValue != undefined && unitData[parentIndexValue][childIndex].compfieDomain,])
    useEffect(() => {

        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetClientUnitFilters",
                    {
                    }
                ]
            }



            getclientunitFilter({
                payload: payload,
                caller: paramid,
            })
        }
    }, [isAuth]);

    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '50px',
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Group',
            dataIndex: 'client_name',
            key: 'client_name',
            ellipsis: true,
            width: "150px",
            ...getColumnSearchProps('client_name', 'Group'),
            filteredValue: filteredInfo1.client_name || null,

        },
        {
            title: 'Business Group',
            dataIndex: 'business_group_name',
            key: 'business_group_name',
            ellipsis: true,
            width: "130px",
            ...getColumnSearchProps('business_group_name', 'Business Group'),
            filteredValue: filteredInfo1.business_group_name || null,

        },
        {
            title: 'Country',
            dataIndex: 'country_name',
            key: 'country_name',
            ellipsis: true,
            width: "100px",
            ...getColumnSearchProps('country_name', 'Country'),
            filteredValue: filteredInfo1.country_name || null,

        },
        {
            title: 'Legal Entity',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            ellipsis: true,
            width: "130px",
            ...getColumnSearchProps('legal_entity_name', 'Legal Entity'),
            filteredValue: filteredInfo1.legal_entity_name || null,

        },
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
            ellipsis: true,
            width: "200px",
            ...getColumnSearchProps('product_name', 'Product'),
            filteredValue: filteredInfo1.product_name || null,

        },
        {
            title: 'Edit',
            dataIndex: 'task_sub_group_name',
            key: 'task_sub_group_name',
            ellipsis: true,
            width: "50px",
            render: (text, record, i) => {
                if (record.is_approved != 0) {
                    return <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                    // onClick={(e) => { editUser(record.user_id, record) }}
                    >
                        <Tooltip title="Click Here to Edit" color='blue'>
                            <EditOutlined /></Tooltip>
                        {/* <i className="ri-edit-line"></i> */}
                    </span>
                }
                else {
                    return false
                }
            }

        },
    ];



    const previous = () => {
        window.location.reload()
    }








    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(false);
        setFilterModalVisible(true);
    };



    return (
        <>
            <div id="page-wrapper" className="page-wrapper">
                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span>Clients Unit</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div
                            className="
                            col-lg-6 col-md-6 col-sm-6 col-xs-6
               d-flex
                align-items-center
                justify-content-sm-start justify-content-md-end">
                            {/* <div> <Tooltip title="" color='#1890ff'>
                                <Button type="primary" shape="round" className='addbutton'

                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }}>
                                    Add
                                </Button>

                            </Tooltip></div> */}
                            <div className="wrapper">
                                {show == true && addType == 'list' &&
                                    <Tooltip title="Advanced Filter" placement='top'><Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                        icon={<FilterOutlined />} size='default' onClick={() => {
                                            setShowModal(true)

                                        }} >
                                        Filter
                                    </Button></Tooltip>}
                                {addType == 'list' ?
                                    <Tooltip title="Add User" placement='top'><Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                        icon={<PlusCircleOutlined />} size='default'
                                        onClick={() => {

                                            setAddType('add')
                                            setEntityData(filterData && filterData.unit_legal_entity
                                            )
                                        }}
                                    >
                                        Add
                                    </Button></Tooltip>
                                    : <Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                        icon={<ArrowLeftOutlined />} size='default'
                                        onClick={() => {

                                            setAddType('list')
                                        }}
                                    >
                                        Back
                                    </Button>}

                                {/* <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius"  >
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button> */}

                                {/* <button onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
                                    <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                    <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                </button> */}
                            </div>

                        </div>

                    </div>
                </div>
                {addType == 'list' ?
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                                    {show == true ?
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={lists && lists.client_unit_list}
                                            //dataSource={datavelue[0]}
                                            bordered
                                            onChange={handleChange}

                                            pagination={false}
                                        // scroll={{ x: 1100 }}
                                        //onChange={handleChange} 
                                        /> :
                                        <Collapse defaultActiveKey={["1"]} >
                                            <Panel header="
Clients Unit
" key={1}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Client Group : </b></label>
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Client Group"
                                                                    showSearch
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    // value={taskcategorymapping.countryName || undefined}
                                                                    onChange={(value, data) => {
                                                                        let FilterDatas = _.filter(filterData && filterData.unit_legal_entity, {
                                                                            client_id
                                                                                : Number(value)
                                                                        })
                                                                        setEntityData(FilterDatas)
                                                                        setTableValue({
                                                                            ...tableValue,
                                                                            client: value,
                                                                            legalEntity: ''
                                                                        })
                                                                    }}
                                                                >
                                                                    {clientData && clientData.length > 0 && clientData.map((item, i) => {
                                                                        return (
                                                                            <Option title=" " key={item.client_id}>
                                                                                {item.group_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {/* {validator.current.message(
                                                            'country',
                                                            taskcategorymapping.country,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label><b>Legal Enity : </b></label>
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    showSearch
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    // value={taskcategorymapping.countryName || undefined}
                                                                    onChange={(value, data) => {
                                                                        setTableValue({
                                                                            ...tableValue,
                                                                            legalEntity: value
                                                                        })
                                                                    }}
                                                                >
                                                                    {entityData && entityData.length > 0 && entityData.map((item, i) => {
                                                                        return (
                                                                            <Option title=" " key={item.legal_entity_id}>
                                                                                {item.legal_entity_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {/* {validator.current.message(
                                                            'country',
                                                            taskcategorymapping.country,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Button
                                                                onClick={() => {
                                                                    const payload =
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetClients",
                                                                            {
                                                                                "cl_id": tableValue.client !== '' ? Number(tableValue.client) : 0,
                                                                                "leid": tableValue.legalEntity !== '' ? Number(tableValue.legalEntity) : 0,
                                                                                "load_all_data": false
                                                                            }
                                                                        ]
                                                                    }

                                                                    setShow(true)

                                                                    getClientunitList({
                                                                        payload: payload,
                                                                        caller: paramid,
                                                                    })


                                                                }}
                                                                type="primary"
                                                                shape="round"
                                                                className='addbutton'
                                                                icon={<EyeOutlined />}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    marginLeft: '30%'
                                                                }}
                                                            >
                                                                Show
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className='col-md-12' style={{ textAlign: "center", marginLeft: "23%" }}>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <Button
                                                        onClick={() => {
                                                            const payload =
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetApproveTaskCategoryMapping",
                                                                    {
                                                                        "c_id": Number(taskcategorymapping.country),
                                                                        "task_group_id": taskcategorymapping.category ? Number(taskcategorymapping.category) : null,
                                                                        "task_sub_group_id": taskcategorymapping.subcategory ? Number(taskcategorymapping.subcategory) : null
                                                                    }
                                                                ]
                                                            }

                                                            setAddFormSubmit(true);
                                                            if (validator.current.allValid()) {
                                                                setShow(true)
                                                                setExitCollapse(true);
                                                                setFilterTaskAcc(false);
                                                                ApproveTaskCategoryMapping({
                                                                    payload: payload,
                                                                    paramid: paramid
                                                                })
                                                            }

                                                        }}
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton'
                                                        icon={<EyeOutlined />}
                                                        style={{
                                                            marginTop: "20px"
                                                        }}
                                                    >
                                                        Show
                                                    </Button>
                                                </div>
                                            </div>
                                        </div> */}
                                            </Panel>
                                        </Collapse>
                                    }

                                </div>

                            </div>
                        </div>
                    </div> :
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: "5px" }}>

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><b>Group : </b><span style={{ "color": "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Group"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            // value={taskcategorymapping.countryName || undefined}
                                                            onChange={(value, data) => {
                                                                let FilterDatas = _.filter(filterData && filterData.countries_units
                                                                    && filterData.countries_units
                                                                        .length > 0 && filterData.countries_units, {
                                                                    client_id
                                                                        : Number(value)
                                                                })

                                                                let uniqueObjArray2 = [...new Map(FilterDatas && FilterDatas
                                                                    .length > 0 && FilterDatas
                                                                        .map((item) => [item["country_id"], item])).values()];
                                                                // let uniqueUser = [...new Map(uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => [item["user_id"], item])).values()];
                                                                const uniqueIds2 = uniqueObjArray2 && uniqueObjArray2.map((item) => {
                                                                    return item
                                                                })
                                                                setCountryData(uniqueIds2)
                                                                let filterValue = _.filter(filterData.business_group_list, { client_id: Number(value) })
                                                                setBusinessGroupData(filterValue)
                                                                setShowData({
                                                                    ...showData,
                                                                    client: value,
                                                                    legalEntity: '',
                                                                    country: '',
                                                                    client_name: data.children
                                                                })
                                                            }}
                                                        >
                                                            {clientData && clientData.length > 0 && clientData.map((item, i) => {
                                                                return (
                                                                    <Option title=" " key={item.client_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'Group',
                                                            showData.client,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Group Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>Business Group : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Business Group"
                                                            showSearch
                                                            disabled={showData.client !== '' ? false : true}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            // value={taskcategorymapping.countryName || undefined}
                                                            onChange={(value, data) => {
                                                                setShowData({
                                                                    ...showData,
                                                                    business: value,
                                                                    // legalEntity: ''
                                                                })
                                                            }}
                                                        >
                                                            {businessGroupData && businessGroupData.length > 0 && businessGroupData.map((item, i) => {
                                                                return (
                                                                    <Option title=" " key={item.business_group_id
                                                                    }>
                                                                        {item.business_group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* {validator.current.message(
                                                         'country',
                                                         taskcategorymapping.country,
                                                         ['required'],
                                                         {
                                                             className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                             messages: {
                                                                 required: 'Country Required',
                                                             }
                                                         })} */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: '5px' }}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><b>Country : </b><span style={{ "color": "red" }}>*</span></label>

                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        disabled={showData.client !== '' ? false : true}
                                                        value={showData.country || undefined}
                                                        onChange={(value, data) => {
                                                            let FilterDatas = _.filter(filterData && filterData.unit_legal_entity, {
                                                                country_id
                                                                    : Number(value), client_id: Number(showData.client)

                                                            })
                                                            setEntityData(FilterDatas)

                                                            setShowData({
                                                                ...showData,
                                                                country: value,
                                                                legalEntity: ''
                                                            })
                                                        }}
                                                    >
                                                        {countryData && countryData.length > 0 && countryData.map((item, i) => {
                                                            return (
                                                                <Option title=" " key={item.country_id
                                                                }>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        showData.country,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>Legal Entity : </b><span style={{ "color": "red" }}>*</span></label>

                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        disabled={showData.country !== '' ? false : true}
                                                        value={showData.legalEntity || undefined}
                                                        // value={taskcategorymapping.countryName || undefined}
                                                        onChange={(value, data) => {
                                                            setShowData({
                                                                ...showData,
                                                                legalEntity: value
                                                            })

                                                            const payload =
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetClients",
                                                                    {
                                                                        "cl_id": showData.client !== '' ? Number(showData.client) : 0,
                                                                        "leid": Number(value),
                                                                        "load_all_data": true
                                                                    }
                                                                ]
                                                            }



                                                            getClientunitList({
                                                                payload: payload,
                                                                caller: paramid,
                                                            })
                                                        }}
                                                    >
                                                        {entityData && entityData.length > 0 && entityData.map((item, i) => {
                                                            return (
                                                                <Option title=" " key={item.legal_entity_id}>
                                                                    {item.legal_entity_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'Legal Entity',
                                                        showData.legalEntity,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}

                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    Total Unit(s) : {unitscount}
                                                </div>

                                                <div className="col-md-6 text-end">
                                                    <a href='javascript:;' onClick={() => {
                                                        setAddFormSubmit(true)
                                                        if (validator.current.allValid()) {
                                                            const payload =
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetClientsEdit",
                                                                    // {
                                                                    //     "cl_id": tableValue.client !== '' ? Number(tableValue.client) : 0,
                                                                    //     "leid": tableValue.legalEntity !== '' ? Number(tableValue.legalEntity) : 0,
                                                                    //     "load_all_data": false
                                                                    // },
                                                                    {
                                                                        "client_id": Number(showData.client),
                                                                        "bg_id": showData.business != '' ? Number(showData.business) : 0,
                                                                        "le_id": Number(showData.legalEntity),
                                                                        "c_id": Number(showData.country),
                                                                        "from_count": 0,
                                                                        "page_count": 0,
                                                                        "unit_ids": []
                                                                    }

                                                                ]
                                                            }

                                                            setShow(true)

                                                            getaddunitList({
                                                                payload: payload,
                                                                caller: paramid,
                                                            })
                                                            let temp = []
                                                            temp.push([
                                                                {
                                                                    division: '',
                                                                    parentIndex: count,
                                                                    geographylevel: '',
                                                                    geographyMapping: [],
                                                                    level: '',
                                                                    unitname: '',
                                                                    unitAddress: '',
                                                                    postalcode: '',
                                                                    unitcode: '',
                                                                    unitcode1: '',
                                                                    unitcode2: '',
                                                                    unitcode3: '',
                                                                    unitcode4: '',
                                                                    compfieDomain: [],
                                                                    compfieOrganization: [],
                                                                    litigationDomain: [],
                                                                    vendorDomain: [],
                                                                    vendorOrganization: [],
                                                                    productData: productData,
                                                                    unitLocationData: lists.unit_geographies_list,
                                                                    compfiedomainvalue: [],
                                                                    compfieorganizationValue: [],
                                                                    compfieorganizationValueDisplay: [],
                                                                    litigationdomainvalue: [],
                                                                    vendordomainvalue: [],
                                                                    vendororganizationValue: [],
                                                                    vendororganizationValueDisplay: []

                                                                }])
                                                            setcount(count + 1)
                                                            setUnitData([...unitData, ...temp])
                                                            let temp2 = []
                                                            temp2.push({
                                                                divisionName: null,
                                                                divisionId: null,
                                                                catName: null,
                                                                divNumber: ''
                                                            })
                                                            setDivisionValue([...divisionValue, ...temp2])
                                                        }
                                                    }}><PlusCircleOutlined
                                                        /><span>&nbsp;Add Division/Category/Unit/Vendor</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        {unitData && unitData.length > 0 && unitData.map((item, i) => {
                                            const columns2 = [
                                                {
                                                    title: 'Geography Level',
                                                    dataIndex: false,
                                                    key: false,
                                                    ellipsis: true,
                                                    align: 'center',
                                                    width: '150px',
                                                    render: (text, record, index) => {
                                                        console.log(record, 'record');
                                                        return <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Geography Level"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            // value={taskcategorymapping.countryName || undefined}
                                                            onChange={(value, data) => {
                                                                // let temp = unitData[record.parentIndex]

                                                                let FilterDatas = _.filter(lists.unit_geographies_list, {
                                                                    level_id
                                                                        : Number(value)
                                                                })
                                                                unitData[record.parentIndex][index].unitLocationData = FilterDatas
                                                                unitData[record.parentIndex][index].level = value
                                                                setUnitData([...unitData])
                                                            }}
                                                        >
                                                            {geographyLeval && geographyLeval.length > 0 && geographyLeval.map((item, i) => {
                                                                return (
                                                                    <Option title=" " key={item.l_position
                                                                    }>
                                                                        {item.l_name
                                                                        }
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    }
                                                },
                                                {
                                                    title: 'Unit Location ',
                                                    dataIndex: 'client_name',
                                                    key: 'client_name',

                                                    width: "150px",
                                                    render: (text, record, index) => {
                                                        return <> <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Unit Location"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5%' }}
                                                            value={`${unitData[record.parentIndex][index].geographylevel}`}
                                                            onChange={(value, data) => {
                                                                unitData[record.parentIndex][index].geographylevel = Number(value)
                                                                setUnitData([...unitData])
                                                                let mappingValue = _.filter(unitData[record.parentIndex][index].unitLocationData
                                                                    && unitData[record.parentIndex][index].unitLocationData, { geography_id: Number(value) })
                                                                unitData[record.parentIndex][index].geographyMapping = mappingValue && mappingValue.length > 0 && mappingValue[0].mapping
                                                                console.log(mappingValue, 'mappingValue');
                                                            }}
                                                        >
                                                            {unitData[record.parentIndex][index].unitLocationData
                                                                && unitData[record.parentIndex][index].unitLocationData.length > 0 && unitData[record.parentIndex][index].unitLocationData.map((item, i) => {
                                                                    return (
                                                                        <Option title=" " key={item.geography_id}>
                                                                            {item.geography_name
                                                                            }
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                            <p style={{ wordBreak: 'break-all' }}>{unitData[record.parentIndex][index].geographyMapping && unitData[record.parentIndex][index].geographyMapping.length > 0 && unitData[record.parentIndex][index].geographyMapping[0]}</p></>
                                                    }

                                                },
                                                {
                                                    title: <><input type='checkbox' onChange={() => {
                                                        const payload =
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetNextUnitCode",
                                                                {
                                                                    "client_id": Number(showData.client),
                                                                    "legal_entity_id": Number(showData.legalEntity)
                                                                }
                                                            ]
                                                        }



                                                        autoGentrateUnitCode({
                                                            payload: payload,
                                                            caller: paramid,
                                                        })
                                                        setParentIndexValue(i)

                                                    }}></input>&nbsp;<span>Unit/Vendor Code</span></>,
                                                    dataIndex: 'business_group_name',
                                                    key: 'business_group_name',
                                                    ellipsis: true,
                                                    width: "205px",
                                                    render: (text, record, index) => {
                                                        return <div><div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-5" style={{ width: '40%' }}>
                                                                    <Input value={unitData[record.parentIndex][index].unitcode} className='form-control' onChange={(e) => {
                                                                        unitData[record.parentIndex][index].unitcode = e.target.value
                                                                        setUnitData([...unitData])
                                                                    }}></Input>
                                                                </div>
                                                                <div className="col-md-7" style={{ padding: 0, width: '40%' }}>
                                                                    <Button type="primary" onClick={() => {
                                                                        setUnitModal(true)
                                                                        setParentIndexValue(record.parentIndex)
                                                                        setChildIndex(index)
                                                                    }} shape="round" >Additional Input</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }

                                                },
                                                {
                                                    title: 'Product',
                                                    dataIndex: 'country_name',
                                                    key: 'country_name',
                                                    ellipsis: true,
                                                    width: "150px",

                                                    render: (text, record, index) => {
                                                        return <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Client Group"
                                                            showSearch
                                                            mode='multiple'
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            maxTagCount='responsive'
                                                            value={unitData[record.parentIndex][index].productData}
                                                            // onChange={(option) => {
                                                            //     console.log(option, 'optionssss');
                                                            // }}
                                                            onChange={(value, data) => {
                                                                console.log(value, 'value');
                                                                console.log(data, 'data9999999999999');
                                                                unitData[record.parentIndex][index].productData = value
                                                                setUnitData([...unitData])

                                                            }}
                                                        >
                                                            {productData && productData.length > 0 && productData.map((item, i) => {
                                                                return (
                                                                    <Option title=" " key={item}>
                                                                        {item}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    }

                                                },
                                                {
                                                    title: 'Domain/ Organization',
                                                    dataIndex: 'legal_entity_name',
                                                    key: 'legal_entity_name',

                                                    width: "100px",
                                                    render: (text, record, index) => {
                                                        return <a href='javascript:;' onClick={() => {
                                                            setDomainDetailsModal(true)
                                                            setParentIndexValue(record.parentIndex)
                                                            setChildIndex(index)

                                                        }}
                                                        ><PlusCircleOutlined
                                                            /><span>&nbsp;Details</span></a>
                                                    }


                                                },
                                                {
                                                    title: 'Status',
                                                    dataIndex: 'product_name',
                                                    key: 'product_name',
                                                    align: 'center',
                                                    ellipsis: true,
                                                    width: "100px",
                                                    render: (text, record, i) => {
                                                        return <p>Active</p>
                                                    }

                                                },
                                                {
                                                    title: 'Approve',
                                                    dataIndex: 'task_sub_group_name',
                                                    key: 'task_sub_group_name',
                                                    align: 'center',
                                                    width: "100px",
                                                    render: (text, record, i) => {

                                                        return <p>Pending</p>

                                                    }

                                                },
                                                {
                                                    title: 'Remove',
                                                    dataIndex: 'product_name',
                                                    key: 'product_name',
                                                    ellipsis: true,
                                                    align: 'center',
                                                    width: "50px",
                                                    render: (text, record, i) => {
                                                        return <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                                        // onClick={(e)  => { editUser(record.user_id, record) }}
                                                        >
                                                            <Tooltip title="Remove" color='blue'>
                                                                <DeleteOutlined onClick={() => {
                                                                    if (unitData[record.parentIndex].length !== 1) {
                                                                        Swal.fire({
                                                                            title: "Are You sure?,You Want to remove the row?",
                                                                            icon: "info",
                                                                            showCancelButton: true,
                                                                            focusConfirm: false,
                                                                            confirmButtonText: "Ok",
                                                                            cancelButtonText: "Cancel",
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                unitData[record.parentIndex].splice(i, 1)
                                                                                setUnitData([...unitData])
                                                                                setParentIndexValue('')
                                                                                setChildIndex('')
                                                                            }
                                                                        });

                                                                    }
                                                                }} /></Tooltip>
                                                            {/* <i className="ri-edit-line"></i> */}
                                                        </span>

                                                    }

                                                },
                                            ];
                                            return <>
                                                <div className="card" style={{ marginTop: '3%' }}>
                                                    <div className="col-md-12" >
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label style={{ marginLeft: '1%' }}><b>Division: </b></label>
                                                                {divisionEdit == true ? <Input
                                                                    placeholder="Enter Division"
                                                                    onChange={(e) => {

                                                                        divisionValue[i].divisionId = null
                                                                        divisionValue[i].divisionName = e.target.value
                                                                        divisionValue[i].divNumber = Number(i) + 1
                                                                        setDivisionValue([...divisionValue])
                                                                    }}
                                                                    style={{ width: '90%' }} className='form-control'></Input> :
                                                                    <Select
                                                                        allowClear={false}
                                                                        size="default"
                                                                        placeholder="Enter Division"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '90%' }}
                                                                        // value={taskcategorymapping.countryName || undefined}
                                                                        onChange={(value, data) => {
                                                                            console.log(data, 'data9325');
                                                                            divisionValue[i].divisionId = Number(value)
                                                                            divisionValue[i].divisionName = data.children
                                                                            divisionValue[i].divNumber = Number(i) + 1
                                                                            setDivisionValue([...divisionValue])
                                                                        }}
                                                                    >
                                                                        {divisionData && divisionData.length > 0 && divisionData.map((item, i) => {
                                                                            return (
                                                                                <Option title=" " key={item.division_id
                                                                                }>
                                                                                    {item.division_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>}
                                                                &nbsp;<a href='javascript:;' onClick={() => {
                                                                    setdivisionEdit(!divisionEdit)
                                                                }}
                                                                >{divisionEdit == true ? <CloseCircleOutlined /> : <PlusCircleOutlined
                                                                />}</a>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label><b>Category: </b></label>
                                                                {categoryEdit == true ? <Input
                                                                    placeholder="Enter Category"
                                                                    onChange={(e) => {
                                                                        divisionValue[i].catName = e.target.value
                                                                        setDivisionValue([...divisionValue])
                                                                    }}
                                                                    style={{ width: '90%' }} className='form-control'></Input>
                                                                    : <Select
                                                                        allowClear={false}
                                                                        size="default"
                                                                        placeholder="Enter Category"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '90%' }}
                                                                        // value={taskcategorymapping.countryName || undefined}
                                                                        onChange={(value, data) => {
                                                                            divisionValue[i].catName = data.children
                                                                            setDivisionValue([...divisionValue])
                                                                        }}
                                                                    >
                                                                        {categoryData && categoryData.length > 0 && categoryData.map((item, i) => {
                                                                            return (
                                                                                <Option title=" " key={item.category_id
                                                                                }>
                                                                                    {item.category_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                }
                                                                &nbsp;<a href='javascript:;' onClick={() => {
                                                                    setCategoryEdit(!categoryEdit)
                                                                }}
                                                                >{categoryEdit == true ? <CloseCircleOutlined /> : <PlusCircleOutlined
                                                                />}</a>
                                                            </div>
                                                            <div className="col-md-4">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Table
                                                        className='userprivclass'
                                                        columns={columns2}
                                                        key={unitData[i]}
                                                        dataSource={unitData[i]}
                                                        //dataSource={datavelue[0]}
                                                        bordered
                                                        // onChange={() => {
                                                        //     console.log(i, 'iiiiiii');
                                                        //     setParentIndexValue(i)
                                                        // }}

                                                        pagination={false}
                                                    // scroll={{ x: 1100 }}

                                                    />
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                            </div>
                                                            <div className="col-md-6 text-end" >
                                                                <a href='javascript:;' onClick={() => {
                                                                    let temp = unitData[i]
                                                                    temp.push({
                                                                        division: '',
                                                                        parentIndex: i,
                                                                        geographylevel: '',
                                                                        level: '',
                                                                        geographyMapping: [],
                                                                        unitname: '',
                                                                        unitAddress: '',
                                                                        postalcode: '',
                                                                        unitcode: '',
                                                                        unitcode1: '',
                                                                        unitcode2: '',
                                                                        unitcode3: '',
                                                                        unitcode4: '',
                                                                        compfieDomain: [],
                                                                        compfieOrganization: [],
                                                                        litigationDomain: [],
                                                                        vendorDomain: [],
                                                                        vendorOrganization: [],
                                                                        productData: productData,
                                                                        unitLocationData: lists.unit_geographies_list,
                                                                        compfiedomainvalue: [],
                                                                        compfieorganizationValue: [],
                                                                        compfieorganizationValueDisplay: [],
                                                                        litigationdomainvalue: [],
                                                                        vendordomainvalue: [],
                                                                        vendororganizationValue: [],
                                                                        vendororganizationValueDisplay: []

                                                                    })
                                                                    setUnitData([...unitData])

                                                                }}
                                                                ><PlusCircleOutlined
                                                                    /><span>&nbsp;Add Unit/Vendor</span></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })}
                                        <div className='text-center'>
                                            <Button
                                                onClick={() => {
                                                    let temp = []
                                                    for (let i in divisionValue) {
                                                        temp.push({
                                                            "dv_id": divisionValue[i].divisionId,
                                                            "dv_name": divisionValue[i].divisionName,
                                                            "cg": divisionValue[i].catName,
                                                            "div_cnt": Number(i) + 1,
                                                            "unit_cnt": unitData[i] && unitData[i].length
                                                        })
                                                    }
                                                    let temp2 = []
                                                    let geographylevelValidation = []
                                                    let unitlocationValidation = []
                                                    let unitname = []
                                                    let unitAddress = []
                                                    let postalcode = []
                                                    let unitcode = []
                                                    for (let i in unitData) {
                                                        for (let j in unitData[i]) {
                                                            if (unitData[i][j].geographylevel !== '') {
                                                                unitlocationValidation.push(true)
                                                            }
                                                            else {
                                                                unitlocationValidation.push(false)
                                                            }
                                                            if (unitData[i][j].unitname !== '') {
                                                                unitname.push(true)
                                                            }
                                                            else {
                                                                unitname.push(false)
                                                            }
                                                            if (unitData[i][j].unitcode !== '') {
                                                                unitcode.push(true)
                                                            }
                                                            else {
                                                                unitcode.push(false)
                                                            }
                                                            if (unitData[i][j].unitAddress !== '') {
                                                                unitAddress.push(true)
                                                            }
                                                            else {
                                                                unitAddress.push(false)
                                                            }
                                                            if (unitData[i][j].postalcode !== '') {
                                                                postalcode.push(true)
                                                            }
                                                            else {
                                                                postalcode.push(false)
                                                            }
                                                            if (unitData[i][j].level !== '') {
                                                                geographylevelValidation.push(true)
                                                            }
                                                            else {
                                                                geographylevelValidation.push(false)
                                                            }

                                                        }
                                                    }
                                                    for (let i in unitData) {
                                                        for (let j in unitData[i]) {
                                                            console.log(unitData, 'unitData977577');
                                                            console.log(unitData[i][j], 'unitData[i][j]');
                                                            temp2.push({

                                                                "unit_id": null,
                                                                "unit_name": unitData[i][j].unitname,
                                                                "unit_code": unitData[i][j].unitcode,
                                                                "unitcodedynamicdata": [
                                                                    {
                                                                        "dynamic_data-0": unitData[i][j].unitcode1,
                                                                        "dynamic_data-1": unitData[i][j].unitcode2,
                                                                        "dynamic_data-2": unitData[i][j].unitcode3,
                                                                        "dynamic_data-3": unitData[i][j].unitcode4
                                                                    }
                                                                ],
                                                                "address": unitData[i][j].unitAddress,
                                                                "postal_code": unitData[i][j].postalcode,
                                                                "geography_id": unitData[i][j].geographylevel,
                                                                "d_ids": unitData[i][j].compfieDomain,
                                                                "i_ids_list": unitData[i][j].compfieorganizationValue,
                                                                "is_approved": 0,
                                                                "tz_id": unitListData && unitListData.tz_country_postal_list && unitListData.tz_country_postal_list.length > 0 && unitListData.tz_country_postal_list[0].tz_id,
                                                                "unit_remarks": "",
                                                                "limt_domain": unitData[i][j].litigationDomain,
                                                                "is_compfie_limt": [
                                                                    {
                                                                        "is_cpmpf": unitData[i][j] && unitData[i][j].productData && unitData[i][j].productData.length > 0 && unitData[i][j].productData.includes('Compfie') ? 1 : 0
                                                                    },
                                                                    {
                                                                        "is_limt": unitData[i][j] && unitData[i][j].productData && unitData[i][j].productData.length > 0 && unitData[i][j].productData.includes(' Compfie Litigation') ? 1 : 0
                                                                    },
                                                                    {
                                                                        "is_vndr": unitData[i][j] && unitData[i][j].productData && unitData[i][j].productData.length > 0 && unitData[i][j].productData.includes(' Compfie Vendor') ? 1 : 0
                                                                    }
                                                                ],
                                                                "v_i_ids_list": unitData[i][j].vendorOrganization,
                                                                "v_d_ids": unitData[i][j].vendorDomain

                                                            })
                                                        }

                                                        console.log(temp, 'temp2127');
                                                        console.log(unitlocationValidation, 'unitlocationValidation');


                                                    }
                                                    if (geographylevelValidation && geographylevelValidation.length > 0 && geographylevelValidation.includes(false) == false) {
                                                        if (unitlocationValidation && unitlocationValidation.length > 0 && unitlocationValidation.includes(false) == false) {
                                                            if (unitcode && unitcode.length > 0 && unitcode.includes(false) == false) {
                                                                if (unitname && unitname.length > 0 && unitname.includes(false) == false) {
                                                                    if (unitAddress && unitAddress.length > 0 && unitAddress.includes(false) == false) {
                                                                        if (postalcode && postalcode.length > 0 && postalcode.includes(false) == false) {

                                                                            const payload =
                                                                            {
                                                                                "session_token": authtoken,
                                                                                "request": [
                                                                                    "SaveClient",
                                                                                    {
                                                                                        "cl_id": Number(showData.client),
                                                                                        "bg_id": showData.business !== '' ? Number(showData.business) : null,
                                                                                        "le_id": Number(showData.legalEntity),
                                                                                        "c_id": Number(showData.country),
                                                                                        "division_units": temp,
                                                                                        "units": temp2
                                                                                        ,
                                                                                        "division_category": [],
                                                                                        "product": productData1,
                                                                                        "mode": 0
                                                                                    }
                                                                                ]
                                                                            }

                                                                            console.log(payload, 'payloadddd');

                                                                            addclientUnit({
                                                                                payload: payload,
                                                                                caller: paramid,
                                                                            })
                                                                        }
                                                                        else {
                                                                            Toaster.error("Unit Postal Code Required")
                                                                        }
                                                                    }

                                                                    else {
                                                                        Toaster.error("Unit/Vendor Address Required")
                                                                    }
                                                                }
                                                                else {
                                                                    Toaster.error("Unit/Vendor Name Required")
                                                                }
                                                            }
                                                            else {
                                                                Toaster.error("Unit Code Required")
                                                            }
                                                        }
                                                        else {
                                                            Toaster.error("Unit Location Name Required")
                                                        }
                                                    }
                                                    else {
                                                        Toaster.error("Geography Level Required")
                                                    }
                                                }
                                                }
                                                type="primary"
                                                shape="round"
                                                className={
                                                    "addbutton " + localStorage.getItem("currentTheme")
                                                }
                                                icon={<PlayCircleOutlined />}
                                                style={{
                                                    marginTop: "20px",
                                                    // marginLeft: '44%'
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {/* <div className='col-md-12' style={{ textAlign: "center", marginLeft: "23%" }}>
                                         <div className='row'>
                                             <div className="col-md-6">
                                                 <Button
                                                     onClick={() => {
                                                         const payload =
                                                         {
                                                             "session_token": authtoken,
                                                             "request": [
                                                                 "GetApproveTaskCategoryMapping",
                                                                 {
                                                                     "c_id": Number(taskcategorymapping.country),
                                                                     "task_group_id": taskcategorymapping.category ? Number(taskcategorymapping.category) : null,
                                                                     "task_sub_group_id": taskcategorymapping.subcategory ? Number(taskcategorymapping.subcategory) : null
                                                                 }
                                                             ]
                                                         }

                                                         setAddFormSubmit(true);
                                                         if (validator.current.allValid()) {
                                                             setShow(true)
                                                             setExitCollapse(true);
                                                             setFilterTaskAcc(false);
                                                             ApproveTaskCategoryMapping({
                                                                 payload: payload,
                                                                 paramid: paramid
                                                             })
                                                         }

                                                     }}
                                                     type="primary"
                                                     shape="round"
                                                     className='addbutton'
                                                     icon={<EyeOutlined />}
                                                     style={{
                                                         marginTop: "20px"
                                                     }}
                                                 >
                                                     Show
                                                 </Button>
                                             </div>
                                         </div>
                                     </div> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Modal
                    title="Advanced Filter"
                    className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                    footer={false}
                    open={showModal}
                    onCancel={() => {
                        setShowModal(false)
                    }}
                >
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label><b>Client Group : </b></label>
                                <div className="form-group">
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder="Enter Client Group"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        // value={taskcategorymapping.countryName || undefined}
                                        onChange={(value, data) => {
                                            let FilterDatas = _.filter(filterData && filterData.unit_legal_entity, {
                                                client_id
                                                    : Number(value)
                                            })
                                            setEntityData(FilterDatas)
                                            setTableValue({
                                                ...tableValue,
                                                client: value,
                                                legalEntity: ''
                                            })
                                        }}
                                    >
                                        {clientData && clientData.length > 0 && clientData.map((item, i) => {
                                            return (
                                                <Option title=" " key={item.client_id}>
                                                    {item.group_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {/* {validator.current.message(
                                                            'country',
                                                            taskcategorymapping.country,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })} */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label><b>Legal Enity : </b></label>
                                <div className="form-group">
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        placeholder="Enter Legal Entity"
                                        showSearch
                                        getPopupContainer={trigger => trigger.parentNode}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        // value={taskcategorymapping.countryName || undefined}
                                        onChange={(value, data) => {
                                            setTableValue({
                                                ...tableValue,
                                                legalEntity: value
                                            })
                                        }}
                                    >
                                        {entityData && entityData.length > 0 && entityData.map((item, i) => {
                                            return (
                                                <Option title=" " key={item.legal_entity_id}>
                                                    {item.legal_entity_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {/* {validator.current.message(
                                                            'country',
                                                            taskcategorymapping.country,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })} */}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="popupbtncolour text-center">
                        <Button
                            onClick={() => {
                                const payload =
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetClients",
                                        {
                                            "cl_id": tableValue.client !== '' ? Number(tableValue.client) : 0,
                                            "leid": tableValue.legalEntity !== '' ? Number(tableValue.legalEntity) : 0,
                                            "load_all_data": false
                                        }
                                    ]
                                }

                                setShow(true)
                                setShowModal(false)
                                getClientunitList({
                                    payload: payload,
                                    caller: paramid,
                                })


                            }}
                            type="primary"
                            shape="round"
                            className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            }
                            icon={<EyeOutlined />}
                            style={{
                                marginTop: "20px",
                                // marginLeft: '44%'
                            }}
                        >
                            Show
                        </Button>
                    </div>
                </Modal>
                <Modal
                    title="Unit Details"
                    className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                    footer={false}
                    open={unitModal}
                    onCancel={() => {
                        setUnitModal(false)
                        unitData[parentIndexValue][childIndex].unitname = ''
                        unitData[parentIndexValue][childIndex].unitAddress = ''
                        unitData[parentIndexValue][childIndex].postalcode = ''
                        unitData[parentIndexValue][childIndex].unitcode1 = ''
                        unitData[parentIndexValue][childIndex].unitcode2 = ''
                        unitData[parentIndexValue][childIndex].unitcode3 = ''
                        unitData[parentIndexValue][childIndex].unitcode4 = ''
                        setUnitData([...unitData])
                    }}
                >
                    <form>

                        <fieldset id='fieldset' style={{ border: '1px solid' }}>
                            <legend style={{ padding: '5px 10px', fontSize: '15px', fontWeight: 'bold' }}>Additional Code(s)</legend>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Code1: </label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitcode1} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].unitcode1 = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Code2: </label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitcode2} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].unitcode2 = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Code3: </label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitcode3} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].unitcode3 = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Code4: </label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitcode4} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].unitcode4 = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </fieldset>

                    </form>
                    <form style={{ marginTop: '3%' }}>

                        <fieldset id='fieldset' style={{ border: '1px solid' }}>
                            <legend style={{ padding: '5px 10px', fontSize: '15px', fontWeight: 'bold' }}>Unit Names</legend>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Name: <span style={{ "color": "red" }}>*</span></label>
                                        <div className="form-group">
                                            <Input value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitname}
                                                onChange={(e) => {
                                                    unitData[parentIndexValue][childIndex].unitname = e.target.value
                                                    setUnitData([...unitData])
                                                }} className='form-control'></Input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Unit/Vendor Address: <span style={{ "color": "red" }}>*</span></label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].unitAddress} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].unitAddress = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Postal Code: <span style={{ "color": "red" }}>*</span></label>
                                        <div className="form-group">
                                            <Input className='form-control' value={unitData[parentIndexValue] && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].postalcode} onChange={(e) => {
                                                unitData[parentIndexValue][childIndex].postalcode = e.target.value
                                                setUnitData([...unitData])
                                            }}></Input>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </fieldset>

                    </form>
                    <div className="popupbtncolour text-center">
                        <Button
                            onClick={() => {
                                setUnitModal(false)


                            }}
                            type="primary"
                            shape="round"
                            className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            }
                            // icon={<EyeOutlined />}
                            style={{
                                marginTop: "20px",
                                // marginLeft: '44%'
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Modal>
                {parentIndexValue !== '' && childIndex !== '' ?
                    <Modal
                        title="Add Domain Details"
                        className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                        footer={false}
                        open={domainDetailsModal}
                        onCancel={() => {
                            setDomainDetailsModal(false)
                        }}
                    >
                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                <div className='ant-table-container'>
                                    <div className='ant-table-content  userprivclass1'></div>
                                    <table>
                                        <colgroup>
                                            <col style={{ width: '10px' }}></col>
                                        </colgroup>
                                        <thead className="ant-table-thead table-head-stick" >
                                            <tr>

                                                <th className={'ant-table-cell'} style={{ width: '30%' }}>Group</th>
                                                <th className={'ant-table-cell'} style={{ width: '30%' }}>Country</th>
                                                <th className={'ant-table-cell'} style={{ width: '30%' }}>Legal Entity</th>


                                            </tr>
                                        </thead>
                                        <tbody className='ant-table-tbody'>
                                            <tr>
                                                <td className={'ant-table-cell'}><p>{lists && lists.client_unit_list && lists.client_unit_list.length > 0 && lists.client_unit_list[0].client_name}</p></td>
                                                <td className={'ant-table-cell'}><p>{lists && lists.client_unit_list && lists.client_unit_list.length > 0 && lists.client_unit_list[0].country_name}</p></td>
                                                <td className={'ant-table-cell'}><p>{lists && lists.client_unit_list && lists.client_unit_list.length > 0 && lists.client_unit_list[0].legal_entity_name}</p></td>
                                            </tr>
                                            {/* {commonArray && commonArray.length > 0 && commonArray.map((commonList) => {
                                        return (
                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                <td className={'ant-table-cell'}><center>{sno + 1}</center></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.assignee)
                                                }}>{commonList.assignee ? commonList.assignee : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.concur)
                                                }}>{commonList.concur ? commonList.concur : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.approver)
                                                }}>{commonList.approver ? commonList.approver : ''}</Link></td>
                                                <span hidden>
                                                    {
                                                        (sno = sno + 1)
                                                    }
                                                </span>
                                            </tr>
                                        )
                                    })} */}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Tabs
                            //  activeKey={active} onChange={(key) => {
                            //     setActive(key)
                            // }}
                            type="card" >
                            {unitData && unitData.length > 0 && parentIndexValue != undefined && childIndex != undefined && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].productData && unitData[parentIndexValue][childIndex].productData.length > 0 && unitData[parentIndexValue][childIndex].productData.includes('Compfie') ?
                                <TabPane tab="Compfie" key="1" >
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content  userprivclass1'></div>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className="ant-table-thead table-head-stick" >
                                                        <tr>

                                                            <th className={'ant-table-cell'} style={{ width: '30%' }}>Domain</th>
                                                            <th className={'ant-table-cell'} style={{ width: '30%' }}>Organization</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody className='ant-table-tbody'>
                                                        <tr>
                                                            <td> <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Domain"
                                                                showSearch
                                                                mode='multiple'
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                value={unitData[parentIndexValue][childIndex].compfiedomainvalue || undefined}
                                                                onChange={(value, data) => {
                                                                    console.log(value, 'value');
                                                                    let temp = []
                                                                    for (let i in value) {
                                                                        temp.push(Number(value[i]))
                                                                    }


                                                                    let arrunit = [];
                                                                    let totalListunit = []
                                                                    let countrylisttempdataunit = [];

                                                                    for (let i in value) {
                                                                        let entityData = _.filter(lists && lists.domains_organization_list, { domain_id: Number(value[i]) })
                                                                        console.log(entityData, 'entityData9604488888888880');
                                                                        arrunit.push(entityData[0])
                                                                    }
                                                                    console.log(arrunit, 'arrunit');

                                                                    arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                                                                        let entityArrayunit = []
                                                                        let tempArrayunit = []

                                                                        lists && lists.domains_organization_list && lists && lists.domains_organization_list.length > 0 && lists && lists.domains_organization_list.map((subunitlist) => {
                                                                            console.log(subunitlist, 'subunitlist');
                                                                            if (pmenu && pmenu.domain_id == subunitlist.domain_id) {
                                                                                entityArrayunit = {
                                                                                    "domain_id": subunitlist.domain_id,

                                                                                    "domain_name": subunitlist.domain_name,

                                                                                    "domain_status": subunitlist.domain_status,

                                                                                    "industry_id": subunitlist.industry_id,

                                                                                    "industry_name": subunitlist.industry_name,

                                                                                    "industry_status": subunitlist.industry_status,

                                                                                    "legal_entity_id": subunitlist.industry_status,

                                                                                    "unit_count": subunitlist.industry_status,

                                                                                    'label': subunitlist.domain_name,
                                                                                    'values': subunitlist.industry_id
                                                                                }
                                                                                tempArrayunit.push(entityArrayunit)
                                                                            }
                                                                        })


                                                                        totalListunit = {
                                                                            'label': pmenu && pmenu.domain_name == null ? 'Others' : pmenu && pmenu.domain_name,
                                                                            'options': tempArrayunit
                                                                        }
                                                                        console.log(totalListunit, 'aishwarya');
                                                                        console.log(totalListunit, 'unitListData')
                                                                        countrylisttempdataunit.push(totalListunit)
                                                                    })
                                                                    unitData[parentIndexValue][childIndex].compfieOrganization = countrylisttempdataunit
                                                                    unitData[parentIndexValue][childIndex].compfieDomain = temp
                                                                    unitData[parentIndexValue][childIndex].compfiedomainvalue = value
                                                                    setUnitData([...unitData])

                                                                }}
                                                            >
                                                                {compfieDomainData && compfieDomainData.length > 0 && compfieDomainData.map((item, i) => {
                                                                    return (
                                                                        <Option title=" " key={item.domain_id
                                                                        }>
                                                                            {item.domain_name
                                                                            }
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select></td>
                                                            <td>
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    placeholder="Enter Organization"
                                                                    showSearch
                                                                    mode='multiple'
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    value={unitData[parentIndexValue][childIndex].compfieorganizationValueDisplay}
                                                                    onChange={(value, data) => {
                                                                        console.log(value, 'data96777');
                                                                        let temp = []
                                                                        for (let i in value) {
                                                                            let splidata = value[i].split('-')
                                                                            console.log(splidata, 'splidata');
                                                                            temp.push({
                                                                                "domain_id": Number(splidata && splidata.length > 0 && splidata[1]),
                                                                                "industry_id": Number(splidata && splidata.length > 0 && splidata[0]),
                                                                            })
                                                                        }
                                                                        unitData[parentIndexValue][childIndex].compfieorganizationValue = temp
                                                                        unitData[parentIndexValue][childIndex].compfieorganizationValueDisplay = value
                                                                        setUnitData([...unitData])
                                                                    }}
                                                                >
                                                                    {/* unitData[record.parentIndex][index].unitLocationData
                        && unitData[record.parentIndex][index].unitLocationData.length > 0 && unitData[record.parentIndex][index].unitLocationData */}
                                                                    {unitData && unitData.length > 0 && parentIndexValue !== undefined && childIndex !== undefined &&
                                                                        unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex]
                                                                        && unitData[parentIndexValue][childIndex].compfieOrganization.length > 0 && unitData[parentIndexValue][childIndex].compfieOrganization.map((finalList_unitlist) => (
                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                    <Option key={`${subUnitData.industry_id}-${subUnitData.domain_id}`}>{subUnitData.industry_name}</Option>

                                                                                ))}
                                                                            </OptGroup>
                                                                        ))}
                                                                </Select></td>
                                                        </tr>
                                                        {/* {commonArray && commonArray.length > 0 && commonArray.map((commonList) => {
                                        return (
                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                <td className={'ant-table-cell'}><center>{sno + 1}</center></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.assignee)
                                                }}>{commonList.assignee ? commonList.assignee : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.concur)
                                                }}>{commonList.concur ? commonList.concur : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.approver)
                                                }}>{commonList.approver ? commonList.approver : ''}</Link></td>
                                                <span hidden>
                                                    {
                                                        (sno = sno + 1)
                                                    }
                                                </span>
                                            </tr>
                                        )
                                    })} */}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane> : ''}
                            {unitData && unitData.length > 0 && parentIndexValue != undefined && childIndex != undefined && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex].productData && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].productData.length > 0 && unitData[parentIndexValue][childIndex].productData.includes(' Compfie Litigation') ?
                                <TabPane tab="Compfie Litigation" key="2" >
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content  userprivclass1'></div>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className="ant-table-thead table-head-stick" >
                                                        <tr>

                                                            <th className={'ant-table-cell'} style={{ width: '30%' }}>Domain</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody className='ant-table-tbody'>
                                                        <tr>
                                                            <td><center> <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Domain"
                                                                showSearch
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                mode='multiple'
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '50%' }}
                                                                value={unitData[parentIndexValue][childIndex].litigationdomainvalue || undefined}
                                                                onChange={(value, data) => {
                                                                    console.log(value, 'value');
                                                                    let temp = []
                                                                    for (let i in value) {
                                                                        temp.push(Number(value[i]))
                                                                    }
                                                                    unitData[parentIndexValue][childIndex].litigationDomain = temp
                                                                    unitData[parentIndexValue][childIndex].litigationdomainvalue = value
                                                                    setUnitData([...unitData])
                                                                }}
                                                            >
                                                                {litiationDomainData && litiationDomainData.length > 0 && litiationDomainData.map((item, i) => {
                                                                    return (
                                                                        <Option title=" " key={item.domain_id
                                                                        }>
                                                                            {item.domain_name
                                                                            }
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select></center></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane> : ""}
                            {unitData && unitData.length > 0 && parentIndexValue != undefined && childIndex != undefined && unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex] && unitData[parentIndexValue][childIndex].productData && unitData[parentIndexValue][childIndex].productData.length > 0 && unitData[parentIndexValue][childIndex].productData.includes(' Compfie Vendor') ?
                                <TabPane tab="Compfie Vendor" key="3" >
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content  userprivclass1'></div>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className="ant-table-thead table-head-stick" >
                                                        <tr>

                                                            <th className={'ant-table-cell'} style={{ width: '30%' }}>Domain</th>
                                                            <th className={'ant-table-cell'} style={{ width: '30%' }}>Organization</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody className='ant-table-tbody'>
                                                        <tr>
                                                            <td>
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Domain"
                                                                    showSearch
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    mode='multiple'
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    value={unitData[parentIndexValue][childIndex].vendordomainvalue || undefined}
                                                                    onChange={(value, data) => {
                                                                        console.log(value, 'value');
                                                                        let temp = []
                                                                        for (let i in value) {
                                                                            temp.push(Number(value[i]))
                                                                        }


                                                                        let arrunit = [];
                                                                        let totalListunit = []
                                                                        let countrylisttempdataunit = [];

                                                                        for (let i in value) {
                                                                            let entityData = _.filter(lists && lists.vendor_domains_organization_list, { domain_id: Number(value[i]) })
                                                                            console.log(entityData, 'entityData9604488888888880');
                                                                            arrunit.push(entityData[0])
                                                                        }
                                                                        console.log(arrunit, 'arrunit');

                                                                        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                                                                            let entityArrayunit = []
                                                                            let tempArrayunit = []

                                                                            lists && lists.vendor_domains_organization_list && lists && lists.vendor_domains_organization_list.length > 0 && lists && lists.vendor_domains_organization_list.map((subunitlist) => {
                                                                                console.log(subunitlist, 'subunitlist');
                                                                                if (pmenu && pmenu.domain_id == subunitlist.domain_id) {
                                                                                    entityArrayunit = {
                                                                                        "domain_id": subunitlist.domain_id,

                                                                                        "domain_name": subunitlist.domain_name,

                                                                                        "domain_status": subunitlist.domain_status,

                                                                                        "industry_id": subunitlist.industry_id,

                                                                                        "industry_name": subunitlist.industry_name,

                                                                                        "industry_status": subunitlist.industry_status,

                                                                                        "legal_entity_id": subunitlist.industry_status,

                                                                                        "unit_count": subunitlist.industry_status,

                                                                                        'label': subunitlist.domain_name,
                                                                                        'values': subunitlist.industry_id
                                                                                    }
                                                                                    tempArrayunit.push(entityArrayunit)
                                                                                }
                                                                            })


                                                                            totalListunit = {
                                                                                'label': pmenu && pmenu.domain_name == null ? 'Others' : pmenu && pmenu.domain_name,
                                                                                'options': tempArrayunit
                                                                            }
                                                                            console.log(totalListunit, 'aishwarya');
                                                                            console.log(totalListunit, 'unitListData')
                                                                            countrylisttempdataunit.push(totalListunit)
                                                                        })
                                                                        unitData[parentIndexValue][childIndex].vendorOrganization = countrylisttempdataunit
                                                                        unitData[parentIndexValue][childIndex].vendorDomain = temp
                                                                        unitData[parentIndexValue][childIndex].vendordomainvalue = value
                                                                        setUnitData([...unitData])

                                                                    }}
                                                                >
                                                                    {vendorDomainData && vendorDomainData.length > 0 && vendorDomainData.map((item, i) => {
                                                                        return (
                                                                            <Option title=" " key={item.domain_id
                                                                            }>
                                                                                {item.domain_name
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select></td>
                                                            <td>
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Organization"
                                                                    showSearch
                                                                    mode='multiple'
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    value={unitData[parentIndexValue][childIndex].vendororganizationValueDisplay}
                                                                    onChange={(value, data) => {
                                                                        console.log(value, 'data96777');
                                                                        let temp = []
                                                                        for (let i in value) {
                                                                            let splidata = value[i].split('-')
                                                                            console.log(splidata, 'splidata');
                                                                            temp.push({
                                                                                "domain_id": Number(splidata && splidata.length > 0 && splidata[1]),
                                                                                "industry_id": Number(splidata && splidata.length > 0 && splidata[0]),
                                                                            })
                                                                        }
                                                                        unitData[parentIndexValue][childIndex].vendororganizationValue = temp
                                                                        unitData[parentIndexValue][childIndex].vendororganizationValueDisplay = value
                                                                        setUnitData([...unitData])
                                                                    }}
                                                                >
                                                                    {/* unitData[record.parentIndex][index].unitLocationData
                        && unitData[record.parentIndex][index].unitLocationData.length > 0 && unitData[record.parentIndex][index].unitLocationData */}
                                                                    {unitData && unitData.length > 0 && parentIndexValue !== undefined && childIndex !== undefined &&
                                                                        unitData[parentIndexValue].length > 0 && unitData[parentIndexValue][childIndex]
                                                                        && unitData[parentIndexValue][childIndex].vendorOrganization.length > 0 && unitData[parentIndexValue][childIndex].vendorOrganization.map((finalList_unitlist) => (
                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                    <Option key={`${subUnitData.industry_id}-${subUnitData.domain_id}`}>{subUnitData.industry_name}</Option>

                                                                                ))}
                                                                            </OptGroup>
                                                                        ))}
                                                                </Select></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane> : ''}
                        </Tabs>

                        <div className="popupbtncolour text-center">
                            <Button
                                onClick={() => {
                                    setDomainDetailsModal(false)
                                }}
                                type="primary"
                                shape="round"
                                className={
                                    "addbutton " + localStorage.getItem("currentTheme")
                                }
                                // icon={<EyeOutlined />}
                                style={{
                                    marginTop: "20px",
                                    // marginLeft: '44%'
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </Modal> : ''}
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getclientunitFilter,
    getClientunitList,
    getaddunitList,
    addclientUnit,
    autoGentrateUnitCode

})(TE_ClientUnit);