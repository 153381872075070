import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClientAgreementReportFilters, DomainwiseAgreementReportData, AggrementReportDisplayPopup, DomainAgreementExportToCSV } from '../../Store/Action/Reports/DomainWiseAgreementMasterReport';
import { Collapse, Select, Button, DatePicker, Card, Table, Modal, Tabs, Tooltip } from 'antd';
import { EyeOutlined, ExportOutlined, FilterOutlined, FullscreenOutlined, UpOutlined, DownloadOutlined } from '@ant-design/icons'
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { TOGGLE_COMMON_LOADER } from './../../Store/types/index'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import axios from 'axios';
import url from '../../Libs/URL';
import { decode as base64_decode } from 'base-64';
import { Toaster } from '../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";

const mapStateToProps = (state) => ({
    ClientAgreementReportFiltersData: state.DomainWiseAgreementMasterReport.ClientAgreementReportFilters,
    DomainwiseAgreementReportDataResponse: state.DomainWiseAgreementMasterReport.DomainwiseAgreementReportData,
    AggrementReportDisplayPopupData: state.DomainWiseAgreementMasterReport.AggrementReportDisplayPopup,
})

const DomainWiseAgreementMasterReport = ({
    ClientAgreementReportFiltersData,
    DomainwiseAgreementReportDataResponse,
    AggrementReportDisplayPopupData,
    ClientAgreementReportFilters,
    DomainwiseAgreementReportData,
    AggrementReportDisplayPopup
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue');
    const paramid = location.pathname;
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const { TabPane } = Tabs;
    const [filterData, setData] = useState({
        countries: ClientAgreementReportFiltersData.countries,
        domains: ClientAgreementReportFiltersData.domains,
    })
    const [mandatory, setMandatory] = useState({
        country: false,
        domain: false,
    })
    const dispatch = useDispatch();
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [tableShow, setTableShow] = useState(false);
    const [tabKey, setTabKey] = useState('1');

    const [inputValues, setInputValues] = useState({
        countryId: '',
        countryName: '',
        domainId: '',
        domainName: '',
        groupId: '',
        groupName: '',
        legalEntityId: '',
        legalEntityName: '',
        contractFrom: '',
        contractTo: '',
        BusinessGroupId: '',
        BusinessGroupName: ''
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpData, setpopUpData] = useState({
        groupName: '',
        groupAdmin: '',
        shortName: ''

    })

    // const countries = ClientAgreementReportFiltersData.countries;
    // const domains = ClientAgreementReportFiltersData.domains;
    // const groupNames = ClientAgreementReportFiltersData.client_group_master;
    // const businessGroup = ClientAgreementReportFiltersData.business_groups;
    // const legalEntity = ClientAgreementReportFiltersData.unit_legal_entity;

    const tableData = DomainwiseAgreementReportDataResponse.domainwise_agreement_list;
    const [tableDataLength,setTableDataLength] = useState(null);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);

    useEffect(() => {
        if (DomainwiseAgreementReportDataResponse.length > 0) {
            setTableDataLength(DomainwiseAgreementReportDataResponse.domainwise_agreement_list.length);            
        }
    },[DomainwiseAgreementReportDataResponse])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        validator.current.showMessages();
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientAgreementReportFilters",
                    {}
                ]
            }
            ClientAgreementReportFilters({
                payload: pay,
                paramid: paramid
            })
        }
    }, [isAuth])

    const showResult = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            setTableShow(true);
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetDomainwiseAgreementReportData",
                    {
                        "country_id": parseInt(inputValues.countryId) || null,
                        "client_id": null,
                        "business_group_id": parseInt(inputValues.BusinessGroupId) || null,
                        "legal_entity_id": parseInt(inputValues.legalEntityId) || null,
                        "domain_id": parseInt(inputValues.domainId) || null,
                        "contract_from_optional": inputValues.contractFrom || null,
                        "contract_to_optional": inputValues.contractTo || null,
                        "csv": false,
                        "from_count": 0,
                        "page_count": 25,
                        "country_name": inputValues.countryName || null,
                        "domain_name": inputValues.domainName || null
                    }
                ]
            }
            DomainwiseAgreementReportData({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const modalClose = () => {
        setIsModalOpen(false);
    }

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const columns = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            width: "30%",
            align: "center",
        },
        {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            width: "30%",
            align: "center",
        },
        {
            title: "Total Units",
            dataIndex: "domain_total_unit",
            key: "domain_total_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Used Units",
            dataIndex: "domain_used_unit",
            key: "domain_used_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Date of Agreement Inception",
            dataIndex: "activation_date",
            key: "activation_date",
            width: "20%",
            align: "center",
        }
    ]

    const columnslitigation = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            align: "center",
        },
        {
            title: "Total Units",
            dataIndex: "ltg_domain_total_unit",
            key: "ltg_domain_total_unit",
            align: "center",
        },
        {
            title: "Used Units",
            dataIndex: "ltg_domain_used_unit",
            key: "ltg_domain_used_unit",
            align: "center",
        },
    ]

    const columnsvendor = [
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            width: "30%",
            align: "center",
        },
        {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            width: "30%",
            align: "center",
        },
        {
            title: "Total Units",
            dataIndex: "vndr_domain_total_unit",
            key: "vndr_domain_total_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Used Units",
            dataIndex: "vndr_domain_used_unit",
            key: "vndr_domain_used_unit",
            align: "center",
            width: "10%"
        },
        {
            title: "Date of Agreement Inception",
            dataIndex: "activation_date",
            key: "activation_date",
            width: "20%",
            align: "center",
        }
    ]

    const exportData = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            const passpay = {
                "session_token": authtoken,
                "request": [
                    "GetDomainwiseAgreementReportData",
                    {
                        "country_id": parseInt(inputValues.countryId),
                        "client_id": null,
                        "business_group_id": parseInt(inputValues.groupId) || null,
                        "legal_entity_id": parseInt(inputValues.legalEntityId) || null,
                        "domain_id": parseInt(inputValues.domainId) || null,
                        "contract_from_optional": inputValues.contractFrom || null,
                        "contract_to_optional": inputValues.contractTo || null,
                        "csv": true,
                        "from_count": 0,
                        "page_count": 25,
                        "country_name": inputValues.countryName,
                        "domain_name": inputValues.domainName
                    }
                ]
            }

            const encryptkey = localStorage.getItem('formkey')
            let encoded = base64_decode(encryptkey);
            const data1 = EncryptDecrypt.encryptdata(passpay, encoded);
            const head = {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            }
            axios.post(`${url.CommonURL}/api/techno_report`, data1, {
                headers: head
            }).then((response) => {
                const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response.data, encoded))
                if (decryptData[0] == 'ExportToCSVSuccess') {
                    window.open(process.env.REACT_APP_API_DOWN_URL + decryptData[1].link);
                } else {
                    Toaster.error(decryptData[0])
                }
            }).catch((error) => {
                Toaster.error('Unable to Perform. Kindly Try again Later')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                })
            })
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    let uniqueGroup = '';

    return (
        <>
            <div id="page-wrapper" className="page-wrapper" >
            <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer'
                                            onClick={homescreen}
                                        >
                                            <i className="ri-home-3-line fs-5"></i>
                                        </span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span>Domain wise Agreement Master Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 text-end">
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "15px",
                                    display: filterTaskAcc ? "none" : "inline",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button
                                onClick={(e) => {
                                    fullscreenMode();
                                }}
                                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                            >
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title="Full Screen Mode"
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                                <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                    <Panel header="Domain wise Agreement Master Report" key={1}>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label><b>Country :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            autoFocus={true}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.countryId || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        countryId: data,
                                                                        countryName: value.children,
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        countryId: '',
                                                                        countryName: '',
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.countries && ClientAgreementReportFiltersData.countries.length > 0 && ClientAgreementReportFiltersData.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            inputValues.countryId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Domain :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain Name"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.domainId || undefined}
                                                            disabled={inputValues.countryId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        domainId: data,
                                                                        domainName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.domains && ClientAgreementReportFiltersData.domains.length > 0 && ClientAgreementReportFiltersData.domains.map((item, i) => {
                                                                return (
                                                                    <Option key={item.domain_id}>
                                                                        {item.domain_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            inputValues.domainId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Compfie Domain Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Group Name :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Group"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.groupId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        groupId: data,
                                                                        groupName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        groupId: '',
                                                                        groupName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.client_group_master && ClientAgreementReportFiltersData.client_group_master.length > 0 && ClientAgreementReportFiltersData.client_group_master.map((item, i) => {
                                                                return (
                                                                    <Option key={item.group_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Business Group :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Business Group"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.BusinessGroupId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        BusinessGroupId: data,
                                                                        BusinessGroupName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        BusinessGroupId: '',
                                                                        BusinessGroupName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.business_groups && ClientAgreementReportFiltersData.business_groups.length > 0 && ClientAgreementReportFiltersData.business_groups.map((item, i) => {
                                                                return (
                                                                    <Option key={item.business_group_id}>
                                                                        {item.business_group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Legal Entity :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.legalEntityId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        legalEntityId: data,
                                                                        legalEntityName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        legalEntityId: '',
                                                                        legalEntityName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.unit_legal_entity && ClientAgreementReportFiltersData.unit_legal_entity.length > 0 && ClientAgreementReportFiltersData.unit_legal_entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.legal_entity_id}>
                                                                        {item.legal_entity_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Contract From :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker style={{ width: '100%' }} allowClear={true} format={'DD-MMM-YYYY'} value={inputValues.contractFrom ? moment(inputValues.contractFrom) : ''} disabled={inputValues.domainId == ''} placeholder='DD-MM-YYYY'
                                                            onChange={(date, dateString) => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    contractFrom: dateString,
                                                                    contractTo: ''
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Contract To :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker style={{ width: '100%' }} format={'DD-MMM-YYYY'} value={inputValues.contractTo ? moment(inputValues.contractTo) : ''} disabled={inputValues.domainId == ''} placeholder='DD-MM-YYYY'
                                                            disabledDate={(current) => {
                                                                return moment(inputValues.contractFrom).add(0, 'days') >= current
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    contractTo: dateString,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button type="primary" shape="round" className='addbutton'
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<EyeOutlined />}
                                                onClick={showResult}
                                            >
                                                Show
                                            </Button>&nbsp;
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<ExportOutlined />}
                                                onClick={exportData}
                                            >
                                                Export
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    title="Domain wise Agreement Master Report"
                                    className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}                                    
                                    footer={false}
                                    visible={filterModalVisible}
                                    onCancel={setcancelFilter}
                                >
                                    <div className='row'>
                                            <div className='col-4'>
                                                <label><b>Country :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            size="default"
                                                            placeholder="Enter Country"

                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.countryId || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        countryId: data,
                                                                        countryName: value.children,
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        countryId: '',
                                                                        countryName: '',
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.countries && ClientAgreementReportFiltersData.countries.length > 0 && ClientAgreementReportFiltersData.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            inputValues.countryId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Domain :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            size="default"
                                                            placeholder="Enter Domain Name"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.domainId || undefined}
                                                            disabled={inputValues.countryId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        domainId: data,
                                                                        domainName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        domainId: '',
                                                                        domainName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.domains && ClientAgreementReportFiltersData.domains.length > 0 && ClientAgreementReportFiltersData.domains.map((item, i) => {
                                                                return (
                                                                    <Option key={item.domain_id}>
                                                                        {item.domain_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            inputValues.domainId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Compfie Domain Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Group Name :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            size="default"
                                                            placeholder="Enter Group"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.groupId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        groupId: data,
                                                                        groupName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        groupId: '',
                                                                        groupName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.client_group_master && ClientAgreementReportFiltersData.client_group_master.length > 0 && ClientAgreementReportFiltersData.client_group_master.map((item, i) => {
                                                                return (
                                                                    <Option key={item.group_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Business Group :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            size="default"
                                                            placeholder="Enter Business Group"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.BusinessGroupId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        BusinessGroupId: data,
                                                                        BusinessGroupName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        BusinessGroupId: '',
                                                                        BusinessGroupName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.business_groups && ClientAgreementReportFiltersData.business_groups.length > 0 && ClientAgreementReportFiltersData.business_groups.map((item, i) => {
                                                                return (
                                                                    <Option key={item.business_group_id}>
                                                                        {item.business_group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Legal Entity :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.legalEntityId || undefined}
                                                            disabled={inputValues.domainId == ''}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue')
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        legalEntityId: data,
                                                                        legalEntityName: value.children
                                                                    })
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        legalEntityId: '',
                                                                        legalEntityName: ''
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {ClientAgreementReportFiltersData && ClientAgreementReportFiltersData.unit_legal_entity && ClientAgreementReportFiltersData.unit_legal_entity.length > 0 && ClientAgreementReportFiltersData.unit_legal_entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.legal_entity_id}>
                                                                        {item.legal_entity_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Contract From :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker style={{ width: '100%' }} allowClear={true} getPopupContainer={trigger => trigger.parentNode} format={'DD-MMM-YYYY'} value={inputValues.contractFrom ? moment(inputValues.contractFrom) : ''} disabled={inputValues.domainId == ''}
                                                        placeholder='DD-MM-YYYY'
                                                            onChange={(date, dateString) => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    contractFrom: dateString,
                                                                    contractTo: ''
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <label><b>Contract To :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker style={{ width: '100%' }} format={'DD-MMM-YYYY'} getPopupContainer={trigger => trigger.parentNode} value={inputValues.contractTo ? moment(inputValues.contractTo) : ''} disabled={inputValues.domainId == ''}
                                                        placeholder='DD-MM-YYYY'
                                                            disabledDate={(current) => {
                                                                return moment(inputValues.contractFrom).add(0, 'days') >= current
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    contractTo: dateString,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<EyeOutlined />}
                                                onClick={showResult}
                                            >
                                                Show
                                            </Button>&nbsp;
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<ExportOutlined />}
                                                onClick={exportData}
                                            >
                                                Export
                                            </Button>
                                        </div>

                                </Modal>
                                {tableShow ? 
                                <Card>
                                    <div className='row p-2 justify-content-md-center'>
                                        <div className='col-lg-4'><b>Country</b> : {inputValues.countryName}</div>
                                        <div className='col-lg-4'><b>Domain</b> : {inputValues.domainName}</div>
                                    </div>
                                    <div className='ant-table-wrapper'>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content'>
                                                    <table style={{ tableLayout: 'auto' }} className={"table-fixed"}>
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: "10px" }}>#</th>
                                                                <th className='ant-table-cell' style={{ width: "180px" }}>Legal Entity</th>
                                                                <th className='ant-table-cell' style={{ width: "90px" }}>Business Group</th>
                                                                <th className='ant-table-cell' style={{ width: "50px" }}>Group Admin / LE Details</th>
                                                                <th className='ant-table-cell' style={{ width: "200px" }}>Product</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ant-table-tbody'>
                                                            {
                                                                tableData && tableData.length > 0 ? tableData.map((tableList, li) => {
                                                                    if (uniqueGroup !== tableList.group_name) {
                                                                    uniqueGroup = uniqueGroup !== tableList.group_name ? tableList.group_name : uniqueGroup;
                                                                    console.log('tabledata', tableData);
                                                                    return <Fragment key={li}>
                                                                        <tr>
                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'><b>{tableList.group_name}</b></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'><b>{tableList.business_group_name}</b></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'><b>{tableList.group_admin_email}</b></td>
                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                        </tr>
                                                                        {
                                                                            tableData && tableData.length > 0 && tableData.map((finallist, keyl) => {
                                                                                console.log(finallist, 'finallist');
                                                                                if (finallist.group_name == tableList.group_name) {
                                                                                    return <Fragment key={keyl}>
                                                                                        <tr>
                                                                                            <td className='ant-table-row ant-table-row-level-0'><center>{keyl + 1}</center></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'>
                                                                                                <a href="javascript:;"
                                                                                                    onClick={(e) => {
                                                                                                        const pay = {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "GetAggrementReportDisplayPopup",
                                                                                                                {
                                                                                                                    "le_id": parseInt(finallist.legal_entity_id),
                                                                                                                    "domain_id_optional": parseInt(inputValues.domainId) || null
                                                                                                                }
                                                                                                            ]
                                                                                                        }
                                                                                                        AggrementReportDisplayPopup({
                                                                                                            payload: pay,
                                                                                                            paramid: paramid
                                                                                                        })
                                                                                                        setpopUpData({
                                                                                                            ...popUpData,
                                                                                                            groupName: finallist.group_name,
                                                                                                            groupAdmin: finallist.group_admin_email,
                                                                                                            shortName: finallist.short_name,
                                                                                                            legalEntityName: finallist.legal_entity_name
                                                                                                        })
                                                                                                        setIsModalOpen(true);
                                                                                                        setTabKey('1');
                                                                                                    }}>{finallist.legal_entity_name}
                                                                                                </a>
                                                                                            </td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'></td>
                                                                                            <td className='ant-table-row ant-table-row-level-0'>{finallist.product_name}</td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                }
                                                                            })
                                                                        }
                                                                    </Fragment>}
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                    {tableData && tableData.length > 0 ? 
                                                    <div className='mt-4 px-2'>
                                                    <span>Showing {1} to {tableData.length} of {tableData.length} entries</span>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <Modal title={`Client Groups : ${popUpData.groupName}`} className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}
                                        open={isModalOpen} onOk={modalClose} onCancel={modalClose} footer={null}
                                    >
                                        <div className='card card-body rounded-3' style={{padding: '13px', marginBottom: '15px'}}>
                                            <div className='row py-1'>
                                                <div className='col-4'>
                                                    <label htmlFor=""><b>Short Name :</b><span style={{ marginLeft: '5px' }}>{popUpData.shortName}</span></label>
                                                </div>
                                                <div className='col-6'>
                                                    <label htmlFor=""><b>Group Admin :</b><span style={{ marginLeft: '5px' }}>{popUpData.groupAdmin}</span></label>
                                                </div>
                                                <div className='row py-1'>
                                                    <div className="col-12">
                                                        <label htmlFor=""><b>View Only Licence(s) :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.no_of_view_licence}</span></label>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-4">
                                                        <label htmlFor=""><b>IP Restriction :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.is_apply_ip_settings === 0 ? 'Disabled' : 'Enabled'}</span></label>
                                                    </div>
                                                    <div className="col-4" style={{paddingLeft: '25px'}}>
                                                        <label htmlFor=""><b>Remarks :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.remarks ? AggrementReportDisplayPopupData.remarks : 'NA'}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card card-body rounded-3' style={{padding: '13px', marginBottom: '15px'}}>
                                            <div className='row'>
                                                <div className="col-3">
                                                    <label htmlFor=""><b>Country :</b><span style={{ marginLeft: '5px' }}>{inputValues.countryName}</span></label>
                                                </div>
                                                {AggrementReportDisplayPopupData.bg_name ? 
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Business Group :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.bg_name}</span></label>
                                                </div> : null}
                                                <div className="col">
                                                    <label htmlFor=""><b>Legal Entity :</b><span style={{ marginLeft: '5px' }}>{popUpData.legalEntityName}</span></label>
                                                </div>
                                            </div>
                                            <div className='row my-1'>
                                                <div className="col-3 ">
                                                    <label htmlFor=""><b>File Space :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.file_space_report} GB</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Used File Space :</b><span style={{ marginLeft: '5px' }}>{AggrementReportDisplayPopupData.used_file_space_report} GB</span></label>
                                                </div>
                                            </div>
                                            <hr className='my-1'/>
                                        <Tabs defaultActiveKey={'1'} activeKey={tabKey} onChange={(key) => setTabKey(key)}>
                                            {AggrementReportDisplayPopupData.org_list_report && AggrementReportDisplayPopupData.org_list_report.length > 0 ?
                                                <TabPane tab="Compfie" key="1" >
                                                    <h6>Compfie- Contract Details</h6>
                                                    <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.used_licence}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                    <br />
                                                    {AggrementReportDisplayPopupData.org_list_report.length > 0 ?
                                                    <>
                                                    <h6>Compfie- Domain &Organization Details</h6>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={AggrementReportDisplayPopupData.org_list_report}
                                                        bordered
                                                        pagination={false}
                                                    // scroll={{y: "100"}}
                                                    >
                                                    </Table>
                                                    </> : null}
                                                </TabPane>

                                                : false}
                                            {AggrementReportDisplayPopupData.ltgs_info_report && AggrementReportDisplayPopupData.ltgs_info_report.length > 0 ?
                                                <TabPane tab="Compfie Litigation" key="2"> 
                                                    <h6>Compfie Litigation- Contract Details</h6>
                                                    <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Approval Status</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.ltgs_info_report[0].contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.ltgs_info_report[0].contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.ltgs_info_report[0].no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.ltgs_info_report[0].used_licn}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.ltgs_info_report[0].ltgs_apprvl == 1 ? 'Yes' : 'No'}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                    <br />
                                                    {AggrementReportDisplayPopupData.ltgs_info_report[0].ltgs_domain_info_report.length > 0 ? 
                                                    <Table
                                                        columns={columnslitigation}
                                                        dataSource={AggrementReportDisplayPopupData.ltgs_info_report[0].ltgs_domain_info_report}
                                                        bordered
                                                        pagination={false}
                                                    // scroll={{y: "100"}}
                                                    >
                                                    </Table> : null}
                                                </TabPane>
                                                : false}
                                            {AggrementReportDisplayPopupData.vndr_info_report && AggrementReportDisplayPopupData.vndr_info_report.length > 0 ?
                                                <TabPane tab="Compfie Vendor" key="3">
                                                    <h6>Compfie Vendor- Contract Details</h6>
                                                    <div class="ant-table ant-table-bordered">
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content">
                                                                    <table style={{ tableLayout: 'auto' }}>
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell"><b>Contract From</b></th>
                                                                                <th className="ant-table-cell"><b>Contract To</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Total License(s)</b></th>
                                                                                <th className="ant-table-cell" style={{ textAlign: 'center' }}><b>Used License(s)</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">
                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.vndr_info_report[0].contract_from}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.vndr_info_report[0].contract_to}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.vndr_info_report[0].no_of_licence}</td>
                                                                                <td className="ant-table-cell" style={{ textAlign: 'center' }}>{AggrementReportDisplayPopupData.vndr_info_report[0].used_licn}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></div>
                                                    <br />
                                                    {AggrementReportDisplayPopupData.vndr_info_report[0].vndr_domain_info_report.length > 0 ?
                                                    <Table
                                                        columns={columnsvendor}
                                                        dataSource={AggrementReportDisplayPopupData.vndr_info_report[0].vndr_domain_info_report}
                                                        bordered
                                                        pagination={false}
                                                    // scroll={{y: "100"}}
                                                    >
                                                    </Table> : null}

                                                </TabPane>
                                                : false}
                                        </Tabs></div>
                                    </Modal>
                                </Card>
                                : false }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}


export default connect(mapStateToProps, {
    ClientAgreementReportFilters,
    DomainwiseAgreementReportData,
    AggrementReportDisplayPopup,
})(DomainWiseAgreementMasterReport);