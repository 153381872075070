import { CLIENT_UNIT_FILTER, LEGALENTITYDATA, CLIENTUNITDETAILSDATA } from './../../types/index'
const initialState = {
    clientunitdetails: {
        filter: [],
        legelentitydata: [],
        unitdata: []
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIENT_UNIT_FILTER:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    filter: payload,
                },
            };
        case LEGALENTITYDATA:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    legelentitydata: payload,
                },
            };
        case CLIENTUNITDETAILSDATA:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    unitdata: payload,
                },
            };
        default:
            return state;
    }
}