import HTTP from '../../../Libs/http';
import { ASSIGN_STATU_UPLOAD_LIST, ASSIGN_PENDING_LIST, ASSIGN_DOMAIN_LIST,BULK_ASSIGN_UPLOAD_VIEW,BULK_ASSIGN_UPLOAD_FILTER } from '../../types/index'

const initialState = {
    assignstatutorybulkupload: {
        list: [],
        pendinglist: [],
        domainlist: [],
        viewdata:[],
        filterdata:[],
        approve:[],
        reject:[]
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGN_STATU_UPLOAD_LIST:
            {
                return {
                    ...state,
                    assignstatutorybulkupload: {
                        ...state.assignstatutorybulkupload,
                        list: payload,
                    },
                };
            }
        case ASSIGN_PENDING_LIST:
            {
                return {
                    ...state,
                    assignstatutorybulkupload: {
                        ...state.assignstatutorybulkupload,
                        pendinglist: payload,
                    },
                };
            }
        case ASSIGN_DOMAIN_LIST:
            {
                return {
                    ...state,
                    assignstatutorybulkupload: {
                        ...state.assignstatutorybulkupload,
                        domainlist: payload,
                    },
                };
            }
            case BULK_ASSIGN_UPLOAD_VIEW:
                {
                    return {
                        ...state,
                        assignstatutorybulkupload: {
                            ...state.assignstatutorybulkupload,
                            viewdata: payload,
                        },
                    };
                }
                case BULK_ASSIGN_UPLOAD_FILTER:
                {
                    return {
                        ...state,
                        assignstatutorybulkupload: {
                            ...state.assignstatutorybulkupload,
                            filterdata: payload,
                        },
                    };
                   
                }
        default:
            return state;
    }
}