import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { GETTASKMAPPING, GETVIEWTASKMAPPING, GETEDITTASKMAPPING, GETTASKCATEGORYMAPPINGFILTER, GETTASKCATEGORYMAPPING, GETTASKCATEGORYMAPPINGSHOWMORE,GETMAPPINGREJECTEDLIST } from '../../types/index'

const initialState = {
    gettaskcategorymapping: '',
    getviewtaskcategorymapping: '',
    getedittaskcategorymapping: '',
    others: {
        country: '',
        taskcategory: '',
        tasksubcategory: ''
    },
    gettaskcategorymappingfilter: '',
    gettaskcategorymappingnew: '',
    gettaskcategorymappinglist: '',
    getrejectedlist:''
}
export default function (state = initialState, action) {
    const { type, payload, country, taskcategory, tasksubcategory, countryId, taskcategoryId, tasksubcategoryId } = action;
    switch (type) {
        case GETTASKMAPPING: {
            return {
                ...state,
                gettaskcategorymapping: payload
            };
        }

        case GETVIEWTASKMAPPING: {
            return {
                ...state,
                getviewtaskcategorymapping: payload
            }
        }
        case GETMAPPINGREJECTEDLIST: {
            return {
                ...state,
                getrejectedlist: payload
            }
        }


        case GETEDITTASKMAPPING: {
            return {
                ...state,
                getedittaskcategorymapping: payload,
                others: {
                    country: country,
                    taskcategory: taskcategory,
                    tasksubcategory: tasksubcategory,
                    countryId: countryId,
                    taskcategoryId: taskcategoryId,
                    tasksubcategoryId: tasksubcategoryId
                }
            }
        }

        case GETTASKCATEGORYMAPPINGFILTER: {
            return {
                ...state,
                gettaskcategorymappingfilter: payload
            }
        }

        case GETTASKCATEGORYMAPPING: {
            // return {
            //     ...state,
            //     gettaskcategorymappingnew: [...state.gettaskcategorymappingnew, payload]
            // }
            return {
                ...state,
                gettaskcategorymappinglist: payload,
                gettaskcategorymappingnew: ''
            }
        }
        
        case GETTASKCATEGORYMAPPINGSHOWMORE: {
            return {
                ...state,
                gettaskcategorymappingnew: [...state.gettaskcategorymappingnew, payload]
            }
        }

        default:
            return state;
    }
}