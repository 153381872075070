import { Fragment, useState, useEffect, useRef, InfoCircleOutlineduseMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Button, Collapse, Steps, Tooltip, Segmented, Space, Avatar, Select, Popover, Card, Progress, Tag, Statistic, Row, Col, List } from 'antd';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { getLegalEntityClosureReportData, saveLegalEntityClosureData } from '../../Store/Action/Transactions/LegalEntityClosure'
import { InfoCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, ClockCircleOutlined, PlusOutlined, SearchOutlined, ArrowUpOutlined, CloseOutlined, ArrowDownOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LikeOutlined, DislikeOutlined, StopOutlined, PlusCircleOutlined, FullscreenOutlined, InfoCircleTwoTone } from '@ant-design/icons';
// import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST, RESET_ELSE } from '../../Store/types';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { Toaster } from '../../Libs/Toaster';
import { HomeRedirect } from './../../Libs/country'

const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    closureList: state.LegalEntityClosure.leclosure,
    // filterrecall: state.recallfilters.recallfilters

});
const LegalEntityClosure = ({
    saveUnitClosureData,
    getUnitClosureList,
    closureList: { list, elsevalue },
    getLegalEntityClosureReportData,
    saveLegalEntityClosureData,
    GetRemarksData

}) => {
    console.log(list, elsevalue, 'closureList');
    const historynew = useHistory();
    // const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    // console.log(unitClosureList.UnitClosureList, 'dkjhfdkjsfhdjf');
    const { Option } = Select;
    const dispatch = useDispatch()
    const { Panel } = Collapse;
    const _ = require("lodash");
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    console.log(remarks, 'remarksremarks');
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [show, setShow] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [statkey, setStatKey] = useState("0")
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        // unit_id: '',
        legal_entity_id: '',
        grp_mode: ''

    })
    console.log(modalData, 'modalData');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [activedata, setActivedata] = useState('0')
    const [inactivedata, setInactivedata] = useState('0')
    const [closeddata, setCloseddata] = useState('0')
    const [type, setType] = useState('active')
    const [entityid, setCurrentEntity] = useState("");
    const [reactivatedArray, setReActivatedArray] = useState([])
    const [activeArray, setActiveArray] = useState([])
    const [inActiveArray, setInActiveArray] = useState([])
    const [blockedArray, setBlockedArray] = useState([])
    const [unBlockedArray, setUnBlockedArray] = useState([])
    const [reactiveArray, setReactiveArray] = useState([])
    const [fullscreen, setfullscreen] = useState(false);
    const serviceProviderRef1 = useRef(null);
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen)

    useEffect(() => {
        if (elsevalue[0] == 'InvalidPassword') {
            Toaster.error('Invalid Password')
            dispatch({
                type: RESET_ELSE
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit(false)
            // setModaVisible(false)
        } else if (elsevalue[0] == "SaveLegalEntityClosureSuccess") {
            if (modalData.grp_mode == 'reactive')
                Toaster.success('Legal Entity Activated Successfullly')
            else if (modalData.grp_mode == 'close')
                Toaster.success('Legal Entity temporarily closed and can be activated within 90 days')
            dispatch({
                type: RESET_ELSE
            })
            setAddFormSubmit(false)
            setModaVisible(false)
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
        }
    }, [elsevalue])
    const { Search } = Input;

    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const [filteredArray, setFilteredArray] = useState([])

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    // Auto Focus 

    const setFocus = (ref) => {
        console.log(ref, 'currentref')
        setTimeout(() => {
            ref.current.focus();
        });
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onInputChange = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setRemarks('')
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~\^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setRemarks(e.target.value)
                }
            }
        }
    }

    /* Action Needed */
    const [filteredInfo1, setFilteredInfo1] = useState({});
    const [sortedInfo1, setSortedInfo1] = useState({});
    const tablehandleChange1 = (pagination, filters, sorter) => {
        setFilteredInfo1(filters);
        setSortedInfo1(sorter);
    };
    /* Action Needed */

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '40px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Country</span>,
            dataIndex: 'country_name',
            key: 'country_name',
            width: '130px',
            // ellipsis: true,
            ...getColumnSearchProps('country_name', 'Country'),
            filteredValue: filteredInfo.country_name || null,
            sorter: (a, b) => {
                if (a && a.country_name && a.country_name.length && b && b.country_name && b.country_name.length) {
                    return a.country_name.localeCompare(b.country_name);
                } else if (a && a.country_name && a.country_name.length) {
                    // That means be has null country_name, so a will come first.
                    return -1;
                } else if (b && b.country_name && b.country_name.length) {
                    // That means a has null country_name so b will come first.
                    return 1;
                }

                // Both country_name has null value so there will be no order change.
                return 0;
            },//a.country_name && b.country_name != null ? a.country_name.localeCompare(b.country_name) : "z",
            sortOrder: sortedInfo.columnKey === 'country_name' ? sortedInfo.order : null,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <span title={text}>{text}</span> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Group</span>,
            dataIndex: 'group_name',
            key: 'group_name',
            width: '130px',
            // ellipsis: true,
            ...getColumnSearchProps('group_name', 'Group'),
            filteredValue: filteredInfo.group_name || null,
            sorter: (a, b) => {
                if (a && a.group_name && a.group_name.length && b && b.group_name && b.group_name.length) {
                    return a.group_name.localeCompare(b.group_name);
                } else if (a && a.group_name && a.group_name.length) {
                    // That means be has null group_name, so a will come first.
                    return -1;
                } else if (b && b.group_name && b.group_name.length) {
                    // That means a has null group_name so b will come first.
                    return 1;
                }

                // Both group_name has null value so there will be no order change.
                return 0;
            },//a.group_name && b.group_name != null ? a.group_name.localeCompare(b.group_name) : "z",
            // sorter: (a, b) => a.category_name && b.category_name != null ? a.category_name.localeCompare(b.category_name) : "",
            sortOrder: sortedInfo.columnKey === 'group_name' ? sortedInfo.order : null,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <span title={text}>{text}</span> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Business Group</span>,
            dataIndex: 'business_group_name',
            key: 'business_group_name',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('business_group_name', 'Business'),
            filteredValue: filteredInfo.business_group_name || null,
            sorter: (a, b) => a.business_group_name.localeCompare(b.business_group_name),
            sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
            width: '200px',
            render: (text, record) => {
                console.log(text, record, "ggggggggggg");
                return (
                    <Fragment>
                        <span title={record.business_group_name}>{record.business_group_name}</span>
                    </Fragment>
                )
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Legal Entity</span>,
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('legal_entity_name', 'legal_entity_name'),
            filteredValue: filteredInfo.legal_entity_name || null,
            sorter: (a, b) => a.legal_entity_name.localeCompare(b.legal_entity_name),
            sortOrder: sortedInfo.columnKey === 'legal_entity_name' ? sortedInfo.order : null,
            width: '200px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <span title={record.legal_entity_name}>{record.legal_entity_name}</span>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            width: '90px',
            align: 'center',
            ...getColumnSearchProps('is_active', 'Status'),
            filteredValue: filteredInfo.is_active || null,
            filters: [
                {
                    text: 'Active',
                    value: false,
                },
                {
                    text: 'Inactive',
                    value: true,
                },
            ],
            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            record.is_active ? "In-Active" : "Active"
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Workflow Status',
            dataIndex: 'is_admin_approved',
            key: 'is_admin_approved',
            // ellipsis: true,
            width: '115px',
            align: 'center',
            ...getColumnSearchProps('is_admin_approved', 'WorkflowStatus'),
            filteredValue: filteredInfo.is_admin_approved || null,
            filters: [
                {
                    text: 'Active',
                    value: false,
                },
                {
                    text: 'Inactive',
                    value: true,
                },
            ],
            filteredValue: filteredInfo.is_admin_approved,
            onFilter: (value, record) => record.is_admin_approved === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            record.is_active == false && record.is_admin_approved == 1 && record.validity_days < 90 ? "Pending" : record.is_active == false && record.is_admin_approved == 2 ? "Approved" : record.is_active == false && record.is_admin_approved == 3 ? "Rejected" : record.is_active == false && record.is_admin_approved == 1 && record.validity_days > 90 ? "Auto Approved" :
                                record.is_active == true && record.is_admin_approved == 4 && record.validity_days < 90 ? "Pending" : record.is_active == true && record.is_admin_approved == 5 ? "Approved" : record.is_active == true && record.is_admin_approved == 6 ? "Rejected" : record.is_active == true && record.is_admin_approved == 4 && record.validity_days > 90 ? "Auto Approved" : ""
                        }
                    </Fragment>
                )
            }
        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"Actions for Close/Closed/Reactive"}><InfoCircleOutlined /></Tooltip></>,
            title: 'Closure',
            dataIndex: 'currentStatus',
            key: 'currentStatus',
            width: '130px',
            align: 'center',
            // filters: [
            //     {
            //         text: 'Close',
            //         value: 0,
            //     },
            //     {
            //         text: 'Closed',
            //         value: 1,
            //     },
            //     {
            //         text: 'Reactivate',
            //         value: 2,
            //     },
            // ],
            // filteredValue: filteredInfo.currentStatus,
            onFilter: (value, record) => record.currentStatus == value,//filterfunction(value, record),
            filterSearch: false,
            // filteredValue: filteredInfo.currentStatus,
            // onFilter: (value, record) => record.currentStatus == value,
            // filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_active != true ?
                            <Button
                                type="primary"
                                style={{ width: "80px", textAlign: "center", borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.legal_entity_id, "close");
                                }}
                                title=""
                                disabled={(record.is_active == false && record.is_admin_approved == 1 && record.validity_days < 90) || (record.is_active == false && record.is_admin_approved == 3)}
                            >Close
                            </Button> : record.is_active && record.validity_days > 90 ?
                                <label style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}>Closed</label>
                                :
                                <span title={(90 - record.validity_days) + '-' + 'Days Left'}>
                                    <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                        onClick={() => {
                                            setModalData({
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.legal_entity_id, "reactive");
                                        }}
                                        title=""
                                        disabled={record.is_active == true && record.is_admin_approved == 2 || record.is_active == true && record.is_admin_approved == 0 || record.is_active == true && record.is_admin_approved == 3 ? false : true}
                                    >Reactivate
                                    </Button>
                                </span>

                        }
                    </Fragment>
                )
            }
        }
    ];


    // const handlePasting = (e, regex) => {
    //     var regex = regex;
    //     var key = e.clipboardData.getData('text')
    //     if (!regex.test(key)) {
    //         e.preventDefault();
    //         Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
    //         return false;
    //     }
    // }

    // const columns1 = [
    //     {
    //         title: '#',
    //         dataIndex: false,
    //         align: 'center',
    //         width: '50px',
    //         render: (text, record, index) => {
    //             return (page - 1) * 10 + index + 1
    //         }
    //     },
    //     {
    //         title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Unit</span>,
    //         dataIndex: 'unit',
    //         key: 'unit',
    //         ...getColumnSearchProps('unit', 'Unit'),
    //         filteredValue: filteredInfo1.unit || null,
    //         showSorterTooltip: true,
    //         sorter: (a, b) => a.unit.localeCompare(b.unit),
    //         sortOrder: sortedInfo1.columnKey === 'unit' ? sortedInfo1.order : null,
    //         render: (text, record) => {
    //             return (
    //                 <Fragment>
    //                     <p className='mb-0' style={{ whiteSpace: 'unset' }}><span><span title={` ${record.address} , ${record.postal_code} `}> <InfoCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /> </span>&nbsp;</span>
    //                         <span title={record.unit}>{record.unit}</span></p>
    //                 </Fragment>
    //             )
    //         }
    //     },
    //     {
    //         title: 'TimeLine',
    //         key: 'status',
    //         align: "center",
    //         width: "150px",
    //         dataIndex: 'status',
    //         render: (text, record, index) => {
    //             if ((30 - record.validity_days) >= 20) {
    //                 return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //             else if ((30 - record.validity_days) >= 6) {
    //                 return <p className='mb-0 text-primary'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //             else if ((30 - record.validity_days) <= 5) {
    //                 return <p className='mb-0 text-danger'><ClockCircleOutlined className='I' id='I' /> &nbsp; {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //         },
    //     },
    //     {
    //         title: 'Closure',
    //         dataIndex: 'currentStatus',
    //         width: '120px',
    //         align: 'center',


    //         render: (text, record) => {
    //             return (
    //                 <Fragment>
    //                     {record.is_active == 0 ?
    //                         <Button
    //                             type="primary"
    //                             style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
    //                             onClick={() => {
    //                                 setShow(true)
    //                                 closeById(record.unit_id, "closed");
    //                             }}
    //                             title=""
    //                         ><Avatar
    //                                 style={{
    //                                     backgroundColor: '#F32013',
    //                                 }}
    //                                 icon={<i className="fas fa-ban"></i>}
    //                             />
    //                         </Button> : record.is_active == 1 && record.validity_days > 30 ?
    //                             <span>Closed</span>
    //                             :
    //                             <span title={(30 - record.validity_days) + '-' + 'Days Left'}>
    //                                 <div className="avtr">
    //                                     <Avatar
    //                                         className='pointer'
    //                                         style={{
    //                                             backgroundColor: '#F32013',
    //                                             fontSize: '13px',
    //                                             width: '28px',
    //                                             height: '29px'
    //                                         }}
    //                                         icon={<i className="fas fa-sync"></i>}
    //                                         onClick={() => {
    //                                             setModalData({
    //                                                 password: '',
    //                                                 reason: ''
    //                                             })
    //                                             setShow(true)
    //                                             closeById(record.unit_id, "reactive");
    //                                         }}
    //                                     />
    //                                 </div>
    //                             </span>

    //                     }
    //                 </Fragment>
    //             )
    //         }
    //     }
    // ];


    // const content = (
    //     <div>
    //         <Row gutter={16}>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="Active"
    //                         value={activedata}
    //                         precision={2}
    //                         valueStyle={{ color: '#3f8600' }}
    //                         prefix={<ArrowUpOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="In-Active"
    //                         value={inactivedata}
    //                         precision={2}
    //                         valueStyle={{ color: '#cf1322' }}
    //                         prefix={<ArrowDownOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="Closed"
    //                         value={closeddata}
    //                         precision={2}
    //                         valueStyle={{ color: '#eed202' }}
    //                         prefix={<CloseOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //         </Row>
    //     </div>
    // );

    // const RemarksColumn = [
    //     {
    //         title: 'S.No',
    //         dataIndex: false,
    //         width: '30px',
    //         align: 'center',
    //         render: (text, record, index) => {
    //             return (page - 1) * 10 + index + 1
    //         }
    //     },
    //     {
    //         title: 'Description',
    //         dataIndex: 'r_description',
    //         key: 'r_description',
    //         width: '200px',
    //         ellipsis: true,
    //         render: (text, record) => <a onClick={() => {
    //             setRemarks(text)
    //             setRemarksModal(false)
    //             dispatch({
    //                 type: REMARKS_MODAL_RESET,
    //                 payload: false
    //             })
    //         }}>{record.r_description}</a>,
    //     },
    // ]



    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            // fetch pay
            const payloadInitial = {
                "session_token": authtoken,
                "request": [
                    "GetLegalEntityClosureReportData",
                    {}
                ]
            }
            //fetchpay
            saveLegalEntityClosureData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveLegalEntityClosureData",
                        {
                            "password": modalData.password,
                            "closed_remarks": remarks,
                            "legal_entity_id": Number(modalData.legal_entity_id),
                            "grp_mode": modalData.grp_mode,
                            // "legal_entity_id": entityid
                        }
                    ]
                },
                caller: paramid,
                payloadInitial: payloadInitial,
            })
            // setModalData({
            //     ...modalData,
            //     password: ""
            // })
            // setRemarks("")
            // setAddFormSubmit(false)
            // setModaVisible(false)
        }
    }

    // useEffect(() => {
    //     if (recallremarks && is_modal_true) {
    //         let temp = [];
    //         for (let i = 0; i < recallremarks.length; i++) {
    //             if (recallremarks[i].r_description != '') {
    //                 temp.push(recallremarks[i])
    //             }
    //             Setremarksvalue(temp)
    //         }
    //         if (temp.length == 0) {
    //             Toaster.error('No Remarks Available')
    //             setModaVisible(false)
    //             setModalData({
    //                 ...modalData,
    //                 password: ""
    //             })
    //             setRemarks("")
    //             setAddFormSubmit(false)
    //             dispatch({
    //                 type: REMARKS_MODAL_RESET,
    //                 payload: false
    //             })
    //         } else {
    //             setRemarksModal(true);
    //         }
    //     }

    // }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    const passwordcancel = () => {
        setModaVisible(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    // const handleOk = () => {
    //     // setIsModalVisible(false);
    //     setRemarksModal(false)
    // };

    // const handleCancel = () => {
    //     // setIsModalVisible(false);
    //     setRemarksModal(false)
    //     dispatch({
    //         type: REMARKS_MODAL_RESET,
    //         payload: false
    //     })
    // };

    // const showRemarksModal = () => {
    //     GetRemarksData({
    //         payload:
    //             [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request": [
    //                         "GetRemarksData",
    //                         {}
    //                     ]
    //                 }
    //             ],
    //         paramid: paramid
    //     })
    //     // setRemarksModal(true);
    //     dispatch({
    //         type: REMARKS_MODAL_RESET,
    //         payload: true
    //     })
    // };

    useEffect(() => {
        if (isAuth) {
            // if (entityid != '') {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetLegalEntityClosureReportData",
                    {}
                ]
            }

            // if (entityid != "null") {
            getLegalEntityClosureReportData({
                payload: payload,
                caller: paramid
            })
            // } else {
            //     setBaseData('')
            // }
            // }

        }
    }, [isAuth])

    const closeById = (entity_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        legal_entity_id: entity_id,
                        // reason: type,
                        grp_mode: type
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        legal_entity_id: entity_id,
                        // reason: type,
                        grp_mode: type
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            })
    };

    // useEffect(() => {
    //     if (modalData.legal_entity == '' || null || undefined) {
    //         setModalData({
    //             ...modalData,
    //             legal_entity: entityid
    //         })

    //     }
    // }, [modalData.legal_entity])




    const [baseData, setBaseData] = useState([]);
    const [baseData1, setBaseData1] = useState([])
    const [finalBaseData, setFinalBaseData] = useState([])
    console.log(baseData, 'baseData');

    // useEffect(() => {
    //     // if (type === 'active' && baseData.length > 0) {
    //     let activeData = _.filter(baseData, { is_active: 1 })
    //     setActiveArray(activeData)
    //     // }
    //     // else if (type === 'inactive' && baseData.length > 0) {
    //     let inactiveData = _.filter(baseData, { is_active: 0 })
    //     setInActiveArray(inactiveData)
    //     // }
    //     // else if (type === 'unblock' && baseData.length > 0) {
    //     let unblockData = _.filter(baseData, { currentStatus: 1 })
    //     setUnBlockedArray(unblockData)
    //     // }
    //     // else {
    //     let block = _.filter(baseData, { currentStatus: 0 })
    //     setBlockedArray(block)
    //     // }
    //     let reactive = _.filter(baseData, { currentStatus: 2 })
    //     setReactiveArray(reactive)
    // }, [baseData])

    // useEffect(() => {
    //     let entityArray = []
    //     let tempArraryNew = []
    //     if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
    //         let array1 = _.filter(unitClosureList.UnitClosureList, { is_active: 0 });
    //         setActivedata(array1.length)
    //         let array2 = _.filter(unitClosureList.UnitClosureList, { is_active: 1 });
    //         setInactivedata(array2.length)

    //     }
    //     if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
    //         unitClosureList.UnitClosureList.map((subList) => {
    //             let currentStatus = ''
    //             if (subList.is_active == 0) {
    //                 currentStatus = 0 //close
    //             } else if (subList.is_active == 1 && subList.validity_days <= 30) {
    //                 currentStatus = 2 //reactivate
    //             } else if (subList.is_active == 1 && subList.validity_days > 30) {
    //                 currentStatus = 1 // closed
    //                 let tempclosed = Number(closeddata) + 1
    //                 setCloseddata(tempclosed)
    //             } else {
    //                 currentStatus = subList.is_active //default is_active
    //             }
    //             entityArray = {
    //                 'address': subList.address,
    //                 'business_group_name': subList.business_group_name,
    //                 'category_name': subList.category_name,
    //                 'closed_on': subList.closed_on,
    //                 'division_name': subList.division_name,
    //                 'is_active': subList.is_active,
    //                 'legal_entity_id': subList.legal_entity_id,
    //                 'legal_entity_name': subList.legal_entity_name,
    //                 'postal_code': subList.postal_code,
    //                 'unit_code': subList.unit_code,
    //                 'unit_id': subList.unit_id,
    //                 'unit_name': subList.unit_name,
    //                 'validity_days': subList.validity_days,
    //                 'currentStatus': currentStatus,
    //                 'unit': `${subList.unit_code}-${subList.unit_name}`,
    //             }
    //             tempArraryNew.push(entityArray)
    //         })
    //         //setBaseData(unitClosureList.UnitClosureList);
    //         setBaseData(tempArraryNew)
    //         setBaseData1(tempArraryNew)
    //         setFinalBaseData(tempArraryNew)
    //     }
    // }, [unitClosureList])

    // useEffect(() => {
    //     return () => {
    //         dispatch({
    //             type: UNIT_CLOSURE_LIST,
    //             payload: ['', { unit_closure_units: [] }]
    //         })
    //     }
    // }, [])

    // useEffect(() => {
    //     if (baseData1 && baseData1.length > 0) {
    //         let reactive = _.filter(baseData1, { currentStatus: Number(2) })
    //         setReActivatedArray(reactive)
    //     }

    // }, [baseData1])

    // useEffect(() => {
    //     if (type === 'inactive') {
    //         let inactive = _.filter(baseData, { is_active: Number(1) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'activeData') {
    //         let inactive = _.filter(baseData, { is_active: Number(0) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'close') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(0) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'closed') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(1) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'reactivate') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(2) })
    //         setFilteredArray(inactive)
    //     }

    // }, [baseData, type])


    // const searchRecords = (e) => {
    //     console.log(e, "eeeeeeeeeee")
    //     if (e == 'clear') {
    //         setClearText('')
    //         setBaseData(finalBaseData)
    //         setIconEnable(false)
    //     } else {
    //         setClearText(e.target.value)
    //         if (e.target.value.length > 0) {
    //             setIconEnable(true)
    //         } else {
    //             setIconEnable(false)
    //         }
    //         const filterTable = finalBaseData.filter(o =>
    //             Object.keys(o).some(k =>
    //                 String(o[k])
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase())
    //             )
    //         );
    //         setBaseData(filterTable)
    //     }

    // };


    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-0 pt-0 page-title-sticky">
                        <div className="col-md-12">
                            <div className="row">
                                {/* <div className="col-md-4 ps-1 py-1"> */}
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transactions</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span>Legal Entity Closure </span>
                                            </li>
                                        </ol>
                                    </nav>
                                    {/* </div> */}
                                </div>
                                <div className="col-md-4 pe-1 py-1 text-end">
                                    {/* <div className="search-box" style={{ position: 'absolute', right: '20px', marginRight: '35px', width: '240px', boxShadow: ' 0 5px 5px rgb(0 0 0 / 10%)' }} >
                                        <input className="search-txt" value={clearText} type="text" onChange={searchRecords} placeholder="Type to Search" />
                                        <a className="search-btn" style={{ position: 'absolute', right: 1 }} >
                                            {iconEnable == true ?
                                                <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                : <i className="fas fa-search"></i>}
                                        </a>
                                    </div>*/}
                                    <button onClick={(e) => { fullscreenMode() }} className="bg-transparent mt-1 text-black full-mode fullscreenradius" >
                                        <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginLeft: '1%', marginRight: '1%' }}>
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory m-2 '}>
                            <Collapse className='report-collapse' defaultActiveKey={["1"]} style={{ display: entityid ? "block" : "none" }}  >
                                <Panel header="Unit Closure Statistics" key={baseData && baseData.length > 0 ? 1 : 0} >
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Card className='rounded-3 unitClosure'>
                                                    <h6>Overall Statistics</h6>
                                                    <div className="service-provider-segment">
                                                        <Space direction="vertical">
                                                            <Segmented size='small'
                                                                onChange={(value) => {
                                                                    setType(value)

                                                                }}
                                                                options={[
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('hello');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-book-mark-fill"></i>}
                                                                                />
                                                                                <div>All Data</div>
                                                                                <b>{baseData && baseData.length}</b>
                                                                            </div>
                                                                        ),
                                                                        value: 'active',
                                                                    },

                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                activeData style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-follow-fill"></i>}
                                                                                />
                                                                                <div>Active</div>
                                                                                <b>{inActiveArray && inActiveArray.length}</b>



                                                                            </div>
                                                                        ),
                                                                        value: 'activeData',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('inactive');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-unfollow-fill"></i>}
                                                                                />
                                                                                <div>Inactive</div>
                                                                                <b>{activeArray && activeArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'inactive',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-ban"></i>}
                                                                                />
                                                                                <div>Closed</div>
                                                                                <b>{unBlockedArray && unBlockedArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'closed',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('blocked');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-sync"></i>}
                                                                                />
                                                                                <div>Reactivate</div>
                                                                                <b>{reactiveArray && reactiveArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'reactivate',
                                                                    },
                                                                ]}
                                                            />
                                                        </Space>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-md-6 userCard userCard1" style={{ height: '219px', width: '50%' }}>
                                                <Card className='rounded-3' style={{ minHeight: '172px' }}>
                                                    <h6>Action Needed</h6>
                                                    <div id='service-table-action' className='service-table'>
                                                        <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                            sortDirections={["ascend", "descend", "ascend"]}
                                                            // columns={columns1}
                                                            dataSource={reactivatedArray}
                                                            pagination={false}
                                                            onChange={tablehandleChange1}
                                                        />
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="card-body p-0 pt-2 service-provider-table">
                                        <Table
                                            key={type === 'active' ? baseData : filteredArray}
                                            id={'unitclosuretableview'}
                                            sortDirections={["ascend", "descend", "ascend"]}
                                            columns={columns}
                                            className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                            size={'small'}
                                            dataSource={list.legalentity_closure}
                                            onChange={tablehandleChange}
                                            bordered
                                            pagination={false}
                                            // scroll={{ x: 1000 }}
                                            showSorterTooltip={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal autoFocus={false} open={modalVisible} footer={null} className='usr_modal_class'
                    onCancel={passwordcancel}
                    maskClosable={false}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                ref={serviceProviderRef1}
                                autoFocus={true}
                                type="password"
                                id="password"
                                name='password'
                                maxLength={'20'}
                                className="form-control"
                                autoComplete='off'
                                placeholder="Enter password"
                                value={modalData.password}
                                style={{ width: '95%' }}
                                onChange={(e) => {
                                    setModalData({
                                        ...modalData,
                                        password: e.target.value
                                    })
                                }} />
                            {formValidator.current.message(
                                'password',
                                modalData.password,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required',
                                    }
                                })}
                        </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <label htmlFor=""><b>Reason</b> <span style={{ "color": "red" }}>*</span></label>
                        <div className='d-flex align-items-end'>

                            <textarea className='form-control passwrd-remark '
                                placeholder="Enter Reason"
                                maxLength={'500'}
                                onKeyPress={(event) => {
                                    if (/[ ~ \ | ^ ]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                // onChange={(e) => {

                                //     setRemarks(e.target.value)
                                // }}
                                onChange={onInputChange}
                                value={remarks}

                            ></textarea>

                            {/* <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => { showRemarksModal() }}>
                                <PlusCircleOutlined />
                            </span> */}
                        </div>
                        {formValidator.current.message(
                            'reason',
                            remarks,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Reason Required',
                                }
                            })}

                    </div>
                    <br />

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}

                            icon={<PlayCircleOutlined />} size='default'
                            onClick={OnSubmited}
                        >
                            Submit
                        </Button>
                        {/* <div className="button2" id={localStorage.getItem('currentTheme')} onClick={OnSubmited}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Submit
                        </div> */}
                    </div>

                </Modal>
            </div>
            <Modal title="Remarks List" open={remarksModal}
                // onOk={handleOk}
                // onCancel={handleCancel} 
                // className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')} footer={false}
                maskClosable={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    // columns={RemarksColumn}
                    // dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    // pagination={{
                    //     pageSizeOptions: ['10', '30', '50'],
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger
                    // }}
                    pagination={false}
                />
            </Modal>
            {/* </div>
        </div> */}
        </Fragment >

    )
}
export default connect(mapStateToProps, {
    // getUnitClosureList,
    // GetRemarksData,
    saveLegalEntityClosureData,
    getLegalEntityClosureReportData
})(LegalEntityClosure);