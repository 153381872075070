import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { notificationshowall,updatenotification } from '../../Store/Action/Login';

const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.messages,

})
const Messages = ({
    notificationshowall,
    lists,
    updatenotification
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    


    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '12%'

        },
        {
            title: 'Title',
            dataIndex: 'message_text',
            key: 'message_text',
            width: '150px',
            render: (text, record) => {
                if (record.link == null) {
                    return <span className='pointer text-wrap-report' onClick={(e) => { markread(record) }}>{record.message_text}</span>
                } else {
                    return <span className='pointer text-wrap-report' onClick={(e) => { markread(record) }}>
                        <span className='text-wrap-report'>{record.message_text}</span><Link onClick={(e) => { downloadreport(record.link,record) }}>Your can download document here.</Link></span>
                }
            }

        },
    ];

    const downloadreport = (e,record) => {
        window.open(process.env.REACT_APP_API_URL + e);
        markread(record)

    }

    const markread = (record) => {
        let notifypayload;
        let home = callername.login_response.menu.menus['My Accounts'];
        let url = _.filter(home, { form_name: 'Messages'});

        if (authtoken) {
            notifypayload = {
                "session_token": authtoken,
                "request": [
                 "UpdateMessageStatus",
                 {
                  "message_id": record.message_id,
                  "has_read": true
                 }
                ]
               }
           
        } 
        updatenotification({
            payload: notifypayload,
            caller: url[0].form_url,
            key: url[0].form_key

        })

    }

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu.menus['My Accounts'] 
            const messageArray = _.filter(getUrl, { form_name: 'Messages'});
            const messagekey = messageArray[0].form_key;

            let payload = {
                "session_token": authtoken,
                "request": [
                 "GetMessages",
                 {
                  "from_count": 0,
                  "page_count": 50
                 }
                ]
            }      
                
            notificationshowall({
                payload: payload,
                caller: paramid,
                key: messagekey
            })

        }
    }, [isAuth])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Home</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Messages</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>

                                        {/* <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                fixedHeader={dataTableProperties.fixedHeader}
                                                pagination
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            /> */}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={lists}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    updatenotification
})(Messages);