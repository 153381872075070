import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { TOGGLE_COMMON_LOADER, ASSIGN_STATUTORY_BULK_UPLOAD, DOWNLOAD_ASSIGN_STATUTORY, DOWNLOAD_ASBU_LINK, UPLOAD_ASSIGN_STATUTORY_CSV, UPLOAD_FILE_STATUS } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';

export const filterdetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                dispatch({
                    type: ASSIGN_STATUTORY_BULK_UPLOAD,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const downloadAssignStatutory = ({ payload, executeCancel, paramid }) => async dispatch => {
    const payloadTemp = payload;
    const paramidTemp = paramid;
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'Alive') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                setTimeout(() => {
                    dispatch(downloadAssignStatutory({
                        payload: payloadTemp,
                        paramid: paramidTemp
                    })) 
                }, 9000)
            } else if(decryptData[0] === 'Done'){
                dispatch({
                    type: DOWNLOAD_ASSIGN_STATUTORY,
                    payload: decryptData[1],
                });                
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const downloadLink = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log("Download Log");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decrypt');
            if (decryptData[0] === 'Alive') {
                dispatch(downloadLink({
                    payload: payload,
                    paramid: paramid
                }))                
            } else if (decryptData[0] === 'DownloadAssignStatutorySuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                dispatch({
                    type: DOWNLOAD_ASBU_LINK,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadCSV = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'Done') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                dispatch({
                    type: UPLOAD_ASSIGN_STATUTORY_CSV,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadFileStatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log('decryptData', decryptData);
            const decryptMessage = decryptData[0];
            let clear;
            if (decryptMessage !== 'Alive') {
                dispatch({
                    type: UPLOAD_FILE_STATUS,
                    payload: decryptData,
                });
                clearTimeout(clear);
                dispatch({
                    type: TOGGLE_COMMON_LOADER,
                    payload: true
                });
            }
            if (decryptData[0] === 'Alive') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER,
                    payload: false
                });
                clear = setTimeout(() => {
                    dispatch(uploadFileStatus({
                        payload: payload,
                        paramid: paramid
                    }));
                }, 9000);
            }
            // if (decryptMessage === 'RejectionMaxCountReached') {
            //     dispatch({
            //         type: UPLOAD_FILE_STATUS,
            //         payload: decryptData[1],
            //     });
            // }
            // if (decryptMessage === 'UploadAssignStatutoryCSVFailed') {
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });

            //     dispatch({
            //         type: UPLOAD_FILE_STATUS,
            //         payload: decryptData[1],
            //     });
            // }
            // if (decryptMessage === 'UploadAssignStatutoryCSVSuccess') {
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });

            //     dispatch({
            //         type: UPLOAD_FILE_STATUS,
            //         payload: decryptData[1],
            //     });
            // }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

