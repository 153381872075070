import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal, Card, Checkbox } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { productDomainList, getComplianceApprove, getDomainExecutiveDetails, getFilterCompliance } from '../../Store/Action/Transactions/ApproveComplianceBasedAssignStatutory_BU';
import { CloudDownloadOutlined, FileExcelTwoTone, FileWordTwoTone, FileTextTwoTone, FileTwoTone } from "@ant-design/icons";
import URL from '../../Libs/URL';
import Swal from 'sweetalert2';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";


const mapStateToProps = (state) => ({
    productDomainData : state.ApproveComplianceBasedASBU.productDomain.bu_product_domains_data,
    complianceData: state.ApproveComplianceBasedASBU.complianceForApprove.pending_compliance_csv_list_as,
    domainExecutiveData: state.ApproveComplianceBasedASBU.domainExecutive.domain_executive_info
});

const ApproveComplianceBased_AS_BulkUpload = ({
    productDomainList,
    productDomainData,
    getComplianceApprove,
    getDomainExecutiveDetails,
    complianceData,
    domainExecutiveData,
    getFilterCompliance
}) => {

    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [exitCollapse, setExitCollapse] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [product, setProduct] = useState({
        productList: '',
        domainList: ''
    });
    const [selectedValues, setSelectedValues] = useState({
        productID: '',
        productName: '',
        domainID: ''
    })
    const [download, setDownload] = useState(false);

    useEffect(() => {
        const pay = {
            "session_token": authtoken,
            "request": [
             "GetProductDomainInfo",
             {}
            ]
        }

        productDomainList({
            payload: pay,
            paramid: paramid
        })

        formValidator.current.showMessages();
    },[])

    useEffect(() => {
        if (productDomainData) {
            const types = productDomainData.map(item => item.product);
            const productTypes = types.filter((item,index) => types.indexOf(item) === index);
            setProduct({
                ...product,
                productList: [{'name': "Compfie", 'id': 1}, {'name': "Compfie Vendor", 'id': 3}],
                domainList: productDomainData,
            })
        }

    }, [productDomainData])

    const homescreen = () => {
        window.location.href = '/dashboard';
    };

    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '16px',
            render: (text, record, index) => <span>{++index}</span>
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            width: '120px',
            key: 'csv_name',
            align: 'center',
            // ellipsis: true,
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            width: '70px',
            key: 'uploaded_on',
            align: 'center',
            // ellipsis: true,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_on',
            width: '90px',
            key: 'uploaded_on',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                if (domainExecutiveData) {
                    let obj = domainExecutiveData.find(item => item.user_id === record.uploaded_by);
                    return <span>{obj.emp_code_name}</span>                    
                }
            }
        },
        {
            title: 'No. of Records',
            dataIndex: 'no_of_records',
            width: '50px',
            key: 'no_of_records',
            align: 'center',
            // ellipsis: true,
        },
        {
            title: 'Approved / Rejected',
            dataIndex: 'uploaded_on',
            width: '45px',
            key: 'uploaded_on',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                return <span>{record.approved_count} / {record.rej_count}</span>
            }
        },
        {
            title: 'Download',
            dataIndex: false,
            width: '36px',
            key: false,
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                const name = record.download_file.split('.')[0];
                const link = URL.CommonURL + '/uploaded_file/';

                return <div>
                    <Tooltip title="Download Excel">
                        <span style={{ marginRight: '5px' }}><a href={link + 'xlsx/' + name + '.xlsx'}><FileExcelTwoTone /></a></span>
                    </Tooltip>
                    <Tooltip title="Download CSV">
                        <span style={{ marginRight: '5px' }}><a href={link + 'csv/' + name + '.csv'}><FileWordTwoTone /></a></span>
                    </Tooltip>
                    <Tooltip title="Download ODS">
                        <span style={{ marginRight: '5px' }}><a href={link + 'ods/' + name + '.ods'}><FileTwoTone /></a></span>
                    </Tooltip>
                    <Tooltip title="Download Text">
                        <span><a href={link + 'txt/' + name + '.txt'}><FileTextTwoTone /></a></span>
                    </Tooltip>
                </div>;
            }
        },
        {
            title: 'Task Wise Action',
            dataIndex: false,
            width: '60px',
            key: 'csv_id',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                return <Button shape='round' size='small' onClick={() => {
                    const pay = {
                        "session_token": authtoken,
                        "request": [
                            "AssignStatutoryDataforfilters",
                            {
                                "csv_id": record.csv_id
                            }
                        ]
                    }
                    getFilterCompliance({
                        payload: pay,
                        paramid: paramid
                    })
                }}>View</Button>;
            }
        },
        {
            title: 'Approve',
            dataIndex: false,
            width: '31px',
            key: false,
            align: 'center',
            // ellipsis: true,
            render: () => <Checkbox />
        },
        {
            title: 'Reject',
            dataIndex: false,
            width: '30px',
            key: false,
            align: 'center',
            // ellipsis: true,
            render: () => <Checkbox />
        },
    ]

    const show = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignComplianceStatutoryForApprove",
                    {
                        "domain_id": selectedValues.domainID,
                        "product_id": selectedValues.productID
                    }
                ]
            };

            getComplianceApprove({
                payload: pay,
                paramid: paramid
            });

            const payPass = {
                "session_token": authtoken,
                "request": [
                    "GetDomainExecutiveDetails",
                    {}
                ]
            };            

            getDomainExecutiveDetails({
                payload: payPass,
                paramid: paramid
            });
        }
    }

    return (
        <div id="page-wrapper" className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-1 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>Approve - Reject Compliance Based Assigned Statutory - Bulk Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: " 5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}  >
                                <Panel header="Approve / Reject Compliance Based Assigned Statutory - Bulk Upload" key={1}>
                                    <div className='col-md-12'>
                                        <div className='row pb-3 px-2'>
                                            <div className='col-md-4'>
                                                <label><b>Product :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        name='Product'
                                                        placeholder="Select any product"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={selectedValues.productID || undefined}
                                                        onChange={(data, value) => {
                                                            setSelectedValues({
                                                                productName: value.children,
                                                                productID: data,
                                                                domainID: ''
                                                            })
                                                        }}>
                                                        {product.productList && product.productList.map((item, i) => {
                                                            return <Option key={item.id} value={item.id}>{item.name}</Option>;
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'Product',
                                                        selectedValues.productID,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Product Required'
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        name='Domain'
                                                        placeholder="Select domain"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={selectedValues.domainID || undefined}
                                                        onChange={(data) => {
                                                            setSelectedValues({
                                                                ...selectedValues,
                                                                domainID: data,
                                                            })
                                                        }}>
                                                        {product.domainList && product.domainList.map((item) => {
                                                            if (selectedValues.productName === item.product) {
                                                                return <Option key={item.d_id} value={item.d_id}>{item.d_name}</Option>;
                                                            }
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'Product',
                                                        selectedValues.domainID,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required'
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4' style={{textAlign: 'center', paddingTop: '16px'}}>
                                                <Button shape='round' onClick={show}>Show</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Card className='mt-3'>
                                <Table
                                    columns={columns}
                                    dataSource={complianceData}
                                    pagination={false}
                                    bordered
                                />
                            </Card>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, {
    productDomainList,
    getComplianceApprove,
    getDomainExecutiveDetails,
    getFilterCompliance
})(ApproveComplianceBased_AS_BulkUpload);