import { CLIENT_AGREEMENT_REPORT_FILTERS, DOMAIN_WISE_AGREEMENT_REPORT_DATA, AGGREMENT_REPORT_DISPLAY_POPUP, DOMAIN_AGREEMENT_EXPORT_TO_CSV } from '../../types/index';

const initialState = {
    ClientAgreementReportFilters: [],
    DomainwiseAgreementReportData: [],
    AggrementReportDisplayPopup: [],
    ExportToCSV: ''

}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CLIENT_AGREEMENT_REPORT_FILTERS:
            return {
                ...state,
                ClientAgreementReportFilters: payload
            }

        case DOMAIN_WISE_AGREEMENT_REPORT_DATA:
            return {
                ...state,
                DomainwiseAgreementReportData: payload
            }

        case AGGREMENT_REPORT_DISPLAY_POPUP:
            return {
                ...state,
                AggrementReportDisplayPopup: payload
            }

        case DOMAIN_AGREEMENT_EXPORT_TO_CSV:
            return {
                ...state,
                ExportToCSV: payload
            }

        default:
            return state;
    }

}