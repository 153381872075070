import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
    getusers, getrejectedlist, getbuconstant, getclientgrouplist, getdownloadcount, getreportdata,
    getdownloadstatus, removefile
} from '../../Store/Action/Transactions/TE_RejectClientunitBulkUpload';
import { CloseCircleOutlined, EyeOutlined, UpOutlined, FullscreenOutlined, FilterOutlined, FileExcelTwoTone, FileTextTwoTone, FileTwoTone, ProfileTwoTone, PlayCircleOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import Swal from "sweetalert2";
const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Rejectedclientunit.Rejectedclientunit.getusers,
    rejectedlists: state.Rejectedclientunit.Rejectedclientunit.rejectedlist,
    rejectedfilename: state.Rejectedclientunit.Rejectedclientunit.rejectedfilename,
    downloadlink: state.Rejectedclientunit.Rejectedclientunit.downloadlink

})
const TE_RejectClientUnitBulkUpload = ({
    getusers,
    getbuconstant,
    getrejectedlist,
    getclientgrouplist,
    rejectedlists,
    lists,
    getdownloadcount,
    getreportdata,
    rejectedfilename,
    getdownloadstatus,
    downloadlink,
    removefile
}) => {
    console.log(rejectedlists, "rejectedlistsrejectedlists");
    const location = useLocation();
    const paramid = location.pathname;
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filteredData, setFilteredData] = useState({
        clientgroup: "",
        clientgroupname: ''

    })
    const [domainList, setDomainList] = useState([]);
    const validator = useRef(new SimpleReactValidator());
    const [visible, setVisible] = useState(false);
    const [fileformat, setfileformat] = useState();
    const [removefileid, setremovefileid] = useState();
    const [passwordmodal, setpasswordmodal] = useState(false);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
    })
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    useEffect(() => {
        validator.current.showMessages()
        if (isAuth) {
            let payloadbuconstant = {
                "session_token": authtoken,
                "request": [
                    "GetBulkUploadConstants",
                    {}
                ]
            }
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetUsers",
                    {}
                ]
            }

            let payloadclientgrouplist = {
                "session_token": authtoken,
                "request": [
                    "GetClientGroupsList",
                    {}
                ]
            }

            getbuconstant({
                payload: payloadbuconstant,
                caller: paramid,
            })

            getclientgrouplist({
                payload: payloadclientgrouplist,
                caller: paramid,
            })
            getusers({
                payload: payload,
                caller: paramid,
            })



        }
    }, [isAuth]);

    // useEffect(() => {
    //     let domainArray = [];
    //     let tempArray = [];
    //     if (filteredData.clientgroup) {
    //         domain && domain.length > 0 && domain.map((domainList) => {
    //             const checkDomainExists = domainList.country_ids.includes(parseInt(filteredData.clientgroup));
    //             if (checkDomainExists) {
    //                 tempArray = {
    //                     'domain_id': domainList.domain_id,
    //                     'domain_name': domainList.domain_name
    //                 }
    //                 domainArray.push(tempArray)
    //             }
    //         })
    //         setDomainList(domainArray)
    //     }
    // }, [filteredData.clientgroup])

    const show = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setVisible(true)
            let payloadaction = {
                "session_token": authtoken,
                "request": [
                    "GetClientUnitRejectedData",
                    {
                        "bu_client_id": parseInt(filteredData.clientgroup),
                    }
                ]
            }

            getrejectedlist({
                payload: payloadaction,
                caller: paramid,
            })
        }
    }
    const [paginationSize, setPaginationSize] = useState(25);
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '30px',
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * paginationSize + index + 1
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name_text',
            key: 'csv_name_text',
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Rejected On',
            width: '130px',
            dataIndex: "rejected_on",
            key: "rejected_on",
            align: 'center',
        },
        {
            title: 'Rejected By',
            width: '90px',
            dataIndex: "rejected_by",
            key: "rejected_by",
            align: 'center',
        },
        {
            title: 'No. of Records',
            width: '100px',
            dataIndex: 'total_records',
            key: 'total_records',
            align: 'center',
        },
        {
            title: 'Declined Count',
            width: '120px',
            dataIndex: 'declined_count',
            key: 'declined_count',
            align: 'center',
            render: (text, record) => {
                if (text == null) {
                    return '-'
                } else {
                    return text
                }
            }
        },
        {
            title: 'Rejected File',
            width: '150px',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return (
                    <div className='d-flex justify-content-around'>
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('excel', record.csv_id) }}>
                            < FileExcelTwoTone title='Download Excel' />
                        </span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('csv', record.csv_id) }}>
                            <FileTwoTone title='Download CSV' />
                        </span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('ods', record.csv_id) }}>
                            <ProfileTwoTone title='Download ODS' />
                        </span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('text', record.csv_id) }}>
                            <FileTextTwoTone title='Download Text' />
                        </span>
                    </div>
                )
            }
        },
        {
            title: 'Reason for Rejection',
            width: '120px',
            dataIndex: 'rejected_reason',
            key: 'rejected_reason',
            align: 'center',
        },
        {
            title: 'Remove',
            width: '70px',
            dataIndex: 'file_download_count',
            key: 'file_download_count',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != 0 ?
                            <div>
                                <span style={{ margin: 'auto' }}
                                    className="btn btn-sm btn-light-danger
                      text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { rejectedfile(record) }}>
                                    <Tooltip title="Click here to remove"><CloseCircleOutlined /></Tooltip>
                                </span>
                            </div> : '-'}
                    </Fragment>
                )
            }
        }
    ];

    const downloadfile = (e, id) => {
        setfileformat(e);
        const downpay = {
            "session_token": authtoken,
            "request": [
                "UpdateDownloadCountToRejectedStatutory",
                {
                    "csv_id": id
                }
            ]
        }
        const filepay = {
            "session_token": authtoken,
            "request": [
                "DownloadRejectedSMReportData",
                {
                    "csv_id": id,
                    "bu_client_id": parseInt(filteredData.clientgroup),
                    "download_format": e
                }
            ]
        }

        getdownloadcount({
            payload: downpay,
            caller: paramid
        })

        getreportdata({
            payload: filepay,
            caller: paramid
        })

    }

    useEffect(() => {
        if (rejectedfilename) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetRejDownloadStatus",
                    {
                        "csv_name": rejectedfilename.csv_name
                    }
                ]
            }

            let payloadaction = {
                "session_token": authtoken,
                "request": [
                    "GetClientUnitRejectedData",
                    {
                        "bu_client_id": parseInt(filteredData.clientgroup),


                    }
                ]
            }


            getdownloadstatus({
                payload: pay,
                caller: paramid
            })


            getrejectedlist({
                payload: payloadaction,
                caller: paramid,
            })
        }
    }, [rejectedfilename])

    useEffect(() => {
        if (downloadlink) {
            if (fileformat == 'csv') {
                window.open(process.env.REACT_APP_API_URL + downloadlink.csv_link);
            } else if (fileformat == 'excel') {
                window.open(process.env.REACT_APP_API_URL + downloadlink.xlsx_link);
            } else if (fileformat == 'ods') {
                window.open(process.env.REACT_APP_API_URL + downloadlink.ods_link);
            } else {

                window.URL = window.URL || window.webkitURL;

                var xhr = new XMLHttpRequest(),
                    a = document.createElement('a'), file;

                xhr.open('GET', 'https://13.234.100.64/knowledge/rejected/downloads/txt/Statutory_Mapping%20(15)_a7d97d13e5f942b7a95d275704cdab3d_download.txt', true);
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    file = new Blob([xhr.response], { type: 'application/octet-stream' });
                    a.href = window.URL.createObjectURL(file);
                    a.download = 'someName.txt';
                    a.click();
                };
                xhr.send();

                // axios.get('https://13.234.100.64' + downloadlink.txt_link)
                //     .then(res => console.log("res", res))


                // window.open('https://13.234.100.64' + downloadlink.txt_link);
                // window.location.href = 'https://13.234.100.64' + downloadlink.txt_link;

                // const datalink='https://13.234.100.64' + downloadlink.txt_link;
                // function download(dataurl, filename) {
                //     const link = document.createElement("a");
                //     // link.href = 'data:text/plain;charset=UTF-8,' + dataurl;
                //     link.href = 'data:text/plain;charset=UTF-8,' + encodeURI(dataurl);
                //     link.target = '_blank';
                //     link.download = filename;
                //     link.click();
                //   }

                //   download(datalink, "helloWorld.txt");
                //   download(datalink, "helloWorld.txt");
                // fetch('https://13.234.100.64' + downloadlink.txt_link)
                // .then(res => res.text())
                // .then(result => console.log("Result", result))

                // fetch('data:text/plain;charset=UTF-8,' + datalink)
                //     .then(res => console.log("res", res.body))
                // .then(result => console.log(result))

                // function buttonset () {
                //     var myWindow = window.open('https://13.234.100.64' + downloadlink.txt_link);
                //     myWindow.onload = function() {
                //         console.log(myWindow.document.getElementById("result").innerHTML);
                //         myWindow.close();
                //     };
                // }

                // buttonset()
            }
        }
    }, [downloadlink])

    const rejectedfile = (record) => {
        const id = record.csv_id;
        Swal.fire({
            title: "Are you sure?",
            text: 'You want to permanently delete this file?',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: 'custom-color-modal',
        }).then((result) => {
            if (result.isConfirmed) {
                setremovefileid(id);
                setpasswordmodal(true);
            }
        });

    }

    const passwordcancel = () => {
        setpasswordmodal(false);
        setAddFormSubmit1(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }
    useEffect(() => {
        formValidator1.current.showMessages()
    }, [])

    const submitpassword = () => {
        setAddFormSubmit1(true);
        if (formValidator1.current.allValid()) {
            const passpay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": passwordata.passvalue
                    }
                ]
            }

            const filepay = {
                "session_token": authtoken,
                "request": [
                    "DeleteRejectedSMCsvId",
                    {

                        "csv_id": parseInt(removefileid),
                        "c_id": parseInt(filteredData.clientgroup)
                    }
                ]
            }

            removefile({
                payload: passpay,
                caller: paramid,
                filepayload: filepay
            })
            setpasswordmodal(false);
            show()
            setAddFormSubmit1(false);
        }

    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    //  Full screen mode //
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <div id="page-wrapper" className="page-wrapper">
            <ScrollToTop smooth component={
                <Tooltip title="Back to top">
                    <UpOutlined />
                </Tooltip>
            }
            />
            <div className="page-titles pb-1 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        {/* <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Rejected Statutory Mapping - Bulk Upload</Link>
                                </li>
                            </ol>
                        </nav> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Rejected Client Unit - Bulk Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 mt-1 col-md-6 col-12 text-end">
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Rejected Client Unit - Bulk Upload" key={"1"}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><b>Client Group : </b><span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Client Group"
                                                        style={{ width: '100%' }}
                                                        value={filteredData.clientgroupname || undefined}
                                                        onChange={(data, value) => {
                                                            setFilteredData({
                                                                ...filteredData,
                                                                clientgroup: data ? data : '',
                                                                clientgroupname: value.children ? value.children : ''
                                                            })

                                                        }
                                                        }
                                                    >
                                                        {lists && lists.length > 0 && lists.map((list) => {
                                                            return (
                                                                <Option key={list.client_id}>
                                                                    {list.group_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'clientgroup',
                                                        filteredData.clientgroup,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Client Group Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-6' style={{ textAlign: 'center', marginTop: '13px' }}>
                                                <Button type="primary" shape="round" className='addbutton' icon={<EyeOutlined />} onClick={show}>
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title="Rejected Client Unit - Bulk Upload"
                                className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                footer={false}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}
                            >

                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label><b>Client Group : </b><span style={{ color: "red" }}> *</span></label>
                                            <div className="form-group">
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Client Group"
                                                    style={{ width: '100%' }}
                                                    value={filteredData.clientgroupname || undefined}
                                                    onChange={(data, value) => {
                                                        setFilteredData({
                                                            ...filteredData,
                                                            clientgroup: data ? data : '',
                                                            clientgroupname: value.children ? value.children : ''

                                                        })
                                                    }
                                                    }
                                                >
                                                    {lists && lists.length > 0 && lists.map((list) => {
                                                        return (
                                                            <Option key={list.client_id}>
                                                                {list.group_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'clientgroup',
                                                    filteredData.clientgroup,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Client Group Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>


                                        <div className='col-md-6' style={{ textAlign: 'center', marginTop: '13px' }}>
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button' icon={<EyeOutlined />} onClick={show}>
                                                Show
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            {visible == true ?
                                <div className="mt-2">
                                    <Table
                                        size={'small'}
                                        columns={columns}
                                        dataSource={rejectedlists}
                                        bordered
                                        pagination={false}
                                    // scroll={{ x: 1500 }}
                                    />
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={passwordmodal} title='Enter Password' footer={null} onCancel={passwordcancel}
                className={"newStyleModalPassword custom-color-modal " + localStorage.getItem('currentTheme')} >
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label>Enter Password :</label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions text-center">
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword} >
                        Submit
                    </Button>
                </div>
            </Modal>
        </div>

    )
}
export default connect(mapStateToProps, {
    getusers,
    getrejectedlist,
    getbuconstant,
    getclientgrouplist,
    getdownloadcount,
    getreportdata,
    getdownloadstatus,
    removefile
})(TE_RejectClientUnitBulkUpload);