import { UNIT_CODE_TABLE, UNIT_CODE_DATA_VIEW, UNIT_CODE_SUCCESS_RESET, UNIT_CODE_SUCCESS } from '../../types/index'

const initialState = {
    unitcode: {
        tabledata: '',
        viewdata: '',
        successdata: ''
    }
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UNIT_CODE_TABLE:
            {
                return {
                    ...state,
                    unitcode: {
                        ...state.unitcode,
                        tabledata: payload,
                    },
                };
            }
        case UNIT_CODE_DATA_VIEW:
            {
                return {
                    ...state,
                    unitcode: {
                        ...state.unitcode,
                        viewdata: payload
                    }
                }
            }
        case UNIT_CODE_SUCCESS:
            {
                return {
                    ...state,
                    unitcode: {
                        ...state.unitcode,
                        successdata: payload
                    }
                }
            }
        case UNIT_CODE_SUCCESS_RESET:
            {
                return {
                    ...state,
                    unitcode: {
                        ...state.unitcode,
                        successdata: ''
                    }
                }
            }

        default:
            return state;
    }
}