import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Modal } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import { uploadconstant, getclientinfo, getusers, usermapping, getrecorddetails, exportdetails } from '../../Store/Action/Reports/Assigned_stat_bulkReport'
import ScrollButton from "../Scrollbar";


const mapStateToProps = (state) => ({
    info: state.Assinged_sta_bulkReport.assignedstatutory.clientinfo,
    filterrecord: state.Assinged_sta_bulkReport.assignedstatutory.recorddata,
    knowledgeman: state.Assinged_sta_bulkReport.assignedstatutory.usermapping,
})
const ApproveStatutoryBulkReport = ({
    uploadconstant,
    getclientinfo,
    getusers,
    usermapping,
    getrecorddetails,
    filterrecord,
    exportdetails,
    knowledgeman,
    info
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const [displayresult, setdisplayresult] = useState('none');
    const [filterModalVisible, setfilterModalVisible] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [fullscreen, setfullscreen] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [exitCollapse, setExitCollapse] = useState(false);
    const [key, setKey] = useState("1")
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [showfiltericon, Setshowfiltericon] = useState('none');
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);



    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const [filterdata, setfilterdata] = useState({
        clientgroup: '',
        groupname: '',
        legal: '',
        legaldata: '',
        legalname: '',
        domain: '',
        domaindata: '',
        domainname: '',
        DEname: '',
        DEdata: '',
        fromdate: '',
        todate: ''
    })

    useEffect(() => {
        if (isAuth) {
            const uploadpay = {
                "session_token": authtoken,
                "request": [
                    "GetBulkUploadConstants",
                    {}
                ]
            }
            const userpay = {
                "session_token": authtoken,
                "request": [
                    "GetUsers",
                    {}
                ]
            }
            const clientpay = {
                "session_token": authtoken,
                "request": [
                    "GetClientInfo",
                    {}
                ]
            }
            const usermappingpay = {
                "session_token": authtoken,
                "request": [
                    "GetUserMappings",
                    {}
                ]
            }

            uploadconstant({
                payload: uploadpay,
                paramid: paramid
            })
            getclientinfo({
                payload: clientpay,
                paramid: paramid
            })
            getusers({
                payload: userpay,
                paramid: paramid
            })
            usermapping({
                payload: usermappingpay,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        if (filterdata.clientgroup != '') {
            const legal = _.filter(info.bu_legalentites, { cl_id: parseInt(filterdata.clientgroup) })
            setfilterdata({
                ...filterdata,
                legaldata: legal
            })
        }
    }, [filterdata.clientgroup])

    const selectedlegal = (data) => {
        if (data == undefined) {
            setfilterdata({
                ...filterdata,
                legal: '',
                domain: '',
                DEname: '',
                fromdate: '',
                todate: ''
            })
        } else {
            const domain = _.filter(info.bu_legalentites, { cl_id: parseInt(filterdata.clientgroup), le_id: parseInt(data.value) })
            setfilterdata({
                ...filterdata,
                legal: data.value,
                legalname: data.children,
                domaindata: domain[0].bu_domains
            })

        }

    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    const [selectdomain, Setselectdomain] = useState("block");
    const [unselectdomain, Setunselectdomain] = useState("none");

    const Handeldomain = (obj) => {
        let allArray = [];
        let alllable = [];
        if (obj.includes("all")) {
            if (filterdata.domaindata && filterdata.domaindata.length > 0) {
                let temp = [];
                for (let i in filterdata.domaindata) {
                    let Code = filterdata.domaindata[i].d_id;
                    let label = filterdata.domaindata[i].d_name
                    temp.push(Code);
                    allArray.push(Code);
                    alllable.push(label)
                }
                setfilterdata({
                    ...filterdata,
                    domain: allArray,
                    domainname: alllable
                })
            }
            Setselectdomain("none");
            Setunselectdomain("block");
        } else if (obj.includes("unselect")) {
            let temp = [];
            setfilterdata({
                ...filterdata,
                domain: [],
                domainname: []
            })
            Setselectdomain("block");
            Setunselectdomain("none");
        } else if (obj.includes("unselect")) {
            let temp = [];
            Setselectdomain("block");
            Setunselectdomain("none");
        } else {
            if (obj.length == filterdata.domaindata.length) {
                Setselectdomain("none");
                Setunselectdomain("block");
            } else {
                Setselectdomain("block");
                Setunselectdomain("none");
            }
            let arr = [];
            for (let i in obj) {
                const label = _.filter(filterdata.domaindata, { d_id: obj[i] });
                arr.push(label);
            }
            const concat = [].concat.apply([], arr);
            setfilterdata({
                ...filterdata,
                domain: obj,
                domainname: concat
            })
        }
    };

    const showrecord = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryBulkReportData",
                    {
                        "bu_client_id": parseInt(filterdata.clientgroup),
                        "bu_legal_entity_id": parseInt(filterdata.legal),
                        "bu_unit_id": "",
                        "domain_ids": filterdata.domain,
                        "from_date": filterdata.fromdate,
                        "to_date": filterdata.todate,
                        "r_count": 0,
                        "p_count": 25,
                        "child_ids": [
                            191
                        ],
                        "user_category_id": 7
                    }
                ]
            }
            getrecorddetails({
                payload: pay,
                paramid: paramid
            })
            setdisplayresult('block');
            setExitCollapse(true);
            Setshowfiltericon('block');
            setfilterModalVisible(false);
        }
    }

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>
            }
        },
        {
            title: 'Domain',
            dataIndex: 'bu_dom_names',
            key: 'bu_dom_names',
            ellipse: true,
            width: '20%',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: '15%',
            render: (data, record) => {
                let know = _.filter(knowledgeman.domain_users, { user_id: parseInt(data) })
                return know && know.length > 0 && know[0].employee_name
            }
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            key: 'uploaded_on',
            width: '10%',
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            key: 'csv_name',
        },
        {
            title: 'No.of Tasks',
            dataIndex: 'total_records',
            key: 'total_records',
            align: 'center'
        },
        {
            title: <span><Tooltip title={'Approved / Rejected Tasks'}>Tasks</Tooltip></span>,
            dataIndex: 'total_records',
            key: 'total_records',
            align: 'center',
            render: (data, record) => {
                return record.total_approve_records + '/' + record.is_fully_rejected
            },
        },
        {
            title: 'Approved / Rejected On',
            dataIndex: 'approved_on',
            key: 'approved_on',
            width: '10%',
        },
        {
            title: 'Approved / Rejected By',
            dataIndex: 'approved_by',
            key: 'approved_by',
            align: 'center',
            width: '15%',
            render: (data, record) => {
                let manager = _.filter(knowledgeman.domain_managers, { user_id: parseInt(data) })
                console.log(knowledgeman.knowledge_managers);
                return manager && manager.length > 0 && manager[0].employee_name
            }
        },
        {
            title: 'Reason for Rejection',
            dataIndex: 'rejected_reason',
            key: 'rejected_reason',
            width: '250px',
        },
    ]

    const setcancelFilter = () => {
        setfilterModalVisible(false);
    }

    const exportfile = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            let d_label = []
            for (let i = 0; i < filterdata.domainname.length > 0; i++) {
                let ab = filterdata.domainname[i].d_name;
                d_label.push(ab);
            }
            const pay = {
                "session_token": authtoken,
                "request": [
                    "ExportASBulkReportData",
                    {
                        "bu_client_id": parseInt(filterdata.clientgroup),
                        "bu_group_name": filterdata.groupname,
                        "bu_legal_entity_id": parseInt(filterdata.legal),
                        "legal_entity_name": filterdata.legalname,
                        "bu_unit_id": "",
                        "unit_name": "",
                        "domain_ids": filterdata.domain,
                        "bu_dom_names": d_label.toString(),
                        "from_date": filterdata.fromdate,
                        "to_date": filterdata.todate,
                        "child_ids": [
                            191
                        ],
                        "user_category_id": 7,
                        "csv": true,
                        "is_select_exe": true
                    }
                ]
            }
            exportdetails({
                payload: pay,
                paramid: paramid
            })
        }

    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper" id="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>Approve / Reject Assigned Statutory - Bulk Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                marginRight: "15px",
                                display: showfiltericon == 'none' ? 'none' : 'inline'
                            }} onClick={() => {
                                setfilterModalVisible(true);
                            }}>
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className='row'>
                    <div className='col-12'>
                        <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Assigned Statutory - Bulk Upload Report" key={key}>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '12px' }}><b>Client Group:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group" style={{ marginLeft: '12px' }}>
                                                    <div className="form-group">
                                                        <Select
                                                            name="clientgroup"
                                                            id="clientgroup"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filterdata.clientgroup || undefined}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Group"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        clientgroup: '',
                                                                        legal: '',
                                                                        domain: '',
                                                                        DEname: '',
                                                                        fromdate: '',
                                                                        todate: ''
                                                                    })
                                                                } else {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        clientgroup: data.value,
                                                                        groupname: data.children
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {info && info.bu_clients.length > 0 && info.bu_clients.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cl_id}>
                                                                        {item.cl_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'clientgroup',
                                                            filterdata.clientgroup,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Client Group Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="legalentity"
                                                            id="legalentity"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filterdata.legal || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filterdata.clientgroup == ''}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(option, data) => {
                                                                selectedlegal(data)
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filterdata && filterdata.legaldata && filterdata.legaldata.length > 0 && filterdata.legaldata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            filterdata.legal,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="domain"
                                                            id="domain"
                                                            allowClear={true}
                                                            mode="multiple"
                                                            size="default"
                                                            value={filterdata.domain || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filterdata.legal == ''}
                                                            placeholder="Enter Domain"
                                                            onChange={(option) => Handeldomain(option)}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            <Option
                                                                key="all"
                                                                value="all"
                                                                style={{ display: selectdomain }}
                                                            >
                                                                ---SELECT ALL---
                                                            </Option>
                                                            <Option
                                                                key="unselect"
                                                                value="unselect"
                                                                style={{ display: unselectdomain }}
                                                            >
                                                                --UNSELECT ALL--
                                                            </Option>
                                                            {filterdata.domaindata && filterdata.domaindata.length > 0 && filterdata.domaindata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id} value={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            filterdata.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Compfie Domain Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 p-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '6px' }}><b>DE Name:</b></label>
                                                <div className="form-group" style={{ marginLeft: '6px' }}>
                                                    <div className="form-group">
                                                        <Select
                                                            name="dename"
                                                            id="dename"
                                                            allowClear={true}
                                                            size="default"
                                                            mode="multiple"
                                                            value={filterdata.DEname || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filterdata.domain == ''}
                                                            placeholder="Enter DE"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        DEname: ''
                                                                    })
                                                                } else {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        DEname: data.value,
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>

                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker format={'DD-MMM-YYYY'} style={{ width: '100%' }} name='fromdate'
                                                            value={filterdata.fromdate ? moment(filterdata.fromdate) : ''}
                                                            placeholder='DD-MM-YYYY'
                                                            disabledDate={(current) => {
                                                                return moment() <= current
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                if (dateString != '') {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        fromdate: dateString,
                                                                        todate: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        fromdate: '',
                                                                        todate: ''
                                                                    })
                                                                }
                                                            }}

                                                        />
                                                        {validator.current.message(
                                                            'fromdate',
                                                            filterdata.fromdate,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker format={'DD-MMM-YYYY'} style={{ width: '100%' }} name='todate'
                                                            value={filterdata.todate ? moment(filterdata.todate) : ''}
                                                            disabledDate={(current) => {
                                                                return moment(filterdata.fromdate) >= current || moment() <= current
                                                            }}
                                                            placeholder='DD-MM-YYYY'

                                                            onChange={(date, dateString) => {
                                                                if (dateString != '') {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        todate: dateString,

                                                                    })
                                                                }
                                                                else {
                                                                    setfilterdata({
                                                                        ...filterdata,
                                                                        todate: ''
                                                                    })
                                                                }
                                                            }}

                                                        />
                                                        {validator.current.message(
                                                            'todate',
                                                            filterdata.todate,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'To Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center p-2'>
                                        <Button type="primary" shape="round" className='addbutton'
                                            style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={<EyeOutlined />} onClick={showrecord}>
                                            Show
                                        </Button>&nbsp;
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={< ExportOutlined />} onClick={exportfile}>
                                            Export
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div style={{ display: displayresult }}>
                    <Table
                        className='reassignclass2'
                        columns={columns}
                        dataSource={filterrecord && filterrecord.assign_statutory_data}
                        bordered
                        pagination={false} />
                    <Pagination style={{ "margin-top": "10px", float: " RIGHT" }}
                        current={current}
                        showSizeChanger={true}
                        showQuickJumper={false}
                        onShowSizeChange={() => {
                            setCurrent(1)
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        hideOnSinglePage={true}
                        defaultPageSize={25}
                        onChange={(page, pageSizes) => {
                            setpageState(true)
                            setCurrent(pageSize !== pageSizes ? 1 : page);
                            setPageSize(pageSizes)
                        }} />
                </div>
            </div>
            <Modal title="Approve / Reject Assigned Statutory Report Filter" className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} footer={false} open={filterModalVisible} onCancel={setcancelFilter}>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label style={{ marginLeft: '12px' }}><b>Client Group:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group" style={{ marginLeft: '12px' }}>
                                <div className="form-group">
                                    <Select
                                        name="clientgroup"
                                        id="clientgroup"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filterdata.clientgroup || undefined}
                                        maxTagCount='responsive'
                                        placeholder="Enter clientgroup"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfilterdata({
                                                    ...filterdata,
                                                    legal: '',
                                                    domain: '',
                                                    DEname: '',
                                                    fromdate: '',
                                                    todate: ''
                                                })
                                            } else {
                                                setfilterdata({
                                                    ...filterdata,
                                                    clientgroup: data.value,
                                                })
                                            }
                                        }}
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {info && info.bu_clients.length > 0 && info.bu_clients.map((item, i) => {
                                            return (
                                                <Option key={item.cl_id}>
                                                    {item.cl_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'clientgroup',
                                        filterdata.clientgroup,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Client Group Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="legalentity"
                                        id="legalentity"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filterdata.legal || undefined}
                                        maxTagCount='responsive'
                                        disabled={filterdata.clientgroup == ''}
                                        placeholder="Enter Legal Entity"
                                        onChange={(option, data) => {
                                            selectedlegal(data)
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filterdata && filterdata.legaldata && filterdata.legaldata.length > 0 && filterdata.legaldata.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        filterdata.legal,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Domain:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="domain"
                                        id="domain"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        mode="multiple"
                                        size="default"
                                        value={filterdata.domain || undefined}
                                        maxTagCount='responsive'
                                        disabled={filterdata.legal == ''}
                                        placeholder="Enter Domain"
                                        onChange={(option) => Handeldomain(option)}
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        <Option
                                            key="all"
                                            value="all"
                                            style={{ display: selectdomain }}
                                        >
                                            ---SELECT ALL---
                                        </Option>
                                        <Option
                                            key="unselect"
                                            value="unselect"
                                            style={{ display: unselectdomain }}
                                        >
                                            --UNSELECT ALL--
                                        </Option>
                                        {filterdata.domaindata && filterdata.domaindata.length > 0 && filterdata.domaindata.map((item, i) => {
                                            return (
                                                <Option key={item.d_id} value={item.d_id}>
                                                    {item.d_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'domain',
                                        filterdata.domain,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Compfie Domain Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 p-2'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label style={{ marginLeft: '6px' }}><b>DE Name:</b></label>
                            <div className="form-group" style={{ marginLeft: '6px' }}>
                                <div className="form-group">
                                    <Select
                                        name="dename"
                                        id="dename"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        mode="multiple"
                                        value={filterdata.DEname || undefined}
                                        maxTagCount='responsive'
                                        disabled={filterdata.domain == ''}
                                        placeholder="Enter DE"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfilterdata({
                                                    ...filterdata,
                                                    DEname: ''
                                                })
                                            } else {
                                                setfilterdata({
                                                    ...filterdata,
                                                    DEname: data.value,
                                                })
                                            }
                                        }}
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>

                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>From Date:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <DatePicker format={'DD-MMM-YYYY'} getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} name='fromdate'
                                        value={filterdata.fromdate ? moment(filterdata.fromdate) : ''}
                                        placeholder='DD-MM-YYYY'
                                        disabledDate={(current) => {
                                            return moment() <= current
                                        }}
                                        onChange={(date, dateString) => {
                                            if (dateString != '') {
                                                setfilterdata({
                                                    ...filterdata,
                                                    fromdate: dateString,
                                                    todate: ''
                                                })
                                            }
                                            else {
                                                setfilterdata({
                                                    ...filterdata,
                                                    fromdate: '',
                                                    todate: ''
                                                })
                                            }
                                        }}

                                    />
                                    {validator.current.message(
                                        'fromdate',
                                        filterdata.fromdate,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'From Date Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>To Date:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <DatePicker format={'DD-MMM-YYYY'} getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} name='todate'
                                        value={filterdata.todate ? moment(filterdata.todate) : ''}
                                        // disabledDate={(current) => {
                                        //     return moment(filterdata.fromdate) >= current
                                        // }}
                                        disabledDate={(current) => {
                                            return moment(filterdata.fromdate) >= current || moment() <= current
                                        }}
                                        placeholder='DD-MM-YYYY'
                                        onChange={(date, dateString) => {
                                            if (dateString != '') {
                                                setfilterdata({
                                                    ...filterdata,
                                                    todate: dateString,

                                                })
                                            }
                                            else {
                                                setfilterdata({
                                                    ...filterdata,
                                                    todate: ''
                                                })
                                            }
                                        }}

                                    />
                                    {validator.current.message(
                                        'todate',
                                        filterdata.todate,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'To Date Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center p-2'>
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                        style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<EyeOutlined />} onClick={showrecord}>
                        Show
                    </Button>&nbsp;
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={< ExportOutlined />} onClick={exportfile}>
                        Export
                    </Button>
                </div>
            </Modal>
        </div >


    )

}
export default connect(mapStateToProps, {
    uploadconstant,
    getclientinfo,
    getusers,
    usermapping,
    getrecorddetails,
    exportdetails
})(ApproveStatutoryBulkReport);