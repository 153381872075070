import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Button, Table, Tooltip, Input, Modal, Card, Popconfirm } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import ApproveStatutoryBulkuploadview from './Approvestat_bulk_view'
import { update } from 'lodash';
import { InfoCircleOutlined, SearchOutlined, CloseOutlined, PlayCircleOutlined, FullscreenOutlined, CloseCircleOutlined } from "@ant-design/icons";
import URL from '../../Libs/URL'
import { clientunitlist, clientviewlist, unitvendorassign, saveAssignClient } from '../../Store/Action/Transactions/Assignclientunit';
import AssignClientUnitView from './Assignclientunitview'
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";
import { Toaster } from '../../Libs/Toaster';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.Assignclientunit.assignclient.list,
    assignlist: state.Assignclientunit.assignclient.assignunit
})
const AssignClientUnit = ({
    clientunitlist,
    clientviewlist,
    unitvendorassign,
    saveAssignClient,
    list,
    assignlist
}) => {
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [details, setDetails] = useState()
    console.log(details, 'details');
    const [currentpage, setcurrentpage] = useState('list');
    const [recordData, setRecordData] = useState([])
    const { TextArea } = Input;
    const [all, setAll] = useState([]);
    console.log(all, 'all555');
    const [modalVisible, setmodalVisible] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [domainexecutive, setdomainexecutive] = useState({
        selecteddomain: ''
    });
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [assignState, setAssignState] = useState(false)
    const [finalvalue, setfinalvalue] = useState();
    const [finalTemp, setFinalTemp] = useState([])
    const [finalTemp1, setFinalTemp1] = useState()
    const [overAllcheckBox, setOverAllcheckbox] = useState([])
    console.log(finalTemp, 'finalTemp')
    const [parentvalue, setparentvalue] = useState();
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }
    console.log(assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list.length > 0 && assignlist.assigned_unit_details_list[0], 'assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list.length > 0 && assignlist.assigned_unit_details_list[0]');
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo(filters);
        // setSortedInfo(sorter);
    };

    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = finalvalue
        console.log(searchingValue['Criticality'], 'lllllllllll');
        if (searchingValue['unitCode']) {
            filterTable = filterTable.filter((item) => {
                if ((item["unit_code"].toLowerCase().includes(searchingValue['unitCode'].toLowerCase())) || (item["unit_code"].toLowerCase().includes(searchingValue['unitCode'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['unitName']) {
            filterTable = filterTable.filter((item) => {
                if ((item["unit_name"].toLowerCase().includes(searchingValue['unitName'].toLowerCase())) || (item["unit_name"].toLowerCase().includes(searchingValue['unitName'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['product']) {
            filterTable = filterTable.filter((item) => {
                if ((item["product"].toLowerCase().includes(searchingValue['product'].toLowerCase())) || (item["product"].toLowerCase().includes(searchingValue['product'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['orgType']) {
            filterTable = filterTable.filter((item) => {
                if ((item["domainOrgName"].toString().toLowerCase().includes(searchingValue['orgType'].toLowerCase())) || (item["domainOrgName"].toString().toLowerCase().includes(searchingValue['orgType'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['unitLocation']) {
            filterTable = filterTable.filter((item) => {
                if ((item["geography_name"].toLowerCase().includes(searchingValue['unitLocation'].toLowerCase())) || (item["geography_name"].toLowerCase().includes(searchingValue['unitLocation'].toLowerCase()))) {
                    return true;
                }
            })
        }
        console.log(filterTable, 'filterTablefilterTable');
        setFinalTemp(filterTable)

    };
    const Submit = () => {
        console.log('hii');
        if (domainexecutive.selecteddomain === '' || domainexecutive.selecteddomain === undefined) {
            Toaster.error('Domain Manager required')
        }
        else {
            let temp = []
            for (let i in all) {
                temp.push({
                    "unit_id": all[i],
                    "domain_name": details && details.domain_name,
                    "legal_entity_id": details && details.legal_entity_id
                })
            }
            console.log(temp, 'temp8956596');
            const payload = {
                "session_token": authtoken,
                // "request": [
                //     "SaveAssignLegalEntity",
                //     {
                //         "client_id": groupName.client_id,
                //         "legal_entity_ids": checkboxValue,
                //         "user_ids": [Number(technoUserId)]
                //     }
                // ]
                "request": [
                    "SaveAsssignedUnits",
                    {
                        "user_id": Number(domainexecutive.selecteddomain),
                        "active_units": temp,
                        "client_id": details && details.client_id
                    }
                ]
            }

            const tablePayload = {
                "session_token": authtoken,
                "request": [
                    "GetUnassignedUnits",
                    {}
                ]
            }

            saveAssignClient({
                payload: payload,
                caller: paramid,
                tablePayload: tablePayload,
                setAssignState: setAssignState

            })
        }
    }
    useEffect(() => {
        if (finalTemp && finalTemp.length > 0) {
            let id = finalTemp && finalTemp.length > 0 && finalTemp.map((item) => {
                return item.unit_id
            })
            setOverAllcheckbox(id)
        }
    }, [finalTemp])
    const columns = [
        {
            title: "#",
            width: '3%',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "Domain",
            dataIndex: "domain_name",
            key: "domain_name",
            ...getColumnSearchProps('domain_name', 'Domain Name'),
            filteredValue: filteredInfo.domain_name || null,
            width: '18%',
        },
        {
            title: "Group",
            dataIndex: "group_name",
            key: "group_name",
            ...getColumnSearchProps('group_name', 'Group Name'),
            filteredValue: filteredInfo.group_name || null,
            width: '12%',
            /* bug number CI-683
            render: (text, record) => {
                 return <div>
                     {record.business_group_name != null ?
                         <span><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight"
                             title={record.business_group_name}>
                             <InfoCircleOutlined /></Tooltip> {text}</span> : <span>{text}</span>} </div>
             }*/
        },
        {
            title: "Legal Entity",
            dataIndex: "legal_entity_name",
            key: "legal_entity_name",
            ...getColumnSearchProps('legal_entity_name', 'LegalEntityName'),
            filteredValue: filteredInfo.legal_entity_name || null,
            ellipsis: true,
            width: '22%',
        },
        {
            title: "Business Group",
            dataIndex: "business_group_name",
            key: "business_group_name",
            ...getColumnSearchProps('business_group_name', 'Business Group'),
            filteredValue: filteredInfo.business_group_name || null,
            width: '10%',
            align: 'left',
            render: (data) => {
                if (data == null) {
                    return '-'
                } else {
                    return data
                }
            }
        },
        {
            title: "Unit(s)",
            dataIndex: "unassigned_units",
            key: "unassigned_units",
            width: '5%',
            align: 'left'
        },
        {
            title: "Assign",
            dataIndex: "unassigned_units",
            key: "unassigned_units",
            ellipsis: true,
            width: '5%',
            render: (text, record) => {
                console.log(record, 'record');

                let textsplit = text.split('/')
                let finalvalue = textsplit[0]
                return <div>
                    {finalvalue == 0 ? '' :
                        <Tooltip title={record.is_closed === true ? 'Legalentity is closed' : ''}>
                            <span style={{ margin: '0 auto', cursor: record.is_closed === true ? 'not-allowed' : 'pointer' }} className="btn btn-sm btn-light-success
  text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                    setDetails(record)
                                    setOverAllcheckbox([])
                                    setAll([])
                                    setdomainexecutive({
                                        ...domainexecutive,
                                        selecteddomain: ''
                                    })
                                    if (record.is_closed === false) {
                                        showassign(record)

                                    }
                                }}>
                                <i className='fas fa-user-plus'></i></span></Tooltip>}
                </div>
            }
        },
        {
            title: "Executive",
            align: 'center',
            width: '5%',
            render: (text, record) => {
                let temp = record.unassigned_units;
                let textsplit = temp.split('/')
                let finalvalue = textsplit[0];
                if (finalvalue == 0) {
                    return <span style={{ margin: '0 auto' }} className="btn btn-sm btn-light-primary
                  text-primary btn-circle d-flex align-items-center justify-content-center" onClick={() => { showview(record); }}>
                        <Tooltip title='View Domain Executive'>
                            <i className='fas fa-eye'></i></Tooltip>
                    </span>;
                }
            },
        },
    ]

    const [searchingValue, setSearchingValue] = useState([])
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const [searchedValue, setSearchedValue] = useState({
        unitCode: '',
        unitName: '',
        product: '',
        orgType: '',
        unitLocation: '',
    })
    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetUnassignedUnits",
                    {}
                ]
            }
            clientunitlist({
                payload: pay,
                caller: paramid
            })
        }
    }, [isAuth])

    const showview = (data) => {
        console.log(data)
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedUnits",
                {
                    "domain_id": data.domain_id,
                    "client_id": data.client_id,
                    "legal_entity_id": data.legal_entity_id
                }
            ]
        }
        clientviewlist({
            payload: pay,
            caller: paramid
        })
        setRecordData(data)
        setcurrentpage('view');
    }
    const modalcancel = () => {
        setmodalVisible(false);
        setdomainexecutive({
            ...domainexecutive,
            selecteddomain: ''
        })
    }
    const [group, setgroups] = useState();
    const showassign = (data) => {
        setmodalVisible(true);
        setgroups(data.group_name);
        setAssignState(true)
        let pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignUnitFormData",
                {
                    "domain_id": data.domain_id,
                    "client_id": data.client_id,
                    "legal_entity_id": data.legal_entity_id
                }
            ]
        }

        unitvendorassign({
            payload: pay,
            caller: paramid
        })

    }
    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (assignlist && assignlist.assigned_unit_details_list) {
            let detailslist
            let arr = [];
            let temparr = []
            for (let i = 0; i < assignlist.assigned_unit_details_list.length; i++) {
                if (assignlist.assigned_unit_details_list[i]['category_name'] == null) {
                    assignlist.assigned_unit_details_list[i]['category_name'] = 'Unassigned';
                }
                arr.push(assignlist.assigned_unit_details_list[i].category_name);
            }
            const temp = {};
            for (const array of arr) {
                temp[array] = true;
            }

            const uniquevalue = [];
            for (const tempvalue in temp) {
                let value = _.find(assignlist.assigned_unit_details_list, { category_name: tempvalue })
                uniquevalue.push({
                    'category_name': value.category_name,
                    'legal_entity_name': value.legal_entity_name,
                    'division_name': value.division_name
                });
            }
            setparentvalue(uniquevalue);
            const finalArrayParent = [...new Set(arr)];
            let entitytemparr = [];
            let finalTempArr = [];
            for (let k = 0; k < finalArrayParent.length; k++) {
                let finalValue = _.filter(assignlist.assigned_unit_details_list, { category_name: finalArrayParent[k] })
                finalTempArr.push(finalValue)

            }

            console.log(finalTempArr, 'temparrtemparr')
            setfinalvalue([].concat.apply([], finalTempArr))
            setFinalTemp1([].concat.apply([], finalTempArr))
        }
    }, [assignlist])

    useEffect(() => {
        if (finalTemp1 && finalTemp1.length > 0) {
            let temp = []
            for (let i in finalTemp1) {
                temp.push({
                    "unit_id": finalTemp1[i].unit_id,
                    "legal_entity_name": finalTemp1[i].legal_entity_name,
                    "division_name": finalTemp1[i].division_name,
                    "category_name": finalTemp1[i].category_name,
                    "unit_code": finalTemp1[i].unit_code,
                    "unit_name": finalTemp1[i].unit_name,
                    "address": finalTemp1[i].address,
                    "domain_names": [
                        "Corporate Finance"
                    ],
                    "org_names_list": [
                        [
                            "Commercial Establishments"
                        ]
                    ],
                    "geography_name": finalTemp1[i].geography_name,
                    "product": finalTemp1[i].product,
                    "domain_name": finalTemp1[i].domain_names && finalTemp1[i].domain_names.length > 0 ? finalTemp1[i].domain_names[0] : '',
                    "org_name": finalTemp1[i].org_names_list && finalTemp1[i].org_names_list.length > 0 ? finalTemp1[i].org_names_list[0][0] : '',
                    "domainOrgName": `${finalTemp1[i].domain_names && finalTemp1[i].domain_names.length > 0 ? finalTemp1[i].domain_names[0] : ''}-${finalTemp1[i].org_names_list && finalTemp1[i].org_names_list.length > 0 ? finalTemp1[i].org_names_list[0][0] : ''}`
                })
            }
            console.log(temp, 'temp');
            setfinalvalue(temp)
            setFinalTemp(temp)
        }

    }, [finalTemp1])

    console.log(assignlist, 'assignlist');
    console.log(parentvalue, 'parrr');
    console.log(finalvalue, 'finalval');
    console.log(list, 'listtt');

    return (
        <>
            {currentpage == 'list' ? (
                <div className="page-wrapper" id="page-wrapper">
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles py-1 page-title-sticky">
                        <div className="row py-0">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transactions</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Assign Client Unit/Vendor </span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <button onClick={(e) => { fullscreenMode(); }}
                                    className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                    <Tooltip
                                        placement="left"
                                        style={{ marginRight: "10px", }}
                                        title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                    >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0 service-provider-table">
                        {assignState === false ?
                            <>
                                <Table
                                    className='review_settings reassignclass2'
                                    columns={columns}
                                    dataSource={list && list.unassigned_units_list}
                                    bordered
                                    pagination={false}
                                    onChange={handleChange}
                                />
                            </> : <Card
                                headStyle={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3', borderRadius: '10px' }}
                                size="small"
                                title="Assign Legal Entity"
                                extra={<CloseOutlined onClick={() => {
                                    setAssignState(false)
                                    // setCheckBoxValue([])
                                    // setTechnoUserId()
                                }} />}
                                style={{
                                    marginLeft: '10%',
                                    marginRight: '10%'
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='col-md-12 mt-2'>
                                            <div className='row'>
                                                <div className='col-md-6 mt-2'>
                                                    <label><b>Group:&nbsp;&nbsp;</b></label>
                                                    {group && group}
                                                </div>
                                                <div className='col-md-6'>
                                                    <label><b>Domain:&nbsp;</b></label>
                                                    {assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list[0] && assignlist.assigned_unit_details_list[0].domain_names &&
                                                        assignlist.assigned_unit_details_list[0].domain_names[0]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 mt-2'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><b>Business Group:&nbsp;&nbsp;&nbsp;</b></label>
                                                    {assignlist && assignlist.business_groups && assignlist.business_groups.length > 0 ? assignlist.business_groups[0].business_group_name : '-'}
                                                </div>
                                                <div className='col-md-6'>
                                                    <label><b>Legal Entity:&nbsp;&nbsp;&nbsp;</b></label>
                                                    {assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list.length > 0 && assignlist.assigned_unit_details_list[0].legal_entity_name}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row" >
                                            <div className="col-12 text-center">
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table style={{ tableLayout: 'auto' }} className={"table-fixed"}>
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        {/* <th className='ant-table-cell'>Unit/Vendor Code</th>
                                                <th className='ant-table-cell'><center>Unit/Vendor Name</center></th>
                                                <th className='ant-table-cell'><center>Product</center></th>
                                                <th className='ant-table-cell'><center>Organization Type</center></th>
                                                <th className='ant-table-cell'><center>Unit/Vendor Location</center></th> */}
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "40px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        marginLeft: "27%",
                                                                                        display: "inline",
                                                                                        // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    <input type="checkbox"

                                                                                        checked={(overAllcheckBox && overAllcheckBox.length > 0 && overAllcheckBox.length) == (all.length)}
                                                                                        name="allchecked" id="all" className='checkclass'
                                                                                        // onClick={(e) => {
                                                                                        //     let checked = e.target.checked;
                                                                                        //     selectAllCheckBox(
                                                                                        //         checked
                                                                                        //     );
                                                                                        // }}
                                                                                        onClick={(e) => {
                                                                                            if (e.target.checked == true) {
                                                                                                if (finalTemp && finalTemp.length > 0) {
                                                                                                    let id = finalTemp && finalTemp.length > 0 && finalTemp.map((item) => {
                                                                                                        return item.unit_id
                                                                                                    })
                                                                                                    setAll(id)
                                                                                                }
                                                                                            }
                                                                                            else {

                                                                                                setAll([])
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </p>{" "}
                                                                            </div>

                                                                        </th>

                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['business_group'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Unit/Vendor Code
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef1);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef1}
                                                                                                placeholder="Unit/Vendor Code"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        unitCode: e.target.value
                                                                                                    });
                                                                                                    searchingValue['unitCode'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.unitCode || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "unitCode",
                                                                                                        "unitCode"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef1.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    unitCode: ''
                                                                                                });
                                                                                                searchingValue['unitCode'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "unitCode",
                                                                                                    "unitCode"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined className='assigClient'
                                                                                        style={{ float: 'right', marginTop: '4px', color: 'black' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['unitName'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Unit/Vendor Name
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef2);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef2}
                                                                                                placeholder="Unit/Vendor Name"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        unitName: e.target.value
                                                                                                    });
                                                                                                    searchingValue['unitName'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.unitName || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "unitName",
                                                                                                        "unitName"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef2.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    unitName: ''
                                                                                                });
                                                                                                searchingValue['unitName'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "unitName",
                                                                                                    "unitName"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['product'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Product
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef3);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef3}
                                                                                                placeholder="Product"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        product: e.target.value
                                                                                                    });
                                                                                                    searchingValue['product'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.product || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "product",
                                                                                                        "product"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef3.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    product: ''
                                                                                                });
                                                                                                searchingValue['product'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "product",
                                                                                                    "product"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['orgType'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Organization Type
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef4);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef4}
                                                                                                placeholder="Organization Type"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        orgType: e.target.value
                                                                                                    });
                                                                                                    searchingValue['orgType'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.orgType || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "orgType",
                                                                                                        "orgType"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef4.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    orgType: ''
                                                                                                });
                                                                                                searchingValue['orgType'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "orgType",
                                                                                                    "orgType"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['unitLocation'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Unit/Vendor Location
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef5);
                                                                                    }}
                                                                                    placement="bottomLeft"
                                                                                    title={
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef5}
                                                                                                placeholder="Unit/Vendor Location"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        unitLocation: e.target.value
                                                                                                    });
                                                                                                    searchingValue['unitLocation'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.unitLocation || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "unitLocation",
                                                                                                        "unitLocation"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef5.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    unitLocation: ''
                                                                                                });
                                                                                                searchingValue['unitLocation'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "unitLocation",
                                                                                                    "unitLocation"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    {
                                                                        parentvalue && parentvalue.length > 0 && parentvalue.map((item, i) => {
                                                                            let filterdata = _.filter(finalTemp, { category_name: item.category_name })
                                                                            let filterId = filterdata && filterdata.map((item) => {
                                                                                return item.unit_id
                                                                            })
                                                                            return <>
                                                                                <tr className="bg-lightdark">
                                                                                    <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                        <div className='bg-white shadow-sm border-radius-reports'>
                                                                                            <div className="col-md-12">
                                                                                                <div className='row m-2'>
                                                                                                    <div className="col-md-2">
                                                                                                        <input type="checkbox"

                                                                                                            // style={{ width: '10px' }}
                                                                                                            // checked={((assignedDataArray && assignedDataArray.length > 0 && assignedDataArray.length) === (checkboxValue && checkboxValue.length > 0 && checkboxValue.length)) ? true : false}
                                                                                                            name="allchecked" id="all" className='checkclass' checked={(filterId.every(elem => all.includes(elem)))}
                                                                                                            onClick={(e) => {

                                                                                                                console.log(filterdata, 'filterdata');
                                                                                                                let temp = all;
                                                                                                                let tempIds = [];

                                                                                                                if (filterId.every(elem => temp.includes(elem))) {
                                                                                                                    console.log('aishuuuu');
                                                                                                                    temp = temp.filter(function (el) {
                                                                                                                        return filterId.indexOf(el) < 0;
                                                                                                                    });
                                                                                                                    console.log(temp, 'temp96885588');
                                                                                                                    setAll([...temp])

                                                                                                                } else {
                                                                                                                    for (let i in filterId) {
                                                                                                                        tempIds.push(filterId[i]);
                                                                                                                    }
                                                                                                                    setAll([...all, ...tempIds]);
                                                                                                                }

                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='col-md-4'>
                                                                                                        <label><b>Division :</b> {item.division_name == null || item.division_name === undefined ? '-' : item.division_name}</label>
                                                                                                    </div>
                                                                                                    <div className='col-md-4'>
                                                                                                        <label><b>Category :</b> {item.category_name == null || item.category_name === 'Unassigned' ? '-' : item.category_name}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                {
                                                                                    finalTemp && finalTemp.map((data) => {
                                                                                        if (data.category_name == item.category_name) {
                                                                                            console.log(data, 'data');
                                                                                            console.log(data.category_name, 'data.category_name');
                                                                                            console.log(item.category_name, 'item.category_name');
                                                                                            console.log(data.category_name != item.category_name, 'notEqual');
                                                                                            console.log(data.category_name == item.category_name, 'data.category_name == item.category_name');
                                                                                            return <>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell custom-width'><input type="checkbox"
                                                                                                        // style={{ width: '10px' }}
                                                                                                        style={{ marginLeft: '27%' }}
                                                                                                        checked={all.includes(data.unit_id)}
                                                                                                        onClick={() => {
                                                                                                            let temp = all;
                                                                                                            let tempIds = [];
                                                                                                            if (temp.includes(data.unit_id) == true) {
                                                                                                                for (let i in temp) {
                                                                                                                    if (temp[i] == Number(data.unit_id)) {
                                                                                                                        temp.splice(i, 1);
                                                                                                                    }
                                                                                                                }
                                                                                                                setAll([...temp]);
                                                                                                            } else {
                                                                                                                tempIds.push(data.unit_id);
                                                                                                                setAll([...all, ...tempIds]);
                                                                                                            }
                                                                                                        }}
                                                                                                        // checked={((assignedDataArray && assignedDataArray.length > 0 && assignedDataArray.length) === (checkboxValue && checkboxValue.length > 0 && checkboxValue.length)) ? true : false}
                                                                                                        name="allchecked" id="all" className='checkclass'
                                                                                                    // onClick={(e) => {
                                                                                                    //     let checked = e.target.checked;
                                                                                                    //     selectAllCheckBox(
                                                                                                    //         checked
                                                                                                    //     );
                                                                                                    // }}
                                                                                                    /></td>
                                                                                                    <td className='ant-table-cell custom-width'>{data.unit_code}</td>
                                                                                                    <td className='ant-table-cell fs'><Tooltip
                                                                                                        className="toolTipClass3"
                                                                                                        placement="top"
                                                                                                        title={data.address}>
                                                                                                        <InfoCircleOutlined />&nbsp;
                                                                                                        {data.unit_name}</Tooltip></td>
                                                                                                    <td className='ant-table-cell fs'>{data.product}</td>
                                                                                                    <td className='ant-table-cell fs'><b>{data && data.domain_name}</b><br />{data && data.org_name}</td>
                                                                                                    <td className='ant-table-cell fs'>{data.geography_name}</td>
                                                                                                </tr>
                                                                                            </>
                                                                                        }
                                                                                        else {
                                                                                            return null
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </>
                                                                        })}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span style={{ marginRight: '83%' }}>Selected Unit/Vendor : {all && all.length} </span>
                                                <div className='mt-3'>
                                                    <label><b>Assign To Domain Executive :</b><span style={{ color: 'red' }}> *</span>&nbsp;&nbsp;
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            name='domain'
                                                            placeholder="Select Domain"
                                                            showSearch
                                                            value={domainexecutive.selecteddomain || undefined}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '220px' }}
                                                            onChange={(data, value) => {
                                                                setdomainexecutive({
                                                                    ...domainexecutive,
                                                                    selecteddomain: value.value,
                                                                })
                                                            }}>
                                                            {assignlist && assignlist.domain_manager_users && assignlist.domain_manager_users.map((item) => {
                                                                return <Option key={item.user_id}>{item.employee_name}</Option>
                                                            })}
                                                        </Select></label>
                                                </div>
                                                <div className='text-center mt-4'>
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton custom-color-modal-button'
                                                        icon={<PlayCircleOutlined />}
                                                        onClick={Submit}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Card>}
                    </div>
                </div>) : <AssignClientUnitView recordData={recordData} fullscreen={fullscreen} setfullscreen={setfullscreen} />}

            {/* <Modal title="Assign Client Unit/Vendor" visible={modalVisible} footer={null} maskClosable={false}
                className={"Assignunit add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                onCancel={modalcancel}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className='col-md-12 mt-2'>
                            <div className='row'>
                                <div className='col-md-6 mt-2'>
                                    <label><b>Group:&nbsp;&nbsp;</b></label>
                                    {group && group}
                                </div>
                                <div className='col-md-6'>
                                    <label><b>Domain:&nbsp;</b></label>
                                    {assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list[0] && assignlist.assigned_unit_details_list[0].domain_names &&
                                        assignlist.assigned_unit_details_list[0].domain_names[0]}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label><b>Business Group:&nbsp;&nbsp;&nbsp;</b></label>
                                    {assignlist && assignlist.business_groups && assignlist.business_groups.length > 0 ? assignlist.business_groups[0].business_group_name : '-'}
                                </div>
                                <div className='col-md-6'>
                                    <label><b>Legal Entity:&nbsp;&nbsp;&nbsp;</b></label>
                                    {assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list.length > 0 && assignlist.assigned_unit_details_list[0].legal_entity_name}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row" >
                            <div className="col-12 text-center">
                                <table style={{ tableLayout: 'auto' }}>
                                    <thead className='ant-table-thead'>
                                        <tr>
                                            <th className='ant-table-cell'>Unit/Vendor Code</th>
                                            <th className='ant-table-cell'><center>Unit/Vendor Name</center></th>
                                            <th className='ant-table-cell'><center>Product</center></th>
                                            <th className='ant-table-cell'><center>Organization Type</center></th>
                                            <th className='ant-table-cell'><center>Unit/Vendor Location</center></th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        {assignlist && assignlist.assigned_unit_details_list ?
                                            <>
                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                    <td colSpan={2}><b>Division :-&nbsp;</b>{assignlist.assigned_unit_details_list[0].division_name == null ? '-' : assignlist.assigned_unit_details_list[0].division_name}</td>
                                                    <td>  <b>Category :-&nbsp; {assignlist.assigned_unit_details_list[0].category_name == null ? '-' : assignlist.assigned_unit_details_list[0].category_name}</b></td>
                                                </tr></> : ""}
                                        {assignlist && assignlist.assigned_unit_details_list && assignlist.assigned_unit_details_list.map((item) => {
                                            return <tr className='ant-table-row ant-table-row-level-0'>
                                                <>
                                                    <td className='ant-table-cell custom-width'>{item.unit_code}</td>
                                                    <td className='ant-table-cell fs'><Tooltip
                                                        className="toolTipClass3"
                                                        placement="topRight"
                                                        title={item.address}>
                                                        <InfoCircleOutlined style={{ marginto: '10px' }} />
                                                        {item.unit_name}</Tooltip></td>
                                                    <td className='ant-table-cell fs'>{item.product}</td>
                                                    <td className='ant-table-cell fs'><b>{item.domain_names[0]}</b><br />{item.org_names_list[0]}</td>
                                                    <td className='ant-table-cell fs'>{item.geography_name}</td>
                                                </>

                                            </tr>
                                        })
                                        }

                                    </tbody>
                                </table>
                                <div className='mt-3'>
                                    <label><b>Assign To Domain Executive:</b><span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;
                                        <Select
                                            allowClear={false}
                                            size="default"
                                            name='domain'
                                            placeholder="Select Domain"
                                            showSearch
                                            value={domainexecutive.selecteddomain || undefined}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '220px' }}
                                            onChange={(data, value) => {
                                                setdomainexecutive({
                                                    ...domainexecutive,
                                                    selecteddomain: value.value,
                                                })
                                            }}>
                                            {assignlist && assignlist.domain_manager_users && assignlist.domain_manager_users.map((item) => {
                                                return <Option key={item.user_id}>{item.employee_name}</Option>
                                            })}
                                        </Select></label>
                                </div>
                                <div className='text-center mt-4'>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className='addbutton custom-color-modal-button'
                                        icon={<PlayCircleOutlined />}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal > */}
        </>
    )
}
export default connect(mapStateToProps, {
    clientunitlist,
    clientviewlist,
    unitvendorassign,
    saveAssignClient
})(AssignClientUnit);