import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Button, Table, Tooltip, Input, Modal, Card, Radio, Divider, Row, Col, Breadcrumb} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { InfoCircleOutlined, SearchOutlined, DownloadOutlined, UploadOutlined, UpOutlined,FullscreenOutlined } from "@ant-design/icons";
import { Toaster } from '../../Libs/Toaster'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import URL from '../../Libs/URL'
import { filterdetails, downloadAssignStatutory, downloadLink, uploadCSV, uploadFileStatus } from '../../Store/Action/Transactions/Assign_sta_bulkupload';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
const _ = require("lodash");

const mapStateToProps = (state) => ({
    info: state.Assign_sta_bulkupload.assign_sta_bulkupload.filterdetails,
    csvName: state.Assign_sta_bulkupload.assign_sta_bulkupload.downloadAssignStatutory.csv_name,
    downloadUrl: state.Assign_sta_bulkupload.assign_sta_bulkupload.downloadUrl.link,
    uploadCSV_name: state.Assign_sta_bulkupload.assign_sta_bulkupload.uploadCSV_name.csv_name,
    fileStatus: state.Assign_sta_bulkupload.assign_sta_bulkupload.uploadFileStatus
})

const AssignStatutoryBulkupload = ({
    filterdetails,
    info,
    downloadAssignStatutory,
    csvName,
    downloadLink,
    downloadUrl,
    uploadCSV,
    uploadCSV_name,
    uploadFileStatus,
    fileStatus
}) => {
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [currentpage, setcurrentpage] = useState('list');
    const { TextArea } = Input;
    const searchInput = useRef(null); const [searchText, setSearchText] = useState('');
    const formValidator = useRef(new SimpleReactValidator());
    const uploadValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [recorddata, setrecorddata] = useState();
    const [filedetails, setfiledetails] = useState({
        filename: ''
    })
    const [uploaddocument, setuploaddocument] = useState({
        country: '',
        domain: '',
        domaindata: '',
        updateloadedFileData: '',
    })
    const [fullscreen, setfullscreen] = useState(false);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [csvStatus, setCSVstatus] = useState([]);
    const [fileInfo, setFileInfo] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState({});
    const [uploadFailedUrl, setUploadFailedUrl] = useState(false);
    const [inValidBtn, setInvalidBtn] = useState(false);

    const [downloaddata, setdownloaddata] = useState({
        clientName: '',
        clientgroup: '',
        legalid: '',
        legaldata: '',
        legalEntityName: '',
        product: '',
        productdata: '',
        domain: '',
        domainIds: '',
        domainNames: '',
        domaindata: '',
        unit: '',
        unitIds: '',
        unitNames: '',
        unitdata: '',
        assignedUnit: 1
    })

    useEffect(() => {
        formValidator.current.showMessages();
        uploadValidator.current.showMessages();
    }, [])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientInfo",
                    {}
                ]
            }

            filterdetails({
                payload: pay,
                paramid: paramid
            })
        }
    }, [isAuth])

    const selectedclientgroup = (e) => {
        if (e == undefined) {
            setdownloaddata({
                ...downloaddata,
                clientgroup: ''
            })
        } else {
            const legalid = _.filter(info.bu_legalentites, { cl_id: parseInt(e.value) });
            setdownloaddata({
                ...downloaddata,
                clientgroup: e.value,
                clientName: e.children,
                legaldata: legalid
            })
        }
    }

    const selectedlegalentity = (e) => {
        if (e == undefined) {
            setdownloaddata({
                ...downloaddata,
                legalid: '',
                legalEntityName: ''
            })
        } else {
            const product = _.filter(info.bu_legalentites, { cl_id: parseInt(downloaddata.clientgroup), le_id: parseInt(e.value) })
            const unitid = _.filter(info.bu_units, { cl_id: parseInt(downloaddata.clientgroup), le_id: parseInt(e.value) });


            let ab_id = product[0].bu_domains;
            const types = ab_id.map(item => item.product);
            const productTypes = types.filter((item,index) => types.indexOf(item) === index);
            // let arr = []
            // for (let i = 0; i < ab_id.length > 0; ab_id++) {
            //     arr.push(ab_id[i].product)
            // }
            // const prodata = [...new Set(arr)]

            setdownloaddata({
                ...downloaddata,
                legalid: e.value,
                legalEntityName: e.children,
                // productdata: prodata,
                productdata: productTypes,
                domaindata: product[0].bu_domains,
                unitdata: unitid
            })
        }
    }

    useEffect(() => {
        if (downloadUrl) {
            window.open(process.env.REACT_APP_API_URL + downloadUrl);
        }
    }, [downloadUrl])

    const convert_to_base64 = (file, callback) => {
        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }

    const uploadedfile = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                return;
            }
            let file_size = file.size;
            if (file_size > file_max_size) {
                Toaster.error('Maximum File Sixe is 25MB');
                return;

            }

            if (file) {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);

                    results.push(result);
                    setfiledetails({
                        ...filedetails,
                        filename: results
                    })

                });

            }
        }
    }

    const uploaddocu = (e) => {
        e.preventDefault();
        setUploadFailedUrl(false);
        setInvalidBtn(false);
        console.log(filedetails, 'filedetails');
        if (uploadValidator.current.allValid()) {
            setAddFormSubmit2(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "UploadAssignStatutoryCSV",
                    {
                        "csv_name": filedetails.filename[0].file_name,
                        "csv_data": filedetails.filename[0].file_content,
                        "csv_size": filedetails.filename[0].file_size
                    }
                ]
            }

            console.log('PAy', pay);

            uploadCSV({
                payload: pay,
                paramid: paramid
            })
        } else {
           setAddFormSubmit2(true);
        }
    }

    useEffect(() => {
        if (uploadCSV_name) {
            const paypass = {
                "session_token": authtoken,
                "request": [
                    "GetAssignStatutoryStatus",
                    {
                        // "csv_name": filedetails.filename[0].file_name
                        "csv_name": uploadCSV_name
                    }
                ]
            }
    
            uploadFileStatus({
                payload: paypass,
                paramid: paramid
            })            
            console.log('PAypass', paypass);
        }

    },[uploadCSV_name])

    useEffect(() => {
        if (fileStatus.length) {
            const data = fileStatus[1];
            let name = data.invalid_file.split('.');
            if (fileStatus[0] === 'UploadAssignStatutoryCSVFailed') {
                setInvalidUrl({
                    invalidUrl : URL.CommonURL + '/' + 'invalid_file/',
                    fileName : name[0]
                })
                setCSVstatus(data);
                setFileInfo(true);
                // setUploadFailedUrl(true);
                setInvalidBtn(true);
                Toaster.error('CSV file upload failed');
            }
            if (fileStatus[0] === 'UploadAssignStatutoryCSVSuccess') {
                setCSVstatus(data);
                setFileInfo(true);
                Toaster.success('CSV file upload success');
            }
            if (fileStatus[0] === 'RejectionMaxCountReached') {
                Toaster.error('CSV file upload max count reached');
            }
            
            console.log('file', fileStatus);
        } else {
            setFileInfo(false);
        }

    },[fileStatus])

    useEffect(() => {
        if (csvName) {
            const passpay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignStatutoryDownloadStatus",
                    {
                        "csv_name": csvName
                    }
                ]
            }

            downloadLink({
                payload: passpay,
                paramid: paramid
            })
        }
    },[csvName])

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <div id="page-wrapper" className="page-wrapper">
            <ScrollToTop smooth color="red" component={
                <Tooltip title="Back to top">
                    <UpOutlined />
                </Tooltip>
            }
            />
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Assign Statutory - Bulk Upload </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title="Full Screen Mode"
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row mx-3 justify-content-md-center" >
                    <Card className='col-md-6' style={{ marginTop: "10px", marginRight: "15px", boxShadow: "0px 0px 0px 1px grey" }}>
                        <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Download Unassigned Compliance</h4>
                        <div className='col-md-12 p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Client Group:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        name='clientgroup'
                                        placeholder="Select Client Group"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.clientgroup || undefined}
                                        onChange={(data, value) => {
                                            selectedclientgroup(value)
                                        }}>
                                        {info && info.bu_clients && info.bu_clients.length > 0 && info.bu_clients.map((item, i) => {
                                            return (
                                                <Option key={item.cl_id}>
                                                    {item.cl_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {formValidator.current.message(
                                        'clientgroup',
                                        downloaddata.clientgroup,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Client Group Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Legal Entity:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        name='legalentity'
                                        placeholder="Select Legal Entity"
                                        showSearch
                                        disabled={downloaddata.clientgroup == ''}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.legalid || undefined}
                                        onChange={(data, value) => { selectedlegalentity(value) }}>
                                        {downloaddata.legaldata && downloaddata.legaldata.length > 0 && downloaddata.legaldata.map((item, i) => {
                                            // console.log("DownloadData", downloaddata);
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {formValidator.current.message(
                                        'legalentity',
                                        downloaddata.legalid,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Name Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Product:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        name='Product:'
                                        placeholder="Select Product:"
                                        showSearch
                                        optionFilterProp="children"
                                        disabled={downloaddata.legalid == ''}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.product || undefined}
                                        onChange={(data, value) => {
                                            setdownloaddata({
                                                ...downloaddata,
                                                product: data
                                            })
                                        }}>
                                        {downloaddata.productdata && downloaddata.productdata.length > 0 && downloaddata.productdata.map((item, i) => <Option key={item}>{item}</Option>)}
                                    </Select>
                                    {formValidator.current.message(
                                        'clientgroup',
                                        downloaddata.product,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Product Name Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Domain:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        name='Domain'
                                        mode='multiple'
                                        placeholder="Select Domain"
                                        showSearch
                                        optionFilterProp="children"
                                        disabled={downloaddata.product == ''}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.domainIds || undefined}
                                        onChange={(data, value) => {
                                            const dname = [];
                                            const dIds = [];
                                            if (!data.length) {
                                                Setselectcheckbox('block');
                                                Setunselectcheckbox('none');            
                                            }

                                            if (data.includes('all')) {
                                                let tempId = []
                                                let tempNames = []
                                                downloaddata.domaindata.map(item => {
                                                    if (downloaddata.product === item.product) {
                                                        tempId.push(parseInt(item.d_id));
                                                        tempNames.push(item.d_name)
                                                    }
                                                })

                                                setdownloaddata({
                                                    ...downloaddata,
                                                    domain: value.value,
                                                    domainIds: tempId,
                                                    domainNames: tempNames
                                                })

                                                Setselectcheckbox('none');
                                                Setunselectcheckbox('block');
                                            } 

                                            if (data.includes("unselect")) {
                                                setdownloaddata({
                                                    ...downloaddata,
                                                    domain: value.value,
                                                    domainIds: [],
                                                    domainNames: []
                                                })
                                                
                                                Setselectcheckbox('block');
                                                Setunselectcheckbox('none');            
                                            }
                                            
                                            value.map((name) => {
                                                if (name.value !== 'all' && name.value !== 'unselect') {
                                                    dIds.push(parseInt(name.value));
                                                    dname.push(name.children);
                                                }
                                            })

                                            if (!(data.includes('all')) && !(data.includes("unselect"))) {
                                                setdownloaddata({
                                                    ...downloaddata,
                                                    domain: value.value,
                                                    domainIds: dIds,
                                                    domainNames: dname
                                                })
                                            }
                                        }}>
                                            <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                            <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                        {downloaddata.domaindata && downloaddata.domaindata.length > 0 && downloaddata.domaindata.map((item, i) => {
                                            if (downloaddata.product === item.product) {
                                                return (
                                                    <Option key={item.d_id} value={item.d_id}>
                                                        {item.d_name}
                                                    </Option>
                                                );
                                            }                                            
                                        })}
                                    </Select>
                                    {formValidator.current.message(
                                        'Domain',
                                        downloaddata.domain,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Compfie Domain Name Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Assign Type:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Radio.Group onChange={(e) => {
                                        let value = e.target.value;
                                        if (value == 1) {
                                            const units = info.bu_units;
                                            let unitList = units.filter(item => {
                                                let status = item['d_ids'].some(val => downloaddata.domainIds.includes(val));
                                                let productStatus = item.product.includes(downloaddata.product);
                                                return status && productStatus && item.cl_id == parseInt(downloaddata.clientgroup) && item.le_id == parseInt(downloaddata.legalid)
                                            })
                                            setdownloaddata({
                                                ...downloaddata,
                                                assignedUnit: value,
                                                unitdata: unitList
                                            })                                           
                                        }
                                        if (value == 2) {
                                            const units = info.units_assigned_data;
                                            let unitList = units.filter(item => {
                                                let status = item['d_ids'].some(val => downloaddata.domainIds.includes(val));
                                                let productStatus = item.product.includes(downloaddata.product);
                                                return status && productStatus && item.cl_id == parseInt(downloaddata.clientgroup) && item.le_id == parseInt(downloaddata.legalid)
                                            })
                                            setdownloaddata({
                                                ...downloaddata,
                                                assignedUnit: value,
                                                unitdata: unitList
                                            })                                           
                                        }
                                    }} value={downloaddata.assignedUnit}>
                                        <Radio value={1}>Yet to Assign</Radio>
                                        <Radio value={2}>Already Assigned</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Unit:</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        name='Unit'
                                        placeholder="Select Unit"
                                        showSearch
                                        optionFilterProp="children"
                                        mode='multiple'
                                        disabled={downloaddata.domain == ''}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.unit || undefined}
                                        onChange={(data, value) => {
                                            const uName = [];
                                            const uIds = [];
                                            value.map((name) => {
                                                let temp = name.children;
                                                temp = temp.split(" ");
                                                uIds.push(parseInt(name.value))
                                                // uName.push(name.children)
                                                uName.push(temp[0])
                                            })

                                            setdownloaddata({
                                                ...downloaddata,
                                                unit: value.value,
                                                unitIds: uIds,
                                                unitNames: uName
                                            })
                                        }}>
                                        {downloaddata.unitdata && downloaddata.unitdata.length > 0 && downloaddata.unitdata.map((item, i) => {
                                            return (
                                                <Option key={item.u_id}>
                                                    {item.u_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {formValidator.current.message(
                                        'Unit',
                                        downloaddata.unit,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Unit Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button type="primary" shape="round" className='addbutton'
                                icon={<DownloadOutlined />} size='default'
                                onClick={() => {
                                    const pay = {
                                        "session_token": authtoken,
                                        "request": [
                                            "DownloadAssignStatutory",
                                            {
                                                "cl_id": parseInt(downloaddata.clientgroup),
                                                "le_id": parseInt(downloaddata.legalid),
                                                "d_ids": downloaddata.domainIds,
                                                "u_ids": downloaddata.unitIds,
                                                "cl_name": downloaddata.clientName,
                                                "le_name": downloaddata.legalEntityName,
                                                "d_names": downloaddata.domainNames,
                                                "u_names": downloaddata.unitNames,
                                                "a_type": downloaddata.assignedUnit == 1 ? "yet_to_assign" : "already_assign"
                                            }
                                        ]
                                    }

                                    console.log('Pay', pay);

                                    downloadAssignStatutory({
                                        payload: pay,
                                        paramid: paramid
                                    })                                    
                                }}>
                                Download
                            </Button>
                        </div>
                    </Card>
                    <Card className='col-md-5' style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                        <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Upload Unassigned Compliance</h4>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4' style={{ paddingRight: '0px', paddingLeft: '25px' }}>
                                    <label style={{ float: "left" }}><b>Select Document :</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-7'>
                                    <Input accept=".csv" className='form-control' type='file' onChange={(e) => {
                                        uploadedfile(e)
                                        setuploaddocument({
                                            ...uploaddocument,
                                            updateloadedFileData: e.target.value
                                        })
                                    }} />
                                    {uploadValidator.current.message(
                                        'File',
                                        uploaddocument.updateloadedFileData,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Select Document'
                                            }
                                        })}
                                    <h6 style={{ marginTop: "5px", marginLeft: "40px" }}>[Accepted file format .csv]</h6>
                                </div>
                                <div className='col-md-2'></div>
                            </div>
                        </div><br />
                        <div className='col-md-12' style={{ textAlign: 'center' }}>
                            <Button type="primary" shape="round" className='addbutton' style={{ marginTop: "10px" }}
                                icon={<UploadOutlined />} size='default' onClick={uploaddocu}>
                                Upload
                            </Button>
                        </div>
                        <div className='pt-2 px-3' style={{textAlign: 'center'}}>
                            {inValidBtn ? <Button onClick={() => setUploadFailedUrl(!uploadFailedUrl)}>Valid / Invalid Data</Button> : null}
                            {uploadFailedUrl ?
                                <Breadcrumb separator=" | " style={{ color: 'blue' }}>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'xlsx/' + invalidUrl.fileName + '.xlsx'}>Download Excel</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'csv/' + invalidUrl.fileName + '.csv'}>Download Csv</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'ods/' + invalidUrl.fileName + '.ods'}>Download ODS</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'txt/' + invalidUrl.fileName + '.txt'}>Download Text</a></Breadcrumb.Item>
                                </Breadcrumb>
                                : null}
                        </div>
                        <Divider />
                        {fileInfo ? <Row>
                            <Col span={12}>
                                <h4>Uploaded Data - Summary</h4>
                                <ul>
                                    <li>Total No. Of Records: {csvStatus.total ? csvStatus.total : 0}</li>
                                    <li>Valid Records: {0}</li>
                                    <li>Invalid Records: {csvStatus.invalid ? csvStatus.invalid : 0}</li>
                                </ul>
                            </Col>
                            <Col span={12} style={{ color: 'red' }}>
                                <h4 style={{ color: 'red' }}>Invalid Data Summary</h4>
                                <ul>
                                    <li>Mandatory Field Blank: {csvStatus.mandatory_error ? csvStatus.mandatory_error : 0}</li>
                                    <li>Duplicate Compliance: {csvStatus.duplicate_error ? csvStatus.duplicate_error : 0}</li>
                                    <li>Master Data Inactive: {csvStatus.inactive_error ? csvStatus.inactive_error : 0}</li>
                                    <li>Max Length Exceeded: {csvStatus.max_length_error ? csvStatus.max_length_error : 0}</li>
                                    <li>Invalid Data: {csvStatus.invalid_data_error ? csvStatus.invalid_data_error : 0}</li>
                                </ul>
                            </Col>
                        </Row> : null}
                    </Card>
                </div>
            </div>
        </div>
    )

}
export default connect(mapStateToProps, {
    filterdetails,
    downloadAssignStatutory,
    downloadLink,
    uploadCSV,
    uploadFileStatus
})(AssignStatutoryBulkupload);