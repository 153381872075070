import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Collapse,
    Select,
    Button,
    Table,
    Tooltip,
    Tabs,
    Input,
    Card,
    DatePicker,
    Modal
} from "antd";
import SimpleReactValidator from "simple-react-validator";
import {
    ArrowRightOutlined,
    ArrowLeftOutlined,
    PlusOutlined,
    CloseOutlined,
    PlusCircleOutlined,
    DeleteColumnOutlined,
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { Toaster } from "../../Libs/Toaster";
import { getGroupAdminEmailDetails } from "../../Store/Action/Transactions/GroupadminRegisEmail";

const mapStateToProps = (state) => ({
    listArray: state.GroupadminRegisEmail.groupadminRegisEmail.list
});

const GroupAdmin_regis_email = ({
    getGroupAdminEmailDetails,
    listArray

}) => {

    const location = useLocation();
    const paramid = location.pathname;
    const authtoken = localStorage.getItem('authToken');
    const isAuth = localStorage.getItem('isAuthenticated');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    console.log(listArray, 'list');

    useEffect(() => {

        if (isAuth) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetGroupAdminGroupUnitList",
                    {}
                ]
            }
            getGroupAdminEmailDetails({
                payload: payload,
                paramid: paramid
            });
        }

    }, [isAuth])


    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                // return (
                //     (page - 1) * paginationSize + index + 1
                // )
            },
        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Country</span>,
            dataIndex: 's_p_name',
            key: 's_p_name',
            ...getColumnSearchProps('s_p_name', 'Service Provider'),
            // ellipsis: true,
            width: '165px',
            filteredValue: filteredInfo.s_p_name || null,
            sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Group</span>,
            dataIndex: 'cont_person',
            key: 'cont_person',
            ...getColumnSearchProps('cont_person', 'Contact Person'),
            // ellipsis: true,
            width: '150px',
            filteredValue: filteredInfo.cont_person || null,
            sorter: (a, b) => a.cont_person.localeCompare(b.cont_person),
            sortOrder: sortedInfo.columnKey === 'cont_person' ? sortedInfo.order : null,
            render: (text, record) => {
                let text1 = record.cont_person
                let letter = text1.charAt(0);
                let upper = letter.toUpperCase();
                return <p style={{ marginBottom: '0px' }}>
                    {/* <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{upper}</Avatar> */}
                    &nbsp;{record.cont_person}</p>
            }

        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Product</span>,
            dataIndex: 'cont_no',
            key: 'cont_no',
            ...getColumnSearchProps('cont_no', 'Contact No'),
            ellipsis: true,
            width: '140px',
            filteredValue: filteredInfo.cont_no || null,
            sorter: (a, b) => a.cont_no.length - b.cont_no.length,
            sortOrder: sortedInfo.columnKey === 'cont_no' ? sortedInfo.order : null,

        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>No. of Legal Entity</span>,
            dataIndex: 'e_id',
            key: 'e_id',
            ...getColumnSearchProps('e_id', 'Email ID'),
            ellipsis: true,
            width: '170px',
            filteredValue: filteredInfo.e_id || null,
            sorter: (a, b) => a.e_id.localeCompare(b.e_id),
            sortOrder: sortedInfo.columnKey === 'e_id' ? sortedInfo.order : null,



        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Registration Email</span>,
            dataIndex: 'remarks',
            key: 'remarks',
            ...getColumnSearchProps('remarks', 'Remarks'),
            ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.remarks || null,
            sorter: (a, b) => {
                if (a && a.remarks && a.remarks.length && b && b.remarks && b.remarks.length) {
                    return a.remarks.localeCompare(b.remarks);
                } else if (a && a.remarks && a.remarks.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.remarks && b.remarks.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            // sorter: (a, b) => a.category_name && b.category_name != null ? a.category_name.localeCompare(b.category_name) : "",
            sortOrder: sortedInfo.columnKey === 'remarks' ? sortedInfo.order : null,
            // sortOrder: sortedInfo.columnKey === 'remarks' ? sortedInfo.order : null,


        },
        {
            title: <span className='title' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Legal Entity</span>,
            dataIndex: 'remarks',
            key: 'remarks',
            ...getColumnSearchProps('remarks', 'Remarks'),
            ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.remarks || null,
            sorter: (a, b) => {
                if (a && a.remarks && a.remarks.length && b && b.remarks && b.remarks.length) {
                    return a.remarks.localeCompare(b.remarks);
                } else if (a && a.remarks && a.remarks.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.remarks && b.remarks.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            // sorter: (a, b) => a.category_name && b.category_name != null ? a.category_name.localeCompare(b.category_name) : "",
            sortOrder: sortedInfo.columnKey === 'remarks' ? sortedInfo.order : null,
            // sortOrder: sortedInfo.columnKey === 'remarks' ? sortedInfo.order : null,


        },
       
    ];

    return (
        <>
            <div id="page-wrapper" className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={
                    <Tooltip title="Back to top">
                        <UpOutlined />
                    </Tooltip>
                }
                /> */}
                <div className="page-titles pb-1 pt-1">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer'
                                            onClick={homescreen}
                                        >
                                            <i className="ri-home-3-line fs-5"></i>
                                        </span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transactions</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span>Group Admin Registration Email</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <div className='service-provider-table'>
                    <Table
                        // sortDirections={["ascend", "descend", "ascend"]}
                        className={localStorage.getItem("currentTheme")}
                        locale={{ emptyText: <b style={{ color: "black" }}>{'No Records Found'}</b> }}
                        // id='service-user-priv'
                        size={'small'}
                        columns={columns}
                        dataSource={listArray && listArray.groupadmin_groupList}
                        bordered
                        pagination={false}
                        showSorterTooltip={false}
                        onChange={handleChange} />
                </div> */}
            </div>
        </>
    )



};

export default connect(mapStateToProps, {
    getGroupAdminEmailDetails
})(GroupAdmin_regis_email);
