import React, { Fragment } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, Button, Table, Pagination, Modal, Card, Tooltip } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { FilterOutlined, FullscreenOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { UserMappingReportFilters, UserMappingLegalEntityDetails, ExportUserMappingDetailsReportData, UserMappingDetailsReportData } from './../../../src/Store/Action/Reports/UserMappingReport';
import { EXPORT_USER_MAPPING_REPORT_RESET } from './../../Store/types/index';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";

const mapStateToProps = (state) => ({
    usermappingresult: state.UserMappingReport.usermapppingreport,
});

const UserMappingReport = ({
    UserMappingReportFilters,
    UserMappingLegalEntityDetails,
    UserMappingDetailsReportData,
    ExportUserMappingDetailsReportData,
    usermappingresult: { usermapppingreportfilter, legalentityfilter, showdata, exportdata }
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue');
    const sessionParsedValue = JSON.parse(sessionValue);
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    let [index, setIndex] = useState(0);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false);
    const [tabledata, setTabledata] = useState([]);
    const [group, setGroup] = useState([]);
    const [businessgroup, setBusinessgroup] = useState([]);
    const [legalentity, setLegalentity] = useState([]);
    const [unit, setUnit] = useState([]);
    const [divisiondata, setdivisiondata] = useState([]);
    const [category_data, setcategory_data] = useState([]);
    const [showstatutory, setShowstatutory] = useState([]);
    const [applicationModal, setApplicationModal] = useState(false);
    const [geo, setGeo] = useState([]);
    const [showfiltericon, Setshowfiltericon] = useState('none');
    const [filterModalVisible, setfilterModalVisible] = useState(false);
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);

    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        currentpage: 1,
        pageSizeOptions: ['10', '20', '30']
    });
    const [key, setKey] = useState("1");
    let [count, setcount] = useState(0);
    const [usermappingdata, setUserMappingdata] = useState({
        country: '',
        countryName: '',
        division: '0',
        division_name: '',
        group: '',
        group_name: '',
        category: '0',
        category_name: '',
        businessgroup: '0',
        businessgroup_name: '',
        unit: "0",
        unit_name: "",
        legal: '',
        legal_name: '',
    });
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25);
    const [current, setCurrent] = useState(1);
    const [paginationArray, setPaginationArray] = useState([]);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [parentNames, setParentNames] = useState([]);
    const [filteredArrayState, setfilteredArrayState] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredFrequency, setFilteredFrequency] = useState([]);
    const [updatedArray, setUpdatedArray] = useState([]);
    const [filtermodal, setfiltermodal] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filteredFields, setFilteredFields] = useState({
        filteredDomain: [],
        filteredNature: [],
        filteredAct: [],
    });

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '25px',
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>;
            }
        },
        {
            title: 'Unit',
            dataIndex: 'unit_code_with_name',
            key: 'unit_code_with_name',
            // align: 'center',
            width: '100px',
            // ellipsis: true,
        },
        {
            title: 'Techno Manager',
            dataIndex: 'techno_manager',
            key: 'techno_manager',
            // align: 'center',
            width: '90px',
            // ellipsis: true,

        },
        {
            title: 'Techno User',
            dataIndex: 'techno_user',
            key: 'techno_user',
            // align: 'center',
            width: '90px',
            // ellipsis: true,
        },
        {
            title: 'Domain Manager Labour - Employment - Industrial',
            dataIndex: 'Manager_Labour',
            key: 'Manager_Labour',
            // align: 'center',
            width: '110px',
            // ellipsis: true,
        },
        {
            title: 'Domain User Labour - Employment - Industrial',
            dataIndex: 'Executive_Labour',
            key: 'Executive_Labour',
            // align: 'center',
            width: '110px',
            // ellipsis: true,
        },
        {
            title: 'Domain Manager Vendor Compliance Management',
            dataIndex: 'Manager_Vendor',
            key: 'Manager_Vendor',
            // align: 'center',
            width: '120px',
            // ellipsis: true,
            render: (text) => text ? text : 'NA'
        },
        {
            title: 'Domain User Vendor Compliance Management',
            dataIndex: 'Executive_Vendor',
            key: 'Executive_Vendor',
            // align: 'center',
            width: '120px',
            // ellipsis: true,
            render: (text) => text ? text : 'NA'
        },

    ];

    useEffect(() => {
        if (isAuth) {
            UserMappingReportFilters({
                payload: {
                    "session_token": authtoken,
                    "request": [
                        "GetUserMappingReportFilters",
                        {}
                    ]
                },
                paramid: paramid
            });
        }
    }, [isAuth]);

    useEffect(() => {
        if (legalentityfilter) {
            let unique = _.uniqBy(legalentityfilter && legalentityfilter.usermapping_unit && legalentityfilter.usermapping_unit.length > 0 && legalentityfilter.usermapping_unit, function (e) {
                return e.unit_id;
            });

            var filtered = unique.filter(function (el) {
                return el.division_name != null;
            });
            var orginalvalue = filtered.map(o => o.division_name);
            var finalarray = filtered.filter(({ division_name }, index) => !orginalvalue.includes(division_name, index + 1));


            var cat_filter = unique.filter(function (el) {
                return el.category_name != null;
            });
            var orginal_cat = cat_filter.map(o => o.category_name);
            var finalarray_cat = cat_filter.filter(({ category_name }, index) => !orginal_cat.includes(category_name, index + 1));

            setdivisiondata(finalarray);
            setcategory_data(finalarray_cat);
            setUnit(unique);

        }
    }, [legalentityfilter]);

    useEffect(() => {
        if (exportdata && exportdata.link) {
            window.open(process.env.REACT_APP_API_DOWN_URL + exportdata.link);
            dispatch({
                type: EXPORT_USER_MAPPING_REPORT_RESET
            });
        }

    }, [exportdata]);

    useEffect(() => {
        if (showdata) {
            let temp = [];
            let temparr = [];
            let value = [];
            let tempmanager = [];
            for (let i in showdata.techno_details) {
                let unitwise = _.filter(showdata.unit_domains, { unit_id: showdata.techno_details[i].unit_id });
                temp.push(unitwise);
            }
            for (let j in temp) {
                let manager = _.filter(temp[j], { user_category_name: "Domain Manager" });
                let executive = _.filter(temp[j], { user_category_name: "Domain Executive" });
                let checkmanager = [];
                for (let c in manager) {
                    if (manager[c].domain_id == 1) {
                        checkmanager.push(
                            {
                                Manager_Labour: manager[c].employee_name,
                                unit_id: manager[c].unit_id
                            }
                        );
                    } else {
                        checkmanager.push(
                            {
                                Manager_Vendor: manager[c].employee_name
                            }
                        );
                    }
                }
                for (let d in executive) {
                    if (executive[d].domain_id == 1) {
                        checkmanager.push(
                            {
                                Executive_Labour: executive[d].employee_name
                            }
                        );
                    } else {
                        checkmanager.push(
                            {
                                Executive_Vendor: executive[d].employee_name
                            }
                        );
                    }
                }
                let k = Object.assign({}, ...checkmanager);
                tempmanager.push(k);
            }
            for (let k in showdata.techno_details) {
                let temp = _.filter(tempmanager, { unit_id: showdata.techno_details[k].unit_id });
                let a = [];
                a.push(showdata.techno_details[k], temp && temp[0]);
                let m = Object.assign({}, ...a);
                value.push(m);
            }
            setTabledata(value);
        }
    }, [showdata]);

    useEffect(() => {
        validator.current.showMessages();
    }, []);

    const onshow = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            Setshowfiltericon('block');
            setExitCollapse(true);
            setfilterModalVisible(false);
            setTableShow(true);
            setKey(0);
            console.log((usermappingdata.businessgroup + "," + usermappingdata.division + ',' + usermappingdata.category + ',' + usermappingdata.unit).toString(), 'ooooooooooo');
            UserMappingDetailsReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserMappingDetailsReportData",
                        {
                            "country_id": Number(usermappingdata.country),
                            "client_id": Number(usermappingdata.group),
                            "legal_entity_id": Number(usermappingdata.legal),
                            "u_m_none": (usermappingdata.businessgroup + "," + usermappingdata.division + ',' + usermappingdata.category + ',' + usermappingdata.unit).toString(),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            });

        }
    };

    const onexport = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            setKey(0);
            ExportUserMappingDetailsReportData({
                payload: {
                    "session_token": authtoken,
                    "request": [
                        "GetUserMappingDetailsReportData",
                        {
                            "country_id": Number(usermappingdata.country),
                            "client_id": Number(usermappingdata.group),
                            "legal_entity_id": Number(usermappingdata.legal),
                            "u_m_none": (usermappingdata.businessgroup + "," + usermappingdata.division + ',' + usermappingdata.category + ',' + usermappingdata.unit).toString(),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            });

        }
    };

    const download = (url) => {
        // window.open(process.env.REACT_APP_API_URL + url)
        window.open(process.env.REACT_APP_API_DOWN_URL + url);

    };

    const homescreen = () => {
        window.location.href = '/dashboard';
    };

    const setcancelFilter = () => {
        setfilterModalVisible(false);
    };

    return (
        <div className="page-wrapper" id="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>User Mapping Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <Tooltip title='Advanced Filter'>
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    display: showfiltericon,
                                    marginRight: "15px",
                                }} onClick={() => {
                                    setfilterModalVisible(true);
                                }}>
                                <FilterOutlined /> Filter
                            </Button>
                        </Tooltip>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="User Mapping Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {
                                                                let unique = _.uniqBy(usermapppingreportfilter && usermapppingreportfilter.usermapping_groupdetails && usermapppingreportfilter.usermapping_groupdetails.length > 0 && usermapppingreportfilter.usermapping_groupdetails, function (e) {
                                                                    return e.client_id;
                                                                });
                                                                let group = _.filter(unique, { country_id: Number(value) });
                                                                setGroup(group);
                                                                if (data) {
                                                                    setUserMappingdata({
                                                                        ...usermappingdata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        division: '0',
                                                                        division_name: '',
                                                                        group: '',
                                                                        group_name: '',
                                                                        category: '0',
                                                                        category_name: '',
                                                                        businessgroup: '0',
                                                                        businessgroup_name: '',
                                                                        unit: '0',
                                                                        unit_name: '',
                                                                        legal: '',
                                                                        legal_name: ''
                                                                    });                                                                    
                                                                } else {
                                                                    setUserMappingdata({
                                                                        ...usermappingdata,
                                                                        country: '',
                                                                        countryName: '',
                                                                        division: '0',
                                                                        division_name: '',
                                                                        group: '',
                                                                        group_name: '',
                                                                        category: '0',
                                                                        category_name: '',
                                                                        businessgroup: '0',
                                                                        businessgroup_name: '',
                                                                        unit: '0',
                                                                        unit_name: '',
                                                                        legal: '',
                                                                        legal_name: ''
                                                                    }); 
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={usermappingdata.countryName || undefined}
                                                        >
                                                            {usermapppingreportfilter && usermapppingreportfilter.countries && usermapppingreportfilter.countries.length > 0 && usermapppingreportfilter.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            usermappingdata.countryName,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Group Name : <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.group_name || undefined}
                                                    disabled={usermappingdata.country == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                group: value,
                                                                group_name: data.children,
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                businessgroup: '0',
                                                                businessgroup_name: '',
                                                                unit: '0',
                                                                unit_name: '',
                                                                legal: '',
                                                                legal_name: ''
                                                            });                                                            
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                group: '',
                                                                group_name: '',
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                businessgroup: '0',
                                                                businessgroup_name: '',
                                                                unit: '0',
                                                                unit_name: '',
                                                                legal: '',
                                                                legal_name: ''
                                                            }); 
                                                        }
                                                        let le = _.filter(group, { client_id: Number(value) });
                                                        let lecheck = _.filter(usermapppingreportfilter && usermapppingreportfilter.usermapping_groupdetails, { client_id: le[0].client_id });
                                                        let le_id = [];
                                                        let le_data = [];
                                                        let temp = [];
                                                        let gp_business = [];
                                                        for (let i in lecheck) {
                                                            le_id.push(lecheck[i].legal_entity_id);
                                                            if (lecheck[i].business_group_id != null) {
                                                                temp.push(lecheck[i].business_group_id);
                                                            }
                                                        }
                                                        for (let j in le_id) {
                                                            let le_temp = _.find(usermapppingreportfilter && usermapppingreportfilter.usermapping_legal_entities, { legal_entity_id: le_id[j] });
                                                            le_data.push(le_temp);
                                                        }
                                                        setLegalentity(le_data);
                                                        let unique = _.uniqBy(temp && temp.length > 0 && temp, function (e) {
                                                            return e;
                                                        });
                                                        for (let k in unique) {
                                                            let gp_temp = _.find(usermapppingreportfilter && usermapppingreportfilter.usermapping_business_groups, { business_group_id: unique[k] });
                                                            gp_business.push(gp_temp);
                                                        }
                                                        setBusinessgroup(gp_business);
                                                    }}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Group"
                                                    style={{ width: '100%' }}
                                                >
                                                    {group && group.length > 0 && group.map((item, i) => {
                                                        return (
                                                            <Option key={item.client_id}>
                                                                {item.client_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'group',
                                                    usermappingdata.group_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Group Name Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Business Group :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.businessgroup_name || undefined}
                                                    showSearch
                                                    disabled={usermappingdata.group == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        let entity = _.filter(usermapppingreportfilter && usermapppingreportfilter.usermapping_legal_entities, { business_group_id: Number(value) });
                                                        setLegalentity(entity);
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                businessgroup: value,
                                                                businessgroup_name: data.children,
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                unit: '0',
                                                                unit_name: '',
                                                                legal: '',
                                                                legal_name: ''
                                                            });                                                            
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                businessgroup: '',
                                                                businessgroup_name: '',
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                unit: '0',
                                                                unit_name: '',
                                                                legal: '',
                                                                legal_name: ''
                                                            }); 
                                                        }
                                                    }}
                                                    placeholder="Enter Business Group"
                                                    style={{ width: '100%' }}
                                                >
                                                    {businessgroup && businessgroup.length > 0 && businessgroup.map((item, i) => {
                                                        return (
                                                            <Option key={item.business_group_id}>
                                                                {item.business_group_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Legal Entity : <span style={{ color: "red" }}>*</span> </label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.legal_name || undefined}
                                                    showSearch
                                                    disabled={usermappingdata.group == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                legal: value,
                                                                legal_name: data.children,
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                unit: '0',
                                                                unit_name: ''
                                                            });
                                                            UserMappingLegalEntityDetails({
                                                                payload: {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "GetUserMappingLegalEntityDetails",
                                                                        {
                                                                            "legal_entity_id": Number(value)
                                                                        }
                                                                    ]
                                                                },
                                                                paramid: paramid
                                                            });                                                            
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                legal: '',
                                                                legal_name: '',
                                                                division: '0',
                                                                division_name: '',
                                                                category: '0',
                                                                category_name: '',
                                                                unit: '0',
                                                                unit_name: ''
                                                            });
                                                        }
                                                    }}
                                                    placeholder="Enter Legal Entity"
                                                    style={{ width: '100%' }}
                                                >
                                                    {legalentity && legalentity.length > 0 && legalentity.map((item, i) => {
                                                        return (
                                                            <Option key={item.legal_entity_id}>
                                                                {item.legal_entity_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'legal',
                                                    usermappingdata.legal,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Name Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Division :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.division_name || undefined}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                division: value,
                                                                division_name: data.children
                                                            });                                                            
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                division: '0',
                                                                division_name: ''
                                                            });  
                                                        }
                                                    }}
                                                    disabled={usermappingdata.legal == '' ? true : false}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%' }}>
                                                    {divisiondata && divisiondata.length > 0 && divisiondata.map((item, i) => {
                                                        return (
                                                            <Option key={item.division_id}>
                                                                {item.division_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Category :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.category_name || undefined}
                                                    disabled={usermappingdata.legal == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                category: value,
                                                                category_name: data.children
                                                            });                                                            
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                category: '0',
                                                                category_name: ''
                                                            }); 
                                                        }
                                                    }}
                                                    placeholder="Enter Category"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {category_data && category_data.length > 0 && category_data.map((item, i) => {
                                                        console.log(item, 'itemmmm');
                                                        if (usermappingdata.division_name !== '' && usermappingdata.division_name == item.division_name) {
                                                            return (
                                                                <Option key={item.category_id}>
                                                                    {item.category_name}
                                                                </Option>
                                                            );                                                            
                                                        }
                                                        if (usermappingdata.division_name == '' && item.country_id == usermappingdata.country && item.client_id == usermappingdata.group && item.legal_entity_id == usermappingdata.legal) {
                                                            return (
                                                                <Option key={item.category_id}>
                                                                    {item.category_name}
                                                                </Option>
                                                            );                                                            
                                                        }
                                                    })}
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Unit :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={usermappingdata.unit_name || undefined}
                                                    disabled={usermappingdata.legal == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        let division = usermappingdata.division !== '0' ? true : false;
                                                        let category = usermappingdata.category !== '0' ? true : false;
                                                        console.log('div', division);
                                                        console.log('cat', category);
                                                        if (data) {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                unit: value,
                                                                unit_name: data.children
                                                            });
                                                        } else {
                                                            setUserMappingdata({
                                                                ...usermappingdata,
                                                                unit: '',
                                                                unit_name: ''
                                                            });
                                                        }
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%' }}
                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        let division = usermappingdata.division !== '0' ? true : false;
                                                        let category = usermappingdata.category !== '0' ? true : false;
                                                        
                                                        if (parseInt(usermappingdata.country) === item.country_id && parseInt(usermappingdata.group) === item.client_id && parseInt(usermappingdata.legal) === item.legal_entity_id) {
                                                            if (division && category) {
                                                                if (item.division_id === parseInt(usermappingdata.division) && item.category_id === parseInt(usermappingdata.category)) {
                                                                    return <Option key={item.unit_id}>{item.unit_code_name}</Option>;
                                                                }
                                                            } 
                                                            
                                                            if (division && !category && item.division_id === parseInt(usermappingdata.division)) {
                                                                    return <Option key={item.unit_id}>{item.unit_code_name}</Option>;
                                                            } 
                                                            
                                                            if (!division && category && item.category_id === parseInt(usermappingdata.category)) {
                                                                    return <Option key={item.unit_id}>{item.unit_code_name}</Option>;
                                                            }

                                                            if (!division && !category) {
                                                                return <Option key={item.unit_id}>{item.unit_code_name}</Option>;
                                                            }                     
                                                        }
                                                    })}
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='text-center '>
                                        <Button type="primary" shape="round" className='addbutton'
                                            onClick={() => { onshow(); }} icon={<EyeOutlined />} >
                                            Show
                                        </Button>&nbsp;&nbsp;
                                        <Button type="primary" shape="round" className='addbutton'
                                            onClick={() => { onexport(); }} icon={< ExportOutlined />} >
                                            Export
                                        </Button>

                                    </div>
                                </Panel>
                            </Collapse>
                            <br />
                            <Modal
                                title="User Mapping Report"
                                className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                footer={false}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        onChange={(value, data) => {
                                                            let unique = _.uniqBy(usermapppingreportfilter && usermapppingreportfilter.usermapping_groupdetails && usermapppingreportfilter.usermapping_groupdetails.length > 0 && usermapppingreportfilter.usermapping_groupdetails, function (e) {
                                                                return e.client_id;
                                                            });
                                                            let group = _.filter(unique, { country_id: Number(value) });
                                                            setGroup(group);
                                                            if (data) {
                                                                setUserMappingdata({
                                                                    ...usermappingdata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    division: '0',
                                                                    division_name: '',
                                                                    group: '',
                                                                    group_name: '',
                                                                    category: '0',
                                                                    category_name: '',
                                                                    businessgroup: '0',
                                                                    businessgroup_name: '',
                                                                    unit: '0',
                                                                    unit_name: '',
                                                                    legal: '',
                                                                    legal_name: ''
                                                                });                                                                
                                                            } else {
                                                                setUserMappingdata({
                                                                    ...usermappingdata,
                                                                    country: '',
                                                                    countryName: '',
                                                                    division: '0',
                                                                    division_name: '',
                                                                    group: '',
                                                                    group_name: '',
                                                                    category: '0',
                                                                    category_name: '',
                                                                    businessgroup: '0',
                                                                    businessgroup_name: '',
                                                                    unit: '0',
                                                                    unit_name: '',
                                                                    legal: '',
                                                                    legal_name: ''
                                                                });
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={usermappingdata.countryName || undefined}
                                                    >
                                                        {usermapppingreportfilter && usermapppingreportfilter.countries && usermapppingreportfilter.countries.length > 0 && usermapppingreportfilter.countries.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        usermappingdata.countryName,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Group Name : <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.group_name || undefined}
                                                disabled={usermappingdata.country == '' ? true : false}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            group: value,
                                                            group_name: data.children,
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            businessgroup: '0',
                                                            businessgroup_name: '',
                                                            unit: '0',
                                                            unit_name: '',
                                                            legal: '',
                                                            legal_name: ''
                                                        });                                                        
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            group: '',
                                                            group_name: '',
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            businessgroup: '0',
                                                            businessgroup_name: '',
                                                            unit: '0',
                                                            unit_name: '',
                                                            legal: '',
                                                            legal_name: ''
                                                        });
                                                    }
                                                    let le = _.filter(group, { client_id: Number(value) });
                                                    let lecheck = _.filter(usermapppingreportfilter && usermapppingreportfilter.usermapping_groupdetails, { client_id: le[0].client_id });
                                                    let le_id = [];
                                                    let le_data = [];
                                                    let temp = [];
                                                    let gp_business = [];
                                                    for (let i in lecheck) {
                                                        le_id.push(lecheck[i].legal_entity_id);
                                                        if (lecheck[i].business_group_id != null) {
                                                            temp.push(lecheck[i].business_group_id);
                                                        }
                                                    }
                                                    for (let j in le_id) {
                                                        let le_temp = _.find(usermapppingreportfilter && usermapppingreportfilter.usermapping_legal_entities, { legal_entity_id: le_id[j] });
                                                        le_data.push(le_temp);
                                                    }
                                                    setLegalentity(le_data);
                                                    let unique = _.uniqBy(temp && temp.length > 0 && temp, function (e) {
                                                        return e;
                                                    });
                                                    for (let k in unique) {
                                                        let gp_temp = _.find(usermapppingreportfilter && usermapppingreportfilter.usermapping_business_groups, { business_group_id: unique[k] });
                                                        gp_business.push(gp_temp);
                                                    }
                                                    setBusinessgroup(gp_business);
                                                }}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                placeholder="Enter Group"
                                                style={{ width: '100%' }}
                                            >
                                                {group && group.length > 0 && group.map((item, i) => {
                                                    return (
                                                        <Option key={item.client_id}>
                                                            {item.client_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'group',
                                                usermappingdata.group_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Group Name Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Business Group : </label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.businessgroup_name || undefined}
                                                showSearch
                                                disabled={usermappingdata.group == '' ? true : false}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(value, data) => {
                                                    let entity = _.filter(usermapppingreportfilter && usermapppingreportfilter.usermapping_legal_entities, { business_group_id: Number(value) });
                                                    setLegalentity(entity);
                                                    if (data) {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            businessgroup: value,
                                                            businessgroup_name: data.children,
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            unit: '0',
                                                            unit_name: '',
                                                            legal: '',
                                                            legal_name: ''
                                                        });                                                        
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            businessgroup: '',
                                                            businessgroup_name: '',
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            unit: '0',
                                                            unit_name: '',
                                                            legal: '',
                                                            legal_name: ''
                                                        });
                                                    }
                                                }}
                                                placeholder="Enter Business Group"
                                                style={{ width: '100%' }}
                                            >
                                                {businessgroup && businessgroup.length > 0 && businessgroup.map((item, i) => {
                                                    return (
                                                        <Option key={item.business_group_id}>
                                                            {item.business_group_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "10px" }}>
                                    <div className='row'>
                                    <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Legal Entity : <span style={{ color: "red" }}>*</span> </label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.legal_name || undefined}
                                                showSearch
                                                disabled={usermappingdata.group == '' ? true : false}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(value, data) => {
                                                    if (data) {                                                        
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            legal: value,
                                                            legal_name: data.children,
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            unit: '0',
                                                            unit_name: ''
                                                        });
                                                        UserMappingLegalEntityDetails({
                                                            payload: {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetUserMappingLegalEntityDetails",
                                                                    {
                                                                        "legal_entity_id": Number(value)
                                                                    }
                                                                ]
                                                            },
                                                            paramid: paramid
                                                        });
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            legal: '',
                                                            legal_name: '',
                                                            division: '0',
                                                            division_name: '',
                                                            category: '0',
                                                            category_name: '',
                                                            unit: '0',
                                                            unit_name: ''
                                                        });
                                                    }
                                                }}
                                                placeholder="Enter Legal Entity"
                                                style={{ width: '100%' }}
                                            >
                                                {legalentity && legalentity.length > 0 && legalentity.map((item, i) => {
                                                    return (
                                                        <Option key={item.legal_entity_id}>
                                                            {item.legal_entity_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'legal',
                                                usermappingdata.legal,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Legal Entity Name Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Division : </label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.division_name || undefined}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            division: value,
                                                            division_name: data.children
                                                        });                                                        
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            division: '',
                                                            division_name: ''
                                                        });
                                                    }
                                                }}
                                                disabled={usermappingdata.legal == '' ? true : false}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                placeholder="Enter Division"
                                                style={{ width: '100%' }}>
                                                {divisiondata && divisiondata.length > 0 && divisiondata.map((item, i) => {
                                                    return (
                                                        <Option key={item.division_id}>
                                                            {item.division_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Category : </label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.category_name || undefined}
                                                disabled={usermappingdata.legal == '' ? true : false}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            category: value,
                                                            category_name: data.children
                                                        });                                                        
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            category: '',
                                                            category_name: ''
                                                        });
                                                    }
                                                }}
                                                placeholder="Enter Category"
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                            >
                                                {category_data && category_data.length > 0 && category_data.map((item, i) => {
                                                    console.log(item, 'itemmmm');
                                                    return (
                                                        <Option key={item.category_id}>
                                                            {item.category_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "10px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Unit : </label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={usermappingdata.unit_name || undefined}
                                                disabled={usermappingdata.legal == '' ? true : false}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            unit: value,
                                                            unit_name: data.children
                                                        });                                                        
                                                    } else {
                                                        setUserMappingdata({
                                                            ...usermappingdata,
                                                            unit: '',
                                                            unit_name: ''
                                                        });
                                                    }
                                                }}
                                                placeholder="Enter Unit"
                                                style={{ width: '100%' }}
                                            >
                                                {unit && unit.length > 0 && unit.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {item.unit_code_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                    </div>
                                </div>
                                <div className='text-center'>
                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                        onClick={() => { onshow(); }} icon={<EyeOutlined />}>
                                        Show
                                    </Button>&nbsp;&nbsp;
                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                        onClick={() => { onexport(); }} icon={< ExportOutlined />}>
                                        Export
                                    </Button>

                                </div>
                            </Modal>
                            {tableshow == true ? <>
                                {/* <Card style={{ marginTop: '-30px' }}>
                                    <div className='row'>
                                        <div className='col-lg-4'><b>Country</b> : {usermappingdata.countryName}</div>
                                        <div className='col-lg-4'><b>Group</b> : {usermappingdata.group_name}</div>
                                        <div className='col-lg-4'><b>Business Group</b> : {usermappingdata.businessgroup_name ? usermappingdata.businessgroup_name : '-'}</div>
                                    </div><br></br>
                                    <div className='row'>
                                        <div className='col-lg-4'><b>Legal Entity</b> : {usermappingdata.legal_name}</div>
                                        <div className='col-lg-4'><b>Division</b> : {usermappingdata.division_name ? usermappingdata.division_name : '-'}</div>
                                        <div className='col-lg-4'><b>Category</b> : {usermappingdata.category_name ? usermappingdata.businessgroup_name : '-'}</div>
                                    </div>
                                </Card> */}
                                {tabledata && tabledata.length > 0 ?
                                    <Card className='servcardround mb-2 pt-1'>
                                        <div className='pb-2 px-2'>
                                            <div className='row'>
                                                <div className='col-lg-4'><b>Country</b> : {usermappingdata.countryName}</div>
                                                <div className='col-lg-4'><b>Group</b> : {usermappingdata.group_name}</div>
                                                <div className='col-lg-4'><b>Business Group</b> : {usermappingdata.businessgroup_name ? usermappingdata.businessgroup_name : '-'}</div>
                                            </div><br></br>
                                            <div className='row'>
                                                <div className='col-lg-4'><b>Legal Entity</b> : {usermappingdata.legal_name}</div>
                                                <div className='col-lg-4'><b>Division</b> : {usermappingdata.division_name ? usermappingdata.division_name : '-'}</div>
                                                <div className='col-lg-4'><b>Category</b> : {usermappingdata.category_name ? usermappingdata.category_name : '-'}</div>
                                            </div>
                                        </div>
                                        <Table
                                            className={localStorage.getItem("currentTheme") + ' reassignclass1 reassignclass2'}
                                            columns={columns}
                                            dataSource={tabledata}
                                            bordered
                                            pagination={false}
                                        />
                                    </Card>
                                    : <Card className='servcardround mb-2 pt-1'>
                                        <div className='pb-2 px-2'>
                                            <div className='row'>
                                                <div className='col-lg-4'><b>Country</b> : {usermappingdata.countryName}</div>
                                                <div className='col-lg-4'><b>Group</b> : {usermappingdata.group_name}</div>
                                                <div className='col-lg-4'><b>Business Group</b> : {usermappingdata.businessgroup_name ? usermappingdata.businessgroup_name : '-'}</div>
                                            </div><br></br>
                                            <div className='row'>
                                                <div className='col-lg-4'><b>Legal Entity</b> : {usermappingdata.legal_name}</div>
                                                <div className='col-lg-4'><b>Division</b> : {usermappingdata.division_name ? usermappingdata.division_name : '-'}</div>
                                                <div className='col-lg-4'><b>Category</b> : {usermappingdata.category_name ? usermappingdata.category_name : '-'}</div>
                                            </div>
                                        </div>
                                        <div style={{ border: 'thin solid grey', padding: '10px', margin: '1%' }}>
                                            <lable style={{ marginLeft: '40%' }}>No Records Found</lable>
                                        </div>
                                    </Card>}
                                <Pagination style={{ "margin-top": "10px", float: " RIGHT" }}
                                    current={current}
                                    showSizeChanger={true}
                                    showQuickJumper={false}
                                    onShowSizeChange={() => {
                                        setCurrent(1);
                                    }}
                                    pageSizeOptions={[25, 50, 100]}
                                    hideOnSinglePage={true}
                                    defaultPageSize={25}
                                    onChange={(page, pageSizes) => {
                                        console.log(pageSizes, 'pageSizes');
                                        setpageState(true);
                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                        setPageSize(pageSizes);
                                        // }} total={StatutoryMappingReportShow.total_count} />
                                    }} />

                            </> : false}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    );
};
export default connect(mapStateToProps, {
    UserMappingReportFilters,
    UserMappingLegalEntityDetails,
    UserMappingDetailsReportData,
    ExportUserMappingDetailsReportData
})(UserMappingReport);