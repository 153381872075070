import { BU_CLIENT_INFO, BU_UPLOAD_CONSTANT, BU_USER, BU_USER_MAPPING, BU_RECORD_DATA } from './../../types/index'
const initialState = {
    assignedstatutory: {
        clientinfo: '',
        upload: '',
        user: '',
        usermapping: '',
        recorddata: ''
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BU_CLIENT_INFO:
            return {
                ...state,
                assignedstatutory: {
                    ...state.assignedstatutory,
                    clientinfo: payload,
                },
            };
        case BU_UPLOAD_CONSTANT:
            return {
                ...state,
                assignedstatutory: {
                    ...state.assignedstatutory,
                    upload: payload,
                },
            };
        case BU_USER:
            return {
                ...state,
                assignedstatutory: {
                    ...state.assignedstatutory,
                    user: payload,
                },
            };
        case BU_USER_MAPPING:
            return {
                ...state,
                assignedstatutory: {
                    ...state.assignedstatutory,
                    usermapping: payload,
                },
            };
        case BU_RECORD_DATA:
            return {
                ...state,
                assignedstatutory: {
                    ...state.assignedstatutory,
                    recorddata: payload,
                },
            };
        default:
            return state;
    }
}