import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Descriptions } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { notificationshowall, getComplianceInfo } from '../../Store/Action/Login';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.statutory_notifications,
    compfieInfo: state.Login.compfieInfo

})
const Notifications = ({
    notificationshowall,
    lists,
    getComplianceInfo,
    compfieInfo
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const [isModalOpen, setIsModalOpen] = useState(false)

    const markread = (record) => {

        let home = callername.login_response.menu.menus['My Accounts'];
        let url = _.filter(home, { form_name: 'Statutory Notification' });
        let notifypayload;
        let getInfo;
        let payload;
        if (authtoken) {
            getInfo = {
                "session_token": authtoken,
                "request": [
                    "GetComplianceInfo",
                    {
                        "comp_id": record.compliance_id,
                        "extn_id": 0
                    }
                ]
            };

            notifypayload = {
                "session_token": authtoken,
                "request": [
                    "UpdateStatutoryNotificationStatus",
                    {
                        "user_id": record.user_id,
                        "notification_id": record.notification_id,
                        "has_read": true,
                    }
                ]
            };

            payload = {
                "session_token": authtoken,
                "request": [
                    "GetStatutoryNotifications",
                    {
                        "from_count": 0,
                        "page_count": 50
                    }
                ]
            }

            getComplianceInfo({
                payload: getInfo,
                caller: url[0].form_url,
                key: url[0].form_key,
                notificationPayload: notifypayload,
                listPayload: payload
            })
        }
        setIsModalOpen(true)

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '12%'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            width: '150px',
            render: (text, record) => {
                return <span className='pointer text-wrap-report' onClick={(e) => { markread(record) }}>
                    {record.notification_text}</span>

            }

        },
        {
            title: 'Name',
            dataIndex: 'created_by',
            key: 'created_by',
            width: '13%',
            render: (data) => {
                return <span className='text-wrap-report'>
                    {data}
                </span>
            }
        }
    ];

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu.menus['My Accounts']
            const messageArray = _.filter(getUrl, { form_name: 'Statutory Notification' });
            const messagekey = messageArray[0].form_key;

            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetStatutoryNotifications",
                    {
                        "from_count": 0,
                        "page_count": 50
                    }
                ]
            }

            notificationshowall({
                payload: payload,
                caller: paramid,
                key: messagekey
            })

        }
    }, [isAuth])


    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Home</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Notifications</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>

                                        {/* <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                fixedHeader={dataTableProperties.fixedHeader}
                                                pagination
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            /> */}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={lists}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

                <Modal title="Compliance Details" open={isModalOpen} footer={false} onCancel={handleCancel} maskClosable={false} className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} >
                    <Descriptions layout="vertical" className='labelclass'>
                        <Descriptions.Item label="Statutory Provision">{compfieInfo.s_pro}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Task">{compfieInfo.c_task}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Description">{compfieInfo.descrip}</Descriptions.Item>
                        <Descriptions.Item label="Penal Consequences">{compfieInfo.p_cons}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Frequency">{compfieInfo.freq}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Occurrence">
                            {compfieInfo.summary}
                        </Descriptions.Item>
                        <Descriptions.Item label="Applicable Location">
                            {compfieInfo.locat}
                        </Descriptions.Item>
                        <Descriptions.Item label="Reference Link">
                            <Link to={compfieInfo.refer} target='_blank'>{compfieInfo.refer}</Link>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    getComplianceInfo
})(Notifications);