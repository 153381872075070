import React, { Fragment,useEffect } from 'react';
import { connect } from 'react-redux';
import Header from './header';
import Sidenav from './sidenav';
import RoutesLink from '../Components/Routes';

const mapStateToProps = (state) => ({

});
const Full = () => {
   
//    useEffect(()=>{
//     // window.onbeforeunload = () => {
//     //     localStorage.clear();
//     //     window.location.reload(true);
//     //   }
//    },[]) 
    
    return (
        <Fragment>
            <div id="main-wrapper">
                <Header></Header>
                <Sidenav></Sidenav>
                <RoutesLink></RoutesLink>
                <footer className="footer d-flex justify-content-center">
                    2022© Aparajitha Software Services Private Limited
                    <span className="ms-4">Privacy Policy · Terms & Condition </span>
                </footer>
            </div>
        </Fragment>
    );
};

export default connect(mapStateToProps, {})(Full);

