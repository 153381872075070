export const LOGINSUCCESS = 'LOGINSUCCESS'
export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const TASK_DUE_DATE = 'TASK_DUE_DATE'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const ESCALATION = 'ESCALATION'
export const REMAINDER = 'REMAINDER'
export const MESSAGE = 'MESSAGE'
export const NOTIFICATIONSHOWALL = 'NOTIFICATIONSHOWALL'
export const GETUSERS = 'GETUSERS'
export const GETUSER = 'GETUSER'
export const GETREJECTEDLIST = 'GETREJECTEDLIST'
export const GETBUCONSTANT = 'GETBUCONSTANT'
export const ADDTASKDUEDATE = 'ADDTASKDUEDATE'
export const TASKDUEDATE = 'TASKDUEDATE'
export const GETTASKMAPPING = 'GETTASKMAPPING'
export const GETVIEWTASKMAPPING = 'GETVIEWTASKMAPPING'
export const TASK_HISTORY = 'TASK_HISTORY'
export const STATUTORYNOTIFICATIONSFILTERS = 'STATUTORYNOTIFICATIONSFILTERS'
export const STATUTORYNOTIFICATIONSREPORTDATA = 'STATUTORYNOTIFICATIONSREPORTDATA'
export const GETEDITTASKMAPPING = 'GETEDITTASKMAPPING'
export const MAPPING_LIST = 'MAPPING_LIST'
export const MAPPING_INFO = 'MAPPING_INFO'
export const MAPPINTMASTER = 'MAPPINTMASTER'
export const BULKUPLOAD = 'BULKUPLOAD'
export const USER = 'USER'
export const BULKREPORTDATA = 'BULKREPORTDATA'
export const STATUTORYMAPPINGREPORT = 'STATUTORYMAPPINGREPORT'
export const PROFILE = 'PROFILE'
export const STATUTORYMAPPINGREPORTSHOW = 'STATUTORYMAPPINGREPORTSHOW'
export const EXPORTBULKREPORTDATA = 'EXPORTBULKREPORTDATA'
export const EXPORTBULKREPORTDATA_RESET = 'EXPORTBULKREPORTDATA_RESET'
export const MAPPINGBULKLIST = 'MAPPINGBULKLIST'
export const GETTASKCATEGORYMAPPINGFILTER = 'GETTASKCATEGORYMAPPINGFILTER'
export const BULKUPLOADDOMAIN = 'BULKUPLOADDOMAIN'
export const GETTASKCATEGORYMAPPING = 'GETTASKCATEGORYMAPPING'
export const REJECTEDDOWNLOADCOUNT = 'REJECTEDDOWNLOADCOUNT'
export const REJECTEDFILENAME = 'REJECTEDFILENAME'
export const DOWNLOADLINK = 'DOWNLOADLINK'
export const UPLOADFILENAME = 'UPLOADFILENAME'
export const ERRORDATA = 'ERRORDATA'
export const FILEUPLOADSUCCESS = 'FILEUPLOADSUCCESS'
export const FETCHINGEDITDETAILS = 'FETCHINGEDITDETAILS'
export const GETAPPROVETASKCATEGORYMAPPINGFILTER = 'GETAPPROVETASKCATEGORYMAPPINGFILTER'
export const APPROVE_TASK_CATEGORY_MAPPING_SHOW = "APPROVE_TASK_CATEGORY_MAPPING_SHOW"
export const APPROVE_TASK_CATEGORY_COMPLIANCE = 'APPROVE_TASK_CATEGORY_COMPLIANCE'
export const APPROVE_TASK_CATEGORY_COMPLIANCE_SHOWMORE = 'APPROVE_TASK_CATEGORY_COMPLIANCE_SHOWMORE'
export const APPROVE_STATUTORY_MAPPING_FILTER = 'APPROVE_STATUTORY_MAPPING_FILTER'
export const APPROVE_STATUTORY_MAPPING_SHOW = 'APPROVE_STATUTORY_MAPPING_SHOW'
export const APPROVE_BULK_UPLOAD = 'APPROVE_BULK_UPLOAD'
export const APPROVE_BULK_UPLOAD_EXE = 'APPROVE_BULK_UPLOAD_EXE'
export const BULK_UPLOAD_LIST = 'BULK_UPLOAD_LIST'
export const BULK_UPLOAD_RESPONSE = 'BULK_UPLOAD_RESPONSE'
export const COMPLIANCE_INFO = 'COMPLIANCE_INFO'
export const COMPLIANCE_INFO_RESET = 'COMPLIANCE_INFO_RESET'
export const BULK_REJECT_RESPONSE = 'BULK_REJECT_RESPONSE'
export const BULK_UPLOAD_VIEW = 'BULK_UPLOAD_VIEW'
export const BULK_UPLOAD_FILTER = 'BULK_UPLOAD_FILTER'
export const BULK_UPLOAD_APPROVE = 'BULK_UPLOAD_APPROVE'
export const BULK_UPLOAD_Reject = 'BULK_UPLOAD_REJECT'
export const SUCCESS_MAPPING = 'SUCCESS_MAPPING'
export const ASSIGN_CLIENT_LIST = 'ASSIGN_CLIENT_LIST'
export const ASSIGN_CLIEBT_VIEW = 'ASSIGN_CLIEBT_VIEW'
export const ASSIGN_CLIENT_UNIT_DETAILS = 'ASSIGN_CLIENT_UNIT_DETAILS'
export const AMENDMENT_DATA = 'AMENDMENT_DATA'
export const FILEUPLOADSUCCESSNOTIFICATION = 'FILEUPLOADSUCCESSNOTIFICATION'
export const COMPLIANCEHISTORY = 'COMPLIANCEHISTORY'
export const ASSIGN_STATU_UPLOAD_LIST = 'ASSIGN_STATU_UPLOAD_LIST'
export const USER_MAPPING_FILTER = 'USER_MAPPING_FILTER'
export const AMENDMENT_UPLOAD_SUCCESS = 'AMENDMENT_UPLOAD_SUCCESS'
export const LEGAL_ENTITY_FILTER = 'LEGAL_ENTITY_FILTER'
export const AMENDMENT_UPLOAD_ERROR = 'AMENDMENT_UPLOAD_ERROR'
export const USER_MAPPING_REPORT_SHOW = 'USER_MAPPING_REPORT_SHOW'
export const EXPORT_USER_MAPPING_REPORT = 'EXPORT_USER_MAPPING_REPORT'
export const EXPORT_USER_MAPPING_REPORT_RESET = 'EXPORT_USER_MAPPING_REPORT_RESET'
export const MAPPING_UPLOAD_LIST = 'MAPPING_UPLOAD_LIST'
export const GETTASKCATEGORYMAPPINGSHOWMORE = 'GETTASKCATEGORYMAPPINGSHOWMORE'
export const STATUTORY_MAPPING_HISTORY = 'STATUTORY_MAPPING_HISTORY'
export const APPROVEASSIGNFILTER = 'APPROVEASSIGNFILTER'
export const APPROVEASSIGNLEGALDOMAINFILTER = 'APPROVEASSIGNLEGALDOMAINFILTER'
export const APPROVEASSIGNUSERFILTER = 'APPROVEASSIGNUSERFILTER'
export const ADD_UPLOAD_DATA = 'ADD_UPLOAD_DATA'
export const AMENDMENT_UPLOAD_FILE = 'AMENDMENT_UPLOAD_FILE'
export const ASSIGN_PENDING_LIST = 'ASSIGN_PENDING_LIST'
export const ASSIGN_DOMAIN_LIST = 'ASSIGN_DOMAIN_LIST'
export const ASSIGNED_STATUTORIES_FOR_APPROVE = 'ASSIGNED_STATUTORIES_FOR_APPROVE'
export const GET_ASSIGNED_STATUTORY_DATA_SUCCESS = 'GET_ASSIGNED_STATUTORY_DATA_SUCCESS'
export const ASSIGN_CLIENT_UNIT_VENDOR = 'ASSIGN_CLIENT_UNIT_VENDOR'
export const CLIENT_UNIT_FILTER = 'CLIENT_UNIT_FILTER'
export const CLIENT_UNIT_UNIT_CODE = 'CLIENT_UNIT_UNIT_CODE'
export const LEGALENTITYDATA = 'LEGALENTITYDATA'
export const CLIENTAGREEMENTFILTER = 'CLIENTAGREEMENTFILTER'
export const CLIENTAGREEMENTDATA = 'CLIENTAGREEMENTDATA'
export const GETMAPPINGREJECTEDLIST = 'GETMAPPINGREJECTEDLIST'

// Domain Wise Agreement Master Report - Start
export const CLIENT_AGREEMENT_REPORT_FILTERS = 'CLIENT_AGREEMENT_REPORT_FILTERS'
export const DOMAIN_WISE_AGREEMENT_REPORT_DATA = 'DOMAIN_WISE_AGREEMENT_REPORT_DATA'
export const AGGREMENT_REPORT_DISPLAY_POPUP = 'AGGREMENT_REPORT_DISPLAY_POPUP'
export const DOMAIN_AGREEMENT_EXPORT_TO_CSV = 'DomainAgreementExportToCSV'

// Domain Wise Agreement Master Report - End

export const STATUTORY_UNIT_FILTER = 'STATUTORY_UNIT_FILTER'
export const STAT_LEGALENTITYDATA = 'STAT_LEGALENTITYDATA'
export const STATUTORY_PRODUCT_DATA = 'STATUTORY_PRODUCT_DATA'
export const STAT_TABLEDATA = 'STAT_TABLEDATA'
export const CLIENTUNITDETAILSDATA = 'CLIENTUNITDETAILSDATA'
export const POPUP_DATA = 'POPUP_DATA'
export const STAT_EXPORTDATA = 'STAT_EXPORTDATA'
export const STAT_EXPORTDATA_RESET = 'STAT_EXPORTDATA_RESET'
export const BU_UPLOAD_CONSTANT = 'BU_UPLOAD_CONSTANT'
export const BU_USER = 'BU_USER'
export const BU_CLIENT_INFO = 'BU_CLIENT_INFO'
export const BU_USER_MAPPING = 'BU_USER_MAPPING'
export const BU_RECORD_DATA = 'BU_RECORD_DATA'
export const ASSIGN_STATUTORY_BULK_UPLOAD = 'ASSIGN_STATUTORY_BULK_UPLOAD'
export const DOWNLOAD_ASBU_LINK = 'DownloadAssignStatutoryBULink'
export const UPLOAD_ASSIGN_STATUTORY_CSV = 'UploadAssignStatutoryCSV'
export const UPLOAD_FILE_STATUS = 'uploadFileStatus'
export const DOWNLOAD_ASSIGN_STATUTORY = 'DOWNLOAD_ASSIGN_STATUTORY'
export const BULK_ASSIGN_UPLOAD_VIEW = 'BULK_ASSIGN_UPLOAD_VIEW'
export const BULK_ASSIGN_UPLOAD_FILTER = 'BULK_ASSIGN_UPLOAD_FILTER'
export const BULK_ASSIGN_UPLOAD_VIEW1 = 'BULK_ASSIGN_UPLOAD_VIEW1'

// Transactions -->  RejectedAssignStatutory_bulkupload - Start
export const RESET_STATE = 'Reset Rejected AS State'
export const GET_CLIENT_INFO = 'ClientInfoRequest'
export const REJECTED_ASM_BULK_UPLOAD_DATA = 'RejectedASMBulkUploadData'
export const REJECTED_ASM_DOWNLOAD_COUNT = 'RejectedASMDownloadCount'
export const DOWNLOAD_REJECTED_ASM_REPORT = 'DownloadRejectedASMReport'
export const DOWNLOAD_REJECT_STATUS = 'DownloadRejectFile'
export const REJECTED_ASBU_VERIFY_PASSWORD = 'RejectedASBUVerifyPassword'
export const DELETE_CSV = 'DeleteCSV Success'
// Transactions -->  RejectedAssignStatutory_bulkupload - End

export const BULK_ASSIGN__VIEW = 'BULK_ASSIGN__VIEW'
export const BULK_ASSIGN_FILTER = 'BULK_ASSIGN_FILTER'

//Assign Statutory
export const DEASSIGNSTATUTORYFILTER = 'DEASSIGNSTATUTORYFILTER'
export const DEASSIGNSTATUTORYOTHERFILTER = 'DEASSIGNSTATUTORYOTHERFILTER'
export const GETASSIGNEDSTAUTORIES = 'GETASSIGNEDSTAUTORIES'
export const ASSIGNED_STATUTORY_COUNT = 'assignedStatutoryCount'
export const ASSIGNED_STATUTORY_TWO_DATA = 'AssignedStatutoryWizardTwoData'
export const ASSIGNED_STATUTORY_ADD_OPTION = 'AssignStatutoryAddOption'
export const ASSIGNED_STATUTORY_ADD_UNITS = 'AssignStatutoryAddUnitsList'
export const SAVE_ASSIGNED_STATUTORY = 'SaveAssignedStatutory'
export const REMARK_STATE = 'RemarkState'
export const ASSIGNED_STATUTORY_ADD_TWO_COUNT = 'AssignedStatutoryAddTwoCount'
export const ASSIGNED_STATUTORY_ADD_TWO_DATA = 'AssignedStatutoryAddTwoData'
export const ASSIGNED_STATUTORY_ADD_TWO_DATA_MULTIPLE = 'AssignedStatutoryAddTwoDataMultiple'
export const SUBMIT_ASSIGNED_STATUTORY = 'SubmitAssignedStatutory'

//LegalEntityClosure
export const LEGAL_ENTITY_CLOSURE_TABLE_DATA = 'LEGAL_ENTITY_CLOSURE_TABLE_DATA'
export const LEGAL_ENTITY_CLOSURE_TABLE_DATA_VIEW = 'LEGAL_ENTITY_CLOSURE_TABLE_DATA_VIEW'
export const ELSE_DATA = 'ELSE_DATA'
export const RESET_ELSE = 'RESET_ELSE'

export const TABLE_DATA = 'TABLE_DATA'
export const GET_BULK_CONSTANT = 'GET_BULK_CONSTANT'
export const GET_REJECT_CLIENT_UNIT = 'GET_REJECT_CLIENT_UNIT'
export const GET_CLIENT_UNIT_FILTER = 'GET_CLIENT_UNIT_FILTER'
export const GET_CLIENT_UNIT_LIST = 'GET_CLIENT_UNIT_LIST'
export const GET_CLIENT_ADD_UNIT_LIST = 'GET_CLIENT_ADD_UNIT_LIST'
export const GETUSERSLIST = 'GETUSERSLIST'


// Transactions --> Assign Legal Entity

export const ASSIGN_LEGAL_ENTITY = 'ASSIGN_LEGAL_ENTITY'
export const ASSIGNED_LEGAL_ENTITY = 'ASSIGNED_LEGAL_ENTITY'
export const GET_ASSIGNED_ENTITY_DATA = 'GET_ASSIGNED_ENTITY_DATA'

// Client Unit Bulk Upload
export const CLIENT_UNIT_BULK_UPLOAD = 'CLIENT_UNIT_BULK_UPLOAD'
export const CLIENT_UNIT_STATUTORY_CSV = 'CLIENT_UNIT_STATUTORY_CSV'
export const CLIENTUPLOAD_FILE_STATUS = 'CLIENTUPLOAD_FILE_STATUS'
export const GETCOMPLIANCEINFO = 'GETCOMPLIANCEINFO'
export const FULL_SCREEN = 'FULL_SCREEN'

//Unit Code Label
export const UNIT_CODE_TABLE = 'UNIT_CODE_TABLE'
export const UNIT_CODE_DATA_VIEW = 'UNIT_CODE_DATA_VIEW'
export const UNIT_CODE_SUCCESS = 'UNIT_CODE_SUCCESS'
export const UNIT_CODE_SUCCESS_RESET = 'UNIT_CODE_SUCCESS_RESET'

//Approve Client 
export const REJECT_CLIENT_UNIT_DATA = 'REJECT_CLIENT_UNIT_DATA'
export const TECHNO_DETAIL = 'TECHNO_DETAIL'
export const UNITS_UPLOADED_CSV = 'UNITS_UPLOADED_CSV'


// Transactions --> Client Group
export const CLIENT_GROUP = 'CLIENT_GROUP'
export const EDIT_CLIENT_GROUP = 'EDIT_CLIENT_GROUP'



// Transactions -->  ApproveComplianceBasedAssignStatutory_BU - Start
export const PRODUCT_DOMAIN_LIST = 'ProductDomainList'
export const COMPLIANCE_ASSIGN_STATUTORY_FOR_APPROVE = 'ComplianceAssignStatutoryForApprove'
export const DOMAIN_EXE_DETAILS = 'DomainExecutiveDetails'
export const FILTER_COMPLIANCE = 'FilterCompliance'
    // Transactions -->  ApproveComplianceBasedAssignStatutory_BU - End
