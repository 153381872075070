import { Fragment, useState, useEffect, useRef, InfoCircleOutlineduseMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Button, Collapse, Steps, Tooltip, Segmented, Space, Avatar, Select, Popover, Card, Progress, Tag, Statistic, Row, Col, List } from 'antd';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { getGroupsEntites, getUnitcodeDetails, saveUnitcode } from '../../Store/Action/Transactions/UnitCodeLabel'
import { InfoCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, ClockCircleOutlined, PlusOutlined, SearchOutlined, ArrowUpOutlined, CloseOutlined, ArrowDownOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LikeOutlined, DislikeOutlined, StopOutlined, PlusCircleOutlined, FullscreenOutlined, InfoCircleTwoTone } from '@ant-design/icons';
// import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST, UNIT_CODE_SUCCESS_RESET } from '../../Store/types';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { Toaster } from '../../Libs/Toaster';
import { HomeRedirect } from './../../Libs/country'

const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    CodeLabel: state.UnitCodeLabel.unitcode,
    // filterrecall: state.recallfilters.recallfilters

});
const UnitCodeLabel = ({
    saveUnitClosureData,
    getUnitClosureList,
    CodeLabel: { tabledata, viewdata, successdata },
    getLegalEntityClosureReportData,
    saveLegalEntityClosureData,
    getGroupsEntites,
    getUnitcodeDetails,
    saveUnitcode

}) => {
    console.log(tabledata, 'tabledata');
    console.log(viewdata, 'viewdata');
    console.log(successdata, 'successdata');
    const historynew = useHistory();
    // const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    // console.log(unitClosureList.UnitClosureList, 'dkjhfdkjsfhdjf');
    const { Option } = Select;
    const dispatch = useDispatch()
    const { Panel } = Collapse;
    const _ = require("lodash");
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    console.log(remarks, 'remarksremarks');
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [show, setShow] = useState(false)
    const [entiyty, setEntity] = useState([])
    const [groupname, setGroupname] = useState([])
    const [filterText, setFilterText] = useState("");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [statkey, setStatKey] = useState("0")
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        // unit_id: '',
        legal_entity_id: '',
        grp_mode: ''

    })

    const [unitCode, setUnitCode] = useState({
        groupname: '',
        groupid: '',
        lename: '',
        leid: ''
    })
    const [labelData, setLabelData] = useState({
        level_position_1: '',
        level_position_2: '',
        level_position_3: '',
        level_position_4: ''
    })
    console.log(labelData, 'labelData');
    console.log(unitCode, 'unitCode');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [type, setType] = useState('active')
    const [entityid, setCurrentEntity] = useState("");
    const [reactivatedArray, setReActivatedArray] = useState([])
    const [activeArray, setActiveArray] = useState([])
    const [inActiveArray, setInActiveArray] = useState([])
    const [unBlockedArray, setUnBlockedArray] = useState([])
    const [reactiveArray, setReactiveArray] = useState([])
    const [fullscreen, setfullscreen] = useState(false);
    const serviceProviderRef1 = useRef(null);
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen)

    // useEffect(() => {
    //     if (elsevalue[0] == 'InvalidPassword') {
    //         Toaster.error('Invalid Password')
    //         dispatch({
    //             type: RESET_ELSE
    //         })
    //         setModalData({
    //             ...modalData,
    //             password: ""
    //         })
    //         setRemarks("")
    //         setAddFormSubmit(false)
    //         // setModaVisible(false)
    //     } else if (elsevalue[0] == "SaveLegalEntityClosureSuccess") {
    //         if (modalData.grp_mode == 'reactive')
    //             Toaster.success('Legal Entity Activated Successfullly')
    //         else if (modalData.grp_mode == 'close')
    //             Toaster.success('Legal Entity temporarily closed and can be activated within 90 days')
    //         dispatch({
    //             type: RESET_ELSE
    //         })
    //         setAddFormSubmit(false)
    //         setModaVisible(false)
    //         setModalData({
    //             ...modalData,
    //             password: ""
    //         })
    //         setRemarks("")
    //     }
    // }, [elsevalue])
    const { Search } = Input;

    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    // Auto Focus 

    const setFocus = (ref) => {
        console.log(ref, 'currentref')
        setTimeout(() => {
            ref.current.focus();
        });
    };

    useEffect(() => {
        if (tabledata && tabledata.groups_entities_list.length > 0) {
            let uniqle = _.uniqBy(tabledata && tabledata.groups_entities_list, function (e) {
                return e.client_id;
            });
            setGroupname(uniqle)
        }
    }, [tabledata])

    useEffect(() => {
        if (successdata[0] == 'SaveUnitcodeSuccess') {
            setUnitCode({
                groupname: '',
                groupid: '',
                lename: '',
                leid: ''
            })
            setLabelData({
                level_position_1: '',
                level_position_2: '',
                level_position_3: '',
                level_position_4: ''
            })
            dispatch({
                type : UNIT_CODE_SUCCESS_RESET
            })
        }
    }, [successdata])

    useEffect(() => {
        if (viewdata && viewdata.Unitcode_Details.length > 0) {
            viewdata.Unitcode_Details.map((items) => {
                return <>{
                    items.level_position == 1 ? setLabelData({
                        ...labelData,
                        level_position_1: items.label_description
                    }) : items.level_position == 2 ? setLabelData({
                        ...labelData,
                        level_position_2: items.label_description
                    }) : items.level_position == 3 ? setLabelData({
                        ...labelData,
                        level_position_3: items.label_description
                    }) : items.level_position == 4 ? setLabelData({
                        ...labelData,
                        level_position_4: items.label_description
                    }) : ''
                }</>
            })
            // if()
            // setLabelData
        }
    }, [viewdata])

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const OnSubmit = () => {
        let levelvalue = []
        let value = Object.values(labelData)
        if (unitCode.groupname == '') {
            Toaster.error('Group Name Required')
        } else if (unitCode.lename == '') {
            Toaster.error('Legal Entity Name Required')
        }
        if (unitCode.groupname != '' && unitCode.lename != '') {
            let temp = value.every((item) => item == '')
            if (temp) {
                Toaster.error('Level name title Required')
            } else {
                value.map((items, i) => {
                    if (items != '') {
                        levelvalue.push({
                            "l_position": i + 1,
                            "l_name": labelData[`level_position_${i + 1}`],
                            "l_id": null,
                            "is_remove": false
                        })
                    }
                })
                const payloadInitial = {
                    "session_token": authtoken,
                    "request": [
                        "GetGroupsEntites",
                        {}
                    ]
                }
                saveUnitcode({
                    payload: {
                        "session_token": authtoken,
                        "request": [
                            "SaveUnitcode",
                            {
                                "client_id": unitCode.groupid,
                                "le_id": unitCode.leid,
                                "levels": levelvalue
                            }
                        ]
                    },
                    caller: paramid,
                    payloadInitial: payloadInitial
                })
            }
        }
    }

    const onInputChange = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setRemarks('')
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~\^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setRemarks(e.target.value)
                }
            }
        }
    }

    /* Action Needed */
    // const handlePasting = (e, regex) => {
    //     var regex = regex;
    //     var key = e.clipboardData.getData('text')
    //     if (!regex.test(key)) {
    //         e.preventDefault();
    //         Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
    //         return false;
    //     }
    // }

    // const columns1 = [
    //     {
    //         title: '#',
    //         dataIndex: false,
    //         align: 'center',
    //         width: '50px',
    //         render: (text, record, index) => {
    //             return (page - 1) * 10 + index + 1
    //         }
    //     },
    //     {
    //         title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Unit</span>,
    //         dataIndex: 'unit',
    //         key: 'unit',
    //         ...getColumnSearchProps('unit', 'Unit'),
    //         filteredValue: filteredInfo1.unit || null,
    //         showSorterTooltip: true,
    //         sorter: (a, b) => a.unit.localeCompare(b.unit),
    //         sortOrder: sortedInfo1.columnKey === 'unit' ? sortedInfo1.order : null,
    //         render: (text, record) => {
    //             return (
    //                 <Fragment>
    //                     <p className='mb-0' style={{ whiteSpace: 'unset' }}><span><span title={` ${record.address} , ${record.postal_code} `}> <InfoCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /> </span>&nbsp;</span>
    //                         <span title={record.unit}>{record.unit}</span></p>
    //                 </Fragment>
    //             )
    //         }
    //     },
    //     {
    //         title: 'TimeLine',
    //         key: 'status',
    //         align: "center",
    //         width: "150px",
    //         dataIndex: 'status',
    //         render: (text, record, index) => {
    //             if ((30 - record.validity_days) >= 20) {
    //                 return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //             else if ((30 - record.validity_days) >= 6) {
    //                 return <p className='mb-0 text-primary'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //             else if ((30 - record.validity_days) <= 5) {
    //                 return <p className='mb-0 text-danger'><ClockCircleOutlined className='I' id='I' /> &nbsp; {`${(30 - record.validity_days)} - Days left`}</p>
    //             }
    //         },
    //     },
    //     {
    //         title: 'Closure',
    //         dataIndex: 'currentStatus',
    //         width: '120px',
    //         align: 'center',


    //         render: (text, record) => {
    //             return (
    //                 <Fragment>
    //                     {record.is_active == 0 ?
    //                         <Button
    //                             type="primary"
    //                             style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
    //                             onClick={() => {
    //                                 setShow(true)
    //                                 closeById(record.unit_id, "closed");
    //                             }}
    //                             title=""
    //                         ><Avatar
    //                                 style={{
    //                                     backgroundColor: '#F32013',
    //                                 }}
    //                                 icon={<i className="fas fa-ban"></i>}
    //                             />
    //                         </Button> : record.is_active == 1 && record.validity_days > 30 ?
    //                             <span>Closed</span>
    //                             :
    //                             <span title={(30 - record.validity_days) + '-' + 'Days Left'}>
    //                                 <div className="avtr">
    //                                     <Avatar
    //                                         className='pointer'
    //                                         style={{
    //                                             backgroundColor: '#F32013',
    //                                             fontSize: '13px',
    //                                             width: '28px',
    //                                             height: '29px'
    //                                         }}
    //                                         icon={<i className="fas fa-sync"></i>}
    //                                         onClick={() => {
    //                                             setModalData({
    //                                                 password: '',
    //                                                 reason: ''
    //                                             })
    //                                             setShow(true)
    //                                             closeById(record.unit_id, "reactive");
    //                                         }}
    //                                     />
    //                                 </div>
    //                             </span>

    //                     }
    //                 </Fragment>
    //             )
    //         }
    //     }
    // ];


    // const content = (
    //     <div>
    //         <Row gutter={16}>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="Active"
    //                         value={activedata}
    //                         precision={2}
    //                         valueStyle={{ color: '#3f8600' }}
    //                         prefix={<ArrowUpOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="In-Active"
    //                         value={inactivedata}
    //                         precision={2}
    //                         valueStyle={{ color: '#cf1322' }}
    //                         prefix={<ArrowDownOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //             <Col span={8}>
    //                 <Card className='unitClosure' bordered={true}>
    //                     <Statistic
    //                         title="Closed"
    //                         value={closeddata}
    //                         precision={2}
    //                         valueStyle={{ color: '#eed202' }}
    //                         prefix={<CloseOutlined />}
    //                     // suffix="%"
    //                     />
    //                 </Card>
    //             </Col>
    //         </Row>
    //     </div>
    // );

    // const RemarksColumn = [
    //     {
    //         title: 'S.No',
    //         dataIndex: false,
    //         width: '30px',
    //         align: 'center',
    //         render: (text, record, index) => {
    //             return (page - 1) * 10 + index + 1
    //         }
    //     },
    //     {
    //         title: 'Description',
    //         dataIndex: 'r_description',
    //         key: 'r_description',
    //         width: '200px',
    //         ellipsis: true,
    //         render: (text, record) => <a onClick={() => {
    //             setRemarks(text)
    //             setRemarksModal(false)
    //             dispatch({
    //                 type: REMARKS_MODAL_RESET,
    //                 payload: false
    //             })
    //         }}>{record.r_description}</a>,
    //     },
    // ]



    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            // fetch pay
            const payloadInitial = {
                "session_token": authtoken,
                "request": [
                    "GetLegalEntityClosureReportData",
                    {}
                ]
            }
            //fetchpay
            saveLegalEntityClosureData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveLegalEntityClosureData",
                        {
                            "password": modalData.password,
                            "closed_remarks": remarks,
                            "legal_entity_id": Number(modalData.legal_entity_id),
                            "grp_mode": modalData.grp_mode,
                            // "legal_entity_id": entityid
                        }
                    ]
                },
                caller: paramid,
                payloadInitial: payloadInitial,
            })
            // setModalData({
            //     ...modalData,
            //     password: ""
            // })
            // setRemarks("")
            // setAddFormSubmit(false)
            // setModaVisible(false)
        }
    }

    // useEffect(() => {
    //     if (recallremarks && is_modal_true) {
    //         let temp = [];
    //         for (let i = 0; i < recallremarks.length; i++) {
    //             if (recallremarks[i].r_description != '') {
    //                 temp.push(recallremarks[i])
    //             }
    //             Setremarksvalue(temp)
    //         }
    //         if (temp.length == 0) {
    //             Toaster.error('No Remarks Available')
    //             setModaVisible(false)
    //             setModalData({
    //                 ...modalData,
    //                 password: ""
    //             })
    //             setRemarks("")
    //             setAddFormSubmit(false)
    //             dispatch({
    //                 type: REMARKS_MODAL_RESET,
    //                 payload: false
    //             })
    //         } else {
    //             setRemarksModal(true);
    //         }
    //     }

    // }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    const passwordcancel = () => {
        setModaVisible(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    // const handleOk = () => {
    //     // setIsModalVisible(false);
    //     setRemarksModal(false)
    // };

    // const handleCancel = () => {
    //     // setIsModalVisible(false);
    //     setRemarksModal(false)
    //     dispatch({
    //         type: REMARKS_MODAL_RESET,
    //         payload: false
    //     })
    // };

    // const showRemarksModal = () => {
    //     GetRemarksData({
    //         payload:
    //             [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request": [
    //                         "GetRemarksData",
    //                         {}
    //                     ]
    //                 }
    //             ],
    //         paramid: paramid
    //     })
    //     // setRemarksModal(true);
    //     dispatch({
    //         type: REMARKS_MODAL_RESET,
    //         payload: true
    //     })
    // };

    useEffect(() => {
        if (isAuth) {
            // if (entityid != '') {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetGroupsEntites",
                    {}
                ]
            }

            // if (entityid != "null") {
            getGroupsEntites({
                payload: payload,
                caller: paramid
            })
            // } else {
            //     setBaseData('')
            // }
            // }

        }
    }, [isAuth])

    const closeById = (entity_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        legal_entity_id: entity_id,
                        // reason: type,
                        grp_mode: type
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        legal_entity_id: entity_id,
                        // reason: type,
                        grp_mode: type
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            })
    };

    // useEffect(() => {
    //     if (modalData.legal_entity == '' || null || undefined) {
    //         setModalData({
    //             ...modalData,
    //             legal_entity: entityid
    //         })

    //     }
    // }, [modalData.legal_entity])




    const [baseData, setBaseData] = useState([]);
    const [baseData1, setBaseData1] = useState([])
    const [finalBaseData, setFinalBaseData] = useState([])
    console.log(baseData, 'baseData');

    // useEffect(() => {
    //     // if (type === 'active' && baseData.length > 0) {
    //     let activeData = _.filter(baseData, { is_active: 1 })
    //     setActiveArray(activeData)
    //     // }
    //     // else if (type === 'inactive' && baseData.length > 0) {
    //     let inactiveData = _.filter(baseData, { is_active: 0 })
    //     setInActiveArray(inactiveData)
    //     // }
    //     // else if (type === 'unblock' && baseData.length > 0) {
    //     let unblockData = _.filter(baseData, { currentStatus: 1 })
    //     setUnBlockedArray(unblockData)
    //     // }
    //     // else {
    //     let block = _.filter(baseData, { currentStatus: 0 })
    //     setBlockedArray(block)
    //     // }
    //     let reactive = _.filter(baseData, { currentStatus: 2 })
    //     setReactiveArray(reactive)
    // }, [baseData])

    // useEffect(() => {
    //     let entityArray = []
    //     let tempArraryNew = []
    //     if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
    //         let array1 = _.filter(unitClosureList.UnitClosureList, { is_active: 0 });
    //         setActivedata(array1.length)
    //         let array2 = _.filter(unitClosureList.UnitClosureList, { is_active: 1 });
    //         setInactivedata(array2.length)

    //     }
    //     if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
    //         unitClosureList.UnitClosureList.map((subList) => {
    //             let currentStatus = ''
    //             if (subList.is_active == 0) {
    //                 currentStatus = 0 //close
    //             } else if (subList.is_active == 1 && subList.validity_days <= 30) {
    //                 currentStatus = 2 //reactivate
    //             } else if (subList.is_active == 1 && subList.validity_days > 30) {
    //                 currentStatus = 1 // closed
    //                 let tempclosed = Number(closeddata) + 1
    //                 setCloseddata(tempclosed)
    //             } else {
    //                 currentStatus = subList.is_active //default is_active
    //             }
    //             entityArray = {
    //                 'address': subList.address,
    //                 'business_group_name': subList.business_group_name,
    //                 'category_name': subList.category_name,
    //                 'closed_on': subList.closed_on,
    //                 'division_name': subList.division_name,
    //                 'is_active': subList.is_active,
    //                 'legal_entity_id': subList.legal_entity_id,
    //                 'legal_entity_name': subList.legal_entity_name,
    //                 'postal_code': subList.postal_code,
    //                 'unit_code': subList.unit_code,
    //                 'unit_id': subList.unit_id,
    //                 'unit_name': subList.unit_name,
    //                 'validity_days': subList.validity_days,
    //                 'currentStatus': currentStatus,
    //                 'unit': `${subList.unit_code}-${subList.unit_name}`,
    //             }
    //             tempArraryNew.push(entityArray)
    //         })
    //         //setBaseData(unitClosureList.UnitClosureList);
    //         setBaseData(tempArraryNew)
    //         setBaseData1(tempArraryNew)
    //         setFinalBaseData(tempArraryNew)
    //     }
    // }, [unitClosureList])

    // useEffect(() => {
    //     return () => {
    //         dispatch({
    //             type: UNIT_CLOSURE_LIST,
    //             payload: ['', { unit_closure_units: [] }]
    //         })
    //     }
    // }, [])

    // useEffect(() => {
    //     if (baseData1 && baseData1.length > 0) {
    //         let reactive = _.filter(baseData1, { currentStatus: Number(2) })
    //         setReActivatedArray(reactive)
    //     }

    // }, [baseData1])

    // useEffect(() => {
    //     if (type === 'inactive') {
    //         let inactive = _.filter(baseData, { is_active: Number(1) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'activeData') {
    //         let inactive = _.filter(baseData, { is_active: Number(0) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'close') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(0) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'closed') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(1) })
    //         setFilteredArray(inactive)
    //     }
    //     if (type === 'reactivate') {
    //         let inactive = _.filter(baseData, { currentStatus: Number(2) })
    //         setFilteredArray(inactive)
    //     }

    // }, [baseData, type])


    // const searchRecords = (e) => {
    //     console.log(e, "eeeeeeeeeee")
    //     if (e == 'clear') {
    //         setClearText('')
    //         setBaseData(finalBaseData)
    //         setIconEnable(false)
    //     } else {
    //         setClearText(e.target.value)
    //         if (e.target.value.length > 0) {
    //             setIconEnable(true)
    //         } else {
    //             setIconEnable(false)
    //         }
    //         const filterTable = finalBaseData.filter(o =>
    //             Object.keys(o).some(k =>
    //                 String(o[k])
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase())
    //             )
    //         );
    //         setBaseData(filterTable)
    //     }

    // };


    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-0 pt-0 page-title-sticky">
                        <div className="col-md-12">
                            <div className="row">
                                {/* <div className="col-md-4 ps-1 py-1"> */}
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transactions</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span>Add Unit Code Label</span>
                                            </li>
                                        </ol>
                                    </nav>
                                    {/* </div> */}
                                </div>
                                <div className="col-md-4 pe-1 py-1 text-end">
                                    {/* <div className="search-box" style={{ position: 'absolute', right: '20px', marginRight: '35px', width: '240px', boxShadow: ' 0 5px 5px rgb(0 0 0 / 10%)' }} >
                                        <input className="search-txt" value={clearText} type="text" onChange={searchRecords} placeholder="Type to Search" />
                                        <a className="search-btn" style={{ position: 'absolute', right: 1 }} >
                                            {iconEnable == true ?
                                                <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                : <i className="fas fa-search"></i>}
                                        </a>
                                    </div>*/}
                                    <button onClick={(e) => { fullscreenMode() }} className="bg-transparent mt-1 text-black full-mode fullscreenradius" >
                                        <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginLeft: '1%', marginRight: '1%' }}>
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory m-2 '}>
                            <Collapse className='report-collapse' defaultActiveKey={["1"]} style={{ display: entityid ? "block" : "none" }}  >
                                <Panel header="Unit Closure Statistics" key={baseData && baseData.length > 0 ? 1 : 0} >
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Card className='rounded-3 unitClosure'>
                                                    <h6>Overall Statistics</h6>
                                                    <div className="service-provider-segment">
                                                        <Space direction="vertical">
                                                            <Segmented size='small'
                                                                onChange={(value) => {
                                                                    setType(value)

                                                                }}
                                                                options={[
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('hello');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-book-mark-fill"></i>}
                                                                                />
                                                                                <div>All Data</div>
                                                                                <b>{baseData && baseData.length}</b>
                                                                            </div>
                                                                        ),
                                                                        value: 'active',
                                                                    },

                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                activeData style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-follow-fill"></i>}
                                                                                />
                                                                                <div>Active</div>
                                                                                <b>{inActiveArray && inActiveArray.length}</b>



                                                                            </div>
                                                                        ),
                                                                        value: 'activeData',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('inactive');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-unfollow-fill"></i>}
                                                                                />
                                                                                <div>Inactive</div>
                                                                                <b>{activeArray && activeArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'inactive',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-ban"></i>}
                                                                                />
                                                                                <div>Closed</div>
                                                                                <b>{unBlockedArray && unBlockedArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'closed',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('blocked');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-sync"></i>}
                                                                                />
                                                                                <div>Reactivate</div>
                                                                                <b>{reactiveArray && reactiveArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'reactivate',
                                                                    },
                                                                ]}
                                                            />
                                                        </Space>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-md-6 userCard userCard1" style={{ height: '219px', width: '50%' }}>
                                                <Card className='rounded-3' style={{ minHeight: '172px' }}>
                                                    <h6>Action Needed</h6>
                                                    <div id='service-table-action' className='service-table'>
                                                        <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                            sortDirections={["ascend", "descend", "ascend"]}
                                                            // columns={columns1}
                                                            dataSource={reactivatedArray}
                                                            pagination={false}
                                                        // onChange={tablehandleChange1}
                                                        />
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Card>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor=""><b>Group Name</b> <span style={{ "color": "red" }}>*</span></label>
                                            <Select style={{ marginTop: "5px", width: '60%', marginLeft: '5px' }}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select"
                                                onChange={(data, value) => {
                                                    let temp = _.filter(tabledata && tabledata.groups_entities_list, { client_id: Number(data) })
                                                    console.log(temp, data, 'temptemp');
                                                    setEntity(temp)
                                                    setUnitCode({
                                                        ...unitCode,
                                                        groupid: data ? Number(data) : '',
                                                        groupname: value ? value.children : '',
                                                        leid: '',
                                                        lename: ''
                                                    })
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={unitCode.groupname || undefined}
                                            // display="none"
                                            >
                                                {groupname && groupname.length > 0 && groupname.map((item, i) => {
                                                    return (
                                                        <Option title="" key={item.client_id}>
                                                            {item.group_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {/* {validator.current.message(
                                            'legalentity',
                                            legalentity != "null" ? legalentity : '',
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',
                                                }
                                            })} */}
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-right'>
                                        <div>
                                            <label htmlFor=""><b>Legal Entity Name</b> <span style={{ "color": "red" }}>*</span></label>
                                            <Select style={{ marginTop: "5px", width: '60%', marginLeft: '5px', textAlign: 'left' }}
                                                labelInValue={false}
                                                allowClear={true}
                                                onFocus={false}
                                                onSelect={false}
                                                disabled={unitCode.groupname ? false : true}
                                                placeholder="Select"
                                                onChange={(data, value) => {
                                                    setUnitCode({
                                                        ...unitCode,
                                                        leid: data ? Number(data) : '',
                                                        lename: value ? value.children : ''
                                                    })
                                                    if (data != undefined) {
                                                        getUnitcodeDetails({
                                                            payload: {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetUnitcodeDetails",
                                                                    {
                                                                        "client_id": unitCode.groupid,
                                                                        "legal_entity_id": Number(data)
                                                                    }
                                                                ]
                                                            },
                                                            caller: paramid
                                                        })
                                                    }
                                                }}
                                                showSearch
                                                value={unitCode.lename || undefined}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {entiyty && entiyty.length > 0 && entiyty.map((item) => {
                                                    return (
                                                        <Option title="" key={item.legal_entity_id}>
                                                            {item.legal_entity_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                            {/* {validator.current.message(
                                        'userId',
                                        userId,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'User Required',
                                                regex: 'Select view only restricted'
                                            }
                                        })} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="ant-table-wrapper service-provider-table">
                                    <div className='ant-table ant-table-bordered'>
                                        <div className='ant-table-container'>
                                            <div className={'ant-table-content userprivclass1 ' + localStorage.getItem('currentTheme')}>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className='ant-table-thead table-head-stick '>
                                                        <tr>
                                                            <th className='ant-table-cell' ># Additional Code</th>
                                                            <th className='ant-table-cell' >Label</th>
                                                            <th className='ant-table-cell' ># Additional Code</th>
                                                            <th className='ant-table-cell' >Label</th>
                                                            {/* <th className='ant-table-cell' style={{ width: '150px' }} rowspan="2"> <div className='ms-4 me-2 d-flex align-items-center justify-content-center'><span style={{ marginRight: '10px' }}>Actions<Tooltip
                                                                    placement="top" title={'Blocked/Unblocked, Remove'}>
                                                                    {" "}
                                                                    <InfoCircleOutlined />
                                                                </Tooltip></span>
                                                                </div></th> */}
                                                        </tr>
                                                    </thead>

                                                    {/* {emailvalue && emailvalue.length > 0 ? emailvalue.map((items, i) => {
                                                            return <> */}
                                                    <tbody className='ant-table-tbody'>
                                                        <tr>
                                                            <td className='ant-table-cell'><span><center>1</center></span></td>
                                                            <td className='ant-table-cell'><center>{<div className="position-relative">
                                                                <Input
                                                                    // disabled={items.value == 0 ? true : false}
                                                                    type='text'
                                                                    placeholder='Enter Email ID'
                                                                    maxLength={100}
                                                                    value={labelData.level_position_1}
                                                                    onChange={(e) => {
                                                                        setLabelData({
                                                                            ...labelData,
                                                                            level_position_1: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                                {/* {emailvalue[i].status === true ?
                                                                                <i style={{ top: '2px', right: '3px', borderRadius: '8px', paddingInline: '4px', backgroundColor: 'white' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i>
                                                                                :
                                                                                emailvalue[i].status === false ?
                                                                                    <i style={{ top: '2px', right: '3px', borderRadius: '8px', paddingInline: '4px', backgroundColor: 'white' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                                                    : ''
                                                                            } */}
                                                            </div>}</center>
                                                            </td>
                                                            <td className='ant-table-cell'><span><center>2</center></span></td>
                                                            <td className='ant-table-cell'><center>{<div className="position-relative">
                                                                <Input
                                                                    // disabled={items.value == 0 ? true : false}
                                                                    type='text'
                                                                    placeholder='Enter Email ID'
                                                                    maxLength={100}
                                                                    value={labelData.level_position_2}
                                                                    onChange={(e) => {
                                                                        setLabelData({
                                                                            ...labelData,
                                                                            level_position_2: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>}</center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='ant-table-cell'><span><center>3</center></span></td>
                                                            <td className='ant-table-cell'><center>{<div className="position-relative">
                                                                <Input
                                                                    // disabled={items.value == 0 ? true : false}
                                                                    type='text'
                                                                    placeholder='Enter Email ID'
                                                                    maxLength={100}
                                                                    value={labelData.level_position_3}
                                                                    onChange={(e) => {
                                                                        setLabelData({
                                                                            ...labelData,
                                                                            level_position_3: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>}</center>
                                                            </td>
                                                            <td className='ant-table-cell'><span><center>4</center></span></td>
                                                            <td className='ant-table-cell'><center>{<div className="position-relative">
                                                                <Input
                                                                    // disabled={items.value == 0 ? true : false}
                                                                    type='text'
                                                                    placeholder='Enter Email ID'
                                                                    maxLength={100}
                                                                    value={labelData.level_position_4}
                                                                    onChange={(e) => {
                                                                        setLabelData({
                                                                            ...labelData,
                                                                            level_position_4: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>}</center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    {/* </> */}
                                                    {/* }) : ''} */}
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions popupbtncolour text-center">
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={() => { OnSubmit() }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                {/* <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="card-body p-0 pt-2 service-provider-table">

                                            <Table
                                                key={type === 'active' ? baseData : filteredArray}
                                                id={'unitclosuretableview'}
                                                sortDirections={["ascend", "descend", "ascend"]}
                                                columns={columns}
                                                className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                                size={'small'}
                                                dataSource={list.legalentity_closure}
                                                onChange={tablehandleChange}
                                                bordered
                                                pagination={false}
                                                // scroll={{ x: 1000 }}
                                                showSorterTooltip={false}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                            </Card>
                        </div>
                    </div>

                </div>
                <Modal autoFocus={false} open={modalVisible} footer={null} className='usr_modal_class'
                    onCancel={passwordcancel}
                    maskClosable={false}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                ref={serviceProviderRef1}
                                autoFocus={true}
                                type="password"
                                id="password"
                                name='password'
                                maxLength={'20'}
                                className="form-control"
                                autoComplete='off'
                                placeholder="Enter password"
                                value={modalData.password}
                                style={{ width: '95%' }}
                                onChange={(e) => {
                                    setModalData({
                                        ...modalData,
                                        password: e.target.value
                                    })
                                }} />
                            {formValidator.current.message(
                                'password',
                                modalData.password,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required',
                                    }
                                })}
                        </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <label htmlFor=""><b>Reason</b> <span style={{ "color": "red" }}>*</span></label>
                        <div className='d-flex align-items-end'>

                            <textarea className='form-control passwrd-remark '
                                placeholder="Enter Reason"
                                maxLength={'500'}
                                onKeyPress={(event) => {
                                    if (/[ ~ \ | ^ ]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                // onChange={(e) => {

                                //     setRemarks(e.target.value)
                                // }}
                                onChange={onInputChange}
                                value={remarks}

                            ></textarea>

                            {/* <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => { showRemarksModal() }}>
                                <PlusCircleOutlined />
                            </span> */}
                        </div>
                        {formValidator.current.message(
                            'reason',
                            remarks,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Reason Required',
                                }
                            })}

                    </div>
                    <br />

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}

                            icon={<PlayCircleOutlined />} size='default'
                            onClick={OnSubmited}
                        >
                            Submit
                        </Button>
                        {/* <div className="button2" id={localStorage.getItem('currentTheme')} onClick={OnSubmited}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Submit
                        </div> */}
                    </div>

                </Modal>
            </div>
            <Modal title="Remarks List" open={remarksModal}
                // onOk={handleOk}
                // onCancel={handleCancel} 
                // className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')} footer={false}
                maskClosable={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    // columns={RemarksColumn}
                    // dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    // pagination={{
                    //     pageSizeOptions: ['10', '30', '50'],
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger
                    // }}
                    pagination={false}
                />
            </Modal>
            {/* </div>
        </div> */}
        </Fragment >

    )
}
export default connect(mapStateToProps, {
    getGroupsEntites,
    getUnitcodeDetails,
    saveUnitcode
})(UnitCodeLabel);