import alertmessage from "../../../Libs/Altermessage";
import EncryptDecrypt from "../../../Libs/Encrypt&Decrypt";
import { Toaster } from "../../../Libs/Toaster";
import url from "../../../Libs/URL";
import { decode as base64_decode } from 'base-64';
import HTTP from "../../../Libs/http";
import { ASSIGN_LEGAL_ENTITY, TOGGLE_COMMON_LOADER,ASSIGNED_LEGAL_ENTITY,GET_ASSIGNED_ENTITY_DATA } from "../../types";

export const Assignlegalentity = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/techno`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: ASSIGN_LEGAL_ENTITY ,
                    payload: decryptData[1],
                });

            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const viewAssignedEntity = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/techno`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: ASSIGNED_LEGAL_ENTITY ,
                    payload: decryptData[1],
                });

            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getAssignedEntityData = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/techno`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: GET_ASSIGNED_ENTITY_DATA ,
                    payload: decryptData[1],
                });

            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const submitLegalEntity = ({ payload, executeCancel, caller,tablePayload,setAssignState }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/techno`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            }
            else if(decryptData[0] === 'SaveAssignLegalEntitySuccess'){
                Toaster.success('Legal Entity Assigned Successfully')
                setAssignState(false)
                dispatch(
                    Assignlegalentity({
                        payload: tablePayload,
                        paramid: caller
                    })
                )
            } 
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};