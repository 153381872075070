import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { TECHNO_DETAIL, REJECT_CLIENT_UNIT_DATA, UNITS_UPLOADED_CSV, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()

export const getClientGroupsList = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        console.log(payload, 'payloadpayload');
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/client_units`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decryptDatadecryptData88');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] == "GetClientGroupsListSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: REJECT_CLIENT_UNIT_DATA,
                    payload: decryptData[1],
                });
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getTechnoUserDetails = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        console.log(payload, 'payloadpayload5');
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/client_units`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decryptDatadecryptData88');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] == "GetTechnoDetailsSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: TECHNO_DETAIL,
                    payload: decryptData[1],
                });
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getClientUnitsUploadedCSVFiles = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        console.log(payload, 'payloadpayload5');
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/client_units`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decryptDatadecryptData88');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] == "ClientUnitsUploadedCSVFilesListSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: UNITS_UPLOADED_CSV,
                    payload: decryptData[1],
                });
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};