import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Card, Modal, Empty } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { InfoCircleOutlined, FullscreenOutlined, FilterOutlined, EyeOutlined, UpOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { StatutoryNotificationsFilters, StatutoryNotificationsReportData } from './../../../src/Store/Action/Reports/StatutoryNotificationList';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";


const mapStateToProps = (state) => ({
    statutorynotification: state.StatutoryNotificationList.statutorynotificationlist,
})

const StatutoryNotificationsList = ({
    StatutoryNotificationsFilters,
    StatutoryNotificationsReportData,
    statutorynotification: { statutorylist, statutorylistreportdata }
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [filteredFields, setFilteredFields] = useState({
        filteredDomain: [],
        filteredAct: []
    })
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: '',
        domain_name: '',
        act: '',
        act_name: '',
        from_date: "",
        to_date: "",
    })

    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [page, setPage] = useState(1);
    const [domainName, setDomainName] = useState([]);
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '10px',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * pageSize + index + 1
            }
        },
        {
            title: "Act",
            dataIndex: 'statutory_name',
            width: '60px',
            // ellipsis: true
        },
        {
            title: "Compliance Task",
            dataIndex: false,
            key: false,
            width: '70px',
            // ellipsis: true
            render: (record) => {
                return (
                    <>
                        <Tooltip title={record.description}>
                            <InfoCircleOutlined />
                        </Tooltip> &nbsp;
                        {record.c_task}
                    </>
                )
            },
        },
        {
            title: "Date",
            dataIndex: 'notification_date',
            width: '21px',
            // ellipsis: true,
            align: 'center'

        },
        {
            title: "Notifications content",
            dataIndex: 'notification_text',
            width: '100px',
            // ellipsis: true
        }
    ]

    useEffect(() => {
        if (isAuth) {
            StatutoryNotificationsFilters({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsFilters",
                        {}
                    ]
                }
                ,
                paramid: paramid
            })
        }
    }, [isAuth, legalstate])


    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (unitdata.country) {
            statutorylist.domains && statutorylist.domains.length > 0 && statutorylist.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(unitdata.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name,
                        'product': domainList.product,
                        'is_cmpf': domainList.is_cmpf,
                        'is_ltgs': domainList.is_ltgs,
                        'is_vndr': domainList.is_vndr,
                        'is_active': domainList.is_active
                    }
                    domainArray.push(tempArray)
                }
            })
            console.log(domainArray, 'domainArray')
            setFilteredFields({
                ...filteredFields,
                filteredDomain: domainArray
            })
        }
    }, [unitdata.country])

    useEffect(() => {
        let act = _.filter(statutorylist.level_one_statutories, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        setFilteredFields({
            ...filteredFields,
            filteredAct: act

        })
    }, [unitdata.domain])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (statutorylistreportdata && statutorylistreportdata.statutory_notifictions_list && statutorylistreportdata.statutory_notifictions_list.length > 0) {
            var array = statutorylistreportdata.statutory_notifictions_list.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [statutorylistreportdata.statutory_notifictions_list, pageSize, current])
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const onshow = () => {
        setPageSize(25);
        setCurrent(1);
        setPage(1);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            let domName = _.find(statutorylist.domains && statutorylist.domains.length > 0 && statutorylist.domains, { domain_id: parseInt(unitdata.domain) })
            setDomainName(domName.domain_name);
            setTableShow(true)
            setKey(0)
            StatutoryNotificationsReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsReportData",
                        {
                            "country_id": Number(unitdata.country),
                            "domain_id": Number(unitdata.domain),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "from_date_optional": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date_optional": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "from_count": 0,
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    useEffect(() => {
        if (pageState == true) {
            StatutoryNotificationsReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsReportData",
                        {
                            "country_id": Number(unitdata.country),
                            "domain_id": Number(unitdata.domain),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "from_date_optional": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date_optional": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "from_count": Math.max(((current - 1) * pageSize), 0),
                            "page_count": pageSize
                        }
                    ]
                },
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    return (
        <div id="page-wrapper" className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles py-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Statutory Notification List </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                marginRight: "15px",
                                display: filterTaskAcc ? "none" : "inline",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="custom-collapse">
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Statutory Notification List" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:&nbsp;</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            autoFocus={true}
                                                            onChange={(value, data) => {
                                                                if (data) {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        domain: '',
                                                                        domain_name: '',
                                                                        act: '',
                                                                        act_name: ''
                                                                    })
                                                                } else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: '',
                                                                        countryName: '',
                                                                        domain: '',
                                                                        domain_name: '',
                                                                        act: '',
                                                                        act_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter Country"
                                                            value={unitdata.countryName || undefined}
                                                        >
                                                            {statutorylist.countries && statutorylist.countries.length && statutorylist.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Domain:&nbsp; <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.country == 0 ? true : false}

                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                domain: value,
                                                                domain_name: data.children,
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                domain: '',
                                                                domain_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}

                                                >
                                                    {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Name Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>From Date:&nbsp; <span ></span></label>
                                                <DatePicker allowClear={true} placeholder="DD-MM-YYYY" format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    // disabledDate={(current) => {
                                                    //     return moment().add(0, 'days') <= current;
                                                    // }}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == '' || dateString == null) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: '',
                                                                to_date: ''
                                                            });
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: dateString,
                                                                to_date: ''
                                                            });
                                                        }
                                                    }}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    renderExtraFooter={() => {
                                                        return <Link style={{ float: 'right' }} onClick={(e) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: '',
                                                                to_date: ''
                                                            });
                                                        }}>Clear</Link>;
                                                    }}
                                                    showToday={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>To Date:&nbsp;</b></label>
                                                <DatePicker allowClear={true} placeholder="DD-MM-YYYY" format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == '' || dateString == null) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: dateString
                                                            })
                                                        }
                                                    }}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(0, 'days') >= current
                                                    }}
                                                    renderExtraFooter={() => {
                                                        return <Link style={{ float: 'right' }} onClick={(e) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: ''
                                                            })
                                                        }}>Clear</Link>
                                                    }}
                                                    showToday={false}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Act:&nbsp;</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                act: value,
                                                                act_name: data.children
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                        return (
                                                            <Option key={item.level_1_statutory_id}>
                                                                {item.level_1_statutory_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12' style={{ marginTop: "10px", textAlign: 'center', marginLeft: '35%' }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    icon={<EyeOutlined />}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title="Statutory Notification List"
                                className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                // style={{overflowX: 'hidden'}}
                                footer={false}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country:&nbsp;</b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        onChange={(value, data) => {
                                                            if (data) {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: ''
                                                                })
                                                            } else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: '',
                                                                    countryName: '',
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter Country"
                                                        value={unitdata.countryName || undefined}
                                                    >
                                                        {statutorylist.countries && statutorylist.countries.length && statutorylist.countries.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        unitdata.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Domain:&nbsp; <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                size="default"
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={unitdata.domain_name || undefined}
                                                disabled={unitdata.country == 0 ? true : false}

                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: '',
                                                            domain_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}
                                                placeholder="Enter Domain Name"
                                                style={{ width: '100%' }}

                                            >
                                                {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                    return (
                                                        <Option key={item.domain_id}>
                                                            {item.domain_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                unitdata.domain_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Name Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>From Date:&nbsp; <span ></span></label>
                                            <DatePicker getPopupContainer={trigger => trigger.parentNode} allowClear={true} placeholder='DD-MM-YYYY' format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '' || dateString == null) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: ''
                                                        })
                                                    }
                                                }}
                                                onKeyDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                                renderExtraFooter={() => {
                                                    return <Link style={{ float: 'right' }} onClick={(e) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    }}>Clear</Link>
                                                }}
                                                showToday={false}
                                            // disabledDate={(current) => {
                                            //     return moment().add(0, 'days') <= current
                                            // }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "10px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>To Date:&nbsp;</b></label>
                                            <DatePicker getPopupContainer={trigger => trigger.parentNode} allowClear={true} placeholder='DD-MM-YYYY' format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '' || dateString == null) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString
                                                        })
                                                    }
                                                }}
                                                onKeyDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                                disabledDate={(current) => {
                                                    return moment(unitdata.from_date).add(0, 'days') >= current
                                                }}
                                                renderExtraFooter={() => {
                                                    return <Link style={{ float: 'right' }} onClick={(e) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: ''
                                                        })
                                                    }}>Clear</Link>
                                                }}
                                                showToday={false}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Act:&nbsp;</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.act_name || undefined}
                                                disabled={unitdata.domain == 0 ? true : false}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })                                                        
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}
                                                placeholder="Enter Act"
                                                style={{ width: '100%' }}
                                            >
                                                {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                    return (
                                                        <Option key={item.level_1_statutory_id}>
                                                            {item.level_1_statutory_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12' style={{ marginTop: "10px", textAlign: 'center', marginLeft: '35%' }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                icon={<EyeOutlined />}
                                                onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Modal>
                            {tableshow == true ?
                                <>

                                    <Card className='rounded-3'>
                                        <div className='d-flex justify-content-around filter-detial-sticky'>
                                            <div><b>Country</b> : {unitdata.countryName}</div>
                                            <div ><b>Domain</b> : {domainName}</div>
                                        </div>
                                        <div className='pt-0 service-provider-table'>
                                            <Table size={'small'}
                                                locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                                                className={'notificationList'}
                                                columns={columns}
                                                bordered
                                                // scroll={{ y: 400 }}
                                                dataSource={statutorylistreportdata.statutory_notifictions_list}
                                                pagination={false}
                                            />
                                        </div>
                                        {statutorylistreportdata && statutorylistreportdata.statutory_notifictions_list && statutorylistreportdata.statutory_notifictions_list.length > 0 ?
                                            <div className='col-lg-12' style={{ paddingLeft: '10px' }}>
                                                <div className='row mt-3'>
                                                    <div className='col-lg-6' style={{ paddingTop: '3px' }}>
                                                        Showing {(current - 1) * pageSize + 1} to {statutorylistreportdata.total_count > current * pageSize ? current * pageSize : statutorylistreportdata.total_count} of {statutorylistreportdata.total_count} entries
                                                    </div>
                                                    {statutorylistreportdata.total_count > 25 ?
                                                        <div className='col-lg-6'>
                                                            <Pagination style={{ padding: '10px', textAlign: "right" }}
                                                                current={current}
                                                                showSizeChanger={true}
                                                                showQuickJumper={false}
                                                                onShowSizeChange={() => {
                                                                    setCurrent(1)
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]}
                                                                hideOnSinglePage={false}
                                                                defaultPageSize={25}
                                                                onChange={(page, pageSizes) => {
                                                                    setpageState(true)
                                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                    setPageSize(pageSizes)
                                                                    setPage(page);
                                                                }} total={statutorylistreportdata.total_count} />
                                                        </div> : ''}
                                                </div>
                                            </div> : ''}
                                    </Card>
                                </>
                                : false}
                        </div>
                    </div>
                </div>
            </div >
        </div>

    )
}
export default connect(mapStateToProps, {
    StatutoryNotificationsFilters,
    StatutoryNotificationsReportData
})(StatutoryNotificationsList);