import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


const SideNav = () => {
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
  const menu = Object.values(sessionValue.login_response.menu.menus)
  // //const menu = sessionValue.login_response.menu
  //console.log(sessionValue.login_response.menu.menus,'menu')
  return (
    <Fragment>
      <aside className="left-sidebar" data-sidebarbg={"skin9"}>
        <div className="scroll-sidebar h-100">
          <nav className="sidebar-nav mt-3">
            <ul id="sidebarnav" className='px-1'>
              {menu.length > 0 && menu.map((menuList, c_s_Id) => {
                const arr = [];
                menuList.map(function (user) {
                  if ((arr.indexOf(user.parent_menu) < 0))
                    arr.push(user.parent_menu);
                });
                switch (menuList[0].form_type) {
                  case 'Dashboard':
                    var featherIcon = 'globe'
                    break;
                  case 'Home':
                    var featherIcon = 'home'
                    break;
                  case 'Master':
                    var featherIcon = 'grid'
                    break;
                  case 'Transaction':
                    var featherIcon = 'file-text'
                    break;
                  case 'Report':
                    var featherIcon = 'archive'
                    break;
                  default:
                    var featherIcon = 'list'
                    break;
                }
                if (menuList[0].form_type !== 'My Accounts' && menuList[0].form_type !== 'Static')
                  return <li className="sidebar-item" key={c_s_Id}>
                    <Link
                      className="sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent"
                      id={"c_s_Id" + c_s_Id}
                      aria-expanded="false"
                    ><i data-feather={featherIcon}></i>
                      <span className="hide-menu" >{menuList[0].form_type}</span></Link>
                    <ul className="collapse first-level subul">
                      {
                        arr.map((pmenu, pId) => {
                          if (pmenu) {
                            return <li className="sidebar-item" title={pmenu} key={pId}>
                              <Link className={"sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent"} id={"pId_" + pId}
                              ><i className="ri-indeterminate-circle-line"></i>
                                <span className="hide-menu fs-3" >{pmenu} </span></Link>
                              <ul className="collapse first-level subul">
                                {menuList && menuList.map((subMenuList, childIds) => {
                                  if (pmenu === subMenuList.parent_menu) {
                                    return <li className="sidebar-item" title={subMenuList.form_name} key={childIds}>
                                      <Link to={subMenuList.form_url} className={"sidebar-link px-1 py-1"} id={"subchild_" + childIds + "_" + subMenuList.form_id} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3" >{subMenuList.form_name} </span></Link>
                                    </li>
                                  }
                                })
                                }
                              </ul>
                            </li>

                          } else {
                            return menuList && menuList.map((subMenuList, childId) => {
                              if (subMenuList.parent_menu == null) {
                                return (<li className="sidebar-item" title={subMenuList.form_name}>
                                  <Link to={subMenuList.form_url} id={"child_" + childId + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                    localStorage.setItem('formkey', subMenuList.form_key)
                                  }}
                                  ><i className="ri-indeterminate-circle-line"></i>
                                    <span className="hide-menu fs-3">{subMenuList.form_name}</span></Link>
                                </li>)
                              }
                            })
                          }
                        })
                      }

                    </ul>
                  </li>
              })}
            </ul>
          </nav>
        </div>
      </aside>

    </Fragment>
  )
};
export default connect(null)(SideNav);