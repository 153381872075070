import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { GET_CLIENT_UNIT_FILTER, GET_CLIENT_UNIT_LIST, GET_CLIENT_ADD_UNIT_LIST, CLIENT_UNIT_UNIT_CODE } from '../../types/index'

const initialState = {
    clientUnit: {
        list: [],
        filterValue: [],
        unitList: [],
        unitCodes: []

    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CLIENT_UNIT_FILTER:
            {
                return {
                    ...state,
                    clientUnit: {
                        ...state.clientUnit,
                        filterValue: payload,
                    },
                };
            }
        case GET_CLIENT_ADD_UNIT_LIST:
            {
                return {
                    ...state,
                    clientUnit: {
                        ...state.clientUnit,
                        unitList: payload,
                    },
                };
            }
        case CLIENT_UNIT_UNIT_CODE:
            {
                return {
                    ...state,
                    clientUnit: {
                        ...state.clientUnit,
                        unitCodes: payload,
                    },
                };
            }
        case GET_CLIENT_UNIT_LIST:
            {
                return {
                    ...state,
                    clientUnit: {
                        ...state.clientUnit,
                        list: payload,
                    },
                };
            }

        default:
            return state;
    }
}