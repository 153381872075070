import { PRODUCT_DOMAIN_LIST, COMPLIANCE_ASSIGN_STATUTORY_FOR_APPROVE, DOMAIN_EXE_DETAILS, FILTER_COMPLIANCE } from '../../types/index';

const initialState = {
    productDomain: [],
    complianceForApprove: [],
    domainExecutive: [],
    filterCompliance: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_DOMAIN_LIST:
            {
                return {
                    ...state,
                    productDomain: payload,
                };
            }

        case COMPLIANCE_ASSIGN_STATUTORY_FOR_APPROVE:
            {
                return {
                    ...state,
                    complianceForApprove: payload,
                };
            }

        case DOMAIN_EXE_DETAILS:
            {
                return {
                    ...state,
                    domainExecutive: payload,
                };
            }

        case FILTER_COMPLIANCE:
            {
                return {
                    ...state,
                    filterCompliance: payload,
                };
            }

        default:
            return state;
    }
}
