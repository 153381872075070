import { DEASSIGNSTATUTORYFILTER, DEASSIGNSTATUTORYOTHERFILTER, GETASSIGNEDSTAUTORIES, ASSIGNED_STATUTORY_COUNT, ASSIGNED_STATUTORY_TWO_DATA, ASSIGNED_STATUTORY_ADD_OPTION, ASSIGNED_STATUTORY_ADD_UNITS, SAVE_ASSIGNED_STATUTORY, REMARK_STATE, ASSIGNED_STATUTORY_ADD_TWO_COUNT, ASSIGNED_STATUTORY_ADD_TWO_DATA, SUBMIT_ASSIGNED_STATUTORY, ASSIGNED_STATUTORY_ADD_TWO_DATA_MULTIPLE } from '../../types/index'
const initialState = {
    deassignstatutory: {
        deassignstatutoryfilter: [],
        deassignstatutoryotherfilter: [],
        getassignedstatutories: [],
        assignedStatutoryCount: [],
        assignedStatutoryTwoData: [],
        ASTwoDataReceivedCount: [],
        addOption: [],
        addUnitsList: [],
        SaveAssignedStatutory: [],
        remarkState: {},
        addTwoCount: [],
        addTwoData: [],
        SubmitStatus: [],
        addMultipleUnitTable: []
    },
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DEASSIGNSTATUTORYFILTER:
            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    getassignedstatutories: [],
                    deassignstatutoryfilter: payload,
                },
            };
        case DEASSIGNSTATUTORYOTHERFILTER:
            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    deassignstatutoryotherfilter: payload,
                },
            };
        case GETASSIGNEDSTAUTORIES:
            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    getassignedstatutories: payload
                }
            }
        case ASSIGNED_STATUTORY_COUNT:
            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    addTwoCount: [],
                    assignedStatutoryCount: payload
                }
            }

        case ASSIGNED_STATUTORY_TWO_DATA:
            const dataLength = payload.statutories_for_assigning.length;
            const ruleTypes = payload.statutories_for_assigning.map(item => item["level_1_s_name"]);
            const types = ruleTypes.filter((item,index) => ruleTypes.indexOf(item) === index);
            const data = []; 
            types.map(item => {
                let filteredArr = payload.statutories_for_assigning.filter(types => item == types["level_1_s_name"]);
                let actFilter = filteredArr.map(item => item.map_text);
                let actTypes = actFilter.filter((item, index) => actFilter.indexOf(item) === index);
                const actArr= [];
                const finalArray = [];
                actTypes.map(act => {
                    actArr.length = 0;
                    actArr.push({title: true, s_provision: act});
                    let temp = filteredArr.filter(item => act == item.map_text);
                    let temp2 = [...actArr, ...temp];
                    finalArray.push(...temp2);
                })
                data.push(finalArray);
            })
            
            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    SaveAssignedStatutory: [],
                    ASTwoDataReceivedCount: dataLength,
                    assignedStatutoryTwoData: data
                }
            }

            case ASSIGNED_STATUTORY_ADD_OPTION:
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        addOption: payload
                    }
            }

            case ASSIGNED_STATUTORY_ADD_UNITS:
                let temp = {}
                payload.statu_units.map(item => {
                    temp[item.u_id] = {
                        location: item['g_name'],
                        unitVendor: item['unit_code'] + ' - ' + item['u_name'] + ',' + item['address'],
                        clientStatutoryId: item['client_statutory_id'],
                        unitName: item['u_name']
                    }
                })

                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        addUnitsList: payload,
                        addMultipleUnitTable: temp
                    }
            }

            case SAVE_ASSIGNED_STATUTORY:
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        SaveAssignedStatutory: payload
                    }
            }

            case SUBMIT_ASSIGNED_STATUTORY:
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        SubmitStatus: payload
                    }
            }

            case REMARK_STATE:
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        remarkState: payload
                    }
            }

            case ASSIGNED_STATUTORY_ADD_TWO_COUNT:
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        assignedStatutoryCount : [],
                        addTwoCount: payload
                    }
            }

        case ASSIGNED_STATUTORY_ADD_TWO_DATA:
            const addDataLength = payload.statutories_for_assigning.length;
            const addRuleTypes = payload.statutories_for_assigning.map(item => item["level_1_s_name"]);
            const addTypes = addRuleTypes.filter((item, index) => addRuleTypes.indexOf(item) === index);
            const addData = [];
            addTypes.map(item => {
                let filteredArr = payload.statutories_for_assigning.filter(types => item == types["level_1_s_name"]);
                let actFilter = filteredArr.map(item => item.map_text);
                let actTypes = actFilter.filter((item, index) => actFilter.indexOf(item) === index);
                const actArr = [];
                const finalArray = [];
                actTypes.map(act => {
                    actArr.length = 0;
                    actArr.push({ title: true, s_provision: act });
                    let temp = filteredArr.filter(item => act == item.map_text);
                    let temp2 = [...actArr, ...temp];
                    finalArray.push(...temp2);
                });
                addData.push(finalArray);
            });

            return {
                ...state,
                deassignstatutory: {
                    ...state.deassignstatutory,
                    ASTwoDataReceivedCount: addDataLength,
                    addTwoData: addData
                }
            }

            case ASSIGNED_STATUTORY_ADD_TWO_DATA_MULTIPLE:
                // const addDataLengthMultiple = payload.statutories_for_multiple.length;
                // const addRuleTypesMultiple = payload.statutories_for_multiple.map(item => item["level_1_s_name"]);
                // const addTypesMultiple = addRuleTypesMultiple.filter((item, index) => addRuleTypesMultiple.indexOf(item) === index);
                // const addDataMultiple = [];
                // addTypesMultiple.map(item => {
                //     let filteredArr = payload.statutories_for_multiple.filter(types => item == types["level_1_s_name"]);
                //     let actFilter = filteredArr.map(item => item.map_text);
                //     let actTypes = actFilter.filter((item, index) => actFilter.indexOf(item) === index);
                //     const actArr = [];
                //     const finalArray = [];
                //     actTypes.map(act => {
                //         actArr.length = 0;
                //         actArr.push({ title: true, s_provision: act });
                //         let temp = filteredArr.filter(item => act == item.map_text);
                //         let temp2 = [...actArr, ...temp];
                //         finalArray.push(...temp2);
                //     });
                //     addDataMultiple.push(finalArray);
                // });

                const addDataLengthMultiple = payload.statutories_for_multiple.length;
                const addRuleTypesMultiple = payload.statutories_for_multiple.map(item => item["level_1_s_name"]);
                const addTypesMultiple = addRuleTypesMultiple.filter((item, index) => addRuleTypesMultiple.indexOf(item) === index);
                const addDataMultiple = [];
                addTypesMultiple.map(item => {
                    let filteredArr = payload.statutories_for_multiple.filter(types => item == types["level_1_s_name"]);
                    let actFilter = filteredArr.map(item => item.map_text);
                    let actTypes = actFilter.filter((item, index) => actFilter.indexOf(item) === index);
                    const actArr = [];
                    const finalArray = [];
                    actTypes.map(act => {
                        actArr.length = 0;
                        actArr.push({ title: true, s_provision: act });
                        let temp = filteredArr.filter(item => act == item.map_text);
                        let fldata = temp.map(item => {
                            return {
                                ...item,
                                applicable_units: item['applicable_units'].map(val => {
                                    let id = val['u_id']
                                    return {
                                        ...val,
                                        ...state.deassignstatutory.addMultipleUnitTable[id],
                                        compId: item['comp_id'],
                                        levelId: item['level_1_s_id']
                                    }
                                }) 
                            };
                        })
                        // let temp2 = [...actArr, ...temp];
                        let temp2 = [...actArr, ...fldata];
                        finalArray.push(...temp2);
                    });
                    addDataMultiple.push(finalArray);
                });
    
                return {
                    ...state,
                    deassignstatutory: {
                        ...state.deassignstatutory,
                        ASTwoDataReceivedCount: addDataLengthMultiple,
                        addTwoData: addDataMultiple
                        // addTwoData: payload.statutories_for_multiple
                    }
                }

        default:
            return state;
    }
}