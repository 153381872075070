import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Popconfirm, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import { InfoCircleOutlined, ArrowLeftOutlined, CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { unitviewlist } from '../../Store/Action/Transactions/Assignclientunit'
import { fullscreenexit, fullscreenmode } from './../../Libs/fullscreenmode';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    viewlist: state.Assignclientunit.assignclient.viewlist,
    list: state.Assignclientunit.assignclient.list,
    unitlist: state.Assignclientunit.assignclient.unitlist
})
const AssignClientUnitView = ({
    viewlist,
    list,
    unitviewlist,
    unitlist,
    fullscreen,
    setfullscreen,
    recordData
}) => {
    const dispatch = useDispatch()
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [modalVisible, setmodalVisible] = useState(false);
    const [domainexeid, setdomainexeid] = useState({
        exename: '',
        domainname: ''
    });
    const [userfullscreen, setuserfullscreen] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [domainData, setDomainData] = useState([])
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };
    const [searchingValue, setSearchingValue] = useState([])
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const [searchedValue, setSearchedValue] = useState({
        unitCode: '',
        unitName: '',
        product: '',
        orgType: '',
        unitLocation: '',
    })
    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };


    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo(filters);
        // setSortedInfo(sorter);
    };

    const columns = [
        {
            title: "#",
            width: '40px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "Domain Executive",
            dataIndex: "employee_name",
            key: "employee_name",
            ...getColumnSearchProps('employee_name', 'Domain Executive'),
            filteredValue: filteredInfo.employee_name || null,
            ellipsis: true,
            width: '170px',
        },
        {
            title: "Domain",
            dataIndex: "domain_name",
            key: "domain_name",
            ...getColumnSearchProps('domain_name', 'Domain'),
            filteredValue: filteredInfo.domain_name || null,
            ellipsis: true,
            width: '140px',

        },
        {
            title: "Group",
            dataIndex: "group_name",
            key: "group_name",
            ...getColumnSearchProps('group_name', 'Group'),
            filteredValue: filteredInfo.group_name || null,
            ellipsis: true,
            width: '100px',

        },
        {
            title: "Business Group",
            dataIndex: "business_group_name",
            key: "business_group_name",
            ...getColumnSearchProps('business_group_name', 'Business Group'),
            filteredValue: filteredInfo.business_group_name || null,
            width: '100px',
            align: 'center'
        },
        {
            title: "Legal Entity",
            dataIndex: "legal_entity_name",
            key: "legal_entity_name",
            ...getColumnSearchProps('legal_entity_name', 'Legal Entity'),
            filteredValue: filteredInfo.legal_entity_name || null,
            ellipsis: true,
            width: '150px',
        },
        {
            title: "No. of Unit(s)",
            dataIndex: "unit_count",
            width: '90px',
            align: 'center'
        },
        {
            title: "No. of Vendor(s)",
            dataIndex: "vndr_unit_count",
            key: "vndr_unit_count",
            width: '90px',
            align: 'center'
        },
        {
            title: "View",
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return <span style={{ margin: '0 auto' }} className="btn btn-sm btn-light-primary
  text-primary btn-circle d-flex align-items-center justify-content-center" onClick={() => { showviewlist(record) }}>
                    <Tooltip title='View Domain Executive'>
                        <i className='fas fa-eye'></i></Tooltip>
                </span>
            },
        },
    ]

    useEffect(() => {
        if (viewlist && viewlist.assigned_units_list) {
            let tempArr = []
            for (let i in viewlist.assigned_units_list) {
                tempArr.push({
                    "user_id": viewlist.assigned_units_list[i].user_id,
                    "employee_name": viewlist.assigned_units_list[i].employee_name,
                    "business_group_name": viewlist.assigned_units_list[i].business_group_name,
                    "legal_entity_id": viewlist.assigned_units_list[i].legal_entity_id,
                    "legal_entity_name": viewlist.assigned_units_list[i].legal_entity_name,
                    "unit_count": viewlist.assigned_units_list[i].unit_count,
                    "vndr_unit_count": viewlist.assigned_units_list[i].vndr_unit_count,
                    "user_category_id": viewlist.assigned_units_list[i].user_category_id,
                    "client_id": viewlist.assigned_units_list[i].client_id,
                    "domain_id": viewlist.assigned_units_list[i].domain_id,
                    "domain_name": recordData && recordData.domain_name,
                    "group_name": recordData && recordData.domain_name
                })
            }
            console.log(tempArr, 'tempArrrrrrr');
            setDomainData(tempArr)
        }

    }, [viewlist && viewlist.assigned_units_list, recordData])

    console.log(viewlist && viewlist.assigned_units_list, 'viewlist && viewlist.assigned_units_list');

    const showviewlist = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedUnitDetails",
                {
                    "legal_entity_id": record.legal_entity_id,
                    "user_id": record.user_id,
                    "client_id": record.client_id,
                    "domain_id": record.domain_id
                }
            ]
        }
        unitviewlist({
            payload: pay,
            caller: paramid
        })
        setmodalVisible(true)
    }

    const modalcancel = () => {
        setmodalVisible(false);
    }

    const fullscreenMode = () => {
        if (fullscreen == true) {
            fullscreenmode(dispatch);
            setfullscreen(false);
            setuserfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(true);
            setuserfullscreen(false);
        }
    }

    useEffect(() => {
        if (fullscreen == true) {
            fullscreenMode(dispatch)

        }
    }, [])



    useEffect(() => {
        if (list && viewlist) {
            let domainexe = _.filter(list.unassigned_units_list, { domain_id: viewlist.assigned_units_list && viewlist.assigned_units_list.length > 0 && viewlist.assigned_units_list[0].domain_id });
            let domain = _.filter(list.unassigned_units_list, { client_id: viewlist.assigned_units_list && viewlist.assigned_units_list.length > 0 && viewlist.assigned_units_list[0].client_id });
            setdomainexeid({
                ...domainexeid,
                exename: domainexe,
                domainname: domain
            })
        }
    }, [list, viewlist])

    const [finalvalue, setfinalvalue] = useState();
    const [finalTemp, setFinalTemp] = useState()
    const [finalTemp1, setFinalTemp1] = useState()
    console.log(finalvalue, 'finalvalue')
    const [parentvalue, setparentvalue] = useState();
    useEffect(() => {
        if (unitlist && unitlist.assigned_unit_details_list) {
            let detailslist
            let arr = [];
            let temparr = []
            for (let i = 0; i < unitlist.assigned_unit_details_list.length; i++) {
                if (unitlist.assigned_unit_details_list[i]['category_name'] == null) {
                    unitlist.assigned_unit_details_list[i]['category_name'] = 'Unassigned';
                }
                arr.push(unitlist.assigned_unit_details_list[i].category_name);
            }
            const temp = {};
            for (const array of arr) {
                temp[array] = true;
            }

            const uniquevalue = [];
            for (const tempvalue in temp) {
                let value = _.find(unitlist.assigned_unit_details_list, { category_name: tempvalue })
                uniquevalue.push({
                    'category_name': value.category_name,
                    'legal_entity_name': value.legal_entity_name,
                    'division_name': value.division_name
                });
            }
            setparentvalue(uniquevalue);
            const finalArrayParent = [...new Set(arr)];
            let entitytemparr = [];
            let finalTempArr = [];
            for (let k = 0; k < finalArrayParent.length; k++) {
                let finalValue = _.filter(unitlist.assigned_unit_details_list, { category_name: finalArrayParent[k] })
                finalTempArr.push(finalValue)

            }

            console.log(finalTempArr, 'temparrtemparr')
            setfinalvalue([].concat.apply([], finalTempArr))
            setFinalTemp1([].concat.apply([], finalTempArr))
        }
    }, [unitlist])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (finalTemp1 && finalTemp1.length > 0) {
            let temp = []
            for (let i in finalTemp1) {
                temp.push({
                    "unit_id": finalTemp1[i].unit_id,
                    "legal_entity_name": finalTemp1[i].legal_entity_name,
                    "division_name": finalTemp1[i].division_name,
                    "category_name": finalTemp1[i].category_name,
                    "unit_code": finalTemp1[i].unit_code,
                    "unit_name": finalTemp1[i].unit_name,
                    "address": finalTemp1[i].address,
                    "domain_names": [
                        "Corporate Finance"
                    ],
                    "org_names_list": [
                        [
                            "Commercial Establishments"
                        ]
                    ],
                    "geography_name": finalTemp1[i].geography_name,
                    "product": finalTemp1[i].product,
                    "domain_name": finalTemp1[i].domain_names && finalTemp1[i].domain_names.length > 0 ? finalTemp1[i].domain_names[0] : '',
                    "org_name": finalTemp1[i].org_names_list && finalTemp1[i].org_names_list.length > 0 ? finalTemp1[i].org_names_list[0][0] : '',
                    "domainOrgName": `${finalTemp1[i].domain_names && finalTemp1[i].domain_names.length > 0 ? finalTemp1[i].domain_names[0] : ''}-${finalTemp1[i].org_names_list && finalTemp1[i].org_names_list.length > 0 ? finalTemp1[i].org_names_list[0][0] : ''}`
                })
            }
            console.log(temp, 'temp');
            setfinalvalue(temp)
            setFinalTemp(temp)
        }

    }, [finalTemp1])

    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = finalvalue
        console.log(searchingValue['Criticality'], 'lllllllllll');
        if (searchingValue['unitCode']) {
            filterTable = filterTable.filter((item) => {
                if ((item["unit_code"].toLowerCase().includes(searchingValue['unitCode'].toLowerCase())) || (item["unit_code"].toLowerCase().includes(searchingValue['unitCode'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['unitName']) {
            filterTable = filterTable.filter((item) => {
                if ((item["unit_name"].toLowerCase().includes(searchingValue['unitName'].toLowerCase())) || (item["unit_name"].toLowerCase().includes(searchingValue['unitName'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['product']) {
            filterTable = filterTable.filter((item) => {
                if ((item["product"].toLowerCase().includes(searchingValue['product'].toLowerCase())) || (item["product"].toLowerCase().includes(searchingValue['product'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['orgType']) {
            filterTable = filterTable.filter((item) => {
                if ((item["domainOrgName"].toString().toLowerCase().includes(searchingValue['orgType'].toLowerCase())) || (item["domainOrgName"].toString().toLowerCase().includes(searchingValue['orgType'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['unitLocation']) {
            filterTable = filterTable.filter((item) => {
                if ((item["geography_name"].toLowerCase().includes(searchingValue['unitLocation'].toLowerCase())) || (item["geography_name"].toLowerCase().includes(searchingValue['unitLocation'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['domain']) {
            filterTable = filterTable.filter((item) => {
                if ((item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase())) || (item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase()))) {
                    return true;
                }
            })
        }
        console.log(filterTable, 'filterTablefilterTable');
        setFinalTemp(filterTable)

    };

    return (
        <div id='page-wrapper' className="page-wrapper">
            <div className="page-titles py-2 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        {/* <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link><b>Assign Client Unit/Vendor</b></Link>
                                </li>
                            </ol>
                        </nav> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Assign Client Unit/Vendor </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center 
                        justify-content-end">
                        <Button
                            type="primary"
                            shape="round"
                            className='addbutton'
                            style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "10px" }}
                            icon={<ArrowLeftOutlined />}
                            size='default'
                            onClick={() => { window.location.reload() }}>
                            Previous
                        </Button>

                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0 service-provider-table">
                <div className="row" >
                    <div className="col-12">
                        <div className="card">
                            <div className='card-body' style={{ padding: '0px' }}>
                                <Table
                                    className='userprivclass'
                                    columns={columns}
                                    dataSource={domainData}
                                    bordered
                                    pagination={false}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Assigned Client Unit/Vendor" open={modalVisible} footer={null} maskClosable={false} className={"Assignunit add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                onCancel={modalcancel}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label><b>Group:&nbsp;&nbsp;</b></label>
                                    {domainexeid && domainexeid.domainname != '' && domainexeid.domainname && domainexeid.domainname.length > 0 && domainexeid.domainname[0].group_name}


                                </div>
                                <div className='col-md-6'>
                                    <label><b>Domain:&nbsp;</b></label>
                                    {domainexeid && domainexeid.exename != '' && domainexeid.exename && domainexeid.exename.length > 0 && domainexeid.exename[0].domain_name}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label><b>Business Group:&nbsp;&nbsp;&nbsp;</b></label>
                                    {viewlist && viewlist.assigned_units_list && viewlist.assigned_units_list.length > 0 && viewlist.assigned_units_list[0].business_group_name}
                                </div>
                                <div className='col-md-6'>
                                    <label><b>Legal Entity:&nbsp;&nbsp;&nbsp;</b></label>
                                    {viewlist && viewlist.assigned_units_list && viewlist.assigned_units_list.length > 0 && viewlist.assigned_units_list[0].legal_entity_name}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                            <div className='ant-table-container'>
                                <div className='ant-table-content userprivclass1'>
                                    <table style={{ tableLayout: 'auto' }} className={"table-fixed"}>
                                        <thead className='ant-table-thead'>
                                            <tr>
                                                {/* <th className='ant-table-cell'>Unit/Vendor Code</th>
                                                <th className='ant-table-cell'><center>Unit/Vendor Name</center></th>
                                                <th className='ant-table-cell'><center>Product</center></th>
                                                <th className='ant-table-cell'><center>Organization Type</center></th>
                                                <th className='ant-table-cell'><center>Unit/Vendor Location</center></th> */}
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['business_group'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Unit/Vendor Code
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef1);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef1}
                                                                        placeholder="Unit/Vendor Code"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                unitCode: e.target.value
                                                                            });
                                                                            searchingValue['unitCode'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.unitCode || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "unitCode",
                                                                                "unitCode"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef1.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            unitCode: ''
                                                                        });
                                                                        searchingValue['unitCode'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "unitCode",
                                                                            "unitCode"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined className='assigClient'
                                                                style={{ float: 'right', marginTop: '4px', color: 'black' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['unitName'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Unit/Vendor Name
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef2);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef2}
                                                                        placeholder="Unit/Vendor Name"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                unitName: e.target.value
                                                                            });
                                                                            searchingValue['unitName'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.unitName || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "unitName",
                                                                                "unitName"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef2.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            unitName: ''
                                                                        });
                                                                        searchingValue['unitName'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "unitName",
                                                                            "unitName"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined
                                                                style={{ float: 'right', marginTop: '4px' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['product'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Product
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef3);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef3}
                                                                        placeholder="Product"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                product: e.target.value
                                                                            });
                                                                            searchingValue['product'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.product || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "product",
                                                                                "product"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef3.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            product: ''
                                                                        });
                                                                        searchingValue['product'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "product",
                                                                            "product"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined
                                                                style={{ float: 'right', marginTop: '4px' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['orgType'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Organization Type
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef4);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef4}
                                                                        placeholder="Organization Type"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                orgType: e.target.value
                                                                            });
                                                                            searchingValue['orgType'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.orgType || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "orgType",
                                                                                "orgType"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef4.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            orgType: ''
                                                                        });
                                                                        searchingValue['orgType'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "orgType",
                                                                            "orgType"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined
                                                                style={{ float: 'right', marginTop: '4px' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['domain'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Domain
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef6);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef6}
                                                                        placeholder="Domain"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                domain: e.target.value
                                                                            });
                                                                            searchingValue['domain'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.orgType || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "domain",
                                                                                "domain"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef6.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            domain: ''
                                                                        });
                                                                        searchingValue['domain'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "domain",
                                                                            "domain"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined
                                                                style={{ float: 'right', marginTop: '4px' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                                <th
                                                    className="ant-table-cell"
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p
                                                            className={"p-0 mb-0"}
                                                            style={{
                                                                // marginLeft: "36%",
                                                                display: "inline",
                                                                color: searchingValue['unitLocation'] ? 'red' : ''
                                                            }}
                                                        >
                                                            Unit/Vendor Location
                                                        </p>{" "}
                                                        <Popconfirm
                                                            afterVisibleChange={() => {
                                                                setFocus(myRef5);
                                                            }}
                                                            placement="bottom"
                                                            title={
                                                                <div className='position-relative'>
                                                                    <Input
                                                                        ref={myRef5}
                                                                        placeholder="Unit/Vendor Location"
                                                                        autoFocus={'on'}
                                                                        onChange={(e) => {
                                                                            setSearchedValue({
                                                                                ...searchedValue,
                                                                                unitLocation: e.target.value
                                                                            });
                                                                            searchingValue['unitLocation'] = e.target.value
                                                                        }}
                                                                        value={searchedValue.unitLocation || undefined}
                                                                        onKeyUp={(e) => {
                                                                            compliancetaskfilter(
                                                                                e.target.value,
                                                                                "unitLocation",
                                                                                "unitLocation"
                                                                            );

                                                                        }}
                                                                    />
                                                                    <CloseCircleOutlined onClick={() => {
                                                                        myRef5.current.value = "";
                                                                        setSearchedValue({
                                                                            ...searchedValue,
                                                                            unitLocation: ''
                                                                        });
                                                                        searchingValue['unitLocation'] = ''
                                                                        compliancetaskfilter(
                                                                            '',
                                                                            "unitLocation",
                                                                            "unitLocation"

                                                                        );

                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                </div>
                                                            }
                                                        >
                                                            <SearchOutlined
                                                                style={{ float: 'right', marginTop: '4px' }}
                                                            />
                                                        </Popconfirm>
                                                    </div>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='ant-table-tbody'>
                                            {
                                                parentvalue && parentvalue.length > 0 && parentvalue.map((item) => {
                                                    return <>
                                                        <tr className="bg-lightdark">
                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                    <div className="col-md-12">
                                                                        <div className='row m-2'>

                                                                            <div className='col-md-4'>
                                                                                <label><b>Division :</b> {item.division_name == null ? '-' : item.division_name}</label>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <label><b>Category :</b> {item.category_name == null ? '-' : item.category_name}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {
                                                            finalTemp && finalTemp.map((data) => {
                                                                if (data.category_name == item.category_name) {
                                                                    return <>
                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                            <td className='ant-table-cell custom-width'>{data.unit_code}</td>
                                                                            <td className='ant-table-cell fs'><Tooltip
                                                                                className="toolTipClass3"
                                                                                placement="top"
                                                                                title={data.address}>
                                                                                <InfoCircleOutlined />&nbsp;
                                                                                {data.unit_name}</Tooltip></td>
                                                                            <td className='ant-table-cell fs'>{data.product}</td>
                                                                            <td className='ant-table-cell fs'>{data && data.org_names_list.length > 0 && data.org_names_list[0]}</td>
                                                                            <td className='ant-table-cell fs'><b>{data && data.domain_name}</b><br />{data && data.org_name}</td>
                                                                            <td className='ant-table-cell fs'>{data.geography_name}</td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            })
                                                        }
                                                    </>
                                                })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}
export default connect(mapStateToProps, {
    unitviewlist
})(AssignClientUnitView);