import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { login } from './../Store/Action/Login'

const mapStateToProps = (state) => ({
})

const Login = ({
    login
}) => {
    const history = useHistory()
    const [Auth, SetAuth] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [captchaError, SetcaptchaError] = useState(false);
    const [LoginData, setLoginData] = useState({
        userName: '',
        password: '',
    });
    const validator = useRef(new SimpleReactValidator());
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pwd, setPwd] = useState('');


    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        SetAuth(localStorage.getItem('isAuthenticated'));
    }, [])



    const submitLogin = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        SetcaptchaError(false);
        if (validator.current.allValid()) {
            login({
                payload: LoginData,
                navigate: history
            })

            e.target.reset();
        }
    }


    return (

        Auth ? <Redirect to='/home' /> : 
        <Fragment>

            <body>
                <div style={{ backgroundImage: 'url(login3-bg.png)', height: '100vh', backgroundSize: 'cover' }} className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-end flex-column">
                    <div style={{ borderRadius: '2rem' }} className="auth-box p-3 bg-white m-0 me-5">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-1">Login</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="commentForm mt-2" onSubmit={submitLogin}>
                                        <div className="form-group mb-3 position-relative">

                                            <input className="form-control" type="text" maxLength={20} required="" autoFocus='autofocus' placeholder="Username" onChange={(e) => {
                                                setLoginData({
                                                    ...LoginData, userName: e.target.value
                                                })
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} 
                                            />
                                            <i style={{ position: 'absolute', top: '6px', right: '7px', fontSize: '1.3rem' }} className="ri-user-line "></i>
                                            {validator.current.message(
                                                'userName',
                                                LoginData.userName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Username',
                                                    }
                                                })}

                                        </div>

                                        <div className="form-group mb-3 position-relative">
                                            <input className="form-control" maxLength={20}  type={isRevealPwd ? "text" : "password"}  value={pwd} required="" placeholder="Password" onChange={(e) => {
                                                setPwd(e.target.value)
                                                setLoginData({
                                                    ...LoginData, password: e.target.value
                                                })
                                            }} />
                                            <span className='pointer'>
                                            <i onClick={() => setIsRevealPwd(prevState => !prevState)} style={{ position: 'absolute', top: '6px', right: '7px', fontSize: '1.3rem' }} className={(isRevealPwd && pwd) ? "ri-eye-line" : "ri-eye-off-line"  }></i></span>
                                            {validator.current.message(
                                                'password',
                                                LoginData.password,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Password',
                                                    }
                                                })}
                                        </div>
                                                                                
                                        <div className="form-group mb-3 d-flex justify-content-between">

                                            <button style={{ backgroundColor: '#8572ed' }} type='submit' className="btn btn-primary border-0 w-50 login3-btn" onClick={submitLogin}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='fs-5'> Login</span>
                                                    <i className="ri-arrow-right-line arrow-btn-hover fs-5"></i>
                                                </div>
                                            </button>




                                            <Link to="/Forgotpassword" id="to" className="

                                                    d-flex
                                                    btn btn-primary
                                                    bg-light
                                                    border-0
                                                    align-items-center

                                                    link

                                                    font-weight-medium

                                                "><i className="ri-lock-line me-1 fs-4"></i> Forgot Password?</Link>

                                        </div>


                                        {/* </div> */}
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>


                    <div className='d-flex  align-items-end justify-content-end w-100 mt-5'>
                        <footer className="footer bg-transparent text-white  pb-0 shadow-sm" >2022© Aparajitha Software Services Private Limited <span className="ms-4">Privacy Policy · Terms & Condition </span> </footer>

                    </div>



                </div>
            </body>
        </Fragment>


    )
}

export default connect(mapStateToProps, {
    login
})(Login);