import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster'
import url from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import alertmessage from './../../Libs/Altermessage'
import { TOGGLE_COMMON_LOADER, PROFILE } from './../../Store/types/index'
import { toast } from 'react-toastify';

export const UserProfile = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    console.log(payload, "payloadpayload");
    try {
        console.log(formkey, 'formkeyformkey');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            }else if(decryptData[0] === 'UpdateUserProfileSuccess'){
                toast.success('Updated Successfully')
                dispatch({
                    type: PROFILE,
                    payload: decryptData[1],
                });
            }
             else {
                toast.success(decryptData[0])
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                dispatch({
                    type: PROFILE,
                    payload: decryptData[1],
                });
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const Updatepassword = ({ payload, executeCancel, paramid, formkey, form_url }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const updatepaylaod =
            [
                "ChangePassword",
                {
                    "session_token": payload && payload[1].session_token,
                    "current_password": payload && payload[1].current_password,
                    "new_password": payload && payload[1].new_password
                }
            ]
        console.log(updatepaylaod, 'payloadpayload');
        console.log(formkey, "formkeyformkey");
        const encryptkey = formkey
        // const head = {
        //     headers: {
        //         'Caller-Name': paramid,
        //         // 'Content-Type': 'application/json'
        //     },
        // }
        let encoded = base64_decode(encryptkey);

        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Content-Type': 'application/json',
                'Caller-Name': form_url
            },
        }
        // let encoded = base64_decode(formkey);
        console.log(encoded, "encoded");
        const data1 = EncryptDecrypt.encryptdata(updatepaylaod, encoded);
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            console.log(decryptData, 'decryptDatadecryptDatadecryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                if (decryptData[0] === 'ChangePasswordSuccess') {
                    Toaster.success('Updated Successfully')
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                }else if(decryptData[0] === 'InvalidCurrentPassword'){
                    Toaster.error('Invalid Current Password')                    
                }else if(decryptData[0] == 'CurrentandNewPasswordSame'){
                    Toaster.error('Current and new password should not be same')
                }
                else {
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                    Toaster.success(decryptData[0])
                }
                // dispatch({
                //     type: PROFILE,
                //     payload: decryptData[1],
                // });
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};