import { CLIENT_GROUP, EDIT_CLIENT_GROUP } from "../../types";

const initialState = {
   clientgrouptable : [],
   clientgroupdomains:[],
   clientgroupindustries:[],
   clientgroupcountries: [],
   editclientgroup:[]
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIENT_GROUP :{
            console.log(payload,'payloadAssignLegalEntity')
            return {
                ...state,
                clientgrouptable:  payload.groups,
                clientgroupdomains: payload.domains,
                clientgroupindustries:payload.industries,
                clientgroupcountries:payload.countries
            }
        }
        case EDIT_CLIENT_GROUP:{
            return{
                ...state,
                editclientgroup:payload
            }
        }
        default:
            return state;
    }
}