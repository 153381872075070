import { GET_CLIENT_INFO, REJECTED_ASM_BULK_UPLOAD_DATA, REJECTED_ASM_DOWNLOAD_COUNT, DOWNLOAD_REJECTED_ASM_REPORT, DOWNLOAD_REJECT_STATUS, RESET_STATE, REJECTED_ASBU_VERIFY_PASSWORD, DELETE_CSV } from '../../types/index';

const initialState = {
    ClientInfo: [],
    RejectedASMBulkUploadData: [],
    RejectedASMDownloadCount: [],
    DownloadRejectedASMReport: [],
    DownloadRejectStatus: [],
    VerifyPassword: [],
    deleteCsvStatus: [],
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RESET_STATE:
            return {
                ClientInfo: [],
                RejectedASMBulkUploadData: [],
                RejectedASMDownloadCount: [],
                DownloadRejectedASMReport: [],
                DownloadRejectStatus: [],
                VerifyPassword: [],
                deleteCsvStatus: [],
            }

        case GET_CLIENT_INFO:
            return {
                ...state,
                ClientInfo: payload
            }

        case REJECTED_ASM_BULK_UPLOAD_DATA:
            return {
                ...state,
                RejectedASMBulkUploadData: payload
            }
        
        case REJECTED_ASM_DOWNLOAD_COUNT:
            return {
                ...state,
                RejectedASMDownloadCount: payload
            }
        
        case DOWNLOAD_REJECTED_ASM_REPORT:
            return {
                ...state,
                DownloadRejectedASMReport: payload
            }

        case DOWNLOAD_REJECT_STATUS:
            return {
                ...state,
                DownloadRejectStatus: payload
            }
        
        case DELETE_CSV:
            return {
                ...state,
                deleteCsvStatus: payload
            }

        default:
            return state;
    }

}