import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal, Checkbox, Space } from 'antd';
import {
    InfoCircleOutlined, ArrowLeftOutlined, EditOutlined, CheckOutlined, CloseOutlined,
    MinusCircleOutlined, InfoCircleTwoTone, FullscreenOutlined, PlayCircleOutlined
} from "@ant-design/icons";
import { unitviewlist } from '../../Store/Action/Transactions/Assignclientunit'
import { getAssignedStatutoriesToApprove } from '../../Store/Action/Transactions/Approveassignedstatutory'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode"

import ScrollButton from "../Scrollbar";
import { fill } from 'lodash';

const _ = require("lodash");

const mapStateToProps = (state) => ({
    assignedStatutoriesToApprove: state.Approveassignedstatutory.approveassignedstatutory.assignedStatutoryDataSuccess.statutories_for_assigning,
    recordCount: state.Approveassignedstatutory.approveassignedstatutory.assignedStatutoryDataCount
})
const ApprovedAssignedStatutoryView = ({
    assignedStatutoriesToApprove,
    recordCount,
    fullscreen,
    setfullscreen,
    record
}) => {
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [userfullscreen, setuserfullscreen] = useState('');
    const [modalVisible, setmodalVisible] = useState(false);
    const [domainexeid, setdomainexeid] = useState({
        exename: '',
        domainname: ''
    });


    let index = 0;
    const history = useHistory();

    const columns = [
        {
            title: "#",
            dataIndex: "level_1_s_id",
            key: "level_1_s_id",
            align: 'center',
            width: '35px',
            ellipsis: true,
            render: (text, record) => {
                // return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>
                return <><Checkbox style={{ padding: "10px" }}>{++index}</Checkbox></>
            }
        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_provision',
            key: 's_provision',
            width: '100px',
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: '150px',
            // ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <Tooltip overlayStyle={{ maxWidth: '530px' }} overlayInnerStyle={{ wordWrap: "break-word" }} className='px-2' title={<b>{"Organizations : " + " " + record.org_names.join(" , ")}</b>}>
                            <InfoCircleTwoTone />
                        </Tooltip>
                        <span>{text}</span>
                    </>
                )
            }
        },
        {
            title: 'Compliance Description',
            dataIndex: 'descrip',
            key: 'descrip',
            width: '170px',
            // ellipsis: true,
        },
        {
            title: <EditOutlined title='Applicable' />,
            dataIndex: 'Manager_Labour',
            key: index,
            width: '30px',
            align: 'center',
            // ellipsis: true,
            render: () => <FeatherIcon icon="check-circle" size={20} className={'pointer'} style={{ color: '#318CE7' }} />
        },
        {
            title: <EditOutlined title='Not Applicable' />,
            dataIndex: 'Executive_Labour',
            key: index,
            width: '30px',
            align: 'center',
            // ellipsis: true,
            render: () => <FeatherIcon icon="x-circle" size="20" className={'pointer'} />
        },
        {
            title: <EditOutlined title='Not at All Applicable' />,
            dataIndex: 'Manager_Vendor',
            key: index,
            align: 'center',
            width: '30px',
            // ellipsis: true,
            render: () => <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '#FF0000' }} />
        }
    ]
    const genExtra = (status) => {
        if (status !== 1) {
            return (

                <><span style={{ marginLeft: '7%' }}>Remarks:<Input
                    disabled
                    className='form-control'
                    placeholder='Enter Remarks'
                    style={{ width: '70%' }}
                // onClick={(event) => {
                //     // If you don't want click extra trigger collapse, you can prevent this:
                //     event.stopPropagation();
                // }}
                />
                </span>
                </>
            )
        }
        else return false
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const fullscreenMode = () => {
        if (fullscreen == true) {
            fullscreenmode(dispatch);
            setfullscreen(false);
            setuserfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(true);
            setuserfullscreen(false);
        }
    }

    useEffect(() => {
        if (fullscreen == true) {
            fullscreenMode(dispatch)

        }
    }, [])

    return (
        <div className="page-wrapper" id="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-1 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        {/* <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link><b>Approve Assigned Statutory</b></Link>
                                </li>
                            </ol>
                        </nav> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Approve Assigned Statutory</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center 
                        justify-content-end">
                        <Button
                            type="primary"
                            shape="round"
                            className='addbutton'
                            style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "10px" }}
                            icon={<ArrowLeftOutlined />}
                            size='default'
                            onClick={() => { window.location.reload() }}>
                            Back
                        </Button>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-0">
                <Card>
                    <div className="ant-table ant-table-bordered ant-table-fixed-header" style={{ padding: '5px' }}>
                        <div className="ant-table-container">
                            <div style={{borderRadius:'20px'}}>
                                <table className='approveassigntable' >
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th className="ant-table-cell"><b>Legal Entity </b></th>
                                            <th className="ant-table-cell"><b>Domain </b></th>
                                            <th className="ant-table-cell"><b>Unit/Vendor Code - Unit/Vendor Name </b></th>
                                            <th className="ant-table-cell"><b>Domain Executive </b></th>
                                        </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                        <tr>
                                            <td className="ant-table-cell">  <span className='ms-1'>{record.l_e_name}</span></td>
                                            <td className="ant-table-cell">  <span className='ms-1'>{record.d_name}</span></td>
                                            <td className="ant-table-cell">  <span className='ms-1'>{record.u_name}</span></td>
                                            <td className="ant-table-cell">  <span className='ms-1'>{record.u_name}</span></td>

                                        </tr>
                                    </tbody>

                                    {/* <div className='col-3'>
                            <label><b>Legal Entity: </b></label>
                            <span className='ms-1'>{record.l_e_name}</span>
                        </div>
                        <div className='col-3'>
                            <label><b>Domain: </b></label>
                            <span className='ms-1'>{record.d_name}</span>
                        </div>
                        <div className='col-3'>
                            <label><b>Unit/Vendor Code - Unit/Vendor Name: </b></label>
                            <span className='ms-1'>{record.u_name}</span>
                        </div>
                        <div className='col-3'>
                            <label><b>Domain Executive: </b></label>
                            <span className='ms-1'>{record.u_name}</span>
                        </div> */}
                                </table>
                            </div>
                        </div>
                    </div>
                </Card>
                <div className="row" >
                    <div className="col-12">
                        <div className='card-body custom-collapse' style={{ padding: 0 }}>
                            {assignedStatutoriesToApprove && assignedStatutoriesToApprove.map(approved => {
                                console.log('assignedStatutory', assignedStatutoriesToApprove);
                                const titleName = approved[0]["level_1_s_name"];
                                console.log(approved, 'approvedapproved');

                                console.log(approved[0]["a_status"] == 1, '  approved[0]["a_status"] == 1');
                                return (
                                    <Collapse className="site-collapse-custom-collapse" expandIcon={() => {
                                        return approved[0].a_status == 1 ? <CheckOutlined /> : approved[0].a_status == 0 ? <CloseOutlined /> : <MinusCircleOutlined></MinusCircleOutlined>
                                    }} defaultActiveKey={["1"]} >
                                        <Panel header={titleName} key={1} extra={genExtra(approved[0].a_status)}>
                                            <Table
                                                className='taskrecalltable'
                                                columns={columns}
                                                dataSource={approved}
                                                bordered
                                                // scroll={{ x: 700 }}
                                                pagination={false}
                                            />
                                        </Panel>
                                    </Collapse>
                                )
                            })
                            }
                            {assignedStatutoriesToApprove ? (assignedStatutoriesToApprove.length > 0 ?
                                <div className='px-2'>
                                    <span>Showing 1 to {recordCount} of {recordCount} entries</span>
                                    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                                        <div className='my-3'>
                                            <label className='px-3'><b>Action:</b> <span style={{ color: "red" }}>*</span></label>
                                            <Select style={{ width: 200 }} placeholder="Select" defaultValue={"Select"} options={[{
                                                value: null,
                                                label: 'Select',
                                            },
                                            {
                                                value: 'Approve',
                                                label: 'Approve',
                                            },
                                            {
                                                value: 'Reject',
                                                label: 'Reject',
                                            }]} />

                                        </div>
                                        <Button type="primary" shape="round" icon={<PlayCircleOutlined />}>Submit</Button>
                                    </Space></div> : null) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, {})(ApprovedAssignedStatutoryView);