import React, { Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation } from "react-router-dom";
import Addstatutorymapping from "./Addstatutorymapping";
import Editstatutorymapping from "./Editstatutorymapping";
import { Collapse, Select, Button, Table, Tooltip, Pagination, Modal, Input, Avatar, Segmented, Space } from "antd";
import {
  getmaplist,
  getinfodetails,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit, gethistorydata, deactivestatuschange
} from "./../../Store/Action/Transactions/Statutorymapping";
import {
  LikeOutlined, UserOutlined,
  DislikeOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined, FormOutlined, BookOutlined, PlayCircleOutlined,
  PlusCircleOutlined,
  CheckCircleFilled, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ExclamationCircleTwoTone
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { set } from "lodash";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../Scrollbar';


const _ = require("lodash");

const mapStateToProps = (state) => ({
  list: state.Statutorymapping.statutorymap.list,
  info: state.Statutorymapping.statutorymap.info,
  history: state.Statutorymapping.statutorymap.historydata,
});

const StatutoryMapping = ({
  getmaplist,
  getinfodetails,
  list,
  info,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit,
  gethistorydata,
  history,
  deactivestatuschange
}) => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
  const location = useLocation();
  const paramid = location.pathname;
  const [key, setKey] = useState("3");
  const { Panel } = Collapse;
  const { Option } = Select;
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [pageSize, setPageSize] = useState(25);
  const [current, setCurrent] = useState(1);
  const [pageState, setpageState] = useState(false);
  const [listpage, setlistpage] = useState('list');
  let [index, setIndex] = useState(0);
  const [iconEnable, setIconEnable] = useState(false);
  const [clearText, setClearText] = useState("");
  const formValidator = useRef(new SimpleReactValidator());
  const formValidator1 = useRef(new SimpleReactValidator());
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
  const [modalVisible, setmodalVisible] = useState(false);
  const [searchdata, setsearchdata] = useState({
    country: "",
    domain: "",
    domaindata: "",
    organization: "",
    organizationdata: "",
    nature: "",
    naturedata: "",
    statutory: "",
    approvalstatus: 6,
    status: 3,
    taskcode: "",
  });
  const [deactivemodal, setdeactivemodal] = useState(false);
  const [effectivedata, seteffectivedata] = useState({
    comp_id: '',
    selecteddate: ''
  });

  const [fullscreen, setfullscreen] = useState(false);
  const [filterTaskAcc, setFilterTaskAcc] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [approvalStatus, setApprovalStatus] = useState(4)
  const [KEY, settempKey] = useState("")

  const tablehandleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
    setsearchdata({
      ...searchdata,
      country: '',
      domain: '',
      domaindata: '',
      organization: '',
      organizationdata: '',
      nature: '',
      naturedata: '',
      statutory: '',
      approvalstatus: '',
      status: '',
      taskcode: '',
    })
  };

  useEffect(() => {
    if (fullscreen == true) {
        document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
        return () => {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
        };
    }
}, [fullscreen]);

const fullscreenMode = () => {
    if (fullscreen == false) {
        fullscreenmode(dispatch);
        setfullscreen(true);
    } else {
        fullscreenexit(dispatch);
        setfullscreen(false);
    }
}



  const columns = [
    {
      title: '#',
      key: 'index',
      width: '4%',
      align: 'center',
      render: (text, record, rowIndex) => (current - 1) * pageSize + rowIndex + 1,
    },
    {
      title: "Task Code",
      dataIndex: "task_code",
      width: '12%',
      key: "task_code",
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            {record.approval_status_text == "Rejected" ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Organization",
      dataIndex: "industry_names",
      key: "industry_names",
      // ellipsis: true,
      width: '150px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} style={{ color: "red" }}>{record.industry_names}</span>
        } else {
          return record.industry_names
        }
      },
    },
    {
      title: "Statutory Nature",
      dataIndex: "statutory_nature_name",
      key: "statutory_nature_name",
      // ellipsis: true,
      width: '100px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} style={{ color: "red" }}>{record.statutory_nature_name}</span>
        } else {
          return record.statutory_nature_name
        }
      },
    },
    {
      title: "Statutory",
      dataIndex: "s_maps",
      key: "s_maps",
      // ellipsis: false,
      width: '220px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} style={{ color: "red" }}>{record.s_maps}</span>
        } else {
          return record.s_maps
        }
      },
    },
    {
      title: "Task Name",
      dataIndex: "compliance_name",
      key: "compliance_name",
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} style={{ color: "red" }}>{record.compliance_name}</span>
        } else {
          return record.compliance_name
        }

      },
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status_text",
      width: '90px',
      render: (text, record) => {
        return (<>
          {record.is_approved == 4 ?
            <div>
              <span className="mx-1 text-primary">
                <Tooltip title={record.remarks}>  <i className="ri-information-fill" /></Tooltip>
                  &nbsp;
                  {text == "Rejected" ? (
                    <span style={{ color: "red" }}>{text}</span>
                  ) : (
                    <span>{text}</span>
                  )}
              </span>
            </div> :
            <div>
              <span>
                <span>{text}</span>
              </span>
            </div>} </>
        )
      },
    },
    {
      title: (
        <>
          <label>Actions</label>
          <Tooltip
            className="serviveproidertooltipclass"
            style={{ marginBotton: "5px", marginleft: "5px" }}
            placement="topRight"
            title={"This Action Includes Edit and Status View"}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "is_active",
      width: '120px',
      render: (text, record) => {
        return (
          <Fragment>
            <div className="row ">

              {record.is_approved == 2 || record.is_approved == 3 ?
                text === true ? (
                  <div className="col-md-2">
                    <span
                      className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => { deactivetask(record) }}>
                      <Tooltip title="click here to deactive" placement="leftBottom">
                        <LikeOutlined />
                      </Tooltip>
                    </span>
                  </div>
                ) : (
                  <div className="col-md-2">
                    <span
                      className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center">
                      <Tooltip title="InActive" placement="leftBottom">
                        <DislikeOutlined />
                      </Tooltip>
                    </span>
                  </div>
                ) :
                text === true ? (
                  <div className="col-md-2">
                    <span
                      className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                    >
                      <Tooltip title="Active" placement="leftBottom">
                        <LikeOutlined />
                      </Tooltip>
                    </span>
                  </div>
                ) : (
                  <div className="col-md-2">
                    <span
                      className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                    >
                      <Tooltip title="InActive" placement="leftBottom">
                        <DislikeOutlined />
                      </Tooltip>
                    </span>
                  </div>
                )}


              <>
                {record.is_approved == 0 || record.is_approved == 4 ?
                  <div className="col-md-2">
                    <span onClick={(e) => { editstatutory(record) }}
                      className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                    >
                      <Tooltip title="Click here to edit" placement="leftBottom">
                        <i className="ri-edit-line"></i>
                      </Tooltip>
                    </span>
                  </div> : ''}
              </>
              {record.is_approved == 2 || record.is_approved == 3 ?
                <>
                  {text == true ?
                    <>
                      <div className="col-md-2">
                        <span onClick={(e) => { editstatutory(record, "3") }}
                          className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center"
                        >
                          <Tooltip title="Click here to edit" placement="leftBottom">
                            <i className="ri-edit-line"></i>
                          </Tooltip>
                        </span> </div>
                      <div className="col-md-2">
                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center">
                          <Tooltip title="click here to proceed amendment" placement="topRight" onClick={(e) => { editstatutory(record) }}  >
                            <FormOutlined />
                          </Tooltip>
                        </span>
                      </div>
                    </> : ''}

                </> : ''}
              {record.is_approved == 2 || record.is_approved == 3 || record.is_approved == 1 || record.is_approved == 4 ?
                <>
                  {record.history_count > 1 ?
                    <div className="col-md-2">
                      <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={() => { showhistory(record) }}>
                        <Tooltip title="click here to view history" placement="leftBottom">
                          <BookOutlined />
                        </Tooltip>
                      </span></div> : ''}
                </> : ''}
              <>
                {record.approval_status_text == "Rejected" ||
                  record.approval_status_text == "Yet to submit" ? (
                  <div className="col-md-2">
                    <span
                      className="btn btn-sm btn btn-sm btn-light-danger text-danger btn-circle d-flex 
                                align-items-center justify-content-center"
                      onClick={(e) => {
                        delete_statutory(record);
                      }}
                    >
                      <Tooltip title="Delete Compliance" placement="leftBottom">
                        <DeleteOutlined />
                      </Tooltip>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            </div>
          </Fragment>
        );
      },
    },
  ];



  const historycolumns = [
    {
      title: "Action Type",
      dataIndex: "task_code",
      key: "task_code",
      render: (text, record) => {
        return (<>
          <div>
            {record.h_change_mode == 0 ?
              <span>Create</span> : record.h_change_mode == 1 ?
                <span>Edit</span> :
                <span>Amendment</span>
            }
          </div>
        </>
        )
      },
    },
    {
      title: "Effective Date",
      dataIndex: "h_effective_date",
      key: "h_effective_date",
    },
    {
      title: "Statutory Info",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Created by",
      dataIndex: "h_created_by",
      key: "h_created_by",
    },
    {
      title: "Validated by",
      dataIndex: "h_last_updated_by",
      key: "h_last_updated_by",
      width: '100px'
    },
    {
      title: "Action",
      dataIndex: "h_action",
      key: "h_action",
    },
    {
      title: "Remarks",
      dataIndex: "h_reason",
      key: "h_reason",
    },
  ]

  const delete_statutory = (record) => {
    const payload = {
      session_token: authtoken,
      request: [
        "GetComplianceDelete",
        {
          comp_id: record.compliance_id,
          task_code: record.task_code,
        },
      ],
    };
    Swal.fire({
      title: "Are You Sure Want to Delete?",
      icon: "info",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deletestatutorymapping({
          payload: payload,
          paramid: paramid,
        });
      }
    });
  };

  const showhistory = (data) => {
    setmodalVisible(true);
    const pay = {
      "session_token": authtoken,
      "request": [
        "GetComplianceHistory",
        {
          "comp_id": data.compliance_id
        }
      ]
    }

    gethistorydata({
      payload: pay,
      paramid: paramid
    })
  }

  const modalcancel = () => {
    setmodalVisible(false);
  }

  const deactivatecancel = () => {
    setdeactivemodal(false);
    seteffectivedata({
      ...effectivedata,
      selecteddate: '',
      comp_id: ''
    })
    setAddFormSubmit1(false);
  }

  useEffect(() => {
    if (isAuth) {
      const pay = {
        session_token: authtoken,
        request: [
          "GetStatutoryMappings",
          {
            approval_status_id: 4,
            active_status_id: 3,
            rcount: 0,
            page_limit: 25,
          },
        ],
      };
      const infopay = {
        session_token: authtoken,
        request: ["GetStatutoryMappingsMaster", {}],
      };

      const masterpay = {
        session_token: authtoken,
        request: ["GetStatutoryMaster", {}],
      };

      getmaplist({
        payload: pay,
        paramid: paramid,
      });

      getinfodetails({
        payload: infopay,
        paramid: paramid,
      });

      getmasterlist({
        payload: masterpay,
        paramid: paramid,
      });
    }
  }, [isAuth]);


  const deactivetask = (re) => {
    seteffectivedata({
      ...effectivedata,
      comp_id: re.compliance_id
    })
    setdeactivemodal(true);
  }

  const submitdate = () => {
    setAddFormSubmit1(true);
    if (formValidator1.current.allValid()) {
      const pay = {
        "session_token": authtoken,
        "request": [
          "ChangeComplianceStatus",
          {
            "compliance_id": effectivedata.comp_id,
            "is_active": false,
            "effective_date": moment(effectivedata.selecteddate).format('DD-MMM-YYYY')
          }
        ]
      }
      deactivestatuschange({
        payload: pay,
        paramid: paramid
      })

    }
    setdeactivemodal(false);

  }

  const showresult = (e) => {
    e.preventDefault();
    setAddFormSubmit(true);
    if (formValidator.current.allValid()) {
      setFilterTaskAcc(false);
      setFilterModalVisible(false);

      const pay = {
        session_token: authtoken,
        request: [
          "GetStatutoryMappingsSearch",
          {
            country_id: parseInt(searchdata.country.value),
            domain_id: parseInt(searchdata.domain.value),
            s_organization_id: parseInt(searchdata.organization.value),
            s_statutory_nature_id: parseInt(searchdata.nature.value),
            s_task_code_id: searchdata.taskcode,
            s_statutory_val: searchdata.statutory,
            s_approval_status_id: parseInt(searchdata.approvalstatus),
            s_active_status_id: parseInt(searchdata.status),
            rcount: 0,
            page_limit: 25,
          },
        ],
      };
      getmaplist({
        payload: pay,
        paramid: paramid,
      });
    }
  };

  useEffect(() => {
    if (searchdata.country && searchdata.country != "") {
      const domain = _.filter(info.domain_info, {
        c_id: parseInt(searchdata.country.value),
      });
      const nature = _.filter(info.nature_info, {
        c_id: parseInt(searchdata.country.value),
      });
      setsearchdata({
        ...searchdata,
        domaindata: domain,
        naturedata: nature,
      });
    }
  }, [searchdata.country]);

  useEffect(() => {
    if (searchdata.domain && searchdata.domain != "") {
      const organisation = _.filter(info.organisation_info, {
        c_id: parseInt(searchdata.country.value),
        d_id: parseInt(searchdata.domain.value),
      });
      setsearchdata({
        ...searchdata,
        organizationdata: organisation,
      });
    }
  }, [searchdata.domain]);

  useEffect(() => {
    formValidator.current.showMessages();
    formValidator1.current.showMessages();
  }, []);

  const addstatutory = () => {
    setlistpage('add');
  };

  const editstatutory = (record, keyvalue) => {
    const payload =
    {
      "session_token": authtoken,
      "request": [
        "GetComplianceEdit",
        {
          "comp_id": parseInt(record.compliance_id),
          "action_type": 1
        }
      ]
    }

    getcomplianceedit({
      payload: payload,
      paramid: paramid
    })
    setlistpage('edit')
    settempKey(keyvalue)


  }

  const [baseData, setBaseData] = useState([]);
  useEffect(() => {
    if (list && list.statu_mappings.length > 0) {
      setBaseData(list.statu_mappings);
    } else {
      setBaseData([]);
    }
  }, [list]);

  const searchRecords = (e) => {
    if (e == "clear") {
      setClearText("");
      setBaseData(list.statu_mappings);
      setIconEnable(false);
    } else {
      setClearText(e.target.value);
      if (e.target.value.length > 0) {
        setIconEnable(true);
      } else {
        setIconEnable(false);
      }
      const filterTable = list.statu_mappings.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setBaseData(filterTable);
    }
  };

  const homescreen = () => {
    window.location.href = '/dashboard'
  }

  useEffect(() => {
    if (pageState == true) {
      let pay;
      if (searchdata.country == "" && searchdata.domain == "") {
        pay = {
          session_token: authtoken,
          request: [
            "GetStatutoryMappings",
            {
              approval_status_id: approvalStatus,
              active_status_id: searchdata.status == 3 ? 3 : parseInt(searchdata.status),
              rcount: Math.max((current - 1) * pageSize, 0),
              page_limit: pageSize,
            },
          ],
        };
      } else {
        pay = {
          session_token: authtoken,
          request: [
            "GetStatutoryMappingsSearch",
            {
              country_id: parseInt(searchdata.country.value),
              domain_id: parseInt(searchdata.domain.value),
              s_organization_id: parseInt(searchdata.organization.value),
              s_statutory_nature_id: parseInt(searchdata.nature.value),
              s_task_code_id: searchdata.taskcode,
              s_statutory_val: searchdata.statutory,
              s_approval_status_id: approvalStatus,
              s_active_status_id: searchdata.status == 3 ? 3 : parseInt(searchdata.status),
              rcount: Math.max((current - 1) * pageSize, 0),
              page_limit: pageSize,
            },
          ],
        };
      }

      getmaplist({
        payload: pay,
        paramid: paramid,
      });
    }
  }, [current, pageSize, pageState])

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };



  return (
    <>
     {listpage == 'list' ? (
      <>
      <div className='back-to-top'>
        <ScrollButton />
      </div>
      <div id="page-wrapper" className="page-wrapper">
        <div className="page-titles pb-1 pt-1">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 d-flex align-items-center">
                  <li className="breadcrumb-item">
                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span style={{ fontSize: '16px' }}>Transaction</span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span >Statutory Mapping </span>
                  </li>
                </ol>
              </nav>
            </div>
            <div
              className="col-lg-8 col-md-6 d-none d-md-flex align-items-center justify-content-end"
            >
              <div className="search-box mt-1" style={{ position: 'relative', width: '240px', height: '35px', boxShadow: '0 5px 5px rgb(0 0 0 / 10%)', marginRight: '25px' }}>
                <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                <a className="search-btn" style={{ position: 'relative', right: 2 }}>
                  {iconEnable == true ?
                    <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                    : <i className="fas fa-search"></i>}
                </a>
              </div>
              <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "10px",
                  display: filterTaskAcc ? "none" : "inline",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
              <Tooltip title="Add StatutoryMapping" placement="left">
                <Button
                  type="primary"
                  shape="round"
                  className="addbutton"

                  icon={<PlusCircleOutlined />}
                  size="default"
                  onClick={addstatutory}
                >
                  Add
                </Button>
              </Tooltip>

              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
              >
                <Tooltip
                  placement="left"
                  // style={{ marginRight: "10px" }}
                  title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                >
                  <FullscreenOutlined />
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-1">
          <div className="row">
            <div className="col-12">
              <div className="card-body" style={{ padding: "0px" }}>
                <Modal
                  title="Statutory Mapping"
                  className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                  footer={false}
                  visible={filterModalVisible}
                  onCancel={setcancelFilter}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Country :<span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="form-group">
                          <Select
                            allowClear={false}
                            size="default"
                            placeholder="Select Country"
                            name="Country"
                            className="form-control"
                            id="Country"
                            value={searchdata.country || undefined}

                            onChange={(data, value) => {
                              setsearchdata({
                                ...searchdata,
                                country: value,
                              });
                            }}
                          >
                            {info &&
                              info.country_info.length > 0 &&
                              info.country_info.map((item) => {
                                return (
                                  <Option key={item.c_id}>
                                    {item.c_name}
                                  </Option>
                                );
                              })}
                          </Select>
                          {formValidator.current.message(
                            "Country",
                            searchdata.country,
                            ["required"],
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Country Name Required",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Domain :<span style={{ color: "red" }}> *</span>
                        </label>
                        <Select
                          allowClear={false}
                          size="default"
                          name="domain"
                          placeholder="Select Domain"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%" }}
                          value={searchdata.domain || undefined}

                          onChange={(data, value) => {
                            setsearchdata({
                              ...searchdata,
                              domain: value,
                            });
                          }}
                        >
                          {searchdata.domaindata &&
                            searchdata.domaindata.length > 0 &&
                            searchdata.domaindata.map((item) => {
                              return (
                                <Option key={item.d_id}>
                                  {item.d_name}
                                </Option>
                              );
                            })}
                        </Select>
                        {formValidator.current.message(
                          "domain",
                          searchdata.domain,
                          ["required"],
                          {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                              }`,
                            messages: {
                              required: "Compfie Domain Name Required",
                            },
                          }
                        )}
                      </div>
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Organization :
                        </label>
                        <Select
                          allowClear={false}
                          size="default"
                          placeholder="Select Organization"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%" }}
                          value={searchdata.organization || undefined}

                          onChange={(data, value) => {
                            setsearchdata({
                              ...searchdata,
                              organization: value,
                            });
                          }}
                        >
                          {searchdata.organizationdata &&
                            searchdata.organizationdata.length > 0 &&
                            searchdata.organizationdata.map((item) => {
                              return (
                                <Option key={item.org_id}>
                                  {item.org_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Statutory Nature :
                        </label>
                        <div className="form-group">
                          <Select
                            allowClear={false}
                            size="default"
                            placeholder="Select Statutory Nature"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%" }}
                            value={searchdata.nature || undefined}

                            onChange={(data, value) => {
                              setsearchdata({
                                ...searchdata,
                                nature: value,
                              });
                            }}
                          >
                            {searchdata.naturedata &&
                              searchdata.naturedata.length > 0 &&
                              searchdata.naturedata.map((item) => {
                                return (
                                  <Option key={item.s_n_id}>
                                    {item.s_n_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Statutory :
                        </label>
                        <Input
                          className="form-control input-sm"
                          type="text"
                          placeholder="Enter Statutory"
                          autocomplete="off"
                          style={{ height: "32px" }}
                          value={searchdata.statutory || undefined}

                          onChange={(e) => {
                            setsearchdata({
                              ...searchdata,
                              statutory: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Approval Status :
                        </label>
                        <Select
                          allowClear={false}
                          size="default"
                          placeholder="Select ApprovalStatus"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%" }}
                          // value={searchdata.approvalstatus || undefined}

                          defaultValue="All"
                          onChange={(data, value) => {
                            setsearchdata({
                              ...searchdata,
                              approvalstatus: value.value,
                            });
                          }}
                        >
                          <Option value="6">All</Option>
                          <Option value="4">Rejected</Option>
                          <Option value="0">Yet to Submit</Option>
                          <Option value="1">Pending</Option>
                          <Option value="2">Approved</Option>
                          <Option value="3">Approved & Notified</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Status :
                        </label>
                        <div className="form-group">
                          <Select
                            allowClear={false}
                            size="default"
                            placeholder="Select Status"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%" }}
                            // value={searchdata.status || undefined}

                            defaultValue="All"
                            onChange={(data, value) => {
                              setsearchdata({
                                ...searchdata,
                                status: value.value,
                              });
                            }}
                          >
                            <Option value="3">All</Option>
                            <Option value="1">Active</Option>
                            <Option value="0">InActive</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          Task Code :
                        </label>
                        <Input
                          className="form-control input-sm"
                          type="text"
                          placeholder="Task Code"
                          autocomplete="off"
                          style={{ height: "32px" }}
                          value={searchdata.taskcode || undefined}

                          onChange={(e) => {
                            setsearchdata({
                              ...searchdata,
                              taskcode: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                      <div className="text-center">
                        <Button
                          type="primary"
                          shape="round"
                          className="addbutton custom-color-modal-button"
                          icon={<EyeOutlined />}
                          style={{
                            width: "100px",
                          }}
                          onClick={showresult}
                        >
                          Show
                        </Button>
                      </div>
                    </div>
                  </div>

                </Modal>
                <div className="card rounded-3  mt-1">
                  <div className="col-md-12" style={{ marginTop: '2%', marginBottom: '2%' }}>
                    <div className="row">
                      <div className="col-md-5">
                        <h6 style={{ marginLeft: '8px' }}>Overall Filter</h6>
                        <Space direction="vertical" style={{ marginLeft: '8px' }}>
                          <Segmented defaultValue={4} onChange={(value) => {
                            setApprovalStatus(value)
                            const pay = {
                              session_token: authtoken,
                              request: [
                                "GetStatutoryMappings",
                                {
                                  approval_status_id: value,
                                  active_status_id: 3,
                                  rcount: 0,
                                  page_limit: 25,
                                },
                              ],
                            };
                            getmaplist({
                              payload: pay,
                              paramid: paramid,
                            });
                          }}
                            options={[
                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#9FA6B2',
                                      }}
                                      icon={<i className="fas fa-database"></i>}
                                    >
                                      K
                                    </Avatar>
                                    <div>All</div>
                                  </div>
                                ),
                                value: 6,
                              },
                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#87d068',
                                      }}
                                      icon={<i className="fas fa-check"></i>}
                                    />
                                    <div>Approved</div>
                                  </div>
                                ),
                                value: 2,
                              },


                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#54B4D3',
                                      }}
                                      icon={<i className="fas fa-file-excel-o"></i>}
                                    />
                                    <div>Yet to Submit</div>
                                  </div>
                                ),
                                value: 0,
                              },
                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#E4A11B',
                                      }}
                                      icon={<i className="fas fa-clock-o"></i>}
                                    />
                                    <div>Pending</div>
                                  </div>
                                ),
                                value: 1,
                              },
                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#F32013',
                                      }}
                                      icon={<i className="fas fa-ban"></i>}
                                    >
                                      K
                                    </Avatar>
                                    <div>Rejected</div>
                                  </div>
                                ),
                                value: 4,
                              },
                              {
                                label: (
                                  <div
                                    style={{
                                      padding: 4,
                                    }}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: '#3B71CA',
                                      }}
                                      icon={<i className="fas fa-bell"></i>}
                                    />
                                    <div>Approved & Notified</div>
                                  </div>
                                ),
                                value: 3,
                              },


                            ]}
                          />
                        </Space>
                      </div>
                      <div className="col-md-7">
                        <div className="row">
                          <h6 style={{ marginLeft: '33%', marginBottom: '22px' }}>Overall Statistics</h6>
                          <div className=" mt-1 mb-1 d-flex justify-content-end">
                            <div className="d-inline mx-2 mt-1" style={{ background: '#311E69', color: "white", borderRadius: '15px', padding: '3px', height: '33px' }}>
                              <p className=" mx-1 d-inline" >
                                Total Records :
                              </p><h5 className="text-white d-inline mt-1 fw-bolder">{list.total_records}</h5> </div>
                            <div className="d-inline mx-2 mt-1" style={{ background: '#311E69', color: "white", borderRadius: '15px', padding: '3px', height: '33px' }}>
                              <p className=" mx-1 d-inline" >
                                InActive Count :
                              </p><h5 className="text-white d-inline mt-1 fw-bolder ">{list.inactive_count ? list.inactive_count : '0'}</h5> </div>
                            <div className="d-inline mx-2 mt-1" style={{ background: '#311E69', color: "white", borderRadius: '15px', padding: '3px', height: '33px' }}>
                              <p className=" mx-1 d-inline" >
                                Amendment Count :
                              </p><h5 className="text-white d-inline mt-1 fw-bolder ">{list.amendment_count ? list.amendment_count : '0'}</h5> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Table
                    size={'small'}
                    className='taskrecalltable'
                    columns={columns}
                    dataSource={baseData && baseData.length > 0 && baseData}
                    bordered
                    pagination={false}
                    onChange={tablehandleChange}
                    rowKey={(record, index) => index}
                  />
                  {baseData && baseData.length > 0 ?
                  <Pagination
                    style={{ "margin-top": "10px", float: " RIGHT" }}
                    current={current}
                    showSizeChanger={true}
                    showQuickJumper={false}
                    onShowSizeChange={() => {
                      setCurrent(1);
                    }}
                    pageSizeOptions={[25, 50, 100]}
                    defaultPageSize={25}
                    onChange={(page, pageSizes) => {
                      setpageState(true);
                      setCurrent(pageSize !== pageSizes ? 1 : page);
                      setPageSize(pageSizes);
                    }}
                    total={list.total_records}
                  /> :''}
                </div>

              </div>
            </div>
          </div>
        </div>
        <Modal title="Compliance History" open={modalVisible} footer={null} maskClosable={false} className="Assignunit add-service-prv custom-color-modal "
          onCancel={modalcancel} getContainer={() => document.getElementById("main-wrapper")}>
          <div className="row">
            <div className="col-lg-12">
              <Table
                size={'small'}
                columns={historycolumns}
                dataSource={history && history.h_compliance_history_list.length > 0 && history.h_compliance_history_list}
                bordered
                pagination={false}
              />
            </div>
          </div>
        </Modal>
        <Modal open={deactivemodal} className="newStyleModalPassword add-service-prv custom-color-modal " title="Task Deactivate" footer={null} onCancel={deactivatecancel} getContainer={() => document.getElementById("main-wrapper")}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-6">
                <label className="control-label form-control "> <b>Implementation Effective Date:</b><span style={{ "color": "red" }}>*</span></label>
                <Input
                  type="date"
                  id="stat_date"
                  name="stat_date"
                  autoComplete={"off"}
                  min={moment().add(1, 'days').format('YYYY-MM-DD')}
                  value={effectivedata.selecteddate}
                  onChange={(e) => {
                    seteffectivedata({
                      ...effectivedata,
                      selecteddate: e.target.value
                    });
                  }}
                />
                {formValidator1.current.message(
                  'stat_date',
                  effectivedata.selecteddate,
                  ['required'],
                  {
                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                    messages: {
                      required: 'Effective Date required'
                    }
                  })}
              </div>
            </div>

          </div>
          <br />
          <div className="form-actions">
            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
              style={{ marginLeft: '33%' }}
              icon={<PlayCircleOutlined />} size='default' onClick={submitdate}>
              Submit
            </Button>
          </div>
        </Modal>
      </div></>
      ) : listpage == 'add' ? (
        <Addstatutorymapping />
      ) : <Editstatutorymapping  EditKey={KEY}/>}
    </>
  );
};
export default connect(mapStateToProps, {
  getmaplist,
  getinfodetails,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit,
  gethistorydata,
  deactivestatuschange
})(StatutoryMapping);
{/* <Collapse defaultActiveKey={["1"]}
                    style={{ display: exitCollapse ? "none" : "block" }}
                  >
                    <Panel header="Statutory Mapping Search" key={key}>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Country :<span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Country"
                                name="Country"
                                className="form-control"
                                id="Country"
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    country: value,
                                  });
                                }}
                              >
                                {info &&
                                  info.country_info.length > 0 &&
                                  info.country_info.map((item) => {
                                    return (
                                      <Option key={item.c_id}>
                                        {item.c_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {formValidator.current.message(
                                "Country",
                                searchdata.country,
                                ["required"],
                                {
                                  className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Country Name Required",
                                  },
                                }
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Domain :<span style={{ color: "red" }}> *</span>
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              name="domain"
                              placeholder="Select Domain"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  domain: value,
                                });
                              }}
                            >
                              {searchdata.domaindata &&
                                searchdata.domaindata.length > 0 &&
                                searchdata.domaindata.map((item) => {
                                  return (
                                    <Option key={item.d_id}>
                                      {item.d_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "domain",
                              searchdata.domain,
                              ["required"],
                              {
                                className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Compfie Domain Name Required",
                                },
                              }
                            )}
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Organization :
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              placeholder="Select Organization"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  organization: value,
                                });
                              }}
                            >
                              {searchdata.organizationdata &&
                                searchdata.organizationdata.length > 0 &&
                                searchdata.organizationdata.map((item) => {
                                  return (
                                    <Option key={item.org_id}>
                                      {item.org_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-1">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Statutory Nature :
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Statutory Nature"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    nature: value,
                                  });
                                }}
                              >
                                {searchdata.naturedata &&
                                  searchdata.naturedata.length > 0 &&
                                  searchdata.naturedata.map((item) => {
                                    return (
                                      <Option key={item.s_n_id}>
                                        {item.s_n_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Statutory :
                            </label>
                            <input
                              className="form-control input-sm"
                              type="text"
                              placeholder="Enter Statutory"
                              autocomplete="off"
                              style={{ height: "32px" }}
                              onChange={(e) => {
                                setsearchdata({
                                  ...searchdata,
                                  statutory: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Approval Status :
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              placeholder="Select ApprovalStatus"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              defaultValue="All"
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  approvalstatus: value.value,
                                });
                              }}
                            >
                              <Option value="6">All</Option>
                              <Option value="4">Rejected</Option>
                              <Option value="0">Yet to Submit</Option>
                              <Option value="1">Pending</Option>
                              <Option value="2">Approved</Option>
                              <Option value="3">Approved & Notified</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-1">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Status :
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Status"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                                defaultValue="All"
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    status: value.value,
                                  });
                                }}
                              >
                                <Option value="3">All</Option>
                                <Option value="1">Active</Option>
                                <Option value="0">InActive</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Task Code :
                            </label>
                            <input
                              className="form-control input-sm"
                              type="text"
                              placeholder="Task Code"
                              autocomplete="off"
                              style={{ height: "32px" }}
                              onChange={(e) => {
                                setsearchdata({
                                  ...searchdata,
                                  taskcode: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: "10px" }}>
                        <div className="row">
                          <div className="text-center">
                            <Button
                              type="primary"
                              shape="round"
                              className="addbutton"
                              icon={<EyeOutlined />}
                              style={{
                                width: "100px",
                              }}
                              onClick={showresult}
                            >
                              Show
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse> */}