import { connect } from 'react-redux';
import { Fragment } from 'react';


const mapStateToProps = (state) => ({
})
const Dashboard = ({
    

}) => {
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const rolename = callername.login_response.user_group_name
    const employee_name = callername.login_response.employee_name
  
    return (
        <Fragment>
            <div className="page-wrapper">
                
                <div className="container-fluid pt-2">
                    <div className="row" style={{ textAlign: 'center', position: 'absolute', top: '50%', width: '100%' }}>
                        <h2>Welcome {employee_name}</h2>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
})(Dashboard);