import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, Collapse, Table } from 'antd';
import { CLIENT_GROUP } from '../../Store/types';
import { useLocation } from 'react-router-dom';
import { clientGroup, editClientsGroup } from '../../Store/Action/Transactions/ClientGroup';
import { ArrowLeftOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Add_EditClientGroup from './Add_EditClientGroup';
const _ = require("lodash");

const mapStateToProps = (state) => ({
})
const ClientGroup = ({
    clientGroup,
    editClientsGroup
}) => {
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    
    var [count1, setCount1] = useState(1);
    const [titledata, Settitledata] = useState()
    const paramid = location.pathname;
    const tableData = useSelector((state) => state.ClientGroup.clientgrouptable)
    console.log(tableData, 'tableData')
    const [renderAddpage, setRenderAddpage] = useState(false)
    const [renderEditPage,setRenderEditPage] = useState(false)
    console.log(renderAddpage,renderEditPage,'RenderPages')
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const { Panel } = Collapse;


    const addClientGroup = () => {
        setRenderAddpage(true)
        setRenderEditPage(false)
    }

    const editClientGroup = (item) => {
        console.log(item,'itemvalues')

       let payload = {
            "session_token": authtoken,
            "request": [
             "GetClientGroupsEdit",
             {
              "group_id": item.g_id
             }
            ]
           }

        editClientsGroup({
            payload:payload,
            caller:paramid
        })
        setRenderEditPage(true)
    }


    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData) {
            const ab = tableData.map(function (item) {
                if ((array.indexOf(item.group_name) < 0))
                    array.push(item.group_name)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let groupid = 0
                if (data) {
                    tableData.map((child, childIndex) => {
                        if (data === child.group_name) {
                            entityArray = {
                                'concat_product_id': child.concat_product_id,
                                'country_name': child.country_name,
                                'custom_report_status': child.custom_report_status,
                                'email_id': child.email_id,
                                'enable_ltgs': child.enable_ltgs,
                                'group_id': child.group_id,
                                'group_name': child.group_name,
                                'is_active': child.is_active,
                                'is_apply_ip_settings': child.is_apply_ip_settings,
                                'is_approved': child.is_approved,
                                'is_closed_cg': child.is_closed_cg,
                                'is_sso_enabled': child.is_sso_enabled,
                                'legal_entity_name': child.legal_entity_name,
                                'no_of_view_licence': child.no_of_view_licence,
                                'otp_remarks': child.otp_remarks,
                                'otp_required': child.otp_required,
                                'product': child.product,
                                'remarks': child.remarks,
                                'short_name': child.short_name,
                                'collapseId': i,
                                'childId': childIndex,
                                'parentId': i,
                                'indexNo': count1
                            }
                            temp.push(entityArray)
                            groupid= child.group_id
                            { <span hidden>{count1 = count1 + 1}</span> }
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp,
                    g_id:groupid
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [tableData])


    useEffect(() => {
        let payload = {
            "session_token": authtoken,
            "request": [
                "GetClientGroupsDetails",
                {
                    "group_id": null
                }
            ]
        }

        clientGroup({
            type: CLIENT_GROUP,
            payload: payload,
            caller: paramid
        })
    }, [])

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Client Group</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center 
                        justify-content-end">
                        {
                           ( renderAddpage || renderEditPage )? <Button
                                type="primary"
                                shape="round"
                                className='addbutton'
                                icon={<ArrowLeftOutlined />}
                                size='default'
                                onClick={()=>{
                                    setRenderEditPage(false)
                                    setRenderAddpage(false)
                                }}
                            >
                                Back
                            </Button>
                                :
                                <Button
                                    type="primary"
                                    shape="round"
                                    className='addbutton'
                                    icon={<PlusCircleOutlined />}
                                    size='default'
                                    onClick={addClientGroup}
                                >
                                    Add
                                </Button>
                        }
                    </div>
                </div>
            </div>
            {(renderAddpage || renderEditPage) ? <Add_EditClientGroup props={{ name: `${renderAddpage ? 'Add' : 'Edit'}` }} /> :
                <div className='card-body pt-0 custom-collapse'>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header={'Client Group'} key={1}>
                            <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper   "} >
                                <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                    <div className="ant-table-container">
                                        <div className="ant-table-content taskrecalltable  ">
                                            <table>
                                                <thead className="ant-table-thead">
                                                    <tr>
                                                        <th className="ant-table-cell">Client Group</th>
                                                        <th className="ant-table-cell">Country</th>
                                                        <th className="ant-table-cell">Legal Entity Name</th>
                                                        <th className="ant-table-cell">Product</th>
                                                        <th className="ant-table-cell">Status</th>
                                                        <th className="ant-table-cell">Approval Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='ant-table-tbody'>
                                                    {
                                                        titledata ? titledata.map((item) => {
                                                            return <>
                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                    <td className='ant-table-cell border-0  border-bottom'> <span className='text-start px-4'>{item.parent}</span> </td>
                                                                    <td colSpan={4} className='ant-table-cell border-0 border-top border-bottom'>  </td>
                                                                    <td className='ant-table-cell'> <span className='text-start px-4'><EditOutlined  onClick={()=>{editClientGroup(item)}}/></span></td>


                                                                </tr>
                                                                {

                                                                    item.child?.map((data) => {
                                                                        return (
                                                                            <>
                                                                                <tr className="ant-table-row ant-table-row-level-0 ">
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.group_name}</span></td>
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.country_name}</span></td>
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.legal_entity_name}</span></td>
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.product}</span></td>
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.is_closed_cg === 1 ? 'In Active' : 'Active'}</span></td>
                                                                                    <td className="ant-table-cell"><span className='text-start px-4'>{data.is_approved === 1 ? 'Approved' : 'Pending'}</span></td>
                                                                                </tr>
                                                                            </>)
                                                                    })

                                                                }
                                                            </>
                                                        }) : <>No Records Found !</>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            }
        </div >
    )
}
export default connect(mapStateToProps, {
    clientGroup,
    editClientsGroup
})(ClientGroup);