import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { ASSIGN_CLIENT_LIST, ASSIGN_CLIEBT_VIEW,ASSIGN_CLIENT_UNIT_DETAILS,ASSIGN_CLIENT_UNIT_VENDOR } from '../../types/index'

const initialState = {
    assignclient: {
        list: [],
        viewlist: [],
        unitlist:[],
        assignunit:[]
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGN_CLIENT_LIST:
            {
                return {
                    ...state,
                    assignclient: {
                        ...state.assignclient,
                        list: payload,
                    },
                };
            }
        case ASSIGN_CLIEBT_VIEW:
            {
                return {
                    ...state,
                    assignclient: {
                        ...state.assignclient,
                        viewlist: payload,
                    },
                };
            }
            case ASSIGN_CLIENT_UNIT_DETAILS:
            {
                return {
                    ...state,
                    assignclient: {
                        ...state.assignclient,
                        unitlist: payload,
                    },
                };
            }
            case ASSIGN_CLIENT_UNIT_VENDOR:
            {
                return {
                    ...state,
                    assignclient: {
                        ...state.assignclient,
                        assignunit: payload,
                    },
                };
            }
        default:
            return state;
    }
}