import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Modal, Input, Table, Button, Space, Tooltip, Select, Tex, DatePicker } from 'antd';
import { gettasklist, addtaskextension, gettaskduedate, gethistory, updateextenddate } from './../../Store/Action/Transactions/Taskduedate';
import moment from 'moment';
import { PlusOutlined, HistoryOutlined, EditOutlined, PlayCircleOutlined, PlusCircleOutlined, FullscreenOutlined, UpOutlined } from '@ant-design/icons';
import { Toaster } from '../../Libs/Toaster'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
const _ = require("lodash");


const mapStateToProps = (state) => ({
    list: state.Taskduedate.TaskDuedate.list,
    statutorydate: state.Taskduedate.TaskDuedate.duedate,
    listdata: state.Taskduedate.TaskDuedate.list.extn_due_info,
    historydata: state.Taskduedate.TaskDuedate.history.due_extension_history_list
})
const { Option } = Select;
const { TextArea } = Input;

const TaskDueDate = ({
    gettasklist,
    list,
    listdata,
    historydata,
    statutorydate,
    addtaskextension, gettaskduedate,
    gethistory,
    updateextenddate

}) => {
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [AddFormSubmitt, setAddFormSubmitt] = useState(false)
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [modalVisible, SetmodalVisible] = useState(false);
    const [extended_due,Setextended_due] = useState(false);
    const [historyModal, sethistoryModal] = useState(false);
    const [rewrite, setrewrite] = useState(false);
    const [taskdue, Settaskdue] = useState({
        country: '',
        cycle: '',
        domain: '',
        domaindate: '',
        sta_date: '',
        nature: '',
        naturedata: '',
        extend_date: '',
        act: '',
        actdata: '',
        remarks: '',
        taskcode: '',
        taskcodedata: '',
        histotyid: '',
        frequency: '',
    })
    console.log(taskdue, 'taskdue')

    const [taskdueextend, Settaskdueextend] = useState({
        country: '',
        cycle: '',
        domain: '',
        sta_date: '',
        nature: '',
        extend_date: '',
        act: '',
        remarks: '',
        taskcode: '',
        histotyid: '',
        compl_id: '',
        extn_id: '',
        frequency_id: '',
    })

    const [customizedata, setcutomizedata] = useState({
        statutorydatte: '',
        remarks: '',
        status: '',
        extn_id: '',
        comp_hst_id: '',
        task_code: '',
        comp_task: ''
    })
    const [complianceInfo, setComplianceInfo] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [customizedArray, setCustomizedArray] = useState([])
    console.log(filteredArray, 'filteredArray')
    const [datavelue, setdatevalue] = useState()

    const [hideFields, setHideFields] = useState('none');
    const [hideShow, setHideShow] = useState('none');
    const [paginationSize, setPaginationSize] = useState(25);
    const [page, setPage] = useState(1);
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '3%',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * paginationSize + index + 1
            }
        },
        {
            title: 'Task Code',
            dataIndex: 'task_code',
            key: 'task_code',
            width: '12%',
        },
        {
            title: 'Task Name',
            dataIndex: 'comp_task',
            key: 'comp_task',
            // ellipsis: true,
            width: '250px'
        },
        {
            title: 'Repeats Every',
            dataIndex: 'false',
            key: 'false',
            width: '13%',
            align: 'left',
            // ellipsis: true,
            render: (text, record) => {
                console.log(record, 'record');
                let dayMonthYear = record.r_type_id === 1 ? 'Day(s)' : record.r_type_id === 2 ? 'Month(s)' : 'Year(s)'
                return (
                    <span>{`Repeat Every ${record.r_every} ${dayMonthYear}`}</span>
                )
            }
        },
        {
            title: 'Statutory Cycle',
            dataIndex: 'false',
            width: '10%',
            key: 'false',
            align: 'left',
            render: (text, record) => {
                console.log(record, 'record');
                let monthName = (record.statu_due_month == 1 ? 'January' : (record.statu_due_month == 2 ? 'February' : (record.statu_due_month == 3 ? 'March' : (record.statu_due_month == 4 ? 'April' : (record.statu_due_month == 5 ? 'May' : (record.statu_due_month == 6 ? 'June' : (record.statu_due_month == 7 ? 'July' : (record.statu_due_month == 8 ? 'August' : (record.statu_due_month == 9 ? 'September' : (record.statu_due_month == 10 ? 'October' : (record.statu_due_month == 11 ? 'November' : (record.statu_due_month == 12 ? 'December' : ''))))))))))))
                return (
                    <span>{`${monthName} ${record.statu_due_year}`}</span>
                )
            }
        },
        {
            title: 'Extension Statutory Date',
            dataIndex: 'extn_due_date',
            key: 'extn_due_date',
            width: '9%',
            align: 'left'
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            width: '15%',
            align: 'left',
            render: (data) => {
                return <span className='text-wrap-report'>{data}</span>
            }

        },
        {
            title: 'Status',
            dataIndex: 'false',
            key: 'false',
            align: 'left',
            width: '7%',
            render: (text, record) => {
                // console.log(record, 'text');
                let status = (record.is_approved == 0 ? 'Pending' : (record.is_approved == 2 ? 'Approved'
                 : (record.is_approved == 3 ? 'Approved ' : (record.is_approved == 4 ? 'Rejected' : ''))))
                // console.log(status, 'status')
                return (
                    <span>{status}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'false',
            key: 'false',
            align: 'center',
            width: '7%',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div className='d-flex'>
                            {record.is_approved != 0 ?
                                <>
                                    <Tooltip placement="topLeft" title="Click here to View Revised Changes" className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={(e) => { showhistory(record.extn_id) }}>
                                        <HistoryOutlined /></Tooltip>&nbsp;
                                    <Tooltip placement="topLeft" title="Click here to Further Extend" className="btn btn-sm btn-light-danger text-danger btn-circle d-flex 
                             align-items-center justify-content-center" onClick={(e) => { showrewrite(record) }}>
                                        <EditOutlined /></Tooltip>
                                </>
                                :
                                record.cnt >= 1 ?
                                    <Tooltip placement="topLeft" title="Click here to View Revised Changes" className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center" onClick={(e) => { showhistory(record.extn_id) }} >
                                        <HistoryOutlined /></Tooltip>

                                    : ''

                            }

                        </div>

                    </Fragment>
                )

            }
        }
    ]

    const historyColumn = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '50px',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * paginationSize + index + 1
            }
        },
        {
            title: 'Statutory Date',
            dataIndex: 'compliance_due_date',
            key: 'compliance_due_date',
            align: 'center',
            width: '90px',
        },
        {
            title: 'Extended Statutory Date',
            dataIndex: 'extn_due_date',
            key: 'extn_due_date',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Action',
            align: 'center',
            width: '70px',
            dataIndex: 'is_approved',
            render: (text, record) => {
                let status = (record.is_approved == 0 ? 'Pending' : (record.is_approved == 2 ? 'Approved'
                 : (record.is_approved == 3 ? 'Approved' : (record.is_approved == 4 ? 'Rejected' : ''))))
                console.log(status, 'status')
                return (
                    <span>{status}</span>
                )
                // return (
                //     <Fragment>
                //         {text == '2' ?
                //             <span>Approved</span> :
                //             <span>Pending</span>
                //         }
                //     </Fragment>)

            }
        },
        {
            title: 'Updated On',
            dataIndex: 'update_on',
            key: 'update_on',
            width: '100px',
            align: 'center',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            width: '120px',
        },
        {
            title: 'Approver Remarks',
            dataIndex: 'remarks_approver',
            width: '100px',
            align: 'center',
            key: 'remarks_approver',
        },

    ]

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetTaskDueExtension",
                    {}
                ]
            }

            gettasklist({
                payload: pay,
                paramid: paramid

            })
        }
    }, [isAuth])

    useEffect(() => {
        if (list) {
            let temp = []
            let temparr = []
            let data;
            var jsonObj = {};
            console.log(list, 'list');
            setComplianceInfo(list.compliance_info)
            //     const extn = list.extn_due_info;
            //     for(let i=0;i<extn.length;i++){
            //       data = extn[i].comp_hst_id;
            //       temp.push(data);
            //       setcutomizedata({
            //         ...customizedata,
            //         statutorydatte: extn[i].extn_due_date,
            //         remarks: extn[i].remarks,
            //         statu:extn[i].is_approved
            //       })
            //     }
            //    for(let j=0;j<temp.length;j++){
            //     let a = list.compliance_info.filter(e => e.history_id == temp[j])
            //     temparr.push(JSON.stringify(a));
            //    }
        }
    }, [list])
    console.log(customizedata, 'cus');

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
    }, [])

    useEffect(() => {
        if (complianceInfo && complianceInfo.length > 0) {
            let tempArrayNew = [];
            let extn_array = [];
            for (let i in list.extn_due_info) {
                const filterComplianceInfo = _.filter(complianceInfo, { history_id: list.extn_due_info[i].comp_hst_id })
                tempArrayNew.push(filterComplianceInfo)
            }
            var merged = [].concat.apply([], tempArrayNew)
            setFilteredArray(merged)

        }
    }, [complianceInfo])

    useEffect(() => {
        let temp = []
        if (listdata && listdata.length > 0) {
            listdata.map((sublist) => {
                const getTaskDetails = _.find(list.compliance_info, { history_id: sublist.comp_hst_id })
                console.log(getTaskDetails, 'getTraskDetails')
                temp.push({
                    statu_due_month: sublist.statu_due_month,
                    statu_due_mode: sublist.statu_due_mode,
                    statu_due_year: sublist.statu_due_year,
                    extn_due_date: sublist.extn_due_date,
                    remarks: sublist.remarks,
                    is_approved: sublist.is_approved,
                    extn_id: sublist.extn_id,
                    compliance_due_date: sublist.compliance_due_date,
                    comp_id: sublist.comp_id,
                    history_id: sublist.comp_hst_id,
                    cnt: sublist.cnt,
                    task_code: getTaskDetails.task_code,
                    comp_task: getTaskDetails.comp_task,
                    r_every: getTaskDetails.r_every,
                    r_type_id: getTaskDetails.r_type_id,
                })
            })
            setCustomizedArray(temp)
        }
    }, [listdata])

    const addopen = () => {
        SetmodalVisible(true);
    }

    const modalcancel = () => {
        SetmodalVisible(false);
        Settaskdue({
            ...taskdue,
            country: '',
            cycle: '',
            domain: '',
            domaindate: '',
            sta_date: '',
            nature: '',
            naturedata: '',
            extend_date: '',
            act: '',
            actdata: '',
            remarks: '',
            taskcode: '',
            taskcodedata: '',
            histotyid: '',
            frequency: '',
        })

        setHideFields('none')
        setAddFormSubmit(false);
        // window.location.reload();
    }

    useEffect(() => {
        if (taskdue.country && taskdue.country != '') {
            const domain = _.filter(list.domain_info, { c_id: parseInt(taskdue.country.value) });
            const nature = _.filter(list.nature_info, { c_id: parseInt(taskdue.country.value) });
            Settaskdue({
                ...taskdue,
                domaindate: domain,
                naturedata: nature
            })
        }
    }, [taskdue.country])

    useEffect(() => {
        if (taskdue.domain && taskdue.domain != '') {
            const act = _.filter(list.statutory_info, { c_id: parseInt(taskdue.country.value), d_id: parseInt(taskdue.domain.value) });
            Settaskdue({
                ...taskdue,
                actdata: act
            })
        }
    }, [taskdue.domain])

    useEffect(() => {
        if (taskdue.act && taskdue.act != '') {
            console.log(taskdue, 'taskdue.act')
            const taskcode = _.filter(list.compliance_info, {
                country_id: parseInt(taskdue.country.value),
                domain_id: parseInt(taskdue.domain.value), statutory_id: parseInt(taskdue.act.value), frequency_id: parseInt(taskdue.frequency)
            })
            console.log(list.compliance_info, 'taskcode')
            Settaskdue({
                ...taskdue,
                taskcodedata: Array.from(new Set(taskcode))
            })
        }
    }, [taskdue.act])

    useEffect(() => {
        if (taskdue.taskcode && taskdue.taskcode != '') {
            const hist = _.filter(list.compliance_info, { comp_id: parseInt(taskdue.taskcode.value) })
            Settaskdue({
                ...taskdue,
                histotyid: hist[0].history_id
            })

            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetComplianceCycle",
                    {
                        "country_id": parseInt(taskdue.country.value),
                        "domain_id": parseInt(taskdue.domain.value),
                        "comp_id": parseInt(taskdue.taskcode.value)
                    }
                ]
            }

            gettaskduedate({
                payload: pay,
                paramid: paramid
            })
        }
    }, [taskdue.taskcode])

    useEffect(() => {
        if (statutorydate && taskdue.frequency == 2) {
            if (taskdue.cycle.value == '1') {
                console.log(taskdue.cycle, 'taskdue.cycle1')
                const duedate = statutorydate.extn_due_date_list;
                const statdate = _.filter(duedate, '0')
                Settaskdue({
                    ...taskdue,
                    sta_date: statdate[0][0].date,
                    extend_date: moment(statdate[0][0].date).add(1, 'days').format('YYYY-MM-DD')
                })
            } else {
                console.log(taskdue.cycle, 'taskdue.cycle2')

                const duedate = statutorydate.extn_due_date_list;
                const statdate = _.filter(duedate, '1')
                console.log(statdate, 'statutorydate')

                Settaskdue({
                    ...taskdue,
                    sta_date: statdate[0][1].date,
                    extend_date: moment(statdate[0][1].date).add(1, 'days').format('YYYY-MM-DD')
                })
            }


        }
    }, [taskdue.cycle])

    const addtaskdue = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            let checkExistingData = _.find(listdata && listdata.length > 0 && listdata, { comp_id: parseInt(taskdue.taskcode.value), compliance_due_date: moment(new Date(taskdue.sta_date)).format('DD-MMM-YYYY') })
            console.log(moment(new Date(taskdue.sta_date)).format('DD-MMM-YYYY'), 'newdate')
            console.log(checkExistingData, 'checkExistingData')
            if (checkExistingData) {
                Toaster.error('Extension already applied to this period')
            } else {
                const datesplit = moment(taskdue.sta_date).format('DD-MM-YYYY').split('-')
                let statu_due_mode_var = 1;
                if (taskdue.frequency == 2) {
                    statu_due_mode_var = parseInt(taskdue.cycle.value)
                } else {
                    statu_due_mode_var = 0
                }
                const payload = {
                    "session_token": authtoken,
                    "request": [
                        "SaveTaskDueExtension",
                        {
                            "compliance_id": parseInt(taskdue.taskcode.value),
                            "statu_due_mode": statu_due_mode_var,
                            "statu_due_month": parseInt(datesplit[1]),
                            "statu_due_year": datesplit[2],
                            "extn_due_date": moment(taskdue.extend_date).format('DD-MMM-YYYY'),
                            "remarks": taskdue.remarks,
                            "history_id": taskdue.histotyid,
                            "compliance_due_date": moment(taskdue.sta_date).format('YYYY-MM-DD'),
                            "extn_id": 0
                        }
                    ]
                }
                // Toaster.success('Extension already applied to this period')
                addtaskextension({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }

    const showhistory = (e) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetDueExtensionHistory",
                {
                    "extn_id": e
                }
            ]
        }
        gethistory({
            payload: pay,
            paramid: paramid
        })
        sethistoryModal(true);
    }

    const historyCancel = () => {
        sethistoryModal(false);
        Settaskdueextend({
            ...taskdueextend,
            remarks: ''
        })
    }

    const rewritecancel = () => {
        Settaskdueextend({
            ...taskdueextend,
            remarks: ''
        })
        setrewrite(false);
        setAddFormSubmitt(false);
        //window.location.reload();
    }

    const showrewrite = (e) => {
        console.log(e, 'record_Values')
        setrewrite(true);
        var frequency_name;
        const info = _.filter(list.compliance_info, { comp_id: e.comp_id })
        const count = _.filter(list.country_info, { c_id: info[0].country_id })
        const domain = _.filter(list.domain_info, { d_id: info[0].domain_id })
        const nature = _.filter(list.nature_info, { s_n_id: info[0].statutory_nature_id })
        const act = _.filter(list.statutory_info, { s_id: info[0].statutory_id })
        console.log(info, 'infovalue')
        if (info[0].frequency_id == 3) {
            frequency_name = 'Review'
        } else {
            frequency_name = 'Periodical'
        }

        Settaskdueextend({
            ...taskdueextend,
            extend_date: moment(e.extn_due_date).add(1, 'days').format('YYYY-MM-DD'),
            sta_date: e.compliance_due_date,
            taskcode: info[0].comp_task,
            country: count[0].c_name,
            domain: domain[0].d_name,
            nature: nature[0].s_n_name,
            cycle: e.statu_due_mode,
            act: act[0].a_names,
            compl_id: e.comp_id,
            histotyid: e.history_id,
            extn_id: e.extn_id,
            frequency_id: frequency_name
        })
    }

    const edittaskdue = (e) => {
        //   e.preventDefault();
        setAddFormSubmitt(true);
        if (formValidator1.current.allValid()) {
            const datesplit = moment(taskdueextend.sta_date).format('DD-MM-YYYY').split('-')
            let statu_due_mode_var = 1;
            if (taskdueextend.frequency == 2) {
                statu_due_mode_var = parseInt(taskdueextend.cycle.value)
            } else {
                statu_due_mode_var = 0
            }
            const pay = {
                "session_token": authtoken,
                "request": [
                    "SaveTaskDueExtension",
                    {
                        "compliance_id": taskdueextend.compl_id,
                        "statu_due_mode": statu_due_mode_var,
                        "statu_due_month": parseInt(datesplit[1]),
                        "statu_due_year": datesplit[2],
                        "extn_due_date": moment(taskdueextend.extend_date).format('DD-MMM-YYYY'),
                        "remarks": taskdueextend.remarks,
                        "history_id": taskdueextend.histotyid,
                        "compliance_due_date": moment(taskdueextend.sta_date).format('YYYY-MM-DD'),
                        "extn_id": taskdueextend.extn_id
                    }
                ]
            }
            updateextenddate({
                payload: pay,
                paramid: paramid
            })
        }


    }

    const onshow = (e) => {
        if (e == 2) {
            setHideFields('block')
            setHideShow('block')
        } else if (e == 3) {
            setHideShow('none')
            setHideFields('block')
        } else {
            setHideFields('none')
            setHideShow('none')
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div>
                <div id="page-wrapper" className="page-wrapper">
                    <ScrollToTop
                        smooth
                        color="red"
                        component={
                            <Tooltip title="Back to top">
                                <UpOutlined />
                            </Tooltip>
                        }
                    />
                    <div className="page-titles py-1 page-title-sticky">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span>Task Due Date Extension List</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">

                                <div className="search-box">
                                    {/* <input class="search-txt" type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a class="search-btn">
                                        <i class="fas fa-search"></i>
                                    </a> */}
                                </div>
                                <div>
                                    <Tooltip title="Add Task"><Button type="primary" shape="round" className='addbutton' onClick={addopen}
                                        icon={<PlusCircleOutlined />} size='default'>
                                        Add
                                    </Button></Tooltip>
                                </div>
                                <div>
                                    <button
                                        onClick={(e) => {
                                            fullscreenMode();
                                        }}
                                        className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                                    >
                                        <Tooltip
                                            placement="left"
                                            style={{ marginRight: "10px", }}
                                            title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                        >
                                            <FullscreenOutlined />
                                        </Tooltip>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0 service-provider-table">

                        <Table
                            columns={columns}
                            dataSource={customizedArray}
                            //dataSource={datavelue[0]}
                            bordered
                            pagination={false}
                        //onChange={handleChange} 
                        />

                    </div>
                </div>
                <Modal title="Task Due Date Extension" visible={modalVisible} footer={null} maskClosable={false}
                    className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                    onCancel={modalcancel}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Country :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Select Country"
                                    name="Country"
                                    className='form-control'
                                    id="Country"
                                    value={taskdue.country || undefined}
                                    onChange={(data, value) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                country: '',
                                                cycle: '',
                                                domain: '',
                                                domaindate: '',
                                                sta_date: '',
                                                nature: '',
                                                naturedata: '',
                                                extend_date: '',
                                                act: '',
                                                actdata: '',
                                                remarks: '',
                                                taskcode: '',
                                                taskcodedata: '',
                                                histotyid: '',
                                                frequency: '',
                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                country: value
                                            })
                                        }
                                    }}>
                                    {
                                        list && list.country_info.length > 0 && list.country_info.map((item) => {
                                            return <Option key={item.c_id}>{item.c_name}</Option>
                                        })
                                    }
                                </Select>
                                {formValidator.current.message(
                                    'country',
                                    taskdue.country,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Country is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Domain :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Select Domain"
                                    name="Domain"
                                    className='form-control'
                                    id="Domain"
                                    value={taskdue.domain || undefined}
                                    disabled={taskdue.country == '' ? true : false}
                                    onChange={(data, value) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                domain: '',
                                                frequency: '',
                                                nature: '',
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',


                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                domain: value,
                                                frequency: '',
                                                nature: '',
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',


                                            })
                                        }
                                    }}>
                                    {taskdue.domaindate && taskdue.domaindate.length > 0 && Array.isArray(taskdue.domaindate) &&
                                        taskdue.domaindate.map((item) => {
                                            return <Option key={item.d_id}>{item.d_name}</Option>
                                        })}
                                </Select>
                                {formValidator.current.message(
                                    'Domain',
                                    taskdue.domain,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Domain Name is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Frequency :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    defaultValue={'Select Frequency'}
                                    placeholder="Select Frequency"
                                    name="frequency"
                                    className='form-control'
                                    id="frequency"
                                    value={taskdue.frequency || undefined}
                                    disabled={taskdue.domain == '' ? true : false}
                                    onChange={(data) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                frequency: '',
                                                nature: '',
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',
                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                frequency: data,
                                                nature: '',
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',
                                            })
                                            onshow(data)
                                        }
                                    }}>

                                    <Option value='2'>Periodical</Option>
                                    <Option value='3'>Review</Option>
                                </Select>
                                {formValidator.current.message(
                                    'frequency',
                                    taskdue.frequency,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Frequency is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Statutory Nature :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Select StatutoryNature"
                                    name="Nature"
                                    className='form-control'
                                    id="Nature"
                                    value={taskdue.nature || undefined}
                                    onChange={(data, value) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                nature: '',
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',
                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                nature: value,
                                                act: '',
                                                taskcode: '',
                                                cycle: '',
                                                sta_date: '',
                                                extend_date: '',
                                            })
                                        }
                                    }}>

                                    {
                                        taskdue.naturedata && Array.isArray(taskdue.naturedata) && taskdue.naturedata.length > 0 &&
                                        taskdue.naturedata.map((e) => {
                                            return <Option key={e.s_n_id}>{e.s_n_name}</Option>
                                        })}
                                </Select>
                                {formValidator.current.message(
                                    'Nature',
                                    taskdue.nature,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Statutory Nature is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Act :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Select Act"
                                    name="Act"
                                    className='form-control'
                                    id="Act"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    value={taskdue.act || undefined}
                                    onChange={(data, value) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                act: '',
                                                taskcode: '',
                                                sta_date: '',
                                                cycle: '',
                                                extend_date: '',
                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                act: value,
                                                taskcode: '',
                                                sta_date: '',
                                                cycle: '',
                                                extend_date: '',
                                            })
                                        }
                                    }}>
                                    {taskdue.actdata && Array.isArray(taskdue.actdata) && taskdue.actdata.length > 0 &&
                                        taskdue.actdata.map((item) => {
                                            return <Option key={item.s_id}>{item.a_names}</Option>
                                        })}
                                </Select>
                                {formValidator.current.message(
                                    'Act',
                                    taskdue.act,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Act is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Task Code \ Compliance Task :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Select Act"
                                    name="taskcode"
                                    className='form-control'
                                    id="taskcode"
                                    value={taskdue.taskcode || undefined}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={(data, value) => {
                                        if (data == undefined) {
                                            Settaskdue({
                                                ...taskdue,
                                                taskcode: '',
                                                sta_date: '',
                                                cycle: '',
                                                extend_date: '',
                                            })
                                        } else {
                                            Settaskdue({
                                                ...taskdue,
                                                taskcode: value,
                                                sta_date: '',
                                                cycle: '',
                                                extend_date: '',
                                            })
                                        }
                                    }}>
                                    {taskdue.taskcodedata && taskdue.taskcodedata.length > 0 && Array.isArray(taskdue.taskcodedata) &&
                                        taskdue.taskcodedata.map((item) => {
                                            return <Option key={item.comp_id}>{item.task_code}-{item.comp_task}</Option>
                                        })}
                                </Select>
                                {formValidator.current.message(
                                    'code',
                                    taskdue.taskcode,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Task Code \ Compliance Task is Required',
                                        }
                                    })}
                            </div>
                        </div>
                        {taskdue.frequency == 2 ?
                            <div className="col-md-6" style={{ display: hideShow }}>
                                <div className="mb-2">
                                    <label className="control-label"><b>Statutory Cycle :&nbsp;</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Select Statutory Cycle"
                                        name="StatutoryCycle"
                                        className='form-control'
                                        id="StatutoryCycle"
                                        value={taskdue.cycle || undefined}
                                        onChange={(data, value) => {
                                            let sta_date = statutorydate.extn_due_date_list
                                            let ext_date = sta_date[0][0].date
                                            if (data == undefined) {
                                                Settaskdue({
                                                    ...taskdue,
                                                    cycle: '',
                                                    //  extend_date: '',
                                                })
                                            } else {
                                                if (ext_date == 'None') {
                                                    Setextended_due(true);
                                                    Toaster.error('Task is not valid to process due date extension (Day / Month without proper statutory date)');
                                                } else {

                                                    Settaskdue({
                                                        ...taskdue,
                                                        cycle: value,
                                                        //  extend_date: '',
                                                    })
                                                }
                                            }


                                        }}>

                                        {/* <Option>Select StatutoryCycle</Option> */}
                                        <Option value='1'>Current</Option>
                                        <Option value='2'>Previous</Option>
                                    </Select>

                                    {formValidator.current.message(
                                        'StatutoryCycle',
                                        taskdue.cycle,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Statutory Cycle is Required',
                                            }
                                        })}
                                </div>
                            </div>
                            : ''}

                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Statutory Date :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                {taskdue.frequency == 2 ?
                                    <Input
                                        type="text"
                                        id="stat_date"
                                        name='stat_date'
                                        autoComplete={'off'}
                                        placeholder="Statutory Date"
                                        value={taskdue.sta_date && taskdue.sta_date != 'None' ? moment(taskdue.sta_date).format('DD-MMM-YYYY') : undefined}
                                        readOnly
                                        style={{ borderRadius: '10px' }}
                                    />

                                    : <DatePicker getPopupContainer={trigger => trigger.parentNode} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={false}
                                        value={taskdue.sta_date ? moment(taskdue.sta_date) : ''}
                                        onChange={(date, dateString) => {
                                            Settaskdue({
                                                ...taskdue,
                                                sta_date: dateString
                                            })
                                        }}

                                    />}


                                {formValidator.current.message(
                                    'stat_date',
                                    taskdue.sta_date,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Statutory Date is Required'
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Extended Statutory Date :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <DatePicker getPopupContainer={trigger => trigger.parentNode} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={false}
                                    value={taskdue.extend_date != "" && taskdue.extend_date != 'Invalid date' ? moment(taskdue.extend_date) : undefined}
                                   disabled = {extended_due}
                                    onKeyDown={(event) => {
                                        event.preventDefault()
                                    }}
                                    onChange={(date, dateString) => {
                                        Settaskdue({
                                            ...taskdue,
                                            extend_date: dateString
                                        })
                                    }}
                                    disabledDate={(current) => {
                                        return moment(taskdue.extend_date).add(-1, 'days') >= current ||
                                            moment(taskdue.sta_date).add(1, 'year') < current;
                                    }}

                                />
                                {formValidator.current.message(
                                    'ext_date',
                                    taskdue.extend_date,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Extended Statutory Date is Required'
                                        }
                                    })}
                            </div>
                        </div>

                        <div className="col-md-6" style={{ display: hideFields }}>
                            <div className="mb-2">
                                <label className="control-label"><b>Remarks :&nbsp;</b></label>
                                <span style={{ "color": "red" }}>*</span>
                                <TextArea className="form-control" row='1' cols='3' style={{ height: '41px' }} id="remarks"
                                    name='remarks' value={taskdue.remarks || undefined} maxlength='500'
                                    placeholder='Remarks'
                                    onChange={(e) => {
                                        Settaskdue({
                                            ...taskdue,
                                            remarks: e.target.value
                                        })
                                    }}></TextArea>
                                {formValidator.current.message(
                                    'remarks',
                                    taskdue.remarks,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Remarks is Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12" >
                        <div className='row'>
                            <div className='col-lg-6' style={{ display: hideFields, textAlign: 'center', marginLeft: '24%' }}>
                                <Button type="primary" shape="round" className='addbutton custom-color-modal-button'

                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={addtaskdue}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </div>
                </Modal>
                {/* History */}
                <Modal title="History" footer={null} visible={historyModal} className={"historyClass add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                    onCancel={historyCancel}>
                    <Table
                        size={'small'}
                        columns={historyColumn}
                        dataSource={historydata}
                        bordered
                        pagination={false}
                    />

                </Modal>
                {/* Extension Rewrite */}
                <Modal title="Task Due Date Extension" visible={rewrite} footer={null} maskClosable={false}
                    className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                    onCancel={rewritecancel}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Country :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name='Country'
                                    autoComplete={'off'}
                                    placeholder="Enter Country"
                                    value={taskdueextend.country}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Domain :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name='Domain'
                                    autoComplete={'off'}
                                    placeholder="Enter StatutoryDate"
                                    value={taskdueextend.domain}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Frequency :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name='Country'
                                    autoComplete={'off'}
                                    placeholder="Enter Frequency"
                                    value={taskdueextend.frequency_id}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Statutory Nature :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name='Nature'
                                    autoComplete={'off'}
                                    placeholder="Enter StatutoryNature"
                                    value={taskdueextend.nature}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Act :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name='Act'
                                    autoComplete={'off'}
                                    placeholder="Enter Act"
                                    value={taskdueextend.act}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Task Code \ Compliance Task :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name='TaskCode'
                                    autoComplete={'off'}
                                    placeholder="Enter StatutoryDate"
                                    value={taskdueextend.taskcode}
                                    readOnly
                                />
                            </div>
                        </div>
                        {taskdueextend.frequency_id == 'Periodical' ?
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Statutory Cycle :</b></label>
                                    <span style={{ "color": "red" }}> *</span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name='Cycle'
                                        autoComplete={'off'}
                                        placeholder="Enter StatutoryCycle"
                                        value={taskdueextend.cycle == 1 ? 'Current' : 'Previous'}
                                        readOnly
                                    />
                                </div>
                            </div>
                            : ''}
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Statutory Date :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                {taskdueextend.frequency_id == 'Periodical' ?
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name='stat_date'
                                        autoComplete={'off'}
                                        placeholder="Enter StatutoryDate"
                                        value={taskdueextend.sta_date}
                                        readOnly
                                    />
                                    : <DatePicker getPopupContainer={trigger => trigger.parentNode} className="form-control" format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={false}
                                        value={taskdueextend.sta_date ? moment(taskdueextend.sta_date) : ''}
                                        onChange={(date, dateString) => {
                                            Settaskdueextend({
                                                ...taskdueextend,
                                                sta_date: dateString
                                            })
                                        }}

                                    />
                                }
                                {formValidator1.current.message(
                                    'stat_date',
                                    taskdueextend.sta_date,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmitt ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Extended Statutory Date is Required'
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Extended Statutory Date :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <DatePicker getPopupContainer={trigger => trigger.parentNode} className="form-control" format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={true}
                                    value={taskdueextend.extend_date ? moment(taskdueextend.extend_date) : ''}
                                    onKeyDown={(event) => {
                                        event.preventDefault()
                                    }}
                                    onChange={(date, dateString) => {
                                        Settaskdueextend({
                                            ...taskdueextend,
                                            extend_date: dateString
                                        })
                                    }}
                                    disabledDate={(current) => {
                                        return moment(taskdueextend.extend_date).add(-1, 'days') >= current ||
                                            moment(taskdueextend.sta_date).add(1, 'year') < current;
                                    }}

                                />
                                {/* <Input
                                    type="date"
                                    id="ext_date"
                                    name='ext_date'
                                    autoComplete={'off'}
                                    placeholder="Enter Extended Statutory"
                                    value={taskdueextend.extend_date}
                                    min={taskdueextend.extend_date}
                                    onChange={(e) => {
                                        Settaskdueextend({
                                            ...taskdueextend,
                                            extend_date: e.target.value
                                        })
                                    }}
                                /> */}
                                {formValidator1.current.message(
                                    'ext_date',
                                    taskdueextend.extend_date,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmitt ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Extended Statutory Date is Required'
                                        }
                                    })}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="control-label"><b>Remarks :</b></label>
                                <span style={{ "color": "red" }}> *</span>
                                <TextArea className="form-control" placeholder='Remarks' style={{ height: '32px' }}
                                    name='remarks' autoComplete='off'
                                    value={taskdueextend.remarks || undefined} maxlength='500'
                                    onChange={(e) => {
                                        Settaskdueextend({
                                            ...taskdueextend,
                                            remarks: e.target.value
                                        })
                                    }}></TextArea>
                                {formValidator1.current.message(
                                    'remarks',
                                    taskdueextend.remarks,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmitt ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Remarks is Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12" >
                        <div className='row'>
                            <div className='col-lg-6' style={{ textAlign: 'center', marginLeft: '24%' }}>
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}

                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={edittaskdue}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </div>
                </Modal>
            </div>
        </Fragment>
    )

}
export default connect(mapStateToProps, {
    gettasklist,
    addtaskextension, gettaskduedate,
    gethistory,
    updateextenddate
})(TaskDueDate);

//Old Customize code
  // useEffect(() => {
    //     let temp = []
    //     if (filteredArray && filteredArray.length > 0) {
    //         for (let i in filteredArray) {
    //             for (let j in list.extn_due_info) {
    //                 if (filteredArray[i].history_id == list.extn_due_info[j].comp_hst_id) {


    //                     // let filteredArrcheck = _.find(list.extn_due_info && list.extn_due_info.length && list.extn_due_info, { comp_hst_id: filteredArray[i].history_id, extn_id: filteredArray[i].extn_id })
    //                     // console.log(filteredArrcheck, 'filteredArrcheck')
    //                     temp.push({
    //                         task_code: filteredArray[i].task_code,
    //                         comp_task: filteredArray[i].comp_task,
    //                         r_every: filteredArray[i].r_every,
    //                         r_type_id: filteredArray[i].r_type_id,
    //                         // statu_due_month: filteredArrcheck.statu_due_month,
    //                         // statu_due_mode: filteredArrcheck.statu_due_mode,
    //                         // statu_due_year: filteredArrcheck.statu_due_year,
    //                         // extn_due_date: filteredArrcheck.extn_due_date,
    //                         // remarks: filteredArrcheck.remarks,
    //                         // is_approved: filteredArrcheck.is_approved,
    //                         // extn_id: filteredArrcheck.extn_id,
    //                         // compliance_due_date: filteredArrcheck.compliance_due_date,
    //                         // comp_id: filteredArrcheck.comp_id,
    //                         // history_id: filteredArrcheck.comp_hst_id
    //                         statu_due_month: list.extn_due_info[j].statu_due_month,
    //                         statu_due_mode: list.extn_due_info[j].statu_due_mode,
    //                         statu_due_year: list.extn_due_info[j].statu_due_year,
    //                         extn_due_date: list.extn_due_info[j].extn_due_date,
    //                         remarks: list.extn_due_info[j].remarks,
    //                         is_approved: list.extn_due_info[j].is_approved,
    //                         extn_id: list.extn_due_info[j].extn_id,
    //                         compliance_due_date: list.extn_due_info[j].compliance_due_date,
    //                         comp_id: list.extn_due_info[j].comp_id,
    //                         history_id: list.extn_due_info[j].comp_hst_id

    //                     })
    //                 }
    //             }

    //         }
    //     }
    //     setCustomizedArray(temp)

    // }, [filteredArray])