import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getassignstatutoryfilter, GetAssignStatutoryClientDetails, GetAssignedStatutories, assignedStatutoryCount, assignedStatutoryTwoData, addOption, addUnitsList, addTwoCount, addTwoData, SaveAssignedStatutory, SubmitAssignedStatutory } from '../../Store/Action/Transactions/DE_Assignstatutory';
import { REMARK_STATE } from '../../Store/types/index';
import { Collapse, Select, Button, DatePicker, Card, Table, Modal, Tabs, Tooltip, Alert, Checkbox, Space, Breadcrumb, Form, Input, Divider, TreeSelect } from 'antd';
import { EyeOutlined, ExportOutlined, FilterOutlined, FullscreenOutlined, UpOutlined, DownloadOutlined, EditTwoTone, InfoCircleTwoTone, EditOutlined, CheckCircleTwoTone, CheckCircleOutlined, CloseCircleTwoTone, CloseCircleOutlined, MinusCircleTwoTone, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { TOGGLE_COMMON_LOADER } from './../../Store/types/index';
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt';
import axios from 'axios';
import url from '../../Libs/URL';
import { decode as base64_decode } from 'base-64';
import { Toaster } from '../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import { toast } from 'react-toastify';

const mapStateToProps = (state) => ({
    deassignstatutorylegalentityfilter: state.DE_Assignstatutory.deassignstatutory.deassignstatutoryfilter,
    deassignstatutoryotherfilter: state.DE_Assignstatutory.deassignstatutory.deassignstatutoryotherfilter,
    getassignedstatutories: state.DE_Assignstatutory.deassignstatutory.getassignedstatutories,
    assignedStatutoryData: state.DE_Assignstatutory.deassignstatutory.assignedStatutoryTwoData,
    recordCount: state.DE_Assignstatutory.deassignstatutory.assignedStatutoryCount.total_records,
    addOptionData: state.DE_Assignstatutory.deassignstatutory.addOption,
    unitList: state.DE_Assignstatutory.deassignstatutory.addUnitsList.statu_units,
    remarkState: state.DE_Assignstatutory.deassignstatutory.remarkState,
    addUnitCount: state.DE_Assignstatutory.deassignstatutory.addTwoCount.total_records,
    addUnitTableData: state.DE_Assignstatutory.deassignstatutory.addTwoData,
    saveStatus: state.DE_Assignstatutory.deassignstatutory.SaveAssignedStatutory.status,
    dataCount: state.DE_Assignstatutory.deassignstatutory.ASTwoDataReceivedCount,
    submitStatus: state.DE_Assignstatutory.deassignstatutory.SubmitStatus.status,
});

const DE_Assignstatutory = ({
    getassignstatutoryfilter,
    deassignstatutorylegalentityfilter,
    GetAssignStatutoryClientDetails,
    deassignstatutoryotherfilter,
    GetAssignedStatutories,
    getassignedstatutories,
    assignedStatutoryCount,
    assignedStatutoryTwoData,
    assignedStatutoryData,
    recordCount,
    addOption,
    addOptionData,
    addUnitsList,
    unitList,
    remarkState,
    addTwoCount,
    addUnitCount,
    addTwoData,
    addUnitTableData,
    SaveAssignedStatutory,
    SubmitAssignedStatutory,
    saveStatus,
    dataCount,
    submitStatus
}) => {
    const _ = require("lodash");
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue');
    const paramid = location.pathname;
    const validator = useRef(new SimpleReactValidator());
    const addValidator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [addSubmit, setAddSubmit] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [selectOption, setSelectOption] = useState(false);
    const [applicationStatus, setApplicationstatus] = useState({
        applicable: null,
        notApplicable: null,

    });
    const [count, setCount] = useState({});
    const [addOptionFilter, setAddOptionFilter] = useState({
        group: '',
        groupId: '',
        legalEntityName: '',
        legalEntityId: '',
        domain: '',
        domainId: '',
        category: '',
        categoryId: '',
        businessGroup: '',
        businessGroupId: '',
        division: '',
        divisionId: ''
    });

    const [inputValues, setInputValues] = useState({
        clientId: '',
        clientName: '',
        legalEntityId: '',
        legalEntityName: '',
        legalEntityData: '',
        unitId: '',
        unitName: '',
        unitData: '',
        domainId: '',
        domainName: '',
        domainData: '',
        status: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addCollapse, setAddCollapse] = useState(false);
    const [activeTab, setActiveTab] = useState('2');
    const [arrRequest, setArrRequest] = useState([]);
    const [view, setView] = useState({});
    const [totalViewed, setTotalViewed] = useState(0);
    const [remarks, setRemarks] = useState([]);
    const [collapseIdenty, setCollapseIdenty] = useState({
        // [0] : true,
        // [1] : false,
        // [2] : true,
        // [3] : false
    });
    // const [option, setOption] = useState(1);
    const [option, setOption] = useState({});
    const [remarkInput, setRemarkInput] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const [unitListData, setUnitListData] = useState([]);
    const [unitIds, setUnitIds] = useState([]);
    const [reloadData, setReloadData] = useState([]);
    const triggerClick = useRef(null);
    const [rowCount, setRowCount] = useState(0);
    const [multiCompStatus, setMultiCompStatus] = useState({});
    const [singleUnit, setSingleUnit] = useState({});
    const [addSelectAllValues, setaddSelectAllValues] = useState([]);
    const [add_rcount, setadd_rcount] = useState(25);
    const [tableID, setTableID] = useState({});

    useEffect(() => {
        validator.current.showMessages();
        addValidator.current.showMessages();
        if (isAuth) {
            const pay =
            {
                "session_token": authtoken,
                "request": [
                    "GetAssignStatutoryFilter",
                    {}
                ]
            };
            getassignstatutoryfilter({
                payload: pay,
                paramid: paramid
            });
        }
    }, [isAuth]);

    useEffect(() => {
        // dispatch({
        //     type: REMARK_STATE,
        //     payload: ['helloWorld']
        // })
        setCollapseIdenty({
            ...remarkState
        });
    }, [remarkState]);

    useEffect(() => {
        if (saveStatus === 'Success') {
            setShowData(false);
            // setReloadData({});
            // setExitCollapse(true);
            // setFilterModalVisible(false);
            setShowTable(!true);
        }

        if (submitStatus === 'Success') {
            setShowData(false);
            setShowTable(!true);
        }

    }, [saveStatus, submitStatus]);

    const modalClose = () => {
        setIsModalOpen(false);
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
    };

    const homescreen = () => {
        window.location.href = '/dashboard';
    };

    const filterHandle = () => {
        setFilterModalVisible(true);
    };

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    };

    const showResult = () => {
        setAddFormSubmit(true);
        setAddCollapse(true);
        if (validator.current.allValid()) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutories",
                    {
                        "from_count": 0,
                        "page_count": 25,
                        "ct_id": parseInt(inputValues.clientId),
                        "le_id": parseInt(inputValues.legalEntityId),
                        "d_id": parseInt(inputValues.domainId),
                        "u_id": inputValues.unitId != '' ? parseInt(inputValues.unitId) : 0,
                        "status": inputValues.status != '' ? parseInt(inputValues.status) : 0,
                    }
                ]

            };

            GetAssignedStatutories({
                payload: payload,
                paramid: paramid
            });

            setShowData(false);
            setExitCollapse(true);
            setFilterModalVisible(false);
            setShowTable(!true);
            setRowCount(rowCount + 50);
            setSelectedValues([]);
        }
    };

    const getLegalEntityData = (data, value) => {
        setInputValues({
            ...inputValues,
            clientId: data,
            clientName: value.children
        });
        const pay =
        {
            "session_token": authtoken,
            "request": [
                "GetAssignStatutoryClientDetails",
                {
                    "ct_id": parseInt(data)
                }
            ]
        };
        GetAssignStatutoryClientDetails({
            payload: pay,
            paramid: paramid
        });
    };

    const getOtherDetails = (data, value) => {
        let getDomainDatas = _.filter(deassignstatutoryotherfilter.dms, { legal_entity_id: parseInt(data) });
        setInputValues({
            ...inputValues,
            legalEntityId: data,
            legalEntityName: value.children,
            domainData: getDomainDatas
        });
    };

    const getUnitDetails = (data, value) => {
        let unitArray = [];
        let tempArray = [];
        let getUnitDatas = _.filter(deassignstatutoryotherfilter.uts, { legal_entity_id: parseInt(inputValues.legalEntityId) });
        getUnitDatas && getUnitDatas.length > 0 && getUnitDatas.map((unitdetails) => {
            const checkDomainExists = unitdetails.d_ids.includes(parseInt(data));
            if (checkDomainExists) {
                tempArray = {
                    'legal_entity_id': unitdetails.legal_entity_id,
                    'unit_id': unitdetails.unit_id,
                    'unit_name': unitdetails.unit_name
                };
                return unitArray.push(tempArray);
            }
        });
        setInputValues({
            ...inputValues,
            domainId: data,
            domainName: value.children,
            unitData: unitArray
        });

    };

    useEffect(() => {
        if (deassignstatutoryotherfilter != '') {
            setInputValues({
                ...inputValues,
                legalEntityData: deassignstatutoryotherfilter.lety
            });
        }
    }, [deassignstatutoryotherfilter]);

    const columns = [
        {
            title: "#",
            width: '35px',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "Country",
            dataIndex: "c_name",
            key: "c_name",
            align: 'center',
            width: "75px",
        },
        {
            title: "Group",
            dataIndex: "grp_name",
            key: "grp_name",
            align: 'center',
            width: "93px"
        },
        {
            title: "Business Group",
            dataIndex: "b_grp_name",
            key: "b_grp_name",
            align: 'center',
            width: "120px"
        },
        {
            title: "Legal Entity",
            dataIndex: "l_e_name",
            key: "l_e_name",
            align: 'center',
            width: "160px"
        },
        {
            title: "Division",
            dataIndex: "div_name",
            key: "div_name",
            align: 'center',
            width: "80px",
            render: (text) => {
                if (text == null) {
                    return <>-</>;
                } else {
                    return text;
                }
            }
        },
        {
            title: "Category",
            dataIndex: "cat_name",
            key: "cat_name",
            align: 'center',
            width: "80px",
            render: (text) => {
                if (text == null) {
                    return <>-</>;
                } else {
                    return text;
                }
            }
        },
        {
            title: "Location",
            dataIndex: "g_name",
            align: 'center',
            key: "g_name",
            width: "93px"
        },
        {
            title: "Unit/Vendor",
            dataIndex: "u_name",
            key: "u_name",
            align: 'center',
            width: "15%"
        },
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            align: 'center',
            width: "15%"
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            align: 'center',
            width: "8%",
        },
        {
            title: "Status",
            dataIndex: "a_s_id",
            key: "a_s_id",
            align: 'center',
            width: "8%",
            render: (text, record, index) => {
                let currentStatus;
                if (text == 1) {
                    currentStatus = 'Yet to submit';
                } else if (text == 2) {
                    currentStatus = 'Pending';
                } else if (text == 3 && record.is_editable == true) {
                    currentStatus = 'Approved';
                } else if (text == 3 && record.is_editable == false) {
                    currentStatus = 'Assigned';
                } else if (text == 4) {
                    currentStatus = 'Rejected';
                } else if (text == 5) {
                    currentStatus = 'Assigned';
                } else {
                    currentStatus = '';
                }
                return currentStatus;
            },
        },
        {
            title: "Edit",
            align: 'center',
            width: "5%",
            render: (text, record, index) => {
                if (record.is_editable) {
                    return <EditTwoTone onClick={() => {
                        setArrRequest([]);
                        const pay = {
                            "session_token": authtoken,
                            "request": [
                                "GetAssignedStatutoryWizardTwoCount",
                                {
                                    "d_id": record.d_id,
                                    "unit_ids": [
                                        record.u_id,
                                    ]
                                }
                            ]
                        };

                        assignedStatutoryCount({
                            payload: pay,
                            paramid: paramid
                        });

                        const temp = record.b_grp_name;
                        const unitVendor = record.u_name;
                        const clientSatutoryId = record.client_statutory_id;
                        const divisionBread = record.div_name;
                        const categoryBread = record.cat_name;

                        setInputValues({
                            ...inputValues,
                            businessGroupBread: temp,
                            unitVendor: unitVendor,
                            clientSatutoryId: clientSatutoryId,
                            unitId: record.u_id,
                            divisionBread: divisionBread,
                            categoryBread: categoryBread
                        });

                        const pay2 = {
                            "session_token": authtoken,
                            "request": [
                                "GetAssignedStatutoryWizardTwoData",
                                {
                                    "d_id": record.d_id,
                                    "unit_ids": [record.u_id],
                                    "rcount": 0
                                }
                            ]
                        };

                        assignedStatutoryTwoData({
                            payload: pay2,
                            paramid: paramid
                        });

                        setActiveTab('2');
                        setExitCollapse(true);
                        setAddCollapse(true);
                        setFilterModalVisible(false);
                        setShowTable(true);
                        setShowData(true);
                    }} />;
                }
            },
        },
    ];

    let index = 0;
    let reqArr = arrRequest;

    const sharedOnCell = (record, index) => {
        if (record.title == undefined) {
            return {
                colSpan: 1,
            };
        }
        return { colSpan: 0 };
    };

    const assignedStatutorycolumns = [
        {
            title: "#",
            dataIndex: "level_1_s_id",
            key: "level_1_s_id",
            align: 'center',
            width: '35px',
            ellipsis: true,
            onCell: sharedOnCell,
            render: (text, record) => {
                // return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>
                if (record.title == undefined) {
                    if (count[record.comp_id] === null) {
                        return <span style={{ color: '#00008B' }}>{++index}</span>;
                    } else {
                        return <>{++index}</>;
                    }
                }
            }
        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_provision',
            key: 's_provision',
            // align: 'left',
            width: '75px',
            ellipsis: true,
            onCell: (record, index) => ({
                colSpan: record.title ? 8 : 1,
                align:  record.title ? 'left' : 'center'
            }),
            render: (text, record) => {
                if (record.title != undefined) {
                    return <b className='px-3'>{text}</b>;
                } else if (count[record.comp_id] === null) {
                    return <span style={{ color: '#00008B' }}>{text}</span>;
                } else {
                    return <span>{text}</span>;
                }
            }
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
            // align: 'center',
            width: '150px',
            onCell: sharedOnCell,
            render: (text, record) => {
                if (record.title == undefined) {
                    if (count[record.comp_id] === null) {
                        return (
                            <>
                                <Tooltip overlayStyle={{ maxWidth: '530px' }} overlayInnerStyle={{ wordWrap: "break-word" }} className='px-2' title={<b>{"Organizations : " + " " + record.org_names.join(" , ")}</b>}>
                                    <InfoCircleTwoTone />
                                </Tooltip>
                                <span style={{ color: '#00008B' }}>{text}</span>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Tooltip overlayStyle={{ maxWidth: '530px' }} overlayInnerStyle={{ wordWrap: "break-word" }} className='px-2' title={<b>{"Organizations : " + " " + record.org_names.join(" , ")}</b>}>
                                    <InfoCircleTwoTone />
                                </Tooltip>
                                <span>{text}</span>
                            </>
                        );
                    }
                }
            }
        },
        {
            title: 'Compliance Description',
            dataIndex: 'descrip',
            key: 'descrip',
            // align: 'center',
            width: '170px',
            onCell: sharedOnCell,
            render: (text, record) => {
                if (record.title == undefined) {
                    if (count[record.comp_id] === null) {
                        return <span style={{ color: '#00008B' }}>{text}</span>;
                    } else {
                        return <span>{text}</span>;
                    }
                }
            }
        },
        {
            title: <Tooltip title='Applicable'><EditOutlined /></Tooltip>,
            dataIndex: 'Manager_Labour',
            key: index,
            align: 'center',
            width: '30px',
            align: 'center',
            onCell: sharedOnCell,
            render: (text, record) => <CheckCircleOutlined style={{
                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                color: count[record.comp_id] === 1 || count[record.comp_id] === null ? 'green' : ''
            }}
                // twoToneColor={count[record.comp_id] === 1 ? 'green' : null} 
                onClick={() => {
                    console.log('Record', record);
                    const compStatus = 1;
                    const compId = record.comp_id;

                    // const temp = {
                    //     "u_id": record.u_id,
                    //     "comp_id": record.comp_id,
                    //     "comp_status": 1,
                    //     "level_1_s_id": record.level_1_s_id,
                    //     "a_status": record.a_status,
                    //     "remarks": record.remarks,
                    //     "client_statutory_id": inputValues.clientSatutoryId,
                    //     "u_name": inputValues.unitVendor
                    // };

                    const temp = {
                        "u_id": record.u_id,
                        "comp_id": record.comp_id,
                        "comp_status": 1,
                        "level_1_s_id": record.level_1_s_id,
                        "a_status": record.a_status == null ? 1 : record.a_status,
                        "remarks": record.remarks,
                        "client_statutory_id": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[1] !== 'null' ? parseInt(singleUnit.name.split(',')[1]) : null : inputValues.clientSatutoryId,
                        "u_name": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[0] : inputValues.unitVendor
                    };

                    reqArr = reqArr.filter(item => {
                        return item.comp_id != record.comp_id;
                    });
                    reqArr.push(temp);

                    console.log("reqArr", reqArr);
                    setArrRequest(reqArr);
                    // console.log('arrRequest', arrRequest);
                    // console.log("countCompStatus", count);

                    setCount({
                        ...count,
                        [record.comp_id]: compStatus
                    });
                    setView({
                        ...view,
                        [record.comp_id]: 1
                    });

                    let indexValue = null;
                    console.log('Remarks', remarks);
                    remarks.map((item, index) => {
                        let findIndex = index;
                        // const status = item.includes(record.comp_id);
                        const status = item.includes(compId);
                        if (status) {
                            indexValue = findIndex;
                            console.log('IndexValue', indexValue);
                        }
                    });

                    console.log('indexValueCheck', indexValue);
                    let remarkEnable = {};
                    if (indexValue !== null) {
                        remarkEnable = { ...remarkEnable, [indexValue]: false };
                        setRemarkInput({
                            ...remarkInput,
                            [indexValue]: null
                        });
                    }

                    setOption({ ...option, [indexValue]: 1 });

                    setCollapseIdenty({
                        ...collapseIdenty,
                        ...remarkEnable
                    });

                }} />
        },
        {
            title: <Tooltip title='Not Applicable'><EditOutlined /></Tooltip>,
            dataIndex: 'Executive_Labour',
            key: index,
            align: 'center',
            width: '30px',
            align: 'center',
            onCell: sharedOnCell,
            render: (text, record) => <CloseCircleOutlined style={{
                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                color: count[record.comp_id] === 2 ? 'darkorange' : ''
            }} onClick={() => {
                const compStatus = 2;
                const compId = record.comp_id;

                // const temp = {
                //     "u_id": record.u_id,
                //     "comp_id": record.comp_id,
                //     "comp_status": 2,
                //     "level_1_s_id": record.level_1_s_id,
                //     "a_status": record.a_status,
                //     "remarks": record.remarks,
                //     "client_statutory_id": inputValues.clientSatutoryId,
                //     "u_name": inputValues.unitVendor
                // };

                const temp = {
                    "u_id": record.u_id,
                    "comp_id": record.comp_id,
                    "comp_status": 2,
                    "level_1_s_id": record.level_1_s_id,
                    "a_status": record.a_status == null ? 1 : record.a_status,
                    "remarks": record.remarks,
                    "client_statutory_id": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[1] !== 'null' ? parseInt(singleUnit.name.split(',')[1]) : null : inputValues.clientSatutoryId,
                    "u_name": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[0] : inputValues.unitVendor
                };

                reqArr = reqArr.filter(item => {
                    return item.comp_id != record.comp_id;
                });
                reqArr.push(temp);
                console.log("reqArr", reqArr);
                setArrRequest(reqArr);
                console.log('arrRequest', arrRequest);

                setCount({
                    ...count,
                    [record.comp_id]: 2
                });

                setView({
                    ...view,
                    [record.comp_id]: 1
                });

                let indexValue = null;
                let remarkEnable = {};
                console.log('Remarks', remarks);
                remarks.map((item, index) => {
                    let findIndex = index;
                    // const status = item.includes(record.comp_id);
                    const status = item.includes(compId);
                    if (status) {
                        indexValue = findIndex;
                    }
                });

                const values = [2];

                if (indexValue !== null) {
                    remarks[indexValue].map((item) => {
                        if (record.comp_id !== item) {
                            values.push(count[item]);
                        }
                    });
                }

                console.log('values', values);
                let disable = values.some(item => item == 1 || null);
                console.log('disable', disable);
                remarkEnable = { ...remarkEnable, [indexValue]: !disable };

                if (disable) {
                    // setOption(1);                        
                    setOption({ ...option, [indexValue]: 1 });
                }
                if (!disable) {
                    let temp = values.some(item => item == 2);
                    // temp ? setOption(2) : setOption(3);
                    temp ? setOption({ ...option, [indexValue]: 2 }) : setOption({ ...option, [indexValue]: 3 });
                }

                dispatch({
                    type: REMARK_STATE,
                    payload: {
                        ...collapseIdenty,
                        // ...remarkState,
                        ...remarkEnable
                    }
                });
            }} />
        },
        {
            title: <Tooltip title='Not at All Applicable'><EditOutlined /></Tooltip>,
            dataIndex: 'Manager_Vendor',
            key: index,
            align: 'center',
            width: '30px',
            onCell: sharedOnCell,
            render: (text, record) => <MinusCircleOutlined style={{
                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                color: count[record.comp_id] === 3 ? 'red' : ''
            }}
                onClick={() => {
                    const compStatus = 3;
                    const compId = record.comp_id;

                    console.log('CheckRecord', record);

                    // const temp = {
                    //     "u_id": record.u_id,
                    //     "comp_id": record.comp_id,
                    //     "comp_status": 3,
                    //     "level_1_s_id": record.level_1_s_id,
                    //     "a_status": record.a_status,
                    //     "remarks": record.remarks,
                    //     "client_statutory_id": inputValues.clientSatutoryId,
                    //     "u_name": inputValues.unitVendor
                    // };

                    const temp = {
                        "u_id": record.u_id,
                        "comp_id": record.comp_id,
                        "comp_status": 3,
                        "level_1_s_id": record.level_1_s_id,
                        "a_status": record.a_status == null ? 1 : record.a_status,
                        "remarks": record.remarks,
                        "client_statutory_id": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[1] !== 'null' ? parseInt(singleUnit.name.split(',')[1]) : null : inputValues.clientSatutoryId,
                        "u_name": selectedValues && selectedValues.length == 1 ? singleUnit.name.split(',')[0] : inputValues.unitVendor
                    };

                    reqArr = reqArr.filter(item => {
                        return item.comp_id != record.comp_id;
                    });
                    reqArr.push(temp);
                    console.log("reqArr", reqArr);
                    setArrRequest(reqArr);
                    console.log('arrRequest', arrRequest);

                    setCount({
                        ...count,
                        [record.comp_id]: compStatus
                    });
                    setView({
                        ...view,
                        [record.comp_id]: 1
                    });

                    let indexValue = null;
                    let remarkEnable = {};
                    console.log('Remarks', remarks);
                    remarks.map((item, index) => {
                        let findIndex = index;
                        // const status = item.includes(record.comp_id);
                        const status = item.includes(compId);
                        if (status) {
                            indexValue = findIndex;
                        }
                    });

                    const values = [3];
                    if (indexValue !== null) {
                        remarks[indexValue].map((item) => {
                            if (record.comp_id !== item) {
                                values.push(count[item]);
                            }
                        });
                    }

                    console.log('values', values);
                    let disable = values.some(item => item == 1 || null);
                    console.log('disable', disable);
                    remarkEnable = { ...remarkEnable, [indexValue]: !disable };

                    if (disable) {
                        // setOption(1);
                        setOption({ ...option, [indexValue]: 1 });
                    }
                    if (!disable) {
                        let temp = values.some(item => item == 2);
                        // temp ? setOption(2) : setOption(3);
                        temp ? setOption({ ...option, [indexValue]: 2 }) : setOption({ ...option, [indexValue]: 3 });
                    }

                    dispatch({
                        type: REMARK_STATE,
                        payload: {
                            ...collapseIdenty,
                            // ...remarkState,
                            ...remarkEnable
                        }
                    });
                }} />
        },
        {
            title: 'Viewed',
            dataIndex: '',
            key: '',
            align: 'center',
            width: '30px',
            ellipsis: true,
            onCell: sharedOnCell,
            render: (text, record) => {
                if (view[record.comp_id] === 1) {
                    return <Checkbox checked={true} />;
                }
            }
        },
    ];

    const multiUnitcolumns = [
        {
            title: "#",
            dataIndex: "level_1_s_id",
            key: "level_1_s_id",
            align: 'center',
            width: '35px',
            ellipsis: true,
            onCell: sharedOnCell,
            render: (text, record) => {
                // return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>
                if (record.title == undefined) {
                    return <><span style={{ fontWeight: 500 }}>{++index}</span></>;
                }
            }
        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_provision',
            key: 's_provision',
            // align: 'left',
            width: '190px',
            // ellipsis: true,
            onCell: (record, index) => ({
                colSpan: record.title ? 6 : 1,
                align:  record.title ? 'left' : 'center'
            }),
            render: (text, record) => {
                if (record.title != undefined) {
                    return <b className='px-3'>{text}</b>;
                } else {
                    return <span style={{ fontWeight: 500 }}>{text}</span>;
                }
            }
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
            align: 'center',
            width: '280px',
            render: (text, record) => {
                if (record.title == undefined) {
                    return (
                        <>
                            <Tooltip overlayStyle={{ maxWidth: '530px' }} overlayInnerStyle={{ wordWrap: "break-word" }} className='px-2' title={<b>{"Organizations : " + " " + record.org_names.join(" , ")}</b>}>
                                <InfoCircleTwoTone />
                            </Tooltip>
                            <span style={{ fontWeight: 500 }}>{text}</span>
                        </>
                    );
                }
            }
        },
        {
            title: 'Compliance Description',
            dataIndex: 'descrip',
            key: 'descrip',
            align: 'center',
            width: '410px',
            render: (text, record) => {
                return <span style={{ fontWeight: 500 }}>{text}</span>;
            }
        },
        {
            title: 'Applicable Units',
            dataIndex: '',
            key: '',
            align: 'center',
            width: '200px',
            render: (text, record) => {
                if (record['applicable_units'] !== undefined) {
                    let len = record['applicable_units'].length;
                    return <span style={{ fontWeight: 500 }}>{len + '/' + selectedValues.length}</span>;
                }
            }
        },
        {
            title: 'Viewed',
            dataIndex: '',
            key: '',
            align: 'center',
            width: '65px',
            ellipsis: true,
            render: (text, record) => {
                if (view[record.comp_id] === 1) {
                    return <Checkbox checked={true} />;
                }
            }
        },
    ];

    const nestedTableColumns = [
        // {
        //     title: "",
        //     dataIndex: "",
        //     key: "",
        //     align: 'center',
        //     width: '35px',
        //     ellipsis: true,
        // },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            align: 'center',
            width: '100px',
            ellipsis: true,
        },
        {
            title: 'Unit Name/Vendor Name',
            dataIndex: 'unitVendor',
            key: 'unitVendor',
            align: 'center',
            width: '310px',
        },
        {
            title: <Checkbox />,
            dataIndex: '',
            key: index,
            align: 'center',
            width: '33px',
            render: (text, record) => {
                let name = record.compId + '' + record.u_id;
                return <CheckCircleOutlined style={{
                    border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                    color: multiCompStatus[name] === 1 ? 'green' : ''
                }} onClick={() => {
                    setMultiCompStatus({
                        ...multiCompStatus,
                        [name]: 1
                    });
                    const temp = {
                        "u_id": record.u_id,
                        "comp_id": record.compId,
                        "comp_status": 1,
                        "level_1_s_id": record.levelId,
                        "a_status": 1,
                        "remarks": null,
                        "client_statutory_id": record.clientStatutoryId ? record.clientStatutoryId : null,
                        "u_name": record.unitVendor.split(',')[0]
                    };

                    reqArr = reqArr.filter(item => {
                        let key = item.comp_id + '' + item.u_id;
                        return key !== name;
                    });

                    reqArr.push(temp);
                    setArrRequest(reqArr);
                    console.log('tempPay', temp);
                    console.log('ReqArr', reqArr);
                    console.log('arrRequest', arrRequest);
                }} />;
            }
        },
        {
            title: <Checkbox />,
            dataIndex: '',
            key: index,
            align: 'center',
            width: '33px',
            render: (text, record) => {
                let name = record.compId + '' + record.u_id;
                return <CloseCircleOutlined style={{
                    border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                    color: multiCompStatus[name] === 2 ? 'darkorange' : ''
                }} onClick={() => {
                    setMultiCompStatus({
                        ...multiCompStatus,
                        [name]: 2
                    });
                    const temp = {
                        "u_id": record.u_id,
                        "comp_id": record.compId,
                        "comp_status": 2,
                        "level_1_s_id": record.levelId,
                        "a_status": 2,
                        "remarks": null,
                        "client_statutory_id": record.clientStatutoryId ? record.clientStatutoryId : null,
                        "u_name": record.unitVendor.split(',')[0]
                    };

                    reqArr = reqArr.filter(item => {
                        let key = item.comp_id + '' + item.u_id;
                        return key !== name;
                    });
                    reqArr.push(temp);
                    setArrRequest(reqArr);
                    console.log('ReqArr', reqArr);
                }} />;
            }
        },
        {
            title: <Checkbox />,
            dataIndex: '',
            key: index,
            align: 'center',
            width: '33px',
            render: (text, record) => {
                let name = record.compId + '' + record.u_id;
                return <MinusCircleOutlined style={{
                    border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                    color: multiCompStatus[name] === 3 ? 'red' : ''
                }} onClick={() => {
                    setMultiCompStatus({
                        ...multiCompStatus,
                        [name]: 3
                    });
                    const temp = {
                        "u_id": record.u_id,
                        "comp_id": record.compId,
                        "comp_status": 3,
                        "level_1_s_id": record.levelId,
                        "a_status": 3,
                        "remarks": null,
                        "client_statutory_id": record.clientStatutoryId ? record.clientStatutoryId : null,
                        "u_name": record.unitVendor.split(',')[0]
                    };

                    reqArr = reqArr.filter(item => {
                        let key = item.comp_id + '' + item.u_id;
                        return key !== name;
                    });
                    reqArr.push(temp);
                    setArrRequest(reqArr);
                    console.log('ReqArr', reqArr);
                }} />;
            }
        },
        {
            title: '',
            dataIndex: '',
            key: index,
            align: 'center',
            width: '30px',
            ellipsis: true,
            render: (text, record) => {
                let name = record.compId + '' + record.u_id;
                if (record.s_s == 1 || multiCompStatus.hasOwnProperty(name)) {
                    return <Checkbox checked={true} />;
                }
            }
        },
    ];

    useEffect(() => {
        let filterCompStatus = {};
        let filterViewStatus = {};
        let filterViewed = 0;
        const collect = [];
        const collapseRemarks = {};
        const remarkvalues = {};
        const tabOption = {};
        // assignedStatutoryData.map((item, index) => {
        reloadData.map((item, index) => {
            let temp = [];
            collapseRemarks[index] = false;
            remarkvalues[index] = null;
            tabOption[index] = 1;
            item.map((value) => {
                if (value.title == undefined) {
                    temp.push(value.comp_id);
                    filterCompStatus[value.comp_id] = value.comp_status;
                    filterViewStatus[value.comp_id] = value.s_s;
                    if (value.s_s == 1) {
                        //   setTotalViewed(totalViewed + 1);
                        filterViewed = filterViewed + 1;
                    }
                    // console.log("compStatus", value.comp_status);
                }
            });
            collect.push(temp);
            // console.log('filterValue', filterCompStatus);
            // console.log('Collect', collect);
        });
        setCount({
            // ...count,
            ...filterCompStatus
        });
        setView({
            ...filterViewStatus
        });
        setRemarks(collect);
        setOption(tabOption);
        setCollapseIdenty({
            ...collapseRemarks
        });
        setRemarkInput({ ...remarkvalues });
        setTotalViewed(filterViewed);
        // console.log('data', assignedStatutoryData);
        // console.log('Count', count);
        // console.log('view', view);
        // console.log('remarks', remarks);
        // console.log('TotalValue', totalViewed);
        // console.log('CollapseIdentity', collapseIdenty);
        console.log('remarkValues', remarkInput);
        // }, [assignedStatutoryData, reloadData]);
    }, [reloadData]);

    useEffect(() => {
        // setReloadData(assignedStatutoryData);
        setRowCount(rowCount + dataCount);
        setReloadData([
            ...reloadData,
            ...assignedStatutoryData]);
        console.log(assignedStatutoryData, 'assignedtableData');
    }, [assignedStatutoryData]);

    useEffect(() => {
        // const data = [];
        // const obj = {};
        // for (let index = 0; index < addUnitTableData.length; index++) {
        //     addUnitTableData[index].map(val => {
        //         if (val.title == undefined) {
        //             let temp = val.applicable_units;                
        //             console.log('val', temp);
        //             data.push(temp)                    
        //         }
        //     })            
        // }

        // if (data.length !== 0) {
        //     for (let index = 0; index < data.length; index++){
        //         data[index].map(item => {
        //             let nameKey = item.compId + '' + item.u_id;
        //             obj[nameKey] = item.comp_status
        //         })                
        //     }
        // }
        // console.log('data', data);
        // console.log('obj', obj);
        // setMultiCompStatus({
        //     ...multiCompStatus,
        //     ...obj
        // })
        // setReloadData(addUnitTableData);

        setRowCount(rowCount + dataCount);
        setReloadData([
            ...reloadData,
            ...addUnitTableData]);
        console.log(reloadData, 'reloadData');
        console.log('addUnitData', addUnitTableData);
    }, [addUnitTableData]);

    const addOptionRequest = () => {
        setUnitListData([]);
        setUnitIds([]);
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedStatutoryWizardOneData",
                {}
            ]
        };

        addOption({
            payload: pay,
            paramid: paramid
        });

        setActiveTab('1');
        setExitCollapse(true);
        setAddCollapse(true);
        setShowData(true);
        setShowTable(true);
    };

    // useEffect(() => {
    //     console.log('AddOptionData', addOptionData);
    // },[addOptionData])

    useEffect(() => {
        console.log('unitList', unitList);
        // if (unitList !== undefined) {
        if (unitList) {
            const temp = unitList.map(item => {
                return {
                    title: item.unit_code + ' - ' + item.u_name + ' - ' + item.address,
                    value: item.u_id
                };
            });
            const values = temp.map(item => item.value);
            setUnitListData(temp);
            setUnitIds(values);

            const singleUnitTempList = {};
            unitList.map(item => {
                singleUnitTempList[item['u_id']] = item.unit_code + ' - ' + item.u_name + ',' + item.client_statutory_id;
            });

            setSingleUnit({
                ...singleUnitTempList
            });
            console.log('Singleunit', singleUnit);
            // console.log('unitlistFilter', temp);
            // console.log('unitlistvalues', values);
        }
        console.log('unitListData', unitListData);
        console.log('unitListIds', unitIds);
    }, [unitList]);

    const addShow = () => {
        if (addValidator.current.allValid()) {
            setAddSubmit(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryWizardOneUnits",
                    {
                        "ct_id": parseInt(addOptionFilter.groupId),
                        "bg_id": parseInt(addOptionFilter.businessGroupId) || null,
                        "le_id": parseInt(addOptionFilter.legalEntityId),
                        "dv_id": parseInt(addOptionFilter.divisionId) || null,
                        "cat_id": parseInt(addOptionFilter.categoryId) || null,
                        "d_id": parseInt(addOptionFilter.domainId)
                    }
                ]
            };

            addUnitsList({
                payload: pay,
                paramid: paramid
            });
            triggerClick.current.focus();
        } else {
            setAddSubmit(true);
        }
    };

    const selectAll = (id) => {
        // e.stopPropagation();
        if (option[id] < 3) {
            // setOption(option + 1);
            setOption({ ...option, [id]: option[id] + 1 });
        } else {
            // setOption(1);
            setOption({ ...option, [id]: 1 });
        }
    };

    useEffect(() => {
        setRowCount(0);
        setReloadData([]);
        console.log('rowCount InputValues', rowCount);
        console.log('rowCount InputValues', recordCount);
    }, [inputValues]);

    useEffect(() => {
        setRowCount(0);
        setReloadData([]);
        console.log('rowCount AddOption', rowCount);
        console.log('rowCount AddOption', addUnitCount);
    }, [addOptionFilter]);

    return (
        <>
            <div id="page-wrapper" className="page-wrapper">
                <ScrollToTop smooth color="red" component={
                    <Tooltip title="Back to top">
                        <UpOutlined />
                    </Tooltip>
                }
                />
                <div className="page-titles pb-1 pt-1">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer'
                                            onClick={homescreen}
                                        >
                                            <i className="ri-home-3-line fs-5"></i>
                                        </span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transactions</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span>Assign Statutory</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 text-end">
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "15px",
                                    display: addCollapse ? "none" : "inline",
                                }}
                                onClick={addOptionRequest}
                            >
                                <PlusCircleOutlined /> Add
                            </Button>
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "15px",
                                    display: exitCollapse ? "inline" : "none",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button
                                onClick={(e) => {
                                    fullscreenMode();
                                }}
                                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                            >
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title="Full Screen Mode"
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                                <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                    <Panel header="Assign Statutory Search" key={1}>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label><b>Client Group :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Client Group"

                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.clientId || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue');
                                                                if (data != undefined) {
                                                                    getLegalEntityData(data, value);
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        clientId: '',
                                                                        clientName: '',
                                                                        legalEntityData: '',
                                                                        legalEntityId: '',
                                                                        legalEntityName: '',
                                                                        domainData: '',
                                                                        domainId: '',
                                                                        domainName: '',
                                                                        unitId: '',
                                                                        unitName: '',
                                                                        unitData: ''
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {deassignstatutorylegalentityfilter.grps && deassignstatutorylegalentityfilter.grps.length > 0 && deassignstatutorylegalentityfilter.grps.map((item, i) => {
                                                                return (
                                                                    <Option key={item.client_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'Client Group',
                                                            inputValues.clientId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Client Group Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Legal Entity :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Client Group"
                                                            disabled={inputValues.clientId == ''}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.legalEntityId || undefined}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    getOtherDetails(data, value);
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        legalEntityId: '',
                                                                        legalEntityName: '',
                                                                        domainId: '',
                                                                        domainName: '',
                                                                        domainData: '',
                                                                        unitId: '',
                                                                        unitName: '',
                                                                        unitData: ''
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {inputValues.legalEntityData && inputValues.legalEntityData.length > 0 && inputValues.legalEntityData.map((item, i) => {
                                                                return (
                                                                    <Option key={item.legal_entity_id}>
                                                                        {item.legal_entity_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'Legal Entity',
                                                            inputValues.legalEntityId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Domain :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            disabled={inputValues.legalEntityName == ''}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.domainId || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue');
                                                                if (data != undefined) {
                                                                    getUnitDetails(data, value);
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        domainId: '',
                                                                        domainName: '',
                                                                        unitId: '',
                                                                        unitName: '',
                                                                        unitData: ''
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {inputValues.domainData && inputValues.domainData.length > 0 && inputValues.domainData.map((item, i) => {
                                                                return (
                                                                    <Option key={item.domain_id}>
                                                                        {item.domain_name}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>
                                                        {validator.current.message(
                                                            'Domain',
                                                            inputValues.domainId,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-1'>
                                                <label><b>Status :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            disabled={inputValues.domainId == ''}
                                                            defaultValue={'0'}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.status || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue');
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        status: data
                                                                    });
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        unitId: '',
                                                                        unitName: ''
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <Option value="0">All</Option>
                                                            <Option value="1">Yet to submit</Option>
                                                            <Option value="2">Pending</Option>
                                                            <Option value="3">Approved</Option>
                                                            <Option value="4">Rejected</Option>
                                                            <Option value="5">Assigned</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mt-1'>
                                                <label><b>Unit :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Unit"
                                                            disabled={inputValues.domainId == ''}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={inputValues.unitId || undefined}
                                                            onChange={(data, value) => {
                                                                console.log(value, 'valuevalue');
                                                                if (data != undefined) {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        unitId: data,
                                                                        unitName: value.children
                                                                    });
                                                                } else {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        unitId: '',
                                                                        unitName: ''
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {inputValues.unitData && inputValues.unitData.length > 0 && inputValues.unitData.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            }
                                                            )}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center mt-2'>
                                            <Button type="primary" shape="round" className='addbutton'
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<EyeOutlined />}
                                                onClick={showResult}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>

                            </div>
                        </div>
                    </div>
                    {!showTable ?
                        <Card>
                            <div className="container-fluid pt-0">
                                <div className="row" >
                                    <div className='row py-2'>
                                        {inputValues.clientName ? <>
                                            <div className='col-4'>
                                                <label><b>Client Group : </b>{inputValues.clientName}</label>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Legal Entity : </b>{inputValues.legalEntityName}</label>
                                            </div>
                                            <div className='col-4'>
                                                <label><b>Domain : </b>{inputValues.domainName}</label>
                                            </div></> : null}
                                    </div>
                                    <div className='card-body' style={{ padding: "0px" }}>
                                        <Table
                                            columns={columns}
                                            dataSource={getassignedstatutories && getassignedstatutories.assigned_statutories}
                                            bordered
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </div></Card>
                        : false}
                    {showData ?
                        <div className="container-fluid pt-0">
                            <div className="row" >
                                {/* <div className="col-12"> */}
                                <div className='card-body' style={{ padding: 0 }}>
                                    <Card title="Assign Statutory" extra={<Button shape='round' size='middle' onClick={(e) => {
                                        e.stopPropagation();
                                        setShowTable(false);
                                        setShowData(false);
                                        setExitCollapse(false);
                                        setAddCollapse(false);
                                    }}>Previous</Button>}>
                                        <Tabs defaultActiveKey={activeTab} activeKey={activeTab} destroyInactiveTabPane={activeTab == '2' ? true : false} onChange={(activeKey) => console.log('activeKey', activeKey)}>
                                            <TabPane tab="Select Unit/Vendor & Domain" key="1" disabled={activeTab === '1' ? false : true}>
                                                <div
                                                // style={{display: activeTab == '2' ? 'none' : 'block'}}
                                                >
                                                    <div className='col-12 px-3 py-2'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><b>Group :</b> <span style={{ color: "red" }}>*</span></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Group"

                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.group || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        group: value.children,
                                                                                        groupId: data,
                                                                                        legalEntityName: '',
                                                                                        legalEntityId: '',
                                                                                        domain: '',
                                                                                        domainId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        group: '',
                                                                                        groupId: '',
                                                                                        legalEntityName: '',
                                                                                        legalEntityId: '',
                                                                                        domain: '',
                                                                                        domainId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {addOptionData.grps && addOptionData.grps.length > 0 && addOptionData.grps.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.client_id}>
                                                                                        {item.group_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {addValidator.current.message(
                                                                            'Group',
                                                                            addOptionFilter.groupId,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${addSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Group Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><b>Legal Entity :</b> <span style={{ color: "red" }}>*</span></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Client Group"
                                                                            disabled={addOptionFilter.groupId == ''}
                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.legalEntityId || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        legalEntityName: value.children,
                                                                                        legalEntityId: data,
                                                                                        domain: '',
                                                                                        domainId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        legalEntityName: '',
                                                                                        legalEntityId: '',
                                                                                        domain: '',
                                                                                        domainId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {addOptionData.lety && addOptionData.lety.length > 0 && addOptionData.lety.map((item, i) => {
                                                                                if (addOptionFilter.groupId == item.client_id) {
                                                                                    return <Option key={item.legal_entity_id}>
                                                                                        {item.legal_entity_name}
                                                                                    </Option>;
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                        {addValidator.current.message(
                                                                            'Legal Entity',
                                                                            addOptionFilter.legalEntityId,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${addSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><b>Domain :</b> <span style={{ color: "red" }}>*</span></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Domain"
                                                                            disabled={addOptionFilter.legalEntityId == ''}
                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.domainId || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        domain: value.children,
                                                                                        domainId: data,
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        domain: '',
                                                                                        domainId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                        division: '',
                                                                                        divisionId: ''
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {addOptionData.dms && addOptionData.dms.length > 0 && addOptionData.dms.map((item, i) => {
                                                                                if (addOptionFilter.legalEntityId == item.legal_entity_id) {
                                                                                    return <Option key={item.domain_id}>
                                                                                        {item.domain_name}
                                                                                    </Option>;
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                        {addValidator.current.message(
                                                                            'Domain',
                                                                            addOptionFilter.domainId,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${addSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Domain Required',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-4'>
                                                                <label><b>Division :</b></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Client Group"
                                                                            disabled={addOptionFilter.domainId == ''}
                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.divisionId || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        division: value.children,
                                                                                        divisionId: data,
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        division: '',
                                                                                        divisionId: '',
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {addOptionData.divs && addOptionData.divs.length > 0 && addOptionData.divs.map((item, i) => {
                                                                                if (addOptionFilter.legalEntityId == item.legal_entity_id && addOptionFilter.groupId == item.client_id) {
                                                                                    return <Option key={item.division_id}>
                                                                                        {item.division_name}
                                                                                    </Option>;
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><b>Category :</b></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Unit"
                                                                            disabled={addOptionFilter.divisionId == ''}
                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.categoryId || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        category: value.children,
                                                                                        categoryId: data,
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        category: '',
                                                                                        categoryId: '',
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {addOptionData.cates && addOptionData.cates.length > 0 && addOptionData.cates.map((item, i) => {
                                                                                if (addOptionFilter.legalEntityId == item.legal_entity_id && addOptionFilter.groupId == item.client_id && addOptionFilter.divisionId == item.division_id) {
                                                                                    return <Option key={item.category_id}>
                                                                                        {item.category_name}
                                                                                    </Option>;
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><b>Business Group :</b></label>
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Client Group"
                                                                            disabled={addOptionFilter.categoryId == ''}
                                                                            showSearch
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%' }}
                                                                            value={addOptionFilter.businessGroupId || undefined}
                                                                            onChange={(data, value) => {
                                                                                if (data != undefined) {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        businessGroup: value.children,
                                                                                        businessGroupId: data,
                                                                                    });
                                                                                } else {
                                                                                    setAddOptionFilter({
                                                                                        ...addOptionFilter,
                                                                                        businessGroup: '',
                                                                                        businessGroupId: '',
                                                                                    });
                                                                                }

                                                                            }}
                                                                        >
                                                                            {addOptionData.bgrps && addOptionData.bgrps.length > 0 && addOptionData.bgrps.map((item, i) => {
                                                                                if (addOptionFilter.groupId == item.client_id) {
                                                                                    return <Option key={item.business_group_id}>
                                                                                        {item.business_group_name}
                                                                                    </Option>;
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-center mt-3'>
                                                            <Button type="primary" shape="round" className='addbutton'
                                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                icon={<EyeOutlined />}
                                                                onClick={addShow}
                                                            >
                                                                Show
                                                            </Button>
                                                        </div></div>
                                                    <Divider />
                                                    <div className='col-12 px-3' style={{ height: '330px' }}>
                                                        <h5 className='px-2'>Unit/Vendor<span style={{ color: "red" }}>*</span></h5>
                                                        <Divider />
                                                        <label className='px-2 mb-2'><b>Select Unit/Vendor :</b></label>
                                                        <TreeSelect
                                                            ref={triggerClick}
                                                            allowClear={true}
                                                            showSearch
                                                            disabled={unitListData.length == 0}
                                                            treeNodeFilterProp='title'
                                                            placeholder="Select Unit/Vendor"
                                                            treeCheckable={true}
                                                            // showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                                            style={{ width: '100%' }}
                                                            treeExpandAction={false}
                                                            dropdownStyle={{ maxHeight: "300px" }}
                                                            onChange={ids => setSelectedValues(ids)}
                                                            value={selectedValues}
                                                            maxTagCount={2}
                                                            maxTagPlaceholder={omittedValues =>
                                                                `+ ${omittedValues.length} Unit/Vendor ...`
                                                            }
                                                            treeData={[
                                                                {
                                                                    title: (
                                                                        <>
                                                                            {selectedValues.length === unitListData.length && (
                                                                                <span
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        color: "#ccc",
                                                                                        cursor: "not-allowed"
                                                                                    }}
                                                                                >
                                                                                    Select all
                                                                                </span>
                                                                            )}
                                                                            {selectedValues.length < unitListData.length && (
                                                                                <span
                                                                                    onClick={() => setSelectedValues(unitIds)}
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        color: "#286FBE",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >
                                                                                    Select all
                                                                                </span>
                                                                            )}
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            {selectedValues.length === 0 && (
                                                                                <span
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        color: "#ccc",
                                                                                        cursor: "not-allowed"
                                                                                    }}
                                                                                >
                                                                                    Unselect all
                                                                                </span>
                                                                            )}
                                                                            {selectedValues.length > 0 && (
                                                                                <span
                                                                                    onClick={() => setSelectedValues([])}
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        color: "#286FBE",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >
                                                                                    Unselect all
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    ),
                                                                    // value: "xxx",
                                                                    // disableCheckbox: true,
                                                                    // disabled: true
                                                                },
                                                                ...unitListData
                                                            ]}
                                                        />
                                                    </div>
                                                    <Space direction="horizontal" className='px-2 py-2 pb-3' style={{ width: '100%', justifyContent: 'flex-end' }}>
                                                        <div>Selected Unit/Vendor: {selectedValues.length}</div>
                                                        <Button shape='round' onClick={() => {
                                                            if (selectedValues.length !== 0) {
                                                                const pay = {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "GetAssignedStatutoryWizardTwoCount",
                                                                        {
                                                                            "d_id": parseInt(addOptionFilter.domainId),
                                                                            "unit_ids": selectedValues,
                                                                        }
                                                                    ]
                                                                };

                                                                addTwoCount({
                                                                    payload: pay,
                                                                    paramid: paramid
                                                                });

                                                                const payload2 = {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "GetAssignedStatutoryWizardTwoData",
                                                                        {
                                                                            "d_id": parseInt(addOptionFilter.domainId),
                                                                            "unit_ids": selectedValues,
                                                                            "rcount": 0
                                                                        }
                                                                    ]
                                                                };

                                                                addTwoData({
                                                                    payload: payload2,
                                                                    paramid: paramid
                                                                });

                                                                console.log('SelectedValues', selectedValues);
                                                                if (selectedValues.length == 1) {
                                                                    let temp = singleUnit[selectedValues[0]];
                                                                    setSingleUnit({
                                                                        name: temp
                                                                    });
                                                                }
                                                                if (selectedValues.length > 1) {
                                                                    setSingleUnit({});
                                                                }

                                                                setActiveTab('2');
                                                                setExitCollapse(true);
                                                                setFilterModalVisible(false);
                                                                setShowTable(!false);
                                                                setShowData(true);
                                                                // setRowCount(rowCount + 50);
                                                                setRowCount(0);
                                                            } else {
                                                                Toaster.error('Select atleast one unit to assign');
                                                            }
                                                        }}>Next</Button>
                                                    </Space>
                                                </div>
                                            </TabPane>

                                            <TabPane tab="Select Statutory" key="2" disabled={activeTab === '2' ? false : true} >
                                                {inputValues.clientName && inputValues.legalEntityName ?
                                                    <div style={{ marginLeft: '12px' }}>
                                                        <Breadcrumb separator=">>">
                                                            <Breadcrumb.Item>{inputValues.clientName}</Breadcrumb.Item>
                                                            <Breadcrumb.Item>{inputValues.businessGroupBread}</Breadcrumb.Item>
                                                            <Breadcrumb.Item>{inputValues.legalEntityName}</Breadcrumb.Item>
                                                            <Breadcrumb.Item>{inputValues.divisionBread}</Breadcrumb.Item>
                                                            <Breadcrumb.Item>{inputValues.categoryBread}</Breadcrumb.Item>
                                                            <Breadcrumb.Item>{inputValues.domainName}</Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div> : ''
                                                }
                                                <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                                                    {/* {assignedStatutoryData && assignedStatutoryData.map((approved, index) => { */}
                                                    {reloadData && reloadData.map((approved, index) => {
                                                        const titleName = approved[1]["level_1_s_name"];
                                                        const aprArr = [];
                                                        let i = index > 2 ? index - 1 : index;
                                                        const filterFun = (arg) => {
                                                            const temp = approved.map(item => {
                                                                if (item.title == undefined) {
                                                                    const reqData = {
                                                                        "u_id": item.u_id,
                                                                        "comp_id": item.comp_id,
                                                                        "comp_status": arg,
                                                                        "level_1_s_id": item.level_1_s_id,
                                                                        "a_status": item.a_status == null ? 1 : item.a_status,
                                                                        "remarks": item.remarks,
                                                                        "client_statutory_id": inputValues.clientSatutoryId ? inputValues.clientSatutoryId : null,
                                                                        "u_name": inputValues.unitVendor
                                                                    };
                                                                    aprArr.push(reqData);
                                                                }
                                                            });
                                                        };

                                                        const addfilterfun = (arg) => {
                                                            approved.map(item => {
                                                                if (item.title == undefined) {
                                                                    let temp = item.applicable_units.map(val => {
                                                                        const reqData = {
                                                                            "u_id": val.u_id,
                                                                            "comp_id": val.compId,
                                                                            "comp_status": arg,
                                                                            "level_1_s_id": val.levelId,
                                                                            "a_status": item.a_status == null ? 1 : item.a_status,
                                                                            "remarks": null,
                                                                            "client_statutory_id": val.clientStatutoryId ? val.clientStatutoryId : null,
                                                                            "u_name": val.unitVendor.split(',')[0]
                                                                        };
                                                                        aprArr.push(reqData);
                                                                    });
                                                                }
                                                            });
                                                        };

                                                        return (
                                                            <Collapse defaultActiveKey={["1"]} className="my-2 mx-2"
                                                                expandIcon={
                                                                    (e) => {
                                                                        if (option[index] === 1) {
                                                                            return <CheckCircleOutlined style={{ border: '0px solid green', borderRadius: '50%', fontSize: '18px', color: 'green' }}
                                                                                onClick={(e) => {
                                                                                    if (!(selectedValues.length > 1)) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        let filterobj = {};
                                                                                        let viewobj = {};
                                                                                        let remarkDisable = { [index]: true };
                                                                                        remarks[index].map((item) => {
                                                                                            filterobj = { ...filterobj, [item]: 2 };
                                                                                            viewobj = { ...viewobj, [item]: 1 };
                                                                                        });
                                                                                        setCount({
                                                                                            ...count,
                                                                                            ...filterobj
                                                                                        });
                                                                                        setView({
                                                                                            ...view,
                                                                                            ...viewobj
                                                                                        });
                                                                                        setCollapseIdenty({
                                                                                            ...collapseIdenty,
                                                                                            ...remarkDisable
                                                                                        });
                                                                                        filterFun(2);
                                                                                        console.log('ReqArr', reqArr);
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        const unique = reqArr.filter(
                                                                                            (obj, index) =>
                                                                                                reqArr.findIndex((item) => item.comp_id === obj.comp_id) === index
                                                                                        );
                                                                                        console.log('unique', unique);
                                                                                        setArrRequest(unique);
                                                                                    }
                                                                                    if (selectedValues.length > 1) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        addfilterfun(2);
                                                                                        // reqArr = [...aprArr, ...reqArr];
                                                                                        const compState = {};

                                                                                        aprArr.forEach(item => {
                                                                                            let key = item.comp_id + '' + item.u_id;
                                                                                            compState[key] = 2;
                                                                                            reqArr = reqArr.filter(val => {
                                                                                                let name = val.comp_id + '' + val.u_id;
                                                                                                return key !== name;
                                                                                            });
                                                                                        });
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        setMultiCompStatus({
                                                                                            ...multiCompStatus,
                                                                                            ...compState
                                                                                        });
                                                                                        setArrRequest(reqArr);
                                                                                        console.log('SelectedValues Greater than 1');
                                                                                    }
                                                                                }} />;
                                                                        } else if (option[index] === 2) {
                                                                            return <CloseCircleOutlined style={{ border: '0px solid green', borderRadius: '50%', fontSize: '18px', color: 'darkorange' }}
                                                                                onClick={(e) => {
                                                                                    if (!(selectedValues.length > 1)) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        let filterobj = {};
                                                                                        let viewobj = {};
                                                                                        let remarkEnable = { [index]: true };
                                                                                        setCollapseIdenty({
                                                                                            ...collapseIdenty,
                                                                                            ...remarkEnable
                                                                                        });
                                                                                        remarks[index].map((item) => {
                                                                                            filterobj = { ...filterobj, [item]: 3 };
                                                                                            viewobj = { ...viewobj, [item]: 1 };
                                                                                        });
                                                                                        setCount({
                                                                                            ...count,
                                                                                            ...filterobj
                                                                                        });
                                                                                        setView({
                                                                                            ...view,
                                                                                            ...viewobj
                                                                                        });
                                                                                        filterFun(3);
                                                                                        console.log('ReqArr', reqArr);
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        const unique = reqArr.filter(
                                                                                            (obj, index) =>
                                                                                                reqArr.findIndex((item) => item.comp_id === obj.comp_id) === index
                                                                                        );
                                                                                        console.log('unique', unique);
                                                                                        setArrRequest(unique);
                                                                                    }
                                                                                    if (selectedValues.length > 1) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        addfilterfun(3);
                                                                                        // reqArr = [...aprArr, ...reqArr];
                                                                                        const compState = {};
                                                                                        aprArr.forEach(item => {
                                                                                            let key = item.comp_id + '' + item.u_id;
                                                                                            compState[key] = 3;
                                                                                            reqArr = reqArr.filter(val => {
                                                                                                let name = val.comp_id + '' + val.u_id;
                                                                                                return key !== name;
                                                                                            });
                                                                                        });
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        setMultiCompStatus({
                                                                                            ...multiCompStatus,
                                                                                            ...compState
                                                                                        });
                                                                                        setArrRequest(reqArr);
                                                                                        console.log('SelectedValues Greater than 1');
                                                                                    }
                                                                                }
                                                                                } />;
                                                                        } else {
                                                                            return <MinusCircleOutlined style={{ border: '0px solid green', borderRadius: '50%', fontSize: '18px', color: 'red' }}
                                                                                onClick={(e) => {
                                                                                    if (!(selectedValues.length > 1)) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        let filterobj = {};
                                                                                        let viewobj = {};
                                                                                        let remarkEnable = { [index]: false };
                                                                                        setCollapseIdenty({
                                                                                            ...collapseIdenty,
                                                                                            ...remarkEnable
                                                                                        });
                                                                                        remarks[index].map((item) => {
                                                                                            filterobj = { ...filterobj, [item]: 1 };
                                                                                            viewobj = { ...viewobj, [item]: 1 };
                                                                                        });
                                                                                        setCount({
                                                                                            ...count,
                                                                                            ...filterobj
                                                                                        });
                                                                                        setView({
                                                                                            ...view,
                                                                                            ...viewobj
                                                                                        });
                                                                                        filterFun(1);
                                                                                        console.log('ReqArr', reqArr);
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        const unique = reqArr.filter(
                                                                                            (obj, index) =>
                                                                                                reqArr.findIndex((item) => item.comp_id === obj.comp_id) === index
                                                                                        );
                                                                                        console.log('unique', unique);
                                                                                        setArrRequest(unique);
                                                                                    }
                                                                                    if (selectedValues.length > 1) {
                                                                                        e.stopPropagation();
                                                                                        selectAll(index);
                                                                                        addfilterfun(1);
                                                                                        // reqArr = [...aprArr, ...reqArr];
                                                                                        const compState = {};
                                                                                        aprArr.forEach(item => {
                                                                                            let key = item.comp_id + '' + item.u_id;
                                                                                            compState[key] = 1;
                                                                                            reqArr = reqArr.filter(val => {
                                                                                                let name = val.comp_id + '' + val.u_id;
                                                                                                return key !== name;
                                                                                            });
                                                                                        });
                                                                                        reqArr = [...aprArr, ...reqArr];
                                                                                        setMultiCompStatus({
                                                                                            ...multiCompStatus,
                                                                                            ...compState
                                                                                        });
                                                                                        setArrRequest(reqArr);
                                                                                        console.log('SelectedValues Greater than 1');
                                                                                        console.log('collapseIdentity', collapseIdenty);
                                                                                    }
                                                                                }
                                                                                } />;
                                                                        }
                                                                    }}>
                                                                <Panel header={<b>{titleName}</b>} key={1}
                                                                    extra={collapseIdenty[index] ? <><Form.Item style={{ margin: 0 }} label="Remarks">
                                                                        <Input onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            console.log('remarkInput', remarkInput);
                                                                        }} onChange={(e) => {
                                                                            let temp = e.target.value;
                                                                            setRemarkInput({
                                                                                ...remarkInput,
                                                                                [index]: temp,
                                                                            });
                                                                            // console.log('remarkChange', e.target.value);
                                                                        }} />
                                                                    </Form.Item></> : null} >
                                                                    {/* <Panel header={titleName} key={1} extra={<Input placeholder="Remarks" />}> */}
                                                                    {selectedValues && selectedValues.length < 2 ? <Table
                                                                        className='userprivclass'
                                                                        columns={assignedStatutorycolumns}
                                                                        dataSource={approved}
                                                                        bordered
                                                                        pagination={false}
                                                                    /> :
                                                                        <Table
                                                                            className='userprivclass'
                                                                            columns={multiUnitcolumns}
                                                                            dataSource={approved}
                                                                            expandable={{
                                                                                expandedRowRender: (record) => {
                                                                                    const id = record.comp_id;
                                                                                    let status = record.hasOwnProperty('applicable_units');
                                                                                    const data = record['applicable_units'];
                                                                                    if (status) {
                                                                                        // return <Table
                                                                                        //     columns={nestedTableColumns}
                                                                                        //     dataSource={record['applicable_units']}
                                                                                        //     bordered
                                                                                        //     pagination={false}
                                                                                        // />;
                                                                                        return <div className="ant-table-wrapper">
                                                                                            <div className="ant-table ant-table-bordered">
                                                                                                <div className="ant-table-container">
                                                                                                    <div className="ant-table-content">
                                                                                                        <table>
                                                                                                            <thead className="ant-table-thead">
                                                                                                                <tr>
                                                                                                                    <th className="ant-table-cell" style={{ width: '100px' }} >Location</th>
                                                                                                                    <th className="ant-table-cell" style={{ width: '310px', textAlign: 'left' }}>Unit Name/Vendor Name</th>
                                                                                                                    <th className="ant-table-cell" style={{ width: '33px' }}>
                                                                                                                        <Checkbox checked={tableID[id] === 1 ? true : false} onClick={(e) => {
                                                                                                                            let tempStatus = {};
                                                                                                                            let tabID = {};
                                                                                                                            tabID[id] = 1;
                                                                                                                            if (e.target.checked) {
                                                                                                                                data.map((item, i) => {
                                                                                                                                    let name = item.compId + '' + item.u_id;
                                                                                                                                    tempStatus[name] = 1;
                                                                                                                                    const temp = {
                                                                                                                                        "u_id": item.u_id,
                                                                                                                                        "comp_id": item.compId,
                                                                                                                                        "comp_status": 1,
                                                                                                                                        "level_1_s_id": item.levelId,
                                                                                                                                        "a_status": 1,
                                                                                                                                        "remarks": null,
                                                                                                                                        "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                        "u_name": item.unitVendor.split(',')[0]
                                                                                                                                    };

                                                                                                                                    reqArr = reqArr.filter(item => {
                                                                                                                                        let key = item.comp_id + '' + item.u_id;
                                                                                                                                        return key !== name;
                                                                                                                                    });

                                                                                                                                    reqArr.push(temp);
                                                                                                                                    setArrRequest(reqArr);
                                                                                                                                    console.log('tempPay', temp);
                                                                                                                                    console.log('ReqArr', reqArr);
                                                                                                                                    console.log('arrRequest', arrRequest);
                                                                                                                                    // }
                                                                                                                                });
                                                                                                                            }
                                                                                                                            setMultiCompStatus({
                                                                                                                                ...multiCompStatus,
                                                                                                                                ...tempStatus
                                                                                                                            });
                                                                                                                            setTableID({
                                                                                                                                ...tableID,
                                                                                                                                ...tabID
                                                                                                                            });
                                                                                                                        }} />

                                                                                                                    </th>
                                                                                                                    <th className="ant-table-cell" style={{ width: '33px' }}>
                                                                                                                        <Checkbox checked={tableID[id] === 2 ? true : false} onClick={(e) => {
                                                                                                                            let tempStatus = {};
                                                                                                                            let tabID = {};
                                                                                                                            tabID[id] = 2;
                                                                                                                            if (e.target.checked) {
                                                                                                                                data.map((item, i) => {
                                                                                                                                    let name = item.compId + '' + item.u_id;
                                                                                                                                    tempStatus[name] = 2;
                                                                                                                                    const temp = {
                                                                                                                                        "u_id": item.u_id,
                                                                                                                                        "comp_id": item.compId,
                                                                                                                                        "comp_status": 2,
                                                                                                                                        "level_1_s_id": item.levelId,
                                                                                                                                        "a_status": 2,
                                                                                                                                        "remarks": null,
                                                                                                                                        "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                        "u_name": item.unitVendor.split(',')[0]
                                                                                                                                    };

                                                                                                                                    reqArr = reqArr.filter(item => {
                                                                                                                                        let key = item.comp_id + '' + item.u_id;
                                                                                                                                        return key !== name;
                                                                                                                                    });

                                                                                                                                    reqArr.push(temp);
                                                                                                                                    setArrRequest(reqArr);
                                                                                                                                    console.log('tempPay', temp);
                                                                                                                                    console.log('ReqArr', reqArr);
                                                                                                                                    console.log('arrRequest', arrRequest);
                                                                                                                                    // }
                                                                                                                                });
                                                                                                                            }
                                                                                                                            setMultiCompStatus({
                                                                                                                                ...multiCompStatus,
                                                                                                                                ...tempStatus
                                                                                                                            });
                                                                                                                            setTableID({
                                                                                                                                ...tableID,
                                                                                                                                ...tabID
                                                                                                                            });
                                                                                                                        }} />
                                                                                                                    </th>
                                                                                                                    <th className="ant-table-cell" style={{ width: '33px' }}>
                                                                                                                        <Checkbox checked={tableID[id] === 3 ? true : false} onClick={(e) => {
                                                                                                                            let tempStatus = {};
                                                                                                                            let tabID = {};
                                                                                                                            tabID[id] = 3;
                                                                                                                            if (e.target.checked) {
                                                                                                                                data.map((item, i) => {
                                                                                                                                    let name = item.compId + '' + item.u_id;
                                                                                                                                    tempStatus[name] = 3;
                                                                                                                                    const temp = {
                                                                                                                                        "u_id": item.u_id,
                                                                                                                                        "comp_id": item.compId,
                                                                                                                                        "comp_status": 3,
                                                                                                                                        "level_1_s_id": item.levelId,
                                                                                                                                        "a_status": 3,
                                                                                                                                        "remarks": null,
                                                                                                                                        "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                        "u_name": item.unitVendor.split(',')[0]
                                                                                                                                    };

                                                                                                                                    reqArr = reqArr.filter(item => {
                                                                                                                                        let key = item.comp_id + '' + item.u_id;
                                                                                                                                        return key !== name;
                                                                                                                                    });

                                                                                                                                    reqArr.push(temp);
                                                                                                                                    setArrRequest(reqArr);
                                                                                                                                    console.log('tempPay', temp);
                                                                                                                                    console.log('ReqArr', reqArr);
                                                                                                                                    console.log('arrRequest', arrRequest);
                                                                                                                                    // }
                                                                                                                                });
                                                                                                                            }
                                                                                                                            setMultiCompStatus({
                                                                                                                                ...multiCompStatus,
                                                                                                                                ...tempStatus
                                                                                                                            });
                                                                                                                            setTableID({
                                                                                                                                ...tableID,
                                                                                                                                ...tabID
                                                                                                                            });
                                                                                                                        }} />
                                                                                                                    </th>
                                                                                                                    <th className="ant-table-cell" style={{ width: '30px' }}>
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody className="ant-table-tbody">
                                                                                                                {data.map((item, i) => {
                                                                                                                    let name = item.compId + '' + item.u_id;
                                                                                                                    return <tr className="ant-table-row ant-table-row-level-0" key={i}>
                                                                                                                        <td className="ant-table-cell" style={{ textAlign: 'center' }}>{item.location}</td>
                                                                                                                        <td className="ant-table-cell">{item.unitVendor}</td>
                                                                                                                        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
                                                                                                                            <CheckCircleOutlined style={{
                                                                                                                                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                                                                                                                                color: multiCompStatus[name] === 1 ? 'green' : ''
                                                                                                                            }} onClick={() => {
                                                                                                                                setMultiCompStatus({
                                                                                                                                    ...multiCompStatus,
                                                                                                                                    [name]: 1
                                                                                                                                });
                                                                                                                                setOption({ ...option, [index]: 1 });
                                                                                                                                let remarkEnable = {};
                                                                                                                                remarkEnable = { ...remarkEnable, [index]: false };

                                                                                                                                dispatch({
                                                                                                                                    type: REMARK_STATE,
                                                                                                                                    payload: {
                                                                                                                                        ...collapseIdenty,
                                                                                                                                        ...remarkEnable
                                                                                                                                    }
                                                                                                                                });

                                                                                                                                const temp = {
                                                                                                                                    "u_id": item.u_id,
                                                                                                                                    "comp_id": item.compId,
                                                                                                                                    "comp_status": 1,
                                                                                                                                    "level_1_s_id": item.levelId,
                                                                                                                                    "a_status": 1,
                                                                                                                                    "remarks": null,
                                                                                                                                    "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                    "u_name": item.unitVendor.split(',')[0]
                                                                                                                                };

                                                                                                                                reqArr = reqArr.filter(item => {
                                                                                                                                    let key = item.comp_id + '' + item.u_id;
                                                                                                                                    return key !== name;
                                                                                                                                });

                                                                                                                                reqArr.push(temp);
                                                                                                                                setArrRequest(reqArr);
                                                                                                                                console.log('tempPay', temp);
                                                                                                                                console.log('ReqArr', reqArr);
                                                                                                                                console.log('arrRequest', arrRequest);
                                                                                                                            }} />
                                                                                                                        </td>
                                                                                                                        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
                                                                                                                            <CloseCircleOutlined style={{
                                                                                                                                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                                                                                                                                color: multiCompStatus[name] === 2 ? 'darkorange' : ''
                                                                                                                            }} onClick={() => {
                                                                                                                                setMultiCompStatus({
                                                                                                                                    ...multiCompStatus,
                                                                                                                                    [name]: 2
                                                                                                                                });

                                                                                                                                const aprTemp = approved.map(item => item['applicable_units']).flat();
                                                                                                                                const values = [2];
                                                                                                                                aprTemp.map(item => {
                                                                                                                                    if (item) {
                                                                                                                                        let nameKey = item.compId + '' + item.u_id;
                                                                                                                                        if (name !== nameKey) {
                                                                                                                                            values.push(multiCompStatus[nameKey]);                                                                                                                                            
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                });
                                                                                                                                let remarkEnable = {};
                                                                                                                                let disable = values.some(item => item == 1 || null);
                                                                                                                                
                                                                                                                                if (disable) {
                                                                                                                                    setOption({ ...option, [index]: 1 });
                                                                                                                                    remarkEnable = { ...remarkEnable, [index]: false };
                                                                                                                                }
                                                                                                                                if (!disable) {
                                                                                                                                    let temp = values.some(item => item == 2);
                                                                                                                                    temp ? setOption({ ...option, [index]: 2 }) : setOption({ ...option, [index]: 3 });
                                                                                                                                    remarkEnable = { ...remarkEnable, [index]: !disable };
                                                                                                                                }

                                                                                                                                dispatch({
                                                                                                                                    type: REMARK_STATE,
                                                                                                                                    payload: {
                                                                                                                                        ...collapseIdenty,
                                                                                                                                        ...remarkEnable
                                                                                                                                    }
                                                                                                                                });

                                                                                                                                const temp = {
                                                                                                                                    "u_id": item.u_id,
                                                                                                                                    "comp_id": item.compId,
                                                                                                                                    "comp_status": 2,
                                                                                                                                    "level_1_s_id": item.levelId,
                                                                                                                                    "a_status": 2,
                                                                                                                                    "remarks": null,
                                                                                                                                    "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                    "u_name": item.unitVendor.split(',')[0]
                                                                                                                                };

                                                                                                                                reqArr = reqArr.filter(item => {
                                                                                                                                    let key = item.comp_id + '' + item.u_id;
                                                                                                                                    return key !== name;
                                                                                                                                });

                                                                                                                                reqArr.push(temp);
                                                                                                                                setArrRequest(reqArr);
                                                                                                                                console.log('tempPay', temp);
                                                                                                                                console.log('ReqArr', reqArr);
                                                                                                                                console.log('arrRequest', arrRequest);
                                                                                                                            }} />
                                                                                                                        </td>
                                                                                                                        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
                                                                                                                            <MinusCircleOutlined style={{
                                                                                                                                border: '0px solid green', borderRadius: '50%', fontSize: '18px',
                                                                                                                                color: multiCompStatus[name] === 3 ? 'red' : ''
                                                                                                                            }} onClick={() => {
                                                                                                                                setMultiCompStatus({
                                                                                                                                    ...multiCompStatus,
                                                                                                                                    [name]: 3
                                                                                                                                });

                                                                                                                                const aprTemp = approved.map(item => item['applicable_units']).flat();
                                                                                                                                const values = [3];
                                                                                                                                aprTemp.map(item => {
                                                                                                                                    if (item) {
                                                                                                                                        let nameKey = item.compId + '' + item.u_id;
                                                                                                                                        if (name !== nameKey) {
                                                                                                                                            values.push(multiCompStatus[nameKey]);                                                                                                                                            
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                });

                                                                                                                                let remarkEnable = {};
                                                                                                                                let disable = values.some(item => item == 1 || null);
                                                                                                                                
                                                                                                                                if (disable) {
                                                                                                                                    setOption({ ...option, [index]: 1 });
                                                                                                                                    remarkEnable = { ...remarkEnable, [index]: false };
                                                                                                                                }
                                                                                                                                if (!disable) {
                                                                                                                                    let temp = values.some(item => item == 2);
                                                                                                                                    temp ? setOption({ ...option, [index]: 2 }) : setOption({ ...option, [index]: 3 });
                                                                                                                                    remarkEnable = { ...remarkEnable, [index]: !disable };
                                                                                                                                }

                                                                                                                                dispatch({
                                                                                                                                    type: REMARK_STATE,
                                                                                                                                    payload: {
                                                                                                                                        ...collapseIdenty,
                                                                                                                                        ...remarkEnable
                                                                                                                                    }
                                                                                                                                });

                                                                                                                                const temp = {
                                                                                                                                    "u_id": item.u_id,
                                                                                                                                    "comp_id": item.compId,
                                                                                                                                    "comp_status": 3,
                                                                                                                                    "level_1_s_id": item.levelId,
                                                                                                                                    "a_status": 3,
                                                                                                                                    "remarks": null,
                                                                                                                                    "client_statutory_id": item.clientStatutoryId ? item.clientStatutoryId : null,
                                                                                                                                    "u_name": item.unitVendor.split(',')[0]
                                                                                                                                };

                                                                                                                                reqArr = reqArr.filter(item => {
                                                                                                                                    let key = item.comp_id + '' + item.u_id;
                                                                                                                                    return key !== name;
                                                                                                                                });

                                                                                                                                reqArr.push(temp);
                                                                                                                                setArrRequest(reqArr);
                                                                                                                                console.log('tempPay', temp);
                                                                                                                                console.log('ReqArr', reqArr);
                                                                                                                                console.log('arrRequest', arrRequest);
                                                                                                                            }} />
                                                                                                                        </td>
                                                                                                                        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
                                                                                                                            {item.s_s == 1 || multiCompStatus.hasOwnProperty(name) ? <Checkbox checked={true} /> : null}
                                                                                                                        </td>
                                                                                                                    </tr>;
                                                                                                                })}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>;
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                },
                                                                                showExpandColumn: false,
                                                                                defaultExpandAllRows: true
                                                                            }}
                                                                            rowKey={(record) => record.u_id}
                                                                            // bordered
                                                                            pagination={false}
                                                                        />}
                                                                </Panel>
                                                            </Collapse>
                                                        );
                                                    })
                                                    }</div>
                                                {/* {assignedStatutoryData.length != 0 && */}
                                                {reloadData.length !== 0 && <>
                                                    {/* <div className='px-3'>Showing</div> */}
                                                    <Space direction="horizontal" className='px-2 py-2 pb-3' style={{ width: '100%', justifyContent: 'center' }}>
                                                        {rowCount < recordCount || rowCount < addUnitCount ?
                                                            <Button shape="round" onClick={() => {
                                                                if (recordCount !== 0 && recordCount > rowCount) {
                                                                    console.log('recordCount', recordCount, rowCount);
                                                                    console.log('addUnitCount', addUnitCount);

                                                                    const pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetAssignedStatutoryWizardTwoData",
                                                                            {
                                                                                "d_id": parseInt(inputValues.domainId),
                                                                                "unit_ids": [inputValues.unitId],
                                                                                "rcount": rowCount
                                                                            }
                                                                        ]
                                                                    };

                                                                    assignedStatutoryTwoData({
                                                                        payload: pay,
                                                                        paramid: paramid
                                                                    });

                                                                    // setRowCount(rowCount + 50);
                                                                }

                                                                if (addUnitCount !== 0 && addUnitCount > rowCount) {
                                                                    // if (addUnitCount > rowCount) {
                                                                    console.log('addUnitCount', addUnitCount, rowCount);
                                                                    const pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetAssignedStatutoryWizardTwoData",
                                                                            {
                                                                                "d_id": parseInt(addOptionFilter.domainId),
                                                                                "unit_ids": selectedValues,
                                                                                // "rcount": rowCount
                                                                                "rcount": add_rcount
                                                                            }
                                                                        ]
                                                                    };

                                                                    addTwoData({
                                                                        payload: pay,
                                                                        paramid: paramid
                                                                    });

                                                                    setadd_rcount(add_rcount + 25);
                                                                    // setRowCount(rowCount + 50);
                                                                }
                                                            }}>Show More</Button> : ''}
                                                    </Space>
                                                    {recordCount ? <div className='px-3 header'>Showing {rowCount !== 0 ? 1 : 0} to {recordCount > rowCount ? rowCount : recordCount} of {recordCount ? recordCount : addUnitCount} entries </div> : <div className='px-3 header'>Showing {rowCount !== 0 ? 1 : 0} to {addUnitCount > rowCount ? rowCount : addUnitCount} of {addUnitCount ? addUnitCount : recordCount} entries</div>}
                                                    <Space direction="horizontal" className='px-3 py-3' style={{ width: '100%', justifyContent: 'flex-end' }}>
                                                        <Button type="primary" shape="round" onClick={() => {
                                                            let validate = true;
                                                            let loop = true;
                                                            const remarksValidate = Object.values(remarkInput);
                                                            remarksValidate.map((remark, num) => {
                                                                if (collapseIdenty[num] && loop) {
                                                                    if (remark !== null) {
                                                                        validate = true;
                                                                    } else {
                                                                        validate = false;
                                                                        loop = false;
                                                                    }
                                                                }
                                                            });
                                                            console.log('validate', validate);

                                                            if (reqArr.length !== 0 && validate) {
                                                                // if (reqArr.length !== 0) {
                                                                remarksValidate.map((item, i) => {
                                                                    remarks[i].map((rem, numb) => {
                                                                        reqArr = reqArr.map(value => {
                                                                            if (value.comp_id === rem) {
                                                                                return { ...value, 'remarks': item };
                                                                            } else {
                                                                                return value;
                                                                            }
                                                                        });
                                                                    });
                                                                });

                                                                let pay;
                                                                if (selectedValues) {
                                                                    pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "SaveAssignedStatutory",
                                                                            {
                                                                                "compliances_applicablity_status": reqArr,
                                                                                "submission_status": 1,
                                                                                "ct_id": parseInt(addOptionFilter.groupId),
                                                                                "le_id": parseInt(addOptionFilter.legalEntityId),
                                                                                "d_id": parseInt(addOptionFilter.domainId),
                                                                                "d_name": addOptionFilter.domain,
                                                                                "unit_ids": selectedValues,
                                                                                "legal_entity_name": addOptionFilter.legalEntityName,
                                                                                "b_grp_name": addOptionFilter.businessGroup || null
                                                                            }
                                                                        ]
                                                                    };
                                                                }

                                                                if (selectedValues.length == 0) {
                                                                    pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "SaveAssignedStatutory",
                                                                            {
                                                                                "compliances_applicablity_status": reqArr,
                                                                                "submission_status": 1,
                                                                                "ct_id": parseInt(inputValues.clientId),
                                                                                "le_id": parseInt(inputValues.legalEntityId),
                                                                                "d_id": parseInt(inputValues.domainId),
                                                                                "d_name": inputValues.domainName,
                                                                                "unit_ids": [
                                                                                    parseInt(inputValues.unitId)
                                                                                ],
                                                                                "legal_entity_name": inputValues.legalEntityName,
                                                                                "b_grp_name": inputValues.businessGroupBread || null
                                                                            }
                                                                        ]
                                                                    };
                                                                }

                                                                SaveAssignedStatutory({
                                                                    payload: pay,
                                                                    paramid: paramid
                                                                });

                                                                console.log('destructure', pay);
                                                                console.log('unitID', inputValues.unitId);
                                                            } else if (reqArr.length == 0) {
                                                                Toaster.error('No updation in assign statutory');
                                                            } else {
                                                                Toaster.error('Remarks Required');
                                                            }
                                                        }}>Save</Button>
                                                        {rowCount == recordCount || rowCount == addUnitCount ?
                                                            <Button type="primary" shape="round" onClick={() => {
                                                                remarks.map((item, i) => {
                                                                    if (remarkInput[i] !== 0) {
                                                                        item.map(arg => {
                                                                            reqArr = reqArr.map(value => {
                                                                                if (value.comp_id === arg) {
                                                                                    return {
                                                                                        ...value,
                                                                                        remarks: remarkInput[i]
                                                                                    };
                                                                                } else {
                                                                                    return { ...value };
                                                                                }
                                                                            });
                                                                        });
                                                                    }

                                                                });

                                                                console.log('reqArr', reqArr);
                                                                console.log('totalviewed', reqArr.length + totalViewed);
                                                                let temp = Object.values(view).every(item => item === 1);
                                                                console.log('temp', temp);

                                                                let pay;
                                                                if (selectedValues) {
                                                                    pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "SaveAssignedStatutory",
                                                                            {
                                                                                "compliances_applicablity_status": reqArr,
                                                                                "submission_status": 2,
                                                                                "ct_id": parseInt(addOptionFilter.groupId),
                                                                                "le_id": parseInt(addOptionFilter.legalEntityId),
                                                                                "d_id": parseInt(addOptionFilter.domainId),
                                                                                "d_name": addOptionFilter.domain,
                                                                                "unit_ids": selectedValues,
                                                                                "legal_entity_name": addOptionFilter.legalEntityName,
                                                                                "b_grp_name": addOptionFilter.businessGroup || null
                                                                            }
                                                                        ]
                                                                    };
                                                                }

                                                                if (selectedValues.length == 0) {
                                                                    pay = {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "SaveAssignedStatutory",
                                                                            {
                                                                                "compliances_applicablity_status": reqArr,
                                                                                "submission_status": 2,
                                                                                "ct_id": parseInt(inputValues.clientId),
                                                                                "le_id": parseInt(inputValues.legalEntityId),
                                                                                "d_id": parseInt(inputValues.domainId),
                                                                                "d_name": inputValues.domainName,
                                                                                "unit_ids": [
                                                                                    parseInt(inputValues.unitId)
                                                                                ],
                                                                                "legal_entity_name": inputValues.legalEntityName,
                                                                                "b_grp_name": inputValues.businessGroupBread || null
                                                                            }
                                                                        ]
                                                                    };
                                                                }

                                                                // if (recordCount === (reqArr.length + totalViewed)) {
                                                                if (temp) {
                                                                    SubmitAssignedStatutory({
                                                                        payload: pay,
                                                                        paramid: paramid
                                                                    });

                                                                    console.log('submit', pay);
                                                                    console.log('recordCount', recordCount);
                                                                    console.log('reqarrlength', reqArr.length);
                                                                } else {
                                                                    Toaster.error('All compliance should be selected before submit');
                                                                }

                                                            }}>Submit</Button> : null}
                                                    </Space>
                                                </>
                                                }
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div> : ''}
                </div>
            </div>
            <Modal title="Assign Statutory Search" className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} footer={false} open={filterModalVisible} onCancel={setcancelFilter}>
                <div className='row'>
                    <div className='col-4'>
                        <label><b>Client Group :</b> <span style={{ color: "red" }}>*</span></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Client Group"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={inputValues.clientId || undefined}
                                    onChange={(data, value) => {
                                        console.log(value, 'valuevalue');
                                        if (data != undefined) {
                                            getLegalEntityData(data, value);
                                        } else {
                                            setInputValues({
                                                ...inputValues,
                                                clientId: '',
                                                clientName: '',
                                                legalEntityData: '',
                                                legalEntityId: '',
                                                legalEntityName: '',
                                                domainData: '',
                                                domainId: '',
                                                domainName: '',
                                                unitId: '',
                                                unitName: '',
                                                unitData: ''
                                            });
                                        }
                                    }}
                                >
                                    {deassignstatutorylegalentityfilter.grps && deassignstatutorylegalentityfilter.grps.length > 0 && deassignstatutorylegalentityfilter.grps.map((item, i) => {
                                        return (
                                            <Option key={item.client_id}>
                                                {item.group_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {validator.current.message(
                                    'Client Group',
                                    inputValues.clientId,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Client Group Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <label><b>Legal Entity :</b> <span style={{ color: "red" }}>*</span></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Client Group"
                                    disabled={inputValues.clientId == ''}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={inputValues.legalEntityId || undefined}
                                    onChange={(data, value) => {
                                        if (data != undefined) {
                                            getOtherDetails(data, value);
                                        } else {
                                            setInputValues({
                                                ...inputValues,
                                                legalEntityId: '',
                                                legalEntityName: '',
                                                domainId: '',
                                                domainName: '',
                                                domainData: '',
                                                unitId: '',
                                                unitName: '',
                                                unitData: ''
                                            });
                                        }
                                    }}
                                >
                                    {inputValues.legalEntityData && inputValues.legalEntityData.length > 0 && inputValues.legalEntityData.map((item, i) => {
                                        return (
                                            <Option key={item.legal_entity_id}>
                                                {item.legal_entity_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {validator.current.message(
                                    'Legal Entity',
                                    inputValues.legalEntityId,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Legal Entity Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <label><b>Domain :</b> <span style={{ color: "red" }}>*</span></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Domain"
                                    disabled={inputValues.legalEntityId == ''}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={inputValues.domainId || undefined}
                                    onChange={(data, value) => {
                                        console.log(value, 'valuevalue');
                                        if (data != undefined) {
                                            getUnitDetails(data, value);
                                        } else {
                                            setInputValues({
                                                ...inputValues,
                                                domainId: '',
                                                domainName: '',
                                                unitId: '',
                                                unitName: '',
                                                unitData: ''
                                            });
                                        }
                                    }}
                                >
                                    {inputValues.domainData && inputValues.domainData.length > 0 && inputValues.domainData.map((item, i) => {
                                        return (
                                            <Option key={item.domain_id}>
                                                {item.domain_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                                {validator.current.message(
                                    'Domain',
                                    inputValues.domainId,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Domain Required',
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Status :</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    disabled={inputValues.domainId == ''}
                                    defaultValue={'0'}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={inputValues.status || undefined}
                                    onChange={(data, value) => {
                                        console.log(value, 'valuevalue');
                                        if (data != undefined) {
                                            setInputValues({
                                                ...inputValues,
                                                status: data
                                            });
                                        } else {
                                            setInputValues({
                                                ...inputValues,
                                                unitId: '',
                                                unitName: ''
                                            });
                                        }
                                    }}
                                >
                                    <Option value="0">All</Option>
                                    <Option value="1">Yet to submit</Option>
                                    <Option value="2">Pending</Option>
                                    <Option value="3">Approved</Option>
                                    <Option value="4">Rejected</Option>
                                    <Option value="5">Assigned</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 mt-1'>
                        <label><b>Unit :</b></label>
                        <div className="form-group">
                            <div className="form-group">
                                <Select
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Unit"
                                    disabled={inputValues.domainId == ''}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%' }}
                                    value={inputValues.unitId || undefined}
                                    onChange={(data, value) => {
                                        console.log(value, 'valuevalue');
                                        if (data != undefined) {
                                            setInputValues({
                                                ...inputValues,
                                                unitId: data,
                                                unitName: value.children
                                            });
                                        } else {
                                            setInputValues({
                                                ...inputValues,
                                                unitId: '',
                                                unitName: ''
                                            });
                                        }
                                    }}
                                >
                                    {inputValues.unitData && inputValues.unitData.length > 0 && inputValues.unitData.map((item, i) => {
                                        return (
                                            <Option key={item.unit_id}>
                                                {item.unit_name}
                                            </Option>
                                        );
                                    }
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center mt-2'>
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                        icon={<EyeOutlined />}
                        onClick={showResult}
                    >
                        Show
                    </Button>
                </div>

            </Modal>
        </>);
};


export default connect(mapStateToProps, {
    getassignstatutoryfilter,
    GetAssignStatutoryClientDetails,
    GetAssignedStatutories,
    assignedStatutoryCount,
    assignedStatutoryTwoData,
    addOption,
    addUnitsList,
    addTwoCount,
    addTwoData,
    SaveAssignedStatutory,
    SubmitAssignedStatutory
})(DE_Assignstatutory);