import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { APPROVEASSIGNFILTER, APPROVEASSIGNLEGALDOMAINFILTER, APPROVEASSIGNUSERFILTER, ASSIGNED_STATUTORIES_FOR_APPROVE, GET_ASSIGNED_STATUTORY_DATA_SUCCESS } from '../../types/index'

const initialState = {
   
    approveassignedstatutory: {
        approveassignfilter: [],
        approveassignlegaldomainfilter: [],
        approveassignuserfilter: [],
        approveAssignedStatutoriesList: [],
        assignedStatutoryDataSuccess: [],
        assignedStatutoryDataCount:[]
       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVEASSIGNFILTER:
            {
                return {
                    ...state,
                    approveassignedstatutory: {
                        ...state.approveassignedstatutory,
                        approveassignfilter: payload,
                    },
                };
            }
        case APPROVEASSIGNLEGALDOMAINFILTER:
            {
                return {
                    ...state,
                    approveassignedstatutory:{
                        ...state.approveassignedstatutory,
                        approveassignlegaldomainfilter: payload
                    }
                }
            }

        case APPROVEASSIGNUSERFILTER:{
            return {
                ...state,
                approveassignedstatutory:{
                    ...state.approveassignedstatutory,
                    approveassignuserfilter: payload
                }
            }
        }

        case ASSIGNED_STATUTORIES_FOR_APPROVE:{
            return {
                ...state,
                approveassignedstatutory:{
                    ...state.approveassignedstatutory,
                    approveAssignedStatutoriesList: payload
                }
            }
        }

        case GET_ASSIGNED_STATUTORY_DATA_SUCCESS:{
            const count = payload.rcount;
            const ruleTypes = payload.statutories_for_assigning.map(item => item["level_1_s_name"]);
            const types = ruleTypes.filter((item,index) => ruleTypes.indexOf(item) === index);
            const data = []; 
            types.map(item => {
                        let filteredArr = payload.statutories_for_assigning.filter(types => item == types["level_1_s_name"])
                        data.push(filteredArr)
                    })

            return {
                ...state,
                approveassignedstatutory:{
                    ...state.approveassignedstatutory,
                    // assignedStatutoryWizardTwoDataSuccess: payload
                    assignedStatutoryDataCount: count,
                    assignedStatutoryDataSuccess: { statutories_for_assigning: data }

                }
            }
        }
       
        default:
            return state;
    }
}

