import HTTP from "../../Libs/http";
import { Toaster } from "../../Libs/Toaster";
import url from "../../Libs/URL";
import EncryptDecrypt from "../../Libs/Encrypt&Decrypt";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { TOGGLE_COMMON_LOADER } from "../types";


export const forgotPassword = ({ payload, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const forgotpayload = [
            'ForgotPassword', {
                'username': payload.userId,
                'short_name': null,
                'login_type': 'Web'
            }

        ]
        console.log(forgotpayload,'forgotPAyload')
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Accept': '*/*',
                'Compfie-Info': encoded,
                'Content-Type': 'application/json'
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(forgotpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ForgotPasswordSuccess') {
                Toaster.success("Password resent link has been sent to your email id")
            }else if(decryptData[0] === 'ForgotLinkHistory'){
                Toaster.error('Forgot Password Request Limit Exceed')
            }else if(decryptData[0] === 'InvalidUserName'){
                Toaster.error('Your search did not return any results. Please try again.')
            } else {
                Toaster.error(decryptData[0])
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};