import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { gettaskmapping, getviewtaskmapping, unmapaction, reassignaction, gettaskcategorymapping, savetaskcategorymapping, gettaskcategorymappingshowmore } from '../../Store/Action/Transactions/Taskcategory';
import { Toaster } from '../../Libs/Toaster'
import { entries, update } from 'lodash';
import { ArrowLeftOutlined, EyeOutlined, PlayCircleOutlined, FullscreenOutlined } from '@ant-design/icons';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';



const _ = require("lodash");

const mapStateToProps = (state) => ({
    viewlist: state.Taskcategory.gettaskcategorymappingfilter,
    // viewlistnew: state.Taskcategory.gettaskcategorymappingnew,
    viewlistnew: state.Taskcategory.gettaskcategorymappinglist,
    viewlistnewshowmore: state.Taskcategory.gettaskcategorymappingnew


})
const Taskcategoryadd = ({
    viewlist,
    gettaskcategorymapping,
    viewlistnew,
    savetaskcategorymapping,
    gettaskcategorymappingshowmore,
    viewlistnewshowmore,
    fullscreen,
    setfullscreen,

}) => {
    console.log(viewlistnew, 'viewlistnew')
    const [userfullscreen, setuserfullscreen] = useState('');
    const [addChange, setAddChange] = useState(false)
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const [filteredDomain, setFilteredDomain] = useState([]);
    const [filteredAct, setFilteredAct] = useState([]);
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [finalArray, setFinalArray] = useState([]);
    const [finalArrayNew, setFinalArrayNew] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const validator1 = useRef(new SimpleReactValidator());
    const validator = useRef(new SimpleReactValidator());
    const [visible, setVisible] = useState(false);
    const [temparr, setTemparr] = useState([])
    console.log(filteredAct, 'filteredArrayState')
    const [selectedvalue, setSelectedvalue] = useState({
        country: '',
        taskcategory: '',
        tasksubcategory: ''
    })
    const { Panel } = Collapse;
    const [count, setCount] = useState(1);
    const [datas, setDatas] = useState([]);
    const [checkedRow, setCheckedRow] = useState([]);
    const [checkAll, setCheckAll] = useState('');
    const [complianceId, setComplianceId] = useState([]);
    const [assignpayload, setAssignPayload] = useState([])
    const { Option } = Select;
    const [taskCatSubCat, setTaskCatSubcat] = useState({
        taskCat: [],
        taskSubCat: []
    });
    const [taskSubCatList, setTaskSubCatList] = useState([]);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [filteredArr, setFilteredArr] = useState({
        countryId: '',
        domainId: '',
        actId: ''
    });
    console.log(filteredArr, 'filteredArr')
    const [actList, setActList] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [entires, setEntries] = useState(100)
    console.log(entires, 'entires')
    const [titledata, Settitledata] = useState({
        displauvalue: ''
    })

    const [updatedArray, setUpdatedArray] = useState([])
    const [taskCat, setTaskCat] = useState([])
    const [taskSubCat, setTaskSubCat] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)
    console.log(totalRecords, 'totalRecords')

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
    })
    const [updateArrayShow, setUpdatedArrayShow] = useState([])
    const [updateArrayShowMore, setUpdatedArrayShowMore] = useState([])

    useEffect(() => {
        if (viewlistnew.task_mapping_data) {
            let arrayTemp = [];
            setTotalRecords(viewlistnew.total_records);
            for (let i in viewlistnew.task_mapping_data) {
                arrayTemp.push(viewlistnew.task_mapping_data[i])
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged])
            setUpdatedArrayShow([...merged])
            setTaskCat([...viewlistnew.task_group])
        }

    }, [viewlistnew])

    useEffect(() => {
        if (viewlistnewshowmore) {
            let arrayTempshowmore = [];
            for (let i in viewlistnewshowmore) {
                arrayTempshowmore.push(viewlistnewshowmore[i].task_mapping_data)
            }
            var mergedshowmore = [].concat.apply([], arrayTempshowmore);
            setUpdatedArrayShowMore([...mergedshowmore])
        }

    }, [viewlistnewshowmore])

    useEffect(() => {
        if (updateArrayShowMore.length > 0) {
            setUpdatedArray([])
            setUpdatedArray([...updateArrayShow, ...updateArrayShowMore])
        }
    }, [updateArrayShowMore])

    // useEffect(() => {
    //     if (viewlistnew) {
    //         setTotalRecords(viewlistnew[0].total_records);
    //         let arrayTemp = [];
    //         let categoryArray = [];

    //         for (let i in viewlistnew) {
    //             console.log(viewlistnew[i].task_mapping_data, 'viewlistnew[i')
    //             arrayTemp.push(viewlistnew[i].task_mapping_data)
    //             categoryArray.push(viewlistnew[i].task_group)
    //         }
    //         var merged = [].concat.apply([], arrayTemp);
    //         var mergedTaskCat = [].concat.apply([], categoryArray);
    //         setUpdatedArray([...merged])
    //         setTaskCat([...mergedTaskCat])
    //     }
    // }, [viewlistnew])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (filteredArr.countryId) {
            viewlist.domains && viewlist.domains.length > 0 && viewlist.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(filteredArr.countryId));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name
                    }
                    domainArray.push(tempArray)
                }
            })
            setDomainList(domainArray)
        }
    }, [filteredArr.countryId])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (filteredArr.countryId && filteredArr.domainId) {
            let getActData = _.filter(viewlist.level_1_statutories_users && viewlist.level_1_statutories_users.length > 0 && viewlist.level_1_statutories_users, { country_id: parseInt(filteredArr.countryId), domain_id: parseInt(filteredArr.domainId) })
            setActList(getActData)
        }
    }, [filteredArr.domainId])

    const getrecords = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            setUpdatedArray([])
            setUpdatedArrayShowMore([])
            setUpdatedArrayShow([])
            setFinalArray([])
            setfilteredArrayState([])
            setTaskCat([])
            setEntries(100)
            setShowAll(true);
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "GetTaskCategoryMapping",
                    {
                        "country_id": filteredArr.countryId,
                        "d_i_d": filteredArr.domainId ? filteredArr.domainId : null,
                        "act_id": filteredArr.actId ? filteredArr.actId : null,
                        "r_count": 0,
                        "page_count": 100,

                    }
                ]
            }
            gettaskcategorymapping({
                payload: payload2,
                caller: paramid,
            })
        }
    }

    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {
            let uniqueObjArray = [...new Map(updatedArray && updatedArray.length > 0 && updatedArray.map((item) => [item["domain_name"], item])).values()];
            console.log(uniqueObjArray, 'uniqueObjArray');
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.domain_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(updatedArray && updatedArray.length > 0 && updatedArray, { domain_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            console.log(uniqueIds, 'tempArry')
            setFilteredDomain([...filteredDomain, tempArr])
        }
    }, [updatedArray])

    useEffect(() => {
        if (filteredDomain.length > 0) {
            let temp = []
            for (let i in filteredDomain) {
                console.log(filteredDomain[i], 'filteredArray[i]');
                temp = filteredDomain[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredDomain])

    useEffect(() => {
        let tempArr = []
        if (updatedArray && updatedArray && updatedArray.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["act_name"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.act_name
                })
                console.log(uniqueIds, 'uniqueIds')
                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { act_name: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })
            setFilteredAct(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let countrylisttempdataNew = [];
        if (updatedArray && updatedArray.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        updatedArray && updatedArray.length > 0 && updatedArray.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "index": childIndex + 1,
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id,
                                    "parentIndex": i,
                                    "childId": childIndex
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    countrylisttempdataNew.push(temp)
                });

                var merged = [].concat.apply([], countrylisttempdataNew);
                setFinalArray(finalarray)
                setDatas(merged)
                Settitledata({
                    ...titledata,
                    displauvalue: [...finalarray]
                })
            }
        }
    }, [updatedArray, filteredAct])

    const downloadselectAllCheckBox = (checked, parent) => {
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + parent)
        if (checked === true) {
            setCheckedRow(true)

            array = _.filter(datas, { parentIndex: parent });
            console.log(parent, 'arrays')
            for (let i in array) {
                tempArray.push(array[i])
            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentIndex === parent) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    const downloadcheckBoxOnChange = (checked, child) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(datas, { childId: child });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].childId === child) {
                        tempArray.splice(i, 1)
                    }
                }
            }

            setTemparr([...tempArray])
        }
        else {
            setCheckAll('')
            if (checked === true) {
                array = _.find(datas, { childId: child });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].childId === child) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const gettasksubcat = (data) => {
        setTaskSubCatList([]);
        setTaskCatSubcat({
            ...taskCatSubCat,
            taskCat: parseInt(data),
            taskSubCat: ''
        })
        let filteredArrs = _.filter(viewlistnew.task_sub_cat && viewlistnew.task_sub_cat.length > 0 && viewlistnew.task_sub_cat, { task_group_id: parseInt(data) })
        setTaskSubCatList([...filteredArrs])
    }

    useEffect(() => {
        if (temparr && temparr.length > 0) {
            let tempArray = []
            for (let i in temparr) {
                tempArray.push(temparr[i].compliance_id)
            }
            setAssignPayload(tempArray)
        }
    }, [temparr])

    const submitrecord = () => {
        setAddFormSubmit1(true);
        if (assignpayload.length > 0) {
            if (validator1.current.allValid()) {
                let payload2 = {
                    "session_token": authtoken,
                    "request": [
                        "SaveTaskCategoryMapping",
                        {
                            "task_group_id": taskCatSubCat.taskCat,
                            "task_sub_group_id": parseInt(taskCatSubCat.taskSubCat),
                            "compliance_ids": assignpayload,
                            "is_approved": 1,
                        }
                    ]
                }

                savetaskcategorymapping({
                    payload: payload2,
                    caller: paramid,

                })
            }
        } else {
            Toaster.error('No compliance selected')
        }
    }

    const showMoreresult = () => {
        if (validator.current.allValid()) {
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "GetTaskCategoryMapping",
                    {
                        "country_id": filteredArr.countryId,
                        "d_i_d": filteredArr.domainId ? filteredArr.domainId : null,
                        "act_id": filteredArr.actId ? filteredArr.actId : null,
                        "r_count": entires,
                        "page_count": 100,

                    }
                ]
            }
            gettaskcategorymappingshowmore({
                payload: payload2,
                caller: paramid,
            })
            setEntries(entires + 100)
        }
    }

    const searchRecords = (e) => {
        const filterTable = updatedArray.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let countrylisttempdataNew = [];
        if (filterTable && filterTable.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        filterTable && filterTable.length > 0 && filterTable.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "index": childIndex + 1,
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id,
                                    "parentIndex": i,
                                    "childId": childIndex
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    countrylisttempdataNew.push(temp)
                });

                var merged = [].concat.apply([], countrylisttempdataNew);
                setFinalArray(finalarray)
                setDatas(merged)
                Settitledata({
                    ...titledata,
                    displauvalue: [...finalarray]
                })
            }
        } else {
            setFinalArray([])
        }
    }
    const previouspage = () => {
        window.location.reload();
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    useEffect(() => {
        console.log(fullscreen,'fullscreen')
        if (userfullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);
    const fullscreenMode = () => {
        if (fullscreen == true) {
            fullscreenmode();
            setfullscreen(false);
            setAddChange(true)
            setuserfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(true);
            setAddChange(true);
            setuserfullscreen(false);
        }
    }

    useEffect(() => {
        if (fullscreen == true) {
            fullscreenMode()

        }
    }, [])

    return (
        <>
        <div className='back-to-top'>
        <ScrollButton />
      </div>
        <div className="page-wrapper" id="page-wrapper">
            <div className="page-titles pb-2 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">                        
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Task Category Mapping - Add</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <div>
                            <Tooltip title="" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                icon={<ArrowLeftOutlined />} size='default' onClick={previouspage}>
                                Back
                            </Button></Tooltip></div>

                        <div>
                            <button
                                onClick={(e) => {
                                    fullscreenMode();
                                }}
                                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                            >
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title="Full Screen Mode"
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "5px" }}>
                                <Card>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <label htmlFor=""><b>Country:</b></label>  <span style={{ "color": "red" }}>*</span>
                                            <Select
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Country"
                                                onChange={(data, value) => {
                                                    console.log(data, 'data')
                                                    setFilteredArr({
                                                        ...filteredArr,
                                                        countryId: data != undefined ? parseInt(data) : '',
                                                        domainId: '',
                                                        actId: ''
                                                    })
                                                    if (data == undefined) {
                                                        setDomainList([])
                                                        setActList([])
                                                    }

                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                            >
                                                {viewlist.countries && viewlist.countries.length > 0 && viewlist.countries.map((item, i) => {

                                                    return (
                                                        <Option key={item.country_id}>
                                                            {item.country_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'taskcategory',
                                                filteredArr.countryId,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Country is Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor=""><b>Domain:</b></label>
                                            <Select
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Domain"
                                                onChange={(data, value) => {
                                                    console.log(value, 'value')
                                                    setFilteredArr({
                                                        ...filteredArr,
                                                        domainId: data != undefined ? parseInt(data) : '',
                                                        actId: ''
                                                    })
                                                    if (data == undefined) {
                                                        setActList([])
                                                    }
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                                value={filteredArr.domainId || undefined}
                                            >
                                                {domainList && domainList.length > 0 && domainList.map((item, i) => {

                                                    return (
                                                        <Option key={item.domain_id} value={item.domain_id}>
                                                            {item.domain_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>

                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor=""><b>Act:</b></label>
                                            <Select
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Act"
                                                onChange={(data, value) => {
                                                    setFilteredArr({
                                                        ...filteredArr,
                                                        actId: parseInt(data)
                                                    })
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                                value={filteredArr.actId || undefined}
                                            >
                                                {actList && actList.length > 0 && actList.map((item, i) => {

                                                    return (
                                                        <Option key={item.act_id} value={item.act_id}>
                                                            {item.act_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>


                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{ marginTop: "15px", marginLeft: "20%", textAlign: 'center' }}>
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            onClick={getrecords} icon={<EyeOutlined />}>
                                            Show
                                        </Button>
                                    </div>
                                </Card>
                                {showAll == true ?
                                    <>
                                        <Card type="inner">
                                            <div className='col-md-3'>
                                                <div className='form-group has-feedback col-sm-12 no-padding' style={{ position: 'relative' }}>
                                                    <Input className="form-control input-sm" onChange={searchRecords} type="text" id="editsearch" placeholder="Compliance Task" maxlength="50" autocomplete="off" />
                                                    <i className="ri-search-line" style={{ position: 'absolute', top: '8px', right: '9px' }}></i>
                                                </div>
                                            </div>
                                        </Card>
                                        <div className='ant-table-wrapper'>
                                            {filteredArrayState && filteredArrayState.length > 0 ? filteredArrayState.map((item, i) => {
                                                return (
                                                    <div className='custom-collapse' key={i}>
                                                        <Collapse defaultActiveKey={['0']} key={i}>
                                                            <Panel header={item[0].domain_name} key={i}>
                                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                                    <div className='ant-table-container'>
                                                                        <div className='ant-table-content'>
                                                                            <table className='widthHeigh table-fixed'>
                                                                                <colgroup>
                                                                                    <col style={{ width: '10px' }}></col>
                                                                                </colgroup>
                                                                                <thead className='ant-table-thead'>
                                                                                    <tr>
                                                                                        <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Statutory Provision</th>
                                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Task</th>
                                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Description</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    <>


                                                                                        {
                                                                                            finalArray && finalArray.length > 0 ? finalArray.map((itemdata, i) => {
                                                                                                let data = _.find(itemdata.child, { domain_name: item[0].domain_name })
                                                                                                if (data != undefined) {
                                                                                                    if (data.act_name == itemdata.parent) {
                                                                                                        return <Fragment key={i}>
                                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                                <td colSpan={4} className='ant-table-cell'><input type="checkbox" onChange={(e) => {
                                                                                                                    let checked = e.target.checked;
                                                                                                                    downloadselectAllCheckBox(checked, itemdata.index)
                                                                                                                }} /> <b>{itemdata.parent}</b></td>
                                                                                                            </tr>
                                                                                                            {itemdata.child.map((items, j) => {
                                                                                                                return <Fragment key={j}>
                                                                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                                        <td className='ant-table-cell'><input type="checkbox" className='checked' name={'checked' + items.parentIndex} onChange={(e) => {
                                                                                                                            let checked = e.target.checked;
                                                                                                                            downloadcheckBoxOnChange(checked, items.childId)
                                                                                                                        }} />&nbsp;&nbsp;{items.index}</td>
                                                                                                                        <td title={items.statutory_provision} className='ant-table-cell ellipsis ant-table-cell-with-append'>
                                                                                                                            {items.statutory_provision}</td>
                                                                                                                        <td title={items.c_task_name} className='ant-table-cell ellipsis'>{items.c_task_name}</td>
                                                                                                                        <td title={items.description} className='ant-table-cell ellipsis'>{items.description}</td>

                                                                                                                    </tr>
                                                                                                                </Fragment>

                                                                                                            }
                                                                                                            )}


                                                                                                        </Fragment>
                                                                                                    }
                                                                                                }


                                                                                            }) : <tr>

                                                                                                <td colSpan={4} style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                                            </tr>
                                                                                        }
                                                                                    </>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                    </div>
                                                )
                                            }) : ''}
                                            <div className='row mt-3'>
                                                {updatedArray.length >= 100 && updatedArray.length <= totalRecords ?
                                                    <div className='col-md-4'>
                                                        <b>Showing 1 to {entires} of {totalRecords} entries</b>
                                                    </div>
                                                    : <div className='col-md-4'>
                                                        <b>Showing 1 to {totalRecords} of {totalRecords} entries</b>
                                                    </div>}
                                                {updatedArray.length >= 100 && updatedArray.length <= totalRecords ?
                                                    <div className='col-md-4'>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '40%' }}
                                                            size='default' icon={<EyeOutlined/>}
                                                            onClick={showMoreresult}
                                                        >Show More
                                                        </Button>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        <br />
                                        
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <label htmlFor=""><b>Task Category:</b></label>  <span style={{ "color": "red" }}>*</span>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Business Group"
                                                    value={taskCatSubCat.taskCat || undefined}
                                                    onChange={(data, value) => {
                                                        if (data == undefined) {
                                                            setTaskCatSubcat({
                                                                ...taskCatSubCat,
                                                                taskCat: '',
                                                                taskSubCat: ''
                                                            })
                                                            setTaskSubCatList([])
                                                        } else {
                                                            gettasksubcat(data)
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {taskCat && taskCat.length > 0 && taskCat.map((item, i) => {

                                                        return (
                                                            <Option key={item.task_group_id} value={item.task_group_id}>
                                                                {item.task_group_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator1.current.message(
                                                    'taskcategory',
                                                    taskCatSubCat.taskCat,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Task Category is required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-lg-6'>
                                                <label htmlFor=""><b>Task Subcategory:</b></label>  <span style={{ "color": "red" }}>*</span>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Business Group"
                                                    value={taskCatSubCat.taskSubCat || undefined}
                                                    onChange={(data, value) => {
                                                        if (data == undefined) {
                                                            setTaskCatSubcat({
                                                                ...taskCatSubCat,
                                                                taskSubCat: ''
                                                            })
                                                        } else {
                                                            setTaskCatSubcat({
                                                                ...taskCatSubCat,
                                                                taskSubCat: data
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {taskSubCatList && taskSubCatList.length > 0 && taskSubCatList.map((item, i) => {

                                                        return (
                                                            <Option key={item.task_sub_group_id} value={item.task_sub_group_id}>
                                                                {item.task_sub_group_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator1.current.message(
                                                    'taskcategory',
                                                    taskCatSubCat.taskSubCat,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Task Subcategory is required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='text-center p-4'>
                                                <Button type="primary" shape="round" className='addbutton' icon={<PlayCircleOutlined />}
                                                    size='default' onClick={submitrecord}> Submit
                                                </Button>
                                            </div>
                                        </div>

                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div ></>
    )
}
export default connect(mapStateToProps, {
    gettaskcategorymapping,
    savetaskcategorymapping,
    gettaskcategorymappingshowmore
})(Taskcategoryadd);