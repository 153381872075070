import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal, Pagination } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { lowerFirst, reject, update } from 'lodash';
import { FilterOutlined, SearchOutlined, CheckOutlined, PlayCircleOutlined, InfoCircleOutlined, PlusCircleOutlined, ExclamationCircleTwoTone, UserAddOutlined, CloseOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { getfiltersdata, filtersearch, approverejecttask, passworkverfy, viewupload } from '../../Store/Action/Transactions/AssignStatutoryBulkupload';
import URL from '../../Libs/URL'
import FeatherIcon from 'feather-icons-react';
import ScrollButton from "../Scrollbar";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";

const _ = require("lodash");
const mapStateToProps = (state) => ({
    listdata: state.Assignstutorybulk.assignstatutorybulkupload.viewdata,
    filtervalue: state.Assignstutorybulk.assignstatutorybulkupload.filterdata,
})
const ApproveAssignBulkuploadview = ({
    listdata,
    getfiltersdata,
    filtervalue,
    filtersearch,
    approverejecttask,
    passworkverfy,
    csvid,
    domain_name,
}) => {
    console.log(listdata, "listdata")
    console.log(filtervalue, "filtervalue")
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [FormSubmit1, setFormSubmit1] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [pageSize, setPageSize] = useState(250);
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [remarksmodal, setremarksmodal] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([])
    const [passwordmodal, setpasswordmodal] = useState(false);
    const [rejectselectedRows, setRejectSelectedRows] = useState([])
    const [tempvalue, setTempvalue] = useState([])
    const [check, setCheck] = useState([])
    const [checkstatus, setCheckstatus] = useState([])
    const [rejectcheck, setRejectcheck] = useState([])
    const [rejectcheckstatus, setRejectcheckstatus] = useState([])
    const [checkedrow, setCheckedRow] = useState(false);
    const [rejectcheckedrow, setRejectCheckedRow] = useState(false);
    const [filtersshow, setfiltersshow] = useState(false);
    const [exitModal, setExitModal] = useState(false);

    const [filterdata, setfilterdata] = useState({
        viewdata: '3',
        organization: '',
        nature: '',
        statutory: '',
        Frequency: [],
        location: '',
        task: '',
        document: '',
        descriptions: '',
        taskcode: ''
    })
    const [rejectdata, setrejectdata] = useState({
        id: '',
        remarks: '',
        csv_id: ''
    });
    const [password, setpassword] = useState({
        passwordvalue: ''
    });


    useEffect(() => {
        validator.current.showMessages();
        formvalidator.current.showMessages();
    }, []);

    useEffect(() => {
        if (listdata && listdata.assign_statutory_data_list) {
            let temp = []
            for (let i in listdata.assign_statutory_data_list) {
                temp.push(listdata.assign_statutory_data_list[i].bu_action)
            }
            setCheck(temp)
            setRejectcheck(temp)
        }
    }, [listdata && listdata.assign_statutory_data_list])

    useEffect(() => {
        if (check && check.length > 0) {
            let temp = []
            for (let i in check) {
                temp.push(check[i])
            }
            let unique = _.uniqBy(temp, function (e) {
                return e;
            });
            if (unique.length == 1) {
                if (unique[0] == 1) {
                    setCheckstatus(1)
                } else {
                    setCheckstatus(0)
                }
            } else {
                setCheckstatus(0)
            }
        }
    }, [check])

    useEffect(() => {
        if (rejectcheck && rejectcheck.length > 0) {
            let temp = []
            for (let i in rejectcheck) {
                temp.push(rejectcheck[i])
            }
            let unique = _.uniqBy(temp, function (e) {
                return e;
            });
            if (unique.length == 1) {
                if (unique[0] == 2) {
                    setRejectcheckstatus(2)
                } else {
                    setRejectcheckstatus(0)
                }
            } else {
                setRejectcheckstatus(0)
            }
        }
    }, [rejectcheck])

    const [uploadfilename, setuploadfilename] = useState();
    // useEffect(() => {
    //     if (listdata) {
    //         const filename = listdata.csv_name.split('_');
    //         const filename1 = filename[0] + filename[1];
    //         console.log(filename1,"filename11111")
    //         setuploadfilename(filename1);
    //     }
    // }, [listdata])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    const column = [
        {
            title: 'Actions',
            children: [
                {
                    title: '#',
                    dataIndex: false,
                    key: false,
                    ellipsis: true,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return index + 1
                    }
                },
                {
                    title: <span style={{ color: 'blue' }}>
                        <Tooltip title="Approve" color='#1890ff'>
                            <CheckOutlined />
                        </Tooltip>
                        <input type='checkbox'
                            checked={checkstatus == 1 ? true : false}
                            name="allchecked" onClick=
                            {(e) => {
                                let checked = e.target.checked
                                overallapprovelist(checked)
                            }} />
                    </span>,
                    dataIndex: false,
                    key: false,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return <Tooltip title="click here to approve">
                            <input type='checkbox'
                                checked={check && check.length > 0 && check[index] == 1 ? true : false}
                                name='approvelist'
                                onClick={
                                    (e) => {
                                        let checked = e.target.checked
                                        approvelist(record, index, checked)
                                    }} />
                        </Tooltip>
                    }
                },
                {
                    title: <span style={{ color: 'red' }}>
                        <Tooltip title="Reject" color='red'>
                            <CheckOutlined />
                        </Tooltip>
                        <input type='checkbox'
                            checked={rejectcheckstatus == 2 ? true : false}
                            name='allrejectlist' onClick=
                            {(e) => {
                                let checked = e.target.checked
                                overallrejectlist(checked)
                                // rejectlist(record, index, checked)
                            }} />
                    </span>,
                    dataIndex: false,
                    key: false,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return <div>
                            <Tooltip title="click here to reject">
                                <input type='checkbox'
                                    checked={rejectcheck && rejectcheck.length > 0 && rejectcheck[index] == 2 ? true : false}
                                    // checked={check && check.length > 0 && check[index] == 1 ? true : false}
                                    name='rejectlist' onClick=
                                    {(e) => {
                                        let checked = e.target.checked
                                        rejectlist(record, index, checked)
                                    }} /></Tooltip>&nbsp;&nbsp;&nbsp;&nbsp;
                            {rejectdata.remarks != '' ?
                                <Tooltip title={rejectdata.remarks}>
                                    <InfoCircleOutlined />
                                </Tooltip> : ''
                            }
                        </div>
                    }
                },
            ]
        },
        {
            title: 'Domain Name',
            dataIndex: 'd_name',
            key: 'd_name',
            ...getColumnSearchProps('d_name', 'Domain Name'),
            width: '140px',
            ellipsis: true,
        },
        {
            title: 'Unit Name',
            dataIndex: 'u_name',
            key: 'u_name',
            ...getColumnSearchProps('u_name', 'Unit Name'),
            ellipsis: true,
            align: 'center',
            width: '120px'
        },
        {
            title: 'Primary Legislation',
            dataIndex: 'p_leg',
            key: 'p_leg',
            ...getColumnSearchProps('p_leg', 'Primary Legislation'),
            ellipsis: true,
            width: '150px',
            align: 'center'
        },
        {
            title: <> <label title='Applicable'><CheckOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            align: 'center',
            render: (text, record) => {
                return <>{record.c_status == '1' ? <Tooltip title='Applicable'><FeatherIcon icon="check-circle" title='Applicable' style={{ color: 'blue' }} size="15" /></Tooltip> : <Tooltip title='Applicable'><FeatherIcon icon="check-circle" size={15} /></Tooltip>}</>
            }

        },
        {
            title: <> <label title='Not Applicable'><CloseOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            align: 'center',
            render: (text, record) => {
                return <>{record.s_status == '2' ? <Tooltip title='Not Applicable'><FeatherIcon icon="x-circle" size="15" /></Tooltip> : <Tooltip title='Not Applicable'><FeatherIcon icon="x-circle" size={15} /></Tooltip>}</>

            }
        },
        {
            title: <> <label title='Do Not Show'><MinusCircleOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            render: (text, record) => {
                return <>{record.s_status == '3' ? <Tooltip title='Do Not Show'><FeatherIcon icon="minus-circle" title='Applicable' size="15" /></Tooltip> : <Tooltip title='Do Not Show'><FeatherIcon icon="minus-circle" size={15} /></Tooltip>}</>


            }
        },
        {
            title: 'Statutory Remarks',
            dataIndex: 'c_desc',
            key: 'c_desc',
            ...getColumnSearchProps('c_desc', 'Statutory Remarks'),
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Secondary Legislation',
            dataIndex: 's_leg',
            key: 's_leg',
            ...getColumnSearchProps('s_leg', 'Secondary Legislation'),
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Statutory Provision',
            ellipsis: true,
            dataIndex: 's_prov',
            key: 's_prov',
            width: '150px',
            ...getColumnSearchProps('s_prov', 'Statutory Provision'),

        },
        {
            title: 'Task Code',
            dataIndex: 'task_code',
            key: 'task_code',
            ...getColumnSearchProps('task_code', 'Task Code'),
            ellipsis: true,
            width: '120px',
            align: 'center'
        },
        {
            title: 'Compliance Task Name',
            dataIndex: 'c_task',
            key: 'c_task',
            // ellipsis: true,
            width: '180px',
            // align: 'center',
            render: (text, record, index) => {
                return <div> <Tooltip title={record.org_names[0]}><ExclamationCircleTwoTone /></Tooltip>
                    {record.c_task}
                </div>
            }
        },
        {
            title: 'Compliance Description',
            dataIndex: 'c_desc',
            key: 'c_desc',
            // ellipsis: true,
            width: '400px'
        },
        {
            title: <> <label title='Applicable'><CheckOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            align: 'center',
            render: (text, record) => {
                return <>{record.s_status == '1' ? <Tooltip title='Applicable'><FeatherIcon icon="check-circle" title='Applicable' style={{ color: 'blue' }} size="15" /></Tooltip> : <Tooltip title='Applicable'><FeatherIcon icon="check-circle" size={15} /></Tooltip>}</>


            }

        },
        {
            title: <> <label title='Not Applicable'><CloseOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            align: 'center',
            render: (text, record) => {
                return <>{record.s_status == '2' ? <Tooltip title='Not Applicable'><FeatherIcon icon="x-circle" size="15" /></Tooltip> : <Tooltip title='Not Applicable'><FeatherIcon icon="x-circle" size={15} /></Tooltip>}</>


            }
        },
        {
            title: <> <label title='Do Not Show'><MinusCircleOutlined /></label> </>,
            dataIndex: 'false',
            key: 'false',
            ellipsis: true,
            width: '30px',
            align: 'center',
            render: (text, record) => {
                return <>{record.s_status == '3' ? <Tooltip title='Do Not Show'><FeatherIcon icon="minus-circle" title='Applicable' size="15" /></Tooltip> : <Tooltip title='Do Not Show'><FeatherIcon icon="minus-circle" size={15} /></Tooltip>}</>

            }
        },

    ];

    const filterclose = () => {
        setfiltersshow(false);
        setfilterdata({
            ...filterdata,
            viewdata: '3',
            organization: '',
            nature: '',
            statutory: '',
            Frequency: [],
            location: '',
            task: '',
            document: '',
            descriptions: ''
        })

        // const pay = {
        //     "session_token": authtoken,
        //     "request": [
        //         "GetAssignMappingFilter",
        //         {
        //             "csv_id": listdata.csv_id
        //         }
        //     ]
        // }

        // getfiltersdata({
        //     payload: pay,
        //     caller: paramid
        // })
    }

    const showfilter = () => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignStatutoryFilters",
                {
                    "csv_id": listdata.csv_id
                }
            ]
        }

        getfiltersdata({
            payload: pay,
            caller: paramid
        })
        setfiltersshow(true);
    }

    const searchfilter = () => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "ViewAssignStatutoryDataFromFilter",
                {
                    "csv_id": listdata.csv_id,
                    "f_count": 0,
                    "r_range": 250,
                    "filter_d_name": filterdata.organization ? parseInt(filterdata.organization) : null,
                    "filter_u_name": filterdata.nature ? parseInt(filterdata.nature) : null,
                    "filter_p_leg": filterdata.statutory ? parseInt(filterdata.statutory) : null,
                    "s_leg": filterdata.Frequency ? parseInt(filterdata.Frequency) : null,
                    "s_prov": filterdata.location ? parseInt(filterdata.location) : null,
                    "task_code": filterdata.taskcode ? parseInt(filterdata.taskcode) : null,
                    "c_task": filterdata.document ? parseInt(document.document) : null,
                    "c_desc": filterdata.descriptions ? parseInt(filterdata.descriptions) : null,
                    "filter_view_data": null,
                    "s_status": null,
                    "c_status": null
                }
            ]
        }

        filtersearch({
            payload: pay,
            caller: paramid
        })
        // setExitModal(true)
    }

    const overallapprovelist = (checked) => {
        let pay
        let temparr = []
        let temp = []
        let tempuncheck = []
        let temparruncheck = []
        let value = []
        var ele = document.getElementsByName('approvelist');
        if (checked == true) {
            setCheckstatus(1)
            for (let k in check) {
                value.push(1)
            }
            setCheck(value)
            setCheckedRow(true)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            temp = listdata && listdata.assign_statutory_data_list
            setSelectedRows([...temp])
            for (let j in temp) {
                temparr.push(temp[j].as_id)
            }
            pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparr,
                        "csv_id": listdata.csv_id,
                        "bu_action": 1,
                        "remarks": null
                    }
                ]
            }
        } else {
            for (let k in check) {
                value.push(0)
            }
            setCheck(value)
            setCheckstatus(0)
            setCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setSelectedRows([])
            tempuncheck = listdata && listdata.assign_statutory_data_list
            for (let j in tempuncheck) {
                temparruncheck.push(tempuncheck[j].as_id)
            }
            pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparruncheck,
                        "csv_id": listdata.csv_id,
                        "bu_action": 0,
                        "remarks": null
                    }
                ]
            }
        }
        approverejecttask({
            payload: pay,
            caller: paramid
        })
    }

    const approvelist = (rd, i, e) => {
        let pay;
        let temp = selectedRows
        let chek = []
        if (checkedrow == true) {
            if (e == true) {
                for (let k in check) {
                    if (k == i) {
                        chek.push(1)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                temp.push(rd)
                if (listdata && listdata.assign_statutory_data_list.length == temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setSelectedRows([...temp])
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 1,
                            "remarks": null
                        }
                    ]
                }
            } else {
                for (let i in temp) {
                    if (temp[i].as_id == rd.as_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in check) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                if (listdata && listdata.assign_statutory_data_list.length > temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setSelectedRows([...temp])
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": null
                        }
                    ]
                }
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        } else {
            let temp = selectedRows
            if (e == true) {
                for (let k in check) {
                    if (k == i) {
                        chek.push(1)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 1,
                            "remarks": null
                        }
                    ]
                }
                temp.push(rd)
                if (listdata && listdata.assign_statutory_data_list.length == temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setSelectedRows([...temp])
            } else {
                for (let k in check) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": null
                        }
                    ]
                }
                for (let i in temp) {
                    if (temp[i].as_id == rd.as_id) {
                        temp.splice(i, 1)
                    }
                }
                setSelectedRows([...temp])
                if (listdata && listdata.assign_statutory_data_list.length != temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        }
    }

    const overallrejectlist = (checked) => {
        let pay
        let temparr = []
        let temp = []
        let tempuncheck = []
        let temparruncheck = []
        let value = []
        var ele = document.getElementsByName('rejectlist');
        if (checked == true) {
            setRejectcheckstatus(2)
            for (let k in rejectcheck) {
                value.push(2)
            }
            setRejectcheck(value)
            setremarksmodal(true);
            setRejectCheckedRow(true)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            temp = listdata && listdata.assign_statutory_data_list
            setRejectSelectedRows([...temp])
            for (let j in temp) {
                temparr.push(temp[j].as_id)
            }
            setTempvalue([...temparr])
            setrejectdata({
                ...rejectdata,
                id: temparr,
                csv_id: listdata.csv_id,
            })
        } else {
            for (let k in rejectcheck) {
                value.push(0)
            }
            setRejectcheck(value)
            setRejectcheckstatus(0)
            setRejectCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setRejectSelectedRows([])
            tempuncheck = listdata && listdata.assign_statutory_data_list
            for (let j in tempuncheck) {
                temparruncheck.push(tempuncheck[j].as_id)
            }
            setTempvalue([...temparruncheck])
            const pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparruncheck,
                        "csv_id": listdata.csv_id,
                        "bu_action": 0,
                        "remarks": rejectdata.remarks
                    }
                ]
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        }
    }

    const rejectlist = (rd, i, e) => {
        let temp = rejectselectedRows
        let chek = []
        if (rejectcheckedrow == true) {
            if (e == true) {
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(2)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                temp.push(rd)
                setrejectdata({
                    ...rejectdata,
                    id: [rd.as_id],
                    csv_id: listdata.csv_id,
                })
                if (listdata && listdata.assign_statutory_data_list.length == temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setRejectSelectedRows([...temp])
                setremarksmodal(true);
            } else {
                for (let i in temp) {
                    if (temp[i].as_id == rd.as_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                if (listdata && listdata.assign_statutory_data_list.length != temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setRejectSelectedRows([...temp])
                const pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": rejectdata.remarks
                        }
                    ]
                }
                approverejecttask({
                    payload: pay,
                    caller: paramid
                })
            }
        } else {
            let temp = rejectselectedRows
            if (e == true) {
                setrejectdata({
                    ...rejectdata,
                    id: [rd.as_id],
                    csv_id: listdata.csv_id,
                })
                setremarksmodal(true);
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(2)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                temp.push(rd)
                if (listdata && listdata.assign_statutory_data_list.length == temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setRejectSelectedRows([...temp])
            } else {
                for (let i in temp) {
                    if (temp[i].as_id == rd.as_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                if (listdata && listdata.assign_statutory_data_list.length != temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setRejectSelectedRows([...temp])
                const pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.as_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": rejectdata.remarks
                        }
                    ]
                }
                approverejecttask({
                    payload: pay,
                    caller: paramid
                })
            }

        }
    }

    const rejecttask = () => {
        setAddFormSubmit1(true);
        if (validator.current.allValid()) {
            const pay =
            {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": rejectdata.id,
                        "csv_id": rejectdata.csv_id,
                        "bu_action": 2,
                        "remarks": rejectdata.remarks
                    }
                ]
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
            setremarksmodal(false);
        }
    }

    const reasoncancel = () => {
        setremarksmodal(false);
        setrejectdata({
            ...rejectdata,
            id: '',
            remarks: '',
            csv_id: ''

        })
        setAddFormSubmit1(false);
    }

    const submittask = () => {
        setpasswordmodal(true);
    }

    const passwordcancel = () => {
        setpasswordmodal(false);
        setFormSubmit1(false);
        setpassword({
            ...password,
            passwordvalue: ''
        })

    }
    const submitpassword = () => {
        setFormSubmit1(true);
        if (formvalidator.current.allValid()) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "AssignStatutoryValidate",
                    {
                        "csv_id": listdata.csv_id,
                        "password": password.passwordvalue
                    }
                ]
            }
            passworkverfy({
                payload: payload,
                caller: paramid
            })
            setpasswordmodal(false);
        }
    }

    useEffect(() => {
        if (pageState == true) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignStatutoryFilters",
                    {
                        "csv_id": csvid,
                        "f_count": 0,
                        "r_range": 250
                    }
                ]
            }
            viewupload({
                payload: pay,
                caller: paramid
            })
        }
    }, [current, pageSize, pageState])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <div className="page-wrapper" id="page-wrapper">
             <div className='back-to-top'>
                        <ScrollButton />
                    </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Approve / Reject Assigned Statutory - Bulk Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <div style={{ marginRight: '15px' }}>
                            <Tooltip title="Back"><Button type="primary" shape="round" className='addbutton'
                                size='default' onClick={() => {
                                    window.location.reload()
                                }} icon={<ArrowLeftOutlined />}
                                >
                                Back
                            </Button></Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]}>
                                <Panel header="Approve / Reject Statutory Mapping - Bulk Upload" key={1}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Client Group :&nbsp;</b></label>
                                                {listdata && listdata.cl_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :&nbsp;</b></label>
                                                {listdata && listdata.le_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Country :&nbsp;</b></label>
                                                {listdata && listdata.c_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <label><b>Uploaded On :&nbsp;</b></label>
                                                {listdata && listdata.uploaded_on}

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Uploaded By :&nbsp;</b></label>
                                                {listdata && listdata.c_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Uploaded File Name :&nbsp;</b></label>
                                                {listdata && listdata.csv_name}
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <div style={{ textAlign: 'right', 'margin-top': '15px' }}>
                                <Tooltip title="Filter" ><Button type="primary" shape="round" className='addbutton'
                                    icon={<FilterOutlined />} size='default' onClick={showfilter} >
                                    Filter
                                </Button></Tooltip>
                            </div>
                            <div className="col-lg-12" style={{ marginTop: '10px' }}>
                                <div className="row">
                                    <Table
                                        className='userprivclass'
                                        columns={column}
                                        dataSource={listdata && listdata.assign_statutory_data_list}
                                        bordered
                                        scroll={{ x: 1500 }}
                                        pagination={false}
                                    />
                                    {listdata && listdata.total && listdata.total != 0 ?
                                        <span>Showing 1 to {listdata && listdata.total} of {listdata && listdata.total} compliances | Show
                                            <Pagination
                                                style={{ "margin-top": "10px", float: " RIGHT" }}
                                                current={current}
                                                showSizeChanger={true}
                                                showQuickJumper={false}
                                                onShowSizeChange={() => {
                                                    setCurrent(1);
                                                }}
                                                pageSizeOptions={[250, 500, 700, 1000]}
                                                defaultPageSize={250}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true);
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes);
                                                }}
                                                total={listdata && listdata.total}
                                            /></span> : ''}
                                </div>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: "20px" }}>
                                <Button type="primary" shape="round" className='addbutton'
                                    size='default' onClick={submittask} icon={<PlayCircleOutlined />}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={filtersshow} maskClosable={false} className={"serviceProvider custom-color-modal "+ localStorage.getItem('currentTheme')} footer={null} title="Filter By"
                onCancel={filterclose} style={{ display: exitModal ? "none" : "block" }}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>View Data</b></label>
                                    <form
                                        value={filterdata.viewdata}
                                        onChange={(e) => {
                                            setfilterdata({
                                                ...filterdata,
                                                viewdata: e.target.value,
                                            });
                                        }}>
                                        <div class="radio radiofill">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                        filterdata.viewdata == 1
                                                    }
                                                />
                                                &nbsp;Verified
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                        filterdata.viewdata == 2
                                                    }
                                                />
                                                &nbsp;Pending
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="3"
                                                    name="status"
                                                    checked={
                                                        filterdata.viewdata == 3
                                                    }
                                                />
                                                &nbsp;All
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Domain Name :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Organization'
                                        placeholder="Enter Domain Name"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.organization || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                organization: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.d_names && filtervalue.d_names.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Unit Name :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Nature'
                                        placeholder="Enter Unit Name"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.nature || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                nature: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.u_names && filtervalue.u_names.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Primary Legislation :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Statutory'
                                        placeholder="Enter Primary Legislation"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.statutory || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                statutory: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.p_legis && filtervalue.p_legis.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Secondary Legislation :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Frequency'
                                        mode="multiple"
                                        placeholder="Enter Secondary Legislation"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.Frequency || undefined}
                                        onChange={(e) => {
                                            setfilterdata({
                                                ...filterdata,
                                                Frequency: e
                                            })
                                        }}>
                                        {filtervalue && filtervalue.s_legis && filtervalue.s_legis.map((item, i) => {
                                            return <Option value={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Statutory Provision :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Location'
                                        placeholder="Enter Statutory Provision"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.location || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                location: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.s_provs && filtervalue.s_provs.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Task Code&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Task'
                                        placeholder="Enter Compliance Task Code"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.task || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                taskcode: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.t_code && filtervalue.t_code.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Task Name :&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Document'
                                        placeholder="Enter Compliance Task Name"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.document || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                document: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.c_tasks && filtervalue.c_tasks.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Description:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Description'
                                        placeholder="Enter Compliance Description"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.descriptions || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                descriptions: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.c_descs && filtervalue.c_descs.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Status:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        name='Document'
                                        placeholder="Enter Compliance Status"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.document || undefined}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                document: value.value
                                            })
                                        }}>
                                        <Option key='0'>All</Option>
                                        <Option key='1'>Applicable</Option>
                                        <Option key='2'>Not Applicable</Option>
                                        <Option key='3'>Do Not Show</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6" style={{ marginTop: '20px', textAlign: 'right' }}>
                                <div className="mb-2">
                                    <Tooltip title="Search" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                        icon={<SearchOutlined />} size='default' onClick={searchfilter} >
                                        Search
                                    </Button></Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={remarksmodal} className={"newStyleModalPassword custom-color-modal "+ localStorage.getItem('currentTheme')} title="Enter Reason" footer={null} onCancel={reasoncancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Reason</b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="text"
                                id="reason"
                                name='reason'
                                autoComplete={'off'}
                                placeholder="Enter Reason"
                                style={{ marginTop: "5px", width: '100%' }}
                                className="form-control"
                                onChange={(e) => {
                                    setrejectdata({
                                        ...rejectdata,
                                        remarks: e.target.value
                                    })
                                }}
                                value={rejectdata.remarks}
                            />
                            {validator.current.message(
                                'reason',
                                rejectdata.remarks,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Reason Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={() => { rejecttask() }}>
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal visible={passwordmodal} className={"newStyleModalPassword custom-color-modal "+ localStorage.getItem('currentTheme')} title="Enter Password" footer={null} onCancel={passwordcancel} >
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password:</b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                autoComplete={'off'}
                                placeholder="Enter password"
                                style={{ marginTop: "5px", width: '100%' }}
                                className="form-control"
                                onChange={(e) => {
                                    setpassword({
                                        ...password,
                                        passwordvalue: e.target.value
                                    })
                                }}
                                value={password.passwordvalue}
                            />
                            {formvalidator.current.message(
                                'Password',
                                password.passwordvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${FormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                        style={{ marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={submitpassword}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
export default connect(mapStateToProps, {
    getfiltersdata,
    filtersearch,
    approverejecttask,
    passworkverfy
})(ApproveAssignBulkuploadview);