import Swal from 'sweetalert2';


export default class alertmessage{
   static sweetalert(){
        Swal.fire({
            title: "User Session Expired..!",
            text: "Re-Login Again",
            type: "danger"
        }).then(function () {
            localStorage.clear();
            window.location.reload(true);
        });
    }
}
