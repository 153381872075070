import { combineReducers } from 'redux';
import Login from './Login';
import Rejectedsummary from './Transactions/Rejectedsummary';
import Taskduedate from './Transactions/Taskduedate'
import Taskcategory from './Transactions/Taskcategory'
import StatutoryNotificationList from './Reports/StatutoryNotificationList';
import StatutoryMapping_BulkUploadReport from './Reports/StatutoryMapping_BulkUploadReport';
import StatutoryMappingReport from './Reports/StatutoryMappingReport';
import Statutorymapping from './Transactions/Statutorymapping';
import MappingBulkupload from './Transactions/MappingBulkupload';
import Profile from './Profile'
import Approvetaskcategorymapping from './Transactions/Approvetaskcategorymapping';
import ApproveStatutoryMapping from './Transactions/ApproveStatutoryMapping';
import ApproveStatotoryBulkupload from './Transactions/Approve_stat_bulk'
import Assignclientunit from './Transactions/Assignclientunit';
import Assignstutorybulk from './Transactions/AssignStatutoryBulkupload'
import UserMappingReport from './Reports/UserMappingReport';
import Approveassignedstatutory from './Transactions/Approveassignedstatutory'
import Clientunitdetails from './Reports/clientunitdetails'
import ClientAggrementMasterReport from './Reports/ClientAggrementMasterReport';
import DomainWiseAgreementMasterReport from './Reports/DomainWiseAgreementMasterReport'
import StatutorySettingsRep from './Reports/StatutorySettingsReport';
import Assinged_sta_bulkReport from './Reports/Assign_statutorybulk'
import Assign_sta_bulkupload from './Transactions/Assign_statutory_bulk_upload'
import RejectedAssignStatutory_bulkupload from './Transactions/RejectedAssignStatutory_bulkupload'
import DE_Assignstatutory from './Transactions/DE_Assignstatutory';
import TE_ClientUnit from './Transactions/TE_ClientUnit';
import LegalEntityClosure from './Transactions/LegalEntityClosure';
import GroupadminRegisEmail from './Transactions/GroupadminRegisEmail';
import AssignLegalEntity from './Transactions/AssignLegalEntity';
import ClientGroup from './Transactions/ClientGroup';
import Rejectedclientunit from './Transactions/TE_RejectClientunitBulkUpload';
import Client_unit_bulkupload from './Transactions/TE_Clientunitbulkupload';
import UnitCodeLabel from './Transactions/UnitCodeLabel';
import ApproveClientUnit_Vendor_BulkUpload from './Transactions/ApproveClientUnit_Vendor_BulkUpload';
import ApproveComplianceBasedASBU from './Transactions/ApproveComplianceBasedAssignStatutory_BU';


const appReducer = combineReducers({
    Login,
    Rejectedsummary,
    Taskduedate,
    Taskcategory,
    StatutoryNotificationList,
    Statutorymapping,
    StatutoryMapping_BulkUploadReport,
    StatutoryMappingReport,
    Profile,
    MappingBulkupload,
    Approvetaskcategorymapping,
    ApproveStatutoryMapping,
    ApproveStatotoryBulkupload,
    Assignclientunit,
    Assignstutorybulk,
    UserMappingReport,
    AssignLegalEntity,
    Approveassignedstatutory,
    Clientunitdetails,
    ClientAggrementMasterReport,
    DomainWiseAgreementMasterReport,
    StatutorySettingsRep,
    Assinged_sta_bulkReport,
    Assign_sta_bulkupload,
    RejectedAssignStatutory_bulkupload,
    DE_Assignstatutory,
    ClientGroup,
    TE_ClientUnit,
    LegalEntityClosure,
    Rejectedclientunit,
    Client_unit_bulkupload,
    GroupadminRegisEmail,
    UnitCodeLabel,
    ApproveClientUnit_Vendor_BulkUpload,
    ApproveComplianceBasedASBU
});
export default (state, action) => {
    return appReducer(state, action);
};