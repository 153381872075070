import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { DownloadOutlined, EditOutlined, DislikeOutlined, LikeOutlined, PlayCircleOutlined, SearchOutlined, ConsoleSqlOutlined, FilterOutlined, FullscreenOutlined, EyeOutlined } from "@ant-design/icons";
import { assignlist, assignsearchlist, assigndomain_exe, approveassigntask, rejectassigntask, viewupload, viewupload1 } from '../../Store/Action/Transactions/AssignStatutoryBulkupload'
import URL from '../../Libs/URL'
import Swal from 'sweetalert2';
import ApproveAssignBulkuploadview from './Approve_assignbulk_view'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import { Toaster } from '../../Libs/Toaster';


const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.Assignstutorybulk.assignstatutorybulkupload.list,
    pendinglist: state.Assignstutorybulk.assignstatutorybulkupload.pendinglist,
    domainlist: state.Assignstutorybulk.assignstatutorybulkupload.domainlist,
})
const ApproveAssignBulkupload = ({
    assignlist,
    list,
    assignsearchlist,
    assigndomain_exe,
    pendinglist,
    domainlist,
    approveassigntask,
    rejectassigntask,
    viewupload,
    viewupload1

}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const { Panel } = Collapse;
    const { Option } = Select;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [approveform, setapproveform] = useState(false);
    const [rejectform, setrejectform] = useState(false);
    const [showlist, setshowlist] = useState(false);
    const [searchdata, setsearchdata] = useState({
        group: '',
        legalid: '',
        legaldata: '',
        product: '',
        productdata: ''
    });
    console.log(searchdata, 'searchdata')
    const [password, setpassword] = useState({
        passwordvalue: ''
    });
    const [remarks, setremarks] = useState({
        remarksvalue: '',
        password: ''
    })

    const [currentpage, setcurrentpage] = useState('list');
    const [csvid, setcsvid] = useState();
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    useEffect(() => {
        formValidator.current.showMessages();
        formValidator1.current.showMessages();
        formValidator2.current.showMessages();
    }, []);

    const searchlist = () => {
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignStatutoryForApprove",
                    {
                        "cl_id": parseInt(searchdata.group),
                        "le_id": parseInt(searchdata.legalid),
                        "product_id": parseInt(searchdata.product)
                    }
                ]
            }

            const pay2 = {
                "session_token": authtoken,
                "request": [
                    "GetDomainExecutiveDetails",
                    {}
                ]
            }

            assignsearchlist({
                payload: pay,
                caller: paramid
            })
            assigndomain_exe({
                payload: pay2,
                caller: paramid
            })
            setshowlist(true)
        }

    }

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientInfo",
                    {}
                ]
            }
            assignlist({
                payload: pay,
                caller: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        if (searchdata.group) {
            const legal = _.filter(list.bu_legalentites, { cl_id: parseInt(searchdata.group) })
            setsearchdata({
                ...searchdata,
                legaldata: legal
            })
        }
    }, [searchdata.group])

    useEffect(() => {
        if (searchdata.legalid) {
            const legal = _.filter(list.bu_legalentites, { cl_id: parseInt(searchdata.group), le_id: parseInt(searchdata.legalid) })
            let finalArrayProduct = legal[0].bu_domains.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.product === value.product
                ))
            )
            let finalProduct = []
            for (let k = 0; k < finalArrayProduct.length; k++) {
                finalProduct.push({
                    'key': finalArrayProduct[k].product == 'Compfie' ? '1' : '3',
                    'value': finalArrayProduct[k].product
                })
            }
            setsearchdata({
                ...searchdata,
                productdata: finalProduct
            })
        }
    }, [searchdata.legalid])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [icondisplay, seticondisplay] = useState(0);
    const [approvedata, setapprovedata] = useState();



    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    // const getColumnSearchProps = (dataIndex, placeholder) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={placeholder}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: 'block',
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     Search
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters)}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     Reset
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? '#1890ff' : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) =>
    //         record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) => text
    // });

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const showview = (record) => {
        console.log(record, 'record');
        const pay = {
            "session_token": authtoken,
            "request": [
                "ViewAssignStatutoryDataFromFilter",
                {
                    "csv_id": record.csv_id,
                    "f_count": 0,
                    "r_range": 250,
                    "filter_d_name": null,
                    "filter_u_name": null,
                    "filter_p_leg": null,
                    "s_leg": null,
                    "s_prov": null,
                    "task_code": null,
                    "c_task": null,
                    "c_desc": null,
                    "filter_view_data": null,
                    "s_status": null,
                    "c_status": null
                }
            ]
        }
        viewupload({
            payload: pay,
            caller: paramid
        })
        const pay1 =
        {
            "session_token": authtoken,
            "request": [
                "GetAssignStatutoryFilters",
                {
                    "csv_id": record.csv_id
                }
            ]
        }
        viewupload1({
            payload: pay1,
            caller: paramid
        })

        setcsvid(record.csv_id);
        setcurrentpage('view');
    }



    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '30px',
            render: (text, record, index) => {
                return <> {index + 1} </>
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            width: '150px',
            key: 'csv_name',
            ...getColumnSearchProps('csv_name', 'Uploaded File Name'),
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            width: '80px',
            ...getColumnSearchProps('uploaded_on', 'Uploaded On'),
            key: 'uploaded_on',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: '80px',
            ...getColumnSearchProps('uploaded_by', 'Uploaded By'),
            align: 'center',
            render: (record) => {
                return (
                    <span>{domainlist && domainlist.domain_executive_info &&
                        domainlist.domain_executive_info.length > 0 && domainlist.domain_executive_info[0].emp_code_name}</span>
                )
            }
        },
        {
            title: 'No. of records',
            dataIndex: 'no_of_records',
            width: '80px',
            key: 'no_of_records',
            ...getColumnSearchProps('no_of_records', 'No Of Records'),
            align: 'center',

        },
        {
            title: 'Approved / Rejected',
            dataIndex: 'approved_count',
            key: 'approved_count',
            width: '60px',
            align: 'center',
            render: (text, record) => {
                return <span>{record.approved_count}/{record.rej_count}</span>

            },
        },
        {
            title: 'Download',
            align: 'center',
            width: '55px',
            render: (text, record, index) => {
                return (
                    <>
                        <span
                            className="btn btn-sm btn-light-primary text-primary btn-circle d-flex align-items-center 
                          justify-content-center" style={{ marginLeft: '28px' }}
                            onClick={() => {
                                if (icondisplay == 0) {
                                    var id = document.getElementById('download_' + index).style.display = 'block'
                                    seticondisplay(1)
                                } else {
                                    var id = document.getElementById('download_' + index).style.display = 'none'
                                    seticondisplay(0)
                                }

                            }}><Tooltip title='Click here to download'><DownloadOutlined /></Tooltip></span>

                        <span id={'download_' + index} style={{ display: 'none' }}>
                            <div>
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('excel', record.download_file) }}>Download Excel/</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('csv', record.download_file) }}>Download CSV/</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('ods', record.download_file) }}>Download ODS/</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('text', record.download_file) }}>Download Text</span>
                            </div>
                        </span>
                    </>
                );
            }
        },
        {
            title: <label title='Task Wise Action'>Action</label>,
            dataIndex: 'c_by',
            key: 'c_by',
            width: '70px',
            align: 'center',           
            render: (text, record) => {
                if (record.approve_count >= 1) {
                    return <span><Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to edit"}>
                        <EditOutlined onClick={() => { showview(record) }} />
                    </Tooltip></span>
                } else {
                    if (record.rej_count >= 1) {
                        return <span><Tooltip
                            className="toolTipClass"
                            placement="topRight"
                            title={"click here to edit"}>
                            <EditOutlined onClick={() => { showview(record) }} />
                        </Tooltip></span>
                    } else {
                        // return 
                        // <Tooltip className="toolTipClass" placement="topRight" title={"click here to view"}>
                        return <Button
                            type="primary"
                            shape="round"
                            className='addbutton' onClick={() => { showview(record); }}>
                            View
                        </Button>
                        // </Tooltip>
                    }
                }
            },
        },
        {
            title: 'Approve',
            align: 'center',
            width: "50px",
            render: (text, record) => {
                return <span className="btn btn-sm btn-light-success
                text-success btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '28px' }} onClick={() => {
                        setapproveform(true);
                        setapprovedata(record);
                    }}>
                    <Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to approve"}>
                        <LikeOutlined />
                    </Tooltip>
                </span>
            },
        },
        {
            title: 'Reject',
            width: "40px",
            align: 'center',
            render: (text, record) => {
                return <span className="btn btn-sm btn-light-danger
                text-danger btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '20px' }} onClick={() => {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Alert messsage!',
                            text: 'This Option will reject all the tasks. To reject, selected task you may choose"View Option". Do you want to proceed',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setrejectform(true);
                                setapprovedata(record);
                            }
                        })
                    }}>
                    <Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to reject"}>
                        <DislikeOutlined />
                    </Tooltip></span>
            },
        },
    ];

    const downloadfile = (e, id) => {
        const expression = id.split('.')
        let site;
        if (e == 'csv') {
            window.open(URL.CommonURL + '/uploaded_file/csv/' + id);
        } else if (e == 'excel') {
            window.open(URL.CommonURL + '/uploaded_file/xlsx/' + expression[0] + '.' + 'xlsx');
        } else if (e == 'ods') {
            window.open(URL.CommonURL + '/uploaded_file/ods/' + expression[0] + '.' + 'ods');
        } else {
            window.open(URL.CommonURL + '/uploaded_file/txt/' + expression[0] + '.' + 'txt', '');
        }

    }

    const passwordcancel = () => {
        setapproveform(false);
        setpassword({
            ...password,
            passwordvalue: ''
        });
        setAddFormSubmit1(false);
    }

    const rejectcancel = () => {
        setrejectform(false);
        setremarks({
            ...remarks,
            password: '',
            remarksvalue: ''
        })
        setAddFormSubmit2(false);
    }

    const submitpassword = (e) => {
        e.preventDefault();
        setAddFormSubmit1(true);
        if (formValidator1.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "AssignStatutoryValidate",
                    {
                        "csv_id": approvedata.csv_id,
                        "password": password.passwordvalue
                    }
                ]
            }
            approveassigntask({
                payload: pay,
                caller: paramid
            })
            setpassword({
                passwordvalue: ''
            })
            setAddFormSubmit1(false);
        }
    }

    const addremarks = (e) => {
        e.preventDefault();
        setAddFormSubmit2(true);
        if (formValidator2.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "AssignStatutoryValidate",
                    {
                        "csv_id": approvedata.csv_id,
                        "password": remarks.password
                    }
                ]
            }
            rejectassigntask({
                payload: pay,
                caller: paramid
            })
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    // Full Screen Mode
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    return (
        <>
            {currentpage == 'list' ? (
                <div id="page-wrapper" className="page-wrapper">
                    <div className="page-titles py-1 page-title-sticky">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link>Transactions</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <Link><b>Approve / Reject Assigned Statutory - Bulk Upload</b></Link>
                                    </li>
                                </ol>
                            </nav> */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transactions</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Approve - Reject Assigned Statutory - Bulk Upload</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end"
                            >




                                <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                        marginLeft: "5px",
                                        display: filterTaskAcc ? "none" : "inline",
                                    }}
                                    onClick={filterHandle}
                                >
                                    <FilterOutlined /> Filter
                                </Button>
                                <button
                                    onClick={(e) => {
                                        fullscreenMode();
                                    }}
                                    className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                                >
                                    <Tooltip
                                        placement="left"
                                        style={{ marginRight: "10px", }}
                                        title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                    >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body custom-collapse" style={{ padding: "1px" }}>
                                    <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                        <Panel header="Approve / Reject Assigned Statutory" key={1}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Client Group:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='clientgroup'
                                                                placeholder="Enter Client Group"
                                                                showSearch
                                                                value={searchdata.group || undefined}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    if(data != undefined){
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            group: value.value,
                                                                            legaldata: '',
                                                                            legalid: '',
                                                                            product: ''
                                                                        })
                                                                    }else{
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            group: '',
                                                                            legaldata: '',
                                                                            legalid: '',
                                                                            product: ''
                                                                        })
                                                                    }
                                                                   
                                                                }}>
                                                                {list && list.bu_clients && list.bu_clients.map((item, i) => {
                                                                    return <Option key={item.cl_id}>{item.cl_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'clientgroup',
                                                                searchdata.group,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Client Group Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='legalentity'
                                                                placeholder="Enter Legal Entity"
                                                                showSearch
                                                                value={searchdata.legalid || undefined}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    if(data != undefined){
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            legalid: value.value,
                                                                            product: "",
                                                                            productdata: ''
                                                                        })
                                                                    }else{
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            legalid: '',
                                                                            product: "",
                                                                            productdata: ''
                                                                        })
                                                                    }
                                                                  
                                                                }}>
                                                                {searchdata && searchdata.legaldata && searchdata.legaldata.map((item, i) => {
                                                                    return <Option key={item.le_id}>{item.le_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'legalentity',
                                                                searchdata.legalid,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Product:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='Product'
                                                                placeholder="None selected"
                                                                showSearch
                                                                value={searchdata.product || undefined}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    if(data != undefined){
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            product: value.value
                                                                        })
                                                                    }else{
                                                                        setsearchdata({
                                                                            ...searchdata,
                                                                            product: ''
                                                                        })
                                                                    }
                                                                   
                                                                }}>
                                                                {searchdata && searchdata.productdata && searchdata.productdata.map((item, i) => {
                                                                    return <Option key={item.key}>{item.value}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'Product',
                                                                searchdata.product,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Product Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            className='addbutton'
                                                            style={{
                                                                marginTop: "20px"
                                                            }}
                                                            icon={<EyeOutlined />}
                                                            onClick={searchlist}>
                                                            Show
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Panel>
                                    </Collapse>
                                    {showlist == true ?
                                        <>
                                            <div className="pt-1 service-provider-table1">
                                                <Table
                                                    columns={columns}
                                                    dataSource={pendinglist && pendinglist.pending_csv_list_as}
                                                    bordered
                                                    pagination={false}
                                                />
                                            </div>
                                            <Modal visible={approveform} className={"newStyleModalPassword add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} title="Enter Password" footer={null} onCancel={passwordcancel} >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-6 py-2">
                                                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                                                            <Input
                                                                type="password"
                                                                id='passwordvalue'
                                                                name='passwordvalue'
                                                                autoComplete={'off'}
                                                                maxLength={20}
                                                                placeholder="Password"
                                                                style={{ marginTop: "5px", width: '100%' }}
                                                                className="form-control"
                                                                onKeyUp={(e) => {
                                                                    let len = e.target.value;
                                                                    if (len.length == 20) {
                                                                        Toaster.error('Password should not exceed 20 characters')
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setpassword({
                                                                        ...password,
                                                                        passwordvalue: e.target.value
                                                                    })
                                                                }}
                                                                value={password.passwordvalue}
                                                            />
                                                            {formValidator1.current.message(
                                                                'passwordvalue',
                                                                password.passwordvalue,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Password Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="form-actions">
                                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                        style={{ marginLeft: '33%' }}
                                                        icon={<PlayCircleOutlined />} size='default' onClick={submitpassword}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Modal>

                                            <Modal visible={rejectform} className={'usr_modal_class add-service-prv custom-color-modal ' + localStorage.getItem('currentTheme')} footer={null} title="Enter Password"
                                                onCancel={rejectcancel} destroyOnClose={true} >
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                                                        <input
                                                            type="password"
                                                            id="passwordvalue"
                                                            name='passwordvalue'
                                                            className="form-control"
                                                            placeholder="Password"
                                                            maxLength={20}
                                                            value={remarks.password}
                                                            onKeyUp={(e) => {
                                                                let len = e.target.value;
                                                                if (len.length == 20) {
                                                                    Toaster.error('Password should not exceed 20 characters')
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setremarks({
                                                                    ...remarks,
                                                                    password: e.target.value
                                                                })
                                                            }} />
                                                        {formValidator2.current.message(
                                                            'passwordvalue',
                                                            remarks.password,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Password Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>
                                                    <textarea className='form-control' name='remark'
                                                        row='1' cols='4' style={{ resize: 'vertical' }}
                                                        placeholder="Enter Reason"
                                                        value={remarks.remarksvalue}
                                                        onChange={(e) => {
                                                            setremarks({
                                                                ...remarks,
                                                                remarksvalue: e.target.value
                                                            })
                                                        }}>
                                                    </textarea>
                                                    {formValidator2.current.message(
                                                        'remark',
                                                        remarks.remarksvalue,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Reason Required'
                                                            }
                                                        })}
                                                </div>
                                                <div className="form-actions pt-2">
                                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                        style={{ marginLeft: '33%' }}
                                                        icon={<PlayCircleOutlined />} size='default' onClick={addremarks}

                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Modal>
                                        </>
                                        : ""}
                                    <Modal
                                        title="Approve / Reject Assigned Statutory"
                                        className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                        footer={false}
                                        visible={filterModalVisible}
                                        onCancel={setcancelFilter}
                                        maskClosable={false}
                                    >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Client Group:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            name='clientgroup'
                                                            placeholder="Enter ClientGroup"
                                                            showSearch
                                                            value={searchdata.group || undefined}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                if(data != undefined){
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        group: value.value,
                                                                        legaldata: '',
                                                                        legalid: '',
                                                                        product: ''
                                                                    })
                                                                }else{
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        group: '',
                                                                        legaldata: '',
                                                                        legalid: '',
                                                                        product: ''
                                                                    })
                                                                }
                                                            }}>
                                                            {list && list.bu_clients && list.bu_clients.map((item, i) => {
                                                                return <Option key={item.cl_id}>{item.cl_name}</Option>
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'clientgroup',
                                                            searchdata.group,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Client Group Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                             getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            name='legalentity'
                                                            placeholder="Enter LegalEntity"
                                                            showSearch
                                                            value={searchdata.legalid || undefined}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                if(data != undefined){
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        legalid: value.value,
                                                                        product: "",
                                                                        productdata: ''
                                                                    })
                                                                }else{
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        legalid: '',
                                                                        product: "",
                                                                        productdata: ''
                                                                    })
                                                                }
                                                            }}>
                                                            {searchdata && searchdata.legaldata && searchdata.legaldata.map((item, i) => {
                                                                return <Option key={item.le_id}>{item.le_name}</Option>
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legalentity',
                                                            searchdata.legalid,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Name Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Product:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            name='Product'
                                                            placeholder="None selected"
                                                            showSearch
                                                            value={searchdata.product || undefined}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                if(data != undefined){
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        product: value.value
                                                                    })
                                                                }else{
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        product: ''
                                                                    })
                                                                }
                                                            }}>
                                                            {searchdata && searchdata.productdata && searchdata.productdata.map((item, i) => {
                                                                return <Option key={item.key}>{item.value}</Option>
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'Product',
                                                            searchdata.product,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Product Name Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton custom-color-modal-button'
                                                        style={{
                                                            marginTop: "20px"
                                                        }}
                                                        onClick={searchlist}>
                                                        Show
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <ApproveAssignBulkuploadview csvid={csvid} />}
        </>
    )
}
export default connect(mapStateToProps, {
    assignlist,
    assignsearchlist,
    assigndomain_exe,
    approveassigntask,
    rejectassigntask,
    viewupload,
    viewupload1
})(ApproveAssignBulkupload);