import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Modal } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { exportfiledetails, Filterdata, legalentitydata, unitdetails } from '../../Store/Action/Reports/Clientunitdetails'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";

const mapStateToProps = (state) => ({
    filterdatavalue: state.Clientunitdetails.clientunitdetails.filter,
    legalentityfilterdata: state.Clientunitdetails.clientunitdetails.legelentitydata,
    unitdata: state.Clientunitdetails.clientunitdetails.unitdata
})
const ClientUnitDetailsReport = ({
    Filterdata,
    filterdatavalue,
    legalentitydata,
    legalentityfilterdata,
    unitdetails,
    exportfiledetails,
    unitdata
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const [displayresult, setresultdisplay] = useState('none');
    const [filterModalVisible, setfilterModalVisible] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [fullscreen, setfullscreen] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [exitCollapse, setExitCollapse] = useState(false);
    const [key, setKey] = useState("1")
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [showfiltericon, Setshowfiltericon] = useState('none');

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);
    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    const [filtervalue, setfiltervalue] = useState({
        country: '',
        groupname: '',
        groupdata: '',
        legal: '',
        legaldata: "",
        domain: '',
        domaindata: '',
        business: '',
        businessdata: '',
        unit: '',
        unitdata: '',
        type: '',
        typedata: '',
        fromdata: '',
        todata: '',
        status: '',
        countrylabel: '',
        grouplabel: '',
        legallabel: '',
        businesslabel: ''
    })


    useEffect(() => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetClientDetailsReportFilters",
                {}
            ]
        }
        Filterdata({
            payload: pay,
            paramid: paramid
        })
    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const showrecord = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            let groupid = filtervalue.business ? parseInt(filtervalue.business) : '%'
            let unitid = filtervalue.unit ? parseInt(filtervalue.unit) : '%'
            let domain = filtervalue.domain ? parseInt(filtervalue.domain) : '%'
            let org_type = filtervalue.type ? parseInt(filtervalue.type) : '%'
            let status = filtervalue.status ? parseInt(filtervalue.status) : '%'
            let fromdate = filtervalue.fromdata ? parseInt(filtervalue.fromdata) : '%'
            let todate = filtervalue.todata ? parseInt(filtervalue.todata) : '%'

            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientDetailsReportData",
                    {
                        "country_id": parseInt(filtervalue.country),
                        "client_id": parseInt(filtervalue.groupname),
                        "legal_entity_id": parseInt(filtervalue.legal),
                        "u_m_none": groupid + ',' + unitid + ',' + domain + ',' + org_type + ',' + fromdate + ',' + todate + ',' + status
                    }
                ]
            }

            unitdetails({
                payload: pay,
                paramid: paramid
            })
            setExitCollapse(true);
            setfilterModalVisible(false);
            setresultdisplay('block');
            Setshowfiltericon('block');
        }
    }

    useEffect(() => {
        if (filtervalue.country != '') {
            const groupname = _.filter(filterdatavalue.statutory_groups, { country_id: parseInt(filtervalue.country) })
            setfiltervalue({
                ...filtervalue,
                groupdata: groupname
            })
        }
    }, [filtervalue.country])

    useEffect(() => {
        if (filtervalue.groupname != '') {
            const legalentity = _.filter(filterdatavalue.statutory_business_groups, { client_id: parseInt(filtervalue.groupname) })
            var filtered = legalentity.filter(function (el) {
                return el.business_group_name != null;
            });
            setfiltervalue({
                ...filtervalue,
                legaldata: legalentity,
                businessdata: filtered
            })
        }
    }, [filtervalue.groupname])

    useEffect(() => {
        if (filtervalue.business != '') {
            const legalentity = _.filter(filterdatavalue.statutory_business_groups, { business_group_id: parseInt(filtervalue.business) })
            setfiltervalue({
                ...filtervalue,
                legal: '',
                legaldata: legalentity,
            })
        }
    }, [filtervalue.business])

    const selectedlegalentity = (data) => {
        console.log(data)
        if (data == undefined) {
            setfiltervalue({
                ...filtervalue,
                legal: '',
                legallabel: '',
                unit: "",
                domain: '',
                type: '',
                fromdata: '',
                todata: '',
                status: '',
                business: '',
                businesslabel: '',
            })
        } else {
            setfiltervalue({
                ...filtervalue,
                legal: data.value,
                legallabel: data.children,
                unit: "",
                domain: '',
                type: '',
                fromdata: '',
                todata: '',
                status: '',
                business: '',
                businesslabel: '',
            })
        }
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetClientUnitLegalEntityDetails",
                {
                    "legal_entity_id": parseInt(data.value)
                }
            ]
        }
        legalentitydata({
            payload: pay,
            paramid: paramid
        })
    }

    useEffect(() => {
        if (legalentityfilterdata) {
            const domain = _.filter(legalentityfilterdata.domains_organization_list, { legal_entity_id: parseInt(filtervalue.legal) })


            setfiltervalue({
                ...filtervalue,
                unitdata: legalentityfilterdata.units_report,
                domaindata: domain,
                typedata: domain
            })
        }
    }, [legalentityfilterdata])

    const exportfile = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            let groupid = filtervalue.business ? parseInt(filtervalue.business) : '%'
            let unitid = filtervalue.unit ? parseInt(filtervalue.unit) : '%'
            let domain = filtervalue.domain ? parseInt(filtervalue.domain) : '%'
            let org_type = filtervalue.type ? parseInt(filtervalue.type) : '%'
            let status = filtervalue.status ? parseInt(filtervalue.status) : '%'
            let fromdate = filtervalue.fromdata ? parseInt(filtervalue.fromdata) : '%'
            let todate = filtervalue.todata ? parseInt(filtervalue.todata) : '%'
            const pay = {
                "session_token": authtoken,
                "request": [
                    "ExportClientDetailsReportData",
                    {
                        "country_id": parseInt(filtervalue.country),
                        "client_id": parseInt(filtervalue.groupname),
                        "legal_entity_id": parseInt(filtervalue.legal),
                        "csv": true,
                        "u_m_none": groupid + ',' + unitid + ',' + domain + ',' + org_type + ',' + fromdate + ',' + todate + ',' + status
                    }
                ]
            }
            exportfiledetails({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const setcancelFilter = () => {
        setfilterModalVisible(false);
    }

    const openfilter = () => {
        setfilterModalVisible(true);
    }

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return <><span>{index + 1}</span><div hidden>{index = index + 1}</div></>
            }
        },
        {
            title: 'Unit Code',
            dataIndex: 'unit_code',
            key: 'unit_code',
            width: '250px',
        },
        {
            title: 'Unit Name',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: '100px',
            render: (data, record) => {
                if (data) {
                    let unitname = data.split('|');
                    return unitname[0]
                }
            },
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '100px'
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '100px'
        },
        {
            title: 'Organization Type',
            dataIndex: 'd_o_names',
            key: 'd_o_names',
            width: '100px'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '100px',
            render: (data, record) => {
                return <span>
                    {data + ',' + record.postal_code}
                </span>
            }
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: '100px'
        },
        {
            title: 'Created By',
            dataIndex: 'emp_code_name',
            key: 'emp_code_name',
            width: '100px'
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on', width: '100px',
            render: (data, record) => {
                return moment(data).format('DD-MMM-YYYY')

            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            width: '100px',
            render: (data, record) => {
                {
                    let closed_days
                    if (record.unit_name) {
                        closed_days = (record.unit_name.split("|")[1]).trim();
                    }

                    if (filtervalue.status == 0) {
                        return <span>Active</span>
                    }
                    else if (filtervalue.status == "2") {
                        if (record.closed_on == "null" && closed_days <= 30)
                            return <><span>Inactive</span><br /><span>-Nil-</span></>
                        else
                            return <><span>Inactive</span><br /><span>{record.closed_on}</span></>
                    } else if (filtervalue.status == "1") {
                        if (record.closed_on == "null" && closed_days > 30)
                            return <><span>Closed</span><br /><span>-Nil-</span></>
                        else
                            return <><span>Closed</span><br /><span>{record.closed_on}</span></>
                    }

                }
            },
        },
    ]

    return (
        <div id="page-wrapper" className="page-wrapper">
           <div className='back-to-top'>
                        <ScrollButton />
                    </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Client Unit Details</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end" style={{ display: showfiltericon }}>
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                marginRight: "15px"
                            }} onClick={openfilter}>
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title="Full Screen Mode"
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Client Unit Details" key={key}>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '6px' }}><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group" style={{ marginLeft: '6px' }}>
                                                    <div className="form-group">
                                                        <Select
                                                            name="country"
                                                            id="country"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.country || undefined}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Country"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        country: '',
                                                                        groupname: '',
                                                                        legal: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        type: '',
                                                                        fromdata: '',
                                                                        todata: '',
                                                                        status: ''

                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        country: data.value,
                                                                        countrylabel: data.children
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filterdatavalue && filterdatavalue.countries && filterdatavalue.countries.length > 0 && filterdatavalue.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            filtervalue.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Group Name:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="groupname"
                                                            id="groupname"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.groupname || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filtervalue.country == ''}
                                                            placeholder="Enter Group"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        groupname: '',
                                                                        legal: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        type: '',
                                                                        fromdata: '',
                                                                        todata: '',
                                                                        status: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        groupname: data.value,
                                                                        grouplabel: data.children
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                                                console.log(filtervalue.groupdata, 'nameee')
                                                                return (
                                                                    <Option key={item.client_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'groupname',
                                                            filtervalue.groupname,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Group Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">

                                                    <div className="form-group">
                                                        <Select
                                                            name="legalentity"
                                                            id="legalentity"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.legal || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filtervalue.groupname == ''}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(option, data) => { selectedlegalentity(data) }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.legaldata && filtervalue.legaldata.length > 0 && filtervalue.legaldata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.legal_entity_id}>
                                                                        {item.legal_entity_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            filtervalue.legal,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 p-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Business Group:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="groupname"
                                                            id="groupname"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.business || undefined}
                                                            // disabled={filtervalue.legal == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Business Group"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        legal: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        type: '',
                                                                        fromdata: '',
                                                                        todata: '',
                                                                        status: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        business: data.value,
                                                                        legal: '',
                                                                        legaldata: '',
                                                                        businesslabel: data.children
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.businessdata && filtervalue.businessdata.length > 0 && filtervalue.businessdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.business_group_id}>
                                                                        {item.business_group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="Unit"
                                                            id="Unit"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.unit || undefined}
                                                            disabled={filtervalue.legal == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Unit"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        unit: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        unit: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.unitdata && filtervalue.unitdata.length > 0 && filtervalue.unitdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_code + '-' + item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="Domain"
                                                            id="Domain"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.domain || undefined}
                                                            disabled={filtervalue.legal == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Domain"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        domain: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        domain: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }} >
                                                            {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.domain_id}>
                                                                        {item.domain_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 p-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Organization Type:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="Organization"
                                                            id="Organization"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.type || undefined}
                                                            disabled={filtervalue.legal == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Organization"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        type: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        type: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {console.log(filtervalue.type, 'typee')}
                                                            {filtervalue.typedata && filtervalue.typedata.length > 0 && filtervalue.typedata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.industry_id}>
                                                                        {item.industry_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker format={'DD-MMM-YYYY'} style={{ width: '100%' }} placeholder='DD-MM-YYYY'
                                                            value={filtervalue.fromdata ? moment(filtervalue.fromdata) : ''}
                                                            disabled={filtervalue.legal == ''}
                                                            onChange={(date, dateString) => {
                                                                if (dateString != '') {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        fromdata: dateString,
                                                                        todata: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        fromdata: '',
                                                                        todata: ''
                                                                    })
                                                                }
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <DatePicker format={'DD-MMM-YYYY'} style={{ width: '100%' }} placeholder='DD-MM-YYYY'
                                                            value={filtervalue.todata ? moment(filtervalue.todata) : ''}
                                                            disabled={filtervalue.legal == ''}
                                                            disabledDate={(current) => {
                                                                return moment(filtervalue.fromdata) >= current
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                if (dateString != '') {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        todata: dateString
                                                                    })
                                                                }
                                                                else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        todata: ''
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 p-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Status:</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="Organization"
                                                            id="Organization"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.status || undefined}
                                                            disabled={filtervalue.legal == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Status"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        status: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        status: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                            <Option key='0'>Active</Option>
                                                            <Option key='1'>Closed</Option>
                                                            <Option key='2'>Inactive</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Button type="primary" shape="round" className='addbutton'
                                            style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={<EyeOutlined />} onClick={showrecord}>
                                            Show
                                        </Button>&nbsp;
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={< ExportOutlined />} onClick={exportfile}>
                                            Export
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div style={{ display: displayresult }}>
                    <div className="d-flex justify-content-around">
                        <label>Country: <b>{filtervalue.countrylabel}</b></label>
                        <label>Business Group: <b>{filtervalue.businesslabel}</b></label>
                        <label>Group: <b>{filtervalue.grouplabel}</b></label>
                        <label>Legal Entity: <b>{filtervalue.legallabel}</b></label>
                    </div>

                    <Table
                        className='reassignclass2'
                        columns={columns}
                        dataSource={unitdata && unitdata.units_list}
                        bordered
                        pagination={false} />
                        {unitdata && unitdata.units_list && unitdata.units_list.length>0?
                        <span>
                            Showing {(current - 1) * pageSize + 1} to {unitdata && unitdata.units_list && unitdata.units_list.length > current * pageSize ?
                        current * pageSize : unitdata && unitdata.units_list && unitdata.units_list.length} of {unitdata && unitdata.units_list && unitdata.units_list.length} entries
                        </span> :''}
                     
                    <Pagination style={{ "margin-top": "10px", float: " RIGHT" }}
                        current={current}
                        showSizeChanger={true}
                        showQuickJumper={false}
                        onShowSizeChange={() => {
                            setCurrent(1)
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        hideOnSinglePage={true}
                        defaultPageSize={25}
                        onChange={(page, pageSizes) => {
                            setpageState(true)
                            setCurrent(pageSize !== pageSizes ? 1 : page);
                            setPageSize(pageSizes)
                        }} />
                </div>
            </div>
            <Modal title="Client Unit Details Report Filter" className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')} footer={false} open={filterModalVisible} onCancel={setcancelFilter}>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label style={{ marginLeft: '6px' }}><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group" style={{ marginLeft: '6px' }}>
                                <div className="form-group">
                                    <Select
                                        name="country"
                                        id="country"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.country || undefined}
                                        maxTagCount='responsive'
                                        placeholder="Enter Country"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    country: '',
                                                    groupname: '',
                                                    legal: '',
                                                    business: '',
                                                    unit: "",
                                                    domain: '',
                                                    type: '',
                                                    fromdata: '',
                                                    todata: '',
                                                    status: ''

                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    country: data.value,
                                                    countrylabel: data.children
                                                })
                                            }
                                        }}
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filterdatavalue && filterdatavalue.countries && filterdatavalue.countries.length > 0 && filterdatavalue.countries.map((item, i) => {
                                            return (
                                                <Option key={item.country_id}>
                                                    {item.country_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'country',
                                        filtervalue.country,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Country Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Group Name:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="groupname"
                                        id="groupname"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.groupname || undefined}
                                        maxTagCount='responsive'
                                        disabled={filtervalue.country == ''}
                                        placeholder="Enter Group"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    groupname: '',
                                                    legal: '',
                                                    business: '',
                                                    unit: "",
                                                    domain: '',
                                                    type: '',
                                                    fromdata: '',
                                                    todata: '',
                                                    status: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    groupname: data.value,
                                                    grouplabel: data.children
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                            console.log(filtervalue.groupdata, 'nameee')
                                            return (
                                                <Option key={item.client_id}>
                                                    {item.group_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'groupname',
                                        filtervalue.groupname,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Group Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">

                                <div className="form-group">
                                    <Select
                                        name="legalentity"
                                        id="legalentity"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.legal || undefined}
                                        maxTagCount='responsive'
                                        disabled={filtervalue.groupname == ''}
                                        placeholder="Enter Legal Entity"
                                        onChange={(option, data) => { selectedlegalentity(data) }}
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filtervalue.legaldata && filtervalue.legaldata.length > 0 && filtervalue.legaldata.map((item, i) => {
                                            return (
                                                <Option key={item.legal_entity_id}>
                                                    {item.legal_entity_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        filtervalue.legal,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Name Required',
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 p-2'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Business Group:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="groupname"
                                        id="groupname"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.business || undefined}
                                        // disabled={filtervalue.legal == ''}
                                        maxTagCount='responsive'
                                        placeholder="Enter Business Group"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    legal: '',
                                                    business: '',
                                                    unit: "",
                                                    domain: '',
                                                    type: '',
                                                    fromdata: '',
                                                    todata: '',
                                                    status: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    business: data.value,
                                                    legal: '',
                                                    legaldata: '',
                                                    businesslabel: data.children
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filtervalue.businessdata && filtervalue.businessdata.length > 0 && filtervalue.businessdata.map((item, i) => {
                                            return (
                                                <Option key={item.business_group_id}>
                                                    {item.business_group_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Unit:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="Unit"
                                        id="Unit"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.unit || undefined}
                                        disabled={filtervalue.legal == ''}
                                        maxTagCount='responsive'
                                        placeholder="Enter Unit"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    unit: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    unit: data.value
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {filtervalue.unitdata && filtervalue.unitdata.length > 0 && filtervalue.unitdata.map((item, i) => {
                                            return (
                                                <Option key={item.unit_id}>
                                                    {item.unit_code + '-' + item.unit_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Domain:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="Domain"
                                        id="Domain"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.domain || undefined}
                                        disabled={filtervalue.legal == ''}
                                        maxTagCount='responsive'
                                        placeholder="Enter Domain"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    domain: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    domain: data.value
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }} >
                                        {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                            return (
                                                <Option key={item.domain_id}>
                                                    {item.domain_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 p-2'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Organization Type:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="Organization"
                                        id="Organization"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.type || undefined}
                                        disabled={filtervalue.legal == ''}
                                        maxTagCount='responsive'
                                        placeholder="Enter Organization"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    type: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    type: data.value
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}>
                                        {console.log(filtervalue.type, 'typee')}
                                        {filtervalue.typedata && filtervalue.typedata.length > 0 && filtervalue.typedata.map((item, i) => {
                                            return (
                                                <Option key={item.industry_id}>
                                                    {item.industry_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>From Date:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <DatePicker format={'DD-MMM-YYYY'} getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} placeholder='DD-MM-YYYY'
                                        value={filtervalue.fromdata ? moment(filtervalue.fromdata) : ''}
                                        disabled={filtervalue.legal == ''}
                                        onChange={(date, dateString) => {
                                            if (dateString != '') {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    fromdata: dateString,
                                                    todata: ''
                                                })
                                            }
                                            else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    fromdata: '',
                                                    todata: ''
                                                })
                                            }
                                        }}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label><b>To Date:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <DatePicker format={'DD-MMM-YYYY'} getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} placeholder='DD-MM-YYYY'
                                        value={filtervalue.todata ? moment(filtervalue.todata) : ''}
                                        disabled={filtervalue.legal == ''}
                                        disabledDate={(current) => {
                                            return moment(filtervalue.fromdata) >= current
                                        }}
                                        onChange={(date, dateString) => {
                                            if (dateString != '') {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    todata: dateString
                                                })
                                            }
                                            else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    todata: ''
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 p-2'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Status:</b></label>
                            <div className="form-group">
                                <div className="form-group">
                                    <Select
                                        name="Organization"
                                        id="Organization"
                                        allowClear={true}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="default"
                                        value={filtervalue.status || undefined}
                                        disabled={filtervalue.legal == ''}
                                        maxTagCount='responsive'
                                        placeholder="Enter Status"
                                        onChange={(option, data) => {
                                            if (data == undefined) {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    status: ''
                                                })
                                            } else {
                                                setfiltervalue({
                                                    ...filtervalue,
                                                    status: data.value
                                                })
                                            }
                                        }
                                        }
                                        showSearch
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        <Option key='0'>Active</Option>
                                        <Option key='1'>Closed</Option>
                                        <Option key='2'>Inactive</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                        style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<EyeOutlined />} onClick={showrecord}>
                        Show
                    </Button>&nbsp;&nbsp;
                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={< ExportOutlined />} onClick={exportfile}>
                        Export
                    </Button>
                </div>
            </Modal>
        </div>


    )
}
export default connect(mapStateToProps, {
    Filterdata,
    legalentitydata,
    unitdetails,
    exportfiledetails
})(ClientUnitDetailsReport);