import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, message, Modal, Tooltip } from 'antd';
import { sessionLogout, notifications, Message } from './../Store/Action/Login';
import { useHistory, Link } from "react-router-dom";
import { Toaster } from '../Libs/Toaster'
import { PoweroffOutlined, CloseCircleOutlined,RightOutlined } from "@ant-design/icons";
const _ = require("lodash");

const mapStateToProps = (state) => ({
    notify: state.Login.notifications,
    messages: state.Login.message
});

const Header = ({
    sessionLogout,
    notifications,
    notify, Message, messages
}) => {
    const isAuth = localStorage.getItem('isAuthenticated');
    const history = useHistory();
    const [entityinfo, Setentityinfo] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [homeurl, sethomeurl] = useState({
        formkey: '',
        formurl: ''
    });
    const [changepassword, Setchangepassword] = useState();
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const country_info = sessionValue.country_info
    const [notificationdata, Setnotificationdata] = useState();
    const [escalationdata, Setescalationdata] = useState();
    const [reminderdata, Setreminderdata] = useState();
    const [messagedata, Setmessagedata] = useState();
    const [notificationValue, SetnotificationValue] = useState({
        statutoryNotificationValue: '',
        messagesValue: '',
    });
    const [notificationscount, Setnotificationscount] = useState();
    const authtoken = localStorage.getItem('authToken');
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu.menus['My Accounts'];
    const [formurl, Setformurl] = useState();

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu.menus['My Accounts']
            const statutoryArray = _.filter(getUrl, { form_name: 'Statutory Notification' })
            const messageArray = _.filter(getUrl, { form_name: 'Messages' })
            const home = callername.login_response.menu.menus['Home'];
            const profile = _.filter(getUrl, { form_name: 'View Profile' })
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            Setchangepassword(ChangePassword && ChangePassword[0]?.form_url)
            Setformurl(profile && profile[0]?.form_url)

            // if(callername.login_response.password_expire_days){
            //      const days = callername.login_response.password_expire_days;
            //     if(days <= 5){
            //         Toaster.error('Your password will expire in '+days+' day(s)');
            //     }
            // }

            sethomeurl({
                ...homeurl,
                formkey: home[0].form_key,
                formurl: home[0].form_url
            });

            SetnotificationValue({
                ...notificationValue,
                statutoryNotificationValue: statutoryArray[0].form_url,
                messagesValue: messageArray[0].form_url,
            })
        }
    }, [isAuth])

    useEffect(() => {
        let notifypay;
        let messagepayload;


        if (authtoken && isAuth) {
            messagepayload = {
                "session_token": authtoken,
                "request": [
                    "GetMessages",
                    {
                        "from_count": 0,
                        "page_count": 2
                    }
                ]
            }

            notifypay = {
                "session_token": authtoken,
                "request": [
                    "GetStatutoryNotifications",
                    {
                        "from_count": 0,
                        "page_count": 2
                    }
                ]
            }
        }
        let home = callername.login_response.menu.menus['Home'];
        notifications({
            payload: notifypay,
            caller: home[0].form_url,
            formkey: home[0].form_key
        })
        Message({
            payload: messagepayload,
            caller: home[0].form_url,
            formkey: home[0].form_key
        })

    }, [authtoken, isAuth])

    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        for (let countrylist in country_info) {
            let country_id = country_info[countrylist].c_id
            let country_name = country_info[countrylist].c_name
            let entityArray = []
            let tempArray = []
            let sumValue = 0;
            for (let entitylist in entity_info) {
                let entitylist_cid = entity_info[entitylist].c_id
                if (entitylist_cid === country_id) {
                    sumValue = entity_info[entitylist].u_count + sumValue
                    entityArray = {
                        'bg_id': entity_info[entitylist].bg_id,
                        'bg_name': entity_info[entitylist].bg_name,
                        'c_id': entity_info[entitylist].c_id,
                        'c_name': entity_info[entitylist].c_name,
                        'le_id': entity_info[entitylist].le_id,
                        'le_name': entity_info[entitylist].le_name,
                        'u_count': entity_info[entitylist].u_count,
                    }
                    tempArray.push(entityArray)
                }
            }
            finalArray[country_name] = tempArray
            countrylistdata = {
                'c_id': country_id,
                'c_name': country_name,
                'allEntitySumNew': sumValue,
                'child': tempArray
            }
            countrylisttempdata.push(countrylistdata)
            SetfinalResult({
                ...finalResult,
                Resultdata: countrylisttempdata
            })


        }

    }, [])

    const entityselected = (c_name, c_id) => {
        localStorage.setItem('SelectedEntity', c_name);
        localStorage.setItem('SelectedEntityid', c_id);
        setIsModalVisible(false);
        window.location.reload(false);
    }

    const logout = () => {
        sessionLogout({
            form_key: homeurl.formkey,
            form_url: homeurl.formurl
        })
    }



    const getnotifications = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        notifications({
            payload: payload,
            caller: login.form_url
        })
    }



    const getmessages = () => {
        let messagepay;
        if (legalid != 'null') {
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        Message({
            payload: messagepay,
            caller: login.form_url
        })
    }

    useEffect(() => {
        if (notify || messages) {
            const data = notify.statutory_notifications;
            const mes = messages.messages;

            Setnotificationdata(data);
            Setmessagedata(mes);
            localStorage.setItem('notificationscount', notify.statutory_count);
            localStorage.setItem('messagecount', messages.messages_count)
        }

    }, [notify, messages])

    return (
        <Fragment>
            <header id='top-nav' className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header" style={{ 'overflow': 'hidden' }}>
                        <a className="nav-toggler waves-effect waves-light d-block d-md-none"><i className="ri-close-line ri-menu-2-line fs-6"></i></a>
                        <div style={{ "height": "73px", 'overflow': 'hidden' }} className="row border-bottom ">
                            <div className="col-md-2">
                                <a id="menu-icon" className="nav-link sidebartoggler d-none d-md-block"><i data-feather="menu"></i></a>
                                <a className="nav-link sidebartoggler d-md-block">
                                    <img id="star-icon" className="d-none staricon" src="favicon.png" alt=""></img>
                                </a>
                            </div>
                            <div id="logo-icon" className="col-md-10 px-0">
                                <a className="navbar-brand  text-center logo-icon">
                                    <img
                                        src="logo-icon.png"
                                        alt="homepage"
                                        className="dark-logo w-75 mt-3"
                                    />
                                </a>
                            </div>
                        </div>
                        <a
                            className="topbartoggler d-block d-md-none waves-effect waves-light"
                            href="/#"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><i className="ri-more-line fs-6"></i></a>
                    </div>
                    <div className="navbar-collapse collapse">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                            </li>

                        </ul>

                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle"
                                    href="/#"
                                    id="2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getmessages}
                                >
                                    <Tooltip getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }} title='Messages'><div><i data-feather="message-square" className='iconcolor'></i></div></Tooltip>
                                    <div className="notify">

                                        {
                                            localStorage.getItem('messagecount') != 0 ?
                                                <span className="point"></span> : null}
                                    </div>
                                </a>
                                <div
                                    className="dropdown-menu mailbox dropdown-menu-end dropdown-menu-animate-up p-0" aria-labelledby="2">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    messagedata && messagedata.length > 0 && messagedata.map((item, i) => {
                                                        return (<Link key={i}
                                                            className="message-item d-flex align-items-center border-bottom p-0">
                                                            <div className=" d-inline-block v-middle ps-2">
                                                                <Link className='p-0 m-0' to={notificationValue.messagesValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text-notify">{item.message_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <label className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</label>
                                                            </div>
                                                        </Link>)

                                                    })
                                                }
                                                {messagedata && messagedata.length > 0 ?
                                                    <div className={"mt-2 popupbtncolour"}>
                                                        <Link id='logout_btn'
                                                            className={`btn text-white ${localStorage.getItem('currentTheme')}`}
                                                            // className={localStorage.getItem('currentTheme') + "btn text-white"}
                                                            to={notificationValue.messagesValue}
                                                        >
                                                            Read More <RightOutlined />
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top pb-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getnotifications}>
                                    <Tooltip title='Statutory notification' getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }} ><div><i data-feather="bell" title='Notifications' className='iconcolor'></i></div></Tooltip>
                                    <div className="notify">
                                        {
                                            localStorage.getItem('notificationscount') != 0 ?
                                                <span className="point"></span> : null
                                        }
                                    </div>
                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up p-0">

                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    notificationdata && notificationdata.length > 0 && notificationdata.map((item, dkey) => {
                                                        return (<Link key={dkey} className="message-item d-flex align-items-center border-bottom p-0">
                                                            <div className=" d-inline-block v-middle ps-2">
                                                                <Link className='p-0 m-0' to={notificationValue.statutoryNotificationValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text-notify">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <label className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</label>
                                                            </div>
                                                        </Link>)

                                                    })
                                                }
                                                {notificationdata && notificationdata.length > 0 ?
                                                    <div className={"mt-3 popupbtncolour"}>
                                                        <Link id='logout_btn'
                                                            className={`btn text-white ${localStorage.getItem('currentTheme')}`}
                                                            to={notificationValue.statutoryNotificationValue}
                                                        >
                                                            Read More <RightOutlined />
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top pb-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item dropdown profile-dropdown">
                                <a
                                    className="nav-link dropdown-toggle d-flex align-items-center"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="3.png"
                                        alt="user"
                                        width="30"
                                        className="profile-pic rounded-circle"
                                    />
                                    <div className="d-none d-md-flex">
                                        <span style={{ "lineHeight": "2.3rem" }} className="ms-2 mt-3"
                                        >
                                            <span className="text-dark fw-bold">{sessionValue.login_response.employee_name}</span>

                                        </span>
                                        <span>
                                            <i data-feather="chevron-down" className="feather-sm"></i>
                                        </span>
                                    </div>

                                </a>
                                <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">
                                    <ul className="list-style-none">
                                        <li className="p-3 pb-2 pt-3">
                                            <div className="rounded-top d-flex align-items-center">
                                                <h3 className="card-title mb-0 fs-4">My Accounts</h3>
                                                <div className="ms-auto">
                                                    <Link className="li py-0">
                                                        <CloseCircleOutlined />
                                                    </Link>
                                                </div>
                                            </div>

                                        </li>
                                        <li id='myAccounts' className="p-3 pb-2 pt-0 ml-2 position-relative" style={{ 'overflowY': 'scroll' }}>
                                            <div className="message-body position-relative">
                                                <Link to={formurl ? formurl : ''}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info p-1">
                                                        <i data-feather="user"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            View Profile
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link to={changepassword ? changepassword : ''}
                                                    className="
                                                    message-item
                                                    px-2
                                                    d-flex
                                                    align-items-center
                                                    border-bottom
                                                    py-1
                                                  ">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">
                                                        <i
                                                            data-feather="lock"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Change Password
                                                        </h5>
                                                    </div>
                                                </Link>
                                                {/* <Link
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">

                                                        <i
                                                            data-feather="copy"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Themes
                                                        </h5>
                                                    </div>
                                                </Link> */}

                                            </div>
                                        </li>
                                        <li className='my-0 mx-2'>
                                            <div className="mt-2 mb-2">
                                                <Button
                                                    style={{ borderRadius: '10px', width: '235px' }}
                                                    className="btn logout-color-change"
                                                    id='logout_btn'
                                                    onClick={logout}
                                                    icon={<PoweroffOutlined />}
                                                >
                                                    Logout
                                                </Button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                        </ul>
                    </div>
                </nav>
            </header>
        </Fragment >
    )
};


export default connect(mapStateToProps, {
    sessionLogout,
    notifications,
    Message
})(Header);
