import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Button, Table, Tooltip, Input, Modal, Card, Radio, Divider, Row, Col, Breadcrumb } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { DownloadOutlined, UploadOutlined, UpOutlined, FullscreenOutlined, FileExcelTwoTone, FileTwoTone, ProfileTwoTone, FileTextTwoTone } from "@ant-design/icons";
import { Toaster } from '../../Libs/Toaster'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import URL from '../../Libs/URL'
import { filterdetails, uploadCSV, uploadFileStatus } from '../../Store/Action/Transactions/TE_Clientunitbulkupload';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";

const _ = require("lodash");

const mapStateToProps = (state) => ({
    info: state.Client_unit_bulkupload.client_unit_bulkupload.filterdetails,
    csvName: state.Client_unit_bulkupload.client_unit_bulkupload.downloadAssignStatutory.csv_name,
    downloadUrl: state.Client_unit_bulkupload.client_unit_bulkupload.downloadUrl.link,
    uploadCSV_name: state.Client_unit_bulkupload.client_unit_bulkupload.uploadCSV_name.csv_name,
    fileStatus: state.Client_unit_bulkupload.client_unit_bulkupload.uploadFileStatus
})

const ClientunitBulkupload = ({
    filterdetails,
    info,
    downloadAssignStatutory,
    csvName,
    downloadLink,
    downloadUrl,
    uploadCSV,
    uploadCSV_name,
    uploadFileStatus,
    fileStatus,
}) => {
    console.log(fileStatus, "fileStatusfileStatus");
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const dispatch = useDispatch();

    const { Option } = Select;
    const uploadValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [filedetails, setfiledetails] = useState({
        filename: ''
    })
    const [uploaddocument, setuploaddocument] = useState({
        country: '',
        domain: '',
        domaindata: '',
        updateloadedFileData: '',
    })


    const [downloaddata, setdownloaddata] = useState({
        clientName: '',
        clientgroup: '',
    })
    const [csvStatus, setCSVstatus] = useState([]);
    const [fileInfo, setFileInfo] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState({});
    const [uploadFailedUrl, setUploadFailedUrl] = useState(false);
    const [inValidBtn, setInvalidBtn] = useState(false);
    console.log(downloaddata, "downloaddata456");

    // For Validation
    useEffect(() => {
        uploadValidator.current.showMessages();
    }, [])

    // For Home Screen navigate
    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    // Inital API
    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientGroupsList",
                    {}
                ]
            }
            filterdetails({
                payload: pay,
                paramid: paramid
            })
        }
    }, [isAuth])


    const convert_to_base64 = (file, callback) => {
        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }

    const uploadedfile = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                return;
            }
            let file_size = file.size;
            if (file_size > file_max_size) {
                Toaster.error('Maximum File Sixe is 25MB');
                return;
            }
            if (file) {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);

                    results.push(result);
                    setfiledetails({
                        ...filedetails,
                        filename: results
                    })

                });

            }
        }
    }

    // Upload API 
    const uploaddocu = (e) => {
        e.preventDefault();
        setUploadFailedUrl(false);
        setInvalidBtn(false);
        console.log(filedetails, 'filedetails');
        if (uploadValidator.current.allValid()) {
            setAddFormSubmit2(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "UploadClientUnitsBulkCSV",
                    {
                        "bu_client_id": parseInt(downloaddata.clientgroup),
                        "bu_group_name": downloaddata.clientName,
                        "csv_name": filedetails.filename[0].file_name,
                        "csv_data": filedetails.filename[0].file_content,
                        "csv_size": filedetails.filename[0].file_size
                    }
                ]
            }
            uploadCSV({
                payload: pay,
                paramid: paramid
            })
        } else {
            setAddFormSubmit2(true);
        }
    }

    useEffect(() => {
        if (uploadCSV_name) {
            const paypass = {
                "session_token": authtoken,
                "request": [
                    "GetClientUnitUploadStatus",
                    {
                        // "csv_name": filedetails.filename[0].file_name
                        "csv_name": uploadCSV_name
                    }
                ]
            }

            uploadFileStatus({
                payload: paypass,
                paramid: paramid
            })

        }

    }, [uploadCSV_name])


    // Download Empty format 
    const Downloaddoc = () => {
        window.open(URL.CommonURL + '/csv_format/Client_Units.csv');
    }


    //  Full screen mode //
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    console.log(is_Fullscreen, "is_Fullscreenis_Fullscreen");
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const downloadfile = (e, id) => {
    }

    // Invalid File Error
    useEffect(() => {
        if (fileStatus.length) {
            console.log(fileStatus, "fileStatus456456");

            const data = fileStatus && fileStatus[1];
            console.log(data,"datadata444555");
            if (data !== {}) {
                console.log("trueeeeeeee");
                let name = data && data.invalid_file.split('.');
                console.log(name, "namename123");


                if (fileStatus[0] === 'UploadClientUnitBulkCSVFailed') {
                    setInvalidUrl({
                        invalidUrl: URL.CommonURL + '/' + 'invalid_file/',
                        fileName: name[0]
                    })
                    setCSVstatus(data);
                    setFileInfo(true);
                    // setUploadFailedUrl(true);
                    setInvalidBtn(true);
                    Toaster.error('CSV file upload failed');
                }

                if (fileStatus[0] === 'UploadClientUnitBulkCSVSuccess') {
                    setCSVstatus(data);
                    setFileInfo(true);

                    Toaster.success('CSV file upload success');
                }
            }

            if (fileStatus[0] === 'RejectionMaxCountReached') {
                Toaster.error('CSV file upload max count reached');
            }
            if (fileStatus[0] === 'CSVColumnMisMatched') {
                Toaster.error('Invalid Csv File');
            }

            console.log('file', fileStatus);
        } else {
            setFileInfo(false);
        }

    }, [fileStatus])



    return (
        <div id="page-wrapper" className="page-wrapper">
            <ScrollToTop smooth color="red" component={
                <Tooltip title="Back to top">
                    <UpOutlined />
                </Tooltip>
            }
            />
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transactions</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Client Unit - Bulk Upload </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row mx-3 justify-content-md-center" >
                    <Card className='col-md-6' style={{ marginTop: "10px", marginRight: "15px", boxShadow: "0px 0px 0px 1px grey" }}>
                        <div className='col-md-12 p-3'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label style={{ float: "left" }}><b>Client Group :</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ padding: 0 }}>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='clientgroup'
                                        placeholder="Select Client Group"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '90%' }}
                                        value={downloaddata.clientgroup || undefined}
                                        onChange={(data, value) => {
                                            setdownloaddata({
                                                ...downloaddata,
                                                clientgroup: data ? data : '',
                                                clientName: value.children ? value.children : ''
                                            })

                                        }}
                                    >
                                        {info && info.client_group_list && info.client_group_list.length > 0 && info.client_group_list.map((item, i) => {
                                            return (
                                                <Option key={item.client_id}>
                                                    {item.group_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {uploadValidator.current.message(
                                        'clientgroup',
                                        downloaddata.clientgroup,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Client Group Required'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-3' style={{ marginLeft: '25px' }}>
                                    <label><b>Select File :</b><span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='col-md-9' style={{ marginLeft: '135px', width: '70%' }}>
                                    <Input accept=".csv"
                                        className='form-control'
                                        type='file'
                                        onChange={(e) => {
                                            uploadedfile(e)
                                            setuploaddocument({
                                                ...uploaddocument,
                                                updateloadedFileData: e.target.value
                                            })
                                        }} />
                                    {uploadValidator.current.message(
                                        'File',
                                        uploaddocument.updateloadedFileData,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'File Required'
                                            }
                                        })}
                                    <h6 style={{ marginTop: "5px", marginLeft: "5px" }}>[Accepted file format .csv]</h6>
                                </div>
                                <div className='col-md-2'></div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4' >
                                    <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "30px", marginTop: "25px" }}
                                        icon={<UploadOutlined />} size='default' onClick={uploaddocu}>
                                        Upload
                                    </Button>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'></div>
                                <div className='col-md-4 mb-2'> {inValidBtn == false ? <Link title='Click here to download' onClick={Downloaddoc}> <DownloadOutlined />   Download Template</Link> : null}
                                </div>
                            </div>
                        </div>

                        <div className='pt-2 px-3' style={{ textAlign: 'center' }}>
                            {inValidBtn ? <Button style={{ borderRadius: '15px' }} onClick={() => setUploadFailedUrl(!uploadFailedUrl)}>Valid / Invalid Data</Button> : null}
                            {uploadFailedUrl ?
                                <Breadcrumb separator=" | " style={{ color: 'blue', marginLeft: '30px' }}>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'xlsx/' + invalidUrl.fileName + '.xlsx'}>Download Excel</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'csv/' + invalidUrl.fileName + '.csv'}>Download Csv</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'ods/' + invalidUrl.fileName + '.ods'}>Download ODS</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a style={{ color: '#0060df' }} href={invalidUrl.invalidUrl + 'txt/' + invalidUrl.fileName + '.txt'}>Download Text</a></Breadcrumb.Item>
                                </Breadcrumb>
                                : null}
                        </div>
                        <Divider />
                        {fileInfo ? <Row>
                            <Col span={12}>
                                <h4>Uploaded Data - Summary</h4>
                                <ul>
                                    <li>Total No. Of Records: {csvStatus.total ? csvStatus.total : 0}</li>
                                    <li>Valid Records: {0}</li>
                                    <li>Invalid Records: {csvStatus.invalid ? csvStatus.invalid : 0}</li>
                                </ul>
                            </Col>
                            <Col span={12} style={{ color: 'red' }}>
                                <h4 style={{ color: 'red' }}>Invalid Data Summary</h4>
                                <ul>
                                    <li>Mandatory Field Blank: {csvStatus.mandatory_error ? csvStatus.mandatory_error : 0}</li>
                                    <li>Compfie Domain Field Mandatory: {csvStatus.compfie_domain_mandatory_error ? csvStatus.compfie_domain_mandatory_error : 0}</li>
                                    <li>Compfie Organization Field Mandatory: {csvStatus.compfie_organization_mandatory_error ? csvStatus.compfie_organization_mandatory_error : 0}</li>
                                    <li>Litigation Domain Field Mandatory: {csvStatus.litigation_domain_mandatory_error ? csvStatus.litigation_domain_mandatory_error : 0}</li>
                                    <li>Not Mandatory Field: {csvStatus.not_mandatory_error ? csvStatus.not_mandatory_error : 0}</li>
                                    <li>Duplicate Unit Code: {csvStatus.duplicate_error ? csvStatus.duplicate_error : 0}</li>
                                    <li>Master Data inactive: {csvStatus.inactive_error ? csvStatus.inactive_error : 0}</li>
                                    <li>Max.Length Exceeded: {csvStatus.max_length_error ? csvStatus.max_length_error : 0}</li>
                                    <li>Invalid Data: {csvStatus.invalid_data_error ? csvStatus.invalid_data_error : 0}</li>
                                    <li>Max.Unit Count Exceeded: {csvStatus.max_unit_count_error ? csvStatus.max_unit_count_error : 0}</li>

                                </ul>
                            </Col>
                        </Row> : null}


                    </Card>

                </div>
            </div>
        </div >
    )

}
export default connect(mapStateToProps, {
    filterdetails,
    uploadCSV,
    uploadFileStatus
})(ClientunitBulkupload);