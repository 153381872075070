import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Button } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { Toaster } from '../../Libs/Toaster';
import { PlayCircleOutlined, SearchOutlined, FullscreenOutlined, UpOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown, setFocus } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import { HomeRedirect } from './../../Libs/country';

const mapStateToProps = (state) => ({

})
const Country = ({

}) => {
    const historynew = useHistory();
    const dispatch = useDispatch();
    const homepage = HomeRedirect()


    //  Full screen mode //
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    // Search Function 

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{

                }}
            />

        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            setSearchedColumn(dataIndex)
        }
    };

    // Home Screen
    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    // Table
    const columns = [
        {
            title: '#',
            key: 'false',
            align: "center",
            width: "10px",
            // render: (text, record, index) => {
            //     return (
            //         (page - 1) * paginationSize + index + 1
            //     )
            // },
        },
        {
            title: 'Country Name',
            ...getColumnSearchProps('r_description', 'Remarks'),
            // dataIndex: 'r_description',
            key: 'r_description',
            width: '70px',
            ellipsis: true
        },
        {
            title: "Postal Code",
            // dataIndex: 'u_c_name',
            align: 'center',
            width: '20px',
            sortable: false,
        },
        {
            title: "Status",
            // dataIndex: 'u_c_name',
            align: 'center',
            width: '15px',
            sortable: false,
        },
        {
            title: "Has Multiple Time zone",
            // dataIndex: 'u_c_name',
            align: 'center',
            width: '20px',
            sortable: false,
        },
        {
            title: "Postal Code Validation",
            // dataIndex: 'u_c_name',
            align: 'center',
            width: '20px',
            sortable: false,
        },
    ];

    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles py-0 page-title-sticky">

                        <div className="row py-2">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span >Countries</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 text-end">
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                    {/* //  Full screen mode // */}
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-0">
                        <div className="row">
                            <div className="col-md-12 remarksplittable">
                                <div className='service-provider-table'>
                                    <Table
                                        className={localStorage.getItem("currentTheme") + ' remarkstable'}
                                        size={'small'}
                                        columns={columns}
                                        dataSource={''}
                                        bordered
                                        pagination={false}
                                        showSorterTooltip={false}

                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                </div >

            </div>
            {/* <Modal autoFocus={false} open={modalVisible} footer={null} maskClosable={false} onCancel={handleCancel} className="newStyleModalPassword-remarks modelradius" >
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-6">
                        <label className="control-label"> <b>Remarks </b><span style={{ "color": "red" }}>*</span></label>
                        <textarea ref={serviceProviderRef1} autoFocus={true} className='form-control' name='remark' value={remarkValue.description}
                            row='1' cols='4' style={{ resize: 'none', height: '150px' }} maxLength={500} onChange={onInputChange}
                        >
                        </textarea>

                        {formValidator.current.message(
                            'remark',
                            remarkValue.description,
                            ['required', { regex: /[^|~^]+$/ }],
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Remarks Required',
                                    regex: '500 Characters only allowed'
                                }
                            })}
                    </div>
                </div>
            </div>
            <div className={"form-actions text-center " + localStorage.getItem('currentTheme')}>


                <div className={"form-actions text-center pt-3 popupbtncolour " + localStorage.getItem('currentTheme')} >
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}>
                        Submit
                    </Button>
                </div>
            </div>

        </Modal> */}
        </Fragment>
    )



}
export default connect(mapStateToProps, {

})(Country);