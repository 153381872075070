import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Modal, Empty } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { BulkUploadConstants, Users, SMBulkReportData, ExportBulkReportData } from './../../../src/Store/Action/Reports/StatutoryMapping_BulkUploadReport';
import { EXPORTBULKREPORTDATA_RESET } from './../../Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
// import useStickyHeight from "../../../Libs/useStickyHeight";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";

const mapStateToProps = (state) => ({
    statutorybulkuploads: state.StatutoryMapping_BulkUploadReport.statutorybulkupload,
})

const StatutoryMappingBulkReport = ({
    BulkUploadConstants,
    ExportBulkReportData,
    Users,
    SMBulkReportData,
    statutorybulkuploads: { bulkupload, user, reportdata, exportdatas }
}) => {
    // useStickyHeight();
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [Country, setCountry] = useState([])
    const [act, setAct] = useState([])
    const [domain, setDomain] = useState([])
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [unitdata, setUnitData] = useState({
        country: [],
        countryName: [],
        domain: [],
        domain_name: [],
        act: '',
        act_name: '',
        from_date: "",
        to_date: "",
        uploadtype: ''
    })
    console.log(unitdata, 'unitdata');
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [page, setPage] = useState(1);
    const [all, setAll] = useState({
        country: [],
        domain: []
    })
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '30px',
            ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * pageSize + index + 1
            },
        },
        {
            title: "Country",
            dataIndex: 'country_name',
            key: 'country_name',
            width: '70px',
            ellipsis: true
        },
        {
            title: "Domain",
            dataIndex: 'domain_name',
            key: 'domain_name',
            width: '120px',
            // ellipsis: true

        },
        {
            title: "Uploaded By",
            dataIndex: false,
            key: false,
            width: '120px',
            // ellipsis: true,
            render: (record) => {
                let data = _.find(user.user_details, { user_id: record.uploaded_by })
                return (
                    <>
                        <span >{data.employee_code}-{data.employee_name}</span>

                    </>
                )
            }
        },
        {
            title: "Uploaded On",
            dataIndex: 'uploaded_on',
            key: 'uploaded_on',
            width: '120px',
            // ellipsis: true
        }, {
            title: "Uploaded File Name",
            dataIndex: 'csv_name_text',
            key: 'csv_name_text',
            width: '160px',
            ellipsis: true
        }, {
            title: "No.of Tasks",
            dataIndex: 'total_records',
            key: 'total_records',
            width: '80px',
            ellipsis: true,
            align: 'center'
        }, {
            title: "Approved / Rejected Tasks",
            dataIndex: false,
            key: false,
            align: 'center',
            render: (record) => {
                // let data = _.find(user.user_details, { user_id : record.uploaded_by })
                return (
                    <>
                        {record.total_approve_records == 0 ? '-' : <span >{record.total_approve_records}/{record.total_rejected_records}</span>}
                    </>
                )
            },
            width: '120px',
            // ellipsis: true
        }, {
            title: "Approved / Rejected On",
            dataIndex: 'approved_on',
            key: 'approved_on',
            width: '120px',
            // ellipsis: true
        },
        {
            title: "Approved / Rejected By",
            dataIndex: false,
            key: false,
            render: (record) => {
                let data = _.find(user.user_details, { user_id: record.approved_by })
                return (
                    <span>{data && data.employee_code}-{data && data.employee_name}</span>
                )
            },
            width: '110px',
            // ellipsis: true
        }, {
            title: "Reason for Rejection",
            dataIndex: 'rejected_reason',
            key: 'rejected_reason',
            width: '100px',
            // ellipsis: true
        }
    ]

    useEffect(() => {
        if (isAuth) {
            BulkUploadConstants({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetBulkUploadConstants",
                        {}
                    ]
                }
                ,
                paramid: paramid
            })
            Users({
                payloads:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUsers",
                        {}
                    ]
                },
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        if (exportdatas && exportdatas.link) {
            window.open(process.env.REACT_APP_API_URL + exportdatas.link);
            dispatch({
                type: EXPORTBULKREPORTDATA_RESET
            });
        }

    }, [exportdatas])

    useEffect(() => {
        if (user) {
            setCountry(user && user.countries)
        }
    }, [user])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (unitdata.country) {
            user.domains && user.domains.length > 0 && user.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(unitdata.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name
                    }
                    domainArray.push(tempArray)
                }
            })
            setDomain(domainArray)
        }
    }, [unitdata.country])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    // useEffect(() => {
    //     if (statutorylistreportdata && statutorylistreportdata.statutory_notifictions_list && statutorylistreportdata.statutory_notifictions_list.length > 0) {
    //         var array = statutorylistreportdata.statutory_notifictions_list.slice((current - 1) * pageSize, (current * pageSize))
    //         setPaginationArray(array)
    //     }
    // }, [statutorylistreportdata.statutory_notifictions_list, pageSize, current])

    const onshow = () => {
        setPageSize(25);
        setCurrent(1);
        setPage(1);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            let temp = []
            let temparr = []
            for (let i in unitdata.domain) {
                temp.push(Number(unitdata.domain[i]))
            }
            for (let i in unitdata.country) {
                temparr.push(Number(unitdata.country[i]))
            }
            SMBulkReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetSMBulkReportData",
                        {
                            "c_ids": temparr,
                            "d_ids": temp,
                            "from_date": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "r_count": 0,
                            "p_count": 25,
                            "is_amend": unitdata.uploadtype == '' ? [0, 1] : [Number(unitdata.uploadtype)],
                            "child_ids": [sessionParsedValue.login_response.user_id],
                            "user_category_id": Number(sessionParsedValue.login_response.reference)
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    const exportdata = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            let temp = []
            let temparr = []
            let tempcd = []
            for (let i in unitdata.domain) {
                temp.push(Number(unitdata.domain[i]))
            }
            for (let i in unitdata.country) {
                temparr.push(Number(unitdata.country[i]))
            }
            for (let i in unitdata.country) {
                for (let j in unitdata.domain) {
                    tempcd.push(Number(unitdata.country[i]) + '-' + Number(unitdata.domain[j]))
                }
            }
            ExportBulkReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "ExportSMBulkReportData",
                        {
                            "c_ids": temparr,
                            "bu_country_names": unitdata.countryName[0],
                            "d_ids": temp,
                            "bu_dom_names": unitdata.domain_name[0],
                            "from_date": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "csv": true,
                            "child_ids": [sessionParsedValue.login_response.user_id],
                            "user_category_id": Number(sessionParsedValue.login_response.reference),
                            "c_d_ids": tempcd,
                            "is_select_exe": false
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    const HandelChange = (obj, data) => {
        console.log(data, 'objectobject')
        if (obj.length > 0) {
            setAll({
                ...all,
                country: obj
            })
            let temp = []
            for (let i in data) {
                temp.push(data[i].children)
            }
            setUnitData({
                ...unitdata,
                country: obj,
                countryName: temp
            })
        } else {
            setAll({
                ...all,
                country: obj,
                domain: ''
            })
            setUnitData({
                ...unitdata,
                country: obj,
                countryName: data,
                domain: '',
                domain_name: ''
            })
        }
    };

    const HandelChangeDomain = (obj, data) => {
        console.log(data, 'objectobject')
        let temp = []
        for (let i in data) {
            temp.push(data[i].children)
        }
        setAll({
            ...all,
            domain: obj
        })
        setUnitData({
            ...unitdata,
            domain: obj,
            domain_name: temp
        })

    };

    // useEffect(() => {
    //     if (pageState == true) {
    //         StatutoryNotificationsReportData({
    //             payload:
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetStatutoryNotificationsReportData",
    //                     {
    //                         "country_id": Number(unitdata.country),
    //                         "domain_id": Number(unitdata.domain),
    //                         "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
    //                         "from_date_optional": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
    //                         "to_date_optional": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
    //                         "from_count": Math.max(((current - 1) * pageSize), 0),
    //                         "page_count": current != 0 ? current * pageSize : pageSize
    //                     }
    //                 ]
    //             },
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])

    // Filter Modal popup
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    // Full Screen Mode
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const [usergroupname, setusergroupname] = useState();
    useEffect(() => {
        if (user && user.user_details && user.user_details.length > 0) {
            const group = _.filter(user.user_details, { user_id: sessionParsedValue.login_response.user_id })
            setusergroupname(group)
            console.log(group, 'group');
        }
    }, [user])

    return (
        <div id="page-wrapper" className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles py-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span >Statutory Mapping - Bulk Upload Report </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                marginRight: "15px",
                                display: filterTaskAcc ? "none" : "inline",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Statutory Mapping - Bulk Upload Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="country"
                                                            id="country"
                                                            mode="multiple"
                                                            allowClear={true}
                                                            autoFocus={true}
                                                            size="default"
                                                            value={all.country || undefined}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Country"
                                                            onChange={(option, data) => HandelChange(option, data)}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                        >
                                                            {Country && Country.length > 0 && Country.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Please Enter Country',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Domain:&nbsp;<span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    mode="multiple"
                                                    allowClear={true}
                                                    size="default"
                                                    name="domain"
                                                    id="domain"
                                                    value={all.domain || undefined}
                                                    disabled={unitdata.country == '' ? true : false}
                                                    placeholder="Enter Domain Name"
                                                    onChange={(option, data) => HandelChangeDomain(option, data)}
                                                    maxTagCount='responsive'
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter Domain',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>From Date:&nbsp;<span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MM-YYYY' style={{ width: '100%' }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        if (dateString != '') {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: dateString,
                                                                to_date: ''
                                                            })
                                                        }
                                                        else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        }
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment().add(0, 'days') <= current
                                                    }}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter From Date',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>To Date:&nbsp;</b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MM-YYYY' style={{ width: '100%' }} value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    disabled={unitdata.from_date == '' ? true : false}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString
                                                        })
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(0, 'days') >= current ||
                                                            moment().add(0, 'days') <= current;
                                                    }}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter To Date',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Upload Type:&nbsp;</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            // value={all.country || undefined}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Upload Type"
                                                            onChange={(data) => {
                                                                if (data) {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        uploadtype: data
                                                                    })                                                                    
                                                                } else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        uploadtype: ''
                                                                    }) 
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Option key='0'>{"Statutory Mapping"}</Option>
                                                            <Option key='1'>{"Approved, Approved & Notified"}</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>KE Name:&nbsp;</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        {usergroupname && usergroupname.length > 0 && usergroupname[0].employee_code}-
                                                        {usergroupname && usergroupname.length > 0 && usergroupname[0].employee_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12' style={{ marginTop: "10px", marginLeft: '35%', textAlign: 'center' }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    icon={<EyeOutlined />}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>&nbsp;
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    onClick={exportdata} icon={< ExportOutlined />}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title="Statutory Mapping - Bulk Upload Report"
                                className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                footer={false}
                                style={{overflow: 'hidden'}}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country:&nbsp;</b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        name="country"
                                                        id="country"
                                                        mode="multiple"
                                                        allowClear={true}
                                                        size="default"
                                                        value={all.country || undefined}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Country"
                                                        onChange={(option, data) => HandelChange(option, data)}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {Country && Country.length > 0 && Country.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        unitdata.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Please Enter Country',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Domain:&nbsp; <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                mode="multiple"
                                                allowClear={true}
                                                size="default"
                                                name="domain"
                                                id="domain"
                                                value={all.domain || undefined}
                                                disabled={unitdata.country == '' ? true : false}
                                                placeholder="Enter Domain Name"
                                                onChange={(option, data) => HandelChangeDomain(option, data)}
                                                maxTagCount='responsive'
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                            >
                                                {domain && domain.length > 0 && domain.map((item, i) => {
                                                    return (
                                                        <Option key={item.domain_id}>
                                                            {item.domain_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                unitdata.domain_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter Domain',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>From Date:&nbsp; <span style={{ color: "red" }}>*</span></label>
                                            <DatePicker getPopupContainer={trigger => trigger.parentNode} format={'DD-MMM-YYYY'} placeholder="DD-MM-YYYY" style={{ width: '100%' }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                onChange={(date, dateString) => {
                                                    if (dateString != '') {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: ''
                                                        })
                                                    }
                                                    else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    }
                                                }}
                                                disabledDate={(current) => {
                                                    return moment().add(0, 'days') <= current
                                                }}
                                                onKeyDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                            />
                                            {validator.current.message(
                                                'from_date',
                                                unitdata.from_date,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter From Date',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "10px" }}>
                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <label><b>To Date:&nbsp; </b><span style={{ color: "red" }}>*</span></label>
                                            <DatePicker getPopupContainer={trigger => trigger.parentNode} format={'DD-MMM-YYYY'} placeholder="DD-MM-YYYY" style={{ width: '100%' }} value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                disabled={unitdata.from_date == '' ? true : false}
                                                onChange={(date, dateString) => {
                                                    setUnitData({
                                                        ...unitdata,
                                                        to_date: dateString
                                                    })
                                                }}
                                                disabledDate={(current) => {
                                                    return moment(unitdata.from_date).add(0, 'days') >= current ||
                                                        moment().add(0, 'days') <= current;
                                                }}
                                                onKeyDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                            />
                                            {validator.current.message(
                                                'to_date',
                                                unitdata.to_date,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter To Date',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Upload Type:&nbsp;</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        // value={all.country || undefined}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Upload Type"
                                                        onChange={(data) => {
                                                            if (data) {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    uploadtype: data
                                                                })
                                                            } else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    uploadtype: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Option key='0'>{"Statutory Mapping"}</Option>
                                                        <Option key='1'>{"Approved, Approved & Notified"}</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>KE Name:&nbsp;</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    {usergroupname && usergroupname.length > 0 && usergroupname[0].employee_code}-
                                                    {usergroupname && usergroupname.length > 0 && usergroupname[0].employee_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12' style={{ marginTop: "10px", marginLeft: '35%', textAlign: 'center' }}>
                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                icon={<EyeOutlined />}
                                                onClick={onshow}
                                            >
                                                Show
                                            </Button>&nbsp;
                                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                onClick={exportdata} icon={< ExportOutlined />}
                                            >
                                                Export
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Modal>

                            {tableshow == true ?
                                <>
                                    <div className='container-fluid pt-0 service-provider-table'>
                                        <Table
                                            locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                                            columns={columns}
                                            size={'small'}
                                            bordered
                                            dataSource={reportdata.reportdata}
                                            pagination={false}
                                        />
                                        {reportdata && reportdata.reportdata && reportdata.reportdata.length > 0 ?
                                            <Pagination style={{ "margin-top": "10px", textAlign: "right" }}
                                                current={current}
                                                showSizeChanger={true}
                                                showQuickJumper={false}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                }}
                                                pageSizeOptions={[25, 50, 100]}
                                                hideOnSinglePage={false}
                                                defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                    setPage(page);
                                                }} /> : ''}
                                    </div>
                                </> : false}
                        </div>
                    </div>
                </div>
            </div >
        </div>

    )
}
export default connect(mapStateToProps, {
    BulkUploadConstants,
    Users,
    SMBulkReportData,
    ExportBulkReportData
})(StatutoryMappingBulkReport);