import { FULL_SCREEN } from "../Store/types"

export const fullscreenmode = (dispatch) => {
    dispatch({
        type: FULL_SCREEN,
        payload: true
    })
    sessionStorage.setItem('isFullScreen', 'true')
    localStorage.setItem('isFullScreen', 'true')
    let topBar = document.getElementById('top-nav')
    let pageWrapper = document.getElementById('page-wrapper')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let pageTitles = document.getElementsByClassName('page-title-sticky');
    let pageTitles1 = document.getElementsByClassName('page-title-sticky1');
    let filterDetails = document.getElementsByClassName('filter-detial-sticky');
    console.log(filterDetails, 'filterDetails')
    let theadSticks = document.getElementsByClassName('table-head-stick');
    let conditionClass = document.getElementsByClassName('rounded-3')
    let serviceProviderTable = document.querySelector('.service-provider-table .ant-table-content .ant-table-thead')
    let serviceProviderTable1 = document.querySelector('.service-provider-table1 .ant-table-content .ant-table-thead')
    let serviceProviderTable2 = document.querySelector('.service-provider-table-statutory .ant-table-content .ant-table-thead')

    let unitDetailBox = document.getElementById('unit-detail-box')
    let compApprovThead = document.getElementById('comp-approv-thead')
    let leftnone = leftbar[0];
    let pageTitle = pageTitles[0];
    let pageTitle1 = pageTitles1[0];
    let filterDetail = filterDetails[0];
    let theadStick = theadSticks[0];
    let Condition = conditionClass[0];
    document.documentElement.requestFullscreen();

    if (serviceProviderTable != null) {
        serviceProviderTable.style.top = '40px'
    }
    if (serviceProviderTable1 != null) {
        serviceProviderTable1.style.top = '40px'
    }
    if (serviceProviderTable2 != null) {
        serviceProviderTable2.style.top = '40px'
    }

    topBar.style.display = 'none'
    pageWrapper.style.paddingTop = '0'
    pageWrapper.style.marginLeft = '0'
    leftnone.style.display = 'none'
    let temp = filterDetail && filterDetail.getAttribute('class')
    let Conditiontemp = Condition && Condition.getAttribute('class')
    const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
    if (unitDetailBox) {
        console.log('unitDetailBox', unitDetailBox)
        compApprovThead.style.top = 0
    }

    const myArr = temp && temp.match("extraFilter");
    console.log(pageTitle, 'pageTitlepageTitle')
    if (pageTitle != undefined) {
        pageTitle.style.top = '0'
    }
    if (pageTitle1 != undefined) {
        pageTitle1.style.top = '0'
    }
    if (filterDetail != undefined && theadStick != undefined) {
        filterDetail.style.top = '44px'
        theadStick.style.top = '82px'
    }
    if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
        console.log(filterDetail, 'filterDetail')
        theadStick.style.top = '44px'
    }
    if (myArr && myArr[0] === 'extraFilter') {
        theadStick.style.top = '104px'
    }

}

export const fullscreenexit = (dispatch) => {
    dispatch({
        type: FULL_SCREEN,
        payload: false
    })

    let topBar = document.getElementById('top-nav')
    let pageWrapper = document.getElementById('page-wrapper')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let pageTitles = document.getElementsByClassName('page-title-sticky');
    let pageTitles1 = document.getElementsByClassName('page-title-sticky1');
    let filterDetails = document.getElementsByClassName('filter-detial-sticky');
    let theadSticks = document.getElementsByClassName('table-head-stick');
    let conditionClass = document.getElementsByClassName('rounded-3')
    let serviceProviderTable = document.querySelector('.service-provider-table .ant-table-content .ant-table-thead')
    let serviceProviderTable1 = document.querySelector('.service-provider-table1 .ant-table-content .ant-table-thead')
    let serviceProviderTable2 = document.querySelector('.service-provider-table-statutory .ant-table-content .ant-table-thead')

    let leftnone = leftbar[0];
    let pageTitle = pageTitles[0];
    let pageTitle1 = pageTitles1[0];
    let filterDetail = filterDetails[0];
    let theadStick = theadSticks[0];
    let Condition = conditionClass[0];
    document.exitFullscreen();
    if (serviceProviderTable != null) {
        serviceProviderTable.style.top = '108px'
    }
    if (serviceProviderTable1 != null) {
        serviceProviderTable1.style.top = '108px'
    }
    if (serviceProviderTable2 != null) {
        serviceProviderTable2.style.top = '108px'
    }

    topBar.style.display = 'block'
    pageWrapper.style.paddingTop = '70px'
    pageWrapper.style.marginLeft = '65px'
    leftnone.style.display = 'block'
    let temp = filterDetail && filterDetail.getAttribute('class')
    let Conditiontemp = Condition && Condition.getAttribute('class')
    const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
    const myArr = temp && temp.match("extraFilter");
    if (pageTitle != undefined) {
        pageTitle.style.top = '69px'
    }
    if (pageTitle1 != undefined) {
        pageTitle1.style.top = '69px'
    }
    if (filterDetail != undefined && theadStick != undefined) {
        pageTitle.style.top = '69px'
        // pageTitle1.style.top = '69px'
        filterDetail.style.top = '113px'
        theadStick.style.top = '154px'
    }
    if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
        theadStick.style.top = '113px'
    }
    if (myArr && myArr[0] === 'extraFilter') {
        theadStick.style.top = '173px'
    }


}

export const handleKeyDown = (dispatch) => {
    if (!document.webkitIsFullScreen && !document.fullscreenElement) {
        dispatch({
            type: FULL_SCREEN,
            payload: false
        })

        let topBar = document.getElementById('top-nav')
        let pageWrapper = document.getElementById('page-wrapper')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let pageTitles = document.getElementsByClassName('page-title-sticky');
        let pageTitles1 = document.getElementsByClassName('page-title-sticky1');
        let filterDetails = document.getElementsByClassName('filter-detial-sticky');
        let theadSticks = document.getElementsByClassName('table-head-stick');
        let conditionClass = document.getElementsByClassName('rounded-3')
        let serviceProviderTable = document.querySelector('.service-provider-table .ant-table-content .ant-table-thead')
        let serviceProviderTable1 = document.querySelector('.service-provider-table1 .ant-table-content .ant-table-thead')
        let serviceProviderTable2 = document.querySelector('.service-provider-table-statutory .ant-table-content .ant-table-thead')

        let leftnone = leftbar[0];
        let pageTitle = pageTitles[0];
        let pageTitle1 = pageTitles1[0];
        let filterDetail = filterDetails[0];
        let theadStick = theadSticks[0];
        let Condition = conditionClass[0];
        let temp = filterDetail && filterDetail.getAttribute('class')
        let Conditiontemp = Condition && Condition.getAttribute('class')
        const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
        const myArr = temp && temp.match("extraFilter");

        if (serviceProviderTable != null) {
            serviceProviderTable.style.top = '108px'
        }
        if (serviceProviderTable1 != null) {
            serviceProviderTable1.style.top = '108px'
        }
        if (serviceProviderTable2 != null) {
            serviceProviderTable2.style.top = '108px'
        }
        document.webkitExitFullscreen();
        topBar.style.display = 'block'
        pageWrapper.style.paddingTop = '70px'
        pageWrapper.style.marginLeft = '65px'
        leftnone.style.display = 'block'
        if (pageTitle != undefined) {
            pageTitle.style.top = '69px'
        }
        if (pageTitle1 != undefined) {
            pageTitle1.style.top = '69px'
        }
        if (filterDetail != undefined && theadStick != undefined) {
            filterDetail.style.top = '113px'
            theadStick.style.top = '154px'
        }
        if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
            theadStick.style.top = '113px'
        }
        if (myArr && myArr[0] === 'extraFilter') {
            theadStick.style.top = '173px'
        }
    }

};


export const setFocus = (ref) => {
    setTimeout(() => {
        ref.current.focus();
    });
}