import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal, Empty } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import ApproveStatutoryBulkuploadview from './Approvestat_bulk_view'
import {
    Domainlist, ExeList, bulkuploadList, approveupload, rejectupload,
    approvealive, rejectalive, viewupload,getqueuedtask
} from '../../Store/Action/Transactions/ApproveStatutoryBulk'
import { update } from 'lodash';
import {
    DownloadOutlined, EditOutlined, DislikeOutlined, LikeOutlined, CloseCircleOutlined,
    PlayCircleOutlined, SearchOutlined, EyeOutlined, FilterOutlined, FullscreenOutlined, SyncOutlined
} from "@ant-design/icons";
import URL from '../../Libs/URL'
import Swal from 'sweetalert2';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";


const _ = require("lodash");


const mapStateToProps = (state) => ({
    domainlist: state.ApproveStatotoryBulkupload.approvebulkupload.domaindetails,
    exelist: state.ApproveStatotoryBulkupload.approvebulkupload.exedetails,
    list: state.ApproveStatotoryBulkupload.approvebulkupload.list.pending_csv_list,
    success: state.ApproveStatotoryBulkupload.approvebulkupload.ressuccess,
    reject_sta: state.ApproveStatotoryBulkupload.approvebulkupload.rejectresponse

})
const ApproveStatutoryBulkupload = ({
    Domainlist,
    ExeList,
    domainlist,
    exelist,
    list,
    bulkuploadList,
    approveupload,
    rejectupload,
    success,
    reject_sta,
    approvealive,
    rejectalive,
    viewupload,
    getqueuedtask

}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [showlist, setshowlist] = useState(false);
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [currentpage, setcurrentpage] = useState('list');
    const [searchdata, Setsearchdata] = useState({
        country: '',
        countrydata: '',
        doamindata: '',
        domain: '',
        userdata: '',
        user: '',
        userid: ''
    })
    const [password, setpassword] = useState({
        passwordvalue: ''
    });
    const [remarks, setremarks] = useState({
        remarksvalue: '',
        password: ''
    })
    const [icondisplay, seticondisplay] = useState(0);
    const [approveform, setapproveform] = useState(false);
    const [rejectform, setrejectform] = useState(false);
    const [approvedata, setapprovedata] = useState();
    const [rejectdata, setrejectdata] = useState();
    const [csvid, setcsvid] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);


    // const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
    //     setSelectedKeys(e.target.value ? [e.target.value] : [])
    //     confirm({ closeDropdown: false });
    //     // setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                        handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '30px',
            render: (text, record, index) => {
                return <> {index + 1} {record && record.is_amendment == 0 ?
                    <Tooltip title={record.compliance_frequency}><p><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip> :
                    <Tooltip title={record.compliance_frequency}><p><i style={{ color: "yellow" }} class="bi bi-square-fill"></i></p></Tooltip>
                }</>
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            width: '130px',
            key: 'csv_name',
            ...getColumnSearchProps('csv_name', 'Uploaded File Name'),
            ellipsis: true,
            render: (record) => {
                let ab = record.split('_');
                let display = ab[0] + '_' + ab[1]
                return (
                    <p title={display}>{ab[0]}_{ab[1]}</p>
                )
            }
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            width: '90px',
            ...getColumnSearchProps('uploaded_on', 'Uploaded On'),
            key: 'uploaded_on',
            ellipsis: true,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: '110px',
            ...getColumnSearchProps('uploaded_by', 'Uploaded By'),
            align: 'center',
            // ellipsis: true,
            render: (record) => {
                return (
                    <span>{exelist && exelist.k_executive_info[0].emp_code_name}</span>
                )
            }
        },
        {
            title: 'No. of records',
            dataIndex: 'no_of_records',
            width: '60px',
            key: 'no_of_records',
            ...getColumnSearchProps('no_of_records', 'No. of records'),
            align: 'center',

        },
        {
            title: 'Approved / Rejected',
            dataIndex: 'approve_count',
            key: 'approve_count',
            width: '60px',
            align: 'center',
            render: (text, record) => {
                return <span>{record.approve_count}/{record.rej_count}</span>

            },
        },
        {
            title: 'Download',
            align: 'center',
            width: '60px',
            render: (text, record, index) => {
                return (
                    <>
                        {record.file_submit_status == 0 ?
                            <>
                                <span
                                    className="btn btn-sm btn-light-primary text-primary btn-circle d-flex align-items-center 
                          justify-content-center" style={{ marginLeft: '24px' }}
                                    onClick={() => {
                                        if (icondisplay == 0) {
                                            var id = document.getElementById('download_' + index).style.display = 'block'
                                            seticondisplay(1)
                                        } else {
                                            var id = document.getElementById('download_' + index).style.display = 'none'
                                            seticondisplay(0)
                                        }

                                    }}><DownloadOutlined /></span>

                                <span id={'download_' + index} style={{ display: 'none' }}>
                                    <div>
                                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('excel', record.csv_name) }}>Download Excel/</span><br />
                                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('csv', record.csv_name) }}>Download CSV/</span><br />
                                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('ods', record.csv_name) }}>Download ODS/</span><br />
                                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('text', record.csv_name) }}>Download Text</span>
                                    </div>
                                </span></> : ''}
                    </>
                );
            }
        },
        {
            title: 'Task Wise Action',
            dataIndex: 'c_by',
            key: 'c_by',
            width: '65px',
            align: 'center',
            render: (text, record) => {
                if (record.file_submit_status == 0) {
                    if (record.approve_count >= 1) {
                        return <span><Tooltip
                            className="toolTipClass"
                            // placement="topRight"
                            title={"Click here to Edit"}>
                            <EditOutlined onClick={() => { showview(record) }} />
                        </Tooltip></span>
                    } else {
                        if (record.rej_count >= 1) {
                            return <span><Tooltip
                                className="toolTipClass"
                                // placement="topRight"
                                title={"Click here to Edit"}>
                                <EditOutlined onClick={() => { showview(record) }} />
                            </Tooltip></span>
                        } else {
                            return <Tooltip
                                className="toolTipClass"
                                // placement="topRight"
                                title={"Click here to View"}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    className='addbutton' onClick={() => { showview(record) }}>
                                    View
                                </Button></Tooltip>
                        }
                    }
                }
            },

        },
        {
            title: 'Approve',
            align: 'center',
            width: "50px",
            render: (text, record) => {
                if (record.file_submit_status == 0) {
                    return <span className="btn btn-sm btn-light-success
                text-success btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '24px' }} onClick={() => {
                            setapproveform(true);
                            setapprovedata(record);
                        }}>
                        <Tooltip
                            className="toolTipClass"
                            // placement="topRight"
                            title={"Click here to approve"}>
                            <LikeOutlined />
                        </Tooltip>
                    </span>
                }
            },
        },
        {
            title: 'Reject',
            ellipsis: true,
            width: "50px",
            align: 'center',
            render: (text, record) => {
                if (record.file_submit_status == 0) {
                    return <span className="btn btn-sm btn-light-danger
                text-danger btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '24px' }} onClick={() => {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Alert messsage!',
                                text: 'This Option will reject all the tasks. To reject, selected task you may choose"View Option". Do you want to proceed',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setrejectform(true);
                                    setapprovedata(record);
                                }
                            })
                        }}>
                        <Tooltip
                            className="toolTipClass"
                            // placement="topRight"
                            title={"Click here to reject"}>
                            <DislikeOutlined />
                        </Tooltip></span>
                }
            },
        },
        {
            title: 'Queued Documents',
            align: 'center',
            width: '7%',
            render: (text, record) => {
                if (record.file_submit_status == 2) {
                    return <span className="btn btn-sm btn-light-info
                    text-info btn-circle d-flex align-items-center 
                    justify-content-center" style={{ marginLeft: '15px' }}>
                        <Tooltip title='click here to upload queued docs' placement='topLeft'>
                            <SyncOutlined onClick={() => {
                                queueddocs(record)
                            }} />
                        </Tooltip></span>
                }else if(record.file_submit_status == 3){
                    return <span style={{color:'blue'}}>In Progress</span>  
                }else{
                    return <span></span>
                }
            },
        },
    ];



    const downloadfile = (e, id) => {
        const expression = id.split('.')
        let site;
        if (e == 'csv') {
            window.open(URL.CommonURL + '/uploaded_file/csv/' + id);
        } else if (e == 'excel') {
            window.open(URL.CommonURL + '/uploaded_file/xlsx/' + expression[0] + '.' + 'xlsx');
        } else if (e == 'ods') {
            window.open(URL.CommonURL + '/uploaded_file/ods/' + expression[0] + '.' + 'ods');
        } else {
            window.open(URL.CommonURL + '/uploaded_file/txt/' + expression[0] + '.' + 'txt', '');
        }

    }

    const showview = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveStatutoryMappingView",
                {
                    "csv_id": record.csv_id,
                    "f_count": 0,
                    "r_range": 250
                }
            ]
        }

        viewupload({
            payload: pay,
            caller: paramid
        })
        setcsvid(record.csv_id);
        setcurrentpage('view');
    }

    const queueddocs = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "DocumentQueueProcess",
                {
                    "csv_id": record.csv_id,
                    "bu_action": 1,
                    "c_id": parseInt(searchdata.country),
                    "d_id": parseInt(searchdata.domain)
                }
            ]
        }

        getqueuedtask({
            payload: pay,
            caller: paramid
        })
    }

    useEffect(() => {
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetDomains",
                    {}
                ]
            }
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "GetKExecutiveDetails",
                    {}
                ]
            }

            Domainlist({
                payload: payload,
                caller: paramid,
            })

            ExeList({
                payload: payload2,
                caller: paramid,
            })

        }
    }, [isAuth]);

    useEffect(() => {
        formValidator.current.showMessages();
        formValidator1.current.showMessages();
        formValidator2.current.showMessages();
    }, []);

    useEffect(() => {
        if (domainlist && exelist.k_executive_info) {
            let temp = [];
            let arr = [];

            const cid = exelist.k_executive_info[0]['c_ids']
            const did = exelist.k_executive_info[0]['d_ids']
            const uniqcid = [...new Set(cid)];
            for (let i = 0; i < uniqcid.length; i++) {
                const countryid = _.filter(domainlist && domainlist.bsm_countries, { country_id: uniqcid[i] })
                temp.push(countryid);
            }
            for (let j = 0; j < did.length; j++) {
                const domainid = _.filter(domainlist && domainlist.bsm_domains, { domain_id: did[j] })
                arr.push(domainid);
            }
            Setsearchdata({
                ...searchdata,
                user: exelist && exelist.k_executive_info[0].emp_code_name,
                countrydata: [].concat.apply([], temp),
                doamindata: [].concat.apply([], arr),
                userid: exelist && exelist.k_executive_info[0].user_id
            })

        }
    }, [domainlist, exelist.k_executive_info])

    const searchlist = (e) => {
        e.preventDefault()
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            setshowlist(true)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetApproveStatutoryMappingList",
                    {
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain),
                        "uploaded_by": null
                    }
                ]
            }
            bulkuploadList({
                payload: payload,
                caller: paramid,
            })

        }
    }

    const passwordcancel = () => {
        setapproveform(false);
        setpassword({
            ...password,
            passwordvalue: ''
        });
        setAddFormSubmit1(false);
    }

    const rejectcancel = () => {
        setrejectform(false);
        setremarks({
            ...remarks,
            password: '',
            remarksvalue: ''
        })
        setAddFormSubmit2(false);
    }

    const submitpassword = (e) => {
        e.preventDefault();
        setAddFormSubmit1(true);
        if (formValidator1.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": password.passwordvalue
                    }
                ]
            }
            const pay2 = {
                "session_token": authtoken,
                "request": [
                    "UpdateApproveActionFromList",
                    {
                        "csv_id": approvedata.csv_id,
                        "bu_action": 1,
                        "remarks": null,
                        "password": password.passwordvalue,
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain)
                    }
                ]
            }

            approveupload({
                payload: pay,
                payload2: pay2,
                caller: paramid
            })
            setapproveform(false);
            setAddFormSubmit1(false);
            setpassword({
                ...password,
                passwordvalue: ''
            })

        }

    }


    useEffect(() => {
        if (success && success.csv_name) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetApproveMappingStatus",
                    {
                        "csv_name": success.csv_name
                    }
                ]
            }
            approvealive({
                payload: pay,
                caller: paramid
            })

        }
    }, [success])

    const addremarks = (e) => {
        e.preventDefault();
        setAddFormSubmit2(true);
        if (formValidator2.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": remarks.password
                    }
                ]
            }

            const pay2 = {
                "session_token": authtoken,
                "request": [
                    "UpdateApproveActionFromList",
                    {
                        "csv_id": approvedata && approvedata.csv_id,
                        "bu_action": 1,
                        "remarks": remarks.remarksvalue,
                        "password": remarks.password,
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain)
                    }
                ]
            }

            rejectupload({
                payload: pay,
                payload2: pay2,
                caller: paramid
            })
            setrejectform(false);
            setAddFormSubmit2(false);
            setremarks({
                ...remarks,
                password: '',
                remarksvalue: ''
            })
        }
    }

    useEffect(() => {
        if (reject_sta && reject_sta.csv_name) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetApproveMappingStatus",
                    {
                        "csv_name": reject_sta.csv_name
                    }
                ]
            }
            rejectalive({
                payload: pay,
                caller: paramid
            })
        }
    }, [reject_sta])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };


    // Full Screen Mode
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    return (
        <>
            {currentpage == 'list' ? (
                <div id="page-wrapper" className="page-wrapper">
                     <div className='back-to-top'>
                    <ScrollButton />
                </div>
                    <div className="page-titles pb-1 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Approve - Reject Statutory Mapping -Bulk Upload</span>
                                        </li>
                                    </ol>
                                </nav>

                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                        marginLeft: "5px",
                                        display: filterTaskAcc ? "none" : "inline",
                                    }}
                                    onClick={filterHandle}
                                >
                                    <FilterOutlined /> Filter
                                </Button>
                                <button
                                    onClick={(e) => {
                                        fullscreenMode();
                                    }}
                                    className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                                >
                                    <Tooltip
                                        placement="left"
                                        style={{ marginRight: "10px", }}
                                        title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                    >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body custom-collapse" style={{ padding: " 5px" }}>
                                    <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}  >
                                        <Panel header="Approve/Reject Statutory Mapping - Bulk Upload" key={1}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='country'
                                                                placeholder="Enter Country"
                                                                autoFocus={true}
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                value={searchdata.country_name || undefined}
                                                                onChange={(data, value) => {
                                                                    if (value) {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            country: data,
                                                                            country_name: value.children,
                                                                            domain: '',
                                                                            user: ''
                                                                        })
                                                                    } else {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            country: '',
                                                                            country_name: '',
                                                                            domain: '',
                                                                            user: ''
                                                                        })
                                                                    }
                                                                }}>
                                                                {searchdata && searchdata.countrydata && searchdata.countrydata.map((item, i) => {
                                                                    return <Option key={item.country_id} value={item.country_id}>{item.country_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'country',
                                                                searchdata.country,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain :</b> <span style={{ color: "red" }}> *</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='domain'
                                                                placeholder="Enter Domain Name"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                disabled={searchdata.country == '' ? true : false}
                                                                value={searchdata.domain || undefined}

                                                                onChange={((data, value) => {
                                                                    console.log('value', value);
                                                                    console.log('exe', exelist);
                                                                    if (value) {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            domain: value.value,
                                                                            userdata: ''
                                                                        })
                                                                    } else {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            domain: '',
                                                                            userdata: ''
                                                                        })
                                                                    }
                                                                })}>
                                                                {searchdata.doamindata && searchdata.doamindata.map((data) => {
                                                                    return <Option key={data.domain_id}>{data.domain_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'domain',
                                                                searchdata.domain,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>User :</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Organization"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                disabled={searchdata.domain == '' ? true : false}
                                                                value={searchdata.userdata || undefined}

                                                                onChange={((data, value) => {
                                                                    if (value) {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            userdata: value.value
                                                                        })
                                                                    } else {
                                                                        Setsearchdata({
                                                                            ...searchdata,
                                                                            userdata: ''
                                                                        })
                                                                    }
                                                                })}>
                                                                    {exelist.k_executive_info && exelist.k_executive_info.map(item => {
                                                                        let country = item.c_ids.includes(parseInt(searchdata.country))
                                                                        let domain = item.d_ids.includes(parseInt(searchdata.domain))
                                                                        if (country && domain) {
                                                                            return <Option key={item.user_id}>{item.emp_code_name}</Option>
                                                                            
                                                                        }
                                                                    })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            className='addbutton'
                                                            onClick={searchlist}
                                                            icon={<EyeOutlined />}>
                                                            Show
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Panel>
                                    </Collapse>
                                    {showlist == true ?
                                        <>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className='d-flex text-center'>
                                                        <i style={{ color: "green" }} class="bi bi-square-fill me-2"></i><p>{"Statutoy mapping Bulk Upload tasks"}</p>&nbsp;&nbsp;
                                                        <i style={{ color: "yellow" }} class="bi bi-square-fill me-2"></i><span>{"Approve, Approve & Notify Tasks"}</span>
                                                    </div>
                                                    <Table
                                                        locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                                                        className='userprivclass'
                                                        columns={columns}
                                                        dataSource={list && list}
                                                        bordered
                                                        // scroll={{ x: 1300 }}
                                                        pagination={false}
                                                    />
                                                </div>
                                            </div>


                                        </>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        title="Approve Statutory Mapping"
                        className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}
                        footer={false}
                        visible={filterModalVisible}
                        onCancel={setcancelFilter}
                       
                    >

                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                    <div className="form-group">
                                        <Select
                                            allowClear={true}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            size="default"
                                            name='country'
                                            placeholder="Enter Country"
                                            showSearch
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%' }}
                                            value={searchdata.country_name || undefined}

                                            onChange={(data, value) => {
                                                if (value) {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        country: data,
                                                        country_name: value.children,
                                                        domain: '',
                                                        domain_name: ''
                                                    })
                                                } else {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        country: '',
                                                        country_name: '',
                                                        domain: '',
                                                        domain_name: ''
                                                    })
                                                }
                                            }}>
                                            {searchdata && searchdata.countrydata && searchdata.countrydata.map((item, i) => {
                                                return <Option key={item.country_id}>{item.country_name}</Option>
                                            })}
                                        </Select>
                                        {formValidator.current.message(
                                            'country',
                                            searchdata.country,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Country Name Required'
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <label><b>Domain :</b> <span style={{ color: "red" }}>  *</span></label>
                                    <div className="form-group">
                                        <Select
                                            allowClear={true}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            size="default"
                                            name='domain'
                                            placeholder="Enter Domain Name"
                                            showSearch
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%' }}
                                            value={searchdata.domain || undefined}

                                            disabled={searchdata.country == '' ? true : false}
                                            onChange={((data, value) => {
                                                if (value) {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        domain: data,
                                                        domain_name: value.children,
                                                        userdata: ''
                                                    })                                                    
                                                } else {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        domain: '',
                                                        domain_name: '',
                                                        userdata: ''
                                                    }) 
                                                }
                                            })}>
                                            {searchdata.doamindata && searchdata.doamindata.map((data) => {
                                                return <Option key={data.domain_id}>{data.domain_name}</Option>
                                            })}
                                        </Select>
                                        {formValidator.current.message(
                                            'domain',
                                            searchdata.domain,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Domain Name Required'
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <label><b>User :</b></label>
                                    <div className="form-group">
                                        <Select
                                            allowClear={true}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            size="default"
                                            placeholder="Enter Organization"
                                            showSearch
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%' }}
                                            disabled={searchdata.domain == '' ? true : false}
                                            value={searchdata.userdata || undefined}
                                            onChange={((data, value) => {
                                                if (value) {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        userdata: value.value
                                                    })                                                    
                                                } else {
                                                    Setsearchdata({
                                                        ...searchdata,
                                                        userdata: ''
                                                    })
                                                }
                                            })}>
                                            {exelist.k_executive_info && exelist.k_executive_info.map(item => {
                                                let country = item.c_ids.includes(parseInt(searchdata.country));
                                                let domain = item.d_ids.includes(parseInt(searchdata.domain));
                                                if (country && domain) {
                                                    return <Option key={item.user_id}>{item.emp_code_name}</Option>;

                                                }
                                            })}
                                            {/* <Option key={searchdata.user}>{searchdata.user}</Option> */}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                            <div className='row'>
                                <div className="col-md-6">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className='addbutton custom-color-modal-button'
                                        onClick={searchlist}
                                        icon={<EyeOutlined />}>
                                        Show
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal visible={approveform} className={"newStyleModalPassword custom-color-modal "+ localStorage.getItem('currentTheme')} title="Enter Password" footer={null} onCancel={passwordcancel} destroyOnClose={true}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-6">
                                    <label className="control-label"> <b>Password :</b><span style={{ "color": "red" }}>*</span></label>
                                    <input
                                        type="password"
                                        id='passwordvalue'
                                        name='passwordvalue'
                                        autoComplete={'new-password'}
                                        maxLength={20}
                                        placeholder="Enter password"
                                        style={{ marginTop: "5px", width: '100%' }}
                                        className="form-control"
                                        onChange={(e) => {
                                            setpassword({
                                                ...password,
                                                passwordvalue: e.target.value
                                            })
                                        }}
                                        value={password.passwordvalue}
                                    />
                                    {formValidator1.current.message(
                                        'passwordvalue',
                                        password.passwordvalue,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Password Required'
                                            }
                                        })}
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className="form-actions">
                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                style={{ marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default' onClick={submitpassword}>
                                Submit
                            </Button>
                        </div>
                    </Modal>

                    <Modal visible={rejectform} className={'usr_modal_class custom-color-modal '+ localStorage.getItem('currentTheme')} footer={null} title="Enter Password"
                        onCancel={rejectcancel} destroyOnClose={true}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor=""><b>Password :</b> <span style={{ "color": "red" }}>*</span></label>
                                <input
                                    type="password"
                                    id="passwordvalue"
                                    name='passwordvalue'
                                    className="form-control"
                                    placeholder="Enter password"
                                    autoComplete='new-password'
                                    value={remarks.password}
                                    onChange={(e) => {
                                        setremarks({
                                            ...remarks,
                                            password: e.target.value
                                        })
                                    }} />
                                {formValidator2.current.message(
                                    'passwordvalue',
                                    remarks.password,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Password Required'
                                        }
                                    })}
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            <label htmlFor=""><b>Reason :</b> <span style={{ "color": "red" }}>*</span></label>
                            <textarea className='form-control' name='remark'
                                row='1' cols='4' style={{ resize: 'vertical' }}
                                placeholder="Enter Reason"
                                value={remarks.remarksvalue}
                                onChange={(e) => {
                                    setremarks({
                                        ...remarks,
                                        remarksvalue: e.target.value
                                    })
                                }}>
                            </textarea>
                            {formValidator2.current.message(
                                'remark',
                                remarks.remarksvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Reason Required'
                                    }
                                })}
                        </div>
                        <br />
                        <div className="form-actions">
                            <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                style={{ marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default' onClick={addremarks}

                            >
                                Submit
                            </Button>
                        </div>
                    </Modal>
                </div>
            ) : <ApproveStatutoryBulkuploadview csvid={csvid} />}
        </>
    )

}
export default connect(mapStateToProps, {
    Domainlist,
    ExeList,
    bulkuploadList,
    approveupload,
    rejectupload,
    approvealive,
    rejectalive,
    viewupload,
    getqueuedtask
})(ApproveStatutoryBulkupload);