import { REJECT_CLIENT_UNIT_DATA, UNITS_UPLOADED_CSV, TECHNO_DETAIL } from '../../types/index'

const initialState = {
    clientunit: {
        tabledata: '',
        techdetail: '',
        viewdata: ''
    }
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REJECT_CLIENT_UNIT_DATA:
            {
                return {
                    ...state,
                    clientunit: {
                        ...state.clientunit,
                        tabledata: payload,
                    },
                };
            }
        case TECHNO_DETAIL:
            {
                return {
                    ...state,
                    clientunit: {
                        ...state.clientunit,
                        techdetail: payload,
                    },
                };
            }
        case UNITS_UPLOADED_CSV:
            {
                return {
                    ...state,
                    clientunit: {
                        ...state.clientunit,
                        viewdata: payload,
                    },
                };
            }
        default:
            return state;
    }
}