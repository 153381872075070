import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { InfoCircleOutlined, EyeOutlined, CloseCircleOutlined, SearchOutlined, FullscreenOutlined } from "@ant-design/icons";
import { approveassignfilter, approveassignlegaldomainfilter, approveassignuserfilter, assignedStatutoriesForApprove, getAssignedStatutoriesToApprove } from '../../Store/Action/Transactions/Approveassignedstatutory'
import Approveassignedstatutoryview from './Approveassignedstatutoryview';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";
const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.Approveassignedstatutory.approveassignedstatutory.approveassignfilter,
    legaldomainlist: state.Approveassignedstatutory.approveassignedstatutory.approveassignlegaldomainfilter,
    userlist: state.Approveassignedstatutory.approveassignedstatutory.approveassignuserfilter,
    approvedStatutoryList: state.Approveassignedstatutory.approveassignedstatutory.approveAssignedStatutoriesList.assigned_statutories_approve,
})
const Approveassignedstatutory = ({
    approveassignfilter,
    list,
    approveassignlegaldomainfilter,
    legaldomainlist,
    approveassignuserfilter,
    userlist,
    assignedStatutoriesForApprove,
    approvedStatutoryList,
    getAssignedStatutoriesToApprove,

}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const { Panel } = Collapse;
    const { Option } = Select;
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [showlist, setshowlist] = useState(false);
    const [modalVisible, setmodalVisible] = useState(false);
    const [view, setView] = useState(true);
    const [fullscreen, setfullscreen] = useState(false);

    const [searchdata, setsearchdata] = useState({
        group: '',
        groupdetails: '',
        legalid: '',
        legaldata: '',
        product: '',
        productdata: '',
        domaindata: [],
        domainid: '',
        domaindetails: '',
        unitid: '',
        unitdetails: '',
        unitdata: [],
        userid: '',
        userdetails: '',
        userData: []
    });
    console.log(searchdata, 'searchdata')

    useEffect(() => {
        formValidator.current.showMessages();
        // approvedStatutoryList && setshowlist(true);
    }, []);

    const searchlist = () => {
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            // console.log(searchdata, 'data');
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoriesForApprove",
                    {
                        "c_id": parseInt(searchdata.group),
                        "le_id": parseInt(searchdata.legalid),
                        "d_id": parseInt(searchdata.domainid),
                        // "u_id": searchdata.unitid,
                        // "user_id": userlist.de_users[0].user_id
                        "u_id": 0,
                        "user_id": 0
                    }
                ]
            }

            assignedStatutoriesForApprove({
                payload: pay,
                caller: paramid
            })

            setshowlist(true)
        }
    }

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetApproveAssignedStatutoryFilters",
                    {}
                ]
            }
            approveassignfilter({
                payload: pay,
                caller: paramid
            })
        }
    }, [isAuth])

    const getlegaldomaindetails = (data) => {

        if (data != undefined) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "ApproveAssignStatutoryClientDetails",
                    {
                        "ct_id": parseInt(data)
                    }
                ]
            }
            approveassignlegaldomainfilter({
                payload: payload,
                caller: paramid
            })
        } else {
            setsearchdata({
                ...searchdata,
                group: '',
                groupdetails: '',
                legalid: '',
                legaldata: '',
                domaindata: [],
                domainid: '',
                domaindetails: '',
                unitdata: [],
                unitdetails: '',
                unitid: '',
                userid: '',
            })
            setshowlist(false)
        }

    }

    const getdomaindetails = (data, value) => {
        if (data != undefined) {
            let templist = []
            let tempdomainarray = []
            let getFinalDomain = _.filter(legaldomainlist.dms, { legal_entity_id: parseInt(data) })
            console.log(getFinalDomain, 'getFinalDomain')
            setsearchdata({
                ...searchdata,
                legalid: data,
                legaldata: value,
                domaindata: getFinalDomain,
                unitdata: [],
                unitdetails: '',
                unitid: ''
            })
        } else {
            setsearchdata({
                ...searchdata,
                domainid: '',
                domaindetails: '',
                domaindata: '',
                legalid: '',
                legaldata: '',
                unitdata: [],
                unitdetails: '',
                unitid: ''
            })
            setshowlist(false)
        }
    }

    const getunitdetails = (data, value) => {
        if (data != undefined) {
            let domainArray = [];
            let tempArray = [];
            legaldomainlist.uts && legaldomainlist.uts.length > 0 && legaldomainlist.uts.map((unitlist) => {
                const checkDomainExists = unitlist.d_ids.includes(parseInt(data));
                if (checkDomainExists) {
                    tempArray = {
                        "legal_entity_id": unitlist.legal_entity_id,
                        "unit_id": unitlist.unit_id,
                        "unit_name": unitlist.unit_name,
                        "d_ids": unitlist.d_ids
                    }
                    domainArray.push(tempArray)
                }
            })

            const payload = {
                "session_token": authtoken,
                "request": [
                    "ApproveAssignStatutoryDomainUsersDetails",
                    {
                        "client_id": parseInt(searchdata.group),
                        "legal_entity_id": parseInt(searchdata.legalid),
                        "domain_id": parseInt(data),
                        "unit_id": searchdata.unitid,
                        "user_id": searchdata.userid
                    }
                ]
            }
            approveassignuserfilter({
                payload: payload,
                caller: paramid
            })

            setsearchdata({
                ...searchdata,
                domainid: data,
                domaindetails: value,
                unitdata: domainArray,
                // unitid: tempArray.unit_id                
            })
        } else {
            setsearchdata({
                ...searchdata,
                domainid: '',
                domaindetails: '',
                unitdata: '',
                unitid: '',
                unitdetails: '',
                userid: '',
                userdetails: ''
            })

        }
        setshowlist(false)
    }

    useEffect(() => {
        if (searchdata.userData.length == 0) {
            setsearchdata({
                ...searchdata,
                userData: userlist.de_users && userlist.de_users.length > 0 ? userlist.de_users : []
            })
        }
    }, [userlist.de_users])

    const getUsersDetails = (data, value) => {
        if (data != undefined) {
            let userArray = [];
            let tempArray = [];
            userlist.de_users && userlist.de_users.length > 0 && userlist.de_users.map((userLists) => {
                const checkUnitExists = userLists.user_units.includes(parseInt(data));
                if (checkUnitExists && userLists.is_active == true) {
                    tempArray = {
                        "user_id": userLists.user_id,
                        "user_category_id": userLists.user_category_id,
                        "employee_name": userLists.employee_name,
                        "is_active": userLists.is_active,
                        'user_units': userLists.user_units
                    }
                    userArray.push(tempArray)
                }
            })

            setsearchdata({
                ...searchdata,
                userData: userArray,
                unitid: data,
                unitdetails: value
            })

        } else {
            setsearchdata({
                ...searchdata,
                userData: userlist.de_users && userlist.de_users.length > 0 ? userlist.de_users : [],
                unitid: '',
                unitdetails: '',
                userid: '',
                userdetails: ''
            })
        }
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const serviceProviderRef = useRef(null);
    const pwdRef = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [recordSave, setRecordSave] = useState([])

    const columns = [
        {
            title: "#",
            width: '4%',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: 'Country',
            dataIndex: "c_name",
            key: "c_name",
            ...getColumnSearchProps('c_name', 'Country Name'),
            // ellipsis: true,
            width: '10%',
        },
        {
            title: "Group",
            dataIndex: "grp_name",
            key: "grp_name",
            ...getColumnSearchProps('grp_name', 'Group'),
            // ellipsis: true,
            width: '8%',
        },
        {
            title: "Business Group",
            dataIndex: "b_grp_name",
            key: "b_grp_name",
            ...getColumnSearchProps('b_grp_name', 'Business Group'),
            // ellipsis: true,
            width: '10%',
        },
        {
            title: "Legal Entity",
            dataIndex: "l_e_name",
            key: "l_e_name",
            ...getColumnSearchProps('l_e_name', 'Legal Enitity'),
            // ellipsis: true,
            width: '15%',
        },
        {
            title: "Division",
            dataIndex: "div_name",
            key: "div_name",
            ...getColumnSearchProps('div_name', 'Division'),
            width: '10%',
            align: 'left',
            render: (text, record) => {
                if (text != null) {
                    return text
                } else {
                    return '-'
                }
            }
        },
        {
            title: "Category",
            dataIndex: "cat_name",
            key: "cat_name",
            ...getColumnSearchProps('cat_name', 'Category Name'),
            // ellipsis: true,
            width: '10%',
            render: (text, record) => {
                if (text != null) {
                    return text
                } else {
                    return '-'
                }
            }
        },
        {
            title: "Unit/Vendor",
            dataIndex: "u_name",
            key: "u_name",
            ...getColumnSearchProps('u_name', 'Unit/Vendor'),
            // ellipsis: true,
            width: '10%',
        },
        {
            title: "Domain",
            dataIndex: "d_name",
            key: "d_name",
            ...getColumnSearchProps('d_name', 'Domain'),
            // ellipsis: true,
            width: '10%',
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            ...getColumnSearchProps('product', 'Product'),
            // ellipsis: true,
            width: '5%',
        },
        {
            title: "Domain Executive",
            dataIndex: "employee_name",
            key: "employee_name",
            ...getColumnSearchProps('product', 'Product'),
            // ellipsis: true,
            width: '10%',
        },
        {
            title: "View",
            align: 'center',
            width: '13%',
            render: (text, record) => {
                setRecordSave(record)
                return <span style={{ margin: '0 auto' }} title='Approve Assigned Statutory' className="btn btn-sm btn-light-primary
                text-primary btn-circle d-flex align-items-center justify-content-center" onClick={() => { showviewlist(record) }}>
                    <i className='fas fa-eye'></i>
                </span>
            },
        },
    ]

    const showviewlist = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedStatutoriesToApprove",
                {
                    "d_id": record.d_id,
                    "u_id": record.u_id,
                    "client_statutory_id": record.client_statutory_id,
                    "rcount": 0
                }
            ]
        }

        getAssignedStatutoriesToApprove({
            payload: pay,
            caller: paramid
        })
        setmodalVisible(true)
        setView(false)
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    return (
        <>
            {view ?

                <div className="page-wrapper" id="page-wrapper">
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link>Transactions</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <Link><b>Approve Assigned Statutorys</b></Link>
                                    </li>
                                </ol>
                            </nav> */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transactions</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Approve Assigned Statutory</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center 
                        justify-content-end">

                                <div>
                                    <button onClick={(e) => { fullscreenMode(); }}
                                        className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                        <Tooltip
                                            placement="left"
                                            style={{ marginRight: "10px", }}
                                            title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                        >
                                            <FullscreenOutlined />
                                        </Tooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body custom-collapse" style={{ padding: "1px" }}>
                                    <Collapse defaultActiveKey={["1"]} >
                                        <Panel header="Approve Assigned Statutory" key={1}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Group:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group" style={{ marginTop: '2px' }}>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='clientgroup'
                                                                placeholder="Enter Group"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        group: data == undefined ? '' : data,
                                                                        groupdetails: value == undefined ? '' : value,
                                                                        domainid: '',
                                                                        domaindetails: '',
                                                                        domaindata: '',
                                                                        legalid: '',
                                                                        legaldata: '',
                                                                        unitdata: [],
                                                                        unitdetails: '',
                                                                        unitid: '',
                                                                        userid: ''


                                                                    })
                                                                    getlegaldomaindetails(data)
                                                                }}>
                                                                {list.grps && list.grps.length > 0 && list.grps.map((item, i) => {
                                                                    return <Option key={item.client_id}>{item.group_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'clientgroup',
                                                                searchdata.group,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Group Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group" style={{ marginTop: '2px' }}>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='legalentity'
                                                                placeholder="Enter Legal Entity"
                                                                showSearch
                                                                value={searchdata.legalid || undefined}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                disabled={searchdata.group == '' ? true : false}
                                                                onChange={(data, value) => {
                                                                    getdomaindetails(data, value)
                                                                }}>
                                                                {legaldomainlist.lety && legaldomainlist.lety.length > 0 && legaldomainlist.lety.map((item, i) => {
                                                                    return <Option key={item.legal_entity_id}>{item.legal_entity_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'legalentity',
                                                                searchdata.legalid,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group" style={{ marginTop: '2px' }}>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='Product'
                                                                placeholder="Enter Domain"
                                                                showSearch
                                                                value={searchdata.domainid || undefined}
                                                                disabled={searchdata.legalid == '' ? true : false}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    getunitdetails(data, value)

                                                                }}>
                                                                {searchdata.domaindata && searchdata.domaindata.length > 0 && searchdata.domaindata.map((item, i) => {
                                                                    return <Option key={item.domain_id}>{item.domain_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'Product',
                                                                searchdata.domainid,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4' style={{ marginTop: '10px' }}>
                                                        <label><b>Unit/Vendor Name:</b></label>
                                                        <div className="form-group" style={{ marginTop: '2px' }}>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='unit'
                                                                placeholder="Enter Unit"
                                                                showSearch
                                                                value={searchdata.unitid || undefined}
                                                                disabled={searchdata.domainid == '' ? true : false}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    getUsersDetails(data, value)

                                                                }}>
                                                                {searchdata.unitdata && searchdata.unitdata.length > 0 && searchdata.unitdata.map((item, i) => {
                                                                    return <Option key={item.unit_id}>{item.unit_name}</Option>
                                                                })}
                                                            </Select>
                                                            {/* {formValidator.current.message(
                                                            'Product',
                                                            searchdata.unitid,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Unit Required'
                                                                }
                                                            })} */}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4' style={{ marginTop: '10px' }}>
                                                        <label><b>User:</b> </label>
                                                        <div className="form-group" style={{ marginTop: '2px' }}>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                name='Product'
                                                                placeholder="Enter Domain Executive"
                                                                showSearch
                                                                value={searchdata.userid || undefined}
                                                                disabled={searchdata.domainid == '' ? true : false}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    setsearchdata({
                                                                        ...searchdata,
                                                                        userid: data == undefined ? '' : data,
                                                                        userdetails: value == undefined ? '' : value
                                                                    })
                                                                }}>
                                                                {searchdata.userData && searchdata.userData.length > 0 && searchdata.userData.map((item, i) => {
                                                                    return <Option key={item.user_id}>{item.employee_name}</Option>
                                                                })}
                                                            </Select>
                                                            {/* {formValidator.current.message(
                                                            'Product',
                                                            searchdata.userid,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'User Required'
                                                                }
                                                            })} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            icon={<EyeOutlined />}
                                                            className='addbutton'
                                                            style={{
                                                                marginTop: "20px"
                                                            }}
                                                            onClick={searchlist}
                                                        // onClick={assignedApprovedStatutory}
                                                        >
                                                            Show
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                                {showlist == true ?
                                    <div className='card-body mt-2' style={{ padding: '1px' }} >
                                        <div className="row">
                                            <Table
                                                className='userprivclass'
                                                columns={columns}
                                                dataSource={approvedStatutoryList && approvedStatutoryList}
                                                bordered
                                                pagination={false}
                                            />
                                        </div>

                                    </div>
                                    : ""}

                            </div>
                        </div>
                    </div>
                </div> : <Approveassignedstatutoryview record={recordSave} fullscreen={fullscreen} setfullscreen={setfullscreen} />}
        </>
    )
}
export default connect(mapStateToProps, {
    approveassignfilter,
    approveassignlegaldomainfilter,
    approveassignuserfilter,
    assignedStatutoriesForApprove,
    getAssignedStatutoriesToApprove
})(Approveassignedstatutory);