import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Input, Modal, Card } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ExportOutlined, UserAddOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Filterdata, legalentitydata, Productdata, Tabledata, Exportdata } from '../../Store/Action/Reports/StatutorySettingsReports';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import { STAT_EXPORTDATA_RESET } from './../../Store/types/index'
import FeatherIcon from 'feather-icons-react';
import ScrollButton from "../Scrollbar";


const mapStateToProps = (state) => ({
    filterdatavalue: state.StatutorySettingsRep.clientunitdetails.filter,
    legalentityfilterdata: state.StatutorySettingsRep.clientunitdetails.legelentitydata,
    productvalue: state.StatutorySettingsRep.clientunitdetails.productdatafilter,
    tabledatavalue: state.StatutorySettingsRep.clientunitdetails.tabledata,
    exportdatavalue: state.StatutorySettingsRep.clientunitdetails.exportdata,


})
const StatutorySettingsReport = ({
    Filterdata,
    filterdatavalue,
    legalentitydata,
    legalentityfilterdata,
    Productdata,
    productvalue,
    Tabledata,
    tabledatavalue,
    Exportdata,
    exportdatavalue
}) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [fullscreen, setfullscreen] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [exitCollapse, setExitCollapse] = useState(false);
    const [key, setKey] = useState("1")
    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [page, setPage] = useState(1);
    const [tabledata, setTableData] = useState([])
    const [Actdata, setActdata] = useState([])
    const [count, setCount] = useState([])
    const [paginationdata, setPaginationdata] = useState([])
    const [titledata, Settitledata] = useState()
    let [index, setindex] = useState(0)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [selectdomain, Setselectdomain] = useState("block");
    const [unselectdomain, Setunselectdomain] = useState("none");

    const [filtervalue, setfiltervalue] = useState({
        country: '',
        countryName: '',
        groupname: '',
        groupdata: '',
        legal: '',
        legaldata: "",
        domain: '',
        domaindata: '',
        business: '',
        businessdata: '',
        unit: '',
        unitdata: [],
        act: '',
        product: '',
        compliance_task: '',
        actdata: '',
        legal_name: '',
        group: '',
        bg_name: '',
        domain_name: ''
    })

    // Filters 
    useEffect(() => {
        if (filtervalue.country != '') {
            const groupname = _.filter(filterdatavalue.statutory_groups, { country_id: parseInt(filtervalue.country) })
            setfiltervalue({
                ...filtervalue,
                groupdata: groupname
            })
        }
    }, [filtervalue.country])

    useEffect(() => {
        if (filtervalue.groupname != '') {
            const legalentity = _.filter(filterdatavalue.statutory_business_groups, { client_id: parseInt(filtervalue.groupname) })
            var filtered = legalentity.filter(function (el) {
                return el.business_group_name != null;
            });
            setfiltervalue({
                ...filtervalue,
                legaldata: legalentity,
                businessdata: filtered
            })
        }
    }, [filtervalue.groupname])

    useEffect(() => {
        if (filtervalue.business != '') {
            const legalentity = _.filter(filterdatavalue.statutory_business_groups, { business_group_id: parseInt(filtervalue.business) })
            setfiltervalue({
                ...filtervalue,
                legal: '',
                legaldata: legalentity,
            })
        }
    }, [filtervalue.business])

    const selectedlegalentity = (data) => {
        if (data == undefined) {
            setfiltervalue({
                ...filtervalue,
                legal: '',
                legal_name: '',
                unit: "",
                domain: '',
                act: '',
                compliance_task: '',
                product: ''
            })
        } else {
            setfiltervalue({
                ...filtervalue,
                legal: data.value,
                legal_name: data.children,
                product: ''
            })
        }
    }

    useEffect(() => {
        if (filterdatavalue) {
            console.log(filterdatavalue, 'productdatafilter')
            const domain = _.filter(filterdatavalue.domains, { legal_entity_id: parseInt(filtervalue.legal) })
            setfiltervalue({
                ...filtervalue,
                domaindata: domain
            })
        }
    }, [filterdatavalue])

    useEffect(() => {
        if (productvalue) {
            const unit = _.filter(productvalue.statutory_units, { legal_entity_id: parseInt(filtervalue.legal) })
            const act = _.filter(productvalue.statutory_compliances, { legal_entity_id: parseInt(filtervalue.legal) })
            setfiltervalue({
                ...filtervalue,
                actdata: act,
                unitdata: unit,
                domaindata : productvalue.domains
            })
        }
    }, [productvalue])

    // Validation
    useEffect(() => {
        validator.current.showMessages()
    }, [])

    // Initial payload
    useEffect(() => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedStatutoryReportFilters",
                {}
            ]
        }
        Filterdata({
            payload: pay,
            paramid: paramid
        })
    }, [])

    // Legal Entity payload
    useEffect(() => {
        if (filtervalue && filtervalue.legal !== '') {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "getAssignedStatutoryReportproduct",
                    {
                        "le_id": parseInt(filtervalue.legal)
                    }
                ]
            }
            legalentitydata({
                payload: pay,
                paramid: paramid
            })
        }
    }, [filtervalue.legal])

    // Product payload
    useEffect(() => {
        if (filtervalue && filtervalue.product !== '') {
            const pay =
            {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryReportFiltersAct",
                    {
                        "le_id": parseInt(filtervalue.legal),
                        "product_id": parseInt(filtervalue.product)
                    }
                ]
            }
            Productdata({
                payload: pay,
                paramid: paramid
            })
        }
    }, [filtervalue.product])

    // Show payload
    const showrecord = () => {
        setPageSize(25);
        setCurrent(1);
        setPage(1);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryReport",
                    {
                        "c_id": parseInt(filtervalue.country),
                        "domain_id_optional": filtervalue.domain ? parseInt(filtervalue.domain) : 0,
                        "client_id": parseInt(filtervalue.groupname),
                        "bg_id": filtervalue.business ? parseInt(filtervalue.business) : 0,
                        "le_id": parseInt(filtervalue.legal),
                        "map_text": "%",
                        "u_ids": [],
                        "c_task": null,
                        "csv": false,
                        "from_count": 0,
                        "page_count": 25,
                        "product_id": parseInt(filtervalue.product)
                    }
                ]
            }
            Tabledata({
                payload: pay,
                paramid: paramid
            })

        }
    }

    // Export payload
    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryReport",
                    {
                        "c_id": parseInt(filtervalue.country),
                        "domain_id_optional": filtervalue.domain ? parseInt(filtervalue.domain) : 0,
                        "client_id": parseInt(filtervalue.groupname),
                        "bg_id": filtervalue.business ? parseInt(filtervalue.business) : 0,
                        "le_id": parseInt(filtervalue.legal),
                        "map_text": "%",
                        "u_ids": [],
                        "c_task": null,
                        "csv": true,
                        "from_count": 0,
                        "page_count": 25,
                        "product_id": parseInt(filtervalue.product)
                    }
                ]
            }
            Exportdata({
                payload: pay,
                paramid: paramid
            })
        }
    }

    // Export reset
    useEffect(() => {
        if (exportdatavalue && exportdatavalue.link) {
            // window.open(process.env.REACT_APP_API_URL + exportdatavalue.link);
            window.open(process.env.REACT_APP_API_DOWN_URL + exportdatavalue.link);
            dispatch({
                type: STAT_EXPORTDATA_RESET
            });
        }
    }, [exportdatavalue])

    // Pagination payload
    useEffect(() => {
        if (pageState == true) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetAssignedStatutoryReport",
                    {
                        "c_id": parseInt(filtervalue.country),
                        "domain_id_optional": filtervalue.domain ? parseInt(filtervalue.domain) : 0,
                        "client_id": parseInt(filtervalue.groupname),
                        "bg_id": filtervalue.business ? parseInt(filtervalue.business) : 0,
                        "le_id": parseInt(filtervalue.legal),
                        "map_text": "%",
                        "u_ids": [],
                        "c_task": null,
                        "csv": false,
                        "from_count": Math.max(((current - 1) * pageSize), 0),
                        "page_count": pageSize,
                        "product_id": parseInt(filtervalue.product)
                    }
                ]
            }
            Tabledata({
                payload: pay,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    // Customized Tabledata
    useEffect(() => {
        if (tabledatavalue) {
            let temp = []
            let entityArray
            let finalarray = [];

            let datavalue = [];
            for (let i in tabledatavalue.compliance_statutories_list) {
                const tempact = _.filter(tabledatavalue.act_groups, { statutory_id: parseInt(tabledatavalue.compliance_statutories_list[i].compliance_id) })
                entityArray = {
                    "admin_update": tabledatavalue.compliance_statutories_list[i].admin_update,
                    "c_task": tabledatavalue.compliance_statutories_list[i].c_task,
                    "client_admin": tabledatavalue.compliance_statutories_list[i].client_admin,
                    "client_update": tabledatavalue.compliance_statutories_list[i].client_update,
                    "compfie_admin": tabledatavalue.compliance_statutories_list[i].compfie_admin,
                    "compliance_applicability_status": tabledatavalue.compliance_statutories_list[i].compliance_applicability_status,
                    "compliance_id": tabledatavalue.compliance_statutories_list[i].compliance_id,
                    "document_name": tabledatavalue.compliance_statutories_list[i].document_name,
                    "frequency": tabledatavalue.compliance_statutories_list[i].frequency,
                    "remarks": tabledatavalue.compliance_statutories_list[i].remarks,
                    "statutory_applicability_status": tabledatavalue.compliance_statutories_list[i].statutory_applicability_status,
                    "statutory_nature_name": tabledatavalue.compliance_statutories_list[i].statutory_nature_name,
                    "statutory_opted_status": tabledatavalue.compliance_statutories_list[i].statutory_opted_status,
                    "statutory_provision": tabledatavalue.compliance_statutories_list[i].statutory_provision,
                    "task_code": tabledatavalue.compliance_statutories_list[i].task_code,
                    "unit_id": tabledatavalue.compliance_statutories_list[i].unit_id,
                    "map_text": tempact[0].map_text
                }
                temp.push(entityArray)
            }
            for (let j in tabledatavalue.unit_groups) {
                const tempparent = _.filter(temp, { unit_id: parseInt(tabledatavalue.unit_groups[j].unit_id) })
                datavalue = {
                    index: j,
                    parent: tabledatavalue.unit_groups[j].unit_code + '-' + tabledatavalue.unit_groups[j].unit_name + '-' + tabledatavalue.unit_groups[j].address,
                    child: tempparent
                }
                finalarray.push(datavalue)
            }
            Settitledata(finalarray)
            setTableData(tabledatavalue && tabledatavalue.compliance_statutories_list)
            setActdata(tabledatavalue && tabledatavalue.act_groups)
        }
        if (tabledatavalue.total_count) {
            setCount(tabledatavalue && tabledatavalue.total_count)
        }
    }, [tabledatavalue])

    // Full screen Mode
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    // Filter
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setAddFormSubmit(false);
        setfiltervalue({
            ...filtervalue,
            country: '',
            countryName: '',
            groupname: '',
            groupdata: '',
            legal: '',
            legaldata: "",
            domain: '',
            domaindata: '',
            business: '',
            businessdata: '',
            unit: '',
            unitdata: [],
            act: '',
            product: '',
            compliance_task: '',
            actdata: '',
            legal_name: '',
            group: '',
            bg_name: '',
            domain_name: ''
        })

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    // Antd Table data
    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '20px',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * pageSize + index + 1
            }
        },
        {
            title: "Statutory Provision",
            dataIndex: 'statutory_provision',
            width: '60px',
            // ellipsis: true
        },
        {
            title: "Complaince Task",
            dataIndex: 'c_task',
            key: 'c_task',
            width: '90px',
            // ellipsis: true
        },
        {
            title: "Task Code",
            dataIndex: 'task_code',
            width: '90px',
            // ellipsis: true,
            align: 'center'

        },
        {
            title: "Statutory Nature",
            dataIndex: 'statutory_nature_name',
            width: '60px',
            align: 'center'

            // ellipsis: true
        },
        {
            title: "Frequency",
            dataIndex: 'frequency',
            width: '70px',
            align: 'center'
            // ellipsis: true
        },
        {
            title: <><label title='Applicability Status'><FormOutlined style={{ color: 'blue' }} /></label> </>,
            dataIndex: 'false',
            width: '20px',
            align: 'center',
            render: (text, record) => {
                return <>{record.statutory_applicability_status == '1' ? <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'yellow' }} size="20" /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />}</>
            }

        },
        {
            title: <> <label title='Opted'><UserAddOutlined /></label> </>,
            dataIndex: 'false',
            width: '20px',
            align: 'center',
            render: (text, record) => {
                return <>{record.statutory_opted_status == '1' ? <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'orange' }} size="20" /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />}</>
            }

            // ellipsis: true
        },
        {
            title: "Modified By Compfie",
            dataIndex: 'compfie_admin',
            width: '120px',
            // ellipsis: true
        },
        {
            title: "Modified By Client",
            dataIndex: 'client_admin',
            width: '100px',
            // ellipsis: true
        }
    ]



    return (
        <div id="page-wrapper" className="page-wrapper">
            <div className='back-to-top'>
                        <ScrollButton />
                    </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>Statutory Settings Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-end">
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                marginRight: "15px",
                                display: filterTaskAcc ? "none" : "inline",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                style={{ marginRight: "10px", }}
                                title="Full Screen Mode"
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Statutory Settings Report" key={key}>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="country"
                                                            id="country"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.country || undefined}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Country"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        country: '',
                                                                        groupname: '',
                                                                        legal: '',
                                                                        legal_name: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        act: '',
                                                                        compliance_task: '',
                                                                        product: ''

                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        country: data.value,
                                                                        countryName: data.children,
                                                                        groupname: '',
                                                                        group: '',
                                                                        legal: '',
                                                                        legal_name: '',
                                                                        product: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filterdatavalue && filterdatavalue.countries && filterdatavalue.countries.length > 0 && filterdatavalue.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            filtervalue.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Group Name :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="groupname"
                                                            id="groupname"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.groupname || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filtervalue.country == ''}
                                                            placeholder="Enter GroupName"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        groupname: '',
                                                                        legal: '',
                                                                        legal_name: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        act: '',
                                                                        compliance_task: '',
                                                                        product: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        groupname: data.value,
                                                                        group: data.children,
                                                                        legal_name: '',
                                                                        legal: ''
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.client_id}>
                                                                        {item.group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'groupname',
                                                            filtervalue.groupname,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Group Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="legalentity"
                                                            id="legalentity"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.legal || undefined}
                                                            maxTagCount='responsive'
                                                            disabled={filtervalue.groupname == ''}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(option, data) => { selectedlegalentity(data) }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.legaldata && filtervalue.legaldata.length > 0 && filtervalue.legaldata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.legal_entity_id}>
                                                                        {item.legal_entity_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            filtervalue.legal,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Business Group :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="groupname"
                                                            id="groupname"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.business || undefined}
                                                            // disabled={filtervalue.product == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Group"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        legal: '',
                                                                        business: '',
                                                                        unit: "",
                                                                        domain: '',
                                                                        act: '',
                                                                        compliance_task: '',
                                                                        product: '',
                                                                        bg_name: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        bg_name: data.children,
                                                                        business: data.value,
                                                                        legal: '',
                                                                        legaldata: '',

                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.businessdata && filtervalue.businessdata.length > 0 && filtervalue.businessdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.business_group_id}>
                                                                        {item.business_group_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Product :</b> <span style={{ color: "red" }}> *</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="product"
                                                            id="product"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.product || undefined}
                                                            disabled={filtervalue.legal_name == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Product"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        product: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        product: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                            <Option key='1'>Compfie</Option>
                                                            <Option key='3'>Compfie Vendor</Option>

                                                        </Select>
                                                        {validator.current.message(
                                                            'product',
                                                            filtervalue.product,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Product Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>Domain :</b></label>
                                                <div className="form-group">

                                                    <Select
                                                        name="Domain"
                                                        id="Domain"
                                                        allowClear={true}
                                                        size="default"
                                                        value={filtervalue.domain || undefined}
                                                        disabled={filtervalue.product == ''}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Domain"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    domain: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    domain: data.value,
                                                                    domain_name: data.children
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }} >
                                                        {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                                            return (
                                                                <Option key={item.domain_id}>
                                                                    {item.domain_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Unit :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="Unit"
                                                            id="Unit"
                                                            allowClear={true}
                                                            size="default"
                                                            mode='multiple'
                                                            // value={filtervalue.unit || undefined}
                                                            value={filtervalue.unit || undefined}
                                                            disabled={filtervalue.product == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Unit"
                                                            onChange={(option, data) => {
                                                                let values;
                                                                if (filtervalue.unitdata.length == option.length) {
                                                                    Setselectdomain("none");
                                                                    Setunselectdomain("block");
                                                                } else {
                                                                    Setselectdomain("block");
                                                                    Setunselectdomain("none");
                                                                }

                                                                if (option.includes('all')) {
                                                                    values = filtervalue.unitdata.map(item => item.unit_id + '');
                                                                    Setselectdomain("none");
                                                                    Setunselectdomain("block");
                                                                }

                                                                if (option.includes("unselect")) {
                                                                    values = [];
                                                                    Setselectdomain("block");
                                                                    Setunselectdomain("none");
                                                                }

                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        unit: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        // unit: data.value
                                                                        unit: values ? values : option
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            <Option
                                                                key="all"
                                                                value="all"
                                                                style={{ display: selectdomain }}
                                                            >
                                                                ---SELECT ALL---
                                                            </Option>
                                                            <Option
                                                                key="unselect"
                                                                value="unselect"
                                                                style={{ display: unselectdomain }}
                                                            >
                                                                --UNSELECT ALL--
                                                            </Option>
                                                            {filtervalue.unitdata && filtervalue.unitdata.length > 0 && filtervalue.unitdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_code + '-' + item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act :</b></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            name="act"
                                                            id="act"
                                                            allowClear={true}
                                                            size="default"
                                                            value={filtervalue.act || undefined}
                                                            disabled={filtervalue.product == ''}
                                                            maxTagCount='responsive'
                                                            placeholder="Enter Act"
                                                            onChange={(option, data) => {
                                                                if (data == undefined) {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        act: ''
                                                                    })
                                                                } else {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        act: data.value
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}>
                                                            {filtervalue.actdata && filtervalue.actdata.length > 0 && filtervalue.actdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.statutory_id}>
                                                                        {item.statutory_name}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task :</b></label>
                                                <div className="form-group">
                                                    <Input
                                                        allowClear={true}
                                                        className='form-control'
                                                        placeholder="Enter Compliance Task"
                                                        style={{ width: "100%" }}
                                                        onChange={(e) => {
                                                            setfiltervalue({
                                                                ...filtervalue,
                                                                compliance_task: e.target.value,
                                                            });
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='mt-3 mb-1 text-center'>
                                        <Button type="primary" shape="round" className='addbutton'
                                            style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={<EyeOutlined />} onClick={showrecord}>
                                            Show
                                        </Button>&nbsp;
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            icon={< ExportOutlined />} onClick={() => { onexport() }}>
                                            Export
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title="Statutory Settings Report"
                                // className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}
                                className={"add-service-prv custom-color-modal "+ localStorage.getItem('currentTheme')}
                                footer={false}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}
                            >
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country :</b> <span style={{ color: "red" }}> *</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="country"
                                                        id="country"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.country || undefined}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Country"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    country: '',
                                                                    groupname: '',
                                                                    legal: '',
                                                                    legal_name: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''

                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    country: data.value,
                                                                    groupname: '',
                                                                    legal: '',
                                                                    legal_name: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        {filterdatavalue && filterdatavalue.countries && filterdatavalue.countries.length > 0 && filterdatavalue.countries.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        filtervalue.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Group Name :</b> <span style={{ color: "red" }}> *</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="groupname"
                                                        id="groupname"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.groupname || undefined}
                                                        maxTagCount='responsive'
                                                        disabled={filtervalue.country == ''}
                                                        placeholder="Enter GroupName"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    groupname: '',
                                                                    legal: '',
                                                                    legal_name: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    groupname: data.value,
                                                                    legal: '',
                                                                    legal_name: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        {filtervalue.groupdata && filtervalue.groupdata.length > 0 && filtervalue.groupdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.client_id}>
                                                                    {item.group_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'groupname',
                                                        filtervalue.groupname,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Group Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Legal Entity :</b> <span style={{ color: "red" }}> *</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="legalentity"
                                                        id="legalentity"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.legal || undefined}
                                                        maxTagCount='responsive'
                                                        disabled={filtervalue.groupname == ''}
                                                        placeholder="Enter Legal Entity"
                                                        onChange={(option, data) => { selectedlegalentity(data) }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        {filtervalue.legaldata && filtervalue.legaldata.length > 0 && filtervalue.legaldata.map((item, i) => {
                                                            return (
                                                                <Option key={item.legal_entity_id}>
                                                                    {item.legal_entity_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'legalentity',
                                                        filtervalue.legal,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Business Group :</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="groupname"
                                                        id="groupname"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.business || undefined}
                                                        // disabled={filtervalue.product == ''}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter GroupName"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    legal: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    business: data.value,
                                                                    legal: '',
                                                                    legaldata: '',
                                                                    business: '',
                                                                    unit: "",
                                                                    domain: '',
                                                                    act: '',
                                                                    compliance_task: '',
                                                                    product: ''
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        {filtervalue.businessdata && filtervalue.businessdata.length > 0 && filtervalue.businessdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.business_group_id}>
                                                                    {item.business_group_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Product :</b> <span style={{ color: "red" }}> *</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="product"
                                                        id="product"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.product || undefined}
                                                        disabled={filtervalue.legal_name == ''}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Product"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    product: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    product: data.value
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                        <Option key='1'>Compfie</Option>
                                                        <Option key='3'>Compfie Vendor</Option>

                                                    </Select>
                                                    {validator.current.message(
                                                        'product',
                                                        filtervalue.product,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Product Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <label><b>Domain :</b></label>
                                            <div className="form-group">

                                                <Select
                                                    name="Domain"
                                                    id="Domain"
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    size="default"
                                                    value={filtervalue.domain || undefined}
                                                    disabled={filtervalue.product == ''}
                                                    maxTagCount='responsive'
                                                    placeholder="Enter Domain"
                                                    onChange={(option, data) => {
                                                        if (data == undefined) {
                                                            setfiltervalue({
                                                                ...filtervalue,
                                                                domain: ''
                                                            })
                                                        } else {
                                                            setfiltervalue({
                                                                ...filtervalue,
                                                                domain: data.value
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }} >
                                                    {filtervalue.domaindata && filtervalue.domaindata.length > 0 && filtervalue.domaindata.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Unit :</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="Unit"
                                                        id="Unit"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        mode='multiple'
                                                        value={filtervalue.unit || undefined}
                                                        disabled={filtervalue.product == ''}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Unit"
                                                        onChange={(option, data) => {
                                                            let values;
                                                            if (filtervalue.unitdata.length == option.length) {
                                                                Setselectdomain("none");
                                                                Setunselectdomain("block");
                                                            } else {
                                                                Setselectdomain("block");
                                                                Setunselectdomain("none");
                                                            }

                                                            if (option.includes('all')) {
                                                                values = filtervalue.unitdata.map(item => item.unit_id + '');
                                                                Setselectdomain("none");
                                                                Setunselectdomain("block");
                                                            }

                                                            if (option.includes("unselect")) {
                                                                values = [];
                                                                Setselectdomain("block");
                                                                Setunselectdomain("none");
                                                            }

                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    unit: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    // unit: data.value
                                                                    unit: values ? values : option
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        <Option
                                                            key="all"
                                                            value="all"
                                                            style={{ display: selectdomain }}
                                                        >
                                                            ---SELECT ALL---
                                                        </Option>
                                                        <Option
                                                            key="unselect"
                                                            value="unselect"
                                                            style={{ display: unselectdomain }}
                                                        >
                                                            --UNSELECT ALL--
                                                        </Option>
                                                        {filtervalue.unitdata && filtervalue.unitdata.length > 0 && filtervalue.unitdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_code + '-' + item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Act :</b></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        name="act"
                                                        id="act"
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        value={filtervalue.act || undefined}
                                                        disabled={filtervalue.product == ''}
                                                        maxTagCount='responsive'
                                                        placeholder="Enter Act"
                                                        onChange={(option, data) => {
                                                            if (data == undefined) {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    act: ''
                                                                })
                                                            } else {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    act: data.value
                                                                })
                                                            }
                                                        }
                                                        }
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}>
                                                        {filtervalue.actdata && filtervalue.actdata.length > 0 && filtervalue.actdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.statutory_id}>
                                                                    {item.statutory_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Task :</b></label>
                                            <div className="form-group">
                                                <Input
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    className='form-control'
                                                    placeholder="Enter Compliance Task"
                                                    style={{ width: "100%" }}
                                                    onChange={(e) => {
                                                        setfiltervalue({
                                                            ...filtervalue,
                                                            compliance_task: e.target.value,
                                                        });
                                                    }}
                                                />

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='text-center mt-3'>
                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button'
                                        style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                        icon={<EyeOutlined />} onClick={showrecord}>
                                        Show
                                    </Button>&nbsp;&nbsp;
                                    <Button type="primary" shape="round" className='addbutton custom-color-modal-button' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                        icon={< ExportOutlined />} onClick={() => { onexport() }}>
                                        Export
                                    </Button>
                                </div>

                            </Modal>
                            {tableshow == true ?
                                <>
                                    <div className='card card-body' style={{ padding: "5px 5px" }}>
                                        <div className='bg-white shadow-sm border-radius-reports'>
                                            <div className="col-md-12">
                                                <div className='row m-2'>
                                                    <div className='col-md-4' >
                                                        <label><b>Country  : </b> {filtervalue.countryName}</label>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <label><b>Group : </b> {filtervalue.group}</label>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <label><b>Business Group : </b> {filtervalue.bg_name}</label>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label><b>Legal Entity : </b> {filtervalue.legal_name}</label>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label><b>Domain : </b> {filtervalue.domain_name}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content'>
                                                        {titledata && titledata.length > 0 ?
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead antable-head">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '35px' }}  > <centre>#</centre> </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }} >Statutory Provision</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}  ><centre>Compliance Task</centre></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><centre>Task Code</centre></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><center>Statutory Nature</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} >Frequency</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}  ><center><label title='Applicability Status'><FormOutlined style={{ color: 'blue' }} /></label></center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} ><center><label title='Opted'><UserAddOutlined /></label></center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }} ><center>Modified By Compfie</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }} ><center>Modified By Client</center></th>


                                                                    </tr>
                                                                </thead>
                                                                {titledata && titledata.map((itemdata, i) => {
                                                                    if (itemdata != undefined) {
                                                                        return <Fragment>
                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                <td colSpan={12} className='ant-table-cell' ><b>{itemdata.parent}</b></td>
                                                                            </tr>
                                                                            {itemdata.child.map((items) => {
                                                                                if (items.unit_id) {
                                                                                    return <>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={12} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>Act : {items.map_text}</b></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='ant-table-cell'><center>{(page - 1) * pageSize + index + 1}</center></td>
                                                                                            <td className='ant-table-cell'>  {items.statutory_provision} </td>
                                                                                            <td className='ant-table-cell'>{items.c_task}</td>
                                                                                            <td className='ant-table-cell'><center>{items.task_code}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.statutory_nature_name}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.frequency}</center></td>
                                                                                            <td className='ant-table-cell'> <><center>{items.compliance_applicability_status == '1' ? <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'blue' }} size="15" /> : <FeatherIcon icon="x-circle" className={'pointer'} size={15} />}</center></></td>
                                                                                            <td className='ant-table-cell'> <><center>{items.statutory_opted_status == '1' ? <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'orange' }} size="15" /> : <FeatherIcon icon="x-circle" className={'pointer'} size={15} />}</center></></td>
                                                                                            <td className='ant-table-cell'>{items.compfie_admin} </td>
                                                                                            <td className='ant-table-cell'>{items.client_admin} </td>
                                                                                            <span hidden>{index = index + 1}</span>
                                                                                        </tr>
                                                                                    </>
                                                                                }
                                                                            })
                                                                            }
                                                                        </Fragment>

                                                                    }
                                                                })
                                                                }
                                                            </table> :
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead antable-head">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '35px' }}  > <centre>#</centre> </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }} >Statutory Provision</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}  ><centre>Compliance Task</centre></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><centre>Task Code</centre></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><center>Statutory Nature</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} >Frequency</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}  ><center><label title='Applicability Status'><FormOutlined style={{ color: 'blue' }} /></label></center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} ><center><label title='Opted'><UserAddOutlined /></label></center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }} ><center>Modified By Compfie</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }} ><center>Modified By Client</center></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <tr className='text-center'>
                                                                        <td colSpan={10}><b>No Records Found</b></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        {index != 0 ?
                                            <><div className='col-md-6'>
                                                {/* <label>Showing {pageSize * (current - 1) + 1} to {index} of {count} entries</label> */}
                                                <label> Showing {(current - 1) * pageSize + 1} to {tabledatavalue && tabledatavalue.total_count > current * pageSize ? current * pageSize : tabledatavalue && tabledatavalue.total_count} of {tabledatavalue && tabledatavalue.total_count} entries</label>

                                            </div>
                                                <div className='col-md-6 text-right'>
                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        showQuickJumper={false}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)

                                                        }}
                                                        pageSizeOptions={[25, 50, 100]} hideOnSinglePage={false} defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                            setPage(page);
                                                        }} total={tabledatavalue && tabledatavalue.total_count} />

                                                </div>
                                            </> : ''}
                                    </div>
                                </>
                                : false}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default connect(mapStateToProps, {
    Filterdata,
    legalentitydata,
    Productdata,
    Tabledata,
    Exportdata
})(StatutorySettingsReport);