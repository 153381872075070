import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import alertmessage from '../../../Libs/Altermessage';
import { DEASSIGNSTATUTORYFILTER, TOGGLE_COMMON_LOADER, DEASSIGNSTATUTORYOTHERFILTER, GETASSIGNEDSTAUTORIES, ASSIGNED_STATUTORY_COUNT, ASSIGNED_STATUTORY_TWO_DATA, ASSIGNED_STATUTORY_ADD_OPTION, ASSIGNED_STATUTORY_ADD_UNITS, SAVE_ASSIGNED_STATUTORY, ASSIGNED_STATUTORY_ADD_TWO_COUNT, ASSIGNED_STATUTORY_ADD_TWO_DATA, SUBMIT_ASSIGNED_STATUTORY, ASSIGNED_STATUTORY_ADD_TWO_DATA_MULTIPLE } from './../../types/index'
import { toast } from 'react-toastify'

export const getassignstatutoryfilter = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardOneDataSuccess") {
                dispatch({
                    type: DEASSIGNSTATUTORYFILTER,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const GetAssignStatutoryClientDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutorylistclientfilterSuccess") {
                dispatch({
                    type: DEASSIGNSTATUTORYOTHERFILTER,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const GetAssignedStatutories = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoriesSuccess") {
                dispatch({
                    type: GETASSIGNEDSTAUTORIES,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const assignedStatutoryCount = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardTwoCountSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_COUNT,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const assignedStatutoryTwoData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardTwoDataSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_TWO_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addOption = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardOneDataSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_ADD_OPTION,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addUnitsList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardOneUnitsSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_ADD_UNITS,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const SaveAssignedStatutory = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "SaveAssignedStatutorySuccess") {
                Toaster.success('Statutory Updated Successfully');
                dispatch({
                    type: SAVE_ASSIGNED_STATUTORY,
                    payload: {status: 'Success'},
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const SubmitAssignedStatutory = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "SaveAssignedStatutorySuccess") {
                Toaster.success('Statutory Submitted Successfully');
                dispatch({
                    type: SUBMIT_ASSIGNED_STATUTORY,
                    payload: {status: 'Success'},
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addTwoCount = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardTwoCountSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_ADD_TWO_COUNT,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addTwoData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/domain_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetAssignedStatutoryWizardTwoDataSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_ADD_TWO_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === "GetAssignedStatutoryWizardTwoMultipleDataSuccess") {
                dispatch({
                    type: ASSIGNED_STATUTORY_ADD_TWO_DATA_MULTIPLE,
                    payload: decryptData[1],
                });
            } 
            else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};