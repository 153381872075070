import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { Input, Row, Col, Button } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { UserProfile } from './../Store/Action/Profile'
import { PlayCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { login } from './../Store/Action/Login'

const _ = require("lodash");

const mapStateToProps = (state) => ({
    profiles: state.Profile.profile,
});
const MyProfile = ({
    login,
    UserProfile,
    profiles
}) => {
    const sessionValue = localStorage.getItem('sessionValue')
    const profilevalue = localStorage.getItem('profiledata')
    const sessionParsedValue = JSON.parse(sessionValue)
    const values = JSON.parse(sessionValue)
    const profilelist = values.login_response
    console.log(profilelist, "profilevalue555");
    const sessionArr = sessionParsedValue.login_response
    const sessionArr123 = sessionParsedValue && sessionParsedValue.login_response && sessionParsedValue.login_response.menu
    const account = Object.values(sessionArr123)
    const getUrl = account[0]['My Accounts']
    console.log(getUrl, "getUrl");
    const profiles1 = _.filter(getUrl, { form_name: 'View Profile' })
    console.log(profiles1, "profiles1");
    const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
    console.log(ChangePassword, "ChangePassword12");
    // Setformurl(profile[0].form_url)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [mobileno, setMoblieNo] = useState([])
    const [contactno, setContactNo] = useState([])
    const [profiledata, setProfileData] = useState({
        employee_name: "",
        employee_id: "",
        designation: "",
        user_id: "",
        email_id: "",
        user_group: "",
        contact_c_code: "",
        contact_a_code: "",
        contact_no: "",
        mobile_no_c_code: "",
        mobile_no: "",
        address: ""
    })
    useEffect(() => {
        if (isAuth && sessionArr.mobile_no) {
            const text = sessionArr.mobile_no
            const myArray = text.split("-");
            setMoblieNo(myArray)
        }

    }, [isAuth, sessionArr.mobile_no])

    console.log(sessionArr, "44444");
    console.log(contactno, "55555");
    console.log(isAuth, "isAuthisAuth");
    useEffect(() => {
        if (isAuth && sessionArr.contact_no) {
            console.log("manoj");
            const text = sessionArr.contact_no
            const myArray = text.split("-");
            console.log(myArray, "myArraymyArray");
            setContactNo(myArray)
        }
    }, [isAuth, sessionArr.contact_no])
    useEffect(() => {
        // if (isAuth) {
        login({})
        // }
    }, [])
    useEffect(() => {
        if (isAuth) {

            const sessionArr = sessionParsedValue.login_response
            setProfileData({
                ...profiledata,
                employee_name: sessionArr.employee_name,
                employee_id: sessionArr.employee_code,
                designation: sessionArr.designation,
                user_id: sessionArr.username,
                email_id: sessionArr.email_id,
                user_group: sessionArr.user_group_name,
                contact_a_code: contactno && contactno[1],
                contact_c_code: contactno && contactno[0],
                contact_no: contactno && contactno[2],
                mobile_no_c_code: mobileno[0],
                mobile_no: mobileno[1],
                address: sessionArr.address
            })
        }
    }, [isAuth, mobileno, contactno])
    console.log(contactno[2], "65656");
    const { TextArea } = Input;
    const { Search } = Input;
    const [changepassword, Setchangepassword] = useState();

    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [profile, setProfile] = useState({
        email_id: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: ''
    })
    const [show, setShow] = useState(false)
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [modalVisible, setModaVisible] = useState(false)
    const onUserDetailOnChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    const location = useLocation();

    const paramid = location.pathname;
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    console.log(callername, "callername");
    useEffect(() => {
        if (isAuth) {
            Setchangepassword(ChangePassword[0].form_url)
            Setformdata({
                ...formdata,
                form_url: profiles1 && profiles1[0] && profiles1[0].form_url,
                form_key: profiles1 && profiles1[0] && profiles1[0].form_key
            })
        }
    }, [isAuth])
    const userDetailOnSubmit = () => {
        const login_response = [{
            "address": profiledata.address,
            "email": profiledata.email_id,
            "contac_a_code": profiledata.contact_a_code,
            "contact_c_code": profiledata.contact_c_code,
            "contact_no": profiledata.contact_no,
            "mobile_c_code": profiledata.mobile_no_c_code,
            "mobile_no": profiledata.mobile_no
        }]
        let prevData = JSON.parse(localStorage.getItem('sessionValue')).login_response;
        prevData.mobile_no = profiledata.mobile_no_c_code + '-' + profiledata.mobile_no
        prevData.email_id = profiledata.email_id
        prevData.address = profiledata.address
        prevData.contact_no = profiledata.contact_c_code + '-' + profiledata.contact_a_code + '-' + profiledata.contact_no
        const arrayvalue = {
            'login_response': prevData
        }
        localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
        setAddFormSubmit(true);
        console.log("submit");
        if (validator.current.allValid()) {
            const payload =
            {
                "session_token": authtoken,
                "request": [
                    "UpdateUserProfile",
                    {
                        "contact_no": `${profiledata.contact_c_code}-${profiledata.contact_a_code}-${profiledata.contact_no}`,
                        "address": profiledata.address,
                        "mobile_no": `${profiledata.mobile_no_c_code}-${profiledata.mobile_no}`,
                        "email_id": profiledata.email_id
                    }

                ]
            }

            UserProfile({
                payload: payload,
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>My Accounts</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>My Profile</span>
                                        </li>
                                    </ol>
                                </nav>
                                {/* <h1 className="mb-0 fw-bold">My Profile </h1> */}
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: "15px 15px" }}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label htmlFor="" ><b>Employee Name</b></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>{profiledata.employee_name}</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="" ><b>Employee ID</b></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>{profiledata.employee_id}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "30px" }}>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>Designation</b></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>{profiledata.designation}</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>User ID</b></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>{profiledata.user_id}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "30px" }}>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>Email ID</b><span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <Input
                                                            style={{ borderRadius: '10px' }}
                                                            placeholder='Enter Email ID'
                                                            type="text"
                                                            autoFocus={true}
                                                            onChange={(e) => {
                                                                setProfileData({
                                                                    ...profiledata, email_id: e.target.value
                                                                })
                                                            }}
                                                            defaultValue={profiledata.email_id}
                                                            key={profiledata.email_id}
                                                        />
                                                        {validator.current.message(
                                                            'emailId',
                                                            profiledata.email_id,
                                                            ['required', {
                                                                regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            }],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Email Id Required',
                                                                    regex: 'Enter valid Email ID'
                                                                }
                                                            })}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>User Group</b></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>{profiledata.user_group}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 " style={{ marginTop: "30px" }}>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>Contact No</b></label>
                                                        {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Input.Group size="default">
                                                            <Row gutter={8}>
                                                                <Col span={6}>
                                                                    <Input
                                                                        style={{ borderRadius: '10px' }}
                                                                        placeholder='+00'
                                                                        name='emp_contact_country_code'
                                                                        autoFocus={true}
                                                                        maxLength='3'
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        defaultValue={profiledata.contact_c_code}
                                                                        key={profiledata.contact_c_code}
                                                                        onChange={(e) => {
                                                                            setProfileData({
                                                                                ...profiledata,
                                                                                contact_c_code: e.target.value
                                                                            })
                                                                        }}
                                                                    />

                                                                </Col>
                                                                <Col span={6}>
                                                                    <Input
                                                                        style={{ borderRadius: '10px' }}
                                                                        placeholder='Area Code'
                                                                        name='emp_contact_code'
                                                                        autoFocus={true}
                                                                        maxLength='4'
                                                                        defaultValue={profiledata.contact_a_code}
                                                                        key={profiledata.contact_a_code}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setProfileData({
                                                                                ...profiledata,
                                                                                contact_a_code: e.target.value
                                                                            })
                                                                        }}
                                                                    />

                                                                </Col>
                                                                <Col span={12}>
                                                                    <Input
                                                                        style={{ borderRadius: '10px' }}
                                                                        placeholder='Contact No'
                                                                        name='emp_contact_no'
                                                                        maxLength='7'
                                                                        autoFocus={true}
                                                                        defaultValue={profiledata.contact_no}
                                                                        key={profiledata.contact_no}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setProfileData({
                                                                                ...profiledata,
                                                                                contact_no: e.target.value
                                                                            })
                                                                        }}
                                                                    />

                                                                </Col>
                                                            </Row>
                                                        </Input.Group>
                                                        [+91 452 1234567]
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>Mobile No</b><span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Input.Group size="default">
                                                            <Row gutter={8}>
                                                                <Col span={6}>
                                                                    <Input
                                                                        style={{ borderRadius: '10px' }}
                                                                        placeholder='+00'
                                                                        autoFocus={true}
                                                                        onChange={(e) => {
                                                                            setProfileData({
                                                                                ...profiledata,
                                                                                mobile_no_c_code: e.target.value
                                                                            })
                                                                        }}
                                                                        defaultValue={profiledata.mobile_no_c_code}
                                                                        key={profiledata.mobile_no_c_code}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxlength='3'
                                                                    />
                                                                    {validator.current.message(
                                                                        'mobile_no_country_code',
                                                                        profiledata.mobile_no_c_code,
                                                                        ['required', { regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country code should not be empty',
                                                                                regex: 'Enter valid country code'
                                                                            }
                                                                        })}
                                                                </Col>


                                                                <Col span={16}>
                                                                    <Input
                                                                        style={{ borderRadius: '10px' }}
                                                                        placeholder='Mobile Number'
                                                                        name='mobile_no'
                                                                        autoFocus={true}
                                                                        onChange={(e) => {
                                                                            setProfileData({
                                                                                ...profiledata,
                                                                                mobile_no: e.target.value
                                                                            })
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxlength='10'
                                                                        defaultValue={profiledata.mobile_no}
                                                                        key={profiledata.mobile_no}
                                                                    />
                                                                    {validator.current.message(
                                                                        'mobile_no',
                                                                        profiledata.mobile_no,
                                                                        ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Mobile Number Required',
                                                                                regex: 'Mobile number required minimum 10 characters'
                                                                            }
                                                                        })}
                                                                </Col>

                                                            </Row>
                                                        </Input.Group>
                                                        [+91 9876543210]
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "20px" }} >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label htmlFor="" ><b>Address</b></label>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                        <TextArea
                                                            rows={1}
                                                            name="address"
                                                            placeholder='Enter Address'
                                                            autoFocus={true}
                                                            defaultValue={profiledata.address}
                                                            key={profiledata.address}
                                                            onKeyPress={(event) => {
                                                                if (!/[\w\d\@\#\w   \-\/\?\.\>\,\<\;\:]$/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setProfileData({
                                                                    ...profiledata,
                                                                    address: e.target.value
                                                                })
                                                            }}
                                                        ></TextArea>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                </div>
                                            </div>
                                        </div><br />


                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row' style={{ textAlign: 'center' }}>
                                                <div className='col-md-6' style={{ marginLeft: '20%' }}>
                                                    <Button type="primary" icon={<PlayCircleOutlined />} shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }} onClick={userDetailOnSubmit}
                                                    >
                                                        Submit
                                                    </Button>

                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'left', marginLeft: '-21%' }}>
                                                    <Button type="primary" icon={<EyeOutlined />} shape="round" className='addbutton'>&nbsp;
                                                        <Link to={changepassword ? changepassword : ''} style={{ color: 'black' }}>
                                                            Change Password</Link>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    UserProfile,
    login
})(MyProfile);