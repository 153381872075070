import { ASSIGN_LEGAL_ENTITY,ASSIGNED_LEGAL_ENTITY,GET_ASSIGNED_ENTITY_DATA } from "../../types";


const initialState = {
   assign_legal_tabledata : [],
   assignedTableData : [],
   getAssignedData : []
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGN_LEGAL_ENTITY:{
            console.log(payload,'payloadAssignLegalEntity')
            return {
                ...state,
                assign_legal_tabledata:  payload.assign_le_list
            }
        }
        case ASSIGNED_LEGAL_ENTITY:{
            console.log(payload,'payloadAssignLegalEntity')
            return {
                ...state,
                assignedTableData:  payload
            }
        }
        
        case GET_ASSIGNED_ENTITY_DATA:{
            console.log(payload,'payloadAssignLegalEntity')
            return {
                ...state,
                getAssignedData:  payload
            }
        }
        default:
            return state;
    }
}