import { LEGAL_ENTITY_CLOSURE_TABLE_DATA, ELSE_DATA, RESET_ELSE } from '../../types/index'

const initialState = {
    leclosure: {
        list: [],
        elsevalue: ''
    }
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LEGAL_ENTITY_CLOSURE_TABLE_DATA:
            {
                return {
                    ...state,
                    leclosure: {
                        ...state.leclosure,
                        list: payload,
                    },
                };
            }
        case ELSE_DATA:
            {
                return {
                    ...state,
                    leclosure: {
                        ...state.leclosure,
                        elsevalue: payload
                    }
                }
            }
        case RESET_ELSE:
            {
                return {
                    ...state,
                    leclosure: {
                        ...state.leclosure,
                        elsevalue: ''
                    }
                }
            }
        default:
            return state;
    }
}