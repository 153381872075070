import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getClientInfo, RejectedASMBulkUploadData, RejectedASMDownloadCount, DownloadRejectedASMReport, downloadRejectStatus, verifyPassword, deleteCSV } from "../../Store/Action/Transactions/RejectedAssignStatutory_bulkupload";
import { RESET_STATE } from "../../Store/types/index";
import { Collapse, Select, Button, DatePicker, Card, Table, Modal, Tabs, Tooltip, Space } from "antd";
import { EyeOutlined, ExportOutlined, FilterOutlined, FullscreenOutlined, UpOutlined, DownloadOutlined, CloseOutlined, FileExcelTwoTone, FileTwoTone, ProfileTwoTone, FileTextTwoTone, CloseCircleTwoTone, PlayCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { TOGGLE_COMMON_LOADER } from "./../../Store/types/index";
import EncryptDecrypt from "./../../Libs/Encrypt&Decrypt";
import axios from "axios";
import url from "../../Libs/URL";
import { decode as base64_decode } from "base-64";
import { Toaster } from "../../Libs/Toaster";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import UrlLink from "../../Libs/URL";
import Swal from "sweetalert2";

const mapStateToProps = (state) => ({
  clientInfo: state.RejectedAssignStatutory_bulkupload.ClientInfo,
  rejectedData: state.RejectedAssignStatutory_bulkupload.RejectedASMBulkUploadData,
  downloadCSV: state.RejectedAssignStatutory_bulkupload.DownloadRejectedASMReport.csv_name,
  downloadFile: state.RejectedAssignStatutory_bulkupload.DownloadRejectStatus,
  passwordStatus: state.RejectedAssignStatutory_bulkupload.VerifyPassword.status,
  deleteStatus: state.RejectedAssignStatutory_bulkupload.deleteCsvStatus.status,
});

const RejectedAssignStatutoryBulkUpload = ({
  getClientInfo,
  clientInfo,
  RejectedASMBulkUploadData,
  rejectedData,
  RejectedASMDownloadCount,
  DownloadRejectedASMReport,
  downloadCSV,
  downloadRejectStatus,
  downloadFile,
  verifyPassword,
  passwordStatus,
  deleteCSV,
  deleteStatus
}) => {
  console.log(rejectedData.asm_rejected_data, "clientInfoclientInfo");
  const location = useLocation();
  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const sessionValue = localStorage.getItem("sessionValue");
  const paramid = location.pathname;
  const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const passwordValidator = useRef(new SimpleReactValidator());
  const { Panel } = Collapse;
  const { Option } = Select;
  const [exitCollapse, setExitCollapse] = useState(false);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [fullscreen, setfullscreen] = useState(false);
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
  const [fileFormat, setFileFormat] = useState("");
  console.log(fileFormat, "fileFormat456");
  const [showTable, setShowTable] = useState(false);
  const pwdRef = useRef(null);

  const [password, Setpassword] = useState({
    passwordvalue: "",
  });
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [inputValues, setInputValues] = useState({
    clientId: "",
    clientName: "",
    unitId: "",
    unitName: "",
    countryId: "",
    countryName: "",
    domainId: "",
    domainName: "",
    groupId: "",
    groupName: "",
    legalEntityId: "",
    legalEntityName: "",
    contractFrom: "",
    contractTo: "",
    BusinessGroupId: "",
    BusinessGroupName: "",
    deleteCsvName: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    validator.current.showMessages();
    passwordValidator.current.showMessages();
  }, []);

  useEffect(() => {
    dispatch({
      type: RESET_STATE,
      payload: "Reset",
    });
  }, []);

  useEffect(() => {
    validator.current.showMessages();
    if (isAuth) {
      const pay = {
        session_token: authtoken,
        request: ["GetClientInfo", {}],
      };
      getClientInfo({
        payload: pay,
        paramid: paramid,
      });
    }
  }, [isAuth]);

  const showResult = () => {

    setAddFormSubmit(true);
    if (validator.current.allValid()) {
      setShowTable(true);
      setExitCollapse(true);
      setFilterModalVisible(false);
      setFilterTaskAcc(false);
      const pay = {
        session_token: authtoken,
        request: [
          "GetRejectedAssignSMData",
          {
            client_id: parseInt(inputValues.clientId),
            le_id: parseInt(inputValues.legalEntityId),
            d_id: 0,
            asm_unit_code: "",
          },
        ],
      };
      RejectedASMBulkUploadData({
        payload: pay,
        paramid: paramid,
      });
    }
  };

  const modalClose = () => {
    setIsModalOpen(false);
  };

  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };

  const homescreen = () => {
    window.location.href = "/dashboard";
  };

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };

  const setFocus = (ref) => {
    setTimeout(() => {
      ref.current.focus();
    });
  };

  // Full Screen Mode 
  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
      document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
      return () => {
        document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
      };
    }
  }, [fullscreen]);

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode(dispatch);
      setfullscreen(true);
    } else {
      fullscreenexit(dispatch);
      setfullscreen(false);
    }
  };

  const rejectedfile = (record) => {
    const id = record.csv_id;
    Swal.fire({
      icon: 'success',
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      text: "You want to permanently delete this file?",
      customClass: 'custom-color-modal',
      confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
    }).then((result) => {
      setTimeout(() => {
        setFocus(pwdRef)
      });
      if (result.isConfirmed) {
        setDeleteID(id)
        Setpasswordmodal(true);
      }
    });

  }

  const columns = [
    {
      title: "#",
      dataIndex: "",
      key: "",
      width: "15px",
      align: "center",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Uploaded File Name",
      dataIndex: "csv_name",
      key: "csv_name",
      width: "150px",
      align: "center",
    },
    {
      title: "Rejected On",
      dataIndex: "rejected_on",
      key: "rejected_on",
      align: "center",
      width: "80px",
    },
    {
      title: "Rejected By",
      dataIndex: "",
      key: "",
      align: "center",
      width: "100px",
    },
    {
      title: "No. of Records",
      dataIndex: "total_records",
      key: "total_records",
      width: "100px",
      align: "center",
    },
    {
      title: "Declined Count",
      dataIndex: "declined_count",
      key: "declined_count",
      width: "80px",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Rejected File",
      dataIndex: "",
      key: "",
      width: "80px",
      align: "center",
      render: (text, record) => {
        console.log(record, "recordrecordddd");
        if (record.file_download_count < 2) {
          return (
            <div className="d-flex justify-content-around">
              <span
                className="pointer"
                style={{
                  color: "blue",
                }}
                onClick={(e) => {
                  downloadfile("excel", record.csv_id);
                }}>
                <FileExcelTwoTone title="Download Excel" />
              </span>
              <br />
              <span
                className="pointer"
                style={{
                  color: "blue",
                }}
                onClick={(e) => {
                  downloadfile("csv", record.csv_id);
                }}>
                <FileTwoTone title="Download CSV" />
              </span>
              <br />
              <span
                className="pointer"
                style={{
                  color: "blue",
                }}
                onClick={(e) => {
                  downloadfile("ods", record.csv_id);
                }}>
                <ProfileTwoTone title="Download ODS" />
              </span>
              <br />
              <span
                className="pointer"
                style={{
                  color: "blue",
                }}
                onClick={(e) => {
                  downloadfile("text", record.csv_id);
                }}>
                <FileTextTwoTone title="Download Text" />
              </span>
            </div>
          );
        }
      },
    },
    {
      title: "Reason for Rejection",
      dataIndex: "rejected_reason",
      key: "rejected_reason",
      width: "100px",
      align: "center",
    },
    {
      title: "Remove",
      dataIndex: "",
      key: "",
      width: "40px",
      ellipsis: true,
      align: "center",
      render: (text, record) => {
        console.log(record, text, "recordrecorddddd");
        setDeleteID(record.csv_id);
        return (
          <Fragment>
            {text != 0 ?
              <div>
                <span style={{ margin: 'auto' }}
                  className="btn btn-sm btn-light-danger
                      text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { rejectedfile(record) }}>
                  <Tooltip title="Click here to remove"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>
                </span>
              </div> : '-'}
          </Fragment>

          // <span
          //   onClick={() => {
          //     Swal.fire({
          //       icon: 'success',
          //       title: "Are you sure?",
          //       showCancelButton: true,
          //       confirmButtonText: "Yes",
          //       cancelButtonText: "No",
          //       text: "You want to permanently delete this file?",
          //       customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
          //       confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
          //     }).then((result) => {
          //       setTimeout(() => {
          //         setFocus(pwdRef)
          //       });
          //       if (result.isConfirmed) {
          //         Setpasswordmodal(true);
          //         Setpassword({
          //           ...password,
          //           passwordvalue: "",
          //         });
          //       }
          //     });
          //   }}
          //   >
          //   <CloseCircleTwoTone twoToneColor="red" /> 
          // </span>
        );
      },
    },
  ];

  const resetFields = (data) => {
    if (data == undefined) {
      setInputValues({
        ...inputValues,
        clientId: "",
        clientName: "",
        unitId: "",
        unitName: "",
        countryId: "",
        countryName: "",
        domainId: "",
        domainName: "",
        groupId: "",
        groupName: "",
        legalEntityId: "",
        legalEntityName: "",
        contractFrom: "",
        contractTo: "",
        BusinessGroupId: "",
        BusinessGroupName: "",
      });
    }
  };

  const passwordcancel = () => {
    Setpasswordmodal(false);
    Setpassword({
      passwordvalue: "",
    });
    // setAddFormSubmit2(false);
  };

  useEffect(() => {
    if (inputValues.clientName && inputValues.legalEntityName) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [inputValues.clientName]);

  const downloadfile = (format, id) => {
    setFileFormat(format);
    const downpay = {
      session_token: authtoken,
      request: [
        "UpdateASMClickCount",
        {
          csv_id: id,
        },
      ],
    };

    const filepay = {
      session_token: authtoken,
      request: [
        "DownloadRejectedASMReport",
        {
          client_id: parseInt(inputValues.clientId),
          le_id: parseInt(inputValues.legalEntityId),
          d_id: 0,
          asm_unit_code: "",
          csv_id: parseInt(id),
          download_format: format,
        },
      ],
    };

    RejectedASMDownloadCount({
      payload: downpay,
      paramid: paramid,
    });

    DownloadRejectedASMReport({
      payload: filepay,
      paramid: paramid,
    });
  };

  useEffect(() => {
    if (downloadCSV) {
      const fileDownload = {
        session_token: authtoken,
        request: [
          "GetASMDownloadRejectStatus",
          {
            csv_name: downloadCSV,
          },
        ],
      };

      downloadRejectStatus({
        payload: fileDownload,
        paramid: paramid,
      });
    }
  }, [downloadCSV]);

  // const submitPassword = (e) => {
  //   e.preventDefault();
  //   setAddFormSubmit2(true);
  //   if (passwordValidator.current.allValid()) {

  //     const pay = {
  //       session_token: authtoken,
  //       request: [
  //         "VerifyPassword",
  //         {
  //           password: password.passwordvalue,
  //         },
  //       ],
  //     };

  //     const passpay = {
  //       session_token: authtoken,
  //       request: [
  //         "DeleteRejectedASMByCsvID",
  //         {
  //           client_id: parseInt(inputValues.clientId),
  //           le_id: parseInt(inputValues.legalEntityId),
  //           d_id: parseInt(inputValues.domainId),
  //           asm_unit_code: "",
  //           csv_id: deleteID,
  //         },
  //       ],
  //     };

  //     deleteCSV({
  //       payload: passpay,
  //       paramid: paramid,
  //       filepayload: filepay
  //     });

  //   } else {
  //     setAddFormSubmit2(true);

  //   }
  // };

  const submitPassword = () => {
    setAddFormSubmit2(true);
    if (passwordValidator.current.allValid()) {
      const passpay = {
        "session_token": authtoken,
        "request": [
          "VerifyPassword",
          {
            password: password.passwordvalue,
          }
        ]
      }

      const filepay = {
        "session_token": authtoken,
        request: [
          "DeleteRejectedASMByCsvID",
          {
            client_id: parseInt(inputValues.clientId),
            le_id: parseInt(inputValues.legalEntityId),
            d_id: inputValues.domainId ? parseInt(inputValues.domainId) : 0,
            asm_unit_code: "",
            csv_id: deleteID,
          },
        ],
      }

      verifyPassword({
        payload: passpay,
        caller: paramid,
        filepayload: filepay
      })
      Setpasswordmodal(false);
      setAddFormSubmit2(false);
    }

  }


  useEffect(() => {
    if (passwordStatus === "Success") {
      console.log("deleteCSVID");
      // const pay = {
      //   session_token: authtoken,
      //   request: [
      //     "DeleteRejectedASMByCsvID",
      //     {
      //       client_id: parseInt(inputValues.clientId),
      //       le_id: parseInt(inputValues.legalEntityId),
      //       d_id: 0,
      //       asm_unit_code: "",
      //       csv_id: deleteID,
      //     },
      //   ],
      // };

      // deleteCSV({
      //   payload: pay,
      //   paramid: paramid,
      // });
    }
  }, [passwordStatus]);

  // useEffect(() => {
  //   if (downloadFile) {
  //     if (fileFormat == "csv") {
  //       window.open(UrlLink.CommonURL + downloadFile.csv_link);
  //     } else if (fileFormat == "excel") {
  //       window.open(UrlLink.CommonURL + downloadFile.xlsx_link);
  //     } else if (fileFormat == "ods") {
  //       window.open(UrlLink.CommonURL + downloadFile.ods_link);
  //     } else {
  //       window.open(UrlLink.CommonURL + downloadFile.txt_link);
  //     }
  //   }
  // }, [downloadFile]);

  useEffect(() => {
    if (downloadFile) {
      if (fileFormat == 'csv') {
        window.open(process.env.REACT_APP_API_URL + downloadFile.csv_link);
      } else if (fileFormat == 'excel') {
        window.open(process.env.REACT_APP_API_URL + downloadFile.xlsx_link);
      } else if (fileFormat == 'ods') {
        window.open(process.env.REACT_APP_API_URL + downloadFile.ods_link);
      } else {

        window.URL = window.URL || window.webkitURL;

        var xhr = new XMLHttpRequest(),
          a = document.createElement('a'), file;

        xhr.open('GET', 'https://13.234.100.64/knowledge/rejected/downloads/txt/Statutory_Mapping%20(15)_a7d97d13e5f942b7a95d275704cdab3d_download.txt', true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          file = new Blob([xhr.response], { type: 'application/octet-stream' });
          a.href = window.URL.createObjectURL(file);
          a.download = 'someName.txt';
          a.click();
        };
        xhr.send();

        // axios.get('https://13.234.100.64' + downloadlink.txt_link)
        //     .then(res => console.log("res", res))


        // window.open('https://13.234.100.64' + downloadlink.txt_link);
        // window.location.href = 'https://13.234.100.64' + downloadlink.txt_link;

        // const datalink='https://13.234.100.64' + downloadlink.txt_link;
        // function download(dataurl, filename) {
        //     const link = document.createElement("a");
        //     // link.href = 'data:text/plain;charset=UTF-8,' + dataurl;
        //     link.href = 'data:text/plain;charset=UTF-8,' + encodeURI(dataurl);
        //     link.target = '_blank';
        //     link.download = filename;
        //     link.click();
        //   }

        //   download(datalink, "helloWorld.txt");
        //   download(datalink, "helloWorld.txt");
        // fetch('https://13.234.100.64' + downloadlink.txt_link)
        // .then(res => res.text())
        // .then(result => console.log("Result", result))

        // fetch('data:text/plain;charset=UTF-8,' + datalink)
        //     .then(res => console.log("res", res.body))
        // .then(result => console.log(result))

        // function buttonset () {
        //     var myWindow = window.open('https://13.234.100.64' + downloadlink.txt_link);
        //     myWindow.onload = function() {
        //         console.log(myWindow.document.getElementById("result").innerHTML);
        //         myWindow.close();
        //     };
        // }

        // buttonset()
      }
    }
  }, [downloadFile])


  return (
    <>
      <div id="page-wrapper" className="page-wrapper">
        <ScrollToTop
          smooth
          color="red"
          component={
            <Tooltip title="Back to top">
              <UpOutlined />
            </Tooltip>
          }
        />
        <div className="page-titles pb-2 pt-2">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12 align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 d-flex align-items-center">
                  <li className="breadcrumb-item">
                    <span className="pointer" onClick={homescreen}>
                      <i className="ri-home-3-line fs-5"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span
                      style={{
                        fontSize: "16px",
                      }}>
                      Report
                    </span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span>Rejected Assign Statutory - Bulk Upload</span>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-4 col-md-6 col-12 text-end">
              <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "15px",
                  display: filterTaskAcc ? "none" : "inline",
                }}
                onClick={filterHandle}>
                <FilterOutlined /> Filter
              </Button>
              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-2 full-mode fullscreenradius">
                <Tooltip
                  placement="left"
                  style={{
                    marginRight: "10px",
                  }}
                  title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                >
                  <FullscreenOutlined />
                </Tooltip>

              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-1">
          <div className="row">
            <div className="col-12">
              <div
                className="card-body custom-collapse"
                style={{
                  padding: "5px",
                }}>
                <Collapse
                  defaultActiveKey={["1"]}
                  style={{
                    display: exitCollapse ? "none" : "block",
                  }}>
                  <Panel header="Rejected Assign Statutory - Bulk  Upload" key={1}>
                    <div className="row">
                      <div className="col-4">
                        <label
                          style={{
                            marginBottom: "5px",
                          }}>
                          <b>Client Group :</b>{" "}
                          <span
                            style={{
                              color: "red",
                            }}>
                            *
                          </span>
                        </label>
                        <div className="form-group">
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Enter Client Group"
                              showSearch
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              style={{
                                width: "100%",
                              }}
                              value={inputValues.clientId || undefined}
                              onChange={(data, value) => {
                                if (data != undefined) {
                                  setInputValues({
                                    ...inputValues,
                                    clientId: data,
                                    clientName: value.children,
                                    legalEntityId: '',
                                    legalEntityName: '',
                                    domainId: "",
                                    domainName: "",
                                    unitId: "",
                                    unitName: "",
                                  });
                                } else {
                                  resetFields(value);
                                }
                              }}>
                              {clientInfo &&
                                clientInfo.bu_clients &&
                                clientInfo.bu_clients.length > 0 &&
                                clientInfo.bu_clients.map((item, i) => {
                                  return <Option key={item.cl_id}>{item.cl_name}</Option>;
                                })}
                            </Select>
                            {validator.current.message("Client Group", inputValues.clientId, "required", {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"}`,
                              messages: {
                                required: "Client Group Required",
                              },
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <label
                          style={{
                            marginBottom: "5px",
                          }}>
                          <b>Legal Entity :</b>{" "}
                          <span
                            style={{
                              color: "red",
                            }}>
                            *
                          </span>
                        </label>
                        <div className="form-group">
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Enter Legal Entity"
                              showSearch
                              disabled={!inputValues.clientName}
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              style={{
                                width: "100%",
                              }}
                              value={inputValues.legalEntityId || undefined}
                              onChange={(data, value) => {
                                if (data != undefined) {
                                  setInputValues({
                                    ...inputValues,
                                    legalEntityId: data,
                                    legalEntityName: value.children,
                                    domainId: "",
                                    domainName: "",
                                    unitId: "",
                                    unitName: "",
                                  });
                                } else {
                                  setInputValues({
                                    ...inputValues,
                                    legalEntityId: "",
                                    legalEntityName: "",
                                    domainId: "",
                                    domainName: "",
                                    unitId: "",
                                    unitName: "",
                                  });
                                }
                              }}>
                              {clientInfo &&
                                clientInfo.bu_legalentites &&
                                clientInfo.bu_legalentites.length > 0 &&
                                clientInfo.bu_legalentites.map((item, i) => {
                                  if (inputValues.clientId == item.cl_id) {
                                    return <Option key={item.le_id}>{item.le_name}</Option>;
                                  }
                                })}
                            </Select>
                            {validator.current.message("Legal Entity", inputValues.legalEntityId, "required", {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"}`,
                              messages: {
                                required: "Legal Entity Required",
                              },
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <label
                          style={{
                            marginBottom: "5px",
                          }}>
                          <b>Domain :</b>
                        </label>
                        <div className="form-group">
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Enter Domain"
                              showSearch
                              disabled={!inputValues.legalEntityName}
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              style={{
                                width: "100%",
                              }}
                              value={inputValues.domainId || undefined}
                              onChange={(data, value) => {
                                if (data != undefined) {
                                  setInputValues({
                                    ...inputValues,
                                    domainId: data,
                                    domainName: value.children,
                                    unitId: "",
                                    unitName: "",
                                  });
                                } else {
                                  setInputValues({
                                    ...inputValues,
                                    domainId: "",
                                    domainName: "",
                                    unitId: "",
                                    unitName: "",
                                  });
                                }
                              }}>
                              {clientInfo &&
                                clientInfo.bu_legalentites &&
                                clientInfo.bu_legalentites.length > 0 &&
                                clientInfo.bu_legalentites.map((item, i) => {
                                  if (inputValues.clientId == item.cl_id && inputValues.legalEntityId == item.le_id) {
                                    return (
                                      <>
                                        {item.bu_domains.map((domain) => {
                                          return <Option key={domain.d_id}>{domain.d_name}</Option>;
                                        })}
                                      </>
                                    );
                                  }
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 mt-3">
                        <label
                          style={{
                            marginBottom: "5px",
                          }}>
                          <b>Unit :</b>
                        </label>
                        <div className="form-group">
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Enter Unit"
                              showSearch
                              disabled={!inputValues.domainName}
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              style={{
                                width: "100%",
                              }}
                              value={inputValues.unitId || undefined}
                              onChange={(data, value) => {
                                if (data != undefined) {
                                  setInputValues({
                                    ...inputValues,
                                    unitId: data,
                                    unitName: value.children,
                                  });
                                } else {
                                  setInputValues({
                                    ...inputValues,
                                    unitId: "",
                                    unitName: "",
                                  });
                                }
                              }}>
                              {clientInfo &&
                                clientInfo.bu_units &&
                                clientInfo.bu_units.length > 0 &&
                                clientInfo.bu_units.map((item, i) => {
                                  if (inputValues.legalEntityId == item.le_id && inputValues.clientId == item.cl_id) {
                                    return <Option key={item.u_id}>{item.u_name}</Option>;
                                  }
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        type="primary"
                        shape="round"
                        className="addbutton"
                        style={{
                          background: "#0096FF",
                          borderColor: "#0096FF",
                        }}
                        icon={<EyeOutlined />}
                        onClick={showResult}>
                        Show
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
                <Modal title="Rejected Assign Statutory - Bulk Upload" className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')} footer={false} visible={filterModalVisible} onCancel={setcancelFilter}>
                  <div className="row">
                    <div className="col-4">
                      <label>
                        <b>Client Group :</b>{" "}
                        <span
                          style={{
                            color: "red",
                          }}>
                          *
                        </span>
                      </label>
                      <div className="form-group">
                        <div className="form-group">
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter Client Group"
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{
                              width: "100%",
                            }}
                            value={inputValues.clientId || undefined}
                            onChange={(data, value) => {
                              if (data != undefined) {
                                setInputValues({
                                  ...inputValues,
                                  clientId: data,
                                  clientName: value.children,
                                  legalEntityId: '',
                                  legalEntityName: '',
                                  domainId: "",
                                  domainName: "",
                                  unitId: "",
                                  unitName: "",
                                });
                              } else {
                                resetFields(value);
                              }
                            }}>
                            {clientInfo &&
                              clientInfo.bu_clients &&
                              clientInfo.bu_clients.length > 0 &&
                              clientInfo.bu_clients.map((item, i) => {
                                return <Option key={item.cl_id}>{item.cl_name}</Option>;
                              })}
                          </Select>
                          {validator.current.message("Client Group", inputValues.clientId, "required", {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"}`,
                            messages: {
                              required: "Client Group Required",
                            },
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <label>
                        <b>Legal Entity :</b>{" "}
                        <span
                          style={{
                            color: "red",
                          }}>
                          *
                        </span>
                      </label>
                      <div className="form-group">
                        <div className="form-group">
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter Legal Entity"
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{
                              width: "100%",
                            }}
                            value={inputValues.legalEntityId || undefined}
                            onChange={(data, value) => {
                              if (data != undefined) {
                                setInputValues({
                                  ...inputValues,
                                  legalEntityId: data,
                                  legalEntityName: value.children,
                                });
                              } else {
                                setInputValues({
                                  ...inputValues,
                                  legalEntityId: "",
                                  legalEntityName: "",
                                  domainId: "",
                                  domainName: "",
                                  unitId: "",
                                  unitName: "",
                                });
                              }
                            }}>
                            {clientInfo &&
                              clientInfo.bu_legalentites &&
                              clientInfo.bu_legalentites.length > 0 &&
                              clientInfo.bu_legalentites.map((item, i) => {
                                if (inputValues.clientId == item.cl_id) {
                                  return <Option key={item.le_id}>{item.le_name}</Option>;
                                }
                              })}
                          </Select>
                          {validator.current.message("Legal Entity", inputValues.legalEntityId, "required", {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"}`,
                            messages: {
                              required: "Legal Entity Required",
                            },
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <label>
                        <b>Domain :</b>
                      </label>
                      <div className="form-group">
                        <div className="form-group">
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter Domain"
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{
                              width: "100%",
                            }}
                            value={inputValues.domainId || undefined}
                            onChange={(data, value) => {
                              if (data != undefined) {
                                setInputValues({
                                  ...inputValues,
                                  domainId: data,
                                  domainName: value.children,
                                });
                              } else {
                                setInputValues({
                                  ...inputValues,
                                  domainId: "",
                                  domainName: "",
                                  unitId: "",
                                  unitName: "",
                                });
                              }
                            }}>
                            {clientInfo &&
                              clientInfo.bu_legalentites &&
                              clientInfo.bu_legalentites.length > 0 &&
                              clientInfo.bu_legalentites.map((item, i) => {
                                if (inputValues.clientId == item.cl_id && inputValues.legalEntityId == item.le_id) {
                                  return (
                                    <>
                                      {item.bu_domains.map((domain) => {
                                        return <Option key={domain.d_id}>{domain.d_name}</Option>;
                                      })}
                                    </>
                                  );
                                }
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <label>
                        <b>Unit :</b>
                      </label>
                      <div className="form-group">
                        <div className="form-group">
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter Unit"
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{
                              width: "100%",
                            }}
                            value={inputValues.unitId || undefined}
                            onChange={(data, value) => {
                              if (data != undefined) {
                                setInputValues({
                                  ...inputValues,
                                  unitId: data,
                                  unitName: value.children,
                                });
                              } else {
                                setInputValues({
                                  ...inputValues,
                                  unitId: "",
                                  unitName: "",
                                });
                              }
                            }}>
                            {clientInfo &&
                              clientInfo.bu_units &&
                              clientInfo.bu_units.length > 0 &&
                              clientInfo.bu_units.map((item, i) => {
                                if (inputValues.legalEntityId == item.le_id && inputValues.clientId == item.cl_id) {
                                  return <Option key={item.u_id}>{item.u_name}</Option>;
                                }
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      type="primary"
                      shape="round"
                      className="addbutton custom-color-modal-button"
                      icon={<EyeOutlined />}
                      onClick={showResult}>
                      Show
                    </Button>
                  </div>
                </Modal>
                {showTable ? (
                  <Card>
                    <Table columns={columns} dataSource={rejectedData.asm_rejected_data && rejectedData.asm_rejected_data} bordered pagination={false} />
                  </Card>
                ) : (
                  ""
                )}
                <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className={"newStyleModalPassword " + localStorage.getItem('currentTheme')}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-6">
                        <label className="control-label">Password : </label>
                        <span
                          style={{
                            color: "red",
                          }}>
                          *
                        </span>
                        <input
                          ref={pwdRef}
                          style={{
                            marginTop: "10px",
                          }}
                          type="password"
                          id="password"
                          name="passwordvalue"
                          className="form-control"
                          placeholder="Enter password"
                          onChange={(e) => {
                            Setpassword({
                              ...password,
                              passwordvalue: e.target.value,
                            });
                          }}
                        // value={password.passwordvalue}
                        />
                        {passwordValidator.current.message("Password", password.passwordvalue, ["required"], {
                          className: `invalid-feedback ${AddFormSubmit2 ? "show" : "hide"}`,
                          messages: {
                            regex: "Password Required",
                          },
                        })}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="form-actions text-center">
                    <Button
                      type="primary"
                      shape="round"
                      className="addbutton custom-color-modal-button"
                      icon={<PlayCircleOutlined />}
                      size="default"
                      onClick={submitPassword}>
                      Submit
                    </Button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  getClientInfo,
  RejectedASMBulkUploadData,
  RejectedASMDownloadCount,
  DownloadRejectedASMReport,
  downloadRejectStatus,
  verifyPassword,
  deleteCSV,
})(RejectedAssignStatutoryBulkUpload);
