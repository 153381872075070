import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import alertmessage from '../../../Libs/Altermessage';
import { GET_CLIENT_INFO, REJECTED_ASM_BULK_UPLOAD_DATA, REJECTED_ASM_DOWNLOAD_COUNT, DOWNLOAD_REJECTED_ASM_REPORT, TOGGLE_COMMON_LOADER, DOWNLOAD_REJECT_STATUS, REJECTED_ASBU_VERIFY_PASSWORD, DELETE_CSV } from '../../types/index';

export const getClientInfo = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetClientInfoSuccess") {
                dispatch({
                    type: GET_CLIENT_INFO,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const RejectedASMBulkUploadData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "GetRejectedASMBulkUploadDataSuccess") {
                dispatch({
                    type: REJECTED_ASM_BULK_UPLOAD_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const RejectedASMDownloadCount = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "RejecteASMUpdatedDownloadCountSuccess") {
                dispatch({
                    type: REJECTED_ASM_DOWNLOAD_COUNT,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const DownloadRejectedASMReport = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "Done") {
                dispatch({
                    type: DOWNLOAD_REJECTED_ASM_REPORT,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const downloadRejectStatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === "DownloadActionSuccess") {
                dispatch({
                    type: DOWNLOAD_REJECT_STATUS,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

// export const verifyPassword = ({ payload, executeCancel, paramid }) => async dispatch => {
//     try {
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//         const encryptkey = localStorage.getItem('formkey')
//         const head = {
//             headers: {
//                 'Caller-Name': paramid,
//                 'Content-Type': 'application/json'
//             },
//         }
//         let encoded = base64_decode(encryptkey);
//         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
//         const createRequest = HTTP.post({
//             url: `${url.CommonURL}/api/general`,
//             headers: head,
//             executeCancel,
//             body: data1
//         })
//         const executeRequest = await HTTP.execute(createRequest)
//         if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
//             (executeRequest.status === 400)) {
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
//         } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
//                 executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
//             (executeRequest.status === 401 || executeRequest.status === 503)) {
//             Toaster.error("Service Unavailable")
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//         } else {
//             const response = HTTP.parseResponse(executeRequest)
//             const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
//             console.log("verify Password", decryptData);
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             if (decryptData[0] === "VerifyPasswordSuccess") {
//                 dispatch({
//                     type: REJECTED_ASBU_VERIFY_PASSWORD,
//                     payload: {status: 'Success'},
//                 });
//             } else if (decryptData[0] === 'InvalidSessionToken') {
//                 alertmessage.sweetalert();
//             } else {
//                 Toaster.error(decryptData[0])
//             }
//         }
//     } catch (err) {
//         Toaster.error(err)
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//     }
// };
export const verifyPassword = ({ payload, executeCancel, caller, filepayload }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData123");
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            }
            else if (decryptData[0] == 'VerifyPasswordSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch(deleteCSV({
                    payload: filepayload,
                    caller: caller
                }))
            }
            else {
                Toaster.error(decryptData[0]);
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const deleteCSV = ({ payload, executeCancel, caller }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/assign_statutory`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log("delete CSV", decryptData);

            if (decryptData[0] === "GetRejectedASMDataSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success('File Removed Sussessfully')

            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            } else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};