import { STATUTORY_UNIT_FILTER, STAT_LEGALENTITYDATA, STATUTORY_PRODUCT_DATA,STAT_TABLEDATA,STAT_EXPORTDATA,STAT_EXPORTDATA_RESET} from './../../types/index'
const initialState = {
    clientunitdetails: {
        filter: [],
        legelentitydata: [],
        productdatafilter: [],
        tabledata:[],
        exportdata:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case STATUTORY_UNIT_FILTER:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    filter: payload,
                },
            };
        case STATUTORY_PRODUCT_DATA:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    productdatafilter: payload,
                },
            };
        case STAT_LEGALENTITYDATA:
            return {
                ...state,
                clientunitdetails: {
                    ...state.clientunitdetails,
                    legelentitydata: payload,
                },
            };
            case STAT_TABLEDATA:
                return {
                    ...state,
                    clientunitdetails: {
                        ...state.clientunitdetails,
                        tabledata: payload,
                    },
                };
                case STAT_EXPORTDATA:
                    return {
                        ...state,
                        clientunitdetails: {
                            ...state.clientunitdetails,
                            exportdata: payload,
                        },
                    };
                    case STAT_EXPORTDATA_RESET:
                        return {
                            ...state,
                            clientunitdetails: {
                                ...state.clientunitdetails,
                                exportdata:[],
                            },
                        };
        default:
            return state;
    }
}