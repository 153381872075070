import { CLIENTAGREEMENTFILTER, CLIENTAGREEMENTDATA, POPUP_DATA } from '../../types/index'
const initialState = {
    clientagreementfilter: {
        clientagreementmasterfilter: [],
        clientagreementdata: [],
        popUpData: []

    },
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIENTAGREEMENTFILTER:
            return {
                ...state,
                clientagreementfilter: {
                    ...state.clientagreementfilter,
                    clientagreementmasterfilter: payload,
                },
            };
        case CLIENTAGREEMENTDATA:
            return {
                ...state,
                clientagreementfilter: {
                    ...state.clientagreementfilter,
                    clientagreementdata: payload
                }
            }
        case POPUP_DATA:
            return {
                ...state,
                clientagreementfilter: {
                    ...state.clientagreementfilter,
                    popUpData: payload
                }
            }
        default:
            return state;
    }
}