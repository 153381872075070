import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import {
    GETUSER, GETREJECTEDLIST, GETBUCONSTANT, REJECTEDDOWNLOADCOUNT,
    REJECTEDFILENAME, DOWNLOADLINK, GETUSERSLIST
} from '../../types/index'

const initialState = {
    Rejectedclientunit: {
        getusers: '',
        domains: '',
        rejectedlist: '',
        downcount: '',
        rejectedfilename: '',
        downloadlink: '',
        getuserslist: ''
    },
    Buconstant: {
        getbuconstant: '',
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GETUSER: {
            console.log(payload['client_group_list'], "yyyyyyyyyyyy");
            return {
                ...state,
                Rejectedclientunit: {
                    ...state.Rejectedclientunit,
                    getusers: payload['client_group_list'],
                },
            };
        }
        case GETUSERSLIST: {
            return {
                ...state,
                Rejectedclientunit: {
                    ...state.Rejectedclientunit,
                    getuserslist: payload,
                },
            };
        }

        case GETREJECTEDLIST: {
            return {
                ...state,
                Rejectedclientunit: {
                    rejectedlist: payload['rejected_unit_data'],
                },
            }
        }

        case GETBUCONSTANT: {
            return {
                ...state,
                Buconstant: {
                    getbuconstant: payload
                }
            }
        }

        case REJECTEDDOWNLOADCOUNT: {
            return {
                ...state,
                Rejectedclientunit: {
                    downcount: payload,
                },
            };
        }
        case REJECTEDFILENAME: {
            return {
                ...state,
                Rejectedclientunit: {
                    rejectedfilename: payload,
                },
            };
        }
        case DOWNLOADLINK: {
            return {
                ...state,
                Rejectedclientunit: {
                    downloadlink: payload,
                },
            };
        }
        default:
            return state;
    }
}