import { Fragment, useState, useEffect, useRef, useMemo, useDebugValue } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Modal, Input, Table, Button, Space, Tooltip, Select, Text, Card } from 'antd';
import moment from 'moment';
import {
    PlusOutlined, CloudUploadOutlined, ArrowLeftOutlined, CloseOutlined,
    UploadOutlined, DownloadOutlined, EditOutlined, ControlOutlined, PlusCircleOutlined, FullscreenOutlined, UpOutlined, FileAddFilled
} from '@ant-design/icons';
import {
    getuploadlist, getdomainlist, getuploadfilename, Bulkfileupload,
    getamendmentdata, downloadamendment, uploadamendment, uploadamendmentdata,
    uploadsdocumentlist, Contentfileupload,
    Contentfileuploads
} from '../../Store/Action/Transactions/MappingBulkupload'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { Toaster } from '../../Libs/Toaster'
import { useDropzone } from 'react-dropzone';
import URL from './../../Libs/URL'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";

const _ = require("lodash");



const mapStateToProps = (state) => ({
    list: state.MappingBulkupload.bulkupload.list.csv_list,
    upload_limit: state.MappingBulkupload.bulkupload.list,
    domainlist: state.MappingBulkupload.bulkupload.domainlist,
    uploadfilename: state.MappingBulkupload.bulkupload.uploadfilename,
    errorlog: state.MappingBulkupload.bulkupload.errordata,
    amendmentdata: state.MappingBulkupload.bulkupload.Amendmentdata,
    uploadmessage: state.MappingBulkupload.bulkupload.uploadsuccess,
    uploaderror: state.MappingBulkupload.bulkupload.uploaderrordata,
    addUploadDatas: state.MappingBulkupload.bulkupload.addUploadData,
    amentmentFileUpload: state.MappingBulkupload.bulkupload.amentmentfile,
})

const StatutoruMappingBulkupload = ({
    getuploadlist,
    getdomainlist,
    list,
    upload_limit,
    domainlist,
    getuploadfilename,
    Contentfileuploads,
    Bulkfileupload,
    uploadfilename,
    amentmentFileUpload,
    addUploadDatas,
    errorlog,
    getamendmentdata,
    amendmentdata,
    downloadamendment,
    uploadamendment,
    uploadamendmentdata,
    uploadmessage,
    uploaderror,
    uploadsdocumentlist,
    Contentfileupload
}) => {
    const [pdfFile, setPdfFile] = useState([]);
    const [pdfFileError, setPdfFileError] = useState('');
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const [filename, setFilename] = useState([])
    const [documentupload, SetDocumentUpload] = useState()
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [modalshow, setmodalshow] = useState(false);
    const [pagename, setpagename] = useState('list');
    const [amendentmodalshow, setamendentmodalshow] = useState(false);
    const [errorshow, seterrorshow] = useState('none');
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [visibleDocUpload, setVisibleDocUpload] = useState(false)
    const [amentmentvisibleDocUpload, setAmentmentvisibleDocUpload] = useState(false)
    const [modalVisible, setmodalVisible] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const { Option } = Select;
    const [downloaddata, setdownloaddata] = useState({
        country: '',
        domaindata: '',
        domain: '',
        actdata: '',
        act: '',
    })
    const [uploaddata, setuploaddata] = useState({
        country: '',
        coutrylabel: '',
        domaindata: '',
        domain: '',
        domainlabel: '',
        selectedfile: ''
    })
    const [adddisplay, setadddisplay] = useState('block');

    useEffect(() => {
        if (upload_limit) {
            if (upload_limit.upload_more == false) {
                setadddisplay('none');
                Toaster.error('Rejected CSV files limit exceeded');
            }
        }
    }, [upload_limit])

    const columns = [
        {
            title: '#',
            width: '2%',
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            key: 'csv_name',
            width: '30%',
            // ellipsis:'false',
            render: (text, record) => {
                return (<p title={record.csv_name}>{record.csv_name}</p>)
            }
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            key: 'uploaded_on',
            width: '10%',
            align: 'center'
        },
        {
            title: 'No.of Records',
            dataIndex: 'no_of_records',
            key: 'no_of_records',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Required Documents',
            dataIndex: 'no_of_documents',
            align: 'center',
            key: 'no_of_documents',
            width: '5%'
        },
        {
            title: 'Uploaded Documents',
            dataIndex: 'uploaded_documents',
            key: 'uploaded_documents',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Remaining Documents',
            dataIndex: 'no_of_documents',
            key: 'no_of_documents',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Upload Documents',
            dataIndex: 'no_of_documents',
            key: 'false',
            align: 'center',
            width: '7%',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div className="col-md-12" style={{ marginLeft: '40%' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={() => { uploaddocuments(record) }}>
                                                <Tooltip title="Click here to Upload Doucments" placement='left'>
                                                    <CloudUploadOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )

            }
        }
    ]

    useEffect(() => {
        formValidator.current.showMessages();
        formValidator1.current.showMessages();
        formValidator2.current.showMessages();
    }, [])

    useEffect(() => {
        if (addUploadDatas != '') {
            if (addUploadDatas.doc_count == 0) {
                setrecorddata(addUploadDatas)
                Toaster.success('CSV file uploaded successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            } else {
                setVisibleDocUpload(true)
                setrecorddata(addUploadDatas)
            }
        }
    }, [addUploadDatas])

    useEffect(() => {
        if (amentmentFileUpload != '') {
            setAmentmentvisibleDocUpload(true)
            setrecorddata(amentmentFileUpload)
        }
    }, [amentmentFileUpload])
    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetStatutoryMappingCsvUploadedList",
                    {}
                ]
            }

            const domainpay = {
                "session_token": authtoken,
                "request": [
                    "GetDomains",
                    {}
                ]
            }

            const amendmentpay = {
                "session_token": authtoken,
                "request": [
                    "GetAmendments",
                    {}
                ]
            }

            getuploadlist({
                payload: pay,
                paramid: paramid
            })

            getdomainlist({
                payload: domainpay,
                paramid: paramid
            })

            getamendmentdata({
                payload: amendmentpay,
                paramid: paramid
            })
        }
    }, [isAuth])

    const [uploaddocument, setuploaddocument] = useState({
        country: '',
        domain: '',
        domaindata: '',
        updateloadedFileData: '',
    })
    const [filedetails, setfiledetails] = useState({
        filename: ''
    })
    const [domaindata, setdomaindata] = useState();

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (uploaddocument.country) {
            domainlist.bsm_domains && domainlist.bsm_domains.length > 0 && domainlist.bsm_domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(uploaddocument.country.value));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name
                    }
                    domainArray.push(tempArray)
                }
            })
            setdomaindata(domainArray)
        }
    }, [uploaddocument.country])


    const uploaddocu = (e) => {
        seterrorshow('none');
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "UploadStatutoryMappingCSV",
                    {
                        "c_id": parseInt(uploaddocument.country.value),
                        "c_name": uploaddocument.country.children,
                        "d_id": parseInt(uploaddocument.domain.value),
                        "d_name": uploaddocument.domain.children,
                        "csv_name": filedetails.filename[0].file_name,
                        "csv_data": filedetails.filename[0].file_content,
                        "csv_size": filedetails.filename[0].file_size
                    }
                ]
            }

            getuploadfilename({
                payload: pay,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (uploadfilename) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetStatus",
                    {
                        "csv_name": uploadfilename.csv_name
                    }
                ]
            }

            Bulkfileupload({
                payload: payload,
                paramid: paramid
            })
        }
    }, [uploadfilename])

    useEffect(() => {
        if (errorlog) {
            seterrorshow('block');
            // setVisibleDocUpload(true)
        }
    }, [errorlog])

    const uploadedfile = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            var fileformat = file_name.split('.')
            let file_size = file.size;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                setfiledetails({
                    ...filedetails,
                    filename: ''
                })
            } else if (file_size > file_max_size) {
                Toaster.error('Maximum File Sixe is 25MB');
                setfiledetails({
                    ...filedetails,
                    filename: ''
                })

            } else if (fileformat[0].length > 50) {
                console.log(fileformat[0].length, 'fileformat[0].length');
                Toaster.error('File Name length exceeded 50 characters');
                setfiledetails({
                    ...filedetails,
                    filename: ''
                })
            } else {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);

                    results.push(result);
                    setfiledetails({
                        ...filedetails,
                        filename: results
                    })

                });

            }
        }
    }

    const convert_to_base64 = (file, callback) => {
        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }

    const downloadtemp = () => {
        window.open(URL.CommonURL + '/csv_format/Statutory_Mapping.csv');
    }
    const [selectstate, Setselectstate] = useState("block");
    const [unselectstate, Setunselectstate] = useState("none");

    useEffect(() => {
        if (downloaddata.country != '') {
            const countryid = _.filter(amendmentdata.csv_bsm_domains, { c_id: parseInt(downloaddata.country) });
            setdownloaddata({
                ...downloaddata,
                domaindata: countryid
            })
        }
    }, [downloaddata.country])

    useEffect(() => {
        if (downloaddata.domain != '') {
            const domainid = _.filter(amendmentdata.csv_bsm_acts, { c_id: parseInt(downloaddata.country), d_id: parseInt(downloaddata.domain) });
            setdownloaddata({
                ...downloaddata,
                actdata: domainid
            })
        }
    }, [downloaddata.domain])

    useEffect(() => {
        if (uploaddata.country != '') {
            const countryid = _.filter(amendmentdata.csv_bsm_domains, { c_id: parseInt(uploaddata.country) });
            setuploaddata({
                ...uploaddata,
                domaindata: countryid
            })
        }
    }, [uploaddata.country])



    const Handelact = (obj) => {
        let allArray = [];
        let alllabel = [];
        if (obj.includes("all")) {
            if (downloaddata.actdata && downloaddata.actdata.length > 0) {
                let temp = [];
                for (let i in downloaddata.actdata) {
                    let Code = downloaddata.actdata[i].s_id;
                    let label = downloaddata.actdata[i];
                    temp.push(Code);
                    allArray.push(Code);
                }

                setdownloaddata({
                    ...downloaddata,
                    act: allArray
                });
            }
            Setselectstate("none");
            Setunselectstate("block");
        } else if (obj.includes("unselect")) {
            let temp = [];
            setdownloaddata({
                ...downloaddata,
                act: [],
            });
            Setselectstate("block");
            Setunselectstate("none");
        } else if (obj.includes("unselect")) {
            let temp = [];
            Setselectstate("block");
            Setunselectstate("none");
        } else {
            if (obj.length == downloaddata.actdata.length) {
                Setselectstate("none");
                Setunselectstate("block");
            } else {
                Setselectstate("block");
                Setunselectstate("none");
            }
            let arr = [];
            for (let i in obj) {
                const label = _.filter(downloaddata.actdata, { s_id: obj[i] });
                arr.push(label);
            }
            const concat = [].concat.apply([], arr);
            setdownloaddata({
                ...downloaddata,
                act: obj,

            });
        }
    };

    const downloadtemplate = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    'DownloadCSVDetails',
                    {
                        'country_id': parseInt(downloaddata.country),
                        'domain_id': parseInt(downloaddata.domain),
                        'bu_act_id': downloaddata.act
                    }]
            }

            downloadamendment({
                payload: pay,
                paramid: paramid
            })
            // setdownloaddata({
            //     ...downloaddata,
            //     country: '',
            //     domain: '',
            //     act: ''
            // })
            setAddFormSubmit1(false);
        }
    }

    const uploaddocu1 = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            let file_size = file.size;
            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                return;
            } else if (file_size > file_max_size) {
                Toaster.error('Maximum File Sixe is 25MB');
                return;

            } else {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);
                    results.push(result);
                    setuploaddata({
                        ...uploaddata,
                        selectedfile: results
                    })
                });

            }
        }
    }

    const uploadamendmentdocu = () => {
        setAddFormSubmit2(true);
        if (formValidator2.current.allValid()) {
            if (uploaddata.selectedfile.length > 0) {
                const pay = {
                    "session_token": authtoken,
                    "request": [
                        "UploadAmendmentStatutoryMappingCSV",
                        {
                            "c_id": parseInt(uploaddata.country),
                            "c_name": uploaddata.coutrylabel,
                            "d_id": parseInt(uploaddata.domain),
                            "d_name": uploaddata.domainlabel,
                            "csv_name": uploaddata.selectedfile[0].file_name,
                            "csv_data": uploaddata.selectedfile[0].file_content,
                            "csv_size": uploaddata.selectedfile[0].file_size
                        }
                    ]
                }
                uploadamendment({
                    payload: pay,
                    paramid: paramid
                })
                // setuploaddata({
                //     ...uploaddata,
                //     country: '',
                //     domain: '',
                //     coutrylabel: '',
                //     domainlabel: '',
                //     selectedfile: ''
                // })
                setAddFormSubmit2(false);
            } else {
                Toaster.error('File Required');
            }
        }
    }

    useEffect(() => {
        if (uploadmessage) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetStatus",
                    {
                        "csv_name": uploadmessage.csv_name
                    }
                ]
            }
            uploadamendmentdata({
                payload: pay,
                paramid: paramid
            })
        }

    }, [uploadmessage])

    const [uploaderrorlog, setuploaderrotlog] = useState();
    const [uploaderrorshow, setuploaderrorshow] = useState('none');
    useEffect(() => {
        if (uploaderror) {
            setuploaderrotlog(uploaderror);
            setuploaderrorshow('block');
        }
    }, [uploaderror])

    const upload_errorlog_download = (format, data) => {
        let datacolumn = data.split('.');
        let filename = datacolumn[0]

        let path = 'invalid_file/';
        let downloadfile;

        if (format == 'csv') {
            downloadfile = path + 'csv/' + filename + '.csv'
        } else if (format == 'excel') {
            downloadfile = path + 'xlsx/' + filename + '.xlsx'
        } else if (format == 'ods') {
            downloadfile = path + 'ods/' + filename + '.ods'
        } else {
            downloadfile = path + 'txt/' + filename + '.txt'
        }
        window.open(`${URL.CommonURL}/` + downloadfile);
    }

    const [recorddata, setrecorddata] = useState();
    console.log(recorddata, 'recorddata');
    const uploaddocuments = (re) => {
        setrecorddata(re)
        setmodalVisible(true);
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetDomains",
                {}
            ]
        }
        uploadsdocumentlist({
            payload: pay,
            paramid: paramid
        })
    }

    const modalcancel = () => {
        setmodalVisible(false);
        setuploaddoc_content({
            filename: ""
        })
    }

    const downloaduploadfile = (filename) => {
        let path = 'uploaded_file/csv/';
        window.open(`${URL.CommonURL}/` + path + filename);
    }
    const [adduploaddoc_content, setAdduploaddoc_content] = useState({
        filename: ''
    })
    const [uploaddoc_content, setuploaddoc_content] = useState({
        filename: ''
    })
    const upload_document = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400;
        let validFileNames = recorddata.doc_names;
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            let file_name = file.name;
            let status = validFileNames.includes(file_name);
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            // if (recorddata.csv_name === file_name) {
            if (status) {
                if (fileRegex.test(f_name) === false) {
                    Toaster.error('Invalid Format');
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    Toaster.error('Maximum File Size is 25MB');
                    return;

                }
                if (file) {
                    convert_to_base64(file, function (file_content) {
                        var result = uploadFileFormat(file_size, file_name, file_content);
                        results.push(result);
                        setuploaddoc_content({
                            ...uploaddoc_content,
                            filename: results
                        })
                    });
                }
            } else {
                Toaster.error('File MisMatched');
            }
        }
    }

    const Addupload_document = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            let file_size = file.size;

            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                return;
            } else if (file_size > file_max_size) {
                Toaster.error('Maximum File Size is 25MB');
                return;

            } else {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);
                    results.push(result);
                    setAdduploaddoc_content({
                        ...adduploaddoc_content,
                        filename: results
                    })
                });
            }
        }
    }

    const downloaderrorfile = (format, data) => {
        let datacolumn = data.split('.');
        let filename = datacolumn[0]
        let path = 'invalid_file/';
        let downloadfile;
        if (format == 'csv') {
            downloadfile = path + 'csv/' + filename + '.csv'
        } else if (format == 'excel') {
            downloadfile = path + 'xlsx/' + filename + '.xlsx'
        } else if (format == 'ods') {
            downloadfile = path + 'ods/' + filename + '.ods'
        } else {
            downloadfile = path + 'txt/' + filename + '.txt'
        }
        window.open(`${URL.CommonURL}/` + downloadfile);
    }

    const uploadselectedfile = (e) => {
        const pay = uploaddoc_content.filename;
        Contentfileupload({
            payload: pay,
            csv_id: e,
            token: authtoken,
            paramid: paramid
        })
    }
    const uploadselectedfiles = (e) => {
        const pay = uploaddoc_content.filename;
        Contentfileuploads({
            payload: pay,
            csv_id: e,
            token: authtoken,
            paramid: paramid
        })
    }
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <Fragment>
            {pagename === 'list' ?
                <div id="page-wrapper" className="page-wrapper">
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles py-1 page-title-sticky">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span>Statutory Mapping List - Bulk Upload</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-6 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <div style={{ marginRight: '15px' }}>
                                    <Tooltip title="Add Task"><Button type="primary" shape="round" className='addbutton' style={{ display: adddisplay }}
                                        icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                            setpagename('add')
                                        }}>
                                        Add
                                    </Button></Tooltip>
                                </div>
                                <div>
                                    <Tooltip title="Add Amendment"><Button type="primary" shape="round" className='addbutton'
                                        icon={<EditOutlined />} size='default' onClick={() => {
                                            setpagename('amendment');
                                        }}>
                                        Amendment
                                    </Button></Tooltip>
                                </div>
                                <button
                                    onClick={(e) => {
                                        fullscreenMode();
                                    }}
                                    className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                                >
                                    <Tooltip
                                        placement="left"
                                        style={{ marginRight: "10px", }}
                                        title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                    >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0 service-provider-table">

                        <Table
                            className='userprivclass'
                            columns={columns}
                            dataSource={list}
                            bordered
                            // pagination={{
                            //     defaultPageSize: dataTableProperties.pagesize,
                            //     showSizeChanger: dataTableProperties.sizechanger,
                            //     pageSizeOptions: dataTableProperties.pageSizeOptions
                            // }}
                            pagination={false}
                        />

                    </div>
                    <Modal title="Upload File" visible={modalVisible} footer={null} maskClosable={false} className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                        onCancel={modalcancel} >
                        <div className='col-md-12'>
                            <div className='row' style={{ marginTop: '10px' }}>
                                <div className='col-md-12'>
                                    <label style={{ marginLeft: "15px" }}><b>Country : </b><span style={{ color: "red" }}> *</span></label>
                                    <span style={{ padding: '25px' }}>{recorddata && recorddata.c_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-12' style={{ paddingTop: '0.5rem' }}>
                                    <label style={{ marginLeft: "15px" }}><b>Domain : </b><span style={{ color: "red" }}> *</span></label>
                                    <span style={{ padding: '25px' }}>{recorddata && recorddata.d_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-12' style={{ paddingTop: '0.5rem' }}>
                                    <label style={{ marginLeft: "15px" }}><b>Select File : </b><span style={{ color: "red" }}> *</span></label>
                                    <span style={{ padding: '25px' }}>{recorddata && recorddata.csv_name}</span>
                                    <span className='pointer' onClick={(e) => downloaduploadfile(recorddata.csv_name)}><Tooltip title='Download Document'><DownloadOutlined /></Tooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 '>
                            <div className='row'>
                                <div className='col-md-8' style={{ paddingTop: '0.5rem' }}>
                                    <label style={{ marginLeft: "15px" }}><b>Select Document: </b><span style={{ color: "red" }}> *</span></label>
                                    {/* <input type="file" name="notification" multiple className="form-control" value='' onChange={(e) => {
                                        upload_document(e)
                                    }} /> */}
                                    <Input accept=".csv,.doc,.docx" className='form-control' name="notification" value={''} multiple type='file' style={{ width: '70%' }} onChange={(e) => {
                                        upload_document(e)
                                    }} />
                                    <p style={{ textAlign: "center", marginLeft: '40px' }}>Click or drag file to this area to upload</p>
                                </div>
                                <div>
                                    {uploaddoc_content.filename && uploaddoc_content.filename.map((data) => {
                                        return <span>{data.file_name}<br /></span>
                                    })}
                                </div>
                                <div className='col-md-4' style={{ paddingTop: '2rem' }}>
                                    <span >Required Documents :{recorddata && recorddata.no_of_documents}</span><br />
                                    <span>Uploaded Documents: {recorddata && recorddata.uploaded_documents}</span><br />
                                    <span style={{ color: 'red' }}>Remaining Documents : {recorddata && recorddata.no_of_records}</span>

                                </div>
                                <div className='text-center'><Button type="primary" shape="round" className='addbutton mt-2 custom-color-modal-button'
                                    icon={<UploadOutlined />} size='default' onClick={() => { uploadselectedfile(recorddata.csv_id) }}>
                                    Upload
                                </Button></div>
                            </div>
                        </div>
                    </Modal>
                </div> : pagename == 'add' ?
                    <div className="page-wrapper">
                        <ScrollToTop smooth component={
                            <Tooltip title="Back to top">
                                <UpOutlined />
                            </Tooltip>
                        }
                        />
                        <div className="page-titles pb-2 pt-1">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    {/* <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <Link>Transactions</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <Link>Statutory Mapping - Bulk Upload</Link>
                                            </li>
                                        </ol>
                                    </nav> */}
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transaction</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span >Statutory Mapping - Bulk Upload </span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div
                                    className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" >
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className='addbutton'
                                        style={{
                                            background: "#0096FF",
                                            borderColor: "#0096FF",
                                            marginRight: "10px"
                                        }}
                                        icon={<ArrowLeftOutlined />
                                        }
                                        size='default'
                                        onClick={() => {
                                            window.location.reload();
                                        }}>Back</Button>

                                </div>
                            </div>
                        </div>
                        <div className="container-fluid pt-1">
                            <Card >
                                <div className='col-md-12'>
                                    <div className='row' style={{ marginTop: '10px' }}>
                                        <div className='col-4'>
                                            <label style={{ float: "left" }}><b>Country :</b><span style={{ color: "red" }}> *</span></label>
                                            <Select
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Country"
                                                showSearch
                                                optionFilterProp="children"
                                                value={uploaddocument.country}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                                onChange={(data, value) => {
                                                    if (data == undefined) {
                                                        setuploaddocument({
                                                            ...uploaddocument,
                                                            country: '',
                                                            domain:''
                                                        })
                                                    } else {
                                                        setuploaddocument({
                                                            ...uploaddocument,
                                                            country: value
                                                        })
                                                    }
                                                }}>
                                                {
                                                    domainlist.bsm_countries && domainlist.bsm_countries.length > 0 && domainlist.bsm_countries.map((item) => {
                                                        return <Option key={item.country_id}>{item.country_name}</Option>
                                                    })
                                                }
                                            </Select>
                                            {formValidator.current.message(
                                                'country',
                                                uploaddocument.country,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Select Country',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-4'>
                                            <label style={{ float: "left" }}><b>Domain :</b><span style={{ color: "red" }}> *</span></label>
                                            <Select
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Domain"
                                                value={uploaddocument.domain}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: "100%" }}
                                                onChange={(data, value) => {
                                                    if (data == undefined) {
                                                        setuploaddocument({
                                                            ...uploaddocument,
                                                            domain: ''
                                                        })
                                                    } else {
                                                        setuploaddocument({
                                                            ...uploaddocument,
                                                            domain: value
                                                        })
                                                    }
                                                }}>
                                                {
                                                    domaindata && domaindata.length > 0 && domaindata.map((item) => {
                                                        return <Option key={item.domain_id}>{item.domain_name}</Option>
                                                    })
                                                }
                                            </Select>
                                            {formValidator.current.message(
                                                'domain',
                                                uploaddocument.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Select Domain',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-4'>
                                            <label style={{ float: "left" }}><b>Select Document :</b><span style={{ color: "red" }}> *</span></label>
                                            <Input accept=".csv" className='form-control' type='file' value={''} onChange={(e) => {
                                                uploadedfile(e)
                                                setuploaddocument({
                                                    ...uploaddocument,
                                                    updateloadedFileData: e.target.value
                                                })
                                            }} />
                                            <h6>[Accepted file format .csv]</h6>
                                            {formValidator.current.message(
                                                'file',
                                                uploaddocument.updateloadedFileData,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Select Document',
                                                    }
                                                })}
                                            {filedetails && filedetails.filename != '' && filedetails.filename.length > 0 && filedetails.filename[0].file_name}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4 text-center p-2'>
                                            <Button type="primary" shape="round" className='addbutton'
                                                icon={<UploadOutlined />} size='default' onClick={uploaddocu}>
                                                Upload
                                            </Button><br />
                                        </div>
                                        <span className='text-center'><Link onClick={downloadtemp}>DownloadTemplate</Link></span>
                                    </div>
                                </div>
                                {visibleDocUpload == true ?
                                    <>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-2' >
                                                    <label style={{ float: "left" }}><b>Uploaded File </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4' >
                                                    <span >{recorddata && recorddata.csv_name}</span>
                                                    <span className='pointer' onClick={(e) => downloaduploadfile(recorddata.csv_name)}><Tooltip title='Download Document'><DownloadOutlined /></Tooltip>
                                                    </span>
                                                    {/* <label>{uploadfile && uploadfile.filename[0] && uploadfile.filename[0].file_name && uploadfile.filename[0].file_name}</label> */}
                                                </div>
                                                <div className='col-md-3'></div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-3'>
                                                    <label ><b>Select Documents : </b><span style={{ color: "red" }}> *</span></label>
                                                </div>
                                                <div className='col-md-6'></div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-5' style={{ padding: 0 }} >
                                                    <section className="container" style={{ boxShadow: "0px 0px 1px 1px grey" }}>
                                                        <div>
                                                            <input type="file" name="notification" multiple className="form-control" value={''} onChange={(e) => {
                                                                Addupload_document(e)
                                                            }} />
                                                            <p style={{ textAlign: "center" }}>Click or drag file to this area to upload</p>
                                                        </div>
                                                    </section>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-6'>
                                                    <aside>
                                                        <h5>Files</h5>
                                                    </aside>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button type="primary" shape="round" className='addbutton'
                                                icon={<UploadOutlined />} size='default' onClick={() => { uploadselectedfiles(recorddata.csv_id) }}>
                                                Upload
                                            </Button>
                                        </div>
                                    </>
                                    : ''}

                            </Card>
                            <div style={{ display: errorshow }}>
                                <div className="row">
                                    <div style={{ textAlign: 'center', padding: '16px' }}>
                                        <Button type="primary" shape="round" className='addbutton'
                                            icon={<DownloadOutlined />} size='default'>
                                            Valid And Invalid Data
                                        </Button><br />
                                        <span onClick={(e) => { downloaderrorfile('excel', errorlog.invalid_file) }}><Link>Download Excel | </Link></span>
                                        <span onClick={(e) => { downloaderrorfile('csv', errorlog.invalid_file) }}><Link>Download CSV | </Link></span>
                                        <span onClick={(e) => { downloaderrorfile('ods', errorlog.invalid_file) }}> <Link>Download ODS | </Link></span>
                                        <span onClick={(e) => { downloaderrorfile('text', errorlog.invalid_file) }}><Link>Download Text</Link></span>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="alert alert-success bg-success  m-3" style={{ opacity: 0.8 }} role="alert">
                                            <p className='h5 text-white'>Uploaded Data - Summary</p>
                                            <span className='d-block'>Total No.of Records: {errorlog.total}</span>
                                            <span className='d-block'>Valid Records: {errorlog.valid}</span>
                                            <span className='d-block'>Invalid Records: {errorlog.invalid}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="alert alert-danger bg-danger m-3" style={{ opacity: 0.8 }} role="alert">
                                            <p className='h5 text-white'>Invalid data - Summary</p>
                                            <span className='d-block'>Mandatory Field Blank: {errorlog.mandatory_error}</span>
                                            <span className='d-block'>Duplicate Compliance: {errorlog.duplicate_error}</span>
                                            <span className='d-block'>Master Data Inactive: {errorlog.inactive_error}</span>
                                            <span className='d-block'>Frequency Data Invalid: {errorlog.invalid_frequency_error}</span>
                                            <span className='d-block'>Max Length Exceeded: {errorlog.max_length_error}</span>
                                            <span className='d-block'>Invalid Character: {errorlog.invalid_char_error}</span>
                                            <span className='d-block'>Invalid Data: {errorlog.invalid_data_error}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <Modal title="Assigned Client Unit/Vendor" visible={amendentmodalshow} footer={null} maskClosable={false} className={"Assignunit " + localStorage.getItem('currentTheme')}>
                        </Modal>
                    </div> :
                    <div className="page-wrapper">
                        <div className="page-titles pb-2 pt-1">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    {/* <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <Link>Transactions</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <Link>Statutory Mapping Bulk Upload - Amendment</Link>
                                            </li>
                                        </ol>
                                    </nav> */}
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transaction</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span>Statutory Mapping Bulk Upload - Amendment </span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div
                                    className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" >
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className='addbutton'
                                        style={{
                                            background: "#0096FF",
                                            borderColor: "#0096FF",
                                            marginRight: "10px"
                                        }}
                                        icon={<ArrowLeftOutlined />
                                        }
                                        size='default'
                                        onClick={() => {
                                            window.location.reload();
                                        }}>Back</Button>

                                </div>
                            </div>
                        </div>
                        <div className="container-fluid pt-1">
                            <div className="row mx-3" >
                                <Card className='col-md-6' style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                                    <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Download file</h4>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Country : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9' style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    name='country'
                                                    placeholder="Select Country"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '90%' }}
                                                    value={downloaddata.country || undefined}
                                                    onChange={(data, value) => {
                                                        setdownloaddata({
                                                            ...downloaddata,
                                                            country: value.value
                                                        })
                                                    }}>
                                                    {amendmentdata && amendmentdata.csv_bsm_countries.length && amendmentdata.csv_bsm_countries.map((item, i) => {
                                                        return (
                                                            <Option key={item.country_id}>
                                                                {item.country_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator1.current.message(
                                                    'country',
                                                    downloaddata.country,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Domain : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9' style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Select Domain"
                                                    name='domain'
                                                    showSearch
                                                    value={downloaddata.domain || undefined}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '90%' }}
                                                    onChange={(data, value) => {
                                                        setdownloaddata({
                                                            ...downloaddata,
                                                            domain: value.value
                                                        })
                                                    }}>
                                                    {downloaddata.domaindata != '' && downloaddata.domaindata.length > 0 && downloaddata.domaindata.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id} >
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator1.current.message(
                                                    'domain',
                                                    downloaddata.domain,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Act : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9' style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    mode="multiple"
                                                    placeholder="Select Act"
                                                    showSearch
                                                    name='act'
                                                    style={{ width: '90%' }}
                                                    maxTagCount="responsive"
                                                    value={downloaddata.act || undefined}
                                                    onChange={(option) => Handelact(option)}
                                                >
                                                    <Option key="all" value="all" style={{ display: selectstate }}> ---SELECT ALL---  </Option>
                                                    <Option key="unselect" value="unselect" style={{ display: unselectstate }}> --UNSELECT ALL-- </Option>
                                                    {downloaddata.actdata != '' && downloaddata.actdata.length > 0 && downloaddata.actdata.map((item, i) => {
                                                        return (
                                                            <Option key={item.s_id} value={item.s_id}>
                                                                {item.s_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator1.current.message(
                                                    'act',
                                                    downloaddata.act,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Act Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row' style={{ marginTop: "20px" }}>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4' style={{ marginLeft: '16px' }} >
                                                <Button type="primary" shape="round" className='addbutton'
                                                    icon={<DownloadOutlined />} size='default' onClick={downloadtemplate}>
                                                    Download
                                                </Button>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Card>

                                <Card className='col-md-6' style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                                    <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Upload file</h4>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Country : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9' style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    name="country"
                                                    placeholder="Select Country"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '90%' }}
                                                    onChange={(data, value) => {
                                                        setuploaddata({
                                                            ...uploaddata,
                                                            country: value.value,
                                                            coutrylabel: value.children
                                                        })
                                                    }}>
                                                    {amendmentdata && amendmentdata.csv_bsm_countries.length && amendmentdata.csv_bsm_countries.map((item, i) => {
                                                        return (
                                                            <Option key={item.country_id}>
                                                                {item.country_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator2.current.message(
                                                    'country',
                                                    uploaddata.country,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Domain : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9' style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    name='domain'
                                                    placeholder="Select Domain"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '90%' }}
                                                    onChange={(data, value) => {
                                                        setuploaddata({
                                                            ...uploaddata,
                                                            domain: value.value,
                                                            domainlabel: value.children
                                                        })
                                                    }}>
                                                    {uploaddata.domaindata != '' && uploaddata.domaindata.length > 0 && uploaddata.domaindata.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id} >
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator2.current.message(
                                                    'domain',
                                                    uploaddata.domain,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3' >
                                                <label style={{ float: "left" }}><b>Select File : </b><span style={{ color: "red" }}> *</span></label>
                                            </div>
                                            <div className='col-md-9 ' style={{ padding: 0 }}>
                                                {/* <Input type="file" accept=".csv" className='form-control' name='selectfile' style={{ width: '90%' }} onChange={(e) => {
                                                    uploaddocu1(e)
                                                }} /> */}
                                                <div className="d-flex">
                                                    <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined /></label>
                                                    <input type="file" id="file-upload" accept=".csv" className='mx-2' value={''} style={{ width: '35%' }} onChange={(e) => {
                                                        uploaddocu1(e)
                                                    }} />
                                                    <span style={{ color: 'red' }}>Accept Only CSV Format</span>
                                                </div>
                                                <span className='mt-4'>{uploaddata.selectedfile && uploaddata.selectedfile[0].file_name}</span>


                                                {formValidator2.current.message(
                                                    'uploadfile',
                                                    uploaddata.selectedfile,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'File Required',
                                                        }
                                                    })}


                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row' style={{ marginTop: "20px" }}>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4' style={{ marginLeft: '30px', marginTop: '7px' }} >
                                                <Button type="primary" shape="round" className='addbutton'
                                                    icon={<UploadOutlined />} size='default' onClick={uploadamendmentdocu}>
                                                    Upload
                                                </Button>
                                            </div>
                                            <div className='col-md-4'></div>

                                        </div>
                                    </div>
                                </Card>
                                {amentmentvisibleDocUpload == true ?
                                    <Card style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                                        <>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-2' >
                                                        <label style={{ float: "left" }}><b>Uploaded File </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <span >{recorddata && recorddata.csv_name}</span>
                                                        <span className='pointer' onClick={(e) => downloaduploadfile(recorddata.csv_name)}><Tooltip title='Download Document'><DownloadOutlined /></Tooltip>
                                                        </span>
                                                        {/* <label>{uploadfile && uploadfile.filename[0] && uploadfile.filename[0].file_name && uploadfile.filename[0].file_name}</label> */}
                                                    </div>
                                                    <div className='col-md-3'></div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'>
                                                        <label ><b>Select Documents</b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-6'></div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-5' style={{ padding: 0 }} >
                                                        <section className="container" style={{ boxShadow: "0px 0px 1px 1px grey" }}>
                                                            <div>
                                                                <input type="file" name="notification" multiple className="form-control" onChange={(e) => {
                                                                    Addupload_document(e)
                                                                }} />
                                                                <p style={{ textAlign: "center" }}>Click or drag file to this area to upload</p>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className="row">
                                                <div className='col-md-4'></div>
                                                <div className='col-md-3'>
                                                    {adduploaddoc_content.filename && adduploaddoc_content.filename.map((data) => {
                                                        return <span>{data.file_name}<br /></span>
                                                    })}
                                                </div>
                                                <div className="col-md-5">

                                                </div>
                                            </div>
                                        </div> */}
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-6'>
                                                        <aside>
                                                            <h5>Files</h5>
                                                            {/* {adduploaddoc_content.filename && adduploaddoc_content.filename.map((data) => {
                                                            return <span>{data.file_name}<br /></span>
                                                        })} */}
                                                            {/* <ul>{files}</ul> */}
                                                        </aside>
                                                    </div>
                                                    <div className='col-md-3'></div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' >
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "70px", marginTop: "20px" }}
                                                            icon={<UploadOutlined />} size='default' onClick={() => { uploadselectedfiles(recorddata.csv_id) }}>
                                                            Upload
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </>
                                    </Card> : ''}
                                <div style={{ display: uploaderrorshow }}>
                                    {uploaderrorlog ?
                                        <div className="row">
                                            <div style={{ textAlign: 'center', padding: '16px' }}>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    icon={<DownloadOutlined />} size='default'>
                                                    Valid And Invalid Data
                                                </Button><br />
                                                <span onClick={(e) => { upload_errorlog_download('excel', uploaderrorlog.invalid_file) }}><Link>Download Excel | </Link></span>
                                                <span onClick={(e) => { upload_errorlog_download('csv', uploaderrorlog.invalid_file) }}><Link>Download CSV |</Link></span>
                                                <span onClick={(e) => { upload_errorlog_download('ods', uploaderrorlog.invalid_file) }}> <Link>Download ODS|</Link></span>
                                                <span onClick={(e) => { upload_errorlog_download('text', uploaderrorlog.invalid_file) }}><Link>Download Text</Link></span>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="alert alert-success bg-success  m-3" style={{ opacity: 0.8 }} role="alert">
                                                    <p className='h5 text-white'>Uploaded Data - Summary</p>
                                                    <span className='d-block'>Total No.of Records: {uploaderrorlog.total}</span>
                                                    <span className='d-block'>Valid Records: {uploaderrorlog.max_length_error}</span>
                                                    <span className='d-block'>Invalid Records: {uploaderrorlog.invalid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="alert alert-danger bg-danger m-3" style={{ opacity: 0.8 }} role="alert">
                                                    <p className='h5 text-white'>Invalid data - Summary</p>
                                                    <span className='d-block'>Master Data Inactive: {uploaderrorlog.inactive_error}</span>
                                                    <span className='d-block'>Mandatory Field Blank: {uploaderrorlog.mandatory_error}</span>
                                                    <span className='d-block'>Invalid Data: {uploaderrorlog.invalid}</span>
                                                    <span className='d-block'>Duplicate Document Name: {uploaderrorlog.duplicate_error}</span>
                                                    <span className='d-block'>Invalid Character: {uploaderrorlog.invalid_char_error}</span>
                                                </div>
                                            </div>
                                        </div> :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    getuploadlist,
    getdomainlist,
    getuploadfilename, Bulkfileupload,
    getamendmentdata,
    downloadamendment,
    uploadamendment,
    uploadamendmentdata,
    uploadsdocumentlist,
    Contentfileupload,
    Contentfileuploads
})(StatutoruMappingBulkupload);


 // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    //     accept: {
    //         'application/pdf': ['.pdf']
    //     },
    //     multiple: true,
    //     onDrop: files => {

    //         const fileType = ['application/pdf'];
    //         let selectedFile = files[0];
    //         if (selectedFile) {
    //             if (selectedFile && fileType.includes(selectedFile.type)) {
    //                 let reader = new FileReader();
    //                 reader.readAsDataURL(selectedFile);
    //                 reader.onloadend = (e) => {
    //                     // let temp = []
    //                     // temp.push(e.target.result)
    //                     setPdfFile(e.target.result);
    //                     // setPdfFile([...pdfFile, ...temp])
    //                     setPdfFileError('');
    //                 }
    //             }
    //             else {
    //                 setPdfFile(null);
    //                 setPdfFileError('Please select valid pdf file');
    //             }
    //         }
    //         else {

    //         }

    //         // var sessionId = client_mirror.getSessionToken();
    //         var fileBaseUrl = "/clienttemp/upload?session_id="
    //         var results = [];
    //         const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    //         let files1 = files;
    //         let file_max_size = 26214400
    //         for (var i = 0; i < files1.length; i++) {
    //             var file = files1[i];
    //             var file_name = file.name;
    //             setFilename(file_name)


    //             const f_name = file_name.substring(0, file_name.lastIndexOf('.'));

    //             if (fileRegex.test(f_name) === false) {
    //                 Toaster.error('Invalid Format');
    //                 return;
    //             }

    //             let file_size = file.size;
    //             if (file_size > file_max_size) {
    //                 Toaster.error('Maximum File Sixe is 25MB');
    //                 return;

    //             }

    //             if (file) {

    //                 convert_to_base641(file, function (file_content) {

    //                     var result = uploadFileFormat1(file_size, file_name, file_content);
    //                     // let tenp1 = []
    //                     // tenp1.push(result)
    //                     results.push(result);
    //                     SetDocumentUpload(results)
    //                     // SetDocumentUpload([...documentupload,...tenp1])

    //                 });

    //             }

    //         }
    //     }


    // });