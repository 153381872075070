import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, Button, Table, Pagination, Modal, Card, Tooltip, Empty } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, EyeOutlined, FilterOutlined, FullscreenOutlined, UpOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { StatutoryMappingReportFilters, StatutoryMappingReportData } from './../../../src/Store/Action/Reports/StatutoryMappingReport';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from "../Scrollbar";


const mapStateToProps = (state) => ({
    statutorymappingresult: state.StatutoryMappingReport.statutorymapping,
})

const StatutoryMappingReport = ({
    StatutoryMappingReportFilters,
    StatutoryMappingReportData,
    statutorymappingresult: { StatutoryMappingReportSuccess, StatutoryMappingReportShow }
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    let [index, setIndex] = useState(0)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [Country, setCountry] = useState([])
    const [act, setAct] = useState([])
    const [domain, setDomain] = useState([])
    const [organization, setOrganization] = useState([])
    const [statutorynature, setStatutorynature] = useState([])
    const [compliancefrequency, setComplianceFrequency] = useState([])
    const [criticality, setCriticality] = useState([])
    const [geography, setGeography] = useState([])
    const [showstatutory, setShowstatutory] = useState([])
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);

    const [applicationModal, setApplicationModal] = useState(false)
    const [geo, setGeo] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        currentpage: 1,
        pageSizeOptions: ['10', '20', '30']
    })
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [domainName, setDomainName] = useState([]);
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: '',
        domain_name: '',
        act: '',
        act_name: '',
        from_date: "",
        to_date: "",
        organization: '',
        organization_name: '',
        statutorynature: 0,
        statutorynature_name: '',
        compliance_frequency: 0,
        compliance_frequency_name: 'All',
        geography: 0,
        geography_name: '',
        criticality: 0,
        criticality_name: 'All'
    })

    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    console.log(pageSize, 'pageSize')
    const [current, setCurrent] = useState(1)
    console.log(current, 'page current')
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [parentNames, setParentNames] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    console.log(page, 'page')
    const [filteredFrequency, setFilteredFrequency] = useState([]);
    const [updatedArray, setUpdatedArray] = useState([])
    const [filteredFields, setFilteredFields] = useState({
        filteredDomain: [],
        filteredNature: [],
        filteredAct: [],
    })
    const [exitCollapse, setExitCollapse] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    console.log(domainName, 'domain')

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(false);
        setFilterModalVisible(true);
    };


    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '60px',
            ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * pageSize + index + 1
            }
        },
        {
            title: '`Organization`',
            dataIndex: 'industry_names',
            key: 'industry_names',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Statutory Nature',
            dataIndex: 'statutory_nature_name',
            key: 'statutory_nature_name',
            width: '150px',
            ellipsis: true,

        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_pro_map',
            key: 's_pro_map',
            width: '170px',
            ellipsis: true,
        },
        {
            title: 'Compliance Task',
            dataIndex: 'c_task',
            key: 'c_task',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Criticality',
            dataIndex: 'criticality_name',
            key: 'criticality_name',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Compliance Description',
            dataIndex: 'description',
            key: 'description',
            width: '190px',
            ellipsis: true,
        },
        {
            title: 'Penal Consequences',
            dataIndex: 'p_consequences',
            key: 'p_consequences',
            width: '170px',
            ellipsis: true,
        },
        {
            title: 'Compliance Occurrence',
            dataIndex: 'summary',
            key: 'summary',
            width: '190px',
            ellipsis: true,
        },
        {
            title: 'Applicable Location',
            // dataIndex: 'geo_maps',
            // key: 'geo_maps',
            dataIndex: false,
            key: false,
            width: '180px',
            render: (text, record) => {
                return (
                    <>
                        <Link onClick={() => application(record.geo_maps)}>Applicable Location</Link>
                    </>
                )
            },
        }
    ];

    const column = [
        {
            title: 'Applicable location',
            dataIndex: false,
            key: false,
            width: '150px',
            ellipsis: true,
        }
    ]

    useEffect(() => {
        if (isAuth) {
            StatutoryMappingReportFilters({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportFilters",
                        {}
                    ]
                },
                paramid: paramid
            })
        }
    }, [isAuth, legalstate])

    useEffect(() => {
        if (StatutoryMappingReportSuccess) {
            setAct(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.level_one_statutories)
            setCountry(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.countries)
            setDomain(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.domains)
            setOrganization(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.industries)
            setStatutorynature(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.statutory_natures)
            setComplianceFrequency(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.compliance_frequency)
            setCriticality(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.criticality)
            // setGeography(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.geographies)
        }
    }, [StatutoryMappingReportSuccess])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (unitdata.country) {
            StatutoryMappingReportSuccess.domains && StatutoryMappingReportSuccess.domains.length > 0 && StatutoryMappingReportSuccess.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(unitdata.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name,
                        'product': domainList.product,
                        'is_cmpf': domainList.is_cmpf,
                        'is_ltgs': domainList.is_ltgs,
                        'is_vndr': domainList.is_vndr,
                        'is_active': domainList.is_active
                    }
                    domainArray.push(tempArray)
                }
            })

            setFilteredFields({
                ...filteredFields,
                filteredDomain: domainArray
            })
        }
    }, [unitdata.country])

    useEffect(() => {
        let industries = _.filter(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.industries, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        let act = _.filter(StatutoryMappingReportSuccess.level_one_statutories, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        setFilteredFields({
            ...filteredFields,
            filteredOrganization: industries,
            filteredAct: act

        })

    }, [unitdata.domain])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (StatutoryMappingReportShow.statutory_mappings) {
            let arrayTemp = [];
            for (let i in StatutoryMappingReportShow.statutory_mappings) {
                arrayTemp.push(StatutoryMappingReportShow.statutory_mappings[i])
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged])
        }
    }, [StatutoryMappingReportShow.statutory_mappings])

    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {
            let uniqueObjArray = [...new Map(updatedArray && updatedArray.length > 0 && updatedArray.map((item) => [item["act_name"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.act_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(updatedArray && updatedArray.length > 0 && updatedArray, { act_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }

            setParentNames([...parentNames, tempArr])
        }
    }, [updatedArray])

    useEffect(() => {
        if (parentNames.length > 0) {
            let temp = []
            for (let i in parentNames) {

                temp = parentNames[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [parentNames])

    useEffect(() => {
        let tempArr = []
        if (updatedArray && updatedArray && updatedArray.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["frequency_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.frequency_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { frequency_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setFilteredFrequency(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = []
        if (updatedArray && updatedArray.length > 0) {
            if (filteredFrequency && filteredFrequency.length > 0) {
                const ab = filteredFrequency && filteredFrequency.length > 0 && filteredFrequency.map(function (user) {
                    if ((arr.indexOf(user.frequency_id) < 0))
                        arr.push({ 'frequency_id': user.frequency_id, 'act_name': user.act_name });
                });
                arr.map((pmenu, index) => {

                    let entityArray = []
                    let tempArray = []
                    var frequency_name = ''
                    let totalList = []
                    if (pmenu) {
                        var indexNo = 1;
                        updatedArray && updatedArray.length > 0 && updatedArray.map((subList, childIndex) => {
                            if (pmenu.frequency_id === subList.frequency_id && pmenu.act_name == subList.act_name) {
                                switch (subList.frequency_id) {
                                    case 1:
                                        frequency_name = 'One Time'
                                        break;
                                    case 2:
                                        frequency_name = 'Periodical'
                                        break;
                                    case 3:
                                        frequency_name = 'Review'
                                        break;
                                    case 4:
                                        frequency_name = 'Flexi Review'
                                        break;
                                    case 5:
                                        frequency_name = 'On Occurence'
                                        break;
                                    default:
                                        frequency_name = ''
                                        break;
                                }
                                entityArray = {
                                    'indexNo': (page - 1) * pageSize + childIndex + 1,
                                    'act_name': subList.act_name,
                                    'approval_status_id': subList.approval_status_id,
                                    'c_task': subList.c_task,
                                    'comp_id': subList.comp_id,
                                    'country_name': subList.country_name,
                                    'criticality_name': subList.criticality_name,
                                    'd_type_id': subList.d_type_id,
                                    'description': subList.description,
                                    'domain_name': subList.domain_name,
                                    'duration': subList.duration,
                                    'frequency_id': subList.frequency_id,
                                    'geo_maps': subList.geo_maps,
                                    'industry_names': subList.industry_names,
                                    'is_active': subList.is_active,
                                    'p_consequences': subList.p_consequences,
                                    'parent_names': subList.parent_names,
                                    'r_every': subList.r_every,
                                    'r_type_id': subList.r_type_id,
                                    's_pro_map': subList.s_pro_map,
                                    'statu_dates': subList.statu_dates,
                                    'statutory_nature_name': subList.statutory_nature_name,
                                    'summary': subList.summary,
                                    'url': subList.url,
                                    'frequency_name': frequency_name
                                }
                                tempArray.push(entityArray)
                            }
                            indexNo++;
                        })
                    }
                    totalList = {
                        index: index,
                        frequency_ids: pmenu.frequency_id,
                        parentData: frequency_name,
                        child: tempArray
                    }
                    countrylisttempdata.push(totalList)
                })
                // setShowstatutory([...countrylisttempdata].sort((a, b) => a.frequency_ids - b.frequency_ids))
                setShowstatutory(countrylisttempdata)
            }

        }
    }, [updatedArray, filteredFrequency])

    useEffect(() => {
        if (pageState == true) {
            StatutoryMappingReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportData",
                        {
                            "c_id": Number(unitdata.country),
                            "d_id": Number(unitdata.domain),
                            "a_i_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "a_s_n_id": Number(unitdata.statutorynature) == 0 ? null : Number(unitdata.statutorynature),
                            "a_g_id": Number(unitdata.geography) == 0 ? null : Number(unitdata.geography),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "frequency_id": Number(unitdata.compliance_frequency) == 0 ? null : Number(unitdata.compliance_frequency),
                            "criticality_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "r_count": Math.max(((current - 1) * pageSize), 0),
                            "csv": false,
                            "page_count": pageSize//Math.max(((current - 1) * pageSize), 0)
                        }
                    ]
                },
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    const onshow = () => {
        setPageSize(25);
        setCurrent(1);
        setPage(1);
        setAddFormSubmit(true)
        setfilteredArrayState([])
        setFilterModalVisible(false);
        let domName = _.find(domain && domain.length > 0 && domain, { domain_id: parseInt(unitdata.domain) })

        setDomainName(domName.domain_name);
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            StatutoryMappingReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportData",
                        {
                            "c_id": Number(unitdata.country),
                            "d_id": Number(unitdata.domain),
                            "a_i_id": Number(unitdata.organization) == 0 ? null : Number(unitdata.organization),
                            "a_s_n_id": Number(unitdata.statutorynature) == 0 ? null : Number(unitdata.statutorynature),
                            "a_g_id": Number(unitdata.geography) == 0 ? null : Number(unitdata.geography),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "frequency_id": Number(unitdata.compliance_frequency) == 0 ? null : Number(unitdata.compliance_frequency),
                            "criticality_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "r_count": 0,
                            "csv": false,                            
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    const handleCancel = () => {
        setApplicationModal(false)
        setGeo([])
        setDataTableProperties({
            ...dataTableProperties,
            currentpage: 1
        })
        // setShowValueModal(false);
    };

    const application = (geo_maps) => {
        setApplicationModal(true)
        setGeo(geo_maps)
    }

    const download = (url) => {
        // window.open(process.env.REACT_APP_API_URL + url)
        window.open(process.env.REACT_APP_API_DOWN_URL + '/' + url)
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    return (
        <div id="page-wrapper" className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span>Statutory Mapping Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <div>
                            <Tooltip title="Advanced Filter"><Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "15px",
                                    display: filterTaskAcc ? "none" : "block",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                        </div>
                        <div>
                            <button onClick={(e) => { fullscreenMode(); }}
                                className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                <Tooltip
                                    placement="left"
                                    style={{ marginRight: "10px", }}
                                    title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-12">
                        <div className="custom-collapse" style={{ padding: "5px" }}>
                            <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} >
                                <Panel header="Statutory Mapping Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:&nbsp;</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            autoFocus={true}
                                                            onChange={(value, data) => {
                                                                if (data) {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        domain: '',
                                                                        domain_name: '',
                                                                        act: '',
                                                                        act_name: '',
                                                                        organization: '',
                                                                        organization_name: ''
                                                                    })
                                                                } else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: '',
                                                                        countryName: '',
                                                                        domain: '',
                                                                        domain_name: '',
                                                                        act: '',
                                                                        act_name: '',
                                                                        organization: '',
                                                                        organization_name: ''
                                                                    })

                                                                }
                                                                let geography = Object.values(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.geographies[value]);
                                                                setGeography(geography)
                                                                let statutory = _.filter(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.statutory_natures, { country_id: Number(value) })
                                                                setStatutorynature(statutory)
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={unitdata.countryName || undefined}
                                                        >
                                                            {Country && Country.length && Country.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.countryName,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Domain:&nbsp;<span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.country == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                domain: value,
                                                                domain_name: data.children,
                                                                act: '',
                                                                act_name: '',
                                                                organization: '',
                                                                organization_name: '',
                                                                statutorynature: '',
                                                                statutorynature_name: '',
                                                                geography: '',
                                                                geography_name: '',
                                                            });
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                domain: '',
                                                                domain_name: '',
                                                                act: '',
                                                                act_name: '',
                                                                organization: '',
                                                                organization_name: '',
                                                                statutorynature: '',
                                                                statutorynature_name: '',
                                                                geography: '',
                                                                geography_name: '',
                                                            });
                                                        }
                                                    }}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%' }}>
                                                    {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Name Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Organization :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.organization_name || undefined}
                                                    showSearch
                                                    // disabled={unitdata.domain == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                organization: value,
                                                                organization_name: data.children,
                                                                statutorynature: '',
                                                                statutorynature_name: '',
                                                                geography: '',
                                                                geography_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                organization: '',
                                                                organization_name: '',
                                                                statutorynature: '',
                                                                statutorynature_name: '',
                                                                geography: '',
                                                                geography_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Organization"
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredOrganization && filteredFields.filteredOrganization.length > 0 && filteredFields.filteredOrganization.map((item, i) => {
                                                        return (
                                                            <Option key={item.industry_id}>
                                                                {item.industry_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4 mt-1'>
                                                <label style={{ "fontWeight": "bold" }}>Statutory Nature :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.statutorynature_name || undefined}
                                                    showSearch
                                                    // disabled={unitdata.domain == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                statutorynature: value,
                                                                statutorynature_name: data.children,
                                                                geography: '',
                                                                geography_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                statutorynature: '',
                                                                statutorynature_name: '',
                                                                geography: '',
                                                                geography_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Statutory Nature"
                                                    style={{ width: '100%' }}
                                                >
                                                    {statutorynature && statutorynature.length > 0 && statutorynature.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_nature_id}>
                                                                {item.statutory_nature_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4 mt-1'>
                                                <label style={{ "fontWeight": "bold" }}>Geography :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.geography_name || undefined}
                                                    disabled={unitdata.domain == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        // let act = _.filter(statutorylist.level_one_statutories, { domain_id: Number(value) })
                                                        // setAct(act)
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                geography: value,
                                                                geography_name: data.children,
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                geography: '',
                                                                geography_name: '',
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Geography"
                                                    style={{ width: '100%' }}
                                                >
                                                    {geography && geography.length > 0 && geography.map((item, i) => {
                                                        return (
                                                            <Option key={item.geography_id}>
                                                                {item.geography_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4 mt-1'>
                                                <label style={{ "fontWeight": "bold" }}>Act :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                act: value,
                                                                act_name: data.children
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                act: '',
                                                                act_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                        return (
                                                            <Option key={item.level_1_statutory_id}>
                                                                {item.level_1_statutory_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            {/* </div> */}
                                            {/* </div> */}
                                            {/* <div className='col-md-12' style={{ marginTop: "10px" }}> */}
                                            {/* <div className='row'> */}
                                            
                                            <div className='col-md-4 mt-1'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Frequency :</label>
                                                <Select
                                                    allowClear={true}
                                                    defaultValue="All"
                                                    value={unitdata.compliance_frequency_name}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                compliance_frequency: value,
                                                                compliance_frequency_name: data.children
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                compliance_frequency: '',
                                                                compliance_frequency_name: ''
                                                            })
                                                        }
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    {compliancefrequency && compliancefrequency && compliancefrequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            {/* </div> */}
                                            {/* </div> */}
                                            {/* <div className='col-md-12' style={{ marginTop: "10px" }}> */}
                                            {/* <div className='row'> */}
                                            
                                            <div className='col-md-4 mt-1'>
                                                <label style={{ "fontWeight": "bold" }}>Criticality :</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.criticality_name}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        if (data) {
                                                            setUnitData({
                                                                ...unitdata,
                                                                criticality: value,
                                                                criticality_name: data.children
                                                            })
                                                        } else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                criticality: '',
                                                                criticality_name: ''
                                                            })
                                                        }
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Option key={0}>All</Option>
                                                    {criticality && criticality.map((item, i) => {
                                                        return (
                                                            <Option key={item.criticality_id}>
                                                                {item.criticality_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "20px", textAlign: 'center', marginLeft: '26%' }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <Button title='Show Records' type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title="Statutory Mapping Report"
                                className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}
                                footer={false}
                                visible={filterModalVisible}
                                onCancel={setcancelFilter}
                            // getContainer={() => document.getElementById("root")}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country:&nbsp;</b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        onChange={(value, data) => {
                                                            if (data) {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: '',
                                                                    organization: '',
                                                                    organization_name: ''
                                                                })
                                                            } else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: '',
                                                                    countryName: '',
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: '',
                                                                    organization: '',
                                                                    organization_name: ''
                                                                })
                                                            }
                                                            let geography = Object.values(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.geographies[value]);
                                                            setGeography(geography)
                                                            let statutory = _.filter(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.statutory_natures, { country_id: Number(value) })
                                                            setStatutorynature(statutory)
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%' }}
                                                        value={unitdata.countryName || undefined}
                                                    >
                                                        {Country && Country.length && Country.map((item, i) => {
                                                            return (
                                                                <Option key={item.country_id}>
                                                                    {item.country_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        unitdata.countryName,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Name Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Domain:&nbsp;<span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                allowClear={true}
                                                value={unitdata.domain_name || undefined}
                                                disabled={unitdata.country == '' ? true : false}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,
                                                            organization: '',
                                                            organization_name: '',
                                                            act: '',
                                                            act_name: '',
                                                            organization: '',
                                                            organization_name: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: '',
                                                            domain_name: '',
                                                            organization: '',
                                                            organization_name: '',
                                                            act: '',
                                                            act_name: '',
                                                            organization: '',
                                                            organization_name: ''
                                                        })
                                                    }
                                                }}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                placeholder="Enter Domain"
                                                style={{ width: '100%' }}
                                            >
                                                {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                    return (
                                                        <Option key={item.domain_id}>
                                                            {item.domain_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                unitdata.domain_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Name Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Organization :</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.organization_name || undefined}
                                                showSearch
                                                // disabled={unitdata.domain == '' ? true : false}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            organization: value,
                                                            organization_name: data.children,
                                                            statutorynature: '',
                                                            statutorynature_name: '',
                                                            geography: '',
                                                            geography_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            organization: '',
                                                            organization_name: '',
                                                            statutorynature: '',
                                                            statutorynature_name: '',
                                                            geography: '',
                                                            geography_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}
                                                placeholder="Enter Organization"
                                                style={{ width: '100%' }}
                                            >
                                                {filteredFields.filteredOrganization && filteredFields.filteredOrganization.length > 0 && filteredFields.filteredOrganization.map((item, i) => {
                                                    return (
                                                        <Option key={item.industry_id}>
                                                            {item.industry_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Statutory Nature :</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.statutorynature_name || undefined}
                                                showSearch
                                                // disabled={unitdata.domain == '' ? true : false}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            statutorynature: value,
                                                            statutorynature_name: data.children,
                                                            geography: '',
                                                            geography_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            statutorynature: '',
                                                            statutorynature_name: '',
                                                            geography: '',
                                                            geography_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}
                                                placeholder="Enter Statutory Nature"
                                                style={{ width: '100%' }}
                                            >
                                                {statutorynature && statutorynature.length > 0 && statutorynature.map((item, i) => {
                                                    return (
                                                        <Option key={item.statutory_nature_id}>
                                                            {item.statutory_nature_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Geography :</label>
                                            <Select
                                                allowClear={true}
                                                value={unitdata.geography_name || undefined}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={(value, data) => {
                                                    // let act = _.filter(statutorylist.level_one_statutories, { domain_id: Number(value) })
                                                    // setAct(act)
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            geography: value,
                                                            geography_name: data.children,
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            geography: '',
                                                            geography_name: '',
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}

                                                disabled={unitdata.domain == '' ? true : false}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                placeholder="Enter Geography"
                                                style={{ width: '100%' }}
                                            >
                                                {geography && geography.length > 0 && geography.map((item, i) => {
                                                    return (
                                                        <Option key={item.geography_id}>
                                                            {item.geography_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Act :</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.act_name || undefined}
                                                disabled={unitdata.domain == '' ? true : false}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })                                                        
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }
                                                }}
                                                placeholder="Enter Act"
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                            >
                                                {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                    return (
                                                        <Option key={item.level_1_statutory_id}>
                                                            {item.level_1_statutory_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Compliance Frequency:</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                defaultValue="All"
                                                value={unitdata.compliance_frequency_name}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_frequency: value,
                                                            compliance_frequency_name: data.children
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_frequency: '',
                                                            compliance_frequency_name: ''
                                                        })
                                                    }
                                                }}
                                                // placeholder="Enter Domain Name"
                                                style={{ width: '100%' }}
                                            >
                                                <Option key="All">All</Option>
                                                {compliancefrequency && compliancefrequency && compliancefrequency.map((item, i) => {
                                                    return (
                                                        <Option key={item.frequency_id}>
                                                            {item.frequency}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        
                                        <div className='col-md-4'>
                                            <label style={{ "fontWeight": "bold" }}>Criticality :</label>
                                            <Select
                                                allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.criticality_name}
                                                defaultValue={"All"}
                                                onChange={(value, data) => {
                                                    if (data) {
                                                        setUnitData({
                                                            ...unitdata,
                                                            criticality: value,
                                                            criticality_name: data.children
                                                        })
                                                    } else {
                                                        setUnitData({
                                                            ...unitdata,
                                                            criticality: '',
                                                            criticality_name: ''
                                                        })
                                                    }
                                                }}
                                                // placeholder="Enter Domain Name"
                                                style={{ width: '100%' }}
                                            >
                                                <Option key={0}>All</Option>
                                                {criticality && criticality.map((item, i) => {
                                                    return (
                                                        <Option key={item.criticality_id}>
                                                            {item.criticality_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "20px", textAlign: 'center', marginLeft: '26%' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Button title='Show Records' type="primary" shape="round" className='addbutton custom-color-modal-button'
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <br />
                            {tableshow == true ? <> <Card className='servcardround mb-2 pt-1'>
                                <div className='row px-2'>
                                    <div className='col-md-4' style={{ wordWrap: 'break-word' }}><b>Country</b> : {unitdata.countryName}</div>
                                    <div className='col-md-4' style={{ wordWrap: 'break-word' }}><b>Domain</b> : {domainName}</div>
                                </div>
                                {filteredArrayState && filteredArrayState.length > 0 ?
                                    filteredArrayState.map((list) => {

                                        return (<div className='mt-3'> <Collapse defaultActiveKey={['1']}>
                                            <Panel header={list[0].act_name} key={1}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table className=''>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th className='ant-table-cell' style={{ width: '7px' }}>#</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Organization</th>
                                                                        <th className='ant-table-cell' style={{ width: '105px' }}>Statutory Nature</th>
                                                                        <th className='ant-table-cell' style={{ width: '140px' }}>Statutory Provision</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Task</th>
                                                                        <th className='ant-table-cell' style={{ width: '90px' }}>Criticality</th>
                                                                        <th className='ant-table-cell' style={{ width: '140px' }}>Compliance Description</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Penal Consequences</th>
                                                                        <th className='ant-table-cell' style={{ width: '145px' }}>Compliance Occurrence</th>
                                                                        <th className='ant-table-cell' style={{ width: '130px' }}>Applicable Location</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <>
                                                                        {showstatutory && showstatutory.length > 0 && showstatutory.map((childList) => {
                                                                            let data = _.find(childList.child, { act_name: list[0].act_name })
                                                                            if (data != undefined) {
                                                                                if (data.frequency_id == childList.frequency_ids) {
                                                                                    return <>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={10} className='ant-table-cell'><b>{childList.parentData}</b></td>
                                                                                        </tr>
                                                                                        {childList.child.map((items) => {
                                                                                            return <>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                    <td className='ant-table-cell'>{items.indexNo}{console.log(items, 'items.indexNo')}</td>
                                                                                                    <td title={items.industry_names} className=''>
                                                                                                        {items.industry_names}</td>
                                                                                                    <td title={items.statutory_nature_name} className=''>{items.statutory_nature_name}</td>
                                                                                                    <td title={items.s_pro_map} className=''>{items.s_pro_map}</td>
                                                                                                    {items.url != null ?
                                                                                                        <td title={items.c_task} className=' '><Link download onClick={(e) => {
                                                                                                            download(items.url)
                                                                                                        }} >{items.c_task}</Link></td>
                                                                                                        : <td title={items.c_task} className=''>{items.c_task}</td>}
                                                                                                    <td title={items.criticality_name} className=''><center>{items.criticality_name}</center></td>
                                                                                                    <td title={items.description} className=''>{items.description}</td>
                                                                                                    <td title={items.p_consequences} className=''>{items.p_consequences}</td>
                                                                                                    <td title={items.summary} className=''>{items.summary}</td>
                                                                                                    <td title={items.description} className=''> <Link onClick={() => application(items.geo_maps)}>Applicable Location</Link></td>
                                                                                                </tr>
                                                                                            </>
                                                                                        })}
                                                                                    </>
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse></div>
                                        )
                                    }) :
                                    <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                        <div className="ant-table-container">
                                            <div className="ant-table-content  userprivclass1"><table className='ant-table-fixed-header'>
                                                <colgroup>
                                                    <col style={{ width: '10px' }}></col>
                                                </colgroup>
                                                <thead className='ant-table-thead antable-head'>
                                                    <tr>
                                                        <th className='ant-table-cell' style={{ width: '7px' }}>#</th>
                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Organization</th>
                                                        <th className='ant-table-cell' style={{ width: '135px' }}>Statutory Nature</th>
                                                        <th className='ant-table-cell' style={{ width: '170px' }}>Statutory Provision</th>
                                                        <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task</th>
                                                        <th className='ant-table-cell' style={{ width: '110px' }}>Criticality</th>
                                                        <th className='ant-table-cell' style={{ width: '150px' }}>Compliance Description</th>
                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Penal Consequences</th>
                                                        <th className='ant-table-cell' style={{ width: '145px' }}>Compliance Occurrence</th>
                                                        <th className='ant-table-cell' style={{ width: '130px' }}>Applicable Location</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='ant-table-tbody'>
                                                    <tr className='text-center'>
                                                        <td colSpan={10}><b>No Records Found</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>}


                                {filteredArrayState && filteredArrayState.length > 0 ?
                                    <div className='mt-4 px-0'>
                                        <span>Showing {(current - 1) * pageSize + 1} to {current * pageSize} of {StatutoryMappingReportShow.total_count} entries</span>
                                        <span><Pagination style={{ "margin-top": "-5px", float: " RIGHT", "padding-bottom": "10px" }}
                                            current={current}
                                            showSizeChanger={true}
                                            showQuickJumper={false}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={false}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                                setPage(page);
                                            }} total={StatutoryMappingReportShow.total_count} /></span>
                                    </div> : ''}
                            </Card></> : false}
                        </div>
                    </div>
                </div>
                <Modal title="Applicable Location" visible={applicationModal} footer={null} onCancel={handleCancel}
                    className={"add-service-prv custom-color-modal " + localStorage.getItem('currentTheme')}>
                    <Table
                        locale={{emptyText: <span style={{color: 'black'}}><b>No Records Found</b></span>}}
                        className='class'
                        columns={column}
                        dataSource={geo}
                        bordered
                        pagination={{
                            onChange: (page) => {
                                setDataTableProperties({
                                    ...dataTableProperties,
                                    currentpage: page
                                })
                            },
                            current: dataTableProperties.currentpage,
                            defaultPageSize: dataTableProperties.pagesize,
                            showSizeChanger: dataTableProperties.sizechanger,
                            pageSizeOptions: dataTableProperties.pageSizeOptions
                        }}
                    />
                </Modal>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    StatutoryMappingReportFilters,
    StatutoryMappingReportData
})(StatutoryMappingReport);