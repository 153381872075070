import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, Col, Collapse, DatePicker, Input, Radio, Row, Select, Space, Switch, Table, Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, DeleteFilled, InfoCircleFilled, PlayCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TabPane from 'antd/lib/tabs/TabPane';
import { Toaster } from '../../Libs/Toaster';
const _ = require("lodash");

// const mapStateToProps = (state) => ({
// })
const Add_EditClientGroup = ({
    props
}) => {
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const { Option } = Select;
    const [tabKey, setTabKey] = useState({
        0: '1'
    })

    const domainResponse = useSelector((state) => state.ClientGroup.clientgroupdomains)
    const industriesResponse = useSelector((state) => state.ClientGroup.clientgroupindustries)
    const countriesResponse = useSelector((state) => state.ClientGroup.clientgroupcountries)
    const editdata = useSelector((state) => state.ClientGroup.editclientgroup)
    console.log(domainResponse, 'domainResponse')
    console.log(industriesResponse, 'industriesResponse')
    console.log(editdata, 'editdata')
    console.log(countriesResponse, 'countriesResponse')
    console.log(tabKey, 'tabKey')

    var [tabValue, setTabValue] = useState([{
        0: {
            id: 0,
            Compfie: false,
            Compfie_vendor: false,
            Compfie_litigation: false
        }
    }]);
    const [titledata, Settitledata] = useState()
    const tabName = {
        1: 'Compfie',
        2: ' Compfie Litigation',
        3: 'Compfie Vendor'
    }
    const paramid = location.pathname;
    const [delIndex, setIndex] = useState()
    const [addDetails, setAddDetails] = useState({
        group: '',
        short_name: '',
        view_only_licence: '',
        email_id: '',
        industry_type: '',
        ip_restriction: 0,
        ip_settings_status: 'Disable',
        add_master_ltgs: true,
        sso: 0,
        custom_report: 0,
        otp_req: 1,
        otp_remarks: null,
        industry_type: '',
        no_of_licence: ''

    })
    console.log(addDetails, 'addDetails')
    const [legalEntityDetails, setLegalEntityDetails] = useState([
        {
            'uniq_id': 0,
            "country_id": '',
            "business_group": {
                "business_group_id": '',
                "business_group_name": null,
                'isInput': false
            },
            "legal_entity_name": '',
            "product": "",
            "logo": null,
            "no_of_licence": 0,
            "file_space": 0,
            "contract_from": null,
            "contract_to": null,
            'select_domain': [],
            'select_domain_ltgs': [],
            'select_domain_vendor': [],
            "domain_details": [
                {
                    "unique_id": 0,
                    "group_id": null,
                    "legal_entity_id": null,
                    "d_id": '',
                    "d_name": '',
                    "activation_date": "10-May-2023",
                    "org": {},

                    "is_delete": 0
                }
            ]
        }
    ],)

    const [ltgsDetails, setLtgsDetails] = useState([{
        unique_id: 0,
        d_id: '',
        group_id: '',
        is_delete: 0,
        le_id: '',
        legal_entity_id: '',
        legal_entity_name: '',
        unit_cnt: '',
    }])

    const [vendorDetails, setVendorDetails] = useState([{
        unique_id: 0,
        activation_date:"",
        d_id :'',
        group_id: '',
        is_delete: 0,
        legal_entity_id : '',
        org : { }
    }])



    console.log(vendorDetails, 'vendorDetails')

    useEffect(() => {
        console.log('ulla vanten')
        legalEntityDetails[0].select_domain = domainResponse
        legalEntityDetails[0].select_domain_ltgs = domainResponse
        legalEntityDetails[0].select_domain_vendor = domainResponse
        setLegalEntityDetails([...legalEntityDetails])

    }, [domainResponse])

    useEffect(() => {
        if (props.name == "Edit" && Object.keys(editdata).length > 0) {
            let tempLEdata = legalEntityDetails
            setAddDetails({
                email_id: editdata.groups[0].email_id,
                group: editdata.groups[0].group_name,
                short_name: editdata.groups[0].short_name,
                view_only_licence: editdata.groups[0].no_of_view_licence,
                sso: editdata.groups[0].is_sso_enabled,
                otp_req: editdata.groups[0].otp_required,
                otp_remarks: editdata.groups[0].otp_remarks,
                // no_of_licence: editdata.groups[0]. ,
                ip_settings_status: editdata.groups[0].is_apply_ip_settings == 0 ? 'Disable' : 'Enable',
                add_master_ltgs: editdata.groups[0].is_approved,
                custom_report: editdata.groups[0].custom_report_status,
                // industry_type: editdata.groups[0]. ,
                ip_restriction: editdata.groups[0].is_apply_ip_settings
            })

            editdata.legal_entities_list.map((childData, index) => {
                console.log(childData, 'editIndex')
                if (index === 0) {
                    setLegalEntityDetails([{
                        ...legalEntityDetails[0],
                        country_id: childData.country_id,
                        legal_entity_name: childData.legal_entity_name,
                        file_space: childData.file_space
                    }])
                } else {
                    setLegalEntityDetails(prev => [...prev, {
                        'uniq_id': index,
                        "country_id": childData?.country_id,
                        "business_group": {
                            "business_group_id": '',
                            "business_group_name": null,
                            'isInput': false
                        },
                        "legal_entity_name": childData?.legal_entity_name,
                        "product": "",
                        "logo": null,
                        "no_of_licence": 0,
                        "file_space": childData?.file_space,
                        "contract_from": null,
                        "contract_to": null,
                        'select_domain': [],
                        'select_domain_ltgs': [],
                        'select_domain_vendor': [],
                        "domain_details": [
                            {
                                "unique_id": 0,
                                "group_id": null,
                                "legal_entity_id": null,
                                "d_id": '',
                                "d_name": '',
                                "activation_date": "10-May-2023",
                                "org": {},

                                "is_delete": 0
                            }
                        ]
                    }])
                }



            })
        }
    }, [editdata])



    const columns = [
        {
            title: "Domain",
            dataIndex: "",
            key: "",
            width: "30%",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        <Input />
                    </>
                )
            }

        }, {
            title: "Activation Date",
            dataIndex: "",
            key: "",
            align: "center",
            width: "10%",
            render: (text, record) => {
                return (
                    <>
                        <Input />
                    </>
                )
            }
        },
        {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            width: "30%",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        <p><InfoCircleFilled /> Add Organization</p>
                    </>
                )
            }
        },

        {
            title: "Remove",
            dataIndex: "",
            key: "",
            align: "center",
            width: "10%",
            render: (text, record) => {
                return (
                    <>
                        <DeleteFilled />
                    </>
                )
            }
        }
    ]
    console.log(legalEntityDetails, 'legalEntityDetails')

    const addLegalEntity = () => {
        let temp = {
            'uniq_id': legalEntityDetails[legalEntityDetails.length - 1].uniq_id + 1,
            "country_id": '',
            "business_group": {
                "business_group_id": '',
                "business_group_name": null
            },
            "legal_entity_name": '',
            "product": "",
            "logo": null,
            "no_of_licence": 0,
            "file_space": 0,
            "contract_from": null,
            "contract_to": null,
            'select_domain': domainResponse,
            'select_domain_ltgs': domainResponse,
            'select_domain_vendor': domainResponse,
            "domain_details": [
                {
                    "unique_id": 0,
                    "group_id": null,
                    "legal_entity_id": null,
                    "d_id": '',
                    "d_name": '',
                    "activation_date": "10-May-2023",
                    "org": {},

                    "is_delete": 0
                }
            ]
        }
        setTabKey({
            ...tabKey,
            [Object.keys(tabKey).length]: '1'
        })

        //  To Add Tab Value using id (key)
        let tempTabVal = {

        }

        setTabValue(prev => [...prev,
        {
            [tabValue[0].length]: {
                Compfie: false,
                Compfie_vendor: false,
                Compfie_litigation: false
            }
        }])
        setLegalEntityDetails(prev => [...prev, temp])


    }
    console.log(props, 'props')

    const { Panel } = Collapse;
    return (
        <div className='card-body pt-0 custom-collapse'>
            <Collapse defaultActiveKey={["2"]}>
                <Panel header={`${props.name} Client Group`} key={2}>
                    <div>
                        <div>
                            <Row>
                                <Col span={8}>
                                    <label>Group: </label>
                                    <Input value={addDetails.group} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            group: e.target.value
                                        })
                                    }} className='w-75 rounded-3 d-block' />
                                </Col>
                                <Col span={8}>
                                    <label>Short Name: </label>
                                    <Input value={addDetails.short_name} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            short_name: e.target.value
                                        })
                                    }} className='w-75 rounded-3 d-block' />
                                </Col>
                                <Col span={8}>
                                    <label>View Only Licence(s): </label>
                                    <Input value={addDetails.view_only_licence} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            view_only_licence: e.target.value
                                        })
                                    }} className='w-75 rounded-3 d-block' />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <label>Email ID:  </label>
                                    <Input value={addDetails.email_id} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            email_id: e.target.value
                                        })
                                    }} className='w-75 rounded-3 d-block' />
                                </Col>
                                <Col span={8}>
                                    <label>Industry Type:  </label>
                                    <Input value={addDetails.industry_type} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            industry_type: e.target.value
                                        })
                                    }} className='w-75 rounded-3 d-block' />
                                </Col>
                                <Col span={8}>
                                    <label>IP Restriction:  </label>
                                    <Select value={addDetails.ip_settings_status} onChange={(ip, keys) => {
                                        console.log(keys, 'ipres')
                                        setAddDetails({
                                            ...addDetails,
                                            ip_restriction: keys.key,
                                            ip_settings_status: ip
                                        })
                                    }} className='w-75 d-block'>
                                        <Option key={1} value={'Enable'}></Option>
                                        <Option key={0} value={'Disable'}></Option>
                                    </Select>
                                </Col>

                            </Row>
                            <Row>

                                <Col span={8}>
                                    <label>No of Licence:</label>
                                    <Input className='w-75 rounded-3 d-block' value={addDetails.no_of_licence} onChange={(e) => {
                                        setAddDetails({
                                            ...addDetails,
                                            no_of_licence: e.target.value
                                        })
                                    }} />
                                </Col>

                                <Col span={8}>
                                    <label>Add Master for Litigation: </label>
                                    <Space direction="vertical" className='w-75 d-block'>
                                        <Switch onChange={(add) => {
                                            console.log(add, 'addmstrltgs')
                                            setAddDetails({
                                                ...addDetails,
                                                add_master_ltgs: add
                                            })
                                        }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked={addDetails.add_master_ltgs} />
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <label>SSO: </label>
                                    <Radio.Group name="radiogroup" className='w-75 d-block' value={addDetails.sso}>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            sso: e.target.value
                                        })} value={1}>Enable</Radio>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            sso: e.target.value
                                        })} value={0}>Disable</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={8}>
                                    <label>Custom Report: </label>
                                    <Radio.Group name="radiogroup" className='w-75 d-block' value={addDetails.custom_report}>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            custom_report: e.target.value
                                        })} value={1}>Enable</Radio>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            custom_report: e.target.value
                                        })} value={0}>Disable</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={8}>
                                    <label>OTP Required: </label>
                                    <Radio.Group name="radiogroup" className='w-75 d-block' value={addDetails.otp_req}>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            otp_req: e.target.value,
                                            otp_remarks: null
                                        })} value={1}>On</Radio>
                                        <Radio onChange={(e) => setAddDetails({
                                            ...addDetails,
                                            otp_req: e.target.value
                                        })} value={0}>Off</Radio>
                                    </Radio.Group>
                                </Col>
                                {
                                    addDetails.otp_req === 0 ?
                                        <>
                                            <Col span={8}>
                                                <label>OTP Remarks: </label>
                                                <Input className='w-75 d-block' value={addDetails.otp_remarks ? addDetails.otp_remarks : ''} onChange={(e) => {
                                                    setAddDetails({
                                                        ...addDetails,
                                                        otp_remarks: e.target.value
                                                    })
                                                }} />
                                            </Col>
                                        </>
                                        :
                                        <></>
                                }

                            </Row>
                        </div>
                        <div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <h3>Legal Entity Configuration</h3>
                                <Button
                                    type="primary"
                                    shape="round"
                                    className='addbutton'
                                    icon={<PlusCircleOutlined />}
                                    size='default'
                                    onClick={addLegalEntity}
                                >
                                    Add Leagl Entity
                                </Button>
                            </div>
                            {
                                legalEntityDetails && legalEntityDetails.map((item) => {
                                    return (
                                        <>
                                            <div style={{ border: '1px solid grey', padding: '6px', margin: '4px' }}>
                                                <Row>
                                                    <Col span={8}>
                                                        <label>
                                                            Country*
                                                        </label>
                                                        <Select value={legalEntityDetails[item.uniq_id]?.country_id} onChange={(datas, value) => {
                                                            console.log(datas, value, 'datavaluecountry')
                                                            legalEntityDetails[item.uniq_id].country_id = datas
                                                            legalEntityDetails[item.uniq_id].select_domain = domainResponse.filter((select_domain_list) => {
                                                                return select_domain_list.country_ids.includes(Number(datas))

                                                            })
                                                            setLegalEntityDetails([...legalEntityDetails])
                                                        }} className='w-75 d-block'>
                                                            {countriesResponse.map((country) => {
                                                                console.log(country, 'countretysy')
                                                                if (country.is_active) {

                                                                    return (
                                                                        <Option key={country.country_id}>
                                                                            {country.country_name}
                                                                        </Option>
                                                                    )
                                                                }


                                                            })
                                                            }

                                                        </Select>
                                                    </Col>
                                                    <Col span={8}>
                                                        <label>
                                                            Business Group
                                                        </label>
                                                        {
                                                            item.business_group.isInput ?
                                                                <div>
                                                                    <Input className='w-75 ' />
                                                                    <CloseOutlined className='mx-1' onClick={() => {
                                                                        legalEntityDetails[item.uniq_id].business_group.isInput = false
                                                                        setLegalEntityDetails([...legalEntityDetails])
                                                                    }} />
                                                                </div> :
                                                                <div> <Select className='w-75 '>
                                                                    <Option></Option>
                                                                </Select>
                                                                    <PlusOutlined className='mx-1' onClick={() => {
                                                                        legalEntityDetails[item.uniq_id].business_group.isInput = true
                                                                        setLegalEntityDetails([...legalEntityDetails])
                                                                    }} />
                                                                </div>
                                                        }
                                                    </Col>
                                                    <Col span={8}>
                                                        <label>
                                                            Legal Entity*
                                                        </label>
                                                        <Input value={legalEntityDetails[item.uniq_id]?.legal_entity_name} className='w-75 d-block' />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8}>
                                                        <label>
                                                            Upload Logo
                                                        </label>
                                                        <Input className='w-75 d-block' />
                                                    </Col>
                                                    <Col span={8}>
                                                        <label>
                                                            File Space*
                                                        </label>
                                                        <div className="d-block"><Input value={legalEntityDetails[item.uniq_id]?.file_space} className='w-25' /> GB</div>
                                                    </Col>
                                                </Row>
                                                <Tabs activeKey={tabKey[item.uniq_id]} onChange={(key) => {
                                                    setTabKey({
                                                        ...tabKey,
                                                        [item.uniq_id]: key
                                                    })

                                                    console.log(item.uniq_id, 'tabKey[item.uniq_id]')

                                                }} >
                                                    <TabPane id={item.uniq_id}
                                                        tab={
                                                            <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                {
                                                                    tabValue[item.uniq_id]?.Compfie ?
                                                                        <CheckCircleOutlined className={'pointer'} size='19' style={{ color: 'green', marginBottom: '-1%' }}
                                                                            onClick={() => {
                                                                                tabValue[item.uniq_id].Compfie = false
                                                                                setTabValue([...tabValue])
                                                                                legalEntityDetails[item.uniq_id].domain_details = [
                                                                                    {
                                                                                        "unique_id": 0,
                                                                                        "group_id": null,
                                                                                        "legal_entity_id": null,
                                                                                        "d_id": '',
                                                                                        "d_name": '',
                                                                                        "activation_date": "10-May-2023",
                                                                                        "org": {},

                                                                                        "is_delete": 0
                                                                                    }
                                                                                ]
                                                                                setLegalEntityDetails([...legalEntityDetails])
                                                                            }}
                                                                        />

                                                                        :

                                                                        <CloseCircleOutlined className={'pointer'} size='19' style={{ color: 'red', marginBottom: '-1%' }}
                                                                            onClick={() => {
                                                                                tabValue[item.uniq_id].Compfie = true
                                                                                setTabValue([...tabValue])

                                                                            }}
                                                                        />

                                                                }
                                                                Compfie
                                                            </span>
                                                        }
                                                        key='1'>
                                                        {tabValue[item.uniq_id]?.Compfie ?
                                                            <>
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <label>
                                                                            Contract From*
                                                                        </label>
                                                                        <Col span={18}>
                                                                            <DatePicker className='d-block w-75' />
                                                                        </Col>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <label>
                                                                            Contract To*
                                                                        </label>
                                                                        <Col span={18}>
                                                                            <DatePicker className='d-block w-75' />
                                                                        </Col>
                                                                    </Col>
                                                                    {/* <Col span={8}>
                                                                        <label>
                                                                            Legal Entity*
                                                                        </label>
                                                                        <Input className='w-75 d-block' />
                                                                    </Col> */}
                                                                </Row>
                                                                <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper   "} >
                                                                    <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                                                        <div className="ant-table-container">
                                                                            <div className="ant-table-content taskrecalltable  ">
                                                                                <table>
                                                                                    <thead className="ant-table-thead">
                                                                                        <tr>
                                                                                            <th className="ant-table-cell">Domain</th>
                                                                                            <th className="ant-table-cell">Activation Date</th>
                                                                                            <th className="ant-table-cell">Organization</th>
                                                                                            <th className="ant-table-cell">Remove</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='ant-table-tbody'>
                                                                                        {
                                                                                            item.domain_details?.map((data, indexId) => {
                                                                                                console.log(data, 'data')
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr className="ant-table-row ant-table-row-level-0 ">
                                                                                                            <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                <Select disabled={legalEntityDetails[item.uniq_id]?.country_id === ''}
                                                                                                                    value={legalEntityDetails[item.uniq_id].domain_details[legalEntityDetails[item.uniq_id]?.domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id)]?.d_name}
                                                                                                                    onChange={(value, datas) => {
                                                                                                                        console.log(legalEntityDetails[item.uniq_id].domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id), 'legalEntityDetails[item.uniq_id].domain_details.findIndex((u_id)=> u_id.unique_id == data.unique_id)')
                                                                                                                        if (legalEntityDetails[item.uniq_id].domain_details[legalEntityDetails[item.uniq_id]?.domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id)]) {
                                                                                                                            legalEntityDetails[item.uniq_id].domain_details[legalEntityDetails[item.uniq_id]?.domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_id = value
                                                                                                                            legalEntityDetails[item.uniq_id].domain_details[legalEntityDetails[item.uniq_id]?.domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_name = datas.children
                                                                                                                            legalEntityDetails[item.uniq_id].select_domain = legalEntityDetails[item.uniq_id]?.select_domain.filter((select_domain_list) => {
                                                                                                                                return select_domain_list?.domain_id !== (Number(value))

                                                                                                                            })
                                                                                                                            setLegalEntityDetails([...legalEntityDetails])
                                                                                                                        }
                                                                                                                    }} className='w-75'>
                                                                                                                    {legalEntityDetails[item.uniq_id].select_domain && legalEntityDetails[item.uniq_id].select_domain.map((domain) => {
                                                                                                                        console.log(domain, legalEntityDetails[item.uniq_id]?.country_id, 'domainfilter')
                                                                                                                        console.log(tabName[Number(tabKey[item.uniq_id])], 'domaintabNamefilter')
                                                                                                                        console.log(domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])]), 'dddomainfilter')
                                                                                                                        if (domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])])) {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <Option key={domain.domain_id}>
                                                                                                                                        {domain.domain_name}
                                                                                                                                    </Option>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                    }
                                                                                                                </Select>
                                                                                                            </span></td>
                                                                                                            <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                <Input className='w-75' />
                                                                                                            </span></td>
                                                                                                            <td className="ant-table-cell"><span className='text-start px-4'><InfoCircleFilled /> Add Organisation</span></td>
                                                                                                            <td className="ant-table-cell"><span className='text-start px-4'>
                                                                                                                {legalEntityDetails[item.uniq_id].domain_details[indexId]?.d_id !== '' ?
                                                                                                                    <DeleteFilled onClick={() => {
                                                                                                                        const index = legalEntityDetails[item.uniq_id].domain_details.findIndex(ele => ele.unique_id === data.unique_id);
                                                                                                                        setIndex(index)
                                                                                                                        console.log(item, data, index, 'itemvalue')
                                                                                                                        let isLegalDomainValue = []
                                                                                                                        // legalEntityDetails[item.uniq_id].domain_details.map((domains) => {
                                                                                                                        //     if (domains.d_id !== '') {
                                                                                                                        //         isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                        //     }
                                                                                                                        // })

                                                                                                                        // isLegalDomainValue = []

                                                                                                                        if (legalEntityDetails[item.uniq_id].domain_details.length > 1) {
                                                                                                                            if (index !== -1) {
                                                                                                                                legalEntityDetails[item.uniq_id].domain_details.splice(index, 1);
                                                                                                                            }
                                                                                                                        }
                                                                                                                        console.log(isLegalDomainValue, legalEntityDetails, 'isLegalDomainValue')

                                                                                                                        legalEntityDetails[item.uniq_id].domain_details.some((domains) => {
                                                                                                                            console.log(domains, 'domainsul')
                                                                                                                            console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                            isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                            console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                        })

                                                                                                                        let finalD_id = domainResponse.filter((select_domain_list) => {
                                                                                                                            return select_domain_list.country_ids.includes(Number(legalEntityDetails[item.uniq_id]?.country_id))

                                                                                                                        })
                                                                                                                        finalD_id = finalD_id.filter((rejDomains) => {
                                                                                                                            console.log(rejDomains.domain_id, 'letscheck')
                                                                                                                            if (isLegalDomainValue.includes(Number(rejDomains.domain_id))) {

                                                                                                                            } else {
                                                                                                                                return rejDomains
                                                                                                                            }
                                                                                                                        })

                                                                                                                        legalEntityDetails[item.uniq_id].select_domain = finalD_id
                                                                                                                        console.log(finalD_id, 'finalD_id')

                                                                                                                        setLegalEntityDetails([...legalEntityDetails])
                                                                                                                    }} /> : <></>}
                                                                                                            </span></td>
                                                                                                        </tr>
                                                                                                    </>)
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {(legalEntityDetails[item.uniq_id].select_domain?.length > 0) ?
                                                                    <Button
                                                                        type="primary"
                                                                        shape="round"
                                                                        className='addbutton'
                                                                        icon={<PlusCircleOutlined />}
                                                                        size='default'
                                                                        onClick={() => {
                                                                            if (legalEntityDetails[item.uniq_id].domain_details[legalEntityDetails[item.uniq_id].domain_details.length - 1].d_id != '') {
                                                                                legalEntityDetails[item.uniq_id].domain_details.push(
                                                                                    {
                                                                                        'unique_id': legalEntityDetails[legalEntityDetails.length - 1].domain_details[legalEntityDetails[legalEntityDetails.length - 1].domain_details.length - 1].unique_id + 1,
                                                                                        "group_id": null,
                                                                                        "legal_entity_id": null,
                                                                                        "d_id": '',
                                                                                        "d_name": '',
                                                                                        "activation_date": "10-May-2023",
                                                                                        "org": {},

                                                                                        "is_delete": 0
                                                                                    }
                                                                                )

                                                                                setLegalEntityDetails([...legalEntityDetails])
                                                                            } else {
                                                                                Toaster.error('Select The Domain')
                                                                            }
                                                                        }}
                                                                    >
                                                                        Add Domain
                                                                    </Button> : <></>}
                                                            </> : <h4>Compfie Contract Details</h4>}
                                                    </TabPane>
                                                    <TabPane id={item.uniq_id} tab={
                                                        <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                            {
                                                                tabValue[item.uniq_id]?.Compfie_litigation ?
                                                                    <CheckCircleOutlined className={'pointer'} size='19' style={{ color: 'green', marginBottom: '-1%' }}
                                                                        onClick={() => {
                                                                            tabValue[item.uniq_id].Compfie_litigation = false
                                                                            setTabValue([...tabValue])

                                                                        }}
                                                                    />

                                                                    :

                                                                    <CloseCircleOutlined className={'pointer'} size='19' style={{ color: 'red', marginBottom: '-1%' }}
                                                                        onClick={() => {
                                                                            tabValue[item.uniq_id].Compfie_litigation = true
                                                                            setTabValue([...tabValue])

                                                                        }}
                                                                    />

                                                            }
                                                            Compfie Litigation
                                                        </span>
                                                    } key='2'>

                                                        {
                                                            tabValue[item.uniq_id]?.Compfie_litigation ?
                                                                <>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <label>
                                                                                Contract From*
                                                                            </label>
                                                                            <Col span={18}>
                                                                                <DatePicker className='d-block w-75' />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <label>
                                                                                Contract To*
                                                                            </label>
                                                                            <Col span={18}>
                                                                                <DatePicker className='d-block w-75' />
                                                                            </Col>
                                                                        </Col>
                                                                        {/* <Col span={8}>
                                                                    <label>
                                                                        Legal Entity*
                                                                    </label>
                                                                    <Input className='w-75 d-block' />
                                                                </Col> */}
                                                                    </Row>
                                                                    <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper   "} >
                                                                        <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                                                            <div className="ant-table-container">
                                                                                <div className="ant-table-content taskrecalltable  ">
                                                                                    <table>
                                                                                        <thead className="ant-table-thead">
                                                                                            <tr>
                                                                                                <th className="ant-table-cell">Domain*</th>
                                                                                                <th className="ant-table-cell">No of Units*</th>
                                                                                                <th className="ant-table-cell">Remove</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className='ant-table-tbody'>
                                                                                            {
                                                                                                ltgsDetails?.map((data, indexId) => {
                                                                                                    console.log(data, 'data')
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr className="ant-table-row ant-table-row-level-0 ">
                                                                                                                <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                    <Select disabled={legalEntityDetails[item.uniq_id]?.country_id === ''}
                                                                                                                        value={ltgsDetails[ltgsDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)]?.d_name}
                                                                                                                        onChange={(value, datas) => {
                                                                                                                            console.log(legalEntityDetails[item.uniq_id].domain_details.findIndex((u_id) => u_id.unique_id == data.unique_id), 'legalEntityDetails[item.uniq_id].domain_details.findIndex((u_id)=> u_id.unique_id == data.unique_id)')
                                                                                                                            if (ltgsDetails[ltgsDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)]) {
                                                                                                                                ltgsDetails[ltgsDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_id = value
                                                                                                                                ltgsDetails[ltgsDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_name = datas.children
                                                                                                                                legalEntityDetails[item.uniq_id].select_domain_ltgs = legalEntityDetails[item.uniq_id]?.select_domain_ltgs.filter((select_domain_list) => {
                                                                                                                                    return select_domain_list?.domain_id !== (Number(value))

                                                                                                                                })
                                                                                                                                setLegalEntityDetails([...legalEntityDetails])
                                                                                                                                setLtgsDetails([...ltgsDetails])
                                                                                                                            }
                                                                                                                        }} className='w-75'>
                                                                                                                        {legalEntityDetails[item.uniq_id].select_domain_ltgs && legalEntityDetails[item.uniq_id].select_domain_ltgs.map((domain) => {
                                                                                                                            console.log(domain.product, domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])]), 'ddddomainfilter')
                                                                                                                            console.log(domain.product.split(','), 'domaintabNamefilter')
                                                                                                                            console.log(domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])]), 'dddomainfilter')
                                                                                                                            if (domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])])) {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <Option key={domain.domain_id}>
                                                                                                                                            {domain.domain_name}
                                                                                                                                        </Option>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                        }
                                                                                                                    </Select>
                                                                                                                </span></td>
                                                                                                                <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                    <Input value={ltgsDetails[item.uniq_id]?.unit_cnt} onChange={(e) => {
                                                                                                                        ltgsDetails[item.uniq_id].unit_cnt = e.target.value
                                                                                                                        setLtgsDetails([...ltgsDetails])
                                                                                                                    }} className='w-75' />
                                                                                                                </span></td>
                                                                                                                <td className="ant-table-cell"><span className='text-start px-4'>
                                                                                                                    {ltgsDetails[indexId]?.d_id !== '' ?
                                                                                                                        <DeleteFilled onClick={() => {
                                                                                                                            const index = ltgsDetails.findIndex(ele => ele.unique_id === data.unique_id);
                                                                                                                            setIndex(index)
                                                                                                                            console.log(item, data, index, 'itemvalue')
                                                                                                                            let isLegalDomainValue = []
                                                                                                                            // legalEntityDetails[item.uniq_id].domain_details.map((domains) => {
                                                                                                                            //     if (domains.d_id !== '') {
                                                                                                                            //         isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                            //     }
                                                                                                                            // })

                                                                                                                            // isLegalDomainValue = []

                                                                                                                            if (ltgsDetails.length > 1) {
                                                                                                                                if (index !== -1) {
                                                                                                                                    ltgsDetails.splice(index, 1);
                                                                                                                                }
                                                                                                                            }
                                                                                                                            console.log(isLegalDomainValue, legalEntityDetails, 'isLegalDomainValue')

                                                                                                                            ltgsDetails.some((domains) => {
                                                                                                                                console.log(domains, 'domainsul')
                                                                                                                                console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                                isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                                console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                            })

                                                                                                                            let finalD_id = domainResponse.filter((select_domain_list) => {
                                                                                                                                return select_domain_list.country_ids.includes(Number(legalEntityDetails[item.uniq_id]?.country_id))

                                                                                                                            })
                                                                                                                            finalD_id = finalD_id.filter((rejDomains) => {
                                                                                                                                console.log(rejDomains.domain_id, 'letscheck')
                                                                                                                                if (isLegalDomainValue.includes(Number(rejDomains.domain_id))) {

                                                                                                                                } else {
                                                                                                                                    return rejDomains
                                                                                                                                }
                                                                                                                            })

                                                                                                                            legalEntityDetails[item.uniq_id].select_domain_ltgs = finalD_id
                                                                                                                            console.log(finalD_id, 'finalD_id')

                                                                                                                            setLegalEntityDetails([...legalEntityDetails])
                                                                                                                        }} /> : <></>}
                                                                                                                </span></td>
                                                                                                            </tr>
                                                                                                        </>)
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {(legalEntityDetails[item.uniq_id].select_domain_ltgs?.length > 0) ?
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            className='addbutton'
                                                                            icon={<PlusCircleOutlined />}
                                                                            size='default'
                                                                            onClick={() => {
                                                                                if (ltgsDetails[ltgsDetails.length - 1].d_id !== '') {
                                                                                    ltgsDetails.push(
                                                                                        {
                                                                                            'unique_id': ltgsDetails[ltgsDetails.length - 1].unique_id + 1,
                                                                                            d_id: '',
                                                                                            group_id: '',
                                                                                            is_delete: 0,
                                                                                            le_id: '',
                                                                                            legal_entity_id: '',
                                                                                            legal_entity_name: '',
                                                                                            unit_cnt: '',
                                                                                        }
                                                                                    )

                                                                                    setLtgsDetails([...ltgsDetails])
                                                                                } else {
                                                                                    Toaster.error('Select The Domain')
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add Domain
                                                                        </Button> : <></>}
                                                                </> :
                                                                <h4>Compfie Litigation Contract Details</h4>
                                                        }


                                                    </TabPane>
                                                    <TabPane id={item.uniq_id} tab={
                                                        <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                            {
                                                                tabValue[item.uniq_id]?.Compfie_vendor ?
                                                                    <CheckCircleOutlined className={'pointer'} size='19' style={{ color: 'green', marginBottom: '-1%' }}
                                                                        onClick={() => {
                                                                            tabValue[item.uniq_id].Compfie_vendor = false
                                                                            setTabValue([...tabValue])

                                                                        }}
                                                                    />

                                                                    :

                                                                    <CloseCircleOutlined className={'pointer'} size='19' style={{ color: 'red', marginBottom: '-1%' }}
                                                                        onClick={() => {
                                                                            tabValue[item.uniq_id].Compfie_vendor = true
                                                                            setTabValue([...tabValue])

                                                                        }}
                                                                    />

                                                            }
                                                            Compfie Vendor
                                                        </span>
                                                    } key='3'>
                                                        {
                                                            tabValue[item.uniq_id]?.Compfie_vendor ?
                                                                <>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <label>
                                                                                Contract From*
                                                                            </label>
                                                                            <Col span={18}>
                                                                                <DatePicker className='d-block w-75' />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <label>
                                                                                Contract To*
                                                                            </label>
                                                                            <Col span={18}>
                                                                                <DatePicker className='d-block w-75' />
                                                                            </Col>
                                                                        </Col>
                                                                        {/* <Col span={8}>
                                                                    <label>
                                                                        Legal Entity*
                                                                    </label>
                                                                    <Input className='w-75 d-block' />
                                                                </Col> */}
                                                                    </Row>
                                                                    <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper   "} >
                                                                        <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                                                            <div className="ant-table-container">
                                                                                <div className="ant-table-content taskrecalltable  ">
                                                                                    <table>
                                                                                        <thead className="ant-table-thead">
                                                                                            <tr>
                                                                                                <th className="ant-table-cell">Domain</th>
                                                                                                <th className="ant-table-cell">Activation Date</th>
                                                                                                <th className="ant-table-cell">Organization</th>
                                                                                                <th className="ant-table-cell">Remove</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className='ant-table-tbody'>
                                                                                            {
                                                                                                vendorDetails?.map((data, indexId) => {
                                                                                                    console.log(data, 'data')
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr className="ant-table-row ant-table-row-level-0 ">
                                                                                                                <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                    <Select disabled={legalEntityDetails[item.uniq_id]?.country_id === ''}
                                                                                                                        value={vendorDetails[vendorDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)]?.d_name}
                                                                                                                        onChange={(value, datas) => {
                                                                                                                            console.log(vendorDetails.findIndex((u_id) => u_id.unique_id == data.unique_id), 'vendorDetails[item.uniq_id].domain_details.findIndex((u_id)=> u_id.unique_id == data.unique_id)')
                                                                                                                            if (vendorDetails[vendorDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)]) {
                                                                                                                                vendorDetails[vendorDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_id = value
                                                                                                                                vendorDetails[vendorDetails.findIndex((u_id) => u_id.unique_id == data.unique_id)].d_name = datas.children
                                                                                                                                legalEntityDetails[item.uniq_id].select_domain_vendor = legalEntityDetails[item.uniq_id]?.select_domain_vendor.filter((select_domain_list) => {
                                                                                                                                    return select_domain_list?.domain_id !== (Number(value))

                                                                                                                                })
                                                                                                                                setLegalEntityDetails([...legalEntityDetails])
                                                                                                                                setVendorDetails([...vendorDetails])
                                                                                                                            }
                                                                                                                        }} className='w-75'>
                                                                                                                        {legalEntityDetails[item.uniq_id].select_domain_vendor && legalEntityDetails[item.uniq_id].select_domain_vendor.map((domain) => {
                                                                                                                            console.log(domain, legalEntityDetails[item.uniq_id]?.country_id, 'domainfilter')
                                                                                                                            console.log(tabName[Number(tabKey[item.uniq_id])], 'domaintabNamefilter')
                                                                                                                            console.log(domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])]), 'dddomainfilter')
                                                                                                                            if (domain.product.split(',').includes(tabName[Number(tabKey[item.uniq_id])])) {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <Option key={domain.domain_id}>
                                                                                                                                            {domain.domain_name}
                                                                                                                                        </Option>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                        }
                                                                                                                    </Select>
                                                                                                                </span></td>
                                                                                                                <td className="ant-table-cell text-center"><span className=' px-4'>
                                                                                                                    <Input className='w-75' />
                                                                                                                </span></td>
                                                                                                                <td className="ant-table-cell"><span className='text-start px-4'><InfoCircleFilled /> Add Organisation</span></td>
                                                                                                                <td className="ant-table-cell"><span className='text-start px-4'>
                                                                                                                    {vendorDetails[indexId]?.d_id !== '' ?
                                                                                                                        <DeleteFilled onClick={() => {
                                                                                                                            const index = vendorDetails.findIndex(ele => ele.unique_id === data.unique_id);
                                                                                                                            setIndex(index)
                                                                                                                            console.log(item, data, index, 'itemvalue')
                                                                                                                            let isLegalDomainValue = []
                                                                                                                            // legalEntityDetails[item.uniq_id].domain_details.map((domains) => {
                                                                                                                            //     if (domains.d_id !== '') {
                                                                                                                            //         isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                            //     }
                                                                                                                            // })

                                                                                                                            // isLegalDomainValue = []

                                                                                                                            if (vendorDetails.length > 1) {
                                                                                                                                if (index !== -1) {
                                                                                                                                    vendorDetails.splice(index, 1);
                                                                                                                                }
                                                                                                                            }
                                                                                                                            console.log(isLegalDomainValue, legalEntityDetails, 'isLegalDomainValue')

                                                                                                                            vendorDetails.some((domains) => {
                                                                                                                                console.log(domains, 'domainsul')
                                                                                                                                console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                                isLegalDomainValue.push(Number(domains.d_id))
                                                                                                                                console.log(isLegalDomainValue, 'isLegalDomainValueul')
                                                                                                                            })

                                                                                                                            let finalD_id = domainResponse.filter((select_domain_list) => {
                                                                                                                                return select_domain_list.country_ids.includes(Number(legalEntityDetails[item.uniq_id]?.country_id))

                                                                                                                            })
                                                                                                                            finalD_id = finalD_id.filter((rejDomains) => {
                                                                                                                                console.log(rejDomains.domain_id, 'letscheck')
                                                                                                                                if (isLegalDomainValue.includes(Number(rejDomains.domain_id))) {

                                                                                                                                } else {
                                                                                                                                    return rejDomains
                                                                                                                                }
                                                                                                                            })

                                                                                                                            legalEntityDetails[item.uniq_id].select_domain_vendor = finalD_id
                                                                                                                            console.log(finalD_id, 'finalD_id')

                                                                                                                            setLegalEntityDetails([...legalEntityDetails])
                                                                                                                        }} /> : <></>}
                                                                                                                </span></td>
                                                                                                            </tr>
                                                                                                        </>)
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {(legalEntityDetails[item.uniq_id].select_domain_vendor?.length > 0) ?
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            className='addbutton'
                                                                            icon={<PlusCircleOutlined />}
                                                                            size='default'
                                                                            onClick={() => {
                                                                                if (vendorDetails[vendorDetails.length - 1].d_id != '') {
                                                                                    vendorDetails.push(
                                                                                        {
                                                                                            'unique_id': vendorDetails[legalEntityDetails.length - 1].unique_id + 1,
                                                                                            activation_date:"",
                                                                                            d_id :'',
                                                                                            group_id: '',
                                                                                            is_delete: 0,
                                                                                            legal_entity_id : '',
                                                                                            org : { }
                                                                                        }
                                                                                    )

                                                                                    setVendorDetails([...vendorDetails])
                                                                                } else {
                                                                                    Toaster.error('Select The Domain')
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add Domain
                                                                        </Button> : <></>}
                                                                </> :
                                                                <h4>Compfie Vendor Contract Details</h4>
                                                        }


                                                    </TabPane>
                                                </Tabs>
                                            </div >
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <h3>Date Configuration</h3>
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button
                                type="primary"
                                shape="round"
                                className='addbutton'
                                icon={<PlayCircleOutlined />}
                                size='default'
                                onClick={() => {

                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div >
    )
}
export default Add_EditClientGroup;