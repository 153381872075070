import { TABLE_DATA } from '../../types/index'

const initialState = {
    groupadminRegisEmail: {
        list: [],
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TABLE_DATA:
            {
                console.log(payload,'payload');
                return {
                    ...state,
                    groupadminRegisEmail: {
                        ...state.groupadminRegisEmail,
                        list: payload,
                    },
                };
            }
        default:
            return state;
    }
}