import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { forgotPassword } from '../Store/Action/Forgotpassword';
import { useLocation, Link, useHistory } from 'react-router-dom'


const mapStateToProps = (state) => ({

})

const Forgotpassword = ({
    forgotPassword
}) => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const history = useHistory();
    const [ForgotData, setForgotData] = useState({
        userId: '',
        groupName: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const submitForgot = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            forgotPassword({
                payload: ForgotData
            })
           
        }       
    }
    return (
        <Fragment>
            <div style={{ backgroundImage: 'url(login3-bg.png)', height: '100vh', backgroundSize: 'cover' }} className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-end flex-column">

                <div style={{ borderRadius: '2rem' }} className="auth-box p-3 bg-white m-0 me-5">
                    <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                    <div id="loginform">
                        <div className="logo">
                            <center>
                                <h3 className="box-title mb-3">Forgot Password</h3>
                            </center>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form className="form-horizontal mt-3" id="loginform">
                                    <div className="form-group mb-3 position-relative">

                                        <input className="form-control" maxLength={20} type="text" required="" autoFocus={true} placeholder="Enter Username" onChange={(e) => {
                                            setForgotData({
                                                ...ForgotData, userId: e.target.value
                                            })
                                        }}  onKeyPress={(event) => {
                                            if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}  />
                                        <i style={{ position: 'absolute', top: '6px', right: '7px', fontSize: '1.3rem' }} className="ri-user-line "></i>

                                        {validator.current.message(
                                            'userId',
                                            ForgotData.userId,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Username Required',
                                                }
                                            })}


                                    </div>                                   


                                    <div className="col-md-12">
                                        <div className='row'>


                                            <div className="col-md-6">

                                                <button style={{ backgroundColor: '#8572ed' }} type='button' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100
                                                        login3-btn
                                                        border-0
                                                        waves-effect waves-light

                                                        "
                                                    onClick={submitForgot}
                                                >

                                                    Submit

                                                </button>

                                            </div>

                                            <div className="col-md-6">

                                                <button type='primery' className="

                                                        btn btn-light

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                    onClick={() => {
                                                        history.push('/login3')
                                                    }}
                                                    style={{ backgroundColor: '#8572ed',color:'white' }}
                                                >

                                                    Cancel

                                                </button>

                                            </div>

                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>

                </div>



                <div className='d-flex  align-items-end justify-content-end w-100 mt-5'>
                        <footer className="footer bg-transparent text-white  pb-0 shadow-sm" >2022© Aparajitha Software Services Private Limited <span className="ms-4">Privacy Policy · Terms & Condition </span> </footer>

                    </div>
            </div>


        </Fragment>
    );
}

export default connect(mapStateToProps, {
    forgotPassword
})(Forgotpassword);