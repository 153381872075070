import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { CLIENT_UNIT_BULK_UPLOAD, DOWNLOAD_ASSIGN_STATUTORY, DOWNLOAD_ASBU_LINK, CLIENT_UNIT_STATUTORY_CSV, CLIENTUPLOAD_FILE_STATUS } from '../../types/index'

const initialState = {
    client_unit_bulkupload: {
        filterdetails: [],
        downloadAssignStatutory: [],
        downloadUrl: [],
        uploadCSV_name: [],
        uploadFileStatus: []
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIENT_UNIT_BULK_UPLOAD:
            {
                return {
                    ...state,
                    client_unit_bulkupload: {
                        ...state.client_unit_bulkupload,
                        filterdetails: payload,
                    },
                };
            }
        case DOWNLOAD_ASSIGN_STATUTORY: {
            return {
                ...state,
                client_unit_bulkupload: {
                    ...state.client_unit_bulkupload,
                    downloadAssignStatutory: payload,
                }
            }
        }
        case DOWNLOAD_ASBU_LINK: {
            return {
                ...state,
                client_unit_bulkupload: {
                    ...state.client_unit_bulkupload,
                    downloadUrl: payload,
                }
            }
        }
        case CLIENT_UNIT_STATUTORY_CSV: {
            return {
                ...state,
                client_unit_bulkupload: {
                    ...state.client_unit_bulkupload,
                    uploadCSV_name: payload,
                }
            }
        }
        case CLIENTUPLOAD_FILE_STATUS: {
            console.log(payload,"payloadpayload");
            return {
                ...state,
                client_unit_bulkupload: {
                    ...state.client_unit_bulkupload,
                    uploadFileStatus: payload,
                }
            }
        }
        default:
            return state;
    }
}
