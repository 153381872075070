import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import {
    MAPPINGBULKLIST, TOGGLE_COMMON_LOADER, BULKUPLOADDOMAIN, UPLOADFILENAME,
    ERRORDATA, AMENDMENT_DATA, ADD_UPLOAD_DATA, AMENDMENT_UPLOAD_SUCCESS, AMENDMENT_UPLOAD_FILE, AMENDMENT_UPLOAD_ERROR, MAPPING_UPLOAD_LIST
} from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';

export const getuploadlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'GetStatutoryMappingCsvUploadedListSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: MAPPINGBULKLIST,
                    payload: decryptData[1],
                });
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getdomainlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'GetDomainsSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: BULKUPLOADDOMAIN,
                    payload: decryptData[1],
                });
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getuploadfilename = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'Done') {

                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: UPLOADFILENAME,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'RejectionMaxCountReached') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error('Rejection Max Count Reached')
            } else if (decryptData[0] === 'CsvFileCannotBeBlank') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error('CSV File Cannot Be Blank')
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Bulkfileupload = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            //  url: `${url.CommonURL}/knowledgetemp/upload?session_id=" +
            // // session_id + "&csvid=" + CSV_ID`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            }
            else if (decryptData[0] === 'UploadStatutoryMappingCSVInvalidSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: ERRORDATA,
                    payload: decryptData[1],
                });
                // Toaster.error('File Upload Failed');
            }
            else if (decryptData[0] === 'UploadStatutoryMappingCSVValidSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: ADD_UPLOAD_DATA,
                    payload: decryptData[1],
                });
                // Toaster.error('File Upload Failed');
            }

            else if (decryptData[0] === 'Success') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: ERRORDATA,
                    payload: decryptData[1],
                });
                // Toaster.error('File Upload Failed');
            }
            else if (decryptData[0] === 'CsvFileCannotBeBlank') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                Toaster.error('CSV File Cannot Be Blank');
            }

            else if (decryptData[0] === 'Alive') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch(Bulkfileupload({
                    payload: payload,
                    paramid: paramid
                }));

            }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error("Invalid csv File");
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getamendmentdata = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        console.log(executeRequest, 'exe');
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'GetCSVDetailsSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: AMENDMENT_DATA,
                    payload: decryptData[1],
                });
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const downloadamendment = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'DownloadCSVFileSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                let path = decryptData[1].file_url;
                window.open(`${url.CommonURL}/` + path);
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadamendment = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'Done') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: AMENDMENT_UPLOAD_SUCCESS,
                    payload: decryptData[1],
                })
            } else if (decryptData[0] == 'BulkUploadTransactionFailed') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error('Failed to Upload');
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error('File cannot be Empty');
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadamendmentdata = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'Alive') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch(uploadamendmentdata({
                    payload: payload,
                    paramid: paramid
                }))

            } else if (decryptData[0] == 'UploadStatutoryMappingCSVInvalidSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: AMENDMENT_UPLOAD_ERROR,
                    payload: decryptData[1],
                })
                toast.error('StatutoryMapping File upload Failed');
            }
            else if (decryptData[0] == 'UploadStatutoryMappingCSVValidSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: AMENDMENT_UPLOAD_FILE,
                    payload: decryptData[1],
                })

            }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.success('Statutory Mapping File upload Success');
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadsdocumentlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/bu/statutory_mapping`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (decryptData[0] == 'GetDomainsSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: MAPPING_UPLOAD_LIST,
                    payload: decryptData[1],
                })
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.console.error(decryptData[1]);
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Contentfileupload = ({ payload, executeCancel, paramid, token, csv_id }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.FileUrl}/upload?session_id=` + encryptkey + `&csvid=` + csv_id,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decryptDatadecryptDatadecryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (response == 'Upload Failed') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Upload Failed');
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.success('File Successfully Uploaded');
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const Contentfileuploads = ({ payload, executeCancel, paramid, token, csv_id }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('authoToken');
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.FileUrl}/upload?session_id=` + encryptkey + `&csvid=` + csv_id,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, 'decryptDatadecryptDatadecryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else if (response == 'Upload Failed') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Upload Failed');
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.success('File Successfully Uploaded');
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

